import { BREAKPOINT } from '@angular/flex-layout'
export const STRING_CONSTANTS = {
  SERVER_SIDE: 'serverSide',
  UPDATED: 'Updated ',
}

export const INNER_ROUND_BUTTONS = [
  { tooltipText: 'Settings', className: 'light', iconClassName: 'setting-c', roundButtonName: 'Settings' },
  { tooltipText: 'Refresh', className: 'light', iconClassName: 'refresh-c', roundButtonName: 'Refresh' },

]
export const WITHOUT_SETTING_BUTTONS = [

  { tooltipText: 'Refresh', className: 'light', iconClassName: 'refresh-c', roundButtonName: 'Refresh' },

]
export const INNER_ROUND_WITH_REFRESH = [

  { tooltipText: 'Settings', className: 'light', iconClassName: 'setting-c', roundButtonName: 'Settings' },
  { tooltipText: 'Refresh', className: 'light', iconClassName: 'refresh-c ', roundButtonName: 'Refresh', isLoading: true,
    isDisabled: true },
]
export const PIECHART_ROUND_BUTTON = [
  { tooltipText: 'Settings', className: 'light', iconClassName: 'setting-c', roundButtonName: 'Settings' },
]
export const ASSET_ROUND_BUTTON = [{ tooltipText: 'Refresh', className: 'light', iconClassName: 'refresh-c', roundButtonName: 'Refresh' }]
export const ASSET_ROUND_BUTTON_WITH_REFRESH = [
  { tooltipText: 'Refresh', className: 'light', iconClassName: 'refresh-c icon-btn-loader', roundButtonName: 'Refresh', isLoading: true,
    isDisabled: true },
]

export const BREAK_POINT = 600
export const SUCCESS_SHOW_NOTIFICATION = {
  SUCCESS_MODE: 'success',
  TITLE: 'Message',
  MAIN_MESSAGE: 'SAVED',
  FAIL_MODE: 'fail',
  FAIL_MAIN_MESSAGE: 'Error',
}
export const ROUND_BUTTON = [
  { tooltipText: 'Manage', className: 'light', iconClassName: 'plus-c', roundButtonName: 'roundButton1' },
]
export const toggleButtonArray: Array<{ tooltipText: string; className: string; iconName: string; isChecked?: boolean }> = [
  { tooltipText: 'Mobile', className: ' icon-mobile ', iconName: 'mobile' },
  { tooltipText: 'Tablet', className: 'icon-tablet', iconName: 'tab' },
  { tooltipText: 'Desktop', className: 'icon-desktop', iconName: 'desktop', isChecked: true },
]
export const toggleButtonTab: Array<{ tooltipText: string; className: string; iconName: string; isChecked?: boolean }> = [
  { tooltipText: 'Tab', className: 'icon-tablet', iconName: 'tab' },
]
export const toggleButtonMob: Array<{ tooltipText: string; className: string; iconName: string; isChecked?: boolean }> = [
  { tooltipText: 'Mobile', className: ' icon-mobile ', iconName: 'mobile' },
]
export const DEFAULT_TAB_ID = {
  MAIN_DASHBOARD_ID: 'ddebf96b-12c9-4e95-8deb-f534b91c2f18',
  HUD_TICKETS_ID: '507f1e00-1c94-49ab-969a-b0692f9d53a7',
  HUD_ASSETS: '076c0a9d-8dd7-4498-937d-f4deaa83a039',
}
export const ASSET_WIDGET_ID = {
  OFFLINE_SERVER_ID: 'fada70b6-8dfa-49ec-b0ce-4dcc25298f58',
  ONLINE_SERVER_ID: '6f4a3b5e-98b7-4cfb-af30-30dfcb0a81a3',
  SERVER_WIDGET_ID: '855eabdf-0113-4cae-b87c-e8026deb56da',
}

export const TABLE_HEADERS = {
  NUMBER_WIDGET_TICKETS: id => `ticket ${id}`,
  NUMBER_WIDGET_ASSETS: id => `assets ${id}`,
}
export const DOMAIN_SSL_PIECHART_ID = '24197946-6016-4a69-85c9-24eb994cd5f0'
export const OPEN_ISSUES_BY_BOARD = '68bed8b7-2b5e-47ef-8ef6-1da6050b4c25'

export const USER_FAV_COMPANIES_WIDGET_ID = 'eded169d-b96c-4cfa-bdf5-310720478483'
export const URL = {
  FAVOURITE_COMPANIES: (widgetId, activeTabId) => `dashboard/widgets/${widgetId}/${activeTabId}`,
  GET_ALL_COMPANIES: 'companies?pageNo=0&pageSize=10',
  GET_ALL_COMPANIES_LIST: 'companies?pageNo=0&pageSize=1000',
  GET_TABS_WIDGET: (categoryId, tabId, currentResoultion) => `dashboard/tabs/widgets/${categoryId}/${tabId}/${currentResoultion}`,
  GET_PIECHART_Data: (id, activeTabId) => `dashboard/widgets/${id}/${activeTabId}`,
  GET_PIECHART_Data_WITHDETAILS: (id, activeTabId) => `dashboard/widgets/${id}/${activeTabId}?detailFlag=true`,
  GET_ASSET_WARRANTY: '/assetwaranty',
  GET_NUMBER_WIDGET_DATA: (id, tabId) => `dashboard/widgets/${id}/${tabId}`,

  SAVE_TAB_SETTING: id => `dashboard/tabs/${id}`,
  GET_ALL_BOARDS: 'organizations/metadata/boards',
  GET_WIDGETS: 'dashboard/widgets',
  GET_TAB_BY_ID: id => `dashboard/tabs/${id}`,
  COPY_TAB: (tabId, categoryId) => `dashboard/tabs/${tabId}/${categoryId}/copy`,
  REMOVE_TAB: (categoryId, tabId, isClosed) => `dashboard/category/${categoryId}/tabs/${tabId}/${isClosed}`,
  REOPEN_TAB: (categoryId, tabId, isClosed) => `dashboard/category/${categoryId}/tabs/${tabId}/${isClosed}`,

  UPDATE_TAB: (categoryId, tabId) => `dashboard/category/${categoryId}/tabs/${tabId}`,

  DELETE_TAB: (categoryId, tabId) => `dashboard/category/${categoryId}/tabs/${tabId}`,
  SAVE_TAB_HANDLER: 'dashboard/tabs',
  GET_SELECTED_WIDGET: id => `dashboard/Widget/${id}`,
  SAVE_WIDGET_SETTING: (tabId, categoryId, type) => `dashboard/tabs/widgets/${categoryId}/${tabId}/${type}`,
  GET_REMOVE_TABS: categoryId => `dashboard/${categoryId}/tabs/true`,

  GET_DASHBOARD_TABS: id => `dashboard/${id}/tabs/false`,
  SAVE_FAVOURITE_COMPANIES: 'companies/bulkupdate',
  ADD_TABS: categoryId => `dashboard/category/${categoryId}`,
  GET_DEFAULT_WIDGETS: (id, view) => `dashboard/default/widgets/${id}/${view}`,
  GET_WIDGET_DETAILS: (widgetId, activetabId) => `dashboard/widgets/${widgetId}/${activetabId}?detailFlag=true`,
  GET_WIDGET_COUNT: (widgetId, activetabId) => `dashboard/widgets/${widgetId}/${activetabId}`,
  GET_CW_AUTH: 'users/cwauth',
  USER_FAVOURITE_COMPANIES_WIDGET: 'users/metadata/favCompanies',
  USER_HEADERS: 'dashboard/user/headers',
  GET_DOMAIN_SSL_DETAILS: (value, widgetId, activeTabId) =>
    `dashboard/widgets/detail/data/${value}/${widgetId}/${activeTabId}/?detailFlag=true`,
  GET_ASSET_WARRANTY_DETAILS: (value, widgetId, activeTabId) =>
    `dashboard/widgets/detail/data/${value}/${widgetId}/${activeTabId}/?detailFlag=true`,
}
