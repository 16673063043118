import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FlexModule } from '@angular/flex-layout/flex'
import { MAT_DIALOG_DEFAULT_OPTIONS, MatCardModule, MatDialogModule } from '@angular/material'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MdePopoverModule } from '@material-extended/mde'
import {
  ActionPanelModule,
  AlphabetFilterModule,
  BadgeModule,
  BarChartModule,
  BarLoaderModule,
  BreadcrumbsModule,
  ButtonGroupModule,
  CheckboxListModule,
  CheckboxModule,
  CheckboxWithDividerModule,
  ChipModule,
  CircleProgressBarModule,
  ColorBarModule,
  ConfirmationMessagesComponent,
  ConfirmationMessagesModule,
  DatePickerModule,
  DateTimePickerModule,
  DefaultButtonModule,
  DividerModule,
  DropdownListModule,
  DropdownModule,
  DropupButtonModule,
  DropZoneModule,
  DynamicActionPanelModule,
  EditorModule,
  EmailTemplateModule,
  EmptyStateImageModule,
  ExpansionListItemModule,
  ExpansionPanelModule,
  FeedbackPageComponent,
  FeedbackPageModule,
  HeadingModule,
  IconModule,
  ImageModule,
  InfiniteSelectModule,
  InfoMessageModule,
  InpageHeadingModule,
  InputModule,
  InputWithFlagModule,
  IntegrationBoxModule,
  JsonViewerModule,
  LeftSideBarModule,
  ListItemModule,
  MenuButtonModule,
  MultiSelectChipsModule,
  MultiSelectChipsObjectModule,
  MultiSelectDropDownModule,
  NotificationModule,
  PageTitleModule,
  PageWrapperModule,
  PaginationModule,
  PaginatorModule,
  PieChartModule,
  PopOverModule,
  PopupAlertComponent,
  PopupAlertModule,
  PricingColumnModule,
  PricingPanelModule,
  ProgressButtonModule,
  RadioModule,
  RangeSliderModule,
  RoundButtonModule,
  RsbExpansionPanelModule,
  RsbFeedbackModule,
  RsbItemListModule,
  SearchBarButtonModule,
  SearchWidgetModule,
  SelectModule,
  SelectWithButtonModule,
  SpinnerModule,
  SquareButtonModule,
  SubmenuButtonModule,
  SurveyCountModule,
  SurveyOptionModule,
  SwitchModule,
  TableModule,
  TabsModule,
  TextareaModule,
  TextModule,
  ThankyouTemplateModule,
  TimelineModule,
  ToggleButtonModule,
  WidgetFooterModule,
  WidgetModule,
} from 'ui-components'

import { DataGridModule } from '../../projects/ag-grid-customized/src/app/data-grid/data-grid.module'
import { GirdActionService } from '../../projects/ag-grid-customized/src/app/data-grid/services/grid-actions.service'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ColorBarModule,
    DatePickerModule,
    TextModule,
    EditorModule,
    ConfirmationMessagesModule,
    ActionPanelModule,
    AlphabetFilterModule,
    BadgeModule,
    BarChartModule,
    BarLoaderModule,
    BreadcrumbsModule,
    ButtonGroupModule,
    CheckboxModule,
    ChipModule,
    FlexLayoutModule,
    ColorBarModule,
    ConfirmationMessagesModule,
    DatePickerModule,
    DateTimePickerModule,
    DefaultButtonModule,
    MultiSelectChipsObjectModule,
    DividerModule,
    DropdownListModule,
    DropdownModule,
    DropupButtonModule,
    DropZoneModule,
    DynamicActionPanelModule,
    EditorModule,
    EmptyStateImageModule,
    ExpansionListItemModule,
    ExpansionPanelModule,
    HeadingModule,
    InfiniteSelectModule,
    IconModule,
    ImageModule,
    InfoMessageModule,
    InputModule,
    InputWithFlagModule,
    LeftSideBarModule,
    PageTitleModule,
    FlexLayoutModule,
    BreadcrumbsModule,
    ActionPanelModule,
    DefaultButtonModule,
    DropupButtonModule,
    DynamicActionPanelModule,
    PageWrapperModule,
    PaginationModule,
    PaginatorModule,
    PieChartModule,
    PopOverModule,
    PricingColumnModule,
    RadioModule,
    RoundButtonModule,
    RsbItemListModule,
    SelectModule,
    SelectWithButtonModule,
    SpinnerModule,
    SquareButtonModule,
    SurveyCountModule,
    SurveyOptionModule,
    SwitchModule,
    MatSnackBarModule,
    TableModule,
    PieChartModule,
    TabsModule,
    ThankyouTemplateModule,
    DropZoneModule,
    PopupAlertModule,
    MatDialogModule,
    RoundButtonModule,
    LeftSideBarModule,
    BarLoaderModule,
    WidgetModule,
    ImageModule,
    RadioModule,
    BadgeModule,
    CheckboxListModule,
    CheckboxWithDividerModule,
    TextareaModule,
    TextModule,
    DataGridModule,
    ToggleButtonModule,
    WidgetModule,
    PricingPanelModule,
    InpageHeadingModule,
    WidgetFooterModule,
    ListItemModule,
    JsonViewerModule,
    MultiSelectChipsModule,
    MenuButtonModule,
    ProgressButtonModule,
    SearchBarButtonModule,
    EmailTemplateModule,
    FeedbackPageModule,
    CircleProgressBarModule,
    RangeSliderModule,
    RsbFeedbackModule,
    SearchWidgetModule,
    SubmenuButtonModule,
    NotificationModule,
    TimelineModule,
    PopupAlertModule,
    RsbExpansionPanelModule,
    MatCardModule,
    FlexLayoutModule,
    RsbFeedbackModule,
    MdePopoverModule,
    MultiSelectDropDownModule,
    IntegrationBoxModule,
  ],
  exports: [
    ActionPanelModule,
    AlphabetFilterModule,
    BadgeModule,
    BarChartModule,
    BarLoaderModule,
    DefaultButtonModule,
    ConfirmationMessagesModule,
    DynamicActionPanelModule,
    EditorModule,
    ColorBarModule,
    DropupButtonModule,
    DynamicActionPanelModule,
    MatDialogModule,
    RoundButtonModule,
    ActionPanelModule,
    BreadcrumbsModule,
    ButtonGroupModule,
    FlexLayoutModule,
    CheckboxListModule,
    CheckboxModule,
    ChipModule,
    ColorBarModule,
    ConfirmationMessagesModule,
    ConfirmationMessagesComponent,
    CheckboxWithDividerModule,
    DatePickerModule,
    MultiSelectChipsObjectModule,
    DateTimePickerModule,
    DefaultButtonModule,
    DividerModule,
    DropdownListModule,
    DropdownModule,
    DropupButtonModule,
    DropZoneModule,
    DynamicActionPanelModule,
    EditorModule,
    EmptyStateImageModule,
    ExpansionListItemModule,
    ExpansionPanelModule,
    HeadingModule,
    InfiniteSelectModule,
    IconModule,
    ImageModule,
    InfoMessageModule,
    InputModule,
    InputWithFlagModule,
    LeftSideBarModule,
    PageTitleModule,
    DropupButtonModule,
    PageWrapperModule,
    PaginationModule,
    PaginatorModule,
    PieChartModule,
    PopOverModule,
    PopupAlertModule,
    PopupAlertComponent,
    PricingColumnModule,
    RadioModule,
    RoundButtonModule,
    RsbItemListModule,
    SelectModule,
    SelectWithButtonModule,
    SpinnerModule,
    SquareButtonModule,
    SurveyCountModule,
    SurveyOptionModule,
    SwitchModule,
    TableModule,
    FlexLayoutModule,
    TabsModule,
    TextModule,
    ThankyouTemplateModule,
    HeadingModule,
    InputModule,
    DropZoneModule,
    PieChartModule,
    PopupAlertModule,
    DatePickerModule,
    DividerModule,
    PageTitleModule,
    BreadcrumbsModule,
    MatDialogModule,
    RoundButtonModule,
    LeftSideBarModule,
    SpinnerModule,
    SpinnerModule,
    RoundButtonModule,
    LeftSideBarModule,
    TextareaModule,
    TextModule,
    ToggleButtonModule,
    WidgetModule,
    PricingPanelModule,
    InpageHeadingModule,
    WidgetFooterModule,
    ListItemModule,
    JsonViewerModule,
    MultiSelectChipsModule,
    MenuButtonModule,
    ProgressButtonModule,
    SearchBarButtonModule,
    EmailTemplateModule,
    FeedbackPageModule,
    CircleProgressBarModule,
    RangeSliderModule,
    SearchWidgetModule,
    SubmenuButtonModule,
    NotificationModule,
    TimelineModule,
    RoundButtonModule,
    TextareaModule,
    RadioModule,
    BarLoaderModule,
    WidgetModule,
    ImageModule,
    BadgeModule,
    RsbExpansionPanelModule,
    MatCardModule,
    DataGridModule,
    FlexModule,
    FlexLayoutModule,
    RsbFeedbackModule,
    MdePopoverModule,
    MultiSelectDropDownModule,
    IntegrationBoxModule,
  ],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }, GirdActionService],
  entryComponents: [ConfirmationMessagesComponent, PopupAlertComponent],
})
export class UiComponentsModule {}
