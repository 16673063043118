export const END_POINTS = {
    GET_BOARDS: 'organizations/metadata/boards',
    GET_BOARDTYPES: 'organizations/metadata/boardTypes',
    GET_MEMBERS: 'organizations/metadata/member',
    GET_TICKETSTATUS: 'organizations/metadata/ticketStatus',
    POST_TICKET: 'users/tickets',
    GET_CONTACTS_BY_COMPANY: (companyId: string) =>
        // tslint:disable-next-line: max-line-length (template literals are prefered so max line rule is disabled)
        `contacts/company/${companyId}?fields={"uuid":1, "firstName":1, "lastName":1, "id":1, "phoneDirect":1}&conditions={"syncFlag":true}`,
}

export const NOTIFICATIONS = {
    SUCCESS_MODE: 'success',
    SAVE_MESSAGE: 'SAVED!',
    SAVED: 'New ticket created successfully.',
    ERROR_MODE: 'fail',
    ERROR_MESSAGE: 'ERROR',
    UNSAVED: 'Something went wrong.',
    SELECT_BOARD_FIRST: 'No board selected',
}
