<form>
  <itb-select
    *ngIf = "linkedItemsTypeOptions.length > 0"
    [formFieldName]="'itemType'"
    [isDisabled]="false"
    [parentForm]="linkedItemForm"
    [items]="linkedItemsTypeOptions"
    [label]="'Select Category'"
    (onChangeHandler)="onTypeChange($event)"
  ></itb-select>
  <itb-select
    *ngIf = "linkedItemsTypeSelectedOptions.length > 0"
    [formFieldName]="'itemsList'"
    [isDisabled]="false"
    [parentForm]="linkedItemForm"
    [items]="linkedItemsTypeSelectedOptions"
    [label]="'Select Item'"
    [isMultiple]="true"
    [isDataInStrings]="false">
  </itb-select>
  <itb-info-message *ngIf="hasNoData" [text]="'No data to be displayed'" [className]="'info-danger p-t-5'"></itb-info-message>
  <div class="rsb-btngroup" *ngIf = "!hasNoData">
    <itb-button-group [buttons]="buttons" [className]="'button-group'" (onClickHandler)="btnClickHandler($event)"></itb-button-group>
  </div>
</form>
