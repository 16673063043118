import { Injectable } from '@angular/core'
import { ApiService } from '@app/services/api.service'
import { Observable } from 'rxjs'

import { END_POINTS } from '../constants/company-creation.constants'
import { ICompanyObject, IStatusOptions, ITypeOptions } from '../interfaces/company-creation'

@Injectable({
  providedIn: 'root',
})
export class CompanyCreationService {
  constructor(private readonly _apiService: ApiService) {}

    /*** Adds a company */
  postCompany(data: ICompanyObject): Observable<ICompanyObject> {
    return this._apiService.add('companies', data, true)
  }

    /*** Adds a company type */
  postCompanyType(data: ITypeOptions): Observable<ITypeOptions> {
    return this._apiService.add(END_POINTS.POST_COMPANY_TYPE, data)
  }

    /*** Adds a company status */
  postCompanyStatus(data: IStatusOptions): Observable<IStatusOptions> {
    return this._apiService.add(END_POINTS.POST_COMPANY_STATUS, data)
  }

    /*** Gets all company types */
  getCompanyTypes(): Observable<ITypeOptions[]> {
    return this._apiService.get(END_POINTS.GET_COMAPNY_META('companyType'), 'Company Type', true)
  }

    /*** Gets all company status types */
  getCompanyStatus(): Observable<IStatusOptions[]> {
    return this._apiService.get(END_POINTS.GET_COMAPNY_META('companyStatus'), 'Company Status', true)
  }

    /*** Gets all countries */
  getCountries() {
    return this._apiService.get(END_POINTS.GET_COUNTRIES, 'Countries')
  }

    /*** Gets all company members */
  getMembers() {
    return this._apiService.get(END_POINTS.GET_COMPANY_MEMBERS, '', true)
  }

    /*** Gets all classifications */
  getClassifications() {
    return this._apiService.get(END_POINTS.GET_COMPANY_CLASSIFFICATION, '', true)
  }
}
