<itb-widget
  [hasFooter]="false"
  [roundButtons]="roundButton"
  [hasBarLoader]="false"
  [hasContentCenter]="true"
  (onClickHandler)="documentListings($event)"
>
  <div class="slide-wrapper {{ wrapperClass }}">
    <div class="main-content">
      <div *ngIf="isDetail">
        <div *ngIf="!isEmpty" class="widget-table action-center">
          <itb-table [dataSource]="dataSource2" [displayedColumns]="displayedColumns" (onActionClickHandler)="navigate($event)"></itb-table>
        </div>
        <div *ngIf="isEmpty" fxLayout="column" fxLayoutAlign="center center" class="no-data">
          <span [ngClass]="{ 'img-nodatafound': true }"></span>
          <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
        </div>
        <div *ngIf="isError" class="no-data" fxLayout="column" fxLayoutAlign="center center">
          <span [ngClass]="{ 'img-404error': true }"></span>
          <itb-text [className]="'text-default text-color-primary'" [text]="'SOMETHING WENT WRONG'"></itb-text>
        </div>
      </div>
    </div>
    <div class="slide-content">
      <div *ngIf="!isDetail" class="h-100">
        <ng-container *ngIf="isColLoaded">
          <itb-data-grid
            [sideBar]="true"
            [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs"
            [cacheBlockSize]="100"
            [totalRows]="rowData.length"
            [rowModelType]="'serverSide'"
            [rowData]="rowData"
            (dataGridRowsPropsEmitter)="getRows($event)"
            (columnStateChangeEmitter)="columnStateChanged($event)"
            [initialUserColState]="userColumnState"
          >
          </itb-data-grid>
        </ng-container>
        <itb-spinner fxLayoutAlign="center center" class="h-100" [spinnerSize]="75" *ngIf="isLoading"></itb-spinner>
        <div *ngIf="isEmpty" [ngClass]="{ 'img-nodatafound': true }" fxLayout="column" fxLayoutAlign="end center">
          <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
        </div>
        <div *ngIf="isError" class="no-data" fxLayout="column" fxLayoutAlign="center center">
          <span [ngClass]="{ 'img-404error': true }"></span>
          <itb-text [className]="'text-default text-color-primary'" [text]="'SOMETHING WENT WRONG'"></itb-text>
        </div>
      </div>
    </div>
  </div>
</itb-widget>
