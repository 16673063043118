import { Injectable } from '@angular/core'
import { act, Actions, Effect, ofType } from '@ngrx/effects'
import { Action } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { AdlService } from '../../../services/adl.service'
import * as adlActions from '../actions/adl.actions'

@Injectable()

export class AdlEffect {

  @Effect()
  loadDiscoverers$: Observable<Action> = this.actions$.pipe(
    ofType<adlActions.LoadDiscoverers>(
      adlActions.LOAD_DISCOVERERS
    ),
    switchMap((action: adlActions.LoadDiscoverers) =>
      this.adlService.getAllDiscoverers().pipe(
        map(discoverers =>
          new adlActions.LoadDiscoverersSuccess(discoverers)
        ),
        catchError(err => of(new adlActions.LoadDiscoverersFail(err)))
      )
    )
  )

  @Effect()
  loadDiscovererSystems$: Observable<Action> = this.actions$.pipe(
    ofType<adlActions.LoadDiscovererSystems>(
      adlActions.LOAD_DISCOVERERSYSTEMS
    ), map((action: adlActions.LoadDiscovererSystems) => ({
      conditions: action.conditions,
      companyUuid: action.companyUuid,
      pageNo: action.pageNo,
      PageSize: action.pageSize,
      sortBy: action.sortBy,
      order: action.order,
    })),
    switchMap(data =>
      this.adlService.getDiscovererSystems(data.conditions, data.companyUuid).pipe(
        map(discovererSystems =>
          new adlActions.LoadDiscovererSystemsSuccess(discovererSystems)
        ),
        catchError(err => of(new adlActions.LoadDiscovererSystemsFail(err)))
      )
    )
  )

  // @Effect()
  // loadHeaders$: Observable<Action> = this.actions$.pipe(
  //   ofType<adlActions.LoadHeaders>(
  //     adlActions.LOAD_HEADERS
  //   ),
  //   switchMap((action: adlActions.LoadHeaders) =>
  //     this.adlService.getHeaders(action.payload).pipe(
  //       map(headers =>
  //         new adlActions.LoadHeadersSuccess(headers)
  //       ),
  //       catchError(err => of(new adlActions.LoadHeadersFail(err)))
  //     )
  //   )
  // )
  constructor(
    private readonly actions$: Actions,
    private readonly adlService: AdlService
  ) { }

}
