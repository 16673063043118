import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { IExpansionPanel, IViewSwitcher } from '@core/interfaces/panels'
import { Subscription } from 'rxjs'

import { PodsService } from '../services/pods.service'

@Component({
  selector: 'app-company-asset-pods',
  templateUrl: './asset-pods.component.html',
  styleUrls: ['./asset-pods.component.scss'],
})
export class AssetPodsComponent implements OnInit, OnDestroy {
  subscription: Subscription
  screenName: string
  isLoading: boolean
  rightSideBarPanels: IExpansionPanel[]
  viewSwitcher: IViewSwitcher
  moduleName: string
  companyId: string
  itemId: string
  hasEditPermissions: boolean
  isError: boolean
  itemName: string
  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly podsService: PodsService) {
    this.hasEditPermissions = true
    this.isError = false
    this.rightSideBarPanels = [
      {
        id: 1,
        name: 'remote-login',
        text: 'REMOTE LOGIN',
        icon: 'lock',
        isExpanded: false,
        hasAddBtn: false,
      },
      {
        id: 2,
        name: 'passwords',
        text: 'LOCAL PASSWORDS',
        icon: 'key',
        isExpanded: false,
        hasAddBtn: true,
      },
      {
        id: 3,
        name: 'notes',
        text: 'QUICK NOTES',
        icon: 'note2',
        isExpanded: false,
        hasAddBtn: true,
      },
      {
        id: 4,
        name: 'linkedItem',
        text: 'LINKED ITEMS',
        icon: 'lock',
        isExpanded: false,
        hasAddBtn: true,
      },
      {
        id: 5,
        name: 'revisions',
        text: 'REVISIONS',
        icon: 'lock',
        isExpanded: false,
        hasAddBtn: true,
      },
      {
        id: 6,
        name: 'attachments',
        text: 'ATTACHMENTS',
        icon: 'paperclip',
        isExpanded: false,
        hasAddBtn: false,
      },
    ]
    this.viewSwitcher = {
      localPasswords: {
        isList: true,
        itemsLength: 0,
        currentView: '',
        selectedObj: {},
      },
      notes: {
        isList: true,
        itemsLength: 0,
        currentView: '',
        selectedObj: {},
      },
      linkedItems: {
        isList: true,
        itemsLength: 0,
        currentView: '',
        selectedObj: {},
      },
      revisions: {
        isList: true,
        itemsLength: 0,
        currentLength: 0,
        currentView: '',
        selectedObj: {},
      },
      attachments: {
        isList: true,
        itemsLength: 0,
        currentLength: 0,
        currentView: '',
        selectedObj: {},
      },
    }
    this.isLoading = false
    this.subscription = new Subscription()
    this.screenName = this.activatedRoute.snapshot.queryParams.screenName
    this.moduleName = this.activatedRoute.snapshot.queryParams.asset
    this.podsService.checkPodsSession()
  }
  ngOnInit() {
    const id = this.activatedRoute.snapshot.queryParams.id
    const asset = this.activatedRoute.snapshot.queryParams.asset
    this.subscription.add(
      this.podsService.getAssetByPsaId(asset, id, this.screenName).subscribe(
        data => {
          if (Object.keys(data).length !== 0 && data.uuid) {
            this.itemName = this.moduleName === 'configurations' ?  data.name  : `${data.firstName} ${data.lastName}`
            this.companyId = data.companyUuid ? data.companyUuid : data.company.companyUuid
            this.itemId = data.uuid
            if (data.permission && data.permission.length !== 0) {
              if (!data.permission.includes('put')) this.hasEditPermissions = false
            } else if (data.permission && data.permission.length === 0) {
              this.isError = true
            }
            this.isLoading = true
          } else {
            this.isError = true
          }
        }, error => {
          this.isLoading = true
        }))

  }
  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

}
