<div *ngFor="let linkedItem of linedItems" class="pt-2 pb-2 position-relative" fxLayoutAlign="space-between center">
  <div class="pl-2 border-bottom w-100" *ngIf="linkedItem.relatedAssetId !== ''">
    <itb-text [tooltipText]="linkedItem.relatedAssetName" [className]="'text-default text-color-primary pointer'" [text]="linkedItem.relatedAssetName" (click)="onItemViewInNewTab(linkedItem)"></itb-text>
  </div>
  <div class="pl-2" *ngIf="linkedItem.relatedAssetId === ''">
    <itb-text [className]="'text-default'" [text]="linkedItem.relatedAsset"></itb-text>
  </div>
  <itb-dropdown class="position-absolute right-0" [buttonType]="'iconBtn'" [className]="'edit-dots icon-black'" *ngIf="linkedItem.relatedAssetId !== '' && hasEditPermissions">
    <div class="rsb-list-dropdown">
      <button mat-menu-item (click)="onItemDelete(linkedItem)"> <span>Delete</span></button>
      <button mat-menu-item (click)="onItemViewInNewTab(linkedItem)"> <span>Open in new tab</span></button>
    </div>
  </itb-dropdown>
</div>

<div fxLayout="column" fxLayoutAlign="center center" class="no-data" *ngIf="linedItems && linedItems.length === 0">
  <span class="img-nodatafound"></span>
  <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
</div>
