<span class="red-not-dot" *ngIf="isRead">{{totalUnread}}</span>
<itb-dropdown [matTooltip]="'Notifications'" [buttonType]="'iconBtn'" [className]="'bell'" (click)="update()" >
<itb-heading *ngIf="alert && alert.length !=0" [className]="'heading dropdown-list-h'" [text]="'Notifications'" ></itb-heading>

<itb-heading *ngIf="alert.length === 0" [className]="'heading dropdown-list-h'" [text]="'No Notifications'" ></itb-heading>
  <div class="header-notification">
  <ul class="header-notification-content">
  <!-- <li class="no-noti">
      <itb-image [imageWidth]=100 [src]="'/images-library/empty-states/light/img_nodata.svg'"></itb-image>

  </li> -->
  <li class="no-noti" *ngIf="alert.length === 0">
    <itb-empty-state-image class="h-100 w-100" [imageClass]="'es-image img-nodata'"
     [headingClass]="'heading'" [headingText]="'No Data Found'"
     ></itb-empty-state-image>
</li>

  <li *ngFor="let i of alert">
    <div class="noti-c" *ngIf="!i.isRead" >
        <div class="action-buttons">
            <itb-image *ngIf="i.actionType === 'DELETE'" [src]="'images-library/icon-library/delete-r.svg'" [imageWidth]="22" ></itb-image>
            <itb-image *ngIf="i.actionType === 'POST'" [src]="'images-library/icon-library/add-doc.svg'" [imageWidth]="22" ></itb-image>
            <itb-image *ngIf="i.actionType === 'PUT'" [src]="'images-library/icon-library/edit.svg'" [imageWidth]="22" ></itb-image>
        </div>
        <div class="noti-cont" *ngIf="i.bulkCount">
          {{i.bulkCount}} <strong>{{i.assetName}} </strong> bulk
          <span *ngIf="i.actionType === 'POST'">Added</span>
          <span *ngIf="i.actionType === 'DELETE'">Deleted</span>
          <span *ngIf="i.actionType === 'PUT'">Updated</span>
          <span> by {{i.addedBy}} .</span>
          <small class="d-flex">{{convertDateToMoment(i.createdAt)}} - {{i.companyName}}</small>
        </div>
        <div class="noti-cont" *ngIf="!i.bulkCount">
            <span> {{i.assetName}}  <strong>{{i.recordName}} </strong> <span >was </span></span>
            <span *ngIf="i.actionType === 'PUT'">updated</span>
            <span *ngIf="i.actionType === 'POST'">added</span>
            <span *ngIf="i.actionType === 'DELETE'">deleted</span>
            <span> by {{i.addedBy}}.</span>
            <small class="d-flex"> {{convertDateToMoment(i.createdAt)}} - {{i.companyName}}</small>
        </div>
    </div>
    <div class="noti-c" *ngIf="i.isRead" >
        <div class="action-buttons">
            <itb-image *ngIf="i.actionType === 'DELETE'" [src]="'images-library/icon-library/delete-r.svg'" [imageWidth]="22" ></itb-image>
            <itb-image *ngIf="i.actionType === 'POST'" [src]="'images-library/icon-library/add-doc.svg'" [imageWidth]="22" ></itb-image>
            <itb-image *ngIf="i.actionType === 'PUT'" [src]="'images-library/icon-library/edit.svg'" [imageWidth]="22" ></itb-image>
        </div>
        <div class="noti-cont" *ngIf="i.bulkCount">
          {{i.bulkCount}} <strong>{{i.assetName}} </strong> bulk
          <span *ngIf="i.actionType === 'POST'">Added</span>
          <span *ngIf="i.actionType === 'DELETE'">Deleted</span>
          <span *ngIf="i.actionType === 'PUT'">Updated</span>
          <span> by {{i.addedBy}} .</span>
          <small class="d-flex">{{convertDateToMoment(i.createdAt)}} - {{i.companyName}}</small>
        </div>
        <div class="noti-cont" *ngIf="!i.bulkCount">
            {{i.assetName}} <strong>{{i.recordName}}</strong> <span> was </span>
            <span *ngIf="i.actionType === 'POST'">added</span>
            <span *ngIf="i.actionType === 'DELETE'">deleted</span>
            <span *ngIf="i.actionType === 'PUT'">updated</span>
            <span> by {{i.addedBy}} .</span>
            <small class="d-flex">{{convertDateToMoment(i.createdAt)}} - {{i.companyName}}</small>
        </div>
    </div>
  </li>
</ul>
  </div>
</itb-dropdown>

