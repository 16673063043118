<div *ngIf="params.data && params.propertyName && !params.showPopOver">
    <a *ngIf="showQueryParams" [routerLink]="baseURL" class="ag-cell-value name-field-table" (click)="nameHandler($event)"
      [queryParams]="{type: qureyParams}" [target]="params.target || '_self'">{{ params.value || '_blank' }}
    </a>
    <a *ngIf="!showQueryParams" [routerLink]="baseURL" class="ag-cell-value name-field-table"
      (click)="nameHandler($event)" [target]="params.target || '_self'">{{ params.value || '_blank' }}
    </a>

  </div>

  <div *ngIf="params.data && !params.propertyName">
    <a [target]="params.target || '_self'" [href]="baseURL" class="ag-cell-value name-field-table"
      (click)="nameHandler($event)">{{ params.value }}</a>
  </div>
  <div *ngIf="params.data && params.propertyName && params.showPopOver">
    <a [target]="'_blank'" [href]="baseURL" class="ag-cell-value name-field-table"
      (click)="nameHandler($event)">{{ params.value }}</a>
  </div>