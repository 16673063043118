export const BUTTONS = {
  EVENTS: {
    EDIT: 'btnEdit',
    PRINT: 'btnPrint',
    PASTE: 'btnPaste',
    COPY: 'btnCopy',
    DELETE: 'btnDelete',
    UPDATE: 'btnUpdate',
    BACK: 'btnBack',
    SAVE: 'btnSave',
    NEXT: 'btnNext',
    SAVE_AND_CLOSE: 'Save & Close',
    SAVE_AND_DRAFT: 'Save As Draft',
    ADD_NETWORK_DETAILS: 'btnAddNetworkDetails',
    ADD_DRIVE_DETAILS: 'btnAddDriveDetails',
    ADD_WARRANTY_DETAILS: 'btnAddWarrantyDetails',
    ADD_TYPE: 'btnAddType',
    ADD_SITE: 'btnAddSite',
    ADD_CONTACT: 'btnAddContact',
    ADD_STATUS: 'btnAddStatus',
    ADD_MANUFACTURER: 'btnAddManufacturer',
    ADD_NEW: 'btnAddNew',
    REFRESH: 'btnRefresh',
    CANCEL: 'btnCancel',
    CLOSE: 'btnClose',
    CONFIRM: 'btnConfirm',
  },
  TEXTS: {
    EDIT: 'edit',
    PRINT: 'print',
    PASTE: 'paste',
    COPY: 'copy',
    NEXT: 'next',
    DELETE: 'delete',
    UPDATE: 'update',
    BACK: 'back',
    ADD_NEW: 'add new',
    REFRESH: 'refresh',
    SAVE: 'save',
    CANCEL: 'cancel',
    CLOSE: 'close',
    CONFIRM: 'confirm',
  },
  CLASSES: {
    SUCCESS: 'btn btn-success',
    PRIMARY: 'btn btn-primary',
    DANGER: 'btn btn-danger',
    DEFAULT: 'btn btn-default',
    SAVE_AND_CLOSE: 'save-close',
    SAVE_AND_DRAFT: 'save-draft',
    TICK: 'tick',
    CROSS: 'cross',
  },
  COLORS: {
    PRIMARY: 'primary',
    DARK: 'dark',
    DANGER: 'danger',
  },
}
export const BREAD_CRUMBS = {
  NAMES: {
    HEART: 'heart',
    UNHEART: 'unheart',
    TAGS: 'tags',
    ASYNC: 'async',
    SYNC: 'sync',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
  },
  TOOL_TIPS: {
    HEART: 'Mark as Favorite',
    UNHEART: 'Unmark as Favorite',
    TAGS: 'Tags',
    ASYNC: 'Disable Sync',
    SYNC: 'Enable Sync',
    ACTIVE: 'Mark Active',
    INACTIVE: 'Mark Inactive',
  },
  CLASSES: {
    HEART: 'heart-filled',
    UNHEART: 'heart-line',
    TAGS: 'tag',
    ASYNC: 'sync-disable',
    SYNC: 'sync',
    ACTIVE: 'file-active',
    INACTIVE: 'file-disabled',
  },
}
export const ICONS = {
  BUTTON_ADD_NEW: 'add-doc',
  BUTTON_REFRESH: 'refresh-list',
  BUTTON_DELETE: 'delete-r',
  EDIT: BUTTONS.TEXTS.EDIT,
  PRINT: BUTTONS.TEXTS.PRINT,
  COPY: BUTTONS.TEXTS.COPY,
  PASTE: BUTTONS.TEXTS.PASTE,
  DELETE: BUTTONS.TEXTS.DELETE,
  BACK: BUTTONS.TEXTS.BACK,
  NEXT: BUTTONS.TEXTS.NEXT,
  CLOSE: 'cross',
  SAVE: 'save',
  TICK: 'tick',
  LOCK: 'lock-s',
}
export const DROP_UP_ITEMS = {
  SAVE_AND_CLOSE: {
    className: 'save-close',
    label: BUTTONS.EVENTS.SAVE_AND_CLOSE,
  },
  SAVE_AND_DRAFT: {
    className: 'save-draft',
    label: BUTTONS.EVENTS.SAVE_AND_DRAFT,
  },
}
export const AG_DATA_GRID = {
  MODEL_TYPE_CLIENT: 'clientSide',
  MODEL_TYPE_SERVER: 'serverSide',
}

export const ICONS_CONSTANTS = {
  COPY: 'copy',
  PASS_GENERATE: 'pass-generate',
  EYE_DA: 'eye-da',
  EYE: 'eye',
}

export const PASSWORD_GENERATOR_STRING = {
  LETTERS_STRING: 'abcdefghijklmnopqrstuvwxyz',
  NUMERIC: '0123456789',
  PUNCTUATION: '!@#$%^&*()_+~`|}{[]:;?><,./-=',
}

// tslint:disable-next-line: max-line-length
export const URL_VALIDATION_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\%\*\+,;=.]+$/

export const PREPARED_BUTTONS = {
  CANCEL_BUTTON: {
    text: BUTTONS.TEXTS.CANCEL,
    isLoading: false,
    className: BUTTONS.CLASSES.DEFAULT,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.CANCEL,
    iconClassName: ICONS.CLOSE,
  },
  CLOSE_BUTTON: {
    text: BUTTONS.TEXTS.CLOSE,
    isLoading: false,
    className: BUTTONS.CLASSES.DEFAULT,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.CLOSE,
    iconClassName: ICONS.CLOSE,
  },
  BACK_BUTTON: {
    text: BUTTONS.TEXTS.BACK,
    isLoading: false,
    className: BUTTONS.CLASSES.DEFAULT,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.BACK,
    iconClassName: ICONS.BACK,
  },
  NEXT_BUTTON: {
    text: BUTTONS.TEXTS.NEXT,
    isLoading: false,
    className: BUTTONS.CLASSES.DEFAULT,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.NEXT,
    iconClassName: ICONS.NEXT,
  },
  CONFIRM_BUTTON: {
    text: BUTTONS.TEXTS.CONFIRM,
    isLoading: false,
    className: BUTTONS.CLASSES.PRIMARY,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.CONFIRM,
    iconClassName: ICONS.TICK,
  },
  DELETE_BUTTON: {
    text: BUTTONS.TEXTS.DELETE,
    isLoading: false,
    className: BUTTONS.CLASSES.DANGER,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.DELETE,
    iconClassName: ICONS.DELETE,
  },
  EDIT_BUTTON: {
    text: BUTTONS.TEXTS.EDIT,
    isLoading: false,
    className: BUTTONS.CLASSES.PRIMARY,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.EDIT,
    iconClassName: ICONS.EDIT,
  },
  SAVE_BUTTON: {
    text: BUTTONS.TEXTS.SAVE,
    isLoading: false,
    className: BUTTONS.CLASSES.PRIMARY,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.SAVE,
    iconClassName: ICONS.SAVE,
  },
  PRINT_BUTTON: {
    text: BUTTONS.TEXTS.PRINT,
    isLoading: false,
    className: BUTTONS.CLASSES.SUCCESS,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.PRINT,
    iconClassName: ICONS.PRINT,
  },
  COPY_BUTTON: {
    text: BUTTONS.TEXTS.COPY,
    isLoading: false,
    className: BUTTONS.CLASSES.SUCCESS,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.COPY,
    iconClassName: ICONS.COPY,
  },
  PASTE_BUTTON: {
    text: BUTTONS.TEXTS.PASTE,
    isLoading: false,
    className: BUTTONS.CLASSES.SUCCESS,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.PASTE,
    iconClassName: ICONS.PASTE,
  },
  REFRESH_BUTTON: {
    text: BUTTONS.TEXTS.REFRESH,
    isLoading: false,
    className: BUTTONS.CLASSES.SUCCESS,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.REFRESH,
    iconClassName: ICONS.BUTTON_REFRESH,
  },
  ADD_NEW_BUTTON: {
    text: BUTTONS.TEXTS.ADD_NEW,
    isLoading: false,
    className: BUTTONS.CLASSES.PRIMARY,
    isDisabled: false,
    buttonName: BUTTONS.EVENTS.ADD_NEW,
    iconClassName: ICONS.BUTTON_ADD_NEW,
  },
}

export const INPUT_FIELD_ICONS_TOOLTIPS = {
  COPY: 'Copy',
  COPY_PASSWORD: 'Copy Password',
  GENERATE_PASSWORD: 'Generate Password',
  HIDE: 'Hide Password',
  SHOW: 'Show Password',
}

export const PREPARED_ICONS = {
  TAG_ICON: {
    className: BREAD_CRUMBS.CLASSES.TAGS,
    tooltipText: BREAD_CRUMBS.TOOL_TIPS.TAGS,
    name: BREAD_CRUMBS.NAMES.TAGS,
  },
  ACTIVE_ICON: {
    className: BREAD_CRUMBS.CLASSES.ACTIVE,
    tooltipText: BREAD_CRUMBS.TOOL_TIPS.INACTIVE,
    name: BREAD_CRUMBS.NAMES.ACTIVE,
  },
  INACTIVE_ICON: {
    className: BREAD_CRUMBS.CLASSES.INACTIVE,
    tooltipText: BREAD_CRUMBS.TOOL_TIPS.ACTIVE,
    name: BREAD_CRUMBS.NAMES.INACTIVE,
  },
  SYNC_ICON: {
    className: BREAD_CRUMBS.CLASSES.SYNC,
    tooltipText: BREAD_CRUMBS.TOOL_TIPS.ASYNC,
    name: BREAD_CRUMBS.NAMES.SYNC,
  },
  ASYNC_ICON: {
    className: BREAD_CRUMBS.CLASSES.ASYNC,
    tooltipText: BREAD_CRUMBS.TOOL_TIPS.SYNC,
    name: BREAD_CRUMBS.NAMES.ASYNC,
  },
  SHOW_PASSWORD: {
    className: ICONS_CONSTANTS.EYE,
    tooltipText: INPUT_FIELD_ICONS_TOOLTIPS.SHOW,
    name: ICONS_CONSTANTS.EYE,
  },
  HIDE_PASSWORD: {
    className: ICONS_CONSTANTS.EYE_DA,
    tooltipText: INPUT_FIELD_ICONS_TOOLTIPS.HIDE,
    name: ICONS_CONSTANTS.EYE_DA,
  },
  GENERATE_PASSWORD: {
    className: ICONS_CONSTANTS.PASS_GENERATE,
    tooltipText: INPUT_FIELD_ICONS_TOOLTIPS.GENERATE_PASSWORD,
    name: ICONS_CONSTANTS.PASS_GENERATE,
  },
  COPY: {
    className: ICONS_CONSTANTS.COPY,
    tooltipText: INPUT_FIELD_ICONS_TOOLTIPS.COPY,
    name: ICONS_CONSTANTS.COPY,
  },
  COPY_PASSWORD: {
    className: ICONS_CONSTANTS.COPY,
    tooltipText: INPUT_FIELD_ICONS_TOOLTIPS.COPY_PASSWORD,
    name: ICONS_CONSTANTS.COPY,
  },
}

export const IMAGE_MAX_SIZE = 100000000
export const IMAGE_MAX_QUANTITY = 20

export const MODAL_CONSTANTS = {
  MODAL_WIDTH: '600px',

  // Modal Texts
  UNSAVED_CHANGES_MODAL_HEADING: 'UNSAVED DATA',
  UNSAVED_CHANGES_MODAL_TEXT: 'Do you want to navigate away without saving changes?',
  SAVE_GRID_SETTINGS: 'Save Grid Settings',
}

export const NOTIFICATION = {
  SUCCESS: {
    COPIED: {
      MODE: 'success',
      MESSAGE: 'Copied',
      BODY: 'Copied Successfully',
      MAIN_MESSAGE: 'DONE',
    },
    PASTED: {
      MODE: 'success',
      MESSAGE: 'Saved',
      BODY: 'Pasted Successfully',
      MAIN_MESSAGE: 'DONE',
    },
    DELETE: {
      MODE: 'success',
      MESSAGE: 'Deleted',
      BODY: 'Deleted Successfully',
      MAIN_MESSAGE: 'DONE',
    },
    SAVED: {
      MODE: 'success',
      MESSAGE: 'Saved',
      BODY: 'Saved Successfully',
      MAIN_MESSAGE: 'SAVED',
    },
    BULK_DELETE: {
      MODE: 'success',
      MESSAGE: 'Deleted',
      BODY: 'Bulk Delete Successfull',
      MAIN_MESSAGE: 'DONE',
    },
  },
  FAIL: {
    COPIED: {
      MODE: 'fail',
      MESSAGE: 'Saved',
      BODY: 'Copied Failed',
      MAIN_MESSAGE: 'FAILED',
    },
    PASTED: {
      MODE: 'fail',
      MESSAGE: 'Saved',
      BODY: 'Pasted Failed',
      MAIN_MESSAGE: 'FAILED',
    },
    DELETE: {
      MODE: 'fail',
      MESSAGE: 'Deleted',
      BODY: 'Deleted Failed',
      MAIN_MESSAGE: 'FAILED',
    },
    SAVED: {
      MODE: 'fail',
      MESSAGE: 'Saved',
      BODY: 'Saved Failed',
      MAIN_MESSAGE: 'FAILED',
    },
    BULK_DELETE: {
      MODE: 'fail',
      MESSAGE: 'Deleted',
      BODY: 'Bulk Delete Failed',
      MAIN_MESSAGE: 'FAILED',
    },
  },
}
