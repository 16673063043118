
import { ICompany } from '../../modules/locations/interfaces/company'
import * as fromCommunication from '../actions/communication.actions'
import { Menu } from '../interfaces/menu'

export interface UserMenuState {
  data: Menu[]
  selectedMenu
  selectedCompany: ICompany
  error?: boolean
}

export const currentUserMenuState: UserMenuState = {
  data: [],
  selectedMenu: {},
  selectedCompany: {},
  error: false,
}

export function menuReducer(
  state: UserMenuState = currentUserMenuState,
  action: fromCommunication.CommunicationActions
): UserMenuState {

  switch (action.type) {
    case fromCommunication.ECommunicationActions.LoadUserMenu: {
      return {
        ...state,
        data: action.payload.User_Menu,
        error: false,
      }
    }
    case fromCommunication.ECommunicationActions.ChangeUserMenu:
      return {
        ...state,
        error: false,
      }

    case fromCommunication.ECommunicationActions.UserMenuError:

      return {
        ...state,
        error: true,
      }

    case fromCommunication.ECommunicationActions.SetCurrentMenu:
      const currentSelectedMenu = action.payload
      return {
        ...state,
        selectedMenu: currentSelectedMenu,
      }

    case fromCommunication.ECommunicationActions.SetCurrentCompany:
      const currentSelectedCompany = action.payload
      return {
        ...state,
        selectedCompany: currentSelectedCompany,
      }
    default:
      return state
  }
}
