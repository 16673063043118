import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'

import { SharedDataService } from '../../../src/app/modules/core/services/shared-data.service'
@Injectable()
export class CanAddCompanyGuard implements CanActivate {
  categoryList = []
  constructor(public router: Router, public sharedDataService: SharedDataService) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    let permissions = []
    const categoriesList = this.sharedDataService.geCategories()
    const userType = JSON.parse(localStorage.getItem('user')).userType
    this.categoryList = categoriesList
    if (this.categoryList && userType !== 'external') {
      this.categoryList.forEach(category => {
        if (category.name === 'Companies') {
          permissions = category.permissions ? category.permissions : ['get', 'post', 'put', 'delete']
        }
      })
    } else {
      if (this.categoryList && this.categoryList.length === 0) {
        this.router.navigateByUrl('app/error').catch(err => {
          throw err
        })
      } else {
        this.categoryList.forEach(category => {
          if (category.name === 'Companies') {
            permissions =  category.permissions ? category.permissions : ['get', 'post', 'put', 'delete']
          }
        })
      }
    }
    if (permissions.indexOf('post') > -1)
          return true
    this.router.navigateByUrl('app/error').catch(err => {
      throw err
    })

  }
}
