<itb-heading mat-dialog-title [className]="'modal-h'" [text]="'TERMS OF SERVICE'"></itb-heading>
<div>
  <form [formGroup]="myForm">
<mat-dialog-content class="terms-service-main">
    <div class="terms-service-content fancyscroller" id="termsScroller">
        <p>The following terms and conditions govern all use of the <strong>ITBOOST</strong> website and Software-as-a-Service products (“Service”) and any assigned account (“Account”) used in connection with the Service and includes, but is not limited to, any new features or tools which are added to the current Service, or any related content, services, or products provided by <strong>ITBOOST</strong> Inc. (“<strong>ITBOOST</strong>”).These terms may be modified from time to time and your use of the Service shall be governed by the latest applicable terms and conditions contained here.
        </p>
        <p>The Service is owned and operated by <strong>ITBOOST</strong> and is offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, the <strong>ITBOOST</strong> Privacy Policy) and procedures that may be published by <strong>ITBOOST</strong> from time to time through the <strong>ITBOOST</strong> website (www.<strong>ITBOOST</strong>.com) or through any of the <strong>ITBOOST</strong> subdomains (collectively, the “Site”) (collectively, the “Agreement”). These terms and conditions are also replicated on the <strong>ITBOOST</strong> website (www.<strong>ITBOOST</strong>.com).</p>
        <p>Please read this Agreement carefully before accessing or using the Service. By accessing or using any part of the Service, you agree to become bound by the terms and conditions of this Agreement. If you do not agree to all the terms and conditions of this agreement, then you may not access the Service. If these terms and conditions are considered an offer by <strong>ITBOOST</strong>, acceptance is expressly limited to these terms. The Service is available only to individuals who are at least 18 years old or the applicable state or provincial age of majority thereby allowing you to legally enter into this Agreement.</p>
        <p>In this Agreement, our customers and any of their staff members whom are given access to the Service are herein referred to as “Subscribers”, and our customers’ customers who use the Service (e.g. to login and manage IT documentation) are referred to as “Customers”.&nbsp;</p>
        <h5 class="labelFontColor bold">1. Term</h5>
        <p>The term of this Agreement begins on the date your Account is activated and continues on a month-to-month basis until either party terminates this Agreement pursuant to the terms hereof.</p>
        <p> For contracts other than month to month agreements, notwithstanding anything to the contrary in any other agreement, the Customer acknowledges and agrees that the initial term of the subscription (the “Initial Term”) shall start and end on the dates noted in the Manage Subscription Page. Pricing during the Initial Term shall be in accordance with the table in the Manage Subscription Page (collectively, the “Total Price”). Upon expiration of the Initial Term, the subscription will automatically renew for successive renewal terms of agreement at <strong>ITBOOST’s</strong> then current pricing rates, unless otherwise agreed. Either party may terminate the subscription effective upon the expiration of the then current term by notifying the other party in writing at least thirty (30) but not more than sixty (60) days prior to such expiration.
        </p>
        <h5 class="labelFontColor bold">2. Your Account</h5>
        <ul>
            <li>You are fully responsible for all activities that occur under the Account and any other actions taken in connection with your Account by your Customers. You must not publish content in your Account in a misleading or unlawful manner, including in a manner intended to trade on the name or reputation of others, and <strong>ITBOOST</strong> may change or remove any description or keyword that it considers inappropriate or unlawful, or otherwise likely to cause <strong>ITBOOST</strong> liability.</li>
            <li>You must immediately notify <strong>ITBOOST</strong> of any fraudulent or unauthorized uses of <strong>ITBOOST</strong>, your Account, or any other breaches of security. <strong>ITBOOST</strong> will not be liable for any acts or omissions by you, the Subscriber, your Customers, or any other users of your Account, including any damages of any kind incurred as a result of such acts or omissions.</li>
            <li>You must provide your full legal name, current address, valid email address, and any other information necessary to complete the <strong>ITBOOST</strong> signup process.</li>
            <li>Accounts registered in an automated fashion (e.g. by “bots”) are not permitted.</li>
            <li>Your login may only be used by one person. Sharing a single user login between multiple people is not permitted. You may create separate user logins for multiple people, provided your Account plan permits.</li>
            <li>You are responsible for keeping your password (and any other users’ passwords under your Account) secure. <strong>ITBOOST</strong> cannot and will not be held liable for any loss or damages from your failure to maintain the security of your Account or passwords.</li>
            <li>You must not upload or transmit any form of viruses or malicious code.</li>
            <li>We have the right to suspend, restrict, or terminate your Account, without any notice to you, if we suspect any form of fraudulent or malicious activity.</li>
            <li>You must not use the Service for any illegal or unauthorized purpose and must not violate any laws in your jurisdiction.</li>
            <li>You are responsible for all activity on your Account, and for all charges incurred by your Account.</li>
        </ul>
        <h5 class="labelFontColor bold">3. Payment of Fees, Refunds, and User Changes</h5>
        <ul>
            <li>Credit Card – A valid credit card is required to use the Service.</li>
            <li>Monthly Fees – The monthly fee is based on the plan you selected via the Pricing page, unless a valid promotional code entered during the signup process permits otherwise.</li>
            <li>User Changes – You may change the number of users at any time. Added users are billed on a pro-rated basis on the next month’s statement Removed users will not be reflected until the next billing period.</li>
            <li>Refunds – NO REFUNDS WILL BE GIVEN UNLESS EXPRESSLY AUTHORIZED BY <strong>ITBOOST</strong>. </li>
            <li>Billing – Currency billing for the Service is in US Dollars (USD).</li>
            <li>Taxes, levies, and duties – All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you shall be responsible for payment of all such taxes, levies, or duties.</li>
            <li>Failure to Make Payment – In the event payment is not received for the Service due to a declined attempt to charge your credit card, expired credit card, or otherwise, and payment is not received within 3 days of the initial attempt, your Account will be suspended. If a suspended Account is not reactivated and the outstanding Account balance paid in full within three (3) months, it will be cancelled and all Account data may be permanently deleted.</li>
            <li>Credit Card Security – All credit card transactions are processed by a PCI-DSS Level 1 compliant 3rd party.Credit card data is not stored in the Service or retained by <strong>ITBOOST</strong> at any time.<strong>ITBOOST</strong> reserves the right at any time, and from time to time, to change providers at its discretion.</li>
        </ul>
        <h5 class="labelFontColor bold">4. Support</h5>
        <p>The Service includes access to email support. “Email support” means the ability to make requests for technical support assistance by email or in-app at any time (with reasonable efforts by <strong>ITBOOST</strong> to respond as per your plan criteria and or within two business days) concerning the use of the Service.&nbsp;</p>
        <h5 class="labelFontColor bold">5. Copyright and Subscriber Data</h5>
        <ul>
            <li>Content – The Content published by <strong>ITBOOST</strong> through the Service, this Site, including, but not limited to, HTML/CSS, JavaScript, visual design elements, concepts, and copy are copyright © <strong>ITBOOST</strong>, Inc. All rights reserved. You may not duplicate, copy, or reuse any portion of the Content without express written permission from <strong>ITBOOST</strong> management. All Content uploaded or published by you via the Service must comply with copyright laws in your jurisdiction.</li>
            <li>All Service and their entire contents, features, and functionality (including, but not limited to, all information, software, programs, source code, patches, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by <strong>ITBOOST</strong>, its licensors, or other providers of such material and are protected by applicable United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</li>
            <li>These Terms of Use permit you to use the Service for your personal and/or commercial use, as applicable.  </li>
            <li>Copyright –  Except for our rights in the software, we claim no copyright or intellectual property over the Content you provide to the Service. Content you upload to the Service remain yours. However, by setting any content to be publicly available, you agree to allow others to view and share your Content.</li>
            <li>Subscriber Data – Subscriber data, pertaining to documentation or any derivatives thereof stored in the Service (the “Subscriber Data”) shall be and remain the sole and exclusive property of you, the Subscriber. You are able to export your Subscriber Data at any time from inside the application. <strong>ITBOOST</strong> is provided a license to Subscriber Data hereunder for the sole and exclusive purpose of providing the Service, including a license to store, record, transmit, maintain, and display Customer Data as necessary for the provisioning of the Service. Other than its security obligations below (Security of Subscriber Data), <strong>ITBOOST</strong> assumes no responsibility or liability for Subscriber Data, and the Subscriber shall be solely responsible for Subscriber and the consequences of using, disclosing, storing, or transmitting it. The Service also allows Subscribers to integrate data from several external systems into their Account. Only the Subscriber and system being integrated with is responsible for this data.</li>
            <li>Sensitive Subscriber Data - Unless the Subscriber has entered into a written agreement with <strong>ITBOOST</strong> to the contrary, the Subscriber acknowledges that we are not a Business Associate or subcontractor (as defined in HIPAA). The Subscriber must not submit, collect or use any “personal health information” as defined in 45 CFR §160.103 (“PHI”) of US Law, with or to the Service. The Subscriber agrees that we have no liability for PHI received from the Subscriber, notwithstanding anything to the contrary herein. The Subscriber also acknowledges that the Service is not designed to store credit card information as defined in PCI-DSS. <strong>ITBOOST</strong> uses external third-parties for the purposes of credit card processing and storage relating to purchasing and renewing of the Subscription. This information is not considered Subscriber Data and not covered by this clause.</li>
            <li>Access to Subscriber Data – The operation of the Service requires that some employees have access to the systems which store and process Subscriber Data. For example, in order to diagnose a problem a Subscriber is having with the Service, we may need to access Subscriber Data. These employees are prohibited from using these permissions to view Subscriber Data unless it is necessary to do so. We have technical controls and audit policies in place to ensure that any access to Subscriber Data is appropriate.</li>
            <li>Security of Subscriber Data – <strong>ITBOOST</strong> is built as a multi-tenant, Software-as-a-Service platform. Individual subscriber data is secured between subscribers. Sharing of information between subscribers is possible within the platform through granting external access, but this function can only be enabled by the subscriber wishing to share data, not another subscriber or <strong>ITBOOST</strong>.&nbsp;</li>
            <li>The company name, <strong>ITBOOST</strong>, and similar terms incorporating the <strong>ITBOOST</strong> name, the company logos, and all related names, logos, product and service names, designs, trademarks, trade dress, service marks, and slogans are trademarks of <strong>ITBOOST</strong> or its affiliates or licensors. You must not use such marks without the prior written permission of the company. All other names, logos, product and service names, designs, trademarks, trade dress, service marks, and slogans on Service are the trademarks of their respective owners.</li>
        </ul>
        <h5 class="labelFontColor bold">6. Cancellation and Termination</h5>
        <ul>
            <li>Termination – You may terminate this Agreement at any time by ceasing all use of the Service and notifying <strong>ITBOOST</strong> through your Account seven (7) days prior to your month to month subscription expiration. For yearly plans, either party may terminate the subscription effective upon the expiration of the then current term by notifying the other party in writing at least thirty (30) but not more than sixty (60) days prior to such expiration. <strong>ITBOOST</strong> may terminate this Agreement and your access to all or any part of the Service at any time, with or without cause, with or without notice, effective immediately, if it believes, in its sole judgement, that you have breached or may breach any term or condition of this Agreement. <strong>ITBOOST</strong> can terminate the Service immediately as part of a general shut down of our Service. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</li>
            <li>Final Payment for Prior Service Rendered – In the event of termination of your Account, you will be charged for any outstanding amounts owing at the end of your billing cycle, including, without limitation, Transaction Fees incurred during your last month of service.In the event this Agreement is terminated prior to the end of the Initial Term for any reason, the Customer shall remain responsible for the Total Price and any unpaid portion of the Total Price shall immediately become due and payable as of such date. </li>
            <li>Customer agrees that it shall reimburse <strong>ITBOOST</strong> for any costs incurred by <strong>ITBOOST</strong> for collection of any costs due hereunder (including attorney’s fees) and agrees to indemnify and hold harmless <strong>ITBOOST</strong> from any such amounts.</li>
            <li>Removal of Subscriber Data – In the event your Account is terminated, all Subscriber Data may be permanently deleted after 14 days. Prior to Subscriber Data being deleted, provisions can be made to allow access to an export of your Subscriber Data by request via email to support@ITBOOST.com.</li>
        </ul>
        <h5 class="labelFontColor bold">7. Changes to the Service, Pricing, or Billing</h5>
        <ul>
            <li>Changes to the Service – <strong>ITBOOST</strong> reserves the right at any time, and from time to time to modify, discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.</li>
            <li>Changes to Billing or Pricing – <strong>ITBOOST</strong> reserves the right, at any time, and from time to time, to change its prices and billing methods for the Service, subject to 30 days’ notice from us or unless otherwise noted. Such notice may be provided at any time by posting on the <strong>ITBOOST</strong> Site (www.<strong>ITBOOST</strong>.com), the Service itself, or by email to you, the Subscriber.</li>
            <li><strong>ITBOOST</strong> shall not be liable to you or any third party for any modification, price change, suspension, or discontinuation of the Service.</li>
        </ul>
        <h5 class="labelFontColor bold">8. Intellectual Property</h5>
        <p>This Agreement does not transfer from <strong>ITBOOST</strong> to you any <strong>ITBOOST</strong> or third party intellectual property, and all right, title and interest in and to such property will remain (as between the parties) solely with <strong>ITBOOST</strong>. <strong>ITBOOST</strong>, <strong>ITBOOST</strong>, Inc., the <strong>ITBOOST</strong> logo, and all other trademarks, service marks, graphics and logos used in connection with <strong>ITBOOST</strong>, or the Service are trademarks or registered trademarks of <strong>ITBOOST</strong> or <strong>ITBOOST</strong>’ licensors. Other trademarks, Service marks, graphics and logos used in connection with the Service may be the trademarks of other third parties. Your use of the Service grants you no right or license to reproduce or otherwise use any <strong>ITBOOST</strong> or third-party trademarks.</p>
        <h5 class="labelFontColor bold">9. Changes to the Agreement</h5>
        <p><strong>ITBOOST</strong> reserves the right, at its sole discretion, to modify or replace any part of this Agreement or the Service. It is your responsibility to check this Agreement periodically for changes. If you would like to be directly notified in the event of a change to the Agreement, you may request so by emailing hello@<strong>ITBOOST</strong>.com. Your continued use of or access to the Service following the posting of any changes to this Agreement constitutes acceptance of those changes. <strong>ITBOOST</strong> may also, in the future, offer new services and/or features through the Service (including, the release of new tools and resources). Such new features and/or services shall be subject to the terms and conditions of this Agreement.</p>
        <h5 class="labelFontColor bold">10. General Representation and Warranty</h5>
        <p>You represent and warrant that (i) your use of the Service will be in strict accordance with the <strong>ITBOOST</strong> Privacy Policy, with this Agreement and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from United States or the country in which you reside) and (ii) your use of the Service will not infringe or misappropriate the intellectual property rights of any third party.</p>
        <h5 class="labelFontColor bold">11. Service Availability &amp; Maintenance</h5>
        <ul>
            <li><strong>ITBOOST</strong> makes every effort to ensure the Service is available at all times. However, there will be occasional periods of downtime necessary to perform essential system upgrades and maintenance. We will attempt to provide twelve (12) hours of notice for scheduled downtime, but in some cases, downtime may be unscheduled or beyond our control.</li>
            <li>There may also be unforeseen incidents that cause the Service to go down for a period of time that are beyond <strong>ITBOOST</strong>’s control. We will work to remediate any Service issues as quickly as possible.</li>
            <li>Certain key Service processes may depend on 3rd party systems. We are unable to guarantee the availability of 3rd party systems (e.g. for synchronization functions) and are in no way responsible for any outages.</li>
        </ul>
        <h5 class="labelFontColor bold">12. Disclaimer of Warranties</h5>
        <p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the Internet or from <strong>ITBOOST</strong> will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. To the fullest extent provided by law, we will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses, malware, ransomware, or other technologically harmful material that may infect your computer or mobile equipment, computer or mobile programs, data, or other proprietary material due to your use of any of the <strong>ITBOOST</strong> services or any services or items obtained through <strong>ITBOOST</strong> or to your downloading of any material posted on it, or on any <strong>ITBOOST</strong> services linked to it.</p>
        <p>Your use of the service, its content, and any services or items obtained through <strong>ITBOOST</strong> is at your own risk. The <strong>ITBOOST</strong> service its content, and any services or items obtained through <strong>ITBOOST</strong> are provided on an “as is”, “with all faults and defects” and “as available” basis, without any representations or warranties of any kind, either express or implied. Neither the company nor any person associated with the company makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of the <strong>ITBOOST</strong> service. Without limiting the foregoing, neither <strong>ITBOOST</strong> nor anyone associated with the company represents or warrants that the service, its content, or any services or items obtained through <strong>ITBOOST</strong> will be accurate, reliable, error-free, or uninterrupted, that defects will be corrected, that our site or the server that makes it available are free of viruses, malware, ransomware or any other harmful components, or the service or any services or items obtained through <strong>ITBOOST</strong> will otherwise meet your needs or expectations.</p>
        <p>To the fullest extent provided by law, <strong>ITBOOST</strong> hereby disclaims any and all warranties of any kind, whether express or implied, statutory, or otherwise, including but not limited to any warranties of merchantability, non-infringement, and fitness for particular purpose.</p>
        <p>The foregoing does not apply to any warranties that cannot be excluded or limited under applicable law.</p>
        <h5 class="labelFontColor bold">13. Limitation on Liability</h5>
        <p>To the fullest extent provided by law, in no event will the <strong>ITBOOST</strong> or its affiliates, or its and their owners, licensors, service providers, employees, agents, officers, attorneys, or directors be liable for damages of any kind, under any legal theory, arising out of or in connection with your use, or inability to use, the Service, any <strong>ITBOOST</strong> services linked to it, any content on the <strong>ITBOOST</strong> site or such other <strong>ITBOOST</strong> services, including any direct, indirect, special, incidental, consequential, or punitive damages, including, but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract, or otherwise, even if foreseeable.</p>
        <p>The foregoing does not affect any liability that cannot be excluded or limited under applicable law.</p>
        <h5 class="labelFontColor bold">14. Indemnification</h5>
        <p>You agree to defend, indemnify, and hold harmless the <strong>ITBOOST</strong> and its affiliates, licensors, and service providers, and its and their respective owners, officers, directors, employees, contractors, agents, attorneys, licensors, suppliers, successors, and assigns from and against any and all claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of, in connection with or relating to your violation of these Terms of Use or your use of the <strong>ITBOOST</strong> service, including, but not limited to, your content, any use of the <strong>ITBOOST</strong> services’ content, services, and products or your use of any information obtained from <strong>ITBOOST</strong>.</p>
        <h5 class="labelFontColor bold">15. Governing Law and Jurisdiction</h5>
        <p>All matters relating to the Service and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Texas without giving effect to any choice or conflict of law provision or rule (whether of the State of Texas or any other jurisdiction).</p>
        <p>Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Service shall be instituted exclusively in the federal courts of the United States or the courts of the State of Texas, in each case located in the City of Dallas and County of Dallas although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
        <h5 class="labelFontColor bold">16. Limitation on Time to File Claims</h5>
        <p>Any cause of action or claim you may have arising out of or relating to these terms of use or any of the <strong>ITBOOST</strong> service must be commenced within one (1) year after the cause of action accrues, otherwise, such cause of action or claim is permanently barred.</p>
        <h5 class="labelFontColor bold">17. Miscellaneous</h5>
        <p>This Agreement constitutes the entire agreement between <strong>ITBOOST</strong> and you concerning the subject matter hereof, and they may only be modified by a written amendment signed by an authorized executive of <strong>ITBOOST</strong>, or by the posting by <strong>ITBOOST</strong> of a revised version. Except to the extent of applicable law, if any, provides otherwise, this Agreement, any access to or use of the Service will be governed by the laws of the province of the State of Texas, excluding its conflict of law provisions, and the proper venue for any disputes arising out of or relating to any of the same will be the federal courts located in Dallas, Texas. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.</p>
        <p>Except where prohibited by applicable law, you waive any right you may have to commence or participate in any class action against <strong>ITBOOST</strong> related to any claim and, where applicable, you also agree to opt out of any class proceedings against <strong>ITBOOST</strong>. If you have a claim, you should give written notice to arbitrate at the address specified below. If we have a claim, we will give you notice to arbitrate at your address provided in your registration data. Arbitration will be conducted by one arbitrator pursuant to the commercial arbitration laws and rules.</p>
        <p>If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties’ original intent, and the remaining portions will remain in full force and effect. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof. You may assign your rights under this Agreement to any party that consents to, and agrees to be bound by, its terms and conditions; <strong>ITBOOST</strong> may assign its rights under this Agreement without condition. This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.</p>
        <p>Questions or Comments. Should you have any questions or comments regarding this agreement, please contact <strong>ITBOOST</strong> using the information below.</p>

        <p>
            <br>
            ITBOOST, Inc.<br><br>

            <a href="mailto:hello@ITBOOST.com">hello@itboost.com</a>
        </p>
    </div>
    <div class="agreed">
        <itb-checkbox  [label]="'I accept the Terms of Service'"  [isChecked]="isDisable" [parentForm]="myForm"
                       [formFieldName]="'checked'"
                       (onChangeHandler)="changeEvent($event)"></itb-checkbox>
        <itb-info-message [text]="'*Please scroll through the complete terms of service before clicking the continue button.'" [className]="'info-danger'"></itb-info-message>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">

  <itb-default-button [text]="'Continue'" [className]="'primary'"  (onClickHandler)="submit()" [isLoading]="isLoader"
                      [iconClassName]="'next'" [isDisabled]="!isDisable"></itb-default-button>
</mat-dialog-actions>
  </form>
</div>
