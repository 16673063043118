<div class="pods-widget-container" *ngIf="!isError">
  <app-company-pods-assetwarrenty [companyId]="companyId" *ngIf="isLoading && screenName === 'assets'"></app-company-pods-assetwarrenty>
  <app-company-pods-domain [companyId]="companyId" *ngIf="isLoading && screenName === 'expiry'"></app-company-pods-domain>
  <app-company-pods-fav-assets [companyId]="companyId" *ngIf="isLoading && screenName === 'favoriteAssets'"></app-company-pods-fav-assets>
  <app-company-pods-fav-documents [companyId]="companyId" *ngIf="isLoading && screenName === 'documents'"></app-company-pods-fav-documents>
</div>
<div  *ngIf="isError" class="no-data" fxLayout="column" fxLayoutAlign="center center">
  <span [ngClass]="{ 'img-nodatafound': true }"></span>
  <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
</div>
