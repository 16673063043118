<div  *ngIf="isLoading && !isError" class="pods-assets-container">
  <app-company-pods-attachment  *ngIf="screenName === 'attachment'"
                                [moduleName]="moduleName" [itemId]="itemId" [panelName]="rightSideBarPanels[5].name" [companyId]="companyId"
                                [panelObj]="rightSideBarPanels[5]" [hasEditPermissions]="hasEditPermissions"></app-company-pods-attachment>
<app-company-pods-linkend-items *ngIf="screenName === 'linkedItem'"
                                [switcherItem]="viewSwitcher.linkedItems" [moduleName]="moduleName"
                                [itemId]="itemId" [itemName]="itemName" [companyId]="companyId"
                                [panelName]="rightSideBarPanels[3].name" [templateName]="templateName"
                                [panelObj]="rightSideBarPanels[3]" [hasEditPermissions]="hasEditPermissions"></app-company-pods-linkend-items>
<app-company-pods-notes *ngIf="screenName === 'ITBNotes'"
                        [switcherItem]="viewSwitcher.notes" [moduleName]="moduleName" [itemId]="itemId"
                        [panelName]="rightSideBarPanels[2].name"
                        [panelObj]="rightSideBarPanels[2]" [hasEditPermissions]="hasEditPermissions"></app-company-pods-notes>
</div>

<div *ngIf="isError" class="no-data" fxLayout="column" fxLayoutAlign="center center">
  <span [ngClass]="{ 'img-nodatafound': true }"></span>
  <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
</div>
