import { Injectable } from '@angular/core'
import { ICompaniesList } from '@core/interfaces/companies'
import { IActivity, ICategoryList } from '@core/interfaces/header-items.interface'
import { IUsers } from '@core/interfaces/users'
import { BehaviorSubject } from 'rxjs/index'
@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  companies: ICompaniesList[]
  activities: IActivity[]
  currentOrgStatus: string
  globalSearchCompanies: string[]
  globalSearchContentSearch: string
  orgLogo: string
  currentTourObj: BehaviorSubject<string> = new BehaviorSubject<string>('')
  allTabs
  private categoryList: ICategoryList[]
  private userData: IUsers
  private readonly orgStatus: string
  private timeZone: string
  private outerURL: string
  getUser(): IUsers {
    const user = JSON.parse(localStorage.getItem('user'))
    this.userData = user
    return user
  }

  updateUserTour(data: string) {
    this.currentTourObj.next(data)
  }

  setUser(setUser: IUsers): IUsers {
    const user = {
      enforceLoginType: setUser.enforceLoginType,
      orgStatus: setUser.orgStatus,
      userUUID: setUser.userUUID,
      email: setUser.email,
      orgName: setUser.orgName,
      token: setUser.token,
      firstName: setUser.firstName,
      lastName: setUser.lastName,
      userName: setUser.userName,
      companyLogo: setUser.companyLogo,
      picture: setUser.picture,
      userRole: setUser.userRole,
      userType: setUser.userType,
      trialEndDate: setUser.trialEndDate,
      theme: setUser.theme,
      googleAuthFlag: setUser.googleAuthFlag,
      enforce2fa: setUser.enforce2fa,
      canAccessAll: setUser.canAccessAll,
      mainIntegration: setUser.mainIntegration,
      cwSyncFlag: !setUser.cwSyncFlag ? false : true,
      labtechIntegration: !setUser.labtechIntegration ? false : true,
      solarwindsNCIntegration: !setUser.solarwindsNCIntegration ? false : true,
      aemIntegration: !setUser.aemIntegration ? false : true,
      screenConnectIntegration: !setUser.screenConnectIntegration ? false : true,
      TvUser: setUser.TvUser,
      planName: setUser.planName,
      ITBTrialEndDate: setUser.ITBTrialEndDate,
      cancellationDate: setUser.cancellation
        ? setUser.cancellation.nextBillingCycle
        : setUser.cancellationDate
          ? setUser.cancellationDate
          : undefined,
      sso: setUser.sso,
      ssoEnable: setUser.ssoEnable,
      inactiveContactsVisibility: setUser.inactiveContactsVisibility,
    }
    this.userData = setUser
    localStorage.removeItem('user')
    localStorage.setItem('user', JSON.stringify(user))
    return this.userData
  }
  changeOrgStatus(status: string) {
    return status
  }

  getCompaniesList(): ICompaniesList[] {
    return this.companies
  }

  setCompaniesList(data: ICompaniesList[]) {
    this.companies = []
    this.companies = data
  }
  setActivities(data: IActivity[]) {
    this.activities = []
    this.activities = data
  }

  getActivities(): IActivity[] {
    return this.activities
  }
  /***Clears the local storage */
  clearLocalStorage() {
    localStorage.clear()
    // localStorage.cookie.remove('setToken')
  }

  /***Gets the time zone */
  getTimeZone(): string {
    return this.timeZone
  }

  setTimeZone(data: string) {
    this.timeZone = ''
    this.timeZone = data
  }

  /***Gets all categories from local storage to avoid refreshing header */
  geCategories(): ICategoryList[] {
    const categories = JSON.parse(localStorage.getItem('categories'))
    this.categoryList = categories
    return categories
  }

  /***Sets all categories to local storage */
  setCategories(setCategories: ICategoryList[]): ICategoryList[] {
    this.categoryList = setCategories
    localStorage.removeItem('categories')
    localStorage.setItem('categories', JSON.stringify(setCategories))
    return this.categoryList
  }
  setURL(val) {
    this.outerURL = val
    localStorage.removeItem('setURL')
    localStorage.setItem('setURL', this.outerURL)
  }
  getURL() {
    if (typeof this.outerURL === 'undefined') {
      this.outerURL = localStorage.getItem('setURL')
      return this.outerURL
    }
    return this.outerURL
  }
  setShowSessionExpiredAlertFlag(flag) {
    localStorage.removeItem('showExpireModalFlag')
    localStorage.setItem('showExpireModalFlag', flag)
  }
  getShowSessionExpiredAlertFlag() {
    return JSON.parse(localStorage.getItem('showExpireModalFlag'))
  }

  setGlobalSearchContentSearch(flag) {
    localStorage.removeItem('globalSearchHighlight')
    localStorage.setItem('globalSearchHighlight', flag)
  }
  getGlobalSearchContentSearch() {
    return (this.globalSearchContentSearch = JSON.parse(localStorage.getItem('globalSearchHighlight')))
  }
  setGlobalSearchCompanies(data: string[]) {
    this.globalSearchCompanies = data
    localStorage.removeItem('globalSearchCompanies')
    localStorage.setItem('globalSearchCompanies', JSON.stringify(data))
  }
  getGlobalSearchCompanies(): string[] {
    const companies = JSON.parse(localStorage.getItem('globalSearchCompanies'))
    this.globalSearchCompanies = companies
    return this.globalSearchCompanies
  }
  setOrgLogo(logo: string) {
    this.orgLogo = logo
    localStorage.removeItem('logo')
    localStorage.setItem('logo', JSON.stringify(logo))
  }
  getOrgLogo(): string {
    const logo = JSON.parse(localStorage.getItem('logo'))
    this.orgLogo = logo
    return this.orgLogo
  }
}
