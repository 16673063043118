import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MODAL_CONSTANTS } from '@app/constants/ui-components.constants'
import { GlobalService } from '@app/modules/core/services/global.service'
import { ProfileService } from '@app/modules/core/services/profile.service'
import { SharedDataService } from '@app/modules/core/services/shared-data.service'
import { ThemeService } from '@app/modules/core/services/theme.service'
import { Subscription } from 'rxjs'
import { Md5 } from 'ts-md5/dist/md5'
import { PopupAlertComponent } from 'ui-components'

import { backendRoutes } from '../../constants/constant'
import { NOTIFICATION_CONSTANTS, STRING_CONSTANTS } from '../../constants/constants'
import {  IAvatar } from '../../interfaces/avatar'
import { IImageObject } from '../../interfaces/image-object'
import { ITheme } from '../../interfaces/theme'
import { IUsers } from '../../interfaces/users'
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit, OnDestroy {
  subscriptions: Subscription
  avatarArray: IAvatar []
  isLoading: boolean
  timezones: string []
  timezone: string
  dateFormats: string []
  picModes: string []
  multiLanguages: string []
  multiLanguage: string
  activeTheme: ITheme
  theme: string
  profileForm: FormGroup
  selectAvatarPhoto: string
  browseAvatarPhoto: string
  gravatarPhoto: string
  isSubmitLoader: boolean
  isSubmitFlag: boolean
  fileName: string
  fileType: string
  file: IImageObject
  firstNameRegex: boolean
  lastNameRegex: boolean
  phoneMobileValue: string
  phoneOfficeValue: string
  max: Date
  min: Date
  isFileSizeExceed: boolean
  constructor(public globalService: GlobalService,  private readonly themeSvc: ThemeService,
              public dialogRef: MatDialogRef<ProfileComponent>,
              public profileService: ProfileService,  private readonly dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public modalData, private readonly sharedService: SharedDataService) {
   this.subscriptions = new Subscription()
   this.browseAvatarPhoto = ''
   this.timezones = globalService.timeZoneList()
   this.dateFormats = globalService.dateFormats()
   this.picModes = globalService.picModes()
   this.multiLanguages = globalService.multiLanguages()
   this.activeTheme = this.themeSvc.getActiveTheme()
   this.theme = this.activeTheme.name
   this.multiLanguage = 'English'
   this.isLoading = false
   this.isSubmitLoader = false
   this.isSubmitFlag = false
   this.firstNameRegex = true
   this.lastNameRegex = true
   this.max = new Date(new Date().setFullYear(new Date().getFullYear() - 15))
   this.min = new Date(new Date().setFullYear(new Date().getFullYear() - 100))
   this.isFileSizeExceed = false
  }
  /*** Sends http request to get profile data and call initializeProfileForm() function */
  ngOnInit() {
    this.avatarArray = this.profileService.avatarArray()
    this.subscriptions.add(this.profileService.getUserProfile().subscribe(dataProfile => {
      const md5 = new Md5()
      this.gravatarPhoto = backendRoutes.GRAVATAR_URL(md5.appendStr(dataProfile.userName).end())
      this.initializeProfileForm(dataProfile)
    }, err => {

    }))
  }
  /*** Initialize profile form */
  initializeProfileForm(data: IUsers) {
    const oldUserprofile = data
    if (oldUserprofile.SC === undefined) {
      oldUserprofile.SC = {
        user : '',
        password : '',
        defaultGroup: '',
      }
    }
    this.profileForm = new FormGroup({
      firstName: new FormControl(oldUserprofile.firstName, Validators.required),
      lastName: new FormControl(oldUserprofile.lastName, Validators.required),
      phoneMobile: new FormControl(oldUserprofile.phoneMobile ?
      this.profileService.checkString(oldUserprofile.phoneMobile).replace(/-|\s/g, '').trim() : ''),
      phoneOffice: new FormControl(oldUserprofile.phoneOffice ?
        this.profileService.checkString(oldUserprofile.phoneOffice).replace(/-|\s/g, '').trim() : ''),
      address: new FormControl(oldUserprofile.address),
      userName: new FormControl({value: oldUserprofile.userName, disabled: true}),
      dob : new FormControl(oldUserprofile.dob ? oldUserprofile.dob : ''),
      gender : new FormControl(oldUserprofile.gender),
      timeFormat : new FormControl(oldUserprofile.timeFormat ? oldUserprofile.timeFormat : ''),
      theme: new FormControl(oldUserprofile.theme),
      timezone: new FormControl(oldUserprofile.timezone ? oldUserprofile.timezone : ''),
      dateFormat: new FormControl(oldUserprofile.dateFormat ? oldUserprofile.dateFormat.toUpperCase() : ''),
      picMode: new FormControl(oldUserprofile.picMode || STRING_CONSTANTS.SELECT_AVATAR),
      userPhoto: new FormControl(oldUserprofile.userPhoto),
    })
    this.phoneMobileValue = this.profileForm.value.phoneMobile
    this.phoneOfficeValue = this.profileForm.value.phoneOffice
    if (this.profileForm.value.picMode === STRING_CONSTANTS.SELECT_AVATAR) {
      const userAvatar = this.profileService.getAvatarImg(this.profileForm.value.userPhoto)
      this.avatarArray.forEach(avatar => {
        const avtr = this.profileService.getAvatarImg(avatar.src)
        if (avtr === userAvatar) {
          this.profileForm.value.userPhoto = avatar.src
          avatar.isSelected = true
        }
      })
    }
    if (this.profileForm.value.picMode === STRING_CONSTANTS.BROWSE_AVATAR) {
        this.browseAvatarPhoto = this.profileForm.value.userPhoto
    }
    this.isLoading = true
    this.subscriptions.add(this.getFirstName.valueChanges.subscribe(res => {
      this.firstNameRegex = res ? /^[a-zA-Z\s]+$/.test(res) : true
    }))
    this.subscriptions.add(this.getLastName.valueChanges.subscribe(res => {
      this.lastNameRegex = res ? /^[a-zA-Z\s]+$/.test(res) : true
    }))
  }
  /*** Gets selected timezone value */
  getSelectedTimeZone(e: string) {
    this.profileForm.patchValue({
      timezone: e,
    })
  }
  /*** Gets selected dte format value */
  getSelectedDateFormat(e: string) {
    this.profileForm.patchValue({
      dateFormat: e,
    })
  }
  /*** Gets selected pic mode value */
  getSelectedPicMode(e: string) {
    this.profileForm.patchValue({
      picMode: e,
    })
  }
  /*** Gets selected language value */
  getSelectedmultiLanguage(e: string) {
    this.multiLanguage = e
  }
  /*** Date of birth change event function */
  dateChanged(date) {
    this.profileForm.patchValue({
      dob: date.value,
    })
  }
  /*** Change theme event */
  toggleTheme(theme: string) {
    const type: string = theme === 'light' ? 'theme-light' : 'theme-dark'
    this.profileForm.patchValue({
      theme: type,
    })
    switch (theme) {
      case 'light':
        this.theme = 'light'
        this.themeSvc.setLightTheme()
        break
      case 'dark':
        this.theme = 'dark'
        this.themeSvc.setDarkTheme()
        break
      case 'green':
        this.theme = 'green'
        this.themeSvc.setGreenTheme()
        break

      default:
    }
  }
  /*** Change avatar event */
  changeAvatar(item: IAvatar) {
    this.avatarArray.forEach(avatar => {
      avatar.isSelected = false
    })
    item.isSelected = true
    this.selectAvatarPhoto = item.src
  }
  /*** Select custom image event */
  selectImage(e: IImageObject) {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    const fileSize = file.size / 1000 // in Kb
    if (fileSize > 200) {
      this.isFileSizeExceed = true
      return
    }
    this.isFileSizeExceed = false
    this.file = e
    const reader = new FileReader()
    let imageData
    const keyName = 'name'
    const keyType = 'type'
    this.fileName = e.target.files[0][keyName]
    this.fileType =  e.target.files[0][keyType]
    reader.onload = evt => {
      // tslint:disable-next-line: no-any
      const target = evt.target as any
      imageData = target.result
      this.browseAvatarPhoto = imageData
    }
    reader.readAsDataURL(file)
  }
  /*** Sends http request to save profile data */
  submit() {
    this.isSubmitFlag = true
    const currentUser = this.sharedService.getUser()
    if (!this.firstNameRegex && !this.lastNameRegex) {
      return
    }
    if (this.profileForm.value.firstName && this.profileForm.value.lastName) {
      this.isSubmitLoader = true
      if (this.profileForm.value.picMode === STRING_CONSTANTS.BROWSE_AVATAR && this.fileName) {
        this.subscriptions.add(this.profileService.uploadProfileImage(this.fileName, this.fileType).subscribe(data => {
          const uploadFile = data
          this.subscriptions.add(this.profileService.uploadProfileImageToS3(data.destination,
            this.file.target.files[0]).subscribe(dataS3 => {
            this.profileForm.value.userPhoto = `${data.path}${data.originalname}`
            this.profileSubmitCall()
          }, error  => {
          }))
        }, error => {
        }))
      } else {
        this.setUserPhoto()
        this.profileSubmitCall()
      }
    }
  }
  profileSubmitCall() {
    const currentUser = this.sharedService.getUser()
    this.subscriptions.add(this.profileService.patchUserProfile(
      this.profileService.createBackendObject(this.profileForm.value)).subscribe(data => {
      this.isSubmitLoader = false
      currentUser.firstName = this.profileForm.value.firstName
      currentUser.lastName = this.profileForm.value.lastName
      currentUser.theme = this.profileForm.value.theme
      currentUser.picture = this.profileForm.value.userPhoto ? this.profileForm.value.userPhoto : currentUser.picture
      this.showNotification(NOTIFICATION_CONSTANTS.SUCCESS, '',
        NOTIFICATION_CONSTANTS.UPDATED_USER(currentUser.firstName), NOTIFICATION_CONSTANTS.SUCCESS)
      this.sharedService.setUser(currentUser)
      this.modalData.cacheUser(currentUser)
      this.dialogRef.close()
    }, err => {
      this.isSubmitLoader = false
    }))
  }
 /*** Updates user tour - sets the avatar option to true */
  updateTour() {
     if (this.selectAvatarPhoto || this.browseAvatarPhoto !== '' && this.profileForm.value.picMode === STRING_CONSTANTS.USE_GRAVATAR) {
       this.sharedService.updateUserTour('personalAvatar')
     }
  }
  /*** Sets user photo based on the avatar selected */
  setUserPhoto() {
    switch (this.profileForm.value.picMode) {
      case STRING_CONSTANTS.SELECT_AVATAR:
        this.profileForm.value.userPhoto = this.selectAvatarPhoto ? this.selectAvatarPhoto : this.profileForm.value.userPhoto
        break
      case STRING_CONSTANTS.USE_GRAVATAR:
        this.profileForm.value.userPhoto = this.gravatarPhoto
        break
      default:
    }
  }
  /*** Shows notification modal */
  showNotification(mode: string, title: string, body: string, mainMessage: string) {
    const dialogRef = this.dialog.open(PopupAlertComponent, {
      width: MODAL_CONSTANTS.MODAL_WIDTH,
      data: {mode, title, body, mainMessage},
    })
  }
  /*** Handles close modal event */
  closeModal() {
    const type: string = this.sharedService.getUser().theme === 'theme-light' ? 'light' : 'dark'
    this.toggleTheme(type)
  }
  get getFirstName() {
    return this.profileForm.get('firstName')
  }

  get getLastName() {
    return this.profileForm.get('lastName')
  }
  /*** Unsubscribe all subscription when component destroy*/
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
