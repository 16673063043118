import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

@Component({
  selector: 'app-date-cell-renderer',
  templateUrl: './date-cell-renderer.component.html',
  styleUrls: ['./date-cell-renderer.component.scss'],
})
export class DateCellRendererComponent implements AgRendererComponent {
  params: ICellRendererParams
  isValidDate: boolean

  agInit(params: ICellRendererParams): void {
    const tempDate = new Date(params.value)
    this.isValidDate = String(tempDate) !== 'Invalid Date'
    this.params = params
  }

  refresh(params: ICellRendererParams): boolean {
    return false
  }
}
