import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

import { GirdActionService } from '../../services/grid-actions.service'

@Component({
  selector: 'app-status-cell-renderer',
  templateUrl: './progressstatus-cell-renderer.component.html',
  styleUrls: ['./progressstatus-cell-renderer.component.scss'],
})
export class ProgressStatusCellRendererComponent implements AgRendererComponent {

  params // used for optional params that are added dynamically for different types
  statusValue: string

  constructor(public gridActionsService: GirdActionService) { }

  agInit(params: ICellRendererParams): void {
    this.params = params
    this.statusValue = String(params.value).replace(/\s/g, '').toLowerCase()
  }

  refresh(params: ICellRendererParams): boolean {
    return false
  }
}
