import {
  AfterViewChecked, ChangeDetectorRef, Component,
  HostListener, Input, OnChanges, OnDestroy, OnInit,
} from '@angular/core'
import { MatDialog } from '@angular/material'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { CoreService } from '@app/modules/core/services/core.service'
import { LoginService } from '@app/modules/login/services/login.service'
import { SharedService } from '@app/modules/shared/shared.service'
import { IProgress } from '@core/interfaces/progress'
import { ProfileService } from '@core/services/profile.service'
import { UtilService } from '@core/services/utils.service'
import _ from 'lodash'
import { from, Subscription } from 'rxjs/index'

import { environment } from '../../../../../environments/environment'
import { backendRoutes, categoriesConstant, HEADER_CONST, notificationItems } from '../../constants/constant'
import {
  CLASSNAME_CONSTANTS, MODAL_WIDTH_CONSTANTS, NOTIFICATION_CONSTANTS,
  RESTRICTED_URLS, STRING_CONSTANTS, TABICON_CONSTANTS,
  URL,
} from '../../constants/constants'
import { URL_CONSTANTS } from '../../constants/url-constants'
import {
  ICategoryList,
  INotificationItemsClass,
  IOnBoardingItems,
  IProgressButton,
  IReturnTabs,
  ISearchBarButton,
  ISettingItemsClass,
  ITab,
  ITicketItemsClass,
} from '../../interfaces/header-items.interface'
import { IHelpItems } from '../../interfaces/help-items.interface'
import { IProfileDropdown } from '../../interfaces/profile-user'
import { ISettingItems } from '../../interfaces/setting-items.interface'
import { IUsers } from '../../interfaces/users'
import { SharedDataService } from '../../services/shared-data.service'
import { ChangePasswordComponent } from '../change-password/change-password.component'
import { GoogleAuthenticationPopupComponent } from '../google-authentication-popup/google-authentication-popup.component'
import { LogOutConfirmationComponent } from '../logout-confirmation-dialog/logout-confirmation.component'
import { ProfileComponent } from '../profile/profile.component'
import { TermsServiceComponent } from '../terms-service-dialog/terms-service.component'

import { GlobalDashboardService } from './../../../../../../projects/global-dashboard/global-dashboard/services/global-dashboard.service'
import { ResponsiveMenu } from './responsive-menu'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit, AfterViewChecked, OnDestroy {

  // Inputs
  @Input() categoryList: ICategoryList[]
  subscriptions: Subscription
  tabsToReturn: IReturnTabs[]
  toggleMenuClass: string
  categoryId: string
  user: IUsers
  allTabs
  mainDashboardLink: string
  // class properties
  onBoardPersonalizationItems: IOnBoardingItems[]
  onBoardUserItems: IOnBoardingItems[]
  onBoardSettingItems: IOnBoardingItems[]
  onBoardMasterDataTableItems: IOnBoardingItems[]
  onBoardMappingMerging: IOnBoardingItems[]
  onBoardAssetItems: IOnBoardingItems
  onBoardIntegrationItems: IOnBoardingItems
  help: IHelpItems[]
  settings: ISettingItems[]
  settingsV4: ISettingItems[]
  searchBarButton: ISearchBarButton
  progressButton: IProgressButton
  notificationItemsClass: INotificationItemsClass
  settingItemsClass: ISettingItemsClass
  settingV4ItemsClass: ISettingItemsClass
  ticketItemsClass: ITicketItemsClass
  regexArrayOfRestrictedUrls: RegExp[]
  arrayOfRestrictedUrls: string[]
  canShowGlobalSearch: boolean
  isDisabledGlobalSearch: boolean
  profileDropdown: IProfileDropdown
  fullName: string
  picture: string
  email: string
  googleAuthFlag: boolean
  toggler: boolean
  orgLogo: string
  defaultLogoPath: string
  respMenuClass: ResponsiveMenu
  isShow: boolean
  _router: string
  tourData: IProgress
  showTour: boolean
  dashboardTabs
  dashboardCategoryId: string
  subMenuList
  isCalled: boolean
  isShowLogo: boolean
  subMenuCompaniesList = { submenuIconClassName: 'fa-book', submenuText: 'sdf', linkPath: '../knowlegedbase' }
  constructor(private readonly router: Router, private readonly dialog: MatDialog, private readonly coreService: CoreService,
              public dashboardService: GlobalDashboardService, private readonly utilService: UtilService,
              private readonly changeDetector: ChangeDetectorRef, private readonly sharedService: SharedService,
              private readonly sharedDataService: SharedDataService, private readonly profileService: ProfileService,
              private readonly loginSvc: LoginService, private readonly activatedRoute: ActivatedRoute) {
    this.subscriptions = new Subscription()
    this.allTabs = []
    this.dashboardTabs = []

    this.tabsToReturn = [
      {
        link: URL_CONSTANTS.DOCUMENTATION_KNOWLEDGEBASE, isSelected: false, text: STRING_CONSTANTS.KNOWLEDGEBASE,
        tabIcon: TABICON_CONSTANTS.SAVE,
      },
      {
        link: URL_CONSTANTS.DOCUMENTATION_RUNBOOK, isSelected: false, text: STRING_CONSTANTS.RUNBOOKS,
        tabIcon: TABICON_CONSTANTS.CLOSE,
      }]
    this.isCalled = false
    this.categoryId = ''
    this.orgLogo = this.sharedDataService.getOrgLogo()
    this.defaultLogoPath = 'images-library/logo.png'
    this.toggleMenuClass = ''
    this.user = this.sharedDataService.getUser()
    this.googleAuthFlag = this.user.googleAuthFlag
    this.showTour = true
    this.toggler = true
    this.ticketItemsClass = {
      iconClassName: 'help',
    }

    this.settingItemsClass = {
      iconClassName: 'setting',
    }
    this.settingV4ItemsClass = {
      iconClassName: 'tools',
    }
    this.notificationItemsClass = {
      iconClassName: 'bell',
    }

    this.progressButton = {
      iconClassName: 'icon-tour',
      progressBarValue: 0,
    }

    this.searchBarButton = {
      iconClassName: 'search',
      textClassName: 'text-default',
      text: 'Search',
    }

    this.profileDropdown = {
      imagePath: 'images-library/profiles/avatar.png',
    }
    this.isShowLogo = true
    this.subscriptions.add(this.coreService.headerLogoUpdateEvent.subscribe(data => {
      if (data) {
        this.isShowLogo = false
        setTimeout(() => {
          this.updateLog()
        }, 0)
      }
    }))
  }
  /*** Sets logo for the header obtained from shared data service */
  updateLog() {
    this.isShowLogo = true
    this.orgLogo = this.sharedDataService.getOrgLogo()
  }

  /*** Set active category class  */
  getActiveClassName(category: ICategoryList): string {
    this.categoryId = category.uuid
    if (category.name === categoriesConstant.DASHBOARD && this.router.url.includes(categoriesConstant.DASHBOARD.toLowerCase()))
      return STRING_CONSTANTS.ACTIVE
    if (category.name === categoriesConstant.DOCUMENTATION && this.router.url.includes(categoriesConstant.DOCUMENTATION.toLowerCase()))
      return STRING_CONSTANTS.ACTIVE
    if (category.name === categoriesConstant.COMPANIES && this.router.url.includes(categoriesConstant.COMPANIES.toLowerCase()))
      return STRING_CONSTANTS.ACTIVE
    if (category.name === categoriesConstant.BI && this.router.url.includes(categoriesConstant.BI.toLowerCase()))
      return STRING_CONSTANTS.ACTIVE
    if (category.name === categoriesConstant.BACKUPS && this.router.url.includes(categoriesConstant.BACKUPS.toLowerCase()))
      return STRING_CONSTANTS.ACTIVE
    if (category.name === categoriesConstant.FEEDBACK && this.router.url.includes(categoriesConstant.FEEDBACK.toLowerCase()))
      return STRING_CONSTANTS.ACTIVE
  }
  /*** Get category tabs  */
  getCategoryTabs(): IReturnTabs[] {
    if (this.router.url.includes('documentation')) {
      this.tabsToReturn = [
        {
          link: URL_CONSTANTS.APP_DOCUMENTATION_KNOWLEDGEBASE, isSelected: false, text: STRING_CONSTANTS.KNOWLEDGEBASE,
          tabIcon: TABICON_CONSTANTS.SAVE,
        },
        {
          link: URL_CONSTANTS.DOCUMENTATION_RUNBOOK, isSelected: false, text: STRING_CONSTANTS.RUNBOOKS,
          tabIcon: TABICON_CONSTANTS.CLOSE,
        }]
      if (this.router.url.includes('runbook')) {
        this.tabsToReturn[0].isSelected = false
        this.tabsToReturn[1].isSelected = true
      } else {
        this.tabsToReturn[0].isSelected = true
        this.tabsToReturn[1].isSelected = false
      }
    }
    if (this.router.url.includes('feedback')) {
      this.tabsToReturn = [{ link: 'templates', isSelected: false, text: STRING_CONSTANTS.TEMPLATES, tabIcon: TABICON_CONSTANTS.SAVE },
                           { link: 'Questions', isSelected: false, text: STRING_CONSTANTS.QUESTIONS, tabIcon: TABICON_CONSTANTS.CLOSE }]
    }

    return this.tabsToReturn

  }
  /*** Checks if category exists or not */
  isThisCategory(categoryName: string): boolean {
    if (this.router.url.includes(categoryName)) {
      return true
    }
    return false
  }

  /*** Checks if user is on global dashboard */
  isDashboard(): boolean {
    if (this.router.url.includes('dashboard')) {
      return true
    }
    return false
  }

    /*** Opens admin portal from the settings icon */
  changeSettings(item) {
    const url = `${environment.v3BaseUrl}/access/login?domain=${this.loginSvc.getSubDomain()}&page=${item.id}`
    const win = window.open(url, '_blank')
    win.focus()
  }

  /*** Sets header data on component load  */
  ngOnInit() {

    this.onBoardSettingItems = notificationItems.ONBOARDING_SETTINGS_ITEMS()
    this.onBoardPersonalizationItems = notificationItems.ONBOARDING_PERSONALIZATION_ITEMS()
    this.onBoardUserItems = notificationItems.ONBOARDING_USER_ITEMS()
    this.onBoardMasterDataTableItems = notificationItems.ONBOARDING_MASTER_DATA_TABLE_ITEMS()
    this.onBoardMappingMerging = notificationItems.ONBOARDING_Mapping_Merging()
    this.onBoardAssetItems = notificationItems.ONBOARDING_ASSET_ITEMS()
    this.settings = notificationItems.SETTING_ITEMS()
    this.settingsV4 = notificationItems.SETTING_ITEMS_V4()
    this.onBoardIntegrationItems = notificationItems.ONBOARDING_INTEGRATION_ITEMS()
    this.help = notificationItems.TICKET_ITEMS()

    this.getUserTourData()
    this.regexArrayOfRestrictedUrls = RESTRICTED_URLS.RESTRICTED_REGEX_URLS
    this.arrayOfRestrictedUrls = RESTRICTED_URLS.RESTRICTED_URLS
    this.canShowGlobalSearch = false
    this.getUserData()
    if (this.router.url !== '/app/welcome') {
      this.subscriptions.add(
        this.coreService.getTerms(backendRoutes.GET_TERMS()).subscribe(data => {
          if (!data || data === null || Object.keys(data).length === 0) {
            this.dialog.open(TermsServiceComponent, {
              data: {}, width: MODAL_WIDTH_CONSTANTS.MEDIUM,
              disableClose: true, autoFocus: false,
            })
          }
        })
      )
    }
    if (this.sharedDataService.getUser().enforce2fa) {
      this.dialog.open(GoogleAuthenticationPopupComponent,
        {
          data: { cacheUser: this.cacheUser }, width: MODAL_WIDTH_CONSTANTS.SMALL,
          disableClose: true, autoFocus: false,
        })
    }
    let routes = this.router.url.split('/')
    this._router = `${routes[0]}/` + `${routes[1]}/` + `${routes[2]}`
    this.subscriptions.add(
      // tslint:disable-next-line: no-any
      this.router.events.subscribe((res: any) => {
        if (res.urlAfterRedirects && res.urlAfterRedirects.includes('app/company')) {
          this.categoryList.map(category => {
            if (category.id === 1 || category.id === 2 || category.name.toLowerCase() === 'companies') category.isLink = true
            })
        }
        if (res instanceof NavigationEnd) {
          routes = this.router.url.split('/')
          this._router = `${routes[0]}/` + `${routes[1]}/` + `${routes[2]}`
        }
      }))
    this.subMenuList = {
      Dashboard: this.dashboardTabs,
      Documentation: [{ submenuIconClassName: 'help', submenuText: 'Knowledgebase', linkPath: '/app/documentation/knowledgebase' },
                      { submenuIconClassName: 'report-c', submenuText: 'Runbook', linkPath: '/app/documentation/runbook' }],
      Companies: [{ submenuIconClassName: 'hierarchy', submenuText: 'Companies List', linkPath: '/app/companies/list' },
                  { submenuIconClassName: 'agreement', submenuText: 'Documentation Snapshot', linkPath: '/app/companies/snapshot' },
                  { submenuIconClassName: 'hierarchy', submenuText: 'All Companies', linkPath: '/app/companies/all' }],
      Backups: [{ submenuIconClassName: 'agreement ', submenuText: 'Crash Plan', linkPath: '/app/backups' }],
      Feedback: [{ submenuIconClassName: 'dashboard ', submenuText: 'Dashboard', linkPath: '/app/feedback/dashboard' },
                 { submenuIconClassName: 'feedback-templates', submenuText: 'Templates', linkPath: '/app/feedback/templates' },
                 { submenuIconClassName: 'feedback-question', submenuText: 'Questions', linkPath: '/app/feedback/questions' },
                 { submenuIconClassName: 'feedback-results', submenuText: 'Results', linkPath: '/app/feedback/results' }],
    }
    if (window.innerWidth <= 768) {
      this.getTabs()

    }
    this.subscriptions.add(this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        if (val.url.includes('app/admin')) {
          this.categoryList.forEach(tab => {
            if (!tab.isLink) {
              tab.isLink = true
              tab.className = ''
            }})
          }}
      }))
    this.subscriptions.add(
      this.coreService.toggleValue.subscribe(data => {
        this.toggler = data
      })
    )
    this.categoryList.map(category => {
      if (category.id === 1 && this.router.url.includes('app/dashboard') && !this.router.url.includes('app/admin')) {
        category.isLink = false
        category.className = 'active'
      } else {
        category.isLink = true
        category.className = ''
      }
    })
    this.getTabs()
  }
  /*** Gets user data including the profile picture and sets it */
  getUserData() {
    this.subscriptions.add(this.profileService.getUserProfile().subscribe(data => {
      this.sharedDataService.setTimeZone(data.timezone)
      this.fullName = `${data.firstName}  ${data.lastName}`
      this.picture = data.userPhoto
      this.email = data.userName
      this.profileDropdown = {
        imagePath: this.picture,
      }
      this.user.picture = this.picture
      this.sharedDataService.setUser(this.user)
    }, err => { }))
  }
  ngAfterViewChecked() {
    this.subscriptions.add(this.sharedService.disableGlobalSearch.subscribe(flag => {
      this.isDisabledGlobalSearch = flag
      this.changeDetector.detectChanges()
    }))
  }
  ngAfterViewInit() {
    this.respMenuClass = new ResponsiveMenu()
    this.subscriptions.add(this.coreService.firstLoginValue.subscribe(prop => {
      if (prop) {
        this.coreService.updateFirstLogin(false)
        this.utilService.openUserTour()
      }
    }))
  }

  /*** Enable or disable search */
  onSearch() {
    if (this.restrictedSearchRoutes() === true || !this.isDisabledGlobalSearch) {
      return
    }
    this.canShowGlobalSearch = true
    document.body.classList.add(STRING_CONSTANTS.OVERLAY_OPEN)
  }
  /*** Disable search on restricted search routes  */
  restrictedSearchRoutes() {
    return (_.includes(this.arrayOfRestrictedUrls, this.router.url) ||
      (this.regexArrayOfRestrictedUrls.some(url => url.test(this.router.url))))
  }
  /*** Disables global search based on the flag */
  close() {
    if (this.canShowGlobalSearch) {
      this.canShowGlobalSearch = false
      document.body.className = document.body.className.replace(STRING_CONSTANTS.OVERLAY_OPEN, '')
    }
  }
  /*** Close search on escape press   */
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.close()
  }

  /*** Opens user profile modal   */
  profileClick() {
    const dialogRef = this.dialog.open(ProfileComponent, {
      data: { cacheUser: this.cacheUser },
      width: MODAL_WIDTH_CONSTANTS.MEDIUM, disableClose: true,
    })
  }
  /*** Cache user data */
  cacheUser = () => {
    this.user = this.sharedDataService.getUser()
    this.fullName = `${this.user.firstName}  ${this.user.lastName}`
    this.picture = this.user.picture
    this.profileDropdown = {
      imagePath: this.picture,
    }
    this.googleAuthFlag = this.user.googleAuthFlag
  }

  /*** Opens hardcoded links for the settings panel */
  infoHandler(event) {
    switch (event.id) {
      case '5':
        window.open('http://help.itboost.com/en/articles/2546758-step-1a-setting-up-your-company-branding', '_blank')
        break
      case '6':
         window.open('', '_blank')
         break
      case '7':
         window.open('http://help.itboost.com/en/articles/2546828-step-3-create-and-invite-users', '_blank')
         break
      case '8':
        window.open('http://help.itboost.com/en/articles/2546803-step-2-roles-and-permissions', '_blank')
        break
      case '9':
         window.open('http://help.itboost.com/en/articles/2546852-step-4-flexible-assets-and-custom-templates', '_blank')
         break
      case '3':
         window.open('http://help.itboost.com/en/articles/2546865-step-5-advanced-settings', '_blank')
         break
      case '4':
         window.open('http://help.itboost.com/en/articles/2546871-step-6-notifications', '_blank')
         break
      case '10':
         window.open('http://help.itboost.com/en/articles/2546873-step-7-integrations', '_blank')
         break
      case '1':
         window.open('http://help.itboost.com/en/articles/2546883-step-10-review-master-data-tables', '_blank')
         break
      case '2':
        window.open('http://help.itboost.com/en/articles/2546883-step-10-review-master-data-tables', '_blank')
        break
      case '13':
        window.open('http://help.itboost.com/en/articles/2546879-step-9-mapping-merging', '_blank')
        break
      case '14':
        window.open('http://help.itboost.com/en/articles/2546879-step-9-mapping-merging', '_blank')
        break
      default:
    }
  }

  /*** */
  onBoardingItemClicked(event) {
    switch (event.id) {
      case '5':
        this.changeSettings({ id: '1' })
        break
      case '6':
        this.profileClick()
        break
      case '7':
        this.changeSettings({ id: '6' })
        break
      case '8':
        this.changeSettings({ id: '5' })
        break
      case '9':
        this.changeSettings({ id: '11' })
        break
      case '3':
        this.changeSettings({ id: '12' })
        break
      case '4':
        this.changeSettings({ id: '13' })
        break
      case '10':
        this.changeSettings({ id: '8' })
        break
      case '1':
        this.changeSettings({ id: '15' })
        break
      case '2':
        this.changeSettings({ id: '16' })
        break
      case '13':
        this.changeSettings({ id: '4' })
        break
      case '14':
        this.changeSettings({ id: '4' })
        break
      default:
    }
  }
  /*** Get user tour data   */
  getUserTourData() {
    this.subscriptions.add(this.coreService
      .getUserTour()
      .subscribe(
        data => {
          this.progressButton.progressBarValue = data.completionPercentage > 100 ? 100 : data.completionPercentage
          if (this.progressButton.progressBarValue >= 100) {
            this.showTour = false
          }
          this.tourData = data
          this.updateUserTour(data)
        },
        error => {
          throw new Error(error.message || error || NOTIFICATION_CONSTANTS.SOMETHING_WENT_WRONG)
        }
      ))
    this.sharedDataService.currentTourObj.subscribe(prop => {
      if (prop && !this.tourData[prop]) {
        this.tourData[prop] = true
        this.tourData.completionPercentage = this.tourData.completionPercentage ?
          // tslint:disable-next-line: restrict-plus-operands
          notificationItems.ONBOARDING_PERCENTAGES[prop] + this.tourData.completionPercentage :
          notificationItems.ONBOARDING_PERCENTAGES[prop]
        this.progressButton.progressBarValue = this.tourData.completionPercentage > 100 ? 100 : this.tourData.completionPercentage
        if (this.progressButton.progressBarValue >= 100) {
          this.progressButton.iconClassName = 'btn-icon tick icon-white'
          setTimeout(() => {
            this.showTour = false
          }, 3000)
        }
        this.updateUserTour(this.tourData)
        const obj = { completionPercentage: this.tourData.completionPercentage }
        obj[prop] = true
        this.coreService.putUserTourdata(obj).subscribe()

      }
    })
  }
  /*** Update user tour data   */
  updateUserTour(tourObj: IProgress) {
    this.onBoardPersonalizationItems[0].iconClassName = tourObj.companyLogo ? 'btn-icon green-tick' :
      this.onBoardPersonalizationItems[0].iconClassName
    /*this.onBoardPersonalizationItems[1].iconClassName = tourObj.personalAvatar ? 'btn-icon green-tick' :
    this.onBoardPersonalizationItems[1].iconClassName*/
    this.onBoardUserItems[0].iconClassName = tourObj.createAndInvite ? 'btn-icon green-tick' : this.onBoardUserItems[0].iconClassName
    this.onBoardUserItems[1].iconClassName = tourObj.rolesAndPermissions ? 'btn-icon green-tick' : this.onBoardUserItems[1].iconClassName
    this.onBoardAssetItems.iconClassName = tourObj.createCustomTemplates ? 'btn-icon green-tick' : this.onBoardAssetItems.iconClassName
    this.onBoardSettingItems[0].iconClassName = tourObj.reviewAdvanceSettings ? 'btn-icon green-tick' :
      this.onBoardSettingItems[0].iconClassName
    this.onBoardSettingItems[1].iconClassName = tourObj.setupNotifications ? 'btn-icon green-tick' :
      this.onBoardSettingItems[1].iconClassName
    this.onBoardIntegrationItems.iconClassName = tourObj.skipIntegrations ? 'btn-icon green-tick' :
      this.onBoardIntegrationItems.iconClassName
    this.onBoardMasterDataTableItems[0].iconClassName = tourObj.configurationTypeCategoryMapping ? 'btn-icon green-tick' :
      this.onBoardMasterDataTableItems[0].iconClassName
    this.onBoardMasterDataTableItems[1].iconClassName = tourObj.passwordTypeFlag ? 'btn-icon green-tick' :
      this.onBoardMasterDataTableItems[1].iconClassName
    this.onBoardMappingMerging[0].iconClassName = tourObj.companyMapping ? 'btn-icon green-tick' :
      this.onBoardMappingMerging[0].iconClassName
    this.onBoardMappingMerging[1].iconClassName = tourObj.ticketMapping ? 'btn-icon green-tick' :
      this.onBoardMappingMerging[1].iconClassName
  }
  /*** Open change password dialog   */
  changePasswordComponet() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, { width: MODAL_WIDTH_CONSTANTS.MEDIUM })
  }
  /*** Open google auth dialog   */
  googleAuthPopup() {
    // this.showGoogleAuthPopup = true
    const dialogRef = this.dialog.open(GoogleAuthenticationPopupComponent,
      { data: { cacheUser: this.cacheUser }, width: MODAL_WIDTH_CONSTANTS.SMALL })
  }
  /*** Logout user   */
  logout() {
    // this.sharedService.logout()
    this.dialog.open(LogOutConfirmationComponent, {
      data: {}, width: MODAL_WIDTH_CONSTANTS.SMALL,
      autoFocus: false,
    })
  }
  /*** Toggle main categories menu for mobile view   */
  toggleMenu() {
    this.toggleMenuClass = !this.toggleMenuClass ? 'open-menu' : ''
  }
  /*** Route ticket portal or itboost knowledgebase portal   */
  helpClick(value: IHelpItems) {
    const link = value.label === 'Ticket Portal' ? HEADER_CONST.TICKET_PORTAL_LINK : HEADER_CONST.ITBOOST_KNOWLEDGE_LINK
    window.open(link, '_blank')
  }
  /*** Unsubscribe all subscription when component destroy*/
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
  headerButton() {
    this.toggler = !this.toggler
    this.coreService.toggleLSB(this.toggler)
  }

    /*** Gets all tabs from the server  */
  getTabs(): void {
    this.isCalled = true
    if (!this.categoryList) {

      this.dashboardTabs = []
      return
    }
    if (this.categoryList && this.categoryList.length > 0) {
      const url = URL.GET_DASHBOARD_TABS(this.categoryList[0].uuid)
      this.subscriptions.add(
        this.dashboardService.getTabs(url).subscribe(
          tabs => {

            this.tabsSorting(tabs)

            this.allTabs.forEach(data => {

              this.dashboardTabs.push({ submenuIconClassName: data.icon, submenuText: data.name, linkPath: `/app/dashboard/${data.uuid}` })
              if (data.name.toLowerCase() === 'main dashboard') this.mainDashboardLink = `dashboard/${data.uuid}`
            })
            this.subMenuList.Dashboard = this.dashboardTabs

          },
          error => { }
        )
      )
    }

  }
  windowResizeFunc() {

    if (window.innerWidth <= 768) {
      if (!this.isCalled)
        this.getTabs()
    }
  }

    /*** Sorts tabs based on alphanumeric order */
  tabsSorting(tabs) {
    if (tabs !== undefined && tabs !== null && tabs.length > 0) {
      this.allTabs = tabs
      const defaultTabs = this.allTabs.filter(allTabs => allTabs.isDefault)
      this.allTabs = this.allTabs.filter(alltabs => !alltabs.isDefault)
      defaultTabs.sort((a, b) => (a.name < b.name ? 1 : -1))
      this.allTabs = defaultTabs.concat(this.allTabs)
    }
  }

    /*** Opens chat with support link */
  supportLink() {
    window.open(environment.cwChat, '_blank')
  }

    /*** Handles category click and opens the route */
  onCategoryClick(event) {
    this.categoryList.map(category => {
      if (category.id === event.id) {
        category.isLink = false
        category.className = 'active'
      } else {
        category.isLink = true
        category.className = ''
      }
    })
  }
}
