import { Injectable } from '@angular/core'
import { PASSWORD_GENERATOR_STRING } from '@app/constants/ui-components.constants'
import { ApiService } from '@app/services/api.service'
import { IStatesIterface } from '@core/interfaces/header-items.interface'
import { Observable } from 'rxjs'

import { URL_CONSTANTS } from '../constants/url-constants'
import { ICategoryOptions } from '../interfaces/category-options'
import { IFilterOptions } from '../interfaces/header-items.interface'
import { PasswordSchema } from '../interfaces/password-schema.interface'
import { IColumnFilterCompanies } from '../interfaces/search-bk-response'

import {
  SearchCompaniesCellRendererComponent,
} from './../components/search-companies-dialog/search-companies-cell-renderer/search-companies-cell-renderer.component'

// tslint:disable-next-line: max-line-length
@Injectable()
export class GlobalService {
  constructor(private readonly api: ApiService) {}

  colors(): string[] {
    return [
      '#0866c6',
      '#6dcff6',
      '#ffd800',
      '#8560a8',
      '#f26522',
      '#acacac',
      '#000000',
      '#0072bc',
      '#62c4b6',
      '#6dcff6',
      '#ffd800',
      '#8560a8',
      '#f26522',
      '#acacac',
      '#000000',
      '#0072bc',
      '#62c4b6',
      '#6dcff6',
      '#ffd800',
      '#8560a8',
      '#f26522',
      '#acacac',
      '#000000',
      '#0072bc',
      '#62c4b6',
      '#6dcff6',
      '#ffd800',
      '#8560a8',
      '#f26522',
      '#acacac',
      '#000000',
      '#0072bc',
      '#62c4b6',
      '#6dcff6',
      '#ffd800',
      '#8560a8',
      '#f26522',
      '#acacac',
      '#000000',
      '#0072bc',
    ]
  }
  timeZoneList(): string[] {
    return [
      '(GMT-12:00) International Date line West',
      '(GMT-11:00) Coordinated Universal Time-11',
      '(GMT-10:00) Aleutian Islands',
      '(GMT-11:00) Midway Island',
      '(GMT-11:00) Samoa',
      '(GMT-10:00) Hawaii',
      '(GMT-09:30)  Marquesas Islands',
      '(GMT-09:00) Alaska',
      '(GMT-08:00) Pacific Time (US , Canada)',
      '(GMT-08:00) Tijuana',
      '(GMT-07:00) Arizona',
      '(GMT-07:00) Mountain Time (US , Canada)',
      '(GMT-07:00) Chihuahua',
      '(GMT-07:00) Mazatlan',
      '(GMT-06:00) Mexico City',
      '(GMT-06:00) Monterrey',
      '(GMT-06:00) Saskatchewan',
      '(GMT-06:00) Central Time (US, Canada)',
      '(GMT-05:00) Eastern Time (US , Canada)',
      '(GMT-05:00) Indiana (East)',
      '(GMT-05:00) Bogota',
      '(GMT-05:00) Lima',
      '(GMT-04:30) Caracas',
      '(GMT-04:00) Atlantic Time (Canada)',
      '(GMT-04:00) La Paz',
      '(GMT-04:00) Santiago',
      '(GMT-03:30) Newfoundland',
      '(GMT-03:00) Buenos Aires',
      '(GMT-03:00) Greenland',
      '(GMT-02:00) Stanley',
      '(GMT-01:00) Azores',
      '(GMT-01:00) Cape Verde Is.',
      '(GMT) Casablanca',
      '(GMT) Dublin',
      '(GMT) Lisbon',
      '(GMT) London',
      '(GMT) Monrovia',
      '(GMT+01:00) Amsterdam',
      '(GMT+01:00) Belgrade',
      '(GMT+01:00) Berlin',
      '(GMT+01:00) Bratislava',
      '(GMT+01:00) Brussels',
      '(GMT+01:00) Budapest',
      '(GMT+01:00) Copenhagen',
      '(GMT+01:00) Ljubljana',
      '(GMT+01:00) Madrid',
      '(GMT+01:00) Paris',
      '(GMT+01:00) Prague',
      '(GMT+01:00) Rome',
      '(GMT+01:00) Sarajevo',
      '(GMT+01:00) Skopje',
      '(GMT+01:00) Stockholm',
      '(GMT+01:00) Vienna',
      '(GMT+01:00) Warsaw',
      '(GMT+01:00) Zagreb',
      '(GMT+02:00) Athens',
      '(GMT+02:00) Bucharest',
      '(GMT+02:00) Cairo',
      '(GMT+02:00) Harare',
      '(GMT+02:00) Helsinki',
      '(GMT+02:00) Istanbul',
      '(GMT+02:00) Jerusalem',
      '(GMT+02:00) Kyiv',
      '(GMT+02:00) Minsk',
      '(GMT+02:00) Riga',
      '(GMT+02:00) Sofia',
      '(GMT+02:00) Tallinn',
      '(GMT+02:00) Vilnius',
      '(GMT+03:00) Baghdad',
      '(GMT+03:00) Kuwait',
      '(GMT+03:00) Nairobi',
      '(GMT+03:00) Riyadh',
      '(GMT+03:00) Moscow',
      '(GMT+03:30) Tehran',
      '(GMT+04:00) Baku',
      '(GMT+04:00) Volgograd',
      '(GMT+04:00) Muscat',
      '(GMT+04:00) Tbilisi',
      '(GMT+04:00) Yerevan',
      '(GMT+04:30) Kabul',
      '(GMT+05:00) Karachi',
      '(GMT+05:00) Tashkent',
      '(GMT+05:30) Kolkata',
      '(GMT+05:45) Kathmandu',
      '(GMT+06:00) Ekaterinburg',
      '(GMT+06:00) Almaty',
      '(GMT+06:00) Dhaka',
      '(GMT+06:30) Rangoon',
      '(GMT+07:00) Novosibirsk',
      '(GMT+07:00) Bangkok',
      '(GMT+07:00) Jakarta',
      '(GMT+08:00) Krasnoyarsk',
      '(GMT+08:00) Chongqing',
      '(GMT+08:00) Hong Kong',
      '(GMT+08:00) Kuala Lumpur',
      '(GMT+08:00) Perth',
      '(GMT+08:00) Singapore',
      '(GMT+08:00) Taipei',
      '(GMT+08:00) Ulaan Bataar',
      '(GMT+08:00) Urumqi',
      '(GMT+09:00) Irkutsk',
      '(GMT+09:00) Seoul',
      '(GMT+09:00) Tokyo',
      '(GMT+09:30) Adelaide',
      '(GMT+09:30) Darwin',
      '(GMT+10:00) Yakutsk',
      '(GMT+10:00) Brisbane',
      '(GMT+10:00) Canberra',
      '(GMT+10:00) Guam',
      '(GMT+10:00) Hobart',
      '(GMT+10:00) Melbourne',
      '(GMT+10:00) Port Moresby',
      '(GMT+10:00) Sydney',
      '(GMT+11:00) Vladivostok',
      '(GMT+12:00) Magadan',
      '(GMT+12:00) Auckland',
      '(GMT+12:00) Fiji',
    ]
  }
  /***Gets icons list */
  getIconList(): string[] {
    return [
      'airplay',
      'album',
      'albums',
      'back-2',
      'bookmarks',
      'box1',
      'box2',
      'browser',
      'calculator',
      'chat',
      'cloud',
      'comment',
      'compass',
      'config',
      'copy-file',
      'credit',
      'date',
      'disk',
      'diskette',
      'display1',
      'display2',
      'door-lock',
      'film',
      'ticket',
      'global',
      'graph',
      'graph1',
      'graph2',
      'headphones',
      'home',
      'id',
      'light',
      'mail',
      'map',
      'map-marker',
      'monitor',
      'network',
      'news-paper',
      'note2',
      'notebook',
      'paperclip',
      'photo',
      'portfolio',
      'print',
      'safe',
      'server',
      'shopbag',
      'shuffle',
      'stopwatch',
      'study',
      'switch',
      'timer',
      'tools',
      'trash',
      'unlock',
      'users',
      'wallet',
      'angle-right',
      'angle-left',
      'angle-down',
      'angle-up',
    ]
  }
  /***Gets Tab options */
  getOptionsObj(): ICategoryOptions {
    const optionsObj: ICategoryOptions = {
      values: [
        {
          id: 1,
          name: 'Add Blank Tab',
        },
        {
          id: 2,
          name: 'Copy Default Tab',
        },
        {
          id: 3,
          name: 'Copy Existing Tab',
        },
        {
          id: 4,
          name: 'Reopen Closed Tab',
        },
      ],
    }
    optionsObj.value = optionsObj.values[0]
    return optionsObj
  }
  /***Return dateFormats **/
  dateFormats(): string[] {
    return ['YYYY/MM/DD', 'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MM-YYYY', 'YYYY.MM.DD', 'MM.DD.YYYY', 'DD.MM.YYYY']
  }
  /***Return multiLanguage **/
  multiLanguages(): string[] {
    return ['English', 'Spanish', 'French']
  }
  /***Return picModes **/
  picModes(): string[] {
    return ['Select Avatar', 'Browse Avatar', 'Use Gravatar']
  }
  /***Gets Tab configuration */
  setTabNameConfiguration(): object {
    return {
      model: '',
      name: '',
      id: 'inputTabName',
      label: 'TAB NAME',
      required: true,
      placeholder: '',
      readOnly: false,
    }
  }
  /***Gets activity filter options */
  filterOptions(): IFilterOptions[] {
    return [
      { id: 0, label: 'Filter by: Add' },
      { id: 1, label: 'Filter by: Edit' },
      { id: 2, label: 'Filter by: Delete' },
      { id: 3, label: 'Filter by: All' },
    ]
  }
  /***Gets activity options */
  activityOptions(): IFilterOptions[] {
    return [
      { id: 0, label: 'Last 10 Activities', isSelected: true },
      { id: 1, label: 'Today' },
      { id: 2, label: 'Last Week' },
      { id: 3, label: 'Last Month' },
      { id: 4, label: 'All' },
    ]
  }

  passwordGenerator(): Observable<string> {
    const observable: Observable<string> = new Observable(observer => {
      this.api.get(URL_CONSTANTS.GET_PASSWORD_SCHEMA, 'Generating Password').subscribe((schema: PasswordSchema) => {
        const passString = PASSWORD_GENERATOR_STRING.LETTERS_STRING
        const numeric = PASSWORD_GENERATOR_STRING.NUMERIC
        const punctuation = schema.symbols.join('')
        let password = ''
        let character = ''
        while (password.length < schema.length) {
          const entity1 = Math.floor(Math.random() * passString.length)
          const entity2 = Math.floor(Math.random() * numeric.length)
          const entity3 = Math.floor(Math.random() * punctuation.length)
          let hold = ''
          if (schema.upperCharacters || schema.lowerCharacters) {
            hold = passString.charAt(entity1)
            hold = password.length % 2 === 0 ? hold.toUpperCase() : hold
            if (schema.upperCharacters) {
              hold = hold.toUpperCase()
            }
            if (schema.lowerCharacters) {
              hold = hold.toLowerCase()
            }
            character += hold
          }
          if (schema.numbers) {
            character += numeric.charAt(entity2)
          }
          character += punctuation.charAt(entity3)
          password = character
        }
        password = password
          .split('')
          .sort(function() {
            return 0.5 - Math.random()
          })
          .join('')

        observer.next(password.substr(0, schema.length))
        observer.complete()
      })
    })

    return observable
  }
  getPasswordSettings(): Observable<PasswordSchema> {
    return this.api.get(URL_CONSTANTS.GET_PASSWORD_SCHEMA, 'Generating Password')
  }
  getALLcompaniesColoumnDefs(): IColumnFilterCompanies {
    return [
      { field: 'name', filter: 'agTextColumnFilter' },
      { field: 'type', sortable: true, filter: 'agTextColumnFilter', hide: true },
      {
        field: 'actions',

        headerName: 'Select Companies',
        cellRendererFramework: SearchCompaniesCellRendererComponent,
        sortable: false,
        filter: '',
      },
    ]
  }

  countriesList(): string[] {
    return new Array(
      'United States',
      'Afghanistan',
      'Albania',
      'Algeria',
      'American Samoa',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antarctica',
      'Antigua and Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia',
      'Bosnia and Herzegowina',
      'Botswana',
      'Bouvet Island',
      'Brazil',
      'British Indian Ocean Territory',
      'Brunei Darussalam',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Cape Verde',
      'Cayman Islands',
      'Central African Republic',
      'Chad',
      'Chile',
      'China',
      'Colombia',
      'Comoros',
      'Congo',
      'Congo, the Democratic Republic of the',
      'Cook Islands',
      'Costa Rica',
      'Cote d`Ivoire',
      'Croatia (Hrvatska)',
      'Cuba',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'East Timor',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Ethiopia',
      'Falkland Islands (Malvinas)',
      'Faroe Islands',
      'Fiji',
      'Finland',
      'France',
      'France Metropolitan',
      'French Guiana',
      'French Polynesia',
      'French Southern Territories',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Gibraltar',
      'Greece',
      'Greenland',
      'Grenada',
      'Guadeloupe',
      'Guam',
      'Guatemala',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Heard and Mc Donald Islands',
      'Holy See (Vatican City State)',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran (Islamic Republic of)',
      'Iraq',
      'Ireland',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Korea, Democratic People`s Republic of',
      'Korea, Republic of',
      'Kuwait',
      'Kyrgyzstan',
      'Lao, People`s Democratic Republic',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libyan Arab Jamahiriya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macau',
      'Macedonia, The Former Yugoslav Republic of',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Martinique',
      'Mauritania',
      'Mauritius',
      'Mayotte',
      'Mexico',
      'Micronesia, Federated States of',
      'Moldova, Republic of',
      'Monaco',
      'Mongolia',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'Netherlands Antilles',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Niue',
      'Norfolk Island',
      'Northern Mariana Islands',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Pitcairn',
      'Poland',
      'Portugal',
      'Puerto Rico',
      'Qatar',
      'Reunion',
      'Romania',
      'Russian Federation',
      'Rwanda',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Vincent and the Grenadines',
      'Samoa',
      'San Marino',
      'Sao Tome and Principe',
      'Saudi Arabia',
      'Senegal',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia (Slovak Republic)',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Georgia and the South Sandwich Islands',
      'Spain',
      'Sri Lanka',
      'St. Helena',
      'St. Pierre and Miquelon',
      'Sudan',
      'Suriname',
      'Svalbard and Jan Mayen Islands',
      'Swaziland',
      'Sweden',
      'Switzerland',
      'Syrian Arab Republic',
      'Taiwan, Province of China',
      'Tajikistan',
      'Tanzania, United Republic of',
      'Thailand',
      'Togo',
      'Tokelau',
      'Tonga',
      'Trinidad and Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks and Caicos Islands',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom',
      'United States Minor Outlying Islands',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Venezuela',
      'Vietnam',
      'Virgin Islands (British)',
      'Virgin Islands (U.S.)',
      'Wallis and Futuna Islands',
      'Western Sahara',
      'Yemen',
      'Yugoslavia',
      'Zambia',
      'Zimbabwe'
    )
  }

  statesList(): IStatesIterface[] {
    return [
      { name: 'Alabama', abbreviation: 'AL', id: 1 },
      { name: 'Alaska', abbreviation: 'AK', id: 2 },
      { name: 'American Samoa', abbreviation: 'AS', id: 3 },
      { name: 'Arizona', abbreviation: 'AZ', id: 4 },
      { name: 'Arkansas', abbreviation: 'AR', id: 5 },
      { name: 'California', abbreviation: 'CA', id: 6 },
      { name: 'Colorado', abbreviation: 'CO', id: 7 },
      { name: 'Connecticut', abbreviation: 'CT', id: 8 },
      { name: 'Delaware', abbreviation: 'DE', id: 9 },
      { name: 'District Of Columbia', abbreviation: 'DC', id: 10 },
      { name: 'Federated States Of Micronesia', abbreviation: 'FM', id: 11 },
      { name: 'Florida', abbreviation: 'FL', id: 12 },
      { name: 'Georgia', abbreviation: 'GA', id: 13 },
      { name: 'Guam', abbreviation: 'GU', id: 14 },
      { name: 'Hawaii', abbreviation: 'HI', id: 15 },
      { name: 'Idaho', abbreviation: 'ID', id: 16 },
      { name: 'Illinois', abbreviation: 'IL', id: 17 },
      { name: 'Indiana', abbreviation: 'IN', id: 18 },
      { name: 'Iowa', abbreviation: 'IA', id: 19 },
      { name: 'Kentucky', abbreviation: 'KY', id: 20 },
      { name: 'Louisiana', abbreviation: 'LA', id: 21 },
      { name: 'Maine', abbreviation: 'ME', id: 22 },
      { name: 'Marshall Islands', abbreviation: 'MH', id: 23 },
      { name: 'Maryland', abbreviation: 'MD', id: 24 },
      { name: 'Massachusetts', abbreviation: 'MA', id: 25 },
      { name: 'Michigan', abbreviation: 'MI', id: 26 },
      { name: 'Minnesota', abbreviation: 'MN', id: 27 },
      { name: 'Mississippi', abbreviation: 'MS', id: 28 },
      { name: 'Missouri', abbreviation: 'MO', id: 29 },
      { name: 'Montana', abbreviation: 'MT', id: 30 },
      { name: 'Nebraska', abbreviation: 'NE', id: 31 },
      { name: 'Nevada', abbreviation: 'NV', id: 32 },
      { name: 'New Hampshire', abbreviation: 'NH', id: 33 },
      { name: 'New Jersey', abbreviation: 'NJ', id: 34 },
      { name: 'New Mexico', abbreviation: 'NM', id: 35 },
      { name: 'New York', abbreviation: 'NY', id: 36 },
      { name: 'North Carolina', abbreviation: 'NC', id: 37 },
      { name: 'North Dakota', abbreviation: 'ND', id: 38 },
      { name: 'Northern Mariana Islands', abbreviation: 'MP', id: 39 },
      { name: 'Ohio', abbreviation: 'OH', id: 40 },
      { name: 'Oklahoma', abbreviation: 'OK', id: 41 },
      { name: 'Oregon', abbreviation: 'OR', id: 42 },
      { name: 'Palau', abbreviation: 'PW', id: 43 },
      { name: 'Pennsylvania', abbreviation: 'PA', id: 44 },
      { name: 'Puerto Rico', abbreviation: 'PR', id: 45 },
      { name: 'Rhode Island', abbreviation: 'RI', id: 46 },
      { name: 'South Carolina', abbreviation: 'SC', id: 47 },
      { name: 'South Dakota', abbreviation: 'SD', id: 48 },
      { name: 'Tennessee', abbreviation: 'TN', id: 49 },
      { name: 'Texas', abbreviation: 'TX', id: 50 },
      { name: 'Utah', abbreviation: 'UT', id: 51 },
      { name: 'Vermont', abbreviation: 'VT', id: 52 },
      { name: 'Virgin Islands', abbreviation: 'VI', id: 53 },
      { name: 'Virginia', abbreviation: 'VA', id: 54 },
      { name: 'Washington', abbreviation: 'WA', id: 55 },
      { name: 'West Virginia', abbreviation: 'WV', id: 56 },
      { name: 'Wisconsin', abbreviation: 'WI', id: 57 },
      { name: 'Wyoming', abbreviation: 'WY', id: 58 },
    ]
  }
}
