import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { Router } from '@angular/router'
import { SharedService } from '@app/modules/shared/shared.service'
import { FOOTER_CONST } from '@core/constants/footer.constants'
import { SharedDataService } from '@core/services/shared-data.service'
@Component({
  selector: 'app-organization-expiray-modal',
  templateUrl: './organization-expiray-modal.component.html',
  styleUrls: ['./organization-expiray-modal.component.css'],
})
export class OrganizationExpirayModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public userData, public sharedDataService: SharedDataService,
              private readonly router: Router,
              public sharedService: SharedService, public dialog: MatDialogRef<OrganizationExpirayModalComponent>) { }

  ngOnInit() {
  }
  subscribe() {
    this.dialog.close()
    this.router.navigateByUrl(FOOTER_CONST.REGISTER_ROUTE).catch()
  }
  logout() {
    this.dialog.close()
    this.sharedService.logout()
  }

}
