import { Component, isDevMode,  OnInit } from '@angular/core'
import {  Router } from '@angular/router'
import { WALKMAN_SCRIPT } from '@app/constants/app.constants'

import { URL_CONSTANTS } from './modules/core/constants/url-constants'
import { SharedDataService } from './modules/core/services/shared-data.service'
import { TitleService } from './modules/core/services/title.service'
import { LoginService } from './modules/login/services/login.service'
@Component({
  selector: 'app-bootstrap',
  templateUrl: './bootstrap.component.html',
})
export class BootstrapComponent implements OnInit {
  constructor(private readonly loginService: LoginService, private readonly sharedDataService: SharedDataService,
              private readonly titleService: TitleService,
              private readonly router: Router
              ) {
    this.loadScript()
  }
  ngOnInit() {
    this.titleService.init()
    const urlParams = new URLSearchParams(window.location.search)
    if (!this.sharedDataService.getUser() &&
      (window.location.pathname === '/app/companyPods' || window.location.pathname === '/app/companyAssetsPods')) {
      if (urlParams.get('pods') || urlParams.get('asset')) {
        const podValue = urlParams.get('pods')  ? urlParams.get('pods') : 'companyAssetsPods'
        this.loginService.createUrlForLogin(podValue)
      } else if (urlParams.get('screenName') && urlParams.get('screenName') === 'favoriteAssets') {
        this.loginService.createUrlForLogin('companyPods')
      } else {
        this.sharedDataService.setURL(document.URL)
        this.router
          .navigate([URL_CONSTANTS.LOGIN])
          .then(routeData => {})
          .catch(() => {})
      }
    }
  }

  loadScript() {
    if (window.location.href.indexOf('companyPods') === -1 && window.location.href.indexOf('companyAssetsPods') === -1) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.innerText = isDevMode() ? WALKMAN_SCRIPT.TEST : WALKMAN_SCRIPT.PROD
      document.body.appendChild(script)
    }
  }
}
