import { Injectable } from '@angular/core'
import { SharedService } from '@app/modules/shared/shared.service'
import { ApiService } from '@app/services/api.service'

import { SharedDataService } from '../../../../../../../src/app/modules/core/services/shared-data.service'
import { UtilService } from '../../../../../../../src/app/modules/core/services/utils.service'
import { COMPANY_PODS } from '../constants/charts-config'
@Injectable({
  providedIn: 'root',
})
export class PodsService {

  constructor(private readonly baseService: ApiService, private readonly sharedDataService: SharedDataService,
              private readonly sharedService: SharedService,
              private readonly utilService: UtilService) { }
  getCompanyByPsaId(id) {
    return this.baseService.get(`companies/pods/${id}`, 'Company Connectwise Pods', true)
  }
  getAssetByPsaId(assets: string, id: string, screenName: string) {
    return this.baseService.get(`${assets}/asset/${id}/${screenName}`, 'Assets Connectwise Pods', true)
  }
  checkPodsSession() {
    const podsInterval =   setInterval(() => {
      if (this.utilService.isUndefined(this.sharedDataService.getUser())) {
        this.sharedService.logout('sessionExpiry')
        clearInterval(podsInterval)

      }
    }, COMPANY_PODS.INTERVAL_TIME)
  }
}
