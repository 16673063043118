import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { SearchTagsModalComponent } from '@app/components/search-tags-modal/search-tags-modal.component'
import { MODAL_CONSTANTS, PREPARED_BUTTONS } from '@app/constants/ui-components.constants'
import { ConfirmationMessagesComponent, PopupAlertComponent } from 'ui-components'
import { ButtonOptions } from 'ui-components/lib/interfaces/default-button.interface'

import { MODAL_WIDTH_CONSTANTS } from '../../app/modules/core/constants/constants'

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  deleteDialogButtonsArray: ButtonOptions[]

  constructor(public dialog: MatDialog) {
    this.deleteDialogButtonsArray = [PREPARED_BUTTONS.DELETE_BUTTON, PREPARED_BUTTONS.CANCEL_BUTTON]
  }

  showSearchTagsModal(tagsArray: string[] = [], isDisable = false) {
    const dialogRef = this.dialog.open(SearchTagsModalComponent, {
      width: MODAL_CONSTANTS.MODAL_WIDTH,
      data: {
        tagsArray,
        isDisable,
      },
    })
    return dialogRef.afterClosed()
  }

  showDeleteConfirmationModal(title: string, body: string, buttonsArray = [], innerHTML?: boolean) {
    return this.dialog
      .open(ConfirmationMessagesComponent, {
        data: {
          title,
          body,
          buttonsArray: buttonsArray.length ? buttonsArray : this.deleteDialogButtonsArray,
          innerHTML,
        },
        width: MODAL_WIDTH_CONSTANTS.SMALL,
      })
      .afterClosed()
  }

  showAlertNotification(mode: string, message: string, title: string, body: string, innerHTML?: boolean) {
    innerHTML = innerHTML ? true : false
    this.dialog.open(PopupAlertComponent, {
      width: MODAL_CONSTANTS.MODAL_WIDTH,
      data: {
        mode,
        title,
        body,
        mainMessage: message,
        innerHTML,
      },
    })
  }
}
