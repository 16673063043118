import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'

import { PodsService } from '../../services/pods.service'
@Component({
  selector: 'app-company-pods-parent',
  templateUrl: './pods-parent.component.html',
  styleUrls: ['./pods-parent.component.scss'],
})
export class PodsParentComponent implements OnInit, OnDestroy {
  companyId: string
  subscription: Subscription
  isLoading: boolean
  screenName: string
  isError: boolean
  constructor(
    private readonly podsService: PodsService,
    private readonly activatedRoute: ActivatedRoute) {
    this.subscription = new Subscription()
    this.isLoading = false
    this.isError = false
    this.screenName = this.activatedRoute.snapshot.queryParams.screenName
    this.podsService.checkPodsSession()
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.companyId) {
      this.subscription.add(this.podsService.getCompanyByPsaId(this.activatedRoute.snapshot.queryParams.companyId).subscribe(
        data => {
          if (Object.keys(data).length !== 0) {
            this.isLoading = true
            this.companyId = data.uuid
          } else {
            this.isError = true
          }

        }, error => {
          this.isLoading = true
        }))
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

}
