<mat-form-field>
  <mat-label>Select Filter</mat-label>
  <mat-select [(ngModel)]="currentValue" (selectionChange)="valueChanged($event.value)">
  <mat-select-trigger>
     <span *ngIf="currentValue == 1"> <img class="table-icons" src="images-library/icon-library/{{IMAGE_TRUE}}.svg"/>{{IMAGE_TEXT_TRUE}}</span>
     <span *ngIf="currentValue == 0"> <img class="table-icons" src="images-library/icon-library/{{IMAGE_FALSE}}.svg"/>{{IMAGE_TEXT_FALSE}}</span>
  </mat-select-trigger>
    <mat-option class="ag-grid-select-filter">None</mat-option>
    <mat-option class="ag-grid-select-filter" value="1"><img class="table-icons"  src="images-library/icon-library/{{IMAGE_TRUE}}.svg"/>{{IMAGE_TEXT_TRUE}}</mat-option>
    <mat-option class="ag-grid-select-filter" value="0"><img class="table-icons"  src="images-library/icon-library/{{IMAGE_FALSE}}.svg"/>{{IMAGE_TEXT_FALSE}}</mat-option>
  </mat-select>
</mat-form-field>
