import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, Renderer2  } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { ICompaniesList } from '@core/interfaces/companies'
import { IUsers } from '@core/interfaces/users'
import * as cloneDeep from 'lodash/cloneDeep'
import { Subscription } from 'rxjs'

import { TicketsDialogComponent } from '../../../../shared/component/tickets-dialog/tickets-dialog.component'
import { CoreService } from '../../../services/core.service'
import { SharedDataService } from '../../../services/shared-data.service'
import { UtilService } from '../../../services/utils.service'
@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompaniesListComponent implements OnDestroy {
  isSearchFlag: boolean
  isAdd: boolean
  companiesList: ICompaniesList[]
  hasCompanyViewFlag: boolean
  filteredCompanies: ICompaniesList[]
  companiesListCopy: ICompaniesList[]
  searchCompany: string
  companyData: ICompaniesList
  searchForm: FormGroup
  isLoading: boolean
  isSmallLodaing: boolean
  subscriptions: Subscription
  pageNumber: number
  users: IUsers
  isEmptyResponse: boolean
  constructor(private readonly coreService: CoreService,
              private readonly sharedDataService: SharedDataService,
              private readonly utilService: UtilService,
              private readonly dialog: MatDialog,
              private readonly renderer: Renderer2,
              private readonly changeDetector: ChangeDetectorRef) {
    this.subscriptions = new Subscription()
    this.isSearchFlag = false
    this.hasCompanyViewFlag = false
    this.filteredCompanies = []
    this.searchCompany = ''
    this.pageNumber = 0
    this.searchForm = new FormGroup({
      query: new FormControl(),
    })
    this.isSmallLodaing = false
    this.isEmptyResponse = false
  }
  /***On load calls fetch companies function and scroll event */
  ngOnInit() {
    this.users = this.sharedDataService.getUser()
    this.fetchCompanies(this.pageNumber)
    this.configureRoles()
    this.renderer.listen(document.getElementById('sidePullCompanyScroll'), 'scroll', event => {
      const element = document.getElementById('sidePullCompanyScroll')
      if (element.scrollTop !==  element.scrollHeight) {
        if (Math.ceil(element.scrollTop) === (element.scrollHeight - element.offsetHeight)) {
          if (this.isLoading || this.isSmallLodaing || this.isEmptyResponse) {
            return
          }
          this.pageNumber++
          this.fetchCompanies(this.pageNumber)
        }
      }
    })
  }
  /***Sends http request to get companies data */
  fetchCompanies(pageNumber) {
    this.isLoading = pageNumber === 0 ?  true : false
    this.isSmallLodaing = pageNumber !== 0 ?  true : false
    let companyArray = []
    this.changeDetector.markForCheck()
    this.subscriptions.add(this.coreService.getCompaniesList(pageNumber).subscribe(response => {
      this.isSmallLodaing = false
      this.isLoading = false
      this.isEmptyResponse = response .length === 0 ? true : false
      companyArray = pageNumber === 0 ?  response : this.companiesList.concat(response)
      this.companiesList = companyArray
      this.sharedDataService.setCompaniesList(this.companiesList)
      this.companiesListCopy = cloneDeep(this.companiesList)
      this.manipulateCompanies()
      this.changeDetector.detectChanges()
    }, error => {
      this.isLoading = false
      this.changeDetector.detectChanges()
    }))
  }

  configureRoles() {
    const userPermissions = this.coreService.getUserRolesConfigurations()
    this.isAdd = userPermissions.isCreate
  }
  /*** Processing on company request response */
  manipulateCompanies() {
    if (this.companiesList && this.companiesList.length > 0) {
      this.companiesList = this.companiesList.sort(this.utilService.dynamicSort('name'))
      if (this.companiesList[0].name) {
        let oldAlphabet = this.companiesList[0].name[0].toUpperCase()
        let newAlphabet = this.companiesList[0].name[0].toUpperCase()
        this.companiesList[0].letter = newAlphabet
        this.companiesList[0].printFlag = true
        for (const obj of Object.keys(this.companiesList)) {
          if (this.companiesList[obj].name) {
            newAlphabet = this.companiesList[obj].name[0].toUpperCase()
            if (oldAlphabet !== newAlphabet) {
              oldAlphabet = newAlphabet
              this.companiesList[obj].letter = newAlphabet
              this.companiesList[obj].printFlag = true
            }
          }
        }
      }
    }
  }

  /*** Filter companies based on search */
  filterCompanies() {
    if (this.searchForm.controls.query.value.length < 3) {
      this.companiesList = this.companiesListCopy
      this.manipulateCompanies()

      return
    }
    this.isLoading = true

    this.filteredCompanies = []
    this.subscriptions.add(this.coreService.searchCompaniesList(this.searchForm.controls.query.value).subscribe(
      response => {
        this.isLoading = false
        this.filteredCompanies = response
        this.companiesList = this.filteredCompanies
        this.changeDetector.detectChanges()
    }, error => {
      this.isLoading = false
      this.changeDetector.detectChanges()
    }))
  }
  /*** Sets current company to companyData object */
  setCurrentCompany(item: ICompaniesList) {
    this.hasCompanyViewFlag = true
    this.companyData = item
  }
  /*** View / hide company page */
  getFlag(value: boolean) {
    this.hasCompanyViewFlag = value
  }
  /*** Open ticket creation dialog box */
  onCreateTicket() {
    this.dialog.open(TicketsDialogComponent, {
      data: { title: 'Create New Ticket' },
      width: '600px',
    })
  }
  /*** Enable / disable search flag */
  onSearch() {
    this.isSearchFlag = !this.isSearchFlag
  }
  /*** Unsubscribe all subscriptions when component is destroyed */
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

}
