import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { cloneDeep } from 'lodash'

import { BUTTONS } from '../../constants/ui-components.constants'

@Component({
  selector: 'app-search-tags-modal',
  templateUrl: './search-tags-modal.component.html',
})
export class SearchTagsModalComponent {
  isDisabled: boolean
  tags: string[]
  organizationTags: string[]
  options: string[]
  placeHolder: string
  buttons
  constructor(private readonly dialogRef: MatDialogRef<SearchTagsModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.tags = cloneDeep(data.tagsArray)
    this.options = []
    this.organizationTags = []
    this.isDisabled = data.isDisable
    this.buttons = BUTTONS
    this.placeHolder = this.isDisabled && this.tags.length <= 0 ? 'No Tags Found' : 'Tags'
  }

  /*** Dialog save button event  */
  onSave() {
    this.dialogRef.close(this.tags)
  }

  /*** Dialog cancel button event */
  onCancel() {
    this.dialogRef.close(this.data.tagsArray)
  }

  /*** Tags updated when entered  */
  updateTags(event) {
    this.tags = event.tags ? event.tags : event
    this.options = event.deletedTags ? event.deletedTags : this.options
    if (this.options.length > 0) {
      const index = this.tags.length
      this.updateOptions(this.tags[index - 1])
    }

    this.organizationTags = [...new Set([...this.tags, ...this.options])]
  }

  /*** Update tags index */
  updateOptions(tag) {
    const index = this.options.indexOf(tag)
    if (index !== -1) this.options.splice(index, 1)
  }
}
