import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'
import { ApiService } from '@app/services/api.service'

import { stringConstants } from '../../../constants/constants'
import { GirdActionService } from '../../services/grid-actions.service'

@Component({
  selector: 'app-password-cell-renderer',
  templateUrl: './password-cell-renderer.component.html',
})
export class PasswordCellRendererComponent implements AgRendererComponent {
  params // used for optional params that are added dynamically for different types
  password: string
  isShow: boolean
  baseURL
  constructor(public gridActionsService: GirdActionService, private readonly apiService: ApiService) {
    this.isShow = false
  }

  agInit(params: ICellRendererParams): void {
    this.params = params
    if (this.params.baseURL && this.params.propertyName) {
      this.params.companyId ?
     this.baseURL = `/${this.params.baseURL.replace('$companyId',  this.params.companyId)}/${this.params.data[this.params.propertyName]}`
    :
    this.baseURL = `/${this.params.baseURL.replace('company/$companyId/', '')}/${this.params.data[this.params.propertyName]}`

    }
    this.password = 'undefined'
    this.params.data.password = new Array(this.password.length + 1).join('*')
  }
  togglePassword(event: Event) {
    event.preventDefault()
    this.isShow = !this.isShow

    if (!this.isShow) {
      return (this.params.data.password = new Array(this.password.length + 1).join('*'))
    }
    this.apiService
      .get(`passwords/company/${this.params.data.companyUuid}/${this.params.data.uuid}/view-password`, 'Password')
      .toPromise()
      .then(password => {
        this.params.data.password = password.password
      })
      .catch(error => {
        this.params.data.password = 'undefined'
      })
  }

  refresh(params: ICellRendererParams): boolean {
    return false
  }
  copyToClipboard(value: string) {
    const copyText = document.createElement('textarea')
    document.body.appendChild(copyText)
    copyText.value = value
    copyText.select()
    document.execCommand('copy')
    document.body.removeChild(copyText)
  }

  copyPassword() {
    if (!this.isShow) {
      this.apiService
        .get(`passwords/company/${this.params.data.companyUuid}/${this.params.data.uuid}/view-password`, 'Password')
        .toPromise()
        .then(password => {
          this.copyToClipboard(password.password)
        })
        .catch(error => {})
    } else {
      this.copyToClipboard(this.params.data.password)
    }
  }

  nameHandler(event: Event) {
    event.preventDefault()
    this.gridActionsService.setSelectedAction(stringConstants.DEFAULT_DATAGRID_NAVIGATE_TO_PASSWORD, this.params.data, this.params.rowIndex)
  }

  urlHandler(event) {
    event.preventDefault()
    this.gridActionsService.setSelectedAction(stringConstants.DEFAULT_DATAGRID_URL, this.params.data, this.params.rowIndex)
  }
}
