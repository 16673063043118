import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SharedDataService } from '@app/modules/core/services/shared-data.service'
import { BehaviorSubject, Observable } from 'rxjs'

import { IDiscovererSystem } from '../interfaces/discoverer-system.interface'
import { IDiscoverers } from '../interfaces/intgeration-list.interface'
import { ApiService } from '../services/api.service'
@Injectable({
  providedIn: 'root',
})
export class AdlService extends ApiService {
  discoverer: BehaviorSubject<{}>

  selectedDiscoverer: any  /*** USE ANY TYPE DUE TO DYNAMIC DATA TYPE */ // tslint:disable-line
  constructor(http: HttpClient, sharedService: SharedDataService) {
    super(http, sharedService)
    this.discoverer = new BehaviorSubject({})
    this.selectedDiscoverer = this.discoverer.asObservable()
  }

  /***Change discover type */
  change_discoverer(discoverer) {
    this.discoverer.next(discoverer)
  }

  /***Gets all discoverer systems from the server */
  getAllDiscoverers(): Observable<IDiscoverers[]> {
    const url = 'assetdiscovery/discoverers?attributionTypes=["Child", "None"]'
    return this.get(url, 'Asset Discovery')
  }

 /***Gets all discoverer systems from the server */
  getDiscovererSystems(
    conditions: string,
    companyUuid: string,
    pageNo?: number,
    pageSize?: number,
    sortBy?: string,
    order?: string
  ): Observable<IDiscovererSystem[]> {
    const url = `assetdiscovery/company/${companyUuid}/discoverersystems?pageNo=0&pageSize=-1`
    return this.get(url + `&conditions=${conditions}`, 'Asset Discovery')
  }
}
