import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {

  transform(value, term) {
    if (value) {
      return value.filter(x => x.assetName.toLowerCase().startsWith(term.toLowerCase()) ||
        x.recordName.toLowerCase().startsWith(term.toLowerCase()))
    }
  }

}
