import { Injectable } from '@angular/core'
import { IRightSideNavBarDrawer } from '@app/modules/core/interfaces/right-side-navbar-drawer'
import { ISelect } from 'app/interfaces/ui-components.interface'
import { BehaviorSubject, Observable, Subject } from 'rxjs'

import { ApiService } from '../../../services/api.service'
import { RIGHT_SIDE_BAR } from '../constants/constants'
import { URL_CONSTANTS } from '../constants/url-constants'
import { IAttachments } from '../interfaces/attachments'
import { IButtonIcon } from '../interfaces/button-icons'
import { ILinkedItems } from '../interfaces/linked-items'
import { ILocalNotes } from '../interfaces/local-notes'
import { ILocalPasswords } from '../interfaces/local-passwords'

@Injectable({
  providedIn: 'root',
})
export class RightSideBarService {

  restoreObserver = new Subject()
  updateRevision$ = new Subject()

  constructor(private readonly apiService: ApiService) { }

  getPasswords(url: string): Observable<ILocalPasswords[]> {
    return this.apiService.get(url, 'Passwords')
  }

  addPassword(item: ILocalPasswords, url: string): Observable<ILocalPasswords> {
    return this.apiService.add(url, item)
  }

  editPassword(item: ILocalPasswords, url: string): Observable<ILocalPasswords> {
    return this.apiService.edit(url, item)
  }

  deleteItem(url: string): Observable<object> {
    return this.apiService.delete(url)
  }

  getNotes(url: string): Observable<ILocalNotes[]> {
    return this.apiService.get(url, 'Notes')
  }

  addNote(item: ILocalNotes, url: string): Observable<ILocalNotes> {
    return this.apiService.add(url, item)
  }

  editNote(item: ILocalNotes, url: string): Observable<ILocalNotes> {
    return this.apiService.edit(url, item)
  }

  getLinkedItems(url: string): Observable<ILinkedItems[]> {
    return this.apiService.get(url, 'Linked items')
  }

  addLinkedItem(item: ILinkedItems, url: string): Observable<ILinkedItems> {
    return this.apiService.add(url, item)
  }

  getSignedUrl(url: string) {
    return this.apiService.get(url, 'signedUrl')
  }

  getBlob(url: string) {
    return this.apiService.getBlobResponse(url)
  }

  getAttachments(url: string): Observable<IAttachments[]> {
    return this.apiService.get(url, 'Attachments')
  }

  uploadFile(file: IAttachments, url: string): Observable<IAttachments> {
    return this.apiService.add(url, file)
  }

  // tslint:disable-next-line: no-any
  s3ImageUpload(file: any, url: string): Observable<any> {
    return this.apiService.s3ImageUploadSignedUrl(url, file)
  }

  getCustomTemplatesMetaDataList(url: string) {
    return this.apiService.get(url, 'Templates')
  }

  createCloseButton() {
    return [
      {
        tooltipText: RIGHT_SIDE_BAR.CLOSE, iconClassName: RIGHT_SIDE_BAR.CROSS.toLowerCase(),
        buttonName: RIGHT_SIDE_BAR.CLOSE.toLowerCase(),
      },
    ]
  }

  createButtons(type: string): IButtonIcon[] {
    if (type === RIGHT_SIDE_BAR.CLOSE) {
      return [
        {
          tooltipText: RIGHT_SIDE_BAR.SAVE, iconClassName: RIGHT_SIDE_BAR.SAVE.toLowerCase(),
          buttonName: RIGHT_SIDE_BAR.SAVE.toLowerCase(),
        },
        {
          tooltipText: RIGHT_SIDE_BAR.CLOSE, iconClassName: RIGHT_SIDE_BAR.CROSS.toLowerCase(),
          buttonName: RIGHT_SIDE_BAR.CLOSE.toLowerCase(),
        },
      ]
    }
    if (type === RIGHT_SIDE_BAR.SETTING) {
      return [
        {
          tooltipText: RIGHT_SIDE_BAR.SAVE, iconClassName: RIGHT_SIDE_BAR.SETTING_CLASS,
          buttonName: RIGHT_SIDE_BAR.SETTING.toLowerCase(),
        },
        {
          tooltipText: RIGHT_SIDE_BAR.CLOSE, iconClassName: RIGHT_SIDE_BAR.CROSS.toLowerCase(),
          buttonName: RIGHT_SIDE_BAR.CLOSE.toLowerCase(),
        },
      ]
    }
    return [
      {
        tooltipText: RIGHT_SIDE_BAR.DELETE, iconClassName: RIGHT_SIDE_BAR.DELETE.toLowerCase(),
        buttonName: RIGHT_SIDE_BAR.DELETE.toLowerCase(),
      },
      {
        tooltipText: RIGHT_SIDE_BAR.EDIT, iconClassName: RIGHT_SIDE_BAR.EDIT.toLowerCase(),
        buttonName: RIGHT_SIDE_BAR.EDIT.toLowerCase(),
      },
      {
        tooltipText: RIGHT_SIDE_BAR.CLOSE, iconClassName: RIGHT_SIDE_BAR.CROSS.toLowerCase(),
        buttonName: RIGHT_SIDE_BAR.CLOSE.toLowerCase(),
      },
    ]
  }

  getPriorityOptions(): ISelect[] {
    return [
      {
        id: 1,
        label: 'High',
        isSelected: true,
        isDisabled: false,
      },
      {
        id: 2,
        label: 'Medium',
        isSelected: false,
        isDisabled: false,
      },
      {
        id: 3,
        label: 'Low',
        isSelected: false,
        isDisabled: false,
      },
    ]
  }
  // tslint:disable-next-line: no-any
  createLinkedItemsOnCategory(items: any, fieldName?: string): ISelect[] { // used any because items belong to different core/custom lists
    return items.map(item =>
      ({
        id: item.ITBRunBookName ? item.id : item.uuid,
        label: item.name || item.passwordName || item.ITBRunBookName || item[fieldName] || item.ITBTemplateName || item.domainName ||
          `${item.firstName} ${item.lastName}`,
        isSelected: false,
        isDisabled: false,
        type: 'templates',
      }))
  }

  // tslint:disable-next-line: no-any
  createLinkedItemsOnAdlIntegration(items: any): ISelect[] { // used any because items belong to different core/custom lists
    return items.map(item =>
      ({
        id: item.uuid,
        label: item.configurations ? item.configurations.alias : item.id,
        isSelected: false,
        isDisabled: false,
        type: item.label,
      }))
  }

  createEmptyLinkedItem(relatedAsset: string): ILinkedItems {
    return {
      relatedAsset,
      relatedAssetPath: '',
      relatedAssetId: '',
      relatedAssetName: '',
    }
  }

  getLinkedItemsOptions(companyId: string): ISelect[] {
    if (companyId === '1') {
      return [
        {
          id: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.KNOWLEDGEBASE.uuid,
          label: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.KNOWLEDGEBASE.id,
          isSelected: false,
          isDisabled: false,
        },
      ]
    }
    return [
      {
        id: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.CONFIGURATION.uuid,
        label: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.CONFIGURATION.id,
        isSelected: false,
        isDisabled: false,
      },
      {
        id: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.CONTACT.uuid,
        label: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.CONTACT.id,
        isSelected: false,
        isDisabled: false,
      },
      {
        id: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.LOCATION.uuid,
        label: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.LOCATION.id,
        isSelected: false,
        isDisabled: false,
      },
      {
        id: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.PASSWORD.uuid,
        label: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.PASSWORD.id,
        isSelected: false,
        isDisabled: false,
      },
      {
        id: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.KNOWLEDGEBASE.uuid,
        label: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.KNOWLEDGEBASE.id,
        isSelected: false,
        isDisabled: false,
      },
      {
        id: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.DOMAIN_TRACKER.uuid,
        label: RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.DOMAIN_TRACKER.id,
        isSelected: false,
        isDisabled: false,
      },
    ]
  }

  reStoreRevision(assetType, assetId, companyId, dataToRestore) {
    if (assetType === 'templates/templatesData') {
      return this.apiService.edit(`${assetType}/restore/${assetId}?companyId=${companyId}`, dataToRestore)
    }
    if (assetType === 'trackers/whois') {
      return this.apiService.edit(`trackers/revisions/whois/restore/${assetId}?companyId=${companyId}`, dataToRestore)
    }
    return this.apiService.edit(`${assetType}/revisions/restore/${assetId}?companyId=${companyId}`, dataToRestore)
  }

  getRevisionsLogs(asset, assetId, pageNo?: number, pageSize?: number) {
    if (asset === 'trackers/whois') {
      asset = 'whois'
    }

    if (asset === 'templates/templatesData') {
      asset = 'assettemplatedata'
    }
    return this.apiService
      // tslint:disable-next-line: max-line-length
      .get(`revisions/${asset}/${assetId}?pageNo=${pageNo}&pageSize=${pageSize}&fields={"ITBUpdatedBy":1, "updatedAt": 1, "updatedBy": 1, "operationType": 1}&order=desc&sortBy=updatedAt`,
        'revisions logs')
  }

  getRevisionsHistory(asset, assetId, pageNo, pageSize) {
    if (asset === 'trackers/whois') {
      asset = 'whois'
    }

    if (asset === 'templates/templatesData') {
      asset = 'assettemplatedata'
    }
    return this.apiService
      .get(`revisions/${asset}/${assetId}?pageNo=${pageNo}&pageSize=${pageSize}&order=desc&sortBy=updatedAt`, 'revisions history')
  }

  getRevisionsCount(asset, assetId, pageNo?: number, pageSize?: number) {
    if (asset === 'trackers/whois') {
      asset = 'whois'
    }
    if (asset === 'templates/templatesData') {
      asset = 'assettemplatedata'
    }
    return this.apiService.get(`revisions/${asset}/${assetId}/count?pageNo=${pageNo}&pageSize=${pageSize}`, 'revisions count')
  }

  getRightSideNavStatus() {
    return this.apiService
      .get(`${URL_CONSTANTS.GET_RIGHT_SIDE_NAVBAR_STATUS}metadata/navbar`, 'Nav status')
    // return this.http.get(`${END_POINTS.GET_RIGHT_SIDE_NAVBAR_STATUS}changesidebarstate/7513/49bd0da3-0d9e-4252-86ba-6f4907fcf160`)
  }

  setRightSideNavStatus(pinStatus: IRightSideNavBarDrawer) {
    return this.apiService.patch(`${URL_CONSTANTS.GET_RIGHT_SIDE_NAVBAR_STATUS}`, pinStatus)
  }

  // dont code after this
  getPasswordList(companyId: string) {
    return this.apiService.get(`passwords/company/${companyId}`, 'Passwords')
  }

  getConfigurationsList(companyId: string) {
    return this.apiService.get(`configurations/company/${companyId}`, 'Configurations')
  }

  getAllLocations(companyId: string) {
    return this.apiService.get(`locations/company/${companyId}`, 'Locations')
  }

  fetchContactTableData(companyId: string) {
    return this.apiService.get(`contacts/company/${companyId}`, 'Contacts')
  }

  getArticlesList(companyId: string) {
    return companyId !== '1' ? this.apiService.get(`articles/company/${companyId}`, 'Knowledgebase') :
      this.apiService.get('articles/global', 'Knowledgebase')
  }

  getDomainsList(companyId: string) {
    return this.apiService.get(`trackers/company/${companyId}/whois`, 'Domains')
  }

  getCustomTemplateList(companyId: string, templateId: string) {
    return this.apiService.get(
      `templates/company/${companyId}/templatesData/${templateId}?templateDataId=${templateId}&companyId=${companyId}`, 'Templates'
      )
  }
}
