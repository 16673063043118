import { DatePipe } from '@angular/common'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
// tslint:disable-next-line: no-duplicate-imports
import { Params } from '@angular/router'
import { IColumnState, IHeaders } from '@app/interfaces/ui-components.interface'
import { SharedService } from '@app/services/shared.service'
import { stringConstants } from '@projects/ag-grid-customized/src/app/constants/constants'
import { GirdActionService } from '@projects/ag-grid-customized/src/app/data-grid/services/grid-actions.service'
import * as _ from 'lodash'
import * as moment from 'moment'
import { Subscription } from 'rxjs'

import { MODAL_WIDTH_CONSTANTS } from '../../../../../../../../src/app/modules/core/constants/constants'

import { dashboardContants } from './../../../company-dashboard/constants/constants'
import { Warranty } from './../../../company-dashboard/interfaces/asset-warranty'
import { Assets } from './../../../company-dashboard/interfaces/company-dashboard'
import { WidgetType } from './../../../company-dashboard/interfaces/menu'
import { RoundButton } from './../../../company-dashboard/interfaces/ui-library'
import { AssetService } from './../../../company-dashboard/services/assets/asset.service'
import { CompanyDashboardService } from './../../../company-dashboard/services/companyDashboard/company-dashboard.service'
import { AssetWarrantyService } from './../../../company-dashboard/services/companyWarranty/asset-warranty.service'
import { config } from './../../constants/charts-config'

@Component({
  selector: 'app-company-pods-assetwarrenty',
  templateUrl: './pods-assetwarrenty.component.html',
  styleUrls: ['./pods-assetwarrenty.component.scss'],
  providers: [GirdActionService],
})
export class PodsAssetwarrentyComponent implements OnInit {
  @Input() companyId: string
  @Input() widgetData: WidgetType
  @ViewChild('warrantyLists', { static: true }) modal
  roundButton: RoundButton[]
  roundButtonwithCross
  wrapperClass: string
  buttonClass
  dropDownArray: string[]
  subscriptions: Subscription
  options: {}
  filter: boolean
  allAssets: Warranty[]
  headername: string
  rowData: Assets[]
  defaultColDef: {}
  columnDefs: IHeaders[]
  filterColumnDefs: IHeaders[]
  isEmpty: boolean
  dialogRef
  userColumnState: IColumnState[]
  filteruserColumnState: IColumnState[]
  isInitialRender: boolean
  filtered: boolean
  status: number
  isGridEmpty: boolean
  isColLoaded: boolean
  isSpinnerLoading: boolean
  isFirst: boolean
  isFilterColLoaded: boolean
  isFilterEmpty: boolean
  isFilterLoading: boolean
  pieChartData
  filterRowData
  isError: boolean
  isData: boolean
  isLoading: boolean
  completeCount: number
  constructor(
    private readonly assetWarrantyService: AssetWarrantyService,
    public assetService: AssetService,
    private readonly companyService: CompanyDashboardService,
    private readonly sharedService: SharedService,
    private readonly gridActionsService: GirdActionService,
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.pieChartData = []
    this.isLoading = true
    this.isData = false
    this.isFirst = true
    this.rowData = []
    this.isGridEmpty = false
    this.isError = false
    this.isEmpty = false
    this.filterRowData = []
    this.isInitialRender = true
    this.subscriptions = new Subscription()
    this.roundButton = []
    this.completeCount = 0
    this.wrapperClass = ''
    this.buttonClass = 'expand'

    this.dropDownArray = [
      dashboardContants.FILTER_EXPIRED,
      dashboardContants.FILTER_SOON,
      dashboardContants.FILTER_ACTIVE,
      dashboardContants.FILTER_UNKNOWN,
    ]
  }

  ngOnInit() {
    this.getAssetWarranty()
    this.subscriptions.add(this.gridActionsService.action.subscribe(action => this.actionClicked(action)))
  }
  getAssetWarranty() {
    this.subscriptions.add(
      this.assetWarrantyService.getCompanyAssetsWarrnatyCounts(this.companyId).subscribe(
        res => {
          this.isData = true
          this.isLoading = false
          if (res instanceof Array && !res.length) {
            this.isData = false
            this.isEmpty = true
            return
          }
          this.isEmpty = res.every(val => !val.value)
          this.isData = this.isEmpty ? false : true
          res.forEach(el => {
            if (el.name === 'Expired') this.pieChartData[0] = el
            if (el.name === 'Soon') this.pieChartData[1] = el
            if (el.name === 'Active') this.pieChartData[2] = el
            if (el.name === 'Unknown') this.pieChartData[3] = el
          })
          this.roundButton = [
            {
              tooltipText: 'Asset Warranty List',
              className: dashboardContants.EDIT_BUTTON_CLASS,
              iconClassName: dashboardContants.EDIT_ICON_CLASSNAME,
              roundButtonName: dashboardContants.EDIT_BUTTON_NAME,
            },
          ]
          this.roundButtonwithCross = [
            {
              tooltipText: 'Close List',
              className: dashboardContants.EDIT_BUTTON_CLASS,
              iconClassName: 'cross',
              roundButtonName: dashboardContants.EDIT_BUTTON_NAME,
            },
          ]
          this.options = config.chartConfig(this.pieChartData, 'Asset Warranty')
        },
        error => {
          this.isLoading = false
          this.isData = false
          this.isEmpty = false
          this.isError = true
        }
      )
    )
  }
  listTrackers(event) {
    this.completeCount = 0
    this.wrapperClass = (this.wrapperClass === '') ? 'slideUp' : ''
    this.isError = false
    this.isFilterLoading = false
    this.isFilterColLoaded = false
    this.isFilterEmpty = false
    this.filter = false
    this.isColLoaded = false
    this.isSpinnerLoading = true
    this.isGridEmpty = false
    this.headername = 'ASSET WARRANTY DETAILS'
    this.defaultColDef = {
      filter: dashboardContants.AG_GRID_FILTER,
    }
    this.columnDefs = this.assetWarrantyService.getCompanyAssetsWarrnatyColDefs(this.companyId)
    this.columnDefs[0] = {
      headerName: 'CONFIGURATION NAME', field: 'name',
      minWidth: 180, filter: 'agTextColumnFilter',
      suppressColumnsToolPanel: true, lockPosition: true,
      cellRenderer: name =>
        `<a  target="_blank" href="/app/company/${this.companyId}/configurations/view/${name.data.uuid}">${name.value}</a>`,
    }
    this.subscriptions.add(
      this.companyService.getCompanyDashboardColumnState('warranty').subscribe(
        response => {
          this.isSpinnerLoading = false
          this.isColLoaded = true
          if (response.length) {
            this.userColumnState = response
          }
          this.isGridEmpty = false
        },
        error => { },
        () => { })
    )
  }
  openModal(event, value) {
    if (value === 0) {
      return
    }
    this.wrapperClass = (this.wrapperClass === '') ? 'slideUp' : ''
    this.isError = false
    this.isColLoaded = false
    this.isFilterLoading = true
    this.isFilterColLoaded = false
    this.filter = true
    this.filterColumnDefs = []
    this.filterColumnDefs = [
      {
        headerName: 'ASSET NAME', field: 'assetName', filter: 'agTextColumnFilter',
        cellRenderer: name =>
         name.data.type === 'assetTemplateData' ?
         // tslint:disable-next-line: max-line-length
         `<a  target="_blank" href="/app/company/${this.companyId}/templates/${name.data.ITBTemplateUuid}/view/${name.data.uuid}">${name.value}</a>`
         :
          `<a  target="_blank" href="/app/company/${this.companyId}/configurations/view/${name.data.uuid}">${name.value}</a>`,
      },
      {
        headerName: 'EXPIRY DATE', field: 'warrantyExpirationDate', filter: 'agTextColumnFilter',
        // tslint:disable-next-line: object-literal-shorthand
        valueFormatter: function(params) {
          let ticketAge
          ticketAge = new DatePipe('en-US').transform(params.value, 'dd/MM/yyyy')
          return ticketAge
        },
      },
      {
        headerName: 'REMAINING DAYS', filter: 'agTextColumnFilter',
        field: 'warrantyExpirationDate',
        // tslint:disable-next-line: object-literal-shorthand
        valueFormatter: function(params) {
          let ticketAge
          ticketAge = params.data.expiration
          return ticketAge
        },
      },
    ]
    this.headername = event
    switch (event) {
      case 'Expired':
        this.status = 1
        break
      case 'Active':
        this.status = 2
        break
      case 'Soon':
        this.status = 3
        break
      case 'Unknown':
        this.status = 4
        break
      default:
    }
    this.subscriptions.add(
      this.companyService.getCompanyDashboardColumnState('filterWarranty').subscribe(
        response => {
          this.isFilterLoading = false
          this.isFilterColLoaded = true
          if (response.length) {
            this.filteruserColumnState = response
          }
          this.isFilterEmpty = false
        },
        error => { },
        () => { }
      )
    )
    this.rowData = []
    this.filterRowData = []
    this.subscriptions.add(
      this.assetService.getFilteredCompanyAsset(this.status, this.companyId).subscribe(
        res => {
          this.filterRowData = res
          this.allAssets = res
          this.isFilterEmpty = this.filterRowData.length === 0 && this.isFirst ? true : false
          this.isFilterColLoaded = this.filterRowData.length === 0 && this.isFirst ? false : true
          this.isFirst = false
        },
        error => {
          this.isError = true
          this.isFilterEmpty = false
          this.isFilterColLoaded = false
          this.isFirst = false
        }
      )
    )
  }
  actionClicked = action => {
    switch (action.actionType) {
      case stringConstants.DEFAULT_DATAGRID_NAVIGATE_TO_VIEW:
        window.open(`app/company/${this.companyId}/configurations/view/${action.data.uuid}`, '_blank')
        break
      default:
        return
    }
  }

  columnStateChanged(state: IColumnState[]) {
    state.forEach((col, i) => {
      col[`${'position'}`] = i
    })
    this.companyService.saveCompanyDashboardColumnState(state, 'warranty').subscribe(
      response => { },
      error => { },
      () => { }
    )
  }
  filterColumnStateChanged(state: IColumnState[]) {
    state.forEach((col, i) => {
      col[`${'position'}`] = i
    })
    this.companyService.saveCompanyDashboardColumnState(state, 'filterWarranty').subscribe(
      response => { },
      error => { },
      () => { }
    )
  }
  getRows(params: Params) {
    Object.keys(params.request.filterModel).length || Object.keys(params.request.sortModel).length
      ? this.gridActionsService.filterChanged$.next(true)
      : this.gridActionsService.filterChanged$.next(false)
    let pageNo: number
    const { startRow, endRow } = params.request
    pageNo = this.setOffset(startRow)
    this.subscriptions.add(
      this.assetService.getAllCompanyAssetCount(this.companyId, params.request).subscribe(data => {
        this.completeCount = data.count
        this.subscriptions.add(
          this.assetService.getAllCompanyAsset(this.companyId, params.request, pageNo).subscribe(
            res => {
              this.allAssets = res
              res = this.getRowData(res)
              this.isGridEmpty = this.rowData.length === 0 && this.isFirst ? true : false
              this.isColLoaded = this.rowData.length === 0 && this.isFirst ? false : true
              this.isFirst = false
              const lastRow = this.completeCount <= endRow ? this.completeCount : -1
              if (this.rowData.length === 0) {
                this.gridActionsService.showNoRowsOverlay()
                params.successCallback([], 0)
                return
              }
              this.gridActionsService.hideNoRowsOverlay()
              params.successCallback(this.rowData, lastRow)
            },
            error => {
              params.failCallback()
              this.isGridEmpty = false
              this.isError = true
              this.isColLoaded = false
              this.isFirst = false
            }
          )
        )
      })
    )
  }
  getRowData(res) {
    this.rowData = []
    res.forEach(element => {
      this.rowData.push({
        uuid: element.uuid,
        configName: element.configName,
        type: element.type,
        status: element.status,
        AssetDate: new DatePipe('en-US').transform(element.AssetDate, 'dd/MM/yyyy'),
        recordStatus: element.recordStatus,
        ipAddress: element.ipAddress,
        maintenanceWindow: element.maintenanceWindow,
        tags: element.tags,
        name: element.name,
        domain: element.domain,
        biosMFG: element.biosMFG,
        biosName: element.biosName,
        upTime: element.upTime,
        lastLoginName: element.lastLoginName,
        serialNumber: element.serialNumber,
        purchaseDate: element.purchaseDate,
        processorName: element.processorName,
        totalMemory: element.totalMemory,
        installDate: element.installDate,
        purchaseBy: element.purchaseBy,
        os: element.os,
        osInfo: element.osInfo,
        osInstallDate: element.osInstallDate,
        dnsInfo: element.dnsInfo,
        routerAddress: element.routerAddress,
        managmentPort: element.managmentPort,
      })
    })
  }
  setOffset(offset: number): number {
    return (offset = 0 ? 1 : offset / 20 + 1)
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
