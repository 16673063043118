import { Injectable } from '@angular/core'
import { backendRoutes } from '@core/constants/constant'
import { Observable } from 'rxjs/index'

import { ApiService } from '../../../services/api.service'
import { ILoginResponse } from '../../login/interface/login-response'
import { CHANGE_PASSWORD_CONSTANTS } from '../constants/constants'
import { IChangePasswordRequest } from '../interfaces/change-password-backend'
import { IChangePasswordColor, IChangePasswordErrorCode } from '../interfaces/change-password-error-code'
import { IChangePassword } from '../interfaces/change-password-interface'
@Injectable({
  providedIn: 'root',
})
export class ChangePasswordService {

  constructor(private readonly baseService: ApiService) { }
  /*** Gets colors  */
  getColor(s: number): IChangePasswordColor {
    const colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0']
    let idx = 0
    if (s <= 10) {
      idx = 0
    } else if (s <= 20) {
      idx = 1
    } else if (s <= 30) {
      idx = 2
    } else if (s <= 40) {
      idx = 3
    } else {
      idx = 4
    }
    return {
      idx: idx + 1,
      col: colors[idx],
    }
  }
  /*** Checks status bar */
  checkBarStatus(uppercaseLetter: boolean, symbol: boolean, numbers: boolean, nospace: boolean, newPassword: string): number {
    const nodes = []
    if (uppercaseLetter) {
      nodes.push(uppercaseLetter)
    }
    if (symbol) {
      nodes.push(symbol)
    }
    if (numbers) {
      nodes.push(numbers)
    }
    if (nospace) {
      nodes.push(nospace)
    }
    if (newPassword.length >= 8) {
      nodes.push('true')
    }
    if (nodes.length === 0) {
      return 0
    }

    return nodes.length
  }
  /***measure strength  */
  calculateStrength(p: string) {
    let force = 0
    const regex = /[@!#$-/:-?{-~!"^_`\[\]]/g
    const lowerLetters = /[a-z]+/.test(p)
    const upperLetters = /[A-Z]+/.test(p)
    const numbers = /[0-9]+/.test(p)
    const noSapce = /^[a-zA-Z0-9-$-/:@!#$-?{-~!"^_`\[\]]*$/.test(p)
    const symbols = regex.test(p)

    const passedMatches = []
    const flags = [lowerLetters, upperLetters, numbers, symbols]
    flags.filter((element, index) => {
      if (element) {
        passedMatches.push(element)
        return true
      }
      return false
    })
    force += 2 * p.length + ((p.length >= 10) ? 1 : 0)

    force += passedMatches.length * 10

    // penality (short password)
    force = (p.length <= 6) ? Math.min(force, 10) : force

    // penality (poor variety of characters)
    force = (passedMatches.length === 1) ? Math.min(force, 10) : force
    force = (passedMatches.length === 2) ? Math.min(force, 20) : force
    force = (passedMatches.length === 3) ? Math.min(force, 40) : force

    return { force, noSapce, symbols, upperLetters, numbers }
  }
  /*** Measures the strenth of the password  */
  changeNewPassworLengthError(errorObject: IChangePasswordErrorCode, confirmNewPassword: string, newPassword: string) {
    if (typeof confirmNewPassword !== 'undefined') {
      if (newPassword !== confirmNewPassword) {
        errorObject.isWrongPasswordText = true
        errorObject.isChecked = true
      }
    }
    errorObject.isChecked = true
    errorObject.isFirstBarStyle = false
    errorObject.isSecondBarStyle = false
    errorObject.isThirdBarStyle = false
    errorObject.isFourthBarStyle = false
    errorObject.isNospaceStyle = false
    errorObject.isSymbolStyle = false
    errorObject.isCapitalLetterStyle = false
    errorObject.isNumberStyle = false
    errorObject.isEightCharStyle = false
    errorObject.isDescriptionStyle = false
    errorObject.hasEightChar = false
    newPassword = ''
    errorObject.hasNumbers = false
    errorObject.isULetter = false
    errorObject.isSsymbol = false
    errorObject.hasNoSpace = false
    errorObject.description = CHANGE_PASSWORD_CONSTANTS.WEEK
    errorObject.isChecked = true
    return {
      errorObject, newPassword,
    }
  }
  /*** Checks new password and return error message in case of error   */
  changeNewPassword(newPassword: string, confirmNewPassword: string, errorObject: IChangePasswordErrorCode) {
    if (newPassword.length === 0) {
      const error = this.changeNewPassworLengthError(errorObject, confirmNewPassword, newPassword)
      errorObject = error.errorObject
      newPassword = error.newPassword
    } else {
      const strengthMeasur = this.calculateStrength(newPassword)
      errorObject.hasNoSpace = strengthMeasur.noSapce
      errorObject.isSsymbol = strengthMeasur.symbols
      errorObject.isULetter = strengthMeasur.upperLetters
      errorObject.hasNumbers = strengthMeasur.numbers
      const force = strengthMeasur.force
      this.getColor(force)

      if (newPassword === confirmNewPassword) {
        errorObject.isWrongPasswordText = false
        errorObject.isChecked = false
      }
      if (errorObject.hasNoSpace) {
        errorObject.isNospaceStyle = true
      }
      if (!errorObject.hasNoSpace) {
        errorObject.isNospaceStyle = false
      }
      if (newPassword.length > 7) {
        errorObject.isEightCharStyle = true
        errorObject.hasEightChar = true
      }
      if (newPassword.length <= 7) {
        errorObject.isEightCharStyle = false
        errorObject.hasEightChar = false
      }
      if (errorObject.isSsymbol) {
        errorObject.isSymbolStyle = true
      }
      if (!errorObject.isSsymbol) {
        errorObject.isSymbolStyle = false
      }
      if (errorObject.isULetter) {
        errorObject.isCapitalLetterStyle = true
      }
      if (!errorObject.isULetter) {
        errorObject.isCapitalLetterStyle = false
      }
      if (errorObject.hasNumbers) {
        errorObject.isNumberStyle = true
      }
      if (!errorObject.hasNumbers) {
        errorObject.isNumberStyle = false
      }
      if (this.checkBarStatus(errorObject.isULetter, errorObject.isSsymbol, errorObject.hasNumbers,
        errorObject.hasNoSpace, newPassword) === 0) {
        newPassword = ''
        errorObject.hasNumbers = false
        errorObject.isULetter = false
        errorObject.isSsymbol = false
        errorObject.hasNoSpace = false
        errorObject.isChecked = true
        errorObject.isFirstBarStyle = false
        errorObject.isSecondBarStyle = false
        errorObject.isThirdBarStyle = false
        errorObject.isFourthBarStyle = false
        errorObject.isEightCharStyle = false
        errorObject.isDescriptionStyle = false
        errorObject.description = CHANGE_PASSWORD_CONSTANTS.WEEK
      }
      if (this.checkBarStatus(errorObject.isULetter, errorObject.isSsymbol,
        errorObject.hasNumbers, errorObject.hasNoSpace, newPassword) === 1) {
        errorObject = this.setChangeNewPasswordFlags(errorObject, true, false, false, false,
          false, CHANGE_PASSWORD_CONSTANTS.WEEK)
      }
      if (this.checkBarStatus(errorObject.isULetter, errorObject.isSsymbol,
        errorObject.hasNumbers, errorObject.hasNoSpace, newPassword) === 2) {
        errorObject = this.setChangeNewPasswordFlags(errorObject, true, false, false, false,
          false, CHANGE_PASSWORD_CONSTANTS.WEEK)
      }
      if (this.checkBarStatus(errorObject.isULetter, errorObject.isSsymbol,
        errorObject.hasNumbers, errorObject.hasNoSpace, newPassword) === 3) {
        errorObject = this.setChangeNewPasswordFlags(errorObject, true, true, false, false,
          true, CHANGE_PASSWORD_CONSTANTS.NORMAL)
      }
      if (this.checkBarStatus(errorObject.isULetter, errorObject.isSsymbol,
        errorObject.hasNumbers, errorObject.hasNoSpace, newPassword) === 4) {
        errorObject = this.setChangeNewPasswordFlags(errorObject, true, true, true, false,
          true, CHANGE_PASSWORD_CONSTANTS.NORMAL)
      }
      if (this.checkBarStatus(errorObject.isULetter, errorObject.isSsymbol,
        errorObject.hasNumbers, errorObject.hasNoSpace, newPassword) === 5) {
        errorObject = this.setChangeNewPasswordFlags(errorObject, true, true, true, true
          , true, CHANGE_PASSWORD_CONSTANTS.STRONG)
      }
    }
    return {
      errorObject, newPassword,
    }
  }
  /*** Sets change password flags  */
  setChangeNewPasswordFlags(errorObject: IChangePasswordErrorCode, isFirstBarStyle: boolean,
                            isSecondBarStyle: boolean, isThirdBarStyle, isFourthBarStyle: boolean,
                            isDescriptionStyle: boolean, description: string
  ): IChangePasswordErrorCode {
    errorObject.isFirstBarStyle = isFirstBarStyle
    errorObject.isSecondBarStyle = isSecondBarStyle
    errorObject.isThirdBarStyle = isThirdBarStyle
    errorObject.isFourthBarStyle = isFourthBarStyle
    errorObject.isDescriptionStyle = isDescriptionStyle
    errorObject.description = description
    return errorObject
  }
  /*** Sends a change password http request  */
  changePasswordCall(passwordObj: IChangePassword): Observable<IChangePasswordRequest> {
    const url = backendRoutes.CHANGE_PASSWORD()
    return this.baseService.patch(url, passwordObj, true)
  }
  resetPasswordCall(url, passwordObj: IChangePassword): Observable<ILoginResponse> {

    return this.baseService.edit(url, passwordObj, true)
  }

}
