import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDialogModule, MatFormFieldModule, MatInputModule, MatMenuModule, MatSelectModule, MatSidenavModule } from '@angular/material'
import { HighlightPipe } from '@app/modules/core/pipes/highlight.pipe'
import { HeaderNotificationService } from '@app/modules/core/services/header-notification.service'
import { AdlEffect } from '@app/store/adl-store/effects/adl.effects'
import { adlMaper } from '@app/store/adl-store/reducers'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { DataGridModule } from '@projects/ag-grid-customized/src/app/data-grid/data-grid.module'
import { GirdActionService } from '@projects/ag-grid-customized/src/app/data-grid/services/grid-actions.service'
import { NgxDropzoneModule } from 'ngx-dropzone'

import { UiComponentsModule } from '../../ui-components.module'
import { TicketsDialogComponent } from '../shared/component/tickets-dialog/tickets-dialog.component'

import { CompaniesService } from './../../../../projects/global-dashboard/companies/companies/services/companies.service'
import { AccessErrorComponent } from './components/access-error/access-error.component'
import { AddCompanyTypeModalComponent } from './components/add-company-type-modal/add-company-type-modal.component'
import {
  AssetStatisticsDialogComponent,
} from './components/asset-statistics-dialog/asset-statistics-dialog.component'
import { AttachmentsComponent } from './components/attachments/attachments.component'
import { ChangePasswordComponent } from './components/change-password/change-password.component'
import { CompanyCreationComponent } from './components/company-creation/company-creation.component'
import { CustomDropzonePreviewComponent } from './components/custom-dropzone-preview/custom-dropzone-preview.component'
import { FooterComponent } from './components/footer/footer.component'
import { FooterModule } from './components/footer/footer.module'
import { GoogleAuthenticationPopupComponent } from './components/google-authentication-popup/google-authentication-popup.component'
import { GranularPermissionComponent } from './components/granular-permission-dialog/granular-permission.component'
import { HeaderComponent } from './components/header/header.component'
import { LinkedItemsFormComponent } from './components/linked-items/linked-items-form/linked-items-form.component'
import { LinkedItemsListComponent } from './components/linked-items/linked-items-list/linked-items-list.component'
import { LinkedItemsComponent } from './components/linked-items/linked-items.component'
import { LocalNotesFormComponent } from './components/local-notes/local-notes-form/local-notes-form.component'
import { LocalNotesListComponent } from './components/local-notes/local-notes-list/local-notes-list.component'
import { LocalNotesComponent } from './components/local-notes/local-notes.component'
import { LocalPasswordsFormComponent } from './components/local-passwords/local-passwords-form/local-passwords-form.component'
import { LocalPasswordsListComponent } from './components/local-passwords/local-passwords-list/local-passwords-list.component'
import { LocalPasswordsComponent } from './components/local-passwords/local-passwords.component'
import { LogOutConfirmationComponent } from './components/logout-confirmation-dialog/logout-confirmation.component'
import { NotFoundComponent } from './components/not-found/not-found.component'
import { NotificationComponent } from './components/notification/notification.component'
import { ProfileComponent } from './components/profile/profile.component'
import { RemoteLoginComponent } from './components/remote-login/remote-login.component'
import { RestoreDeletedCompaniesComponent } from './components/restore-deleted-companies/restore-deleted-companies.component'
import { RevisionTableBoxComponent } from './components/revisions/revision-table-box/revision-table-box.component'
import { RevisionsComponent } from './components/revisions/revisions.component'
import { RightSideBarComponent } from './components/right-side-bar/right-side-bar.component'
import {
  SearchCompaniesCellRendererComponent,
} from './components/search-companies-dialog/search-companies-cell-renderer/search-companies-cell-renderer.component'
import { SearchCompaniesDialogComponent } from './components/search-companies-dialog/search-companies-dialog.component'
import { SearchComponent } from './components/search/search.component'
import { ActivityExportComponent } from './components/sidepull/activity-export/activity-export.component'
import { ActivityListComponent } from './components/sidepull/activity-list/activity-list.component'
import { SearchFilterPipe } from './components/sidepull/activity-list/search-filter.pipe'
import { AddCompanyItemsComponent } from './components/sidepull/add-company-items/add-company-items.component'
import { AssetWidgetComponent } from './components/sidepull/asset-widget/asset-widget.component'
import { CompaniesListComponent } from './components/sidepull/companies-list/companies-list.component'
import { CompanyViewComponent } from './components/sidepull/company-view/company-view.component'
import { FavoriteConfigurationComponent } from './components/sidepull/favorite-configuration/favorite-configuration.component'
import { SidepullComponent } from './components/sidepull/sidepull.component'
import { TermsServiceComponent } from './components/terms-service-dialog/terms-service.component'
import { WelcomeScreenComponent } from './components/welcome-screen/welcome-screen.component'
import { WrapperRightSidenavComponent } from './components/wrapper-right-sidenav/wrapper-right-sidenav.component'
import { CoreComponent } from './core.component'
import { KeyPipePipe } from './pipes/key-pipe.pipe'
import { SearchPipe } from './pipes/search.pipe'
import { GlobalService } from './services/global.service'
@NgModule({
  declarations: [
    RightSideBarComponent,
    LocalPasswordsComponent,
    LocalPasswordsListComponent,
    LocalPasswordsFormComponent,
    WrapperRightSidenavComponent,
    RevisionsComponent,
    RevisionTableBoxComponent,
    CoreComponent,
    HeaderComponent,
    SidepullComponent,
    ActivityListComponent,
    CompaniesListComponent,
    TicketsDialogComponent,
    SearchPipe,
    ProfileComponent,
    TermsServiceComponent,
    LogOutConfirmationComponent,
    SearchComponent,
    HighlightPipe,
    ChangePasswordComponent,
    GoogleAuthenticationPopupComponent,
    CompanyViewComponent,
    AddCompanyItemsComponent,
    FavoriteConfigurationComponent,
    AssetWidgetComponent,
    RestoreDeletedCompaniesComponent,
    SearchFilterPipe,
    ActivityExportComponent,
    NotificationComponent,
    LocalNotesComponent,
    LocalNotesListComponent,
    LocalNotesFormComponent,
    LinkedItemsComponent,
    LinkedItemsListComponent,
    LinkedItemsFormComponent,
    AttachmentsComponent,
    GranularPermissionComponent,
    CompanyCreationComponent,
    AddCompanyTypeModalComponent,
    CustomDropzonePreviewComponent,
    AccessErrorComponent,
    SearchCompaniesDialogComponent,
    RemoteLoginComponent,
    NotFoundComponent,
    SearchCompaniesCellRendererComponent,
    WelcomeScreenComponent,
    KeyPipePipe,
    AssetStatisticsDialogComponent,
  ],
  imports: [
    CommonModule,
    UiComponentsModule,
    DataGridModule,
    MatDialogModule,
    MatMenuModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    FooterModule,
    NgxDropzoneModule,
    CKEditorModule,
    StoreModule.forFeature('Adl Router', adlMaper),
    EffectsModule.forFeature([AdlEffect]),
  ],
  exports: [WrapperRightSidenavComponent, ReactiveFormsModule, CoreComponent, HighlightPipe, CustomDropzonePreviewComponent,
            HeaderComponent, FooterComponent, SidepullComponent],
  entryComponents: [
    TicketsDialogComponent,
    ProfileComponent,
    TermsServiceComponent,
    LogOutConfirmationComponent,
    GoogleAuthenticationPopupComponent,
    ChangePasswordComponent,
    RestoreDeletedCompaniesComponent,
    GranularPermissionComponent,
    AddCompanyTypeModalComponent,
    SearchCompaniesDialogComponent,
    SearchCompaniesCellRendererComponent,
    AssetStatisticsDialogComponent,
  ],
  providers: [GirdActionService, GlobalService, HeaderNotificationService, CompaniesService],
})
export class CoreModule { }
