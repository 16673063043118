<itb-widget
  [title]="'Favorite Configurations'"
  [hasFooter]="false"
  [roundButtons]=" "
  [hasBarLoader]='isLoading'
  [hasContentCenter]='true'>
  <itb-table *ngIf="favoriteList.length!==0" [dataSource]="favoriteList" [displayedColumns]="displayedColumns2"
             (onActionClickHandler)="clickEvent($event)" [hasInnerHTML]="true"
   ></itb-table>
  <div  fxLayoutAlign="center center">
    <itb-empty-state-image *ngIf="favoriteList.length==0 && !isLoading" [imageClass]="'es-image img-nodata'" [iconClassName]="'btn-innerico save'" [headingClass]="'heading'" [headingText]="'NO DATA FOUND'" ></itb-empty-state-image>
  </div>
</itb-widget>
