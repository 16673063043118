export const RIGHT_SIDE_BAR = {
  ADD: 'Add',
  EDIT: 'Edit',
  VIEW: 'View',
  DELETE: 'Delete',
  CLOSE: 'Close',
  SAVE: 'Save',
  SETTING: 'Setting',
  SETTING_CLASS: 'setting btn-loader btn',
  CROSS: 'Cross',
  PASSWORD: 'password',
  NOTE: 'note',
  LINKEDITEM: 'linked item',
  ATTACHMENT: 'attachment',
  TEXT: 'text',
  MODEL_STRING: {
    DELETE: {
      HEADING: 'DELETE PASSWORD',
      BODY: 'Do you want to delete password?',
    },
  },
  MODE: {
    HOVER: 'hover',
    SIDE: 'side',
  },
  EVENT_BUTTON: {
    ICON_CLOSE_MENU: 'cross',
    ICON_MENU: 'icon_menu',
    PIN: 'pin',
  },
  TOOL_TIP_TEXT: {
    ICON_MENU: 'Menu',
    PIN: 'Pin',
  },
  LINKED_ITEMS_OPTIONS: {
    CONFIGURATION: { id: 'Configuration', label: 'configurations', uuid: '1' },
    LOCATION: { id: 'Location', label: 'locations', uuid: '2' },
    CONTACT: { id: 'Contact', label: 'contacts', uuid: '3' },
    PASSWORD: { id: 'Password', label: 'passwords', uuid: '4' },
    KNOWLEDGEBASE: { id: 'Knowledge Base', label: 'articles', uuid: '5' },
    DOMAIN_TRACKER: { id: 'Domain Tracker', label: 'trackers/whois', uuid: '6' },
  },
}

export const STRING_CONSTANTS = {

  EYE_DA: 'eye-da',
  SHOW_PASSWORD: 'Show Password',
  HIDE_PASSWORD: 'Hide Password',
  EYE: 'eye',
  PASSWORD: 'password',
  TEXT: 'text',
  KNOWLEDGEBASE: 'Knowledgebase',
  RUNBOOKS: 'Runbooks',
  ACTIVE: 'active',
  TEMPLATES: 'Templates',
  QUESTIONS: 'Questions',
  OVERLAY_OPEN: 'overlay-open',
  SELECT_AVATAR: 'Select Avatar',
  BROWSE_AVATAR: 'Browse Avatar',
  USE_GRAVATAR: 'Use Gravatar',
  ALL: 'All',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
  INTERNAL: 'internal',
  LAST_WEEK_ACTIVITIES: 'Last week activities',
  TODAY_ACTIVITIES: 'Today activities',
  LAST_MONTH_ACTIVITIES: 'Last Month activities',
  ALL_ACTIVITIES: 'All activities',
  LAST_10_ACTIVITIES: 'Last 10 Activities',
  NEXT_RUN_IN: 'Next run: in',
}

export const TABICON_CONSTANTS = {
  SAVE: 'save',
  CLOSE: 'close',
}

export const NUMBER_CONSTANTS = {
  TIMEOUT_1000: 1000,
}

export const CLASSNAME_CONSTANTS = {
  ICON_GREEN_TICK: 'btn-icon green-tick',
  BTN_ICON: 'btn-icon',
}

export const NOTIFICATION_CONSTANTS = {

  FAILED: 'failed',
  USER: 'User',
  TWO_FA: 'Two Factor Authentication',
  ENABLE_SUCCSESS: 'enabled successfully',
  DISABLE_SUCCESS: 'disabled successfully',
  UPDATED: 'Updated!',
  SUCCESS: 'success',
  PASSWORD_CHANGED: 'Password Changed Successfully',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  UPDATED_USER: user => (`Successfully Updated User: ${user}`),
  DELETE: (msg: string) => `Do you want to delete ${msg}?`,
}

export const DATE_CONSTANTS = {

  FEW_SECONDS: ' a few seconds ago',
  ONE_MINUTE: ' a minute ago',
  MINUTES_A_GO: ' minutes ago',
  HOURS_A_GO: '  hour ago',
  ONE_DAY_A_GO: ' 1 day ago',
  DAYS_A_GO: ' days ago',
  ONE_MONTH: ' 1 month ago',
  MONTHS_A_GO: ' months ago',
  ONE_YEAR: ' 1 year ago',
  YEARS_A_GO: ' years ago',
  YEARS: 'years',
  YEAR: 'year',
  MONTHS: 'months',
  DAYS: 'days',
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
  MONTH: 'month',
  DAY: 'day',
  HOUR: 'hour',
  MINUTE: 'minute',
  SECOND: 'second',
  WEEK: 'week',
  WHILE: ' a while ago',
}

export const CHANGE_PASSWORD_CONSTANTS = {
  STRONG: 'Strong',
  NORMAL: 'Normal',
  WEEK: 'Too Weak',
}

export const MODAL_WIDTH_CONSTANTS = {
  LARGE: '99%',
  MEDIUM: '900px',
  SMALL: '600px',
  EXTRASMALL: '300px',
  CUSTOM: '1200px',
}
export const RESTRICTED_URLS = {
  RESTRICTED_REGEX_URLS: [
    new RegExp('/app/documentation/knowledgebase/edit/(.)*'),
    new RegExp('/app/documentation/runbook/add/(.)*'),
    new RegExp('/app/documentation/runbook/edit/(.)*'),
    new RegExp('/app/feedback/templates/edit/(.)*'),
  ],
  RESTRICTED_URLS: ['/app/documentation/knowledgebase/add', '/runbook/edit/', '/app/feedback/templates/add'],
  RESTRICTED_URLS_CORE: ['/app/companyAssetsPods', '/app/companyPods', '/app/welcome'],
}

export const PASSWORD_CONSTANTS = {
  KEYWORD_PASSWORDS: 'password',
  KEYWORD_TEXT: 'text',
}
export const URL = { GET_DASHBOARD_TABS: id => `dashboard/${id}/tabs/false` }

export enum PERMISSIONS {
  create = 'post',
  read = 'get',
  delete = 'delete',
  update = 'put',
}
