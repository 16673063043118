<itb-heading mat-dialog-title [className]="'modal-h'" [text]="'User Profile'" ></itb-heading>
<itb-round-button mat-dialog-close [className]="'light round-modal-cross caret-close-modal'" (onClickHandler)="closeModal()"
                  [iconClassName]="'cross'" [tooltipText]="'Close'" > </itb-round-button>
<mat-dialog-content class="mat-typography user-profile-sec">
  <itb-spinner [spinnerSize]="50" *ngIf="!isLoading" fxLayoutAlign="center center"></itb-spinner>
  <form [formGroup]="profileForm" *ngIf="isLoading">
    <div fxLayout='row wrap'>
      <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
        <itb-input [formFieldName]="'firstName'" [parentForm]="profileForm" [label]="'FIRST NAME'"  [isRequired]="true"
                 [maxlength]="30"  [placeholder]="'FIRST NAME'"[type]="'text'"></itb-input>
      <span *ngIf="firstNameRegex">
        <itb-info-message
        *ngIf="isSubmitFlag && !profileForm.valid && profileForm.get('firstName').errors"
        [text]="'Required'"
        [className]="'info-danger for-input'"
      ></itb-info-message>
      </span>
        
      </div>
      <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
        <itb-input  [formFieldName]="'lastName'" [parentForm]="profileForm" [label]="'LAST NAME'"  [isRequired]="true"  [maxlength]="30"
                    [placeholder]="'LAST NAME'"[type]="'text'"></itb-input>
        <span *ngIf="lastNameRegex">
          <itb-info-message
          *ngIf="isSubmitFlag && !profileForm.valid && profileForm.get('lastName').errors"
          [text]="'Required'"
          [className]="'info-danger for-input'"
        ></itb-info-message>
          </span>
        
      </div>
        <itb-input-with-flag fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" [className]="'flag'"
                             [formFieldName]="'phoneMobile'" [value]="phoneMobileValue"
                             [parentForm]="profileForm" [label]="'Mobile'"></itb-input-with-flag>

        <itb-input-with-flag fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" [className]="'flag1'"
                             [formFieldName]="'phoneOffice'" [value]="phoneOfficeValue"
                             [parentForm]="profileForm" [label]="'Office Phone'"></itb-input-with-flag>


        <itb-input fxFlex="100" [formFieldName]="'address'" [parentForm]="profileForm" [label]="'ADDRESS'" [maxlength]="100"
                   [placeholder]="'ADDRESS'"[type]="'text'"></itb-input>


        <itb-input fxFlex="40" fxFlex.sm="100" fxFlex.xs="100"  [formFieldName]="'userName'"
                   [parentForm]="profileForm" [label]="'USERNAME'" [placeholder]="'USERNAME'"[type]="'text'" ></itb-input>


      <itb-date-time-picker [min]="min" [max]="max" [hourFormate]="'false'"
                            fxFlex="25" fxFlex.sm="100" fxFlex.xs="100"  [placeholder]="'DATE OF BIRTH'"
                            (onChangeHandler)="dateChanged($event)" [pickerType]="'calendar'"
                            [parentForm]="profileForm" [formFieldName]="'dob'"></itb-date-time-picker>


        <itb-radio  fxFlex="35" fxFlex.sm="100" fxFlex.xs="100"  [label]="'GENDER'" [labelPosition]="'after'"
                    [parentForm]="profileForm" [formFieldName]="'gender'" [options]=" [{value: 'male',label: 'MALE'},  {value: 'female',label: 'FEMALE'}  ]" ></itb-radio>
      <div fxFlex="100" fxFlexLayout="row" class="">
          <!-- <itb-inpage-heading [text]="profileForm.value.picMode" [hasButton]="false" [hasDivider]="true"  ></itb-inpage-heading>   -->
        <!-- <h5 class="header-color">{{profileForm.value.picMode}}</h5> -->
      </div>

        <itb-select fxFlex="100"
          [isDisabled]="'false'"
          [label]="'Select Picture Mode'"
          [isMultiple]="false"
          [itemsStringArray]="picModes"
          [isDataInStrings]="true"
          [selected]="profileForm.get('picMode').value"
          [isReactive]="false"
          (onSimpleChangeHandler)="getSelectedPicMode($event)"
        ></itb-select>

     <div class=" avatar-git mb-2" [hidden] = "profileForm.value.picMode !== 'Select Avatar'" fxFlex="100" class="pl-2 pr-2">
       <itb-image id="{{item.id}}" *ngFor="let item of avatarArray" [src]="item.src"
         [className]=" item.isSelected ?'selected avatar-item':'avatar-item'" (onClickHandler)="changeAvatar(item)"></itb-image>
     </div>
      <div *ngFor="let item of profileArray"><pre>{{item.selected}}</pre></div>
      <div [hidden] = "profileForm.value.picMode !== 'Browse Avatar'" fxFlex="100" class="pl-2 pr-2">
        <div fxLayout="row" fxLayoutAlign="start center">
          <itb-image [imageWidth]=40 [hidden] = "browseAvatarPhoto !== ''" [src]="profileForm.get('userPhoto').value"></itb-image>
          <itb-image [imageWidth]=40 [hidden] = "browseAvatarPhoto === ''"  [src]="browseAvatarPhoto"></itb-image>
          <div data-toggle="tooltip" data-original-title="Upload Image" ui-jq="tooltip" data-placement="bottom" class="fileUpload btn-transparent ml-1 mr-1">
            <!-- <itb-image  [src]="'images-library/icon-library/icon_browse.svg'"></itb-image> -->
            <!-- <input type="file" class="upload" (change)="selectImage($event)" accept="image/png, image/jpeg, image/jpg"> -->
            <label for="file-input">
              <itb-image [imageWidth]=40  [src]="'images-library/icon-library/icon_browse.svg'" [tooltipText]="'Upload Image'"></itb-image>
            </label>

            <input id="file-input" type="file" class="upload" (change)="selectImage($event)" accept="image/png, image/jpeg, image/jpg">

          </div>
          <itb-text [className]="'text-m-small'" [text]="'Optimal size for image uploading is 40x40 pixels.'"></itb-text>
        </div>
        <itb-info-message [hidden] = "profileForm.value.picMode !== 'Browse Avatar'"
                          [text]="'File exceeding the maximum upload size of 200KB'" [className]="'info-danger m-t-5'"
                          *ngIf="isFileSizeExceed"></itb-info-message>
      </div>
      <div [hidden] = "profileForm.value.picMode !== 'Use Gravatar'" fxFlex="100" class="pl-2 pr-2">
        <itb-image [imageWidth]=40  [src]="gravatarPhoto"></itb-image>
      </div>
    </div>
    <div fxFlexLayout="row" class="mt-3" fxLayoutAlign="center center" fxLayoutGap="20px">
        <div>
          <div fxLayoutAlign="flex-end flex-end" class="uploadImageContainer lightbg_fix img-app-light" id="light" (click)="toggleTheme('light')">
            <div class="logo_overlay"></div>
            <span class="expandIcon selected"  *ngIf="theme === 'light'">
              <itb-icon  [className]="'tick'" ></itb-icon>
            </span>
          </div>
        </div>
        <div >
          <div fxLayoutAlign="flex-end flex-end" class="uploadImageContainer darkbg_fix img-app-dark" id="dark" (click)="toggleTheme('dark')">
            <div class="logo_overlay" ></div>
            <span class="expandIcon selected" *ngIf="theme === 'dark'">
               <itb-icon  [className]="'tick'" ></itb-icon>
            </span>
          </div>
        </div>
      </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <itb-default-button [text]="'Save'" [className]="'primary'" [iconClassName]="'save'" [isLoading]="isSubmitLoader"
                      (onClickHandler)="submit()"></itb-default-button>
  <itb-default-button [text]="'Cancel'" [className]="'default'" [iconClassName]="'cross'"  (onClickHandler)="closeModal()"
                      mat-dialog-close></itb-default-button>
</mat-dialog-actions>
