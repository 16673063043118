import { AgRendererComponent } from '@ag-grid-community/angular'
import { Component } from '@angular/core'
import { MatDialog } from '@angular/material'
import { ModalService } from '@app/services/modal.service'

@Component({
  selector: 'app-table-tags-cell-renderer',
  templateUrl: './table-tags-cell-renderer.component.html',
  styleUrls: ['./table-tags-cell-renderer.component.scss'],
})
export class TableTagsCellRendererComponent implements AgRendererComponent {
  params // used for optional params that are added dynamically for different types
  customTags = []
  numberOfTags: number
  constructor(public dialog: MatDialog, readonly modalService: ModalService) {
    this.numberOfTags = 2
  }
  agInit(params): void {
    // used for optional params that are added dynamically for different types
    if (params.numberOfTags) {
        this.numberOfTags = params.numberOfTags
    }
    Array.isArray(params.value) ? (this.params = params.value) : (this.params = [])
    this.params.length > this.numberOfTags ?  this.customTags = this.params.slice(0, this.numberOfTags) : this.customTags = this.params

  }
  showTags() {
    this.modalService.showSearchTagsModal(this.params, true)
  }
  refresh(params): boolean {
    // used for optional params that are added dynamically for different types
    return false
  }
}
