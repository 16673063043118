import { AgRendererComponent } from '@ag-grid-community/angular'
import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-cell-renderer',
  templateUrl: './icon-cell-renderer.component.html',
  styleUrls: ['./icon-cell-renderer.component.scss'],
})
export class IconCellRendererComponent implements AgRendererComponent {

  params // used for optional params that are added dynamically for different types

  agInit(params): void {
    this.params = params
  }

  refresh(params): boolean { // used for optional params that are added dynamically for different types
    return false
  }
}
