import { Component, OnInit } from '@angular/core'

import { GirdActionService } from '../../../../../../../projects/ag-grid-customized/src/app/data-grid'

@Component({
  selector: 'app-search-companies-cell-rendrer',
  templateUrl: './search-companies-cell-renderer.component.html',
  styleUrls: ['./search-companies-cell-renderer.component.css'],
})
export class SearchCompaniesCellRendererComponent {
  params
  isSelected: boolean
  constructor(public gridActionsService: GirdActionService) {}
  agInit(params): void {
    this.params = params.data
    if (params.data && params.data.isSelected) {
      this.isSelected = true
    }
  }
  nameHandler(event) {
    this.gridActionsService.setSelectedAction(event, this.params, 3)
  }
}
