import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { UiComponentsModule } from '@app/ui-components.module'

import { FooterComponent } from './footer.component'
import { OrganizationExpirayModalComponent } from './organization-expiray-modal/organization-expiray-modal.component'

@NgModule({
  declarations: [FooterComponent, OrganizationExpirayModalComponent],
  imports: [
    CommonModule, UiComponentsModule,
  ],
  entryComponents: [OrganizationExpirayModalComponent],
  exports: [FooterComponent],
})
export class FooterModule { }
