import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { GlobalService } from '@core/services/global.service'
import { Subscription } from 'rxjs/index'

import { STRING_CONSTANTS } from '../../../constants/constants'
import { IFilter } from '../../../interfaces/activities'
import { ICompaniesList } from '../../../interfaces/companies'
import { IActivity, IFilterOptions } from '../../../interfaces/header-items.interface'
import { IUsers } from '../../../interfaces/users'
import { IUsersList } from '../../../interfaces/users-list'
import { CoreService } from '../../../services/core.service'
import { DateService } from '../../../services/date.service'
import { SharedDataService } from '../../../services/shared-data.service'
import { UtilService } from '../../../services/utils.service'
@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
})
export class ActivityListComponent implements OnInit, AfterViewInit, OnDestroy {
  subscriptions: Subscription
  heading: string
  curdHeading: string
  filteredActivitiesList: IActivity[]
  activitiesList: IActivity[]
  companiesList: ICompaniesList[]
  usersList: IUsersList[]
  searchActivity: string
  inputForm: FormGroup
  activityOptions: IFilterOptions[]
  filterOptions: IFilterOptions[]
  isLoading: boolean
  pageNumber: number
  url: string
  isScrollLoader: boolean
  isScrollDataComplete: boolean
  filterLabel: string
  constructor(
    private readonly coreService: CoreService,
    private readonly sharedService: SharedDataService,
    private readonly utilService: UtilService,
    private readonly dateService: DateService,
    private readonly globalService: GlobalService,
    private readonly renderer: Renderer2
  ) {
    this.subscriptions = new Subscription()
    this.searchActivity = ''
    this.filterOptions = globalService.filterOptions()
    this.activityOptions = globalService.activityOptions()
    this.heading = this.activityOptions[0].label
    this.pageNumber = 0
    this.isScrollLoader = false
    this.isScrollDataComplete = false
  }
  /*** On load - gets user list and calls getActivities() function */
  ngOnInit() {
    this.isLoading = true
    this.inputForm = new FormGroup({
      activities: new FormControl(this.activityOptions[0]),
      filters: new FormControl(),
      searchName: new FormControl(''),
    })
    this.companiesList = this.sharedService.getCompaniesList()
  }
  ngAfterViewInit() {
    this.renderer.listen(document.getElementById('sidePullActivityScroll'), 'scroll', event => {
      if (this.heading !== 'Last 10 Activities') {
        const element = document.getElementById('sidePullActivityScroll')
        if (element.scrollTop !==  element.scrollHeight) {
          if (Math.ceil(element.scrollTop) === (element.scrollHeight - element.offsetHeight)) {
            if (this.isScrollLoader || this.isScrollDataComplete) {
              return
            }
            this.pageNumber++
            this.fetchActivitiesOnScroll(this.pageNumber)
          }
        }
      }
    })
  }

    /*** Handles scroll to end - and loads more companies */
  fetchActivitiesOnScroll(pageNumber: number) {
    this.isScrollLoader = true
    const urlScroll = `${this.url}&pageNo=${pageNumber}&&pageSize=20`
    this.subscriptions.add(this.coreService.activitiesFilter(urlScroll).subscribe((dataActivity: IActivity[]) => {
      if (Array.isArray(dataActivity) && dataActivity.length !== 0) {
        dataActivity =  this.activitiesList.concat(dataActivity)
        this.topTenCallResponse(dataActivity)
      } else {
        this.pageNumber = 0
        this.isScrollDataComplete = true
      }
      this.isScrollLoader = false
    }, error => {
    }))
  }
  /*** Top ten activities call response */
  topTenCallResponse(data: IActivity[]) {
    const user: IUsers = this.sharedService.getUser()
    this.isLoading = false
    let activitiesList
    if (user.userType === STRING_CONSTANTS.INTERNAL) {
      activitiesList = data
      this.manipulateActivities(data)
      if (this.curdHeading && this.curdHeading !== STRING_CONSTANTS.ALL) {
        this.filteredActivitiesList = this.curdActivities(this.filteredActivitiesList, this.curdHeading)
      }
    } else {
      activitiesList = []
      for (const obj of Object.keys(data)) {
        if (
          this.utilService.isUndefined(data[obj].companyId) ||
          data[obj].action === STRING_CONSTANTS.DELETE
        ) {
          activitiesList.push(data[obj])
        } else {
          for (const item of Object.keys(this.companiesList)) {
            if (this.companiesList[item].ITBCompanyId === data[obj].companyId) {
              activitiesList.push(data[obj])
            }
          }
        }
        this.manipulateActivities(activitiesList)
        if (this.curdHeading && this.curdHeading !== STRING_CONSTANTS.ALL) {
          this.filteredActivitiesList = this.curdActivities(this.filteredActivitiesList, this.curdHeading)
        }
      }
      this.manipulateActivities(activitiesList)
      if (this.curdHeading && this.curdHeading !== STRING_CONSTANTS.ALL) {
        this.filteredActivitiesList = this.curdActivities(this.filteredActivitiesList, this.curdHeading)
      }
    }
  }
  /*** Filter activity based on method selected */
  curdActivities(activitiesList: IActivity[], curd: string): IActivity[] {
    const filteredList = []
    switch (curd) {
      case 'Add':
        curd = STRING_CONSTANTS.POST
        break
      case 'Edit':
        curd = STRING_CONSTANTS.PUT
        break
      case 'Delete':
        curd = STRING_CONSTANTS.DELETE
        break
      default:
    }
    for (const item of Object.keys(activitiesList)) {
      if (activitiesList[item].action === curd) {
        filteredList.push(activitiesList[item])
      }
    }
    return filteredList
  }
  /*** Filter activity based on method selected */
  manipulateActivities(activityList: IActivity[]) {
    this.filteredActivitiesList = []
    if (!this.utilService.isUndefined(activityList)) {
      this.activitiesList = activityList
      let tempDate
      let convertedDate
      const searchList = []
      let printFlag
      tempDate = this.activitiesList[0].createdAt
      this.activitiesList[0].letter = tempDate
      for (const obj of Object.keys(this.activitiesList)) {
        for (const user of Object.keys(this.usersList)) {
          if (this.activitiesList[obj].user && this.activitiesList[obj].user.userId) {
            if (this.activitiesList[obj].user.userId === this.usersList[user].userId) {
              this.activitiesList[obj].user.userPhoto = this.usersList[user].userPhoto
              this.activitiesList[obj].user.userName = `${this.usersList[user].firstName}  ${this.usersList[user].lastName}`
              break
            }
          }
        }
        if (
          new Date(this.activitiesList[obj].createdAt).getFullYear() === new Date(tempDate).getFullYear() &&
          new Date(this.activitiesList[obj].createdAt).getMonth() === new Date(tempDate).getMonth() &&
          new Date(this.activitiesList[obj].createdAt).getDate() === new Date(tempDate).getDate()
        ) {
          this.activitiesList[obj].moment = this.activitiesList[obj].createdAt
          if (!this.utilService.isUndefined(this.activitiesList[obj].recordName)) {
            if (this.activitiesList[obj].recordName.length > 13) {
              this.activitiesList[obj].recordName = `${this.activitiesList[obj].recordName.slice(0, 12)} ...`
            }
          }
          if (!this.utilService.isUndefined(this.activitiesList[obj].companyName)) {
            if (this.activitiesList[obj].companyName.length > 20) {
              this.activitiesList[obj].companyName = `${this.activitiesList[obj].companyName.slice(0, 18)} ...`
            }
          }
          const dateFilter = new Date(tempDate)
          tempDate = `${this.utilService.convertMonthToMonthName(dateFilter.getMonth())}   ${dateFilter.getDate()} ,
           ${dateFilter.getFullYear()}`
          if (searchList.indexOf(tempDate) > -1) {
            printFlag = 0
          } else {
            searchList.push(tempDate)
            printFlag = 0
          }

          if (this.activitiesList[obj] === this.activitiesList[0]) {
            this.activitiesList[obj].letter = tempDate
            printFlag = 1
          }
          this.activitiesList[obj].printFlag = printFlag
          this.filteredActivitiesList.push(this.activitiesList[obj])
        } else {
          this.activitiesList[obj].moment = this.activitiesList[obj].createdAt
          tempDate = this.activitiesList[obj].createdAt
          const dateFilter = new Date(tempDate)
          convertedDate = `${this.utilService.convertMonthToMonthName(dateFilter.getMonth())}    ${dateFilter.getDate()} ,
            ${dateFilter.getFullYear()}`
          if (searchList.indexOf(tempDate) > -1) {
            printFlag = 0
          } else {
            this.activitiesList[obj].letter = convertedDate
            searchList.push(tempDate)
            printFlag = 1
          }
          if (this.activitiesList[obj] === this.activitiesList[0]) {
            this.activitiesList[obj].letter = tempDate
            printFlag = 1
          }
          this.activitiesList[obj].printFlag = printFlag
          this.filteredActivitiesList.push(this.activitiesList[obj])
        }
      }
    }
  }
  /*** Change event of filter select dropdown */
  filterChange(filter: IFilter) {
    switch (filter.source.value.label) {
      case 'Filter by: Add':
        this.filterLabel = 'POST'
        break
      case 'Filter by: Edit':
        this.filterLabel = 'PUT'
        break
      case 'Filter by: Delete':
        this.filterLabel = 'DELETE'
        break
      case 'Filter by: All':
        this.filterLabel = STRING_CONSTANTS.ALL
        break
      default:
        this.filterLabel = STRING_CONSTANTS.ALL
    }
    this.activitiesCall(this.createFilterQuery(this.heading))
  }
  /*** Change event of activity select dropdown */
  activityChange(activity: IFilter) {
    this.heading = activity.source.value.label
    let url
    switch (this.heading) {
      case 'Last 10 Activities':
        url = this.createFilterQuery('Last 10 Activities')
        break
      case 'Today':
        url = this.createFilterQuery('Today activities')
        break
      case 'Last Week':
        url = this.createFilterQuery('Last week activities')
        break
      case 'Last Month':
        url = this.createFilterQuery('Last Month activities')
        break
      case 'All':
        url = this.createFilterQuery('All')
        break
      default:
        return
    }
    this.activitiesCall(url)
  }

  /*** change activities function **/
  activitiesCall(url) {
    this.url = `${url}`
    this.isScrollDataComplete = false
    this.isLoading = true
    this.pageNumber = 0
    if (! this.usersList) {
      this.subscriptions.add(this.coreService.getUsersList().subscribe(data => {
          this.usersList = data
          this.subscriptions.add(this.coreService.activitiesFilter(this.url).subscribe((dataActivity: IActivity[]) => {
            this.isLoading = false
            if (Array.isArray(dataActivity) && dataActivity.length !== 0) {
              this.topTenCallResponse(dataActivity)
            } else {
              this.filteredActivitiesList = []
            }
          }, error => {
          }))
        },
        error => {}
      ))
    } else {
      this.subscriptions.add(this.coreService.activitiesFilter(this.url).subscribe((dataActivity: IActivity[]) => {
        this.isLoading = false
        if (Array.isArray(dataActivity) && dataActivity.length !== 0) {
          this.topTenCallResponse(dataActivity)
        } else {
          this.filteredActivitiesList = []
        }
      }, error => {
      }))
    }
  }

    /*** Creates and sanitizes query for the backend */
  createFilterQuery(heading?: string) {
    let url = ''
    let start
    const end = new Date(new Date().setUTCHours(23, 59, 59, 999)).toISOString()
    let query
    this.heading = heading ? heading : this.heading
    switch (this.heading) {
      case 'Last 10 Activities':
        url = this.getTopTenFilters(heading, url)
        break
      case 'Today activities':
        start = new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString()
        query = this.filterLabel === 'All' || this.filterLabel === undefined ?
          `{"createdAt":{"$lt":"${end}","$gt":"${start}"}` :
          `{"createdAt":{"$lt":"${end}","$gt":"${start}"},"action":"${this.filterLabel}"`
        url = heading ? `organizations/activities?sortBy=createdAt&order=desc&conditions=${query}}` :
          `organizations/activities?sortBy=createdAt&order=desc&conditions=${query},`
        break
      case 'Last week activities':
        start = new Date(this.dateService.getXDaysAgoDate(7).setUTCHours(0, 0, 0, 0)).toISOString()
        query = this.filterLabel === 'All' || this.filterLabel === undefined ?
          `{"createdAt":{"$lt":"${end}","$gt":"${start}"}` :
          `{"createdAt":{"$lt":"${end}","$gt":"${start}"},"action":"${this.filterLabel}"`
        url = heading ? `organizations/activities?sortBy=createdAt&order=desc&conditions=${query}}` :
          `organizations/activities?sortBy=createdAt&order=desc&conditions=${query},`
        break
      case 'Last Month activities':
        start = new Date(this.dateService.getXDaysAgoDate(30).setUTCHours(0, 0, 0, 0)).toISOString()
        query = this.filterLabel === 'All' || this.filterLabel === undefined ?
          `{"createdAt":{"$lt":"${end}","$gt":"${start}"}` :
          `{"createdAt":{"$lt":"${end}","$gt":"${start}"},"action":"${this.filterLabel}"`
        url = heading ? `organizations/activities?sortBy=createdAt&order=desc&conditions=${query}}` :
          `organizations/activities?sortBy=createdAt&order=desc&conditions=${query},`
        break
      case 'All':
        url = this.getAllFilters(heading, url)
        break
      default:
        url = this.getAllFilters(heading, url)
    }
    return url
  }
  getAllFilters(heading, url) {
    let query
    if (heading) {
      query =  this.filterLabel === 'All' || this.filterLabel === undefined ?
        '' : `&conditions={"action":"${this.filterLabel}"}`
    } else {
      query =  this.filterLabel === 'All' || this.filterLabel === undefined ?
        '' : `"action":"${this.filterLabel}"},`
    }
    url = heading ? `organizations/activities?sortBy=createdAt&order=desc${query}` :
      `organizations/activities?sortBy=createdAt&order=desc&conditions={${query}`
    return url
  }
  getTopTenFilters(heading, url) {
    let query
    if (heading) {
      query =  this.filterLabel === 'All' || this.filterLabel === undefined ?
        '' : `&conditions={"action":"${this.filterLabel}"}`
    } else {
      query =  this.filterLabel === 'All' || this.filterLabel === undefined ?
        '' : `"action":"${this.filterLabel}"},`
    }
    url = heading ? `organizations/activities?pageNo=0&pageSize=10&sortBy=createdAt&order=desc${query}` :
      `organizations/activities?pageNo=0&pageSize=10&sortBy=createdAt&order=desc&conditions={${query}`
    return url
  }
  /*** On activities changed event  **/
  changeActivities() {
    this.isScrollDataComplete = false
    this.pageNumber = 0
    const conditions = `"$or":[{"assetName":{"$regex":"${this.inputForm.value.searchName}","$options":"i"}},
    {"user.userName":{"$regex":"${this.inputForm.value.searchName}","$options":"i"}},
    {"recordName":{"$regex":"${this.inputForm.value.searchName}","$options":"i"}}]}`
    this.url = `${this.createFilterQuery()}${conditions}`
    this.subscriptions.add(this.coreService.activitiesFilter(this.url).subscribe((dataActivity: IActivity[])  => {
      if (Array.isArray(dataActivity) && dataActivity.length !== 0) {
        this.topTenCallResponse(dataActivity)
      } else {
        this.filteredActivitiesList = []
      }
    }, error => {}))
  }
  /*** Unsubscribe all subscription when component destroy*/
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
