<div *ngFor="let note of notes" class="pt-2 pb-2 border-bottom" fxLayoutAlign="space-between center">
  <div class="pl-2 pointer">
    <itb-color-bar *ngIf="note.priority === 'High'" [className]="'color-bar color-bar-1'"></itb-color-bar>
    <itb-color-bar *ngIf="note.priority === 'Medium'" [className]="'color-bar color-bar-4'"></itb-color-bar>
    <itb-color-bar *ngIf="note.priority === 'Low'" [className]="'color-bar color-bar-2'"></itb-color-bar>
    <itb-text [className]="'text-default'" [text]="note.description" (click)="onItemView(note)"></itb-text>
  </div>
  <itb-dropdown *ngIf="hasEditPermissions" [buttonType]="'iconBtn'" [className]="'edit-dots icon-black'" [progressButton]="'progressButton'">
    <div class="rsb-list-dropdown">
      <button class="mat-menu-item" (click)="onItemEdit(note)"> <span>Edit</span></button>
      <button  class="mat-menu-item" (click)="onItemDelete(note)"> <span>Delete</span></button>
    </div>
  </itb-dropdown>
</div>

<div fxLayout="column" fxLayoutAlign="center center" class="no-data" *ngIf="notes && notes.length === 0">
  <span class="img-nodatafound"></span>
  <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
</div>
