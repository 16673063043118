import { AgRendererComponent } from '@ag-grid-community/angular'
import { Component } from '@angular/core'
import { stringConstants } from '@projects/ag-grid-customized/src/app/constants/constants'

import { GirdActionService } from '../../services/grid-actions.service'
@Component({
  selector: 'app-smiley',
  templateUrl: './smiley.component.html',
})
export class SmileyComponent implements AgRendererComponent {

  params // used for optional params that are added dynamically for different types
  src: string
  constructor(public gridActionsService: GirdActionService) {}
  agInit(params): void {
    this.params = params
    this.src = stringConstants.SMILEY_IMAGES[this.params.value - 1]
  }

  refresh(params): boolean { // used for optional params that are added dynamically for different types
    return false
  }
  clickHandler() {
    this.gridActionsService.setSelectedAction(stringConstants.DEFAULT_DATAGRID_NAVIGATE_TO_VIEW, this.params.data, this.params.rowIndex)
  }

}
