<itb-heading mat-dialog-title [className]="'modal-h'" [text]="'Create New Ticket'"></itb-heading>
<itb-round-button
  [tooltipText]="'Close'"
  mat-dialog-close
  [className]="'light round-modal-cross caret-close-modal'"
  [iconClassName]="'cross'"
></itb-round-button>
<div mat-dialog-content>
  <itb-spinner *ngIf="!isDataLoaded" [spinnerSize]="75" class="h-40vh" fxLayoutAlign="center center"></itb-spinner>
  <div [hidden]="!isDataLoaded">
    <itb-textarea
      [isRequired]="true"
      [formFieldName]="'summary'"
      [rows]="'4'"
      [placeholder]="'Summary'"
      [parentForm]="ticketForm"
      [maxlength]="''"
    ></itb-textarea>
    <div fxLayout="row wrap">
      <itb-select
        [isRequired]="true"
        [isDisabled]="isDisabled"
        fxFlex="50"
        fxFlex.sm="100"
        fxFlex.xs="100"
        [parentForm]="ticketForm"
        [formFieldName]="'company'"
        [items]="companies"
        [label]="'Company Name'"
        (onChangeHandler)="setCompany($event)"
      ></itb-select>
      <itb-select
        fxFlex="50"
        fxFlex.sm="100"
        fxFlex.xs="100"
        [parentForm]="ticketForm"
        [formFieldName]="'contact'"
        [items]="contacts"
        [label]="'Contact Name'"
        (onChangeHandler)="setContact($event)"
      ></itb-select>
      <itb-select
        [isRequired]="true"
        fxFlex="50"
        fxFlex.sm="100"
        fxFlex.xs="100"
        [parentForm]="ticketForm"
        [formFieldName]="'board'"
        [items]="boards"
        [label]="'Board'"
        (onChangeHandler)="setBoard($event)"
      ></itb-select>
      <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
        <itb-select
          [isRequired]="true"
          [parentForm]="ticketForm"
          [formFieldName]="'status'"
          [items]="statuses"
          [label]="'Status'"
        ></itb-select>
        <itb-info-message
          *ngIf="isBoardSelected && hasNoStatuses"
          [text]="'No statuses against this board.'"
          [className]="'info-danger'"
        ></itb-info-message>
      </div>
      <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
        <itb-select [parentForm]="ticketForm" [formFieldName]="'type'" [items]="types" [label]="'Type'"> </itb-select>
        <itb-info-message
          *ngIf="isBoardSelected && hasNoTypes"
          [text]="'No types against this board.'"
          [className]="'info-danger'"
        ></itb-info-message>
      </div>
      <itb-select
        fxFlex="50"
        fxFlex.sm="100"
        fxFlex.xs="100"
        [parentForm]="ticketForm"
        [formFieldName]="'owner'"
        [items]="owners"
        [label]="'Ticket Owner'"
      ></itb-select>
    </div>
    <itb-textarea
      [formFieldName]="'initialDescription'"
      [rows]="'4'"
      [placeholder]="'Description'"
      [parentForm]="ticketForm"
      [maxlength]="''"
    ></itb-textarea>
  </div>
</div>
<mat-dialog-actions align="end">
  <itb-default-button
    [isLoading]="isSaving"
    [text]="'save'"
    [isDisabled]="!ticketForm.valid"
    [className]="'primary'"
    [iconClassName]="'save'"
    (onClickHandler)="onSave()"
  ></itb-default-button>
  <itb-default-button [text]="'cancel'" [className]="'default'" [iconClassName]="'cross'" (onClickHandler)="onClose()">
  </itb-default-button>
</mat-dialog-actions>
