<ng-container>
    <img *ngIf="params.value === true || params.value === 'private'" class="table-icons "
        src="images-library/icon-library/{{params.imageTrue}}.svg" />
    <span *ngIf="params.value === 'private' "> Private </span>
</ng-container>
<ng-container>
    <img *ngIf="params.value === false || params.value === 'public'" class="table-icons"
        src="images-library/icon-library/{{params.imageFalse}}.svg" />
    <span *ngIf="params.value === 'public' "> Public </span>
</ng-container>
<ng-container>
    <img *ngIf="params.value === 'male'" class="table-icons"
        src="images-library/icon-library/{{params.male}}.svg" />
    <span *ngIf="params.value === 'male'"> Public </span>
</ng-container>
<ng-container>
    <img *ngIf="params.value === 'female'" class="table-icons"
        src="images-library/icon-library/{{params.female}}.svg" />
    <span *ngIf="params.value === 'female' "> Public </span>
</ng-container>
