import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material'
import { IColumnState } from '@app/interfaces/ui-components.interface'
import { Subscription } from 'rxjs'

import { MODAL_CONSTANTS } from '../../../../constants/ui-components.constants'
import { ApiService } from '../../../../services/api.service'

@Component({
  selector: 'app-grid-setting-confirmation-dialog',
  templateUrl: './grid-setting-confirmation-dialog.component.html',
})
export class GridSettingConfirmationDialogComponent implements OnInit {
  saveGrid: string
  saveGridMsg: string
  serviceSubscription: Subscription
  constructor(public dialogRef: MatDialogRef<GridSettingConfirmationDialogComponent>,
              private readonly apiService: ApiService,
              readonly dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: { type: string, message: string, columnState: IColumnState }) {
    this.saveGrid = MODAL_CONSTANTS.SAVE_GRID_SETTINGS
    this.saveGridMsg = this.data.message
    this.serviceSubscription = new Subscription()
  }

  ngOnInit() {
  }
  onClick() {
    const columnDefs = this.data.columnState
    const columnState = {
      columnDefs,
    }
    this.serviceSubscription.add(this.apiService.add('dashboard/user/headers/' + this.data.type.toString(), columnState)
    .subscribe(response => {
      this.dialogRef.close(true)
    }))
  }
}
