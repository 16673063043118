<div class="action-cell-btns">
  <img
    matTooltip="Edit"
    class="table-action-icons"
    src="images-library/icon-library/edit-s.svg"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_NAVIGATE_TO_EDIT)"
    *ngIf="params.edit"
  />
  <img
    matTooltip="Delete"
    class="table-action-icons"
    src="images-library/icon-library/delete-r.svg"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_DELETE)"
    *ngIf="params.delete"
  />
  <img
    [matTooltip]="params.copyToolTip ? params.copyToolTip : 'Copy to Company'"
    class="table-action-icons"
    src="images-library/icon-library/duplicate.svg"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_COPY_COMPANIES)"
    *ngIf="params.copyCompanies"
  />
  <ng-container *ngIf="params.doc">
    <itb-icon *ngIf="loaderDocCheck()" [className]="'table-action-icons icon-btn-loader setting'"></itb-icon>
    <img
      *ngIf="imageDocCheck()"
      matTooltip="Export to DOCX"
      class="table-action-icons"
      [ngClass]="docLoader || pdfLoader ? 'filterGrayscale' : ''"
      src="images-library/icon-library/export-doc.svg"
      (click)="actionClicked(constants.DEFAULT_DATAGRID_EXPORT_DOC)"
    />
  </ng-container>
  <ng-container *ngIf="params.pdf">
    <itb-icon *ngIf="loaderPdfCheck()" [className]="'table-action-icons icon-btn-loader setting'"></itb-icon>
    <img
      *ngIf="imagePdfCheck()"
      matTooltip="Export to PDF"
      class="table-action-icons"
      [ngClass]="docLoader || pdfLoader ? 'filterGrayscale' : ''"
      src="images-library/icon-library/export-pdf.svg"
      (click)="actionClicked(constants.DEFAULT_DATAGRID_EXPORT_PDF)"
    />
  </ng-container>
  <img
    matTooltip="Reset Password"
    class="table-action-icons"
    src="images-library/icon-library/pass-refresh.svg"
    *ngIf="params.resetPassword"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_RESET_PASSWORD)"
  />
  <img
    matTooltip="Send Invite"
    class="table-action-icons"
    src="images-library/icon-library/send3.svg"
    *ngIf="params.sendInvite"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_SEND_INVITE)"
  />
  <img
    matTooltip="Unrestricted" class="table-action-icons" src="images-library/icon-library/unlock-s.svg" *ngIf="params.unlock" />
  <img  class="table-action-icons"  [mdePopoverTriggerFor]="popover"
        [mdePopoverEnterDelay]="300"
        src="images-library/icon-library/lock-s.svg" *ngIf="params.lock" />
  <img
    matTooltip="Contact Dashboard"
    class="table-action-icons"
    src="images-library/icon-library/dashboard-contact.svg"
    *ngIf="params.contactDashBoard"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_CONTACT_DASHBOARD)"
  />
  <img
    matTooltip="Create New Ticket"
    class="table-action-icons"
    src="images-library/icon-library/icon_ticket_new.svg"
    *ngIf="params.newTicket && newTicket"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_NEW_TICKET)"
  />
  <img
    matTooltip="Tick"
    class="table-action-icons"
    src="tick.svg"
    *ngIf="params.tick"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_TICK)"
  />
  <img *ngIf="params.data && params.data.swNCSync" matTooltip="SolarWinds N-Central" class="table-action-icons"
  src="images-library/adl/sync_solarwindsncentral.png" />
    <img *ngIf="params.data && params.data.aemSync" matTooltip="Autotask AEM" class="table-action-icons"
    src="images-library/adl/sync_aem.png" />
  <img
    matTooltip="Play"
    class="table-action-icons"
    src="play1.svg"
    *ngIf="params.play"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_PLAY)"
  />
  <img
    matTooltip="Cancel"
    class="table-action-icons"
    src="images-library/icon-library/cross-r.svg"
    *ngIf="redCrossCheck() && params.redCross"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_RED_CROSS)"
  />
  <img
    matTooltip="Export"
    class="table-action-icons"
    src="images-library/icon-library/export.svg"
    *ngIf="params.export"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_EXPORT)"
  />
  <img
    matTooltip="Pause"
    class="table-action-icons"
    src="pause.svg"
    *ngIf="params.pause"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_PAUSE)"
  />
  <img
    matTooltip="ConnectWise Manage"
    class="table-action-icons connectwise-icon"
    height="20"
    width="20"
    src="images-library/icon-library/icon_connectwise.svg"
    *ngIf="params.cwIndex && isCWSynced()"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_CW_SYNC)"
  />
  <img
    matTooltip="Autotask"
    class="table-action-icons"
    src="images-library/adl/sync_autotask.png"
    *ngIf="params.atIndex && isATSynced()"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_AT_SYNC)"
  />
  <img
    matTooltip="Tags"
    class="table-action-icons"
    src="images-library/icon-library/tag.svg"
    *ngIf="params.tags"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_TAGS_ACTION)"
  />
  <img
    matTooltip="Update"
    *ngIf="loaderCheck() && params.update"
    class="table-action-icons filterGrayscale"
    src="images-library/icon-library/refresh-s.svg"
    [ngClass]=" docLoader  ? 'icon-btn-loader' : ''"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_UPDATE_ACTION)"
  />
  <img
    matTooltip="Reset Expiry Date"
    class="table-action-icons"
    src="images-library/icon-library/add-date.svg"
    *ngIf="params.expiryDate"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_RESET_EXPIRY_DATE)"
  />
  <img
    matTooltip="Tags"
    class="table-action-icons"
    src="images-library/icon-library/tag-update.svg"
    *ngIf="params.tagsUpdate"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_TAGS_UPDATE)"
  />
  <img *ngIf="params.data && params.data.labTechSync" matTooltip="CW Automate" class="table-action-icons"
  src="images-library/adl/sync_labtech.png" />
  <img
    matTooltip="Download Report"
    class="table-action-icons"
    src="images-library/icon-library/icon_download_launcher.svg"
    (click)="actionClicked(constants.DEFAULT_DATAGRID_DOWNLOAD_REPORT)"
    *ngIf="params.download_report"
  />
  <div *ngFor = "let item of params.integrationIcons">
      <img
      [matTooltip]="item.toolTip"
      class="table-action-icons"
      [src]="item.url"
    />
  </div>
</div>

<div>
  <mde-popover #popover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
    <mat-card class="itb-popover">
      <div popoverBody class="popover-content">
        <h3 class="popover-title">Users</h3>
        <div class="popover-details" *ngIf="params.data">
          <div *ngFor= "let item of params.data.userPermission">
            {{item.fullName}}
          </div>
        </div>
      </div>
    </mat-card>
  </mde-popover>
</div>
