import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material'

@Component({
  selector: 'app-table-modal',
  templateUrl: './restore-deleted-companies.component.html',
})
export class RestoreDeletedCompaniesComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RestoreDeletedCompaniesComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    const index = this.data.columnDefs.length - 1
  }
}
