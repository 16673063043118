import { checkboxPadding } from '@projects/ag-grid-customized/src/app/constants/constants'

export const FORM_SUBMISSION = {
  POST_LOCATION_FORM: 'postLocationForm',
  STORE_COMMUNICATION: 'communicationChannel',
  POST_LOCATION_SUCCESS: 'postLocationFormSuccess',
  POST_LOCATION_FAIL: 'postLocationFormFail',
}

export const TABLE_CONFIGURAION_LIST_COLUMNDEFS = [
  {
    headerName: 'NAME',
    headerType: 'custom_header',
    field: 'name',
    type: 'name',
    filter: 'agTextColumnFilter',
    minWidth: 200,
    lockPosition: true,
    suppressColumnsToolPanel: true,
    cellRendererParams: {
      baseURL: 'app/company/$companyId/configurations/view',
      propertyName: 'uuid',
      companyId: '',
    },
    checkboxSelection: params => {
      let isDelete = true
      if (params.data && params.data.permission && params.data.permission.length > 0) {
        if (!params.data.permission.includes('delete') && !params.data.permission.includes('put')) isDelete = false
      }
      return isDelete
    },
    cellStyle: params => {
      let isDelete = true
      if (params.data && params.data.permission && params.data.permission.length > 0) {
        if (!params.data.permission.includes('delete') && !params.data.permission.includes('put')) isDelete = false
      }
      return isDelete ? '' : checkboxPadding
    },
  },
  {
    headerName: 'type',
    field: 'type.name',
    filter: 'agTextColumnFilter',
    minWidth: 140,
    suppressSorting: false,
    floatingFilterComponentParams: {
      suppressFilterButton: false,
    },
  },
  {
    headerName: 'Company',
    field: 'company.name',
    filter: 'agTextColumnFilter',
    minWidth: 140,
    hide: true,
    suppressColumnsToolPanel: true,
  },
  {
    headerName: 'SITE',
    field: 'site.name',
    filter: 'agTextColumnFilter',
    minWidth: 140,
  },
  {
    headerName: 'CONTACT',
    field: 'contact.name',
    filter: 'agTextColumnFilter',
    minWidth: 140,
  },
  {
    headerName: 'STATUS',
    field: 'status.name',
    filter: 'agTextColumnFilter',
    minWidth: 140,
  },
  {
    headerName: 'ASSET TAG',
    field: 'AssetTag',
    filter: 'agTextColumnFilter',
    minWidth: 200,
  },
  {
    headerName: 'SERIAL NUMBER',
    field: 'serialNumber',
    filter: 'agTextColumnFilter',
    minWidth: 140,
  },
  {
    headerName: 'OS',
    field: 'osType',
    filter: 'agTextColumnFilter',
    minWidth: 140,
  },
  {
    headerName: 'SEARCH TAG',
    field: 'searchTags',
    filter: 'agTextColumnFilter',
    minWidth: 200,
    type: 'tags',
    autoHeight: true,
    cellStyle: { 'white-space': 'normal' },
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    pinned: 'right',
    minWidth: 200,
    lockVisible: true,
    checkboxSelection: false,
    resizeable: true,
    filter: false,
    sortable: false,
    lockPinned: true,
    suppressMenu: true,
    suppressColumnsToolPanel: true,
    lockPosition: true,
    cellRendererParams: {
      edit: true,
      delete: true,
      docx: false,
      pdf: false,
      lock: true,
      newTicket: false,
      cwIndex: false,
      atIndex: false,
    },
  },
]
