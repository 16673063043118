export const END_POINTS = {
  POST_COMPANY_TYPE: 'organizations/companytype',
  POST_COMPANY_STATUS: 'organizations/companystatus',
  GET_COMAPNY_META: type => `organizations/metadata/${type}`,
  GET_COUNTRIES: 'organizations/metadata/countries',
  GET_COMPANY_MEMBERS: 'organizations/metadata/member',
  GET_COMPANY_CLASSIFFICATION: 'organizations/metadata/companyClassification',
}

export const STATES = [
  { id: 0, label: 'AL', isDisabled: false, isSelected: false },
  { id: 1, label: 'AK', isDisabled: false, isSelected: false },
  { id: 2, label: 'AZ', isDisabled: false, isSelected: false },
  { id: 3, label: 'AR', isDisabled: false, isSelected: false },
  { id: 4, label: 'AA', isDisabled: false, isSelected: false },
  { id: 5, label: 'AE', isDisabled: false, isSelected: false },
  { id: 6, label: 'AP', isDisabled: false, isSelected: false },
  { id: 7, label: 'CA', isDisabled: false, isSelected: false },
  { id: 8, label: 'CO', isDisabled: false, isSelected: false },
  { id: 9, label: 'CT', isDisabled: false, isSelected: false },
  { id: 10, label: 'DE', isDisabled: false, isSelected: false },
  { id: 11, label: 'DC', isDisabled: false, isSelected: false },
  { id: 12, label: 'FL', isDisabled: false, isSelected: false },
  { id: 13, label: 'GA', isDisabled: false, isSelected: false },
  { id: 14, label: 'HI', isDisabled: false, isSelected: false },
  { id: 15, label: 'ID', isDisabled: false, isSelected: false },
  { id: 16, label: 'IL', isDisabled: false, isSelected: false },
  { id: 17, label: 'IN', isDisabled: false, isSelected: false },
  { id: 18, label: 'IA', isDisabled: false, isSelected: false },
  { id: 19, label: 'KS', isDisabled: false, isSelected: false },
  { id: 20, label: 'KY', isDisabled: false, isSelected: false },
  { id: 21, label: 'LA', isDisabled: false, isSelected: false },
  { id: 22, label: 'ME', isDisabled: false, isSelected: false },
  { id: 23, label: 'MD', isDisabled: false, isSelected: false },
  { id: 24, label: 'MA', isDisabled: false, isSelected: false },
  { id: 25, label: 'MI', isDisabled: false, isSelected: false },
  { id: 26, label: 'MN', isDisabled: false, isSelected: false },
  { id: 27, label: 'MS', isDisabled: false, isSelected: false },
  { id: 28, label: 'MO', isDisabled: false, isSelected: false },
  { id: 29, label: 'MT', isDisabled: false, isSelected: false },
  { id: 30, label: 'NE', isDisabled: false, isSelected: false },
  { id: 31, label: 'NV', isDisabled: false, isSelected: false },
  { id: 32, label: 'NH', isDisabled: false, isSelected: false },
  { id: 33, label: 'NJ', isDisabled: false, isSelected: false },
  { id: 34, label: 'NM', isDisabled: false, isSelected: false },
  { id: 35, label: 'NY', isDisabled: false, isSelected: false },
  { id: 36, label: 'NC', isDisabled: false, isSelected: false },
  { id: 37, label: 'ND', isDisabled: false, isSelected: false },
  { id: 38, label: 'OH', isDisabled: false, isSelected: false },
  { id: 39, label: 'OK', isDisabled: false, isSelected: false },
  { id: 40, label: 'OR', isDisabled: false, isSelected: false },
  { id: 41, label: 'PA', isDisabled: false, isSelected: false },
  { id: 42, label: 'RI', isDisabled: false, isSelected: false },
  { id: 43, label: 'SC', isDisabled: false, isSelected: false },
  { id: 44, label: 'SD', isDisabled: false, isSelected: false },
  { id: 45, label: 'TN', isDisabled: false, isSelected: false },
  { id: 46, label: 'TX', isDisabled: false, isSelected: false },
  { id: 47, label: 'UT', isDisabled: false, isSelected: false },
  { id: 48, label: 'VT', isDisabled: false, isSelected: false },
  { id: 49, label: 'VA', isDisabled: false, isSelected: false },
  { id: 50, label: 'WA', isDisabled: false, isSelected: false },
  { id: 51, label: 'WV', isDisabled: false, isSelected: false },
  { id: 52, label: 'WI', isDisabled: false, isSelected: false },
  { id: 53, label: 'WY', isDisabled: false, isSelected: false },
]

export const COUNTRIES = [
  { id: 0, label: 'Afghanistan', isDisabled: false, isSelected: false },
  { id: 1, label: 'Albania', isDisabled: false, isSelected: false },
  { id: 2, label: 'Algeria', isDisabled: false, isSelected: false },
  { id: 3, label: 'Andorra', isDisabled: false, isSelected: false },
  { id: 4, label: 'Angola', isDisabled: false, isSelected: false },
  { id: 5, label: 'Anguilla', isDisabled: false, isSelected: false },
  { id: 6, label: 'Antigua & Barbuda', isDisabled: false, isSelected: false },
  { id: 7, label: 'Argentina', isDisabled: false, isSelected: false },
  { id: 8, label: 'Armenia', isDisabled: false, isSelected: false },
  { id: 9, label: 'Aruba', isDisabled: false, isSelected: false },
  { id: 10, label: 'Australia', isDisabled: false, isSelected: false },
  { id: 11, label: 'Austria', isDisabled: false, isSelected: false },
  { id: 12, label: 'Azerbaijan', isDisabled: false, isSelected: false },
  { id: 13, label: 'Bahamas', isDisabled: false, isSelected: false },
  { id: 14, label: 'Bahrain', isDisabled: false, isSelected: false },
  { id: 15, label: 'Bangladesh', isDisabled: false, isSelected: false },
  { id: 16, label: 'Barbados', isDisabled: false, isSelected: false },
  { id: 17, label: 'Belarus', isDisabled: false, isSelected: false },
  { id: 18, label: 'Belgium', isDisabled: false, isSelected: false },
  { id: 19, label: 'Belize', isDisabled: false, isSelected: false },
  { id: 20, label: 'Benin', isDisabled: false, isSelected: false },
  { id: 21, label: 'Bermuda', isDisabled: false, isSelected: false },
  { id: 22, label: 'Bhutan', isDisabled: false, isSelected: false },
  { id: 23, label: 'Bolivia', isDisabled: false, isSelected: false },
  { id: 24, label: 'Bosnia & Herzegovina', isDisabled: false, isSelected: false },
  { id: 25, label: 'Botswana', isDisabled: false, isSelected: false },
  { id: 26, label: 'Brazil', isDisabled: false, isSelected: false },
  { id: 27, label: 'British Virgin Islands', isDisabled: false, isSelected: false },
  { id: 28, label: 'Brunei', isDisabled: false, isSelected: false },
  { id: 29, label: 'Bulgaria', isDisabled: false, isSelected: false },
  { id: 30, label: 'Burkina Faso', isDisabled: false, isSelected: false },
  { id: 31, label: 'Burundi', isDisabled: false, isSelected: false },
  { id: 32, label: 'Cambodia', isDisabled: false, isSelected: false },
  { id: 33, label: 'Cameroon', isDisabled: false, isSelected: false },
  { id: 35, label: 'Canada', isDisabled: false, isSelected: false },
  { id: 36, label: 'Cape Verde', isDisabled: false, isSelected: false },
  { id: 37, label: 'Cayman Islands', isDisabled: false, isSelected: false },
  { id: 38, label: 'Chad', isDisabled: false, isSelected: false },
  { id: 39, label: 'Chile', isDisabled: false, isSelected: false },
  { id: 40, label: 'China', isDisabled: false, isSelected: false },
  { id: 41, label: 'Colombia', isDisabled: false, isSelected: false },
  { id: 42, label: 'Congo', isDisabled: false, isSelected: false },
  { id: 43, label: 'Cook Islands', isDisabled: false, isSelected: false },
  { id: 44, label: 'Costa Rica', isDisabled: false, isSelected: false },
  { id: 45, label: 'Cote D Ivoire', isDisabled: false, isSelected: false },
  { id: 46, label: 'Croatia', isDisabled: false, isSelected: false },
  { id: 47, label: 'Cruise Ship', isDisabled: false, isSelected: false },
  { id: 48, label: 'Cuba', isDisabled: false, isSelected: false },
  { id: 49, label: 'Cyprus', isDisabled: false, isSelected: false },
  { id: 50, label: 'Czech Republic', isDisabled: false, isSelected: false },
  { id: 51, label: 'Denmark', isDisabled: false, isSelected: false },
  { id: 52, label: 'Djibouti', isDisabled: false, isSelected: false },
  { id: 53, label: 'Dominica', isDisabled: false, isSelected: false },
  { id: 54, label: 'Dominican Republic', isDisabled: false, isSelected: false },
  { id: 55, label: 'Ecuador', isDisabled: false, isSelected: false },
  { id: 56, label: 'Egypt', isDisabled: false, isSelected: false },
  { id: 57, label: 'El Salvador', isDisabled: false, isSelected: false },
  { id: 58, label: 'Equatorial Guinea', isDisabled: false, isSelected: false },
  { id: 59, label: 'Estonia', isDisabled: false, isSelected: false },
  { id: 60, label: 'Ethiopia', isDisabled: false, isSelected: false },
  { id: 61, label: 'Falkland Islands', isDisabled: false, isSelected: false },
  { id: 62, label: 'Faroe Islands', isDisabled: false, isSelected: false },
  { id: 63, label: 'Fiji', isDisabled: false, isSelected: false },
  { id: 64, label: 'Finland', isDisabled: false, isSelected: false },
  { id: 65, label: 'France', isDisabled: false, isSelected: false },
  { id: 66, label: 'French Polynesia', isDisabled: false, isSelected: false },
  { id: 67, label: 'French West Indies', isDisabled: false, isSelected: false },
  { id: 68, label: 'Gabon', isDisabled: false, isSelected: false },
  { id: 69, label: 'Gambia', isDisabled: false, isSelected: false },
  { id: 70, label: 'Georgia', isDisabled: false, isSelected: false },
  { id: 71, label: 'Germany', isDisabled: false, isSelected: false },
  { id: 72, label: 'Ghana', isDisabled: false, isSelected: false },
  { id: 73, label: 'Gibraltar', isDisabled: false, isSelected: false },
  { id: 74, label: 'Greece', isDisabled: false, isSelected: false },
  { id: 75, label: 'Greenland', isDisabled: false, isSelected: false },
  { id: 76, label: 'Grenada', isDisabled: false, isSelected: false },
  { id: 77, label: 'Guam', isDisabled: false, isSelected: false },
  { id: 78, label: 'Guatemala', isDisabled: false, isSelected: false },
  { id: 79, label: 'Guernsey', isDisabled: false, isSelected: false },
  { id: 80, label: 'Guinea', isDisabled: false, isSelected: false },
  { id: 81, label: 'Guinea Bissau', isDisabled: false, isSelected: false },
  { id: 82, label: 'Guyana', isDisabled: false, isSelected: false },
  { id: 83, label: 'Haiti', isDisabled: false, isSelected: false },
  { id: 84, label: 'Honduras', isDisabled: false, isSelected: false },
  { id: 85, label: 'Hong Kong', isDisabled: false, isSelected: false },
  { id: 86, label: 'Hungary', isDisabled: false, isSelected: false },
  { id: 87, label: 'Iceland', isDisabled: false, isSelected: false },
  { id: 88, label: 'India', isDisabled: false, isSelected: false },
  { id: 89, label: 'Indonesia', isDisabled: false, isSelected: false },
  { id: 90, label: 'Iran', isDisabled: false, isSelected: false },
  { id: 91, label: 'Iraq', isDisabled: false, isSelected: false },
  { id: 92, label: 'Ireland', isDisabled: false, isSelected: false },
  { id: 93, label: 'Isle of Man', isDisabled: false, isSelected: false },
  { id: 94, label: 'Israel', isDisabled: false, isSelected: false },
  { id: 95, label: 'Italy', isDisabled: false, isSelected: false },
  { id: 96, label: 'Jamaica', isDisabled: false, isSelected: false },
  { id: 97, label: 'Japan', isDisabled: false, isSelected: false },
  { id: 98, label: 'Jersey', isDisabled: false, isSelected: false },
  { id: 99, label: 'Jordan', isDisabled: false, isSelected: false },
  { id: 100, label: 'Kazakhstan', isDisabled: false, isSelected: false },
  { id: 101, label: 'Kenya', isDisabled: false, isSelected: false },
  { id: 102, label: 'Kuwait', isDisabled: false, isSelected: false },
  { id: 103, label: 'Kyrgyz Republic', isDisabled: false, isSelected: false },
  { id: 104, label: 'Laos', isDisabled: false, isSelected: false },
  { id: 105, label: 'Latvia', isDisabled: false, isSelected: false },
  { id: 106, label: 'Lebanon', isDisabled: false, isSelected: false },
  { id: 107, label: 'Lesotho', isDisabled: false, isSelected: false },
  { id: 108, label: 'Liberia', isDisabled: false, isSelected: false },
  { id: 109, label: 'Libya', isDisabled: false, isSelected: false },
  { id: 110, label: 'Liechtenstein', isDisabled: false, isSelected: false },
  { id: 111, label: 'Lithuania', isDisabled: false, isSelected: false },
  { id: 112, label: 'Luxembourg', isDisabled: false, isSelected: false },
  { id: 113, label: 'Macau', isDisabled: false, isSelected: false },
  { id: 114, label: 'Macedonia', isDisabled: false, isSelected: false },
  { id: 115, label: 'Madagascar', isDisabled: false, isSelected: false },
  { id: 116, label: 'Malawi', isDisabled: false, isSelected: false },
  { id: 117, label: 'Malaysia', isDisabled: false, isSelected: false },
  { id: 118, label: 'Maldives', isDisabled: false, isSelected: false },
  { id: 119, label: 'Mali', isDisabled: false, isSelected: false },
  { id: 120, label: 'Malta', isDisabled: false, isSelected: false },
  { id: 121, label: 'Mauritania', isDisabled: false, isSelected: false },
  { id: 122, label: 'Mauritius', isDisabled: false, isSelected: false },
  { id: 123, label: 'Mexico', isDisabled: false, isSelected: false },
  { id: 124, label: 'Moldova', isDisabled: false, isSelected: false },
  { id: 125, label: 'Monaco', isDisabled: false, isSelected: false },
  { id: 126, label: 'Mongolia', isDisabled: false, isSelected: false },
  { id: 127, label: 'Montenegro', isDisabled: false, isSelected: false },
  { id: 128, label: 'Montserrat', isDisabled: false, isSelected: false },
  { id: 129, label: 'Morocco', isDisabled: false, isSelected: false },
  { id: 130, label: 'Mozambique', isDisabled: false, isSelected: false },
  { id: 131, label: 'Namibia', isDisabled: false, isSelected: false },
  { id: 132, label: 'Nepal', isDisabled: false, isSelected: false },
  { id: 133, label: 'Netherlands', isDisabled: false, isSelected: false },
  { id: 134, label: 'Netherlands Antilles', isDisabled: false, isSelected: false },
  { id: 135, label: 'New Caledonia', isDisabled: false, isSelected: false },
  { id: 136, label: 'New Zealand', isDisabled: false, isSelected: false },
  { id: 137, label: 'Nicaragua', isDisabled: false, isSelected: false },
  { id: 138, label: 'Niger', isDisabled: false, isSelected: false },
  { id: 139, label: 'Nigeria', isDisabled: false, isSelected: false },
  { id: 140, label: 'Norway', isDisabled: false, isSelected: false },
  { id: 141, label: 'Oman', isDisabled: false, isSelected: false },
  { id: 142, label: 'Pakistan', isDisabled: false, isSelected: false },
  { id: 143, label: 'Palestine', isDisabled: false, isSelected: false },
  { id: 144, label: 'Panama', isDisabled: false, isSelected: false },
  { id: 145, label: 'Papua New Guinea', isDisabled: false, isSelected: false },
  { id: 146, label: 'Paraguay', isDisabled: false, isSelected: false },
  { id: 147, label: 'Peru', isDisabled: false, isSelected: false },
  { id: 148, label: 'Philippines', isDisabled: false, isSelected: false },
  { id: 149, label: 'Poland', isDisabled: false, isSelected: false },
  { id: 150, label: 'Portugal', isDisabled: false, isSelected: false },
  { id: 151, label: 'Puerto Rico', isDisabled: false, isSelected: false },
  { id: 152, label: 'Qatar', isDisabled: false, isSelected: false },
  { id: 153, label: 'Reunion', isDisabled: false, isSelected: false },
  { id: 154, label: 'Romania', isDisabled: false, isSelected: false },
  { id: 155, label: 'Russia', isDisabled: false, isSelected: false },
  { id: 156, label: 'Rwanda', isDisabled: false, isSelected: false },
  { id: 157, label: 'Saint Pierre & Miquelon', isDisabled: false, isSelected: false },
  { id: 158, label: 'Samoa', isDisabled: false, isSelected: false },
  { id: 159, label: 'San Marino', isDisabled: false, isSelected: false },
  { id: 160, label: 'Satellite', isDisabled: false, isSelected: false },
  { id: 161, label: 'Saudi Arabia', isDisabled: false, isSelected: false },
  { id: 162, label: 'Senegal', isDisabled: false, isSelected: false },
  { id: 163, label: 'Serbia', isDisabled: false, isSelected: false },
  { id: 164, label: 'Seychelles', isDisabled: false, isSelected: false },
  { id: 165, label: 'Sierra Leone', isDisabled: false, isSelected: false },
  { id: 166, label: 'Singapore', isDisabled: false, isSelected: false },
  { id: 167, label: 'Slovakia', isDisabled: false, isSelected: false },
  { id: 168, label: 'Slovenia', isDisabled: false, isSelected: false },
  { id: 169, label: 'South Africa', isDisabled: false, isSelected: false },
  { id: 170, label: 'South Korea', isDisabled: false, isSelected: false },
  { id: 171, label: 'Spain', isDisabled: false, isSelected: false },
  { id: 172, label: 'Sri Lanka', isDisabled: false, isSelected: false },
  { id: 173, label: 'St Kitts & Nevis', isDisabled: false, isSelected: false },
  { id: 174, label: 'St Lucia', isDisabled: false, isSelected: false },
  { id: 175, label: 'St Vincent', isDisabled: false, isSelected: false },
  { id: 176, label: 'St. Lucia', isDisabled: false, isSelected: false },
  { id: 177, label: 'Sudan', isDisabled: false, isSelected: false },
  { id: 178, label: 'Suriname', isDisabled: false, isSelected: false },
  { id: 179, label: 'Swaziland', isDisabled: false, isSelected: false },
  { id: 180, label: 'Sweden', isDisabled: false, isSelected: false },
  { id: 181, label: 'Switzerland', isDisabled: false, isSelected: false },
  { id: 182, label: 'Syria', isDisabled: false, isSelected: false },
  { id: 183, label: 'Taiwan', isDisabled: false, isSelected: false },
  { id: 184, label: 'Tajikistan', isDisabled: false, isSelected: false },
  { id: 185, label: 'Tanzania', isDisabled: false, isSelected: false },
  { id: 186, label: 'Thailand', isDisabled: false, isSelected: false },
  { id: 187, label: 'Timor L Este', isDisabled: false, isSelected: false },
  { id: 188, label: 'Togo', isDisabled: false, isSelected: false },
  { id: 189, label: 'Tonga', isDisabled: false, isSelected: false },
  { id: 190, label: 'Trinidad & Tobago', isDisabled: false, isSelected: false },
  { id: 191, label: 'Tunisia', isDisabled: false, isSelected: false },
  { id: 192, label: 'Turkey', isDisabled: false, isSelected: false },
  { id: 193, label: 'Turkmenistan', isDisabled: false, isSelected: false },
  { id: 194, label: 'Turks & Caicos', isDisabled: false, isSelected: false },
  { id: 195, label: 'Uganda', isDisabled: false, isSelected: false },
  { id: 196, label: 'Ukraine', isDisabled: false, isSelected: false },
  { id: 197, label: 'United Arab Emirates', isDisabled: false, isSelected: false },
  { id: 198, label: 'United States', isDisabled: false, isSelected: false },
  { id: 199, label: 'United Kingdom', isDisabled: false, isSelected: false },
  { id: 200, label: 'Uruguay', isDisabled: false, isSelected: false },
  { id: 201, label: 'Uzbekistan', isDisabled: false, isSelected: false },
  { id: 202, label: 'Venezuela', isDisabled: false, isSelected: false },
  { id: 203, label: 'Vietnam', isDisabled: false, isSelected: false },
  { id: 204, label: 'Virgin Islands (US)', isDisabled: false, isSelected: false },
  { id: 205, label: 'Yemen', isDisabled: false, isSelected: false },
  { id: 206, label: 'Zambia', isDisabled: false, isSelected: false },
  { id: 207, label: 'Zimbabwe', isDisabled: false, isSelected: false },
]
