export const stringConstants = {

  DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK: 'cellRendererFramework',
  DEFAULT_DATAGRID_CUSTOM_HEADER_FRAMEWORK: 'headerComponentFramework',
  CONNECTWISE: 'CONNECTWISE',
  AUTOTASK: 'AUTOTASK',
  COMPLETED: 'Completed',
  PARTIAL_COMPLETED: 'Partial Completed',
  DEFAULT_SELECT_OPTION: '--- Please Select ---',
  // cell renderer types start
  DEFAULT_DATAGRID_TAGS: 'tags',
  DEFAULT_DATAGRID_CUSTOM_HEADER: 'custom_header',
  DEFAULT_DATAGRID_ACTIONS: 'actions',
  DEFAULT_DATAGRID_DATE: 'date',
  DEFAULT_DATAGRID_NAME: 'name',
  DEFAULT_DATAGRID_CUSTOME_TEMPLTE: 'custom_templates',
  DEFAULT_DATAGRID_ICON: 'icon',
  DEFAULT_DATAGRID_IMAGE: 'image',
  DEFAULT_DATAGRID_HOVERTAGS: 'clickable-tags',
  DEFAULT_DATAGRID_PROGRESSBAR: 'progressbar',
  DEFAULT_DATAGRID_STATUS_PROGRESS: 'status-progress',
  DEFAULT_DATAGRID_SMILEY: 'smiley',
  DEFAULT_DATAGRID_OS_IMAGE: 'os',
  DEFAULT_DATAGRID_AGREEMENT: 'agreement',
  DEFAULT_DATAGRID_JSON : 'json',
  DEFAULT_DATAGRID_PASSWORD : 'password',
  DEFAULT_DATAGRID_URL_MAP : 'url',
  // cell renderer types end

  // cell renderer actions
  DEFAULT_DATAGRID_DELETE: 'delete',
  DEFAULT_DATAGRID_SEND_INVITE: 'send_Invite',
  DEFAULT_DATAGRID_RESET_PASSWORD: 'reset_password',
  DEFAULT_DATAGRID_CONTACT_DASHBOARD: 'contact_dashboard',
  DEFAULT_DATAGRID_NEW_TICKET: 'new_ticket',
  DEFAULT_DATAGRID_RED_CROSS: 'red_cross',
  DEFAULT_DATAGRID_NAVIGATE_TO_VIEW: 'navigate_to_view',
  DEFAULT_DATAGRID_VIEW_TEMPLATES_DETAIL: 'open_templates_modal',
  DEFAULT_DATAGRID_NAVIGATE_TO_PASSWORD : 'navigate_to_password',
  DEFAULT_DATAGRID_RESET_EXPIRY_DATE : 'expiry_date',
  DEFAULT_DATAGRID_TAGS_UPDATE : 'tags_update',
  DEFAULT_DATAGRID_NAVIGATE_TO_COMPANY : 'navigate_to_company',
  DEFAULT_DATAGRID_NAVIGATE_TO_COMPANY_DASHBOARD : 'navigate_to_company_dashboard',
  DEFAULT_DATAGRID_URL: 'navigate_to_url',
  DEFAULT_DATAGRID_NAVIGATE_TO_EDIT: 'navigate_to_edit',
  DEFAULT_DATAGRID_COPY_COMPANIES: 'copy_companies',
  DEFAULT_DATAGRID_EXPORT_DOC: 'export_doc',
  DEFAULT_DATAGRID_EXPORT_PDF: 'export_pdf',
  DEFAULT_DATAGRID_EXPORT: 'export',
  DEFAULT_DATAGRID_PLAY: 'play',
  DEFAULT_DATAGRID_LOGS: 'logs',
  DEFAULT_DATAGRID_TICK: 'tick',
  DEFAULT_DATAGRID_PAUSE: 'pause',
  DEFAULT_DATAGRID_SETTINGS: 'settings',
  DEFAULT_DATAGRID_CW_SYNC: 'cw_sync',
  DEFAULT_DATAGRID_AT_SYNC: 'at_sync',
  DEFAULT_DATAGRID_TAGS_ACTION : 'tags',
  DEFAULT_DATAGRID_UPDATE_ACTION : 'update',
  DEFAULT_DATAGRID_DOWNLOAD_REPORT: 'download_report',

  // table constants
  DEFAULT_DATAGRID_CONTAINS: 'contains',
  DEFAULT_DATAGRID_NOT_CONTAINS: 'notContains',
  DEFAULT_DATAGRID_EQUALS: 'equals',

  DEFAULT_DATAGRID_ITB_RUNBOOK_STATUS: 'ITBRunBookStatus',
  DEFAULT_DATAGRID_PRIVATE: 'private',
  DEFAULT_DATAGRID_PUBLIC: 'public',
  DEAULT_DATAGRID_TRUE: 'true',
  DEFAULT_DATAGRID_FALSE: 'false',
  DEFAULT_DATAGRID_TEXT: 'text',
  DEFAULT_DATAGRID_SIDEBAR_ID: 'columns',
  DEFAULT_DATAGRID_SIDEBAR_LABEL: 'Columns',
  DEFAULT_DATAGRID_SIDEBAR_LABEL_KEY: 'columns',
  DEFAULT_DATAGRID_SIDEBAR_LABEL_ICON_KEY: 'columns',
  DEFAULT_DATAGRID_SIDEBAR_TOOL_PANEL: 'agColumnsToolPanel',
  DEFAULT_DATAGRID_TOOLTIP_COMPONENT: 'dataGridTooltip',

  // Simley cell renderer constants
  SMILEY_IMAGES: ['images-library/smiley-poor.png', 'images-library/smiley-confuse.png',
                  'images-library/smiley-neutral.png', 'images-library/smiley-satified.png',
                  'images-library/smiley-happy.png'],
  // master data table consts
  MDT: {
    CW_CLASS: 'table-action-icons connectwise-icon',
    CW_CLASS_FAILED: 'icon-red connectwise-icon',
    CW_CLASS_DISABLED: 'icon-disabled connectwise-icon',
    AT_CLASS: 'table-action-icons autotask-icon',
    AT_CLASS_FAILED: 'icon-red autotask-icon',
    AT_CLASS_DISABLED: 'icon-disabled autotask-icon',
    CW_IMAGE: 'images-library/icon-library/icon_connectwise.svg',
    AT_IMAGE: 'images-library/adl/sync_autotask.png',
    AUTOTASK: 'autotask',
    CW: 'connectwise',
  },
}

export const checkboxPadding = { 'padding-left': '40px' }
