import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(value, args, term) {
    if (!args || !term) {
      return value
    }
    // Match in a case insensitive maneer
    const re = new RegExp(args, 'gi')
    const match = value ? value.match(re) : undefined
    // If there's no match, just return the original value.
    if (!match) {
      return value
    }

    const replacedValue = value.replace(re, `<mark> ${match[0]} </mark>`)
    return replacedValue
  }
}
