import { Component, HostListener, Inject, OnDestroy } from '@angular/core'
import {
  FormControl, FormGroup,
} from '@angular/forms'
import { Router } from '@angular/router'
import { AppComponent } from '@app/app.component'
import { backendRoutes } from '@core/constants/constant'
import { CoreService } from '@core/services/core.service'
import { Subscription } from 'rxjs/index'
@Component({
  selector: 'app-welcome-screen',
  templateUrl: './welcome-screen.component.html',
  styleUrls: ['./welcome-screen.component.css'],
})
export class WelcomeScreenComponent implements  OnDestroy {
  isDisabled: boolean
  subscriptions: Subscription
  hasLoader: boolean

  parentForm = new FormGroup({
    acceptTerms: new FormControl(''),

  })
  constructor(@Inject(AppComponent) private readonly appComponent,
              public coreService: CoreService, public router: Router) {
    this.isDisabled = true
    this.hasLoader = false
    this.subscriptions = new Subscription()

  }
  @HostListener('scroll', ['$event'])
  onScroll(event) {

    // tslint:disable-next-line: restrict-plus-operands
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.isDisabled = false
      this.parentForm.patchValue({ acceptTerms: true })
    }
  }
  changeEvent(params) {

    this.isDisabled = (params.checked) ? false : true
  }
  letsGo() {
    this.subscriptions.add(this.coreService.updateTerms().subscribe(response => {
      const data = {
        firstTimeLoginFlag: false,
      }
      const url = backendRoutes.FIRST_FLAG_LOGIN_URL
      this.subscriptions.add(
        this.coreService.updateFirsttimeLoginStatus(data, url).subscribe(responseFirstLogin => {
          this.hasLoader = true
          this.coreService.updateFirstLogin(true)
          this.router.navigate(['app/dashboard']).then(routerData => {
            this.appComponent.hideHeader()
          }).catch()
        })
      )
    }))

  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe()

  }
}
