<itb-heading mat-dialog-title [className]="'modal-h'" [text]="name"></itb-heading>
<itb-round-button
  [tooltipText]="'Close'"
  mat-dialog-close
  [className]="'light round-modal-cross caret-close-modal'"
  [iconClassName]="'cross'"
></itb-round-button>

<div mat-dialog-content>
  <ng-container *ngIf="isColLoaded">
    <itb-data-grid
      [columnDefs]="columnDefs"
      [rowModelType]="'serverSide'"
      (dataGridRowsPropsEmitter)="getRows($event)"
      (columnStateChangeEmitter)="columnStateChanged($event)"
      [initialUserColState]="userColumnState"
      [sideBar]="true"
      [cacheBlockSize]="20"
      [totalRows]="completeCount"
    >
    </itb-data-grid>
  </ng-container>
  <itb-spinner fxLayoutAlign="center center" class="h-100" [spinnerSize]="75" *ngIf="isLoading"></itb-spinner>
  <div *ngIf="isEmpty" class="no-data" fxLayout="column" fxLayoutAlign="center center">
    <span [ngClass]="{ 'img-nodatafound': true }"></span>
    <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
  </div>
  <div *ngIf="isError" class="no-data" fxLayout="column" fxLayoutAlign="center center">
    <span [ngClass]="{ 'img-404error': true }"></span>
    <itb-text [className]="'text-default text-color-primary'" [text]="'SOMETHING WENT WRONG'"></itb-text>
  </div>
</div>
<div mat-dialog-actions>
  <itb-default-button [mat-dialog-close]="'Close'" [text]="'cancel'" [className]="'default'" [iconClassName]="'cross'"></itb-default-button>
</div>
