<div mat-dialog-title fxLayout="row"  fxLayoutAlign="start center">
  <itb-heading [className]="'modal-h'" [text]="'Two Factor Authentication'"></itb-heading>
  <itb-round-button *ngIf="user.googleAuthAlgorithmFlag" class="ml-2" [className]="'dark'" [iconClassName]="'twofa-nda'" [tooltipText]="'Disable Google Authentication'" (onClickHandler)="changeStatus(false)" ></itb-round-button>
</div>
<itb-round-button mat-dialog-close fxHide.xs
                  [className]="'light round-modal-cross caret-close-modal'"  [tooltipText]="'Close'"
                  [iconClassName]="'cross'" *ngIf="!user.enforce2fa"> </itb-round-button>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="googleAuthForm" >
    <!-- <div class="pull-right pageTRIcon" *ngIf="user.googleAuthAlgorithmFlag">
      <itb-image [className]="''"   [src]="'https://live-aws.itboost.com/assets/img/icon_2fa_disabled.svg'"
                 (onClickHandler)="changeStatus(false)"  [imageWidth]=35></itb-image>
    </div> -->
    <div class="text-center m-b-20 m-t-20" *ngIf="user.googleAuthAlgorithmFlag">
      <itb-image [imageWidth]=250 [className]="''"   [src]="'images-library/2fa-success.png'"></itb-image>
    </div>

    <div fxFlexLayout="row" *ngIf="!user.googleAuthAlgorithmFlag">
        <p><itb-text fxFlexLayout="column" [className]="'text-default'" [text]="'Use Google Authenticator to scan this code'"></itb-text></p>
      <itb-spinner [spinnerSize]="50" *ngIf="isLoading"></itb-spinner>
        <itb-image fxFlexLayout="column" [className]="'barCodeImage'"  *ngIf="qrCode && !isLoading" [src]="qrCode.dataUrl"></itb-image>
    </div>

    <p *ngIf="!user.googleAuthAlgorithmFlag"><itb-text fxFlexLayout="column" [className]="'text-default'" [text]="'Enter the verification code generated by the application'"></itb-text> </p>

    <itb-input *ngIf="!user.googleAuthAlgorithmFlag" [formFieldName]="'code'"  [isRequired]="true"
               [parentForm]="googleAuthForm" [label]="'Verification Code'" [maxlength]="6"
               [placeholder]="" [type]="'number'"></itb-input>
    <itb-info-message *ngIf="hasGoogleAuthErrorMessage && !user.googleAuthAlgorithmFlag" [text]="'Invalid verification code'" [className]="'info-danger for-input'"></itb-info-message>

    <div class="alert alert-success sc-info-msg" role="alert"  *ngIf="!user.googleAuthAlgorithmFlag">
      <div class="d-flex align-items-center">
        <itb-icon class="d-flex"  [tooltipText]="" [className]="'btn-icon info-c'" ></itb-icon>
        <itb-text [className]="'text-default'" [text]="'Instructions'"></itb-text>
      </div>

      <ul class="unstyled-list">
        <li>1. Download and Install Google Authenticator on your mobile</li>
        <li>2. Open the application and scan the QR code</li>
        <li>3. Enter the 6-digit verification code in the field above</li>
      </ul>
  </div>
</form>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!user.googleAuthAlgorithmFlag">
  <itb-default-button [text]="'Save'" [className]="'primary'" [iconClassName]="'save'"
                      [isDisabled]="!googleAuthForm.value.code || isLoading" [isLoading]="isSubmitLoader"
                      (onClickHandler)="submitCode()"></itb-default-button>
  <itb-default-button [text]="'Cancel'" [className]="'default'" [iconClassName]="'cross'" mat-dialog-close *ngIf="!user.enforce2fa"
                     ></itb-default-button>

</mat-dialog-actions>


