<app-search (closeSearch)="close()" *ngIf="canShowGlobalSearch === true"></app-search>
<header class="app-header">
  <nav class="header-main-navbar">
    <div class="inner-header app-logo logo" *ngIf="isShowLogo">
      <itb-image [isLink]=true [link]="mainDashboardLink" [imageWidth]="180" [src]="this.orgLogo" [canShowErrorImage]=true [srcNotFound]="defaultLogoPath"></itb-image>
  </div>
  <div *ngIf="_router == '/app/company' || _router == '/app/admin'" class="company-menu-icon" fxLayoutAlign="start center" fxFlex="" fxHide="false" fxHide.gt-sm="" >
    <itb-icon class="icon_menu icon-white menu-small" (click)="headerButton()" ></itb-icon>
</div>
      <div   class="adaptive-main-menu inner-header small-screen {{toggleMenuClass}}"  (window:resize)="respMenuClass.doMenuAdaptive()" >
          <div class="menu-cross"  (window:resize)="windowResizeFunc()">
              <itb-icon [className]="'cross'" (click)="toggleMenu()" ></itb-icon>
          </div>
          <ul class="main-menu"  >
            <itb-menu-button class="main-i" *ngFor="let category of categoryList; let i = index" [className]="category.className" [menuButton]="category" [isLink]="category.isLink"
            [link]="category.link" [subMenu]="subMenuList[category.name]" (onClickHandler)="onCategoryClick($event)"></itb-menu-button>
              <li class="-menu-more">
                  <div class="btn-icon edit-dots" aria-haspopup="true" aria-expanded="false"></div>
                  <ul class="-menu-secondary">
                      <itb-menu-button class="sec-i --hidden" *ngFor="let category of categoryList" [className]="category.className"
                                       [menuButton]="category" [isLink]='category.isLink'  (onClickHandler)="onCategoryClick($event)"
                        [link]="category.link"></itb-menu-button>
                  </ul>
              </li>
          </ul>
      </div>
      <div class="inner-header push-right">
          <itb-search-bar-button [className]="'itb-search-bar-button'" [searchBarButton]="searchBarButton"
                                 *ngIf="user.userType !=='external'" (onClickHandler)="onSearch()"></itb-search-bar-button>
          <itb-icon [className]="'icon-chatassits icon-white mt-1'" [tooltipText]="'Chat with Support'"
                    (onClickHandler)="supportLink()"></itb-icon>
          <itb-dropdown-list [matTooltip]="'Help'" fxHide.sm fxHide.xs [dropDownButton]="ticketItemsClass" [items]="help"  *ngIf="user.userType !=='external'"
                             (onClickHandler)="helpClick($event)"></itb-dropdown-list>
         <!-- <itb-dropdown [dropDownButton]="notificationItemsClass" [items]="notificationItems"></itb-dropdown>-->
         <app-header-notification fxHide.sm fxHide.xs  class="header-noti" *ngIf="user.userType !=='external'"></app-header-notification>
          <itb-dropdown [matTooltip]="'Tour'" fxHide.sm fxHide.xs [buttonType]="'progressBtn'" [className]="'itb-progress-button'"
                         [progressButton]="progressButton" *ngIf="user.userType !=='external' && user.canAccessAll && showTour">
            <div class="tour-dropdown">
                <itb-heading [className]="'heading dropdown-list-h'" [text]="'Personalize ITBOOST'" ></itb-heading>
                <itb-divider [className]="'mat-divider'"></itb-divider>
                <itb-list-item *ngFor="let item of onBoardPersonalizationItems" [item]="item" (onClickHandler)="onBoardingItemClicked($event)" (onInfoButtonClickHandler)="infoHandler($event)"></itb-list-item>
                <itb-heading [className]="'heading dropdown-list-h'" [text]="'Security & Users'" ></itb-heading>
                <itb-divider [className]="'mat-divider'"></itb-divider>
                <itb-list-item *ngFor="let item of onBoardUserItems" [item]="item" (onClickHandler)="onBoardingItemClicked($event)" (onInfoButtonClickHandler)="infoHandler($event)"></itb-list-item>
                <itb-heading [className]="'heading dropdown-list-h'" [text]="'Custom & Flexible Assets'"></itb-heading>
                <itb-divider [className]="'mat-divider'"></itb-divider>
                <itb-list-item [item]="onBoardAssetItems" (onClickHandler)="onBoardingItemClicked($event)" (onInfoButtonClickHandler)="infoHandler($event)"></itb-list-item>
                <itb-heading [className]="'heading dropdown-list-h'" [text]="'Other System Settings'"></itb-heading>
                <itb-divider [className]="'mat-divider'"></itb-divider>
                <itb-list-item *ngFor="let item of onBoardSettingItems" [item]="item" (onClickHandler)="onBoardingItemClicked($event)" (onInfoButtonClickHandler)="infoHandler($event)"></itb-list-item>
                <itb-heading [className]="'heading dropdown-list-h'" [text]="'Integrations & Syncing'"></itb-heading>
                <itb-divider [className]="'mat-divider'"></itb-divider>
                <itb-list-item [item]="onBoardIntegrationItems" (onClickHandler)="onBoardingItemClicked($event)" (onInfoButtonClickHandler)="infoHandler($event)"></itb-list-item>
              <span *ngIf="tourData && !tourData.skipIntegrations && tourData.Integrations">
                <itb-heading [className]="'heading dropdown-list-h'" [text]="'Mapping & Merging'"></itb-heading>
                <itb-divider [className]="'mat-divider'"></itb-divider>
                <itb-list-item  [item]="onBoardMappingMerging[0]" (onClickHandler)="onBoardingItemClicked($event)" (onInfoButtonClickHandler)="infoHandler($event)"></itb-list-item>
                <itb-list-item *ngIf="tourData && !tourData.skipIntegrations && tourData.Integrations && user.mainIntegration ==='connectwise'"
                  [item]="onBoardMappingMerging[1]" (onClickHandler)="onBoardingItemClicked($event)"
                  (onInfoButtonClickHandler)="infoHandler($event)"></itb-list-item>
             </span>
              <itb-heading [className]="'heading dropdown-list-h'" [text]="'Master Data Table'"></itb-heading>
              <itb-divider [className]="'mat-divider'"></itb-divider>
              <itb-list-item *ngFor="let item of onBoardMasterDataTableItems" [item]="item" (onClickHandler)="onBoardingItemClicked($event)" (onInfoButtonClickHandler)="infoHandler($event)"></itb-list-item>
            </div>
          </itb-dropdown>
          <itb-dropdown-list [matTooltip]="'Settings'" *ngIf="user.userType !=='external' && user.canAccessAll" (onClickHandler)="changeSettings($event)"
            [dropDownButton]="settingItemsClass" [items]="settings"></itb-dropdown-list>
          <!--<itb-dropdown-list [matTooltip]="'Admin Portal (Beta)'" *ngIf="user.userType !=='external' && user.canAccessAll"
            [dropDownButton]="settingV4ItemsClass" [items]="settingsV4"></itb-dropdown-list>-->
          <itb-dropdown [buttonType]="'profileBtn'" class="'ml-1'" [progressButton]="profileDropdown" >
            <div class="menu-profile">
              <div class="userInfo pl-3 pr-3 pt-4 pb-4">
                <itb-image [className]="'user-image'" [src]="picture"></itb-image>
                <div class="userName ">
                  <itb-text [className]="'text-default text-color-white pl-2 d-flex'" [text]="fullName"></itb-text>
                  <itb-text [className]="'text-small text-color-white pl-2'" [text]="email"></itb-text>
                </div>
              </div>
              <button mat-menu-item (click)="profileClick()"><itb-icon [className]="'icon-profile'" ></itb-icon><span>Profile</span></button>
              <button mat-menu-item (click)="googleAuthPopup()" *ngIf="(!user.ssoEnable)||(user.ssoEnable&&user.canAccessAll)"><itb-icon [className]="'icon-2fa'" ></itb-icon><span>Two Factor Authentication</span>
                <img *ngIf="googleAuthFlag" class="gauth" src="images-library/icon-library/icon_circle_success.svg" alt="" width="20">
                <img *ngIf="!googleAuthFlag" class="gauth" src="images-library/icon-library/icon_circle_error.svg" alt="" width="20">
              </button>
              <button mat-menu-item *ngIf="(!user.ssoEnable)||(user.ssoEnable&&user.canAccessAll)" (click)="changePasswordComponet()"><itb-icon [className]="'icon-change-pass'" ></itb-icon><span>Change Password</span></button>
              <!-- <a *ngIf="(!user.ssoEnable)||(user.ssoEnable&&user.canAccessAll)" href="https://itboost.typeform.com/to/nylwOy" target="_blank">
                <button mat-menu-item><itb-icon
                [className]="'icon-onboarding'" ></itb-icon><span>Need Help in Onboarding?</span> <div class="book-session fs-12">Book a 1 on 1 session</div></button></a> -->
              <button mat-menu-item (click)="logout()"><itb-icon [className]="'icon-logout'" ></itb-icon><span>Logout</span></button>
            </div>
          </itb-dropdown>
          <itb-icon class="icon_menu icon-white menu-small" fxFlex="" fxHide="false" fxHide.gt-sm="" (click)="toggleMenu()" ></itb-icon>
      </div>
  </nav>
</header>

