import { getRouterState } from '@app/root-store/reducers/router-store'
import { createFeatureSelector, createSelector } from '@ngrx/store'

import { ADLState } from '../reducers/index'

// state definition
export const adlState = createFeatureSelector<ADLState>('Adl Router')

// selectors
export const getDiscoverers = createSelector(adlState, state => state.discoverers.data)
export const getDiscovererSystems = createSelector(adlState, state => state.discovererSystems.data)
export const getHeaders = createSelector(adlState, state => state.discoverers.headers)
// export const getActiveFlag = createSelector(locationState, state => state.locations.isActive)
// export const getSyncFlag = createSelector(locationState, state => state.locations.isSync)
// export const getLoadingStatus = createSelector(locationState, state => state.locations.isLoading)
export const getChangeStatus = createSelector(adlState, state => state.discoverers.hasDataChanged)
export const getDiscovererSystemChangeStatus = createSelector(adlState, state => state.discovererSystems.hasDataChanged)
// export const getLoadedStatus = createSelector(locationState, state => state.locations.isLoaded)
// export const getArraysStatus = createSelector(locationState, state => state.locations.canLoadArrays)
// export const getFieldsStatus = createSelector(locationState, state => state.locations.isDisabled)
// export const getCopyStatus = createSelector(locationState, state => state.locations.copied)
// export const getCurrentLocation = createSelector(locationState, state => state.location.data)
// export const getCurrentLocationError = createSelector(locationState, state => state.location.isError)
// export const getCurrentLocationUuid = createSelector(locationState, state => state.location.uuid)
// export const getCopiedLocation = createSelector(locationState, state => state.copied.data)
// export const getCopiedStatus = createSelector(locationState, state => state.copied.copied)

export const getParams = createSelector(
  adlState,
  getRouterState,
  (entities, router) => {
    if (router) return router.state.params
  }
)

export const getQueryParams = createSelector(
  adlState,
  getRouterState,
  (entities, router) => {
    if (router) return router.state.queryParams
  }
)
