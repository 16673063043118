import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { IUsers } from '@core/interfaces/users'
import { SharedDataService } from '@core/services/shared-data.service'
import { Subscription } from 'rxjs'
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponent implements OnDestroy {
  subscription: Subscription
  errorMessage: string
  user: IUsers
    /*** To handle 404 */
  constructor(private readonly _router: Router,  private readonly activatedRoute: ActivatedRoute,
              private readonly sharedDataService: SharedDataService) {
    this.errorMessage = "Either this record doesn't exist or you don't have access to it"
    this.user = this.sharedDataService.getUser()
    this.subscription = new Subscription()
  }
    /*** Routes to dashboard or company screen based on the user type */
  backHandler() {
    if (this.user.userType === 'internal') {
      this._router
        .navigateByUrl('app/dashboard')
        .then()
        .catch()
    } else {
      this.subscription.add(this.activatedRoute.params.subscribe(res => {
        this._router
          .navigateByUrl(`/app/company/${res.companyId}`)
          .then()
          .catch()
      }))
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
