export const config = {
  // tslint:disable-next-line: arrow-return-shorthand
  chartConfig: (counts, namee) => {
    return {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      series: [
        {
          name: namee,
          type: 'pie',
          radius: ['45%', '35%'],
          center: ['70%', '32%'],
          avoidLabelOverlap: false,
          color: counts === undefined ? ['#ddd'] : ['#e14c42', '#716aca', ' #00c5dc', '#ffb822'],
          label: {
            normal: {
              show: false,
              position: 'center',
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: '30',
                fontWeight: 'bold',
              },
            },
          },
          animation: false,
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: counts,
        },
      ],
    }
  },

}

export const COMPANY_PODS = {
  INTERVAL_TIME: 10000,
}
