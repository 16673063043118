import { Injectable } from '@angular/core'
import { IBoards, IBoardTypes, IMembers, ITicket, ITicketStatus } from '@app/modules/shared/interfaces/ticket.interface'
import { Contact } from '@projects/company-dashboard/src/app/modules/contacts/interfaces/contacts.interface'
import { Observable } from 'rxjs'

import { END_POINTS } from '../modules/shared/constants/ticket-constants'

import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root',
})
export class TicketService {

  constructor(
    private readonly _api: ApiService
  ) { }

  getBoards(): Observable<IBoards[]> {
    return this._api.get(END_POINTS.GET_BOARDS, 'Organization Boards')
  }
  getContacts(companyId: string): Observable<Contact[]> {
    return this._api.get(END_POINTS.GET_CONTACTS_BY_COMPANY(companyId), 'Contacts')
  }
  getStatuses(): Observable<ITicketStatus[]> {
    return this._api.get(END_POINTS.GET_TICKETSTATUS, 'Organization Ticket Statuses')
  }
  getTypes(): Observable<IBoardTypes[]> {
    return this._api.get(END_POINTS.GET_BOARDTYPES, 'Organization Board Types')
  }
  getTicketOwners(): Observable<IMembers[]> {
    return this._api.get(END_POINTS.GET_MEMBERS, 'Organization Members')
  }
  postTicket(payload: ITicket): Observable<ITicket> {
    return this._api.add(END_POINTS.POST_TICKET, payload)
  }
}
