import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { BUTTONS } from '@app/constants/ui-components.constants'
import { ModalService } from '@app/services/modal.service'
import * as AdlActions from '@app/store/adl-store/actions/adl.actions'
import { ADLState } from '@app/store/adl-store/reducers'
import * as selector from '@app/store/adl-store/selectors/index'
import { NOTIFICATION_CONSTANTS, RIGHT_SIDE_BAR, STRING_CONSTANTS } from '@core/constants/constants'
import { URL_CONSTANTS } from '@core/constants/url-constants'
import { ILinkedItems } from '@core/interfaces/linked-items'
import { IExpansionPanel, ISwitcherItem } from '@core/interfaces/panels'
import { RightSideBarService } from '@core/services/right-side-bar.service'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-company-pods-linkend-items',
  templateUrl: './pods-linkend-items.component.html',
  styleUrls: ['./pods-linkend-items.component.scss'],
})
export class PodsLinkendItemsComponent implements OnInit, OnDestroy {

  @Input() switcherItem: ISwitcherItem
  @Input() moduleName: string
  @Input() itemId: string
  @Input() itemName: string
  @Input() panelName: string
  @Input() companyId: string
  @Input() templateName?: string
  @Input() panelObj: IExpansionPanel
  @Input() hasEditPermissions: boolean

  linkedItems: ILinkedItems[]
  originalLinkedItemslist: ILinkedItems[]
  templates: any[] // tslint:disable-line: no-any
  linkedItemsIdsArr: string[]
  isDisabled: boolean
  isSpinner: boolean
  subscriptions: Subscription
  adlIntegrations: any[] // tslint:disable-line: no-any

  constructor(private readonly rightSideBarSvc: RightSideBarService, private readonly modalService: ModalService,
              private readonly router: Router, private readonly store: Store<ADLState>) {
    this.isDisabled = false
    this.subscriptions = new Subscription()
  }

  viewLinkedItem(id: string) {
    const selectedLinkedItem = this.linkedItems.find(linkedItem => linkedItem.uuid === id)
    if (selectedLinkedItem.relatedAsset === RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.CONTACT.id) {
      this.navigateToLinkedItemView(`app/company/${this.companyId}/contacts/view/${selectedLinkedItem.relatedAssetId}`,
        selectedLinkedItem.newTab
      )
      return
    }
    if (selectedLinkedItem.relatedAsset === RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.LOCATION.id) {
      this.navigateToLinkedItemView(`app/company/${this.companyId}/locations/view/${selectedLinkedItem.relatedAssetId}`,
        selectedLinkedItem.newTab
      )
      return
    }
    if (selectedLinkedItem.relatedAsset === RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.CONFIGURATION.id) {
      this.navigateToLinkedItemView(`app/company/${this.companyId}/configurations/view/${selectedLinkedItem.relatedAssetId}`,
        selectedLinkedItem.newTab
      )
      return
    }
    if (selectedLinkedItem.relatedAsset === RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.PASSWORD.id) {
      this.navigateToLinkedItemView(`app/company/${this.companyId}/passwords/view/${selectedLinkedItem.relatedAssetId}`,
        selectedLinkedItem.newTab
      )
      return
    }
    if (selectedLinkedItem.relatedAsset === RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.DOMAIN_TRACKER.id) {
      this.navigateToLinkedItemView(`app/company/${this.companyId}/domains/view/${selectedLinkedItem.relatedAssetId}`,
        selectedLinkedItem.newTab
      )
      return
    }
    if (selectedLinkedItem.relatedAsset === RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.KNOWLEDGEBASE.id) {
      if (this.companyId === '1') {
        this.navigateToLinkedItemView(`app/documentation/knowledgebase/view/${selectedLinkedItem.relatedAssetId}`,
          selectedLinkedItem.newTab)
      } else {
        this.navigateToLinkedItemView(`app/company/${this.companyId}/documents/knowledgebase/view/${selectedLinkedItem.relatedAssetId}`,
          selectedLinkedItem.newTab)
      }
      return
    }
    if (selectedLinkedItem.relatedAsset.includes('Cloud')) {
      const { uuid } = this.adlIntegrations.find(item => item.id === selectedLinkedItem.relatedAsset)
      this.navigateToLinkedItemView(`app/company/${this.companyId}/adl/discoverers/${uuid}/view/${selectedLinkedItem.relatedAssetId}`,
        selectedLinkedItem.newTab
      )
      return
    }
    const templateMetaId = this.templates.find(item => item.ITBTemplateName === selectedLinkedItem.relatedAsset).uuid
    this.navigateToLinkedItemView(
      `app/company/${this.companyId}/templates/${templateMetaId}/view/${selectedLinkedItem.relatedAssetId}`,
      selectedLinkedItem.newTab
    )
  }

  navigateToLinkedItemView(url: string, isNewTab = false) {
    isNewTab ? window.open(url, '_blank') : this.router.navigate([url]).catch()
  }

  createReverseLinkedItemslabels(label: string) {
    let reversedModuleName = ''
    let reversedModuleLabel = ''
    Object.entries(RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS).forEach(([, value]) => {
      if (value.id === label) {
        reversedModuleName = value.label
      }
      if (value.label === this.moduleName) {
        reversedModuleLabel = value.id
      }
    })
    if (reversedModuleName === '') {
      reversedModuleName = label.includes('Cloud') ? 'assetdiscovery/discoverersystems' : 'templates/templatesData'
    }
    if (reversedModuleLabel === '') {
      reversedModuleLabel = this.templateName
    }
    return { reversedModuleLabel, reversedModuleName }
  }

  deleteLinkedItem(id: string) {
    this.modalService.showDeleteConfirmationModal(`${STRING_CONSTANTS.DELETE} ${RIGHT_SIDE_BAR.LINKEDITEM}`,
      NOTIFICATION_CONSTANTS.DELETE(RIGHT_SIDE_BAR.LINKEDITEM)).subscribe(res => {
      if (res === BUTTONS.EVENTS.DELETE) {
        this.isSpinner = true
        this.switcherItem.isList = !this.switcherItem.isList
        this.subscriptions.add(
          this.rightSideBarSvc.deleteItem(URL_CONSTANTS.RIGHT_SIDE_BAR.PUT(this.moduleName,
            this.itemId, this.panelName, id)).subscribe(() => {
            const linkedItemObj = this.linkedItems.find(linkedItem => linkedItem.uuid === id)
            const { reversedModuleLabel, reversedModuleName } = this.createReverseLinkedItemslabels(linkedItemObj.relatedAsset)
            this.subscriptions.add(
              this.rightSideBarSvc.deleteItem(
                `${reversedModuleName}/reverse/${linkedItemObj.relatedAssetId}/${this.panelName}/${this.itemId}/${reversedModuleLabel}`)
                .subscribe(() => {
                  this.linkedItems.splice(this.linkedItems.findIndex(linkedItem => linkedItem.uuid === id), 1)
                  this.originalLinkedItemslist.splice(this.originalLinkedItemslist.findIndex(linkedItem => linkedItem.uuid === id), 1)
                  if (this.originalLinkedItemslist.length === 0) this.panelObj.isExpanded = false
                  this.switcherItem.itemsLength--
                  this.originalLinkedItemslist.length > 0 ? this.createLinkedItemsList(this.originalLinkedItemslist) :
                    this.linkedItems = []
                  this.isSpinner = false
                  this.switcherItem.isList = !this.switcherItem.isList
                }))
          })
        )
      }
    })
  }
  async saveLinkedItems(linkedItems: ILinkedItems[]) {
    const { reversedModuleLabel, reversedModuleName } = this.createReverseLinkedItemslabels(
      this.switcherItem.selectedObj.returnedItemType.label)
    const tempLinkedItems = []
    for (const item of linkedItems) {
      const returnedData = await this.rightSideBarSvc.addLinkedItem(item,
        URL_CONSTANTS.RIGHT_SIDE_BAR.GET(this.moduleName, this.itemId, this.panelName)).toPromise()
      if (returnedData) {
        this.originalLinkedItemslist.push(returnedData)
        const reverseLinkedItemObj: ILinkedItems = this.rightSideBarSvc.createEmptyLinkedItem(reversedModuleLabel)
        reverseLinkedItemObj.relatedAssetId = this.itemId
        reverseLinkedItemObj.relatedAssetName = this.itemName
        reverseLinkedItemObj.relatedAssetPath = ''
        const reversedReturnedData = await this.rightSideBarSvc.addLinkedItem(reverseLinkedItemObj,
          URL_CONSTANTS.RIGHT_SIDE_BAR.GET(reversedModuleName, returnedData.relatedAssetId, this.panelName)).toPromise()
        if (reversedReturnedData) {
          tempLinkedItems.push(reversedReturnedData)
          this.panelObj.isExpanded = true
          if (tempLinkedItems.length === linkedItems.length) {
            this.createLinkedItemsList(this.originalLinkedItemslist)
            this.switcherItem.isList = !this.switcherItem.isList
          }
        }
      }
    }
  }

  cancel() {
    this.switcherItem.currentView = ''
    this.switcherItem.isList = !this.switcherItem.isList
    this.isDisabled = false
  }

  createLinkedItemsList(data: ILinkedItems[]) {
    this.linkedItems = []
    this.linkedItemsIdsArr = []
    this.linkedItemsIdsArr.push(this.itemId)
    const tempLinkedItemsList = data.sort((a, b) => a.relatedAsset.localeCompare(b.relatedAsset))
    this.switcherItem.itemsLength = tempLinkedItemsList.length
    let tempStr: string
    tempLinkedItemsList.forEach((item, index) => {
      this.linkedItemsIdsArr.push(item.relatedAssetId)
      if (index === 0) {
        tempStr = item.relatedAsset
        this.linkedItems.push(this.rightSideBarSvc.createEmptyLinkedItem(tempStr))
        this.linkedItems.push(item)
      } else {
        if (item.relatedAsset !== tempStr) {
          tempStr = item.relatedAsset
          this.linkedItems.push(this.rightSideBarSvc.createEmptyLinkedItem(tempStr))
        }
        this.linkedItems.push(item)
      }
    })
  }

  ngOnInit() {
    if (this.switcherItem.isList) {
      this.linkedItems = []
      this.store.dispatch(new AdlActions.LoadDiscoverers())
      this.subscriptions.add(
        this.store.select(selector.getDiscoverers).subscribe(data => {
          this.adlIntegrations = data
        })
      )
      this.subscriptions.add(
        this.rightSideBarSvc.getLinkedItems(URL_CONSTANTS.RIGHT_SIDE_BAR.GET(this.moduleName,
          this.itemId, this.panelName)).subscribe(data => {
          this.originalLinkedItemslist = data
          if (this.originalLinkedItemslist.length > 0) this.panelObj.isExpanded = true
          this.createLinkedItemsList(data)
          this.subscriptions.add(
            this.rightSideBarSvc.getCustomTemplatesMetaDataList(URL_CONSTANTS.TEMPLATES).subscribe(templates => {
              this.templates = templates
            })
          )
        })
      )
    }
  }
  onAdd(item: IExpansionPanel) {
    item.isExpanded = true
    this.switcherItem.isList = false
    this.switcherItem.currentView = RIGHT_SIDE_BAR.ADD.toLowerCase()
    this.switcherItem.selectedObj = {
      description: '',
      priority: '',
      ispopup: false,
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

}
