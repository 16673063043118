import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

import { GirdActionService } from '../../services/grid-actions.service'

@Component({
  selector: 'app-progressbar-cell-renderer',
  templateUrl: './progressbar-cell-renderer.component.html',
  styleUrls: ['./progressbar-cell-renderer.component.scss'],
})
export class ProgressBarCellRendererComponent implements AgRendererComponent {

  params // used for optional params that are added dynamically for different types
  paramsData
  progressValue: number
  progressStatus = ''

  constructor(public gridActionsService: GirdActionService) { }

  agInit(params): void { // used for optional params that are added dynamically for different types
    if (params) {

      if (params.value === 'null' || params.value === undefined) {
        this.progressStatus = '-'
        this.progressValue = undefined
      } else {
        // tslint:disable-next-line: restrict-plus-operands
        this.progressStatus = params.value + '%'
        this.progressValue = parseInt(String(params.value).replace('%', ''))
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false
  }

  // nameHandler() {
  //   this.gridActionsService.setSelectedAction(stringConstants.DEFAULT_DATAGRID_NAVIGATE_TO_VIEW, this.params.data, this.params.rowIndex)
  // }

}
