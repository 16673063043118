import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { Subscription } from 'rxjs'

import { CoreService } from '../../services/core.service'
import { UtilService } from '../../services/utils.service'
@Component({
  selector: 'app-company-terms-service',
  templateUrl: './terms-service.component.html',
})
export class TermsServiceComponent implements OnInit, OnDestroy {
  isDisable: boolean
  subscriptions: Subscription
  isLoader: boolean
  myForm: FormGroup
  constructor(private readonly renderer: Renderer2, private readonly coreService: CoreService,
              private readonly utilService: UtilService, public dialog: MatDialog) {
    this.isDisable = false
    this.subscriptions = new Subscription()
    this.isLoader = false
    this.myForm = new FormGroup({
      checked: new FormControl(''),
    })
  }

    /*** Shows the terms of service and scrolls to top */
  ngOnInit() {
    const element = document.getElementById('termsScroller')
    this.renderer.listen(element, 'scroll', event => {
      if (element.scrollTop !==  element.scrollHeight) {
        if (element.scrollTop >= (element.scrollHeight - element.offsetHeight)) {
          this.isDisable = true
        }
      }
    })
  }
    /*** Checks if user has scrolled all the way to the bottom or not */
  changeEvent(value) {
    this.isDisable = value.checked
  }

    /*** User accepts the terms of service - set the flag on the server */
  submit() {
    this.isLoader = true
    this.subscriptions.add(this.coreService.updateTerms().subscribe(data => {
      if (!data.error_code) {
        this.isLoader = false
        this.dialog.closeAll()
        this.utilService.openUserTour()
      }
    }, error => {

    }))
  }

  /*** Unsubscribe all subscription when component destroy*/
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
