import { IColumnDefs } from '@app/interfaces/column-defs.interface'
import { STRING_CONSTANTS } from '@projects/documentation/modules/knowledgebase/constants'

import { checkboxPadding } from '../../../ag-grid-customized/src/app/constants/constants'

export const knowledgebaseHeaders: IColumnDefs[] = [
  {
    field: 'ITBRunBookName',
    headerName: 'Name',
    filter: 'agTextColumnFilter',
    suppressColumnsToolPanel: true,
    checkboxSelection: params => {
      let isDelete = true
      if (params.data && params.data.permission && params.data.permission.length > 0)
      isDelete = params.data.permission.indexOf(STRING_CONSTANTS.DELETE) > -1
      return isDelete
    },
    cellStyle: params => {
      let isDelete = true
      if (params.data && params.data.permission && params.data.permission.length > 0) {
      isDelete = params.data.permission.indexOf(STRING_CONSTANTS.DELETE) > -1
      }
      return isDelete ?
      ''
      : checkboxPadding
      },
    type: 'name',
    resizeable: true,
    minWidth: 380,
    lockPinned: true,
    lockPosition: true,
    headerType: 'custom_header',
    cellRendererParams: {
      baseURL: 'app/company/$companyId/documents/view',
      propertyName: 'id',
    },
  },
  {
    field: 'favourite',
    headerName: 'Favorite',
    filter: 'agTextColumnFilter',
    minWidth: 200,
    suppressMenu: true,
    type: 'icon',
    resizeable: true,
    lockPinned: true,
    floatingFilterComponent: 'dropdownFloatingFilter',
    floatingFilterComponentParams: {
      filterInstance: 'favourite',
      imageTrue: 'heart-filled',
      imageFalse: 'heart-line',
      imageTextTrue: 'Favorite',
      imageTextFalse: 'Not Favorite',
      suppressFilterButton: true,
    },
    cellRendererParams: {
      imageTrue: 'heart-filled',
      imageFalse: 'heart-line',
    },
  },
  {
    field: 'activeFlag',
    headerName: 'Active',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    resizeable: true,
    minWidth: 200,
    lockPinned: true,
    floatingFilterComponent: 'dropdownFloatingFilter',
    type: 'icon',
    floatingFilterComponentParams: {
      filterInstance: 'activeFlag',
      imageTrue: 'tick',
      imageFalse: 'cross-r',
      imageTextTrue: 'Active',
      imageTextFalse: 'Inactive',
      suppressFilterButton: true,
    },
    cellRendererParams: {
      imageTrue: 'tick',
      imageFalse: 'cross-r',
    },
  },
  {
    field: 'ITBRunBookStatus',
    headerName: 'Status',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    type: 'icon',
    resizeable: true,
    minWidth: 200,
    lockPinned: true,
    floatingFilterComponent: 'dropdownFloatingFilter',
    floatingFilterComponentParams: {
      filterInstance: 'ITBRunBookStatus',
      imageTrue: 'private',
      imageFalse: 'public',
      imageTextTrue: 'Private',
      imageTextFalse: 'Public',
      suppressFilterButton: true,
    },
    cellRendererParams: {
      imageTrue: 'private',
      imageFalse: 'public',
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    filter: 'agDateColumnFilter',
    filterParams: {
      browserDatePicker: true,
    },
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    type: 'date',
    resizeable: true,
    lockPinned: true,
    minWidth: 200,
  },
  {
    field: 'ITBLastUpdated',
    headerName: 'Last Updated At',
    filter: 'agDateColumnFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    type: 'date',
    resizeable: true,
    lockPinned: true,
    minWidth: 200,
  },
  {
    field: 'ITBUpdatedBy',
    headerName: 'Last Updated By',
    filter: 'agTextColumnFilter',
    type: '',
    resizeable: true,
    lockPinned: true,
    minWidth: 260,
  },
  {
    field: 'tags',
    headerName: 'Search Tags',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    sortable : false,
    type: 'tags',
    resizeable: true,
    autoHeight: true,
    lockPinned: true,
    minWidth: 320,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    suppressColumnsToolPanel: true,
    sortable : false,
    type: 'actions',

    resizeable: false,
    minWidth: 200,
    pinned: 'right',
    lockPinned: true,
    lockPosition: true,
    cellRendererParams: {
      edit: true,
      delete: true,
      copyCompanies: true,
      doc: true,
      pdf: true,
      lock: true,
    },
  },
]
export const knowledgebaseRestoreHeaders: IColumnDefs[] = [
  {
    field: 'ITBRunBookName',
    headerName: 'Name',
    checkboxSelection: params => {
      let isDelete = true
      if (params.data && params.data.permission && params.data.permission.length > 0)
      isDelete = params.data.permission.indexOf(STRING_CONSTANTS.DELETE) > -1
      return isDelete
    },
    cellStyle: params => {
      let isDelete = true
      if (params.data && params.data.permission && params.data.permission.length > 0) {
      isDelete = params.data.permission.indexOf(STRING_CONSTANTS.DELETE) > -1
      }
      return isDelete ?
      ''
      : checkboxPadding
      },
    filter: 'agTextColumnFilter',
    resizeable: true,
    minWidth: 380,
    lockPinned: true,
    lockPosition: true,
    suppressColumnsToolPanel: true,
    headerType: 'custom_header',
  },
  {
    field: 'favourite',
    headerName: 'Favorite',
    filter: 'agTextColumnFilter',
    minWidth: 200,
    sortable : false,
    type: 'icon',
    resizeable: true,
    floatingFilterComponent: 'dropdownFloatingFilter',
    floatingFilterComponentParams: {
      filterInstance: 'favourite',
      imageTrue: 'heart-filled',
      imageFalse: 'heart-line',
      imageTextTrue: 'Favorite',
      imageTextFalse: 'Not Favorite',
      suppressFilterButton: true,
    },
    cellRendererParams: {
      imageTrue: 'heart-filled',
      imageFalse: 'heart-line',
    },
  },
  {
    field: 'activeFlag',
    headerName: 'Active',
    filter: 'agTextColumnFilter',
    resizeable: true,
    sortable : false,
    minWidth: 200,
    floatingFilterComponent: 'dropdownFloatingFilter',
    type: 'icon',
    floatingFilterComponentParams: {
      filterInstance: 'activeFlag',
      imageTrue: 'tick',
      imageFalse: 'cross-r',
      imageTextTrue: 'Active',
      imageTextFalse: 'Inactive',
      suppressFilterButton: true,
    },
    cellRendererParams: {
      imageTrue: 'tick',
      imageFalse: 'cross-r',
    },
  },
  {
    field: 'ITBRunBookStatus',
    headerName: 'Status',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    type: 'icon',
    resizeable: true,
    minWidth: 200,
    floatingFilterComponent: 'dropdownFloatingFilter',
    floatingFilterComponentParams: {
      filterInstance: 'ITBRunBookStatus',
      imageTrue: 'private',
      imageFalse: 'public',
      imageTextTrue: 'Private',
      imageTextFalse: 'Public',
      suppressFilterButton: true,
    },
    cellRendererParams: {
      imageTrue: 'private',
      imageFalse: 'public',
    },
  },
  {
    field: 'deletedAt',
    headerName: 'Deleted At',
    filter: 'agDateColumnFilter',
    filterParams: {
      browserDatePicker: true,
    },
    floatingFilterComponentParams: {
        suppressFilterButton: true,
        },
    type: 'date',
    resizeable: true,
    minWidth: 200,
  },
  {
    field: 'deletedBy',
    headerName: 'Last Deleted By',
    filter: 'agTextColumnFilter',
    type: '',
    resizeable: true,
    minWidth: 260,
  },
  {
    field: 'ITBLastUpdated',
    headerName: 'Last Updated At',
    filter: 'agDateColumnFilter',
    type: 'date',
    floatingFilterComponentParams: {
        suppressFilterButton: true,
        },
    resizeable: true,
    minWidth: 200,
  },

  {
    field: 'ITBUpdatedBy',
    headerName: 'Last Updated By',
    filter: 'agTextColumnFilter',
    type: '',
    resizeable: true,
    minWidth: 260,
  },

  {
    field: 'tags',
    headerName: 'Search Tags',
    filter: 'agTextColumnFilter',
    sortable : false,
    suppressMenu: true,
    type: 'tags',
    resizeable: true,
    minWidth: 380,
  },
]
