import { DatePipe } from '@angular/common'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { IColumnState, IHeaders } from '@app/interfaces/ui-components.interface'
import { stringConstants } from '@projects/ag-grid-customized/src/app/constants/constants'
import { GirdActionService } from '@projects/ag-grid-customized/src/app/data-grid'
import { Subscription } from 'rxjs'

import { CompanyDashboardService } from '../../../company-dashboard/services/companyDashboard/company-dashboard.service'

import { dashboardContants } from './../../../company-dashboard/constants/constants'
import { Source } from './../../../company-dashboard/interfaces/company-dashboard'
import { WidgetType } from './../../../company-dashboard/interfaces/menu'
import { RoundButton } from './../../../company-dashboard/interfaces/ui-library'
import { QuickNotesService } from './../../../company-dashboard/services/companyQuickNotes/quick-notes.service'

@Component({
  selector: 'app-company-pods-fav-documents',
  templateUrl: './pods-fav-documents.component.html',
  styleUrls: ['./pods-fav-documents.component.scss'],
  providers: [GirdActionService],
})
export class PodsFavDocumentsComponent implements OnInit {
  @Input() companyId: string
  @Input() widgetData: WidgetType
  @Output() contentReady = new EventEmitter()
  wrapperClass: string
  isLoading: boolean
  roundButton: RoundButton[]
  subscriptions: Subscription
  displayedColumns: string[]
  dataSource2: Source[]
  isEmpty: boolean
  allDocs
  isError: boolean
  columnDefs: IHeaders[]
  defaultColDef: {}
  userColumnState: IColumnState[]
  rowData
  isColLoaded: boolean
  isInitialRender: boolean
  isDetail: boolean
  isFirst: boolean
  constructor(
    public documentsService: QuickNotesService,
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly gridActionsService: GirdActionService,
    private readonly companyService: CompanyDashboardService,
    public route: Router) {
    this.subscriptions = new Subscription()
    this.isEmpty = false
    this.isDetail = true
    this.isError = false
    this.roundButton = []
    this.isLoading = true
    this.dataSource2 = []
    this.wrapperClass = ''
  }

  ngOnInit() {
    this.getDocuments()
  }
  getDocuments() {
    this.subscriptions.add(
      this.documentsService.getCompanyDocuments(this.companyId).subscribe(res => {
        this.isLoading = false
        this.contentReady.emit()
        const temp: Source[] = []
        if (res.length === 0) {
          this.isEmpty = true
          return
        }
        this.roundButton = [
          {
            tooltipText: 'Favorite Documents List',
            className: dashboardContants.EDIT_BUTTON_CLASS, iconClassName: dashboardContants.EDIT_ICON_CLASSNAME,
            roundButtonName: 'list',
          },
        ]
        this.allDocs = res
        this.displayedColumns = ['name', 'actions']
        res.forEach(ele => {
          temp.push(
            {
              name: ele.ITBRunBookName,
              actions: [
                { tooltipText: 'View Document', className: 'icon-redirect', name: 'view' },
              ],
            }
          )

        })
        this.dataSource2 = temp
      }, error => {
        this.isEmpty = false
        this.isError = true
        this.isLoading = false
        this.contentReady.emit()
      })
    )
  }
  viewDocument(id) {
  }

  documentListings(event) {
    if (event === 'list') {
      this.wrapperClass = 'slideUp'
      this.isDetail = false
    } else {
      this.wrapperClass = ''
      this.isDetail = true
      this.roundButton = [
        {
          tooltipText: 'Favorite Document List',
          className: dashboardContants.EDIT_BUTTON_CLASS, iconClassName: dashboardContants.EDIT_ICON_CLASSNAME,
          roundButtonName: 'list',
        },
      ]
      return
    }
    this.roundButton = [
      {
        tooltipText: 'Close List',
        className: dashboardContants.EDIT_BUTTON_CLASS,
        iconClassName: 'cross',
        roundButtonName: 'close',
      },
    ]
    this.isDetail = false
    this.isColLoaded = false
    this.rowData = []
    this.isError = false
    this.defaultColDef = {
      filter: dashboardContants.AG_GRID_FILTER,
    }
    this.columnDefs = [
      {
        headerName: 'NAME', field: 'ITBRunBookName', suppressSorting: true,
        cellRenderer: name =>
          `<a  target="_blank" href="/app/company/${this.companyId}/documents/knowledgebase/view/${name.data.id}">${name.value}</a>`,
      },
      { headerName: 'STATUS', field: 'ITBRunBookStatus', suppressSorting: true },
      { headerName: 'UPDATED BY', field: 'ITBUpdatedBy', suppressSorting: true },
      { headerName: 'CREATED AT', field: 'createdAt', suppressSorting: true },
      { headerName: 'LAST UPDATE', field: 'lastUpdate', suppressSorting: true },
      { headerName: 'SEARCH TAGS', field: 'tags', type: 'tags', suppressSorting: true },
    ]
    this.isLoading = true
    this.subscriptions.add(
      this.companyService.getCompanyDashboardColumnState('favDoc').subscribe(
        response => {
          this.isLoading = false
          if (response.length) {
            this.isEmpty = false
            this.userColumnState = response
          }
          this.isColLoaded = true
          this.isEmpty = false
        },
        error => { },
        () => { }
      )
    )
    this.subscriptions.add(
      this.gridActionsService.action.subscribe(action => this.actionClicked(action))
    )
  }
  actionClicked = action => {
    switch (action.actionType) {
      case stringConstants.DEFAULT_DATAGRID_NAVIGATE_TO_VIEW:
        window.open(`app/documentation/knowledgebase/view/${action.data.id}`, '_blank')
        break
      default:
        return
    }
  }
  columnStateChanged(state: IColumnState[]) {
    state.forEach((col, i) => {
      col[`${'position'}`] = i
    })
    this.companyService.saveCompanyDashboardColumnState(state, 'favDoc').subscribe(
      response => { },
      error => { },
      () => { }
    )
  }
  getRows(params: Params) {
    let dataCount: number
    let pageNo: number
    let pageSize: number
    const { startRow } = params.request
    pageNo = this.setOffset(startRow)
    this.subscriptions.add(
      this.documentsService.getAllCompanyDocuments(this.companyId, params.request, pageNo).subscribe(
        res => {
          res.forEach((ele, index) => {
            res[index].createdAt = new DatePipe('en-US').transform(ele.createdAt, 'dd/MM/yyyy')
            res[index].ITBLastUpdated = new DatePipe('en-US').transform(ele.ITBLastUpdated, 'dd/MM/yyyy')
          })
          this.rowData = res
          this.isEmpty = (res.length === 0 || res === undefined) && this.isFirst ? true : false
          this.isColLoaded = (res.length === 0 || res === undefined) && this.isFirst ? false : true
          this.isFirst = false
          dataCount = this.rowData.length
          pageSize = pageNo * params.request.endRow
          const lastRow = res.length <= pageSize ? res.length : -1
          params.successCallback(this.rowData, lastRow)
        },
        error => {
          params.failCallback()
          this.isError = true
          this.isEmpty = false
          this.isColLoaded = false
          this.isFirst = false
        }
      )
    )
  }
  setOffset(offset: number): number {
    return (offset = 0 ? 1 : offset / 100 + 1)
  }
  navigate(event) {
    this.allDocs.forEach(ele => {
      if (ele.ITBRunBookName === event.user.name) {
        window.open(`app/company/${this.companyId}/documents/knowledgebase/view/${ele.id}`, '_blank')
      }
    })
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
