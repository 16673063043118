import { Injectable } from '@angular/core'
import { dark, green, ITheme, light } from '@app/modules/core/interfaces/theme'
import { BehaviorSubject } from 'rxjs'
@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  private active: ITheme
  private readonly availableThemes: ITheme[]
  private readonly theme: BehaviorSubject<ITheme>
  constructor() {
    this.active = light
    this.availableThemes = [light, dark, green]
    this.theme = new BehaviorSubject<ITheme>(light)

  }
  /*** Gets active theme */
  getActiveTheme(): ITheme {
    return this.active
  }
  /*** Gets green theme */
  setGreenTheme() {
    this.setActiveTheme(green)
    this.theme.next(green)
  }
  /*** Gets dark theme */
  setDarkTheme() {
    this.setActiveTheme(dark)
    this.theme.next(dark)
  }
  /*** Gets light theme */
  setLightTheme() {
    this.setActiveTheme(light)
    this.theme.next(light)
  }
  /*** Set active theme */
  setActiveTheme(theme: ITheme) {
    this.active = theme

    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      )
    })
  }
}
