<div  id="search-results" class="overlay search_body pl-0">
  <!-- BEGIN Overlay Content !-->
  <div class="overlay-content has-results">
    <!-- BEGIN Overlay Header !-->
    <header class="search_header">

      <form
        [formGroup]="globalSearchForm"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <div
          fxFlex="40"
          fxFlex.sm="70"
          fxFlex.xs="70"
          fxLayout="column"
          fxLayoutAlign="space-evenly stretch"
        >
          <itb-input
            pattern="^(?! |.*\s $)[A-Za-z0-9\s\-\_]+$"
            [parentForm]="globalSearchForm"
            [formFieldName]="'searchField'"
            [icons]="[{toolTipText: 'Search', className: 'search'}]"
            [id]="'itbSearch'"
            [label]="'SEARCH'"
            [type]="'text'"
            [isAutofocus]="true"
            (input)="search()"
            class="search-input"
          >
          </itb-input>
          <itb-info-message
            *ngIf="!globalSearchForm.controls.searchField.valid"
            [text]="'Input is invalid'"
            [className]="'info-danger for-input'"
          >
          </itb-info-message>
        </div>
        <itb-dropdown
        [buttonType]="'iconBtn'"
        [className]="selectedCompanies && selectedCompanies.length!==0 ? 'filter-fill': 'filter'" *ngIf="!isSelectedCompaniesDialog"
      >
      <div class="detail-search-filter">
        <itb-checkbox [label]="' Highlight search text'"  [parentForm]="globalSearchForm"
                      [formFieldName]="'highlightText'" (onChangeHandler) = "changeEvent($event)" ></itb-checkbox>
        <itb-checkbox [label]="' Search KB Articles'" [parentForm]="globalSearchForm" [formFieldName]="'kbSearchContent'"
                      (onChangeHandler)="toggleFlag($event)"></itb-checkbox>
        <button mat-menu-item  (click)="filterCompanies()"><span>Select Companies</span></button>
      </div>

      </itb-dropdown>
      </form>
      <itb-round-button [className]="'light round-modal-cross caret-close-modal'" [iconClassName]="'cross'"
                        [tooltipText]="'Close'" (click)="close()"> </itb-round-button>
    </header>
    <div class="searchscroller mb-3 search-cont">
      <div class="search-results" fxFlex="100" fxLayout='row' fxLayout.sm='column' fxLayout.xs='column' fxLayout.md='column'>
        <div  fxFlex="100"  class="slideLeft quick_search_colum" *ngIf="globalSearchForm.value.kbSearchContent">
          <itb-search-widget
                [headingClassName]="'heading search-h'"
                [headingText]="'KnowledgeBase'"
                [textClassName]="'text-m-small text-color-primary'"
                [text]="'ITEMS'"
                [searchedItems]="documents.length"
                [hasLoader]="canShowLoadingKbContent"
                [hasSearchIcon]="false"
              >
              <ul class="s-full-h Knowledge-base-search main">
            <li *ngFor="let item of removeDublicateObjects(documents,'uuid')" class="mb-2" fxFlex="32" fxFlex.md="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout='column'>
              <a (click)='getData(item, "documents")'>
                  <itb-text *ngIf='item.searchName == ""' [className]="'text-default text-color-primary'" [text]="'blank'"></itb-text>
                  <itb-text *ngIf='item.searchName != ""' [className]="'text-default text-color-primary'" [isInnerHtml]="'true'"
                            [text]="item.searchName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></itb-text>
                <div class="item-container">
                      <span class="itemCompanySecondColumn">
                        <img class="pr-1" src="images-library/icon-library/icon_ct13.svg" width="16" alt=""/>{{item.entryType}}&nbsp; - &nbsp;<img class="pr-1" src="images-library/icon-library/icon_company13.svg" width="16" alt=""/>
                        <span [innerText]="item.companyName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></span>
                      </span>
                      <div class="serchTagsContainer mt-2 mb-1" fxLayout="row" fxLayoutAlign=" center" >
                          <span  class="searchTagsSearch" *ngFor="let tag of item.tags"
                                 [innerText]="tag | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></span>
                      </div>
                </div>
              </a>
            </li>
          </ul>
          </itb-search-widget>
        </div>
      <div fxFlex="100" fxLayout="row" fxLayout.sm='column' fxLayout.xs='column' fxLayout.md='column' *ngIf="!globalSearchForm.value.kbSearchContent">
        <div fxFlex="29" fxFlex.md="130" fxFlex.sm="130" fxFlex.xs="130" fxLayout='column'  class="mr-lg-3" >
          <div id="quickSearch"  fxFlex="100" class="quick_search_colum">
              <itb-search-widget
                [headingClassName]="'heading search-h'"
                [headingText]="'Companies'"
                [textClassName]="'text-m-small text-color-primary'"
                [text]="'ITEMS'"
                [searchedItems]="companies.length"
                [hasLoader]="canShowLoading"
                [hasSearchIcon]="false">
                <ul>
                  <li *ngFor="let item of removeDublicateObjects(companies,'uuid') ; index as i"  class="companyLiHeight">
                    <a (click)='getData(item, "companies")' fxLayout="column" >
                        <span class="text-default text-color-primary" *ngIf='item.searchName != ""'
                              [innerText]="item.searchName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"> </span>
                        <span class="text-default text-color-primary" *ngIf='item.searchName == ""'>blank </span>
                        <div class="item-container" fxLayout="column" >
                          <span class="itemCompanySecondColumn" [innerText]="item.identifier  | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"> identifier</span>
                          <div class="serchTagsContainer mt-2 mb-1" fxLayout="row" fxLayoutAlign=" center" >
                              <span  class="searchTagsSearch" *ngFor="let tag of item.tags"
                                     [innerText]="tag | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></span>
                          </div>
                        </div>
                    </a>
                  </li>
                </ul>
              </itb-search-widget>
          </div>
          <div fxFlex="100" class="quick_search_colum">
            <itb-search-widget
                [headingClassName]="'heading search-h'"
                [headingText]="'Contacts'"
                [textClassName]="'text-m-small text-color-primary'"
                [text]="'ITEMS'"
                [searchedItems]="contacts.length"
                [hasLoader]="canShowLoading"
                [hasSearchIcon]="false">
              <ul>
                <li *ngFor="let item of removeDublicateObjects(contacts,'uuid') ; index as i">
                  <a (click)='getData(item, "contacts")'>
                    <itb-text *ngIf='item.searchName == ""' [className]="'text-default text-color-primary'" [text]="'blank'"></itb-text>
                    <itb-text *ngIf='item.searchName != ""' [className]="'text-default text-color-primary'" [isInnerHtml]="'true'"
                              [text]="item.searchName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></itb-text>
                    <div class="item-container" fxLayout="column" >
                        <span class="itemCompanySecondColumn" [innerText]="item.companyName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"><img class="pr-1" src="images-library/icon-library/icon_company13.svg" width="13" alt=""/> </span>
                          <div class="serchTagsContainer mt-2 mb-1" fxLayout="row" fxLayoutAlign=" center" >
                            <span  class="searchTagsSearch" *ngFor="let tag of item.tags"
                                   [innerText]="tag | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></span>
                          </div>
                    </div>
                </a>
              </li>
              </ul>
            </itb-search-widget>
          </div>
          <div fxFlex="100" class="quick_search_colum">
            <itb-search-widget
                [headingClassName]="'heading search-h'"
                [headingText]="'Locations'"
                [textClassName]="'text-m-small text-color-primary'"
                [text]="'ITEMS'"
                [searchedItems]="locations.length"
                [hasLoader]="canShowLoading"
                [hasSearchIcon]="false" >
              <ul>
              <li *ngFor="let item of removeDublicateObjects(locations,'uuid') ; index as i">
                <a (click)='getData(item, "locations")'>
                    <itb-text *ngIf='item.searchName == ""' [className]="'text-default text-color-primary'" [text]="'blank'"></itb-text>
                    <itb-text *ngIf='item.searchName != ""' [className]="'text-default text-color-primary'"  [isInnerHtml]="'true'"
                              [text]="item.searchName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></itb-text>
                    <div class="item-container" fxLayout="column" >
                        <span class="itemCompanySecondColumn" [innerText]="item.companyName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"><img class="pr-1" src="images-library/icon-library/icon_company13.svg" width="13" alt=""/> </span>
                          <div class="serchTagsContainer mt-2 mb-1" fxLayout="row" fxLayoutAlign=" center" >
                              <span  class="searchTagsSearch" *ngFor="let tag of item.tags"
                                     [innerText]="tag | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></span>
                          </div>
                    </div>
                </a>
              </li>
              </ul>
            </itb-search-widget>

          </div>
          <div fxFlex="100" class="quick_search_colum">
              <itb-search-widget
              [headingClassName]="'heading search-h'"
              [headingText]="'SSL & Domain Tracker'"
              [textClassName]="'text-m-small text-color-primary'"
              [text]="'ITEMS'"
              [searchedItems]="ssls.length"
              [hasLoader]="canShowLoading"
              [hasSearchIcon]="false" >
            <ul>
                <li *ngFor="let item of removeDublicateObjects(ssls,'uuid') ; index as i">
                  <a (click)='getData(item, "ssl")'>
                      <itb-text *ngIf='item.searchName == ""' [className]="'text-default text-color-primary'" [text]="'blank'"></itb-text>
                      <itb-text *ngIf='item.searchName != ""' [className]="'text-default text-color-primary'" [isInnerHtml]="'true'"
                                [text]="item.searchName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></itb-text>
                      <div class="item-container" fxLayout="column" >
                          <span class="itemCompanySecondColumn" [innerText]="item.companyName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"><img class="pr-1" src="images-library/icon-library/icon_company13.svg" width="13" alt=""/> </span>
                            <div class="serchTagsContainer mt-2 mb-1" fxLayout="row" fxLayoutAlign=" center" >
                               <span  class="searchTagsSearch" *ngFor="let tag of item.tags"
                                      [innerText]="tag | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></span>
                            </div>
                      </div>
                  </a>
                </li>
                </ul>
              </itb-search-widget>

          </div>
          <div class="clearfix"></div>
        </div>
        <div fxFlex="40" fxFlex.md="40" fxFlex.sm="100" fxFlex.xs="100" class="mr-lg-3 my-lg-0 mt-sm-3" fxLayout='column'>
          <div class="quick_search_colum ">
              <itb-search-widget
              [headingClassName]="'heading search-h'"
              [headingText]="'Assets'"
              [textClassName]="'text-m-small text-color-primary'"
              [text]="'ITEMS'"
              [searchedItems]="assetts.length"
              [hasLoader]="canShowLoading"
              [hasSearchIcon]="fasle"
            >
            <ul [ngClass.xl]="'s-full-h'" [ngClass.lg]="'s-full-h'">
                <li *ngFor="let item of removeDublicateObjects(assetts,'uuid')" class="companyLiHeight">
                  <a (click)='getData(item, "assetts")'>
                      <itb-text *ngIf='!item.searchName || item.searchName == ""' [className]="'text-default text-color-primary'" [text]="'blank'"></itb-text>
                      <itb-text *ngIf='item.searchName != ""' [className]="'text-default text-color-primary'" [isInnerHtml]="'true'"
                                [text]="item.searchName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></itb-text>
                      <div class="item-container" fxLayout="column" >
                          <span class="itemCompanySecondColumn">
                        <img class="pr-1" src="images-library/icon-library/icon_ct13.svg" width="13" alt=""/>{{item.entryType}}
                            <span *ngIf="item.entryType === 'configurations' && item.configType">({{item.configType.name}})</span>
                            &nbsp; - &nbsp;<img class="pr-1" src="images-library/icon-library/icon_company13.svg" width="13" alt=""/>
                        <span [innerText]="item.companyName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></span>
                      </span>
                            <div class="serchTagsContainer mt-2 mb-1" fxLayout="row" fxLayoutAlign=" center" >
                              <span  class="searchTagsSearch" *ngFor="let tag of item.tags"
                                     [innerText]="tag | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></span>
                            </div>
                      </div>
                  </a>
                </li>
                </ul>
              </itb-search-widget>

          </div>
        </div>
        <div fxFlex="29" fxFlex.md="29" fxFlex.sm="100" fxFlex.xs="100" fxLayout='column'>
          <div fxFlex="100" class="quick_search_colum"  >
            <itb-search-widget
              [headingClassName]="'heading search-h'"
              [headingText]="'Passwords'"
              [textClassName]="'text-m-small text-color-primary'"
              [text]="'ITEMS'"
              [searchedItems]="passwords.length"
              [hasLoader]="canShowLoading"
              [hasSearchIcon]="fasle"  >
              <ul class="ul-pn-h">
                <li *ngFor="let item of removeDublicateObjects(passwords,'uuid') ; index as i">
                <a (click)='getData(item, "passwords")'>
                    <itb-text *ngIf='item.searchName == ""' [className]="'text-default text-color-primary'" [text]="'blank'"></itb-text>
                    <itb-text *ngIf='item.searchName != ""' [className]="'text-default text-color-primary'" [isInnerHtml]="'true'"
                              [text]="item.searchName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></itb-text>
                    <div class="item-container" fxLayout="column" >
                        <span class="itemCompanySecondColumn" [innerText]="item.companyName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"><img class="pr-1" src="images-library/icon-library/icon_company13.svg" width="13" alt=""/> </span>
                          <div class="serchTagsContainer mt-2 mb-1" fxLayout="row" fxLayoutAlign=" center" >
                              <span  class="searchTagsSearch" *ngFor="let tag of item.tags"
                                     [innerText]="tag | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></span>
                          </div>
                    </div>
                </a>
              </li>
            </ul>
            </itb-search-widget>

          </div>
          <div fxFlex="100" class="quick_search_colum" style="">
            <itb-search-widget
              [headingClassName]="'heading search-h'"
              [headingText]="'KnowledgeBase & Runbooks'"
              [textClassName]="'text-m-small text-color-primary'"
              [text]="'ITEMS'"
              [searchedItems]="documents.length"
              [hasLoader]="canShowLoading"
              [hasSearchIcon]="false"
            >
            <ul class="ul-pn-h Knowledge-base-search">
              <li  *ngFor="let item of documents" class="companyLiHeight">
                <a (click)='getData(item, "documents")'>

                      <itb-text *ngIf='item.searchName == ""' [className]="'text-default text-color-primary'" [text]="'blank'"></itb-text>
                      <itb-text *ngIf='item.searchName != ""' [className]="'text-default text-color-primary'" [isInnerHtml]="'true'"
                                [text]="item.searchName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></itb-text>

                  <div class="item-container">
                      <span class="itemCompanySecondColumn">
                        <img class="pr-1" src="images-library/icon-library/icon_ct13.svg" width="13" alt=""/>{{item.entryType}}&nbsp; - &nbsp;<img class="pr-1" src="images-library/icon-library/icon_company13.svg" width="13" alt=""/>
                        <span [innerText]="item.companyName | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></span>
                      </span>
                      <div class="serchTagsContainer mt-2 mb-1" fxLayout="row" fxLayoutAlign=" center" >
                         <span  class="searchTagsSearch" *ngFor="let tag of item.tags"
                                [innerText]="tag | highlight: globalSearchForm.value.searchField:globalSearchForm.value.highlightText"></span>
                      </div>
                  </div>
                </a>
              </li>
              </ul>
            </itb-search-widget>

          </div>
        </div>
      </div>
      </div>
    </div>
    <!-- END Overlay Search Results !-->
  </div>

  <!-- END Overlay Content !-->
</div>
