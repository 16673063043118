import { transpileModule } from 'typescript'

export const URL = {
  FETCH_COMPANIES_TABLE_HEADERS: 'companies/headers',
  FETCH_COMPANIES_TABLE_DATA: 'companies',
  FETCH_RESTORE_COMPANIES_TABLE_DATA: 'companies/deleted',

  FETCH_SNAPSHOT_TABLE_DATA: 'users/counts/false/?detailFlag=true',
  REFRESH_SNAPSHOT_TABLE_DATA: 'users/counts/true',
  CW_CREDS: 'users/cwauth',
  AT_CREDS: 'users/autotask/url',
  COMPANY: 'companies',
  BULK_DELETE_COMPANIES: 'companies',
  RESTORE_COMPANIES: 'companies/restore-companies',
  FETCH_DELETED_COMPANIES_TABLE_HEADERS: 'companies/deletedCompanies/headers',
  ALLCOMPANIES: 'allcompanies',
  GLOBAL_COMPANIES_COUNT: 'companies/count',
  RESTORE_COMPANIES_COUNT: 'companies/deleted/count',
  USER_HEADERS: 'dashboard/user/headers',
  USER_HEADER_COUNTS: 'users/counts',
  USER_COUNTS: 'users/counts/false',
}

export const STRING_CONSTANTS = {
  SERVER_SIDE: 'serverSide',
  CLIENT_SIDE: 'clientSide',
  COMPANY_MODULE: 'Company Module',
}
export const COMPANY_TYPE = 'company'
export const RESTORE_COMPANY_TYPE = 'restoreCompany'
export const COMPANY_BUTTONS = {
  DELETE: {
    text: 'Delete',
    className: 'danger',
    isDisabled: false,
    iconClassName: 'delete',
    buttonName: 'delete',
    isLoading: false,
  },
  CANCEL: {
    text: 'Cancel',
    className: 'default',
    isDisabled: false,
    iconClassName: 'cross',
    buttonName: 'dancel',
    isLoading: false,
  },
}
export const FILTER_TYPES = {
  TYPE: 'type',
  ALL: 'All',
  LETTER: 'letter',
  TEXT: 'text',
}
export const COMPANIES_STATES = {
  DASHBOARD: '/app/company/',
  COMPANY_ON_BOARD: '/app/creation/company',
}
export const COMPANY_NOTIFICATION_CONST = {
  SUCCESS: 'success',
  DELETED: 'Deleted',
  BULK_DELETED: 'Company Item(s) deleted',
}
export const COMPANY_CONFIRMATION_MODAL = {
  DELETE_COMPANY: {
    title: 'Delete Company',
    body: 'Are you sure you want to delete this Company?',
    buttonsArray: [COMPANY_BUTTONS.DELETE, COMPANY_BUTTONS.CANCEL],
  },
  BULK_DELETE: {
    title: 'Delete Companies',
    body: 'Are you sure you want to delete selected companies?',
    buttonsArray: [COMPANY_BUTTONS.DELETE, COMPANY_BUTTONS.CANCEL],
  },
}
export const COMPANY_ACTIONS = {
  DELETE: 'delete',
  EDIT: 'edit',
  ADD: 'add',
  VIEW: 'view',
}
export const COMPANY_LINKS = {
  COMPANY_LIST: [
    { name: 'Companies', route: '../list' },
    { name: 'List', route: '' }],
  ALL_COMPANIES: [
    { name: 'Companies', route: '../list' },
    { name: 'All Companies', route: '' }],
  DOCSNAPSHOT: [
    { name: 'Companies', route: '../list' },
    { name: 'Documentation Snapshot', route: 'snapshot' }],
}
export const COMPANY_TYPE_COLORS =
  ['color-bar-1', 'color-bar-2', 'color-bar-3', 'color-bar-4', 'color-bar-5', '#0866c6', '#6dcff6', '#ffd800',
   '#8560a8', '#f26522', '#acacac', '#000000', '#0072bc', '#62c4b6',
   '#6dcff6', '#ffd800', '#8560a8', '#f26522', '#acacac', '#000000', '#0072bc', '#62c4b6', '#6dcff6', '#ffd800', '#8560a8', '#f26522',
   '#acacac', '#000000', '#0072bc', '#62c4b6', '#6dcff6', '#ffd800', '#8560a8', '#f26522', '#acacac', '#000000', '#0072bc', '#62c4b6',
   '#6dcff6', '#ffd800', '#8560a8', '#f26522', '#acacac', '#000000', '#0072bc']
export const COMPANY_NOTIFICATIONS = {
  COMPANY_DELETE_FAIL: {
    mode: 'fail',
    title: '',
    body: '',
    mainMessage: 'ERROR!',
  },
  COMPANY_DELETE_SUCCESS: {
    mode: 'success',
    title: '',
    body: ' Company deleted.',
    mainMessage: 'DONE!',
  },
  COMPANY_BULK_DELETE_FAIL: {
    mode: 'fail',
    title: '',
    body: 'Fail to delete Companies',
    mainMessage: 'ERROR!',
  },
  COMPANY_BULK_DELETE_SUCCESS: {
    mode: 'success',
    title: '',
    body: 'Company Item(s) deleted',
    mainMessage: 'DONE!',
  },
  COMPANY_RESTORE_FAIL: {
    mode: 'fail',
    title: '',
    body: 'Fail to restore Companies',
    mainMessage: 'ERROR!',
  },
  COMPANY_RESTORE_SUCCESS: {
    mode: 'success',
    title: '',
    body: 'Companies restored Successfully',
    mainMessage: 'DONE!',
  },
}
export const COMPANY_HEADERS = {
  COMPANY_LIST: [
    {
      field: 'name',
      headerName: 'Name',
      checkboxSelection: true,
      filter: 'agTextColumnFilter',
      type: 'name',
      cellRendererParams: {
        baseURL : COMPANIES_STATES.DASHBOARD,
        propertyName : 'uuid',
      },
      resizeable: true,
      minWidth: 320,
      suppressColumnsToolPanel: true,
      headerType: 'custom_header',

    },
    {
      field: 'identifier',
      headerName: 'Company Identifier',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'website',
      headerName: 'Website',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'status.name',
      headerName: 'Status',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'types',
      headerName: 'Type',
      filter: 'agTextColumnFilter',
      cellRenderer: getTypes => {
        if (getTypes.value && getTypes.value.length > 0) {
          return getTypes.value.map(type => type.name).join()
        }
      },
      minWidth: 200,
    },
    {
      field: 'city',
      headerName: 'City',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'state',
      headerName: 'State',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'addressLine1',
      headerName: 'address1',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'addressLine2',
      headerName: 'address2',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },

    {
      field: 'country.name',
      headerName: 'country',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'searchTags',
      headerName: 'Search tags',
      filter: 'agTextColumnFilter',
      type: 'tags',
      minWidth: 320,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 140,
      checkboxSelection: false,
      resizeable: false,
      pinned: 'right',
      lockPinned: true,
      lockPosition: true,
      suppressColumnsToolPanel: true,
      filter: false,
      sortable: false,
      type: 'actions',
      cellRendererParams: {
        cwIndex: false,
        atIndex: false,
        delete: true,
        lock: false,
        unlock: false,
      },
    },
  ],
  RESTORE_COMPANIES: [
    {
      field: 'name',
      headerName: 'Name',
      checkboxSelection: true,
      filter: 'agTextColumnFilter',
      type: 'text',
      minWidth: 320,
      headerType: 'custom_header',
    },
    {
      field: 'identifier',
      headerName: 'id',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'website',
      headerName: 'website',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'status.name',
      headerName: 'status',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'types',
      headerName: 'type',
      filter: 'agTextColumnFilter',
      minWidth: 200,
      type: '',
      cellRenderer: getTypes => {
        if (getTypes.value && getTypes.value.length > 0) {
          return getTypes.value[0].name
        }
      },
    },
    {
      field: 'city',
      headerName: 'city',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'state',
      headerName: 'state',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'addressLine1',
      headerName: 'address1',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'addressLine2',
      headerName: 'address2',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
    {
      field: 'country.name',
      headerName: 'country',
      filter: 'agTextColumnFilter',
      type: '',
      minWidth: 200,
    },
  ],
}

export const CW_CONSTANTS = {
  CW_PATH: '/v4_6_release/services/system_io/router/openrecord.rails?locale=en_US&recordType=CompanyFv&recid=',
  AT_PATH:  '/Autotask35/crm/account/accountdetail.aspx?accountId=',
}
export const COMPANIES_ROUTES = {
  COMPANIES_LIST: pageNumber => (`${URL.COMPANY}?pageNo=${pageNumber}&&pageSize=100&&fields={"name":1,"types":1,"type":1,"uuid":1}`),
  COMPANIES_LIST_LETTER_FILTER: (name, pageNumber) => (`${URL.COMPANY}?conditions={"name":{"$regex":
    "^${name}","$options":"i"}}&&pageNo=${pageNumber}&&pageSize=100&&fields={"name":1,"types":1,"type":1,"uuid":1}`),
  COMPANIES_LIST_INPUT_FILTER: (name, pageNumber) => (`${URL.COMPANY}?conditions={"name":{"$regex" :"${name}",
    "$options":"i"}}&&pageNo=${pageNumber}&&pageSize=100&&fields={"name":1,"types":1,"type":1,"uuid":1}`),
  COMPANIES_LIST_TYPE_FILTER: (name, pageNumber) => (`${URL.COMPANY}?conditions={"types.name":{"$regex":"${name}","$options":"i"}}
  &&pageNo=0&&pageSize=100&&fields={"name":1,"types":1,"type":1,"uuid":1}`),
  COMPANIES_TYPE: () => ('organizations/metadata/companyType'),
}
