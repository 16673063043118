import { GridApi, IServerSideGetRowsParams } from '@ag-grid-community/all-modules'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { CoreService } from '@core/services/core.service'
import { GlobalService } from '@core/services/global.service'
import { SharedDataService } from '@core/services/shared-data.service'
import * as _ from 'lodash'
import { Subscription } from 'rxjs'

import { GirdActionService } from '../../../../../../projects/ag-grid-customized/src/app/data-grid'
import { CompaniesService } from '../../../../../../projects/global-dashboard/companies/companies/services/companies.service'
import { ICompaniesList } from '../../interfaces/companies'
import { IColumnFilterCompanies } from '../../interfaces/search-bk-response'
@Component({
  selector: 'app-search-companies-dialog',
  templateUrl: './search-companies-dialog.component.html',
  styleUrls: ['./search-companies-dialog.component.css'],
})
export class SearchCompaniesDialogComponent implements OnInit, OnDestroy {
  gridApi: GridApi
  selectedCompanies: ICompaniesList[]
  rowData: ICompaniesList[]
  columnDefs: IColumnFilterCompanies
  favCompanies: string[]
  unSelectedCompanies: string[]
  selectedCompaniesArray: ICompaniesList[]
  found: ICompaniesList
  totalRows: number
  subscription: Subscription
  selectedCompany: string[]
  isNoDataFound: boolean
  isInitialRender: boolean
  totalRowsCount: number
  pageSize: number
  globalCompanies: string[]
  constructor(
    public dialogRef: MatDialogRef<SearchCompaniesDialogComponent>,
    public globalService: GlobalService,
    private readonly coreService: CoreService,
    @Inject(MAT_DIALOG_DATA) public modalData,
    private readonly sharedDataService: SharedDataService,
    public gridActionService: GirdActionService,
    public companiesService: CompaniesService
  ) {
    this.subscription = new Subscription()

    this.selectedCompaniesArray = []
    this.favCompanies = []
    this.selectedCompanies = []
    this.columnDefs = this.globalService.getALLcompaniesColoumnDefs()
    this.unSelectedCompanies = []
    this.subscription.add(this.gridActionService.action.subscribe(action => this.actionClicked(action.actionType, action.data)))
    this.pageSize = 50
    this.selectedCompany = []
  }

  ngOnInit() {
    this.globalCompanies = this.sharedDataService.getGlobalSearchCompanies()
  }

    /*** Gets global search companies */
  gridReady(params) {
    this.gridApi = params.api

    this.totalRows = this.gridApi.getDisplayedRowCount()
    const globalCompanies = this.sharedDataService.getGlobalSearchCompanies()
    this.gridApi.forEachNode(node => {
      if (globalCompanies !== undefined) {
        globalCompanies.forEach(widgetData => {
          if (widgetData === node.data.uuid) {
            node.setSelected(true)
          }
        })
      }
    })
  }
  selectedRows(param) {
    this.selectedCompanies = param
  }

    /*** Saves companies in global search */
  saveAddedCompanies() {
    this.favCompanies = this.selectedCompany
    this.sharedDataService.setGlobalSearchCompanies(this.favCompanies)
    this.modalData.selected()
    this.dialogRef.close()
  }
  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
    /*** AG Grid action clicked */
  actionClicked = (event, param) => {
    if (typeof  event === 'undefined') {
      return
    }
    if (event.checked) {
      this.selectedCompany.push(param.uuid)
    } else {
      const index = this.selectedCompany.indexOf(param.uuid, 0)
      if (index > -1) {
        this.selectedCompany.splice(index, 1)
      }
    }
  }

    /*** Handles serverside data for the ag grid */
  getRows(params?: IServerSideGetRowsParams) {
    const { startRow, filterModel, sortModel, endRow } = params.request
    this.subscription.add(
      this.companiesService.getCompaniesCount(params.request.filterModel).subscribe(response => {
      this.totalRowsCount = response.count
      })
    )
    this.setNoRows(this.totalRowsCount)
    this.isNoDataFound = (_.isEmpty(filterModel) && _.isEmpty(sortModel)) ? this.totalRowsCount === 0 : false
    this.subscription.add(
      this.companiesService.fetchTableData({ offset: this.setOffset(startRow), ...params.request }, this.pageSize).subscribe(
        response => {
          if (this.isInitialRender && this.totalRowsCount === 0) {
            this.gridActionService.showNoRowsOverlay()
            params.successCallback([], 0)
            return
          }
          this.isInitialRender = false
          const lastRow = this.totalRowsCount <= endRow ? this.totalRowsCount : -1

          response.forEach(rows => {
            if (this.globalCompanies) {
              this.globalCompanies.forEach(widgetData => {
                if (widgetData === rows.uuid) {
                  rows.isSelected = true
                  this.selectedCompany.push(rows.uuid)
                }
              })
            }
          })

          params.successCallback(response, lastRow)
        },
        error => {
          params.failCallback()
        }
      )
    )
  }
  setNoRows(count) {
    if (count > 0) return
    // else showNoRowsOverlay that accepts plain html
  }
  setOffset(offset: number): number {
    return offset / 50 // to normalize offset value
  }
}
