import { SmileyComponent } from '@projects/ag-grid-customized/src/app/data-grid/cell-renderers/smiley/smiley.component'

import { stringConstants } from '../../constants/constants'
import { ActionsCellRendererComponent } from '../cell-renderers/actions-cell-renderer/actions-cell-renderer.component'
import { AgreementComponent } from '../cell-renderers/agreement-cell-renderer/agreement-cell-renderer.component'
import { DateCellRendererComponent } from '../cell-renderers/date-cell-renderer/date-cell-renderer.component'
import { HoverTagsCellRendererComponent } from '../cell-renderers/hover-tags-renderer/hover-tags-cell-renderer.component'
import { IconCellRendererComponent } from '../cell-renderers/icon-cell-renderer/icon-cell-renderer.component'
import { ImageCellRendererComponent } from '../cell-renderers/image-cell-renderer/image-cell-renderer.component'
import { ImportScreenCellRendererComponent } from '../cell-renderers/import-screen-cell-renderer/import-screen-cell-renderer.component'
import { JsonCellRenderComponent } from '../cell-renderers/json-cell-render/json-cell-render.component'
import { NameCellRendererComponent } from '../cell-renderers/name-cell-renderer/name-cell-renderer.component'
import { OSCellRendererComponent } from '../cell-renderers/os-cell-renderer/os-cell-renderer.component'
import { PasswordCellRendererComponent } from '../cell-renderers/password-cell-renderer/password-cell-renderer.component'
import { ProgressBarCellRendererComponent } from '../cell-renderers/progressbar-cell-renderer/progressbar-cell-renderer.component'
import { ProgressStatusCellRendererComponent } from '../cell-renderers/progressstatus-cell-renderer/progressstatus-cell-renderer.component'
import { TableTagsCellRendererComponent } from '../cell-renderers/table-tags-cell-renderer/table-tags-cell-renderer.component'
import { URLCellRendererComponent } from '../cell-renderers/url-cell-renderer/url-cell-renderer.component'

export function CellRendererFrameworksMapper(type: string, object) {
  switch (type) {
    case stringConstants.DEFAULT_DATAGRID_TAGS:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = TableTagsCellRendererComponent)
    case stringConstants.DEFAULT_DATAGRID_NAME:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = NameCellRendererComponent)
    case stringConstants.DEFAULT_DATAGRID_CUSTOME_TEMPLTE:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = ImportScreenCellRendererComponent)
    case stringConstants.DEFAULT_DATAGRID_DATE:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = DateCellRendererComponent)
    case stringConstants.DEFAULT_DATAGRID_ACTIONS:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = ActionsCellRendererComponent)
    case stringConstants.DEFAULT_DATAGRID_ICON:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = IconCellRendererComponent)
    case stringConstants.DEFAULT_DATAGRID_IMAGE:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = ImageCellRendererComponent)
    case stringConstants.DEFAULT_DATAGRID_HOVERTAGS:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = HoverTagsCellRendererComponent)
    case stringConstants.DEFAULT_DATAGRID_PROGRESSBAR:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = ProgressBarCellRendererComponent)
    case stringConstants.DEFAULT_DATAGRID_STATUS_PROGRESS:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = ProgressStatusCellRendererComponent)
    case stringConstants.DEFAULT_DATAGRID_SMILEY:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = SmileyComponent)
    case stringConstants.DEFAULT_DATAGRID_OS_IMAGE:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = OSCellRendererComponent)
    case stringConstants.DEFAULT_DATAGRID_AGREEMENT:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = AgreementComponent)
    case stringConstants.DEFAULT_DATAGRID_JSON:
      return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = JsonCellRenderComponent)
      case stringConstants.DEFAULT_DATAGRID_PASSWORD:
          return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = PasswordCellRendererComponent)
          case stringConstants.DEFAULT_DATAGRID_URL_MAP:
            return (object[stringConstants.DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK] = URLCellRendererComponent)
    default:
      return false
  }
}
