import { Injectable } from '@angular/core'
import { IAlert } from '@core/interfaces/alert'
import { Observable } from 'rxjs/index'

import { ApiService } from '../../../services/api.service'
@Injectable()
export class HeaderNotificationService {

  constructor(private readonly baseService: ApiService) { }
  /*** Sends http request to get notification  */
  getNotifications(url: string): Observable< IAlert[] > {
      return  this.baseService.get(url, 'Get Notifications')
  }
  /*** Sends http request to update notification  */
  updateNotification(url: string): Observable< IAlert[] > {
      return  this.baseService.patch(url, {})
  }
}
