<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <form [formGroup]="companyTypeForm">
    <div fxLayout="row wrap">
      <div fxFlex="100">
        <itb-input [formFieldName]="'typeName'" [parentForm]="companyTypeForm" [label]="'Name'" [type]="'text'"> </itb-input>
        <itb-info-message
          *ngIf="!companyTypeForm.valid && companyTypeForm.get('typeName').errors && isFormSubmitted"
          [text]="'Field is required'"
          [className]="'info-danger'"
        ></itb-info-message>
      </div>
      <div fxFlex="50">
        <itb-switch
          [label]="'SYNC WITH PSA'"
          [labelPosition]="'after'"
          [parentForm]="companyTypeForm"
          [formFieldName]="'syncFlag'"
        ></itb-switch>
      </div>
      <div fxFlex="50">
        <itb-switch
          *ngIf = "companyTypeForm.get('syncFlag').value"
          [label]="'DEFAULT FLAG'"
          [labelPosition]="'after'"
          [parentForm]="companyTypeForm"
          [formFieldName]="'defaultFlag'"
        ></itb-switch>
      </div>
      <div fxFlex="50">
        <itb-info-message *ngIf="isSavedFailed" [text]="'Something went wrong'" [className]="'info-danger'"></itb-info-message>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <itb-default-button
    [className]="'primary'"
    [isLoading]="isButtonLoading"
    [text]="'Save'"
    [iconClassName]="'save'"
    (onClickHandler)="saveType()"
  ></itb-default-button>
  <itb-default-button
    [text]="'Cancel'"
    [className]="'default'"
    [iconClassName]="'cross'"
    (onClickHandler)="closeModal()"
  ></itb-default-button>
</div>
