
import { stringConstants } from '../../constants/constants'
import { CustomHeader } from '../custom-components/custom-header.component'

export function CustomHeaderFrameworksMapper(headerType: string, object) {
  switch (headerType) {
    case stringConstants.DEFAULT_DATAGRID_CUSTOM_HEADER:
      return (object[stringConstants.DEFAULT_DATAGRID_CUSTOM_HEADER_FRAMEWORK] = CustomHeader)
    default:
      return false
  }
}
