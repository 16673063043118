import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'

import { IStatusOptions, ITypeOptions } from '../../interfaces/company-creation'
import { CompanyCreationService } from '../../services/company-creation.service'

@Component({
  selector: 'app-company-type-modal',
  templateUrl: './add-company-type-modal.component.html',
})
export class AddCompanyTypeModalComponent implements OnInit {
  companyTypeForm: FormGroup
  isFormSubmitted: boolean
  isButtonLoading: boolean
  isSavedFailed: boolean
  constructor(
    public dialogRef: MatDialogRef<AddCompanyTypeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; type: string; integrationType: boolean },
    private readonly fb: FormBuilder,
    private readonly _companyCreationService: CompanyCreationService
  ) {
    this.isFormSubmitted = false
    this.isButtonLoading = false
    this.isSavedFailed = false
  }

    /*** Adds options of company types */
  ngOnInit() {
    this.companyTypeForm = this.fb.group({
      typeName: ['', Validators.required],
      defaultFlag: [!this.data.integrationType ? true : false],
      syncFlag: [false],
    })
    if (!this.data.integrationType) {
      this.companyTypeForm.controls.syncFlag.disable()
    }
  }

    /*** Saves a new company type that is added in the dialog */
  saveType() {
    this.isFormSubmitted = true
    if (this.companyTypeForm.valid) {
      this.isButtonLoading = true
      const formData = {
        name: this.companyTypeForm.value.typeName,
        defaultFlag: this.companyTypeForm.value.defaultFlag,
        syncFlag: this.companyTypeForm.value.syncFlag,
        mainIntegration: this.data.integrationType,
      }
      if (!this.data.integrationType) delete formData.mainIntegration
      if (this.data.type === 'Type') {
        this._companyCreationService.postCompanyType(formData).subscribe(
          (result: ITypeOptions) => {
            this.dialogRef.close({ data: { result } })
          },
          error => {
            this.isSavedFailed = true
            this.isButtonLoading = false
          }
        )
      }
      if (this.data.type === 'Status') {
        this._companyCreationService.postCompanyStatus(formData).subscribe(
          (result: IStatusOptions) => {
            this.dialogRef.close({ data: { result } })
          },
          error => {
            this.isSavedFailed = true
            this.isButtonLoading = false
          }
        )
      }
    }
  }
    /*** Handles modal close event */
  closeModal(): void {
    this.dialogRef.close()
  }
}
