import { Action } from '@ngrx/store'

import { ICompany } from '../../modules/locations/interfaces/company'
import { IMenuOption, Menu } from '../interfaces/menu'

export enum ECommunicationActions {
  TRIGGER_ACTION = '[Communication] Trigger Action',
  LoadUserMenu = '[Menu Page] Load User Menu',
  ChangeUserMenu = '[Menu Page] Change User Menu',
  UserMenuError = '[Source APIs] User Menu Error',
  SetCurrentMenu = '[Menu Page] Set Current Menu',
  SetCurrentCompany = '[Menu Page] Set Current Company',
}

export class LoadUserMenuAction implements Action {
  readonly type = ECommunicationActions.LoadUserMenu
  constructor(public payload: { User_Menu: Menu[] }) { }
}

export class ChangeUserMenuAction implements Action {
  readonly type = ECommunicationActions.ChangeUserMenu
  constructor(public payload: { option: IMenuOption }) { }
}

export class SetCurrentMenuAction implements Action {
  readonly type = ECommunicationActions.SetCurrentMenu
  constructor(public payload) { }
}

export class SetCurrentCompanyAction implements Action {
  readonly type = ECommunicationActions.SetCurrentCompany
  constructor(public payload) { }
}

export class UserMenuErrorAction implements Action {
  readonly type = ECommunicationActions.UserMenuError
}

export class TriggerCommunicationAction implements Action {
  readonly type = ECommunicationActions.TRIGGER_ACTION
  constructor(public payload: string) { }
}

export type CommunicationActions = TriggerCommunicationAction |
  LoadUserMenuAction | ChangeUserMenuAction |
  UserMenuErrorAction | SetCurrentMenuAction |
  SetCurrentCompanyAction
