import { ErrorHandler, Injectable, Injector } from '@angular/core'
import { Router } from '@angular/router'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly injector: Injector) { }
    /*** Handles all errors otherwise hidden - shows them in the console */
  handleError(error) {
    const router = this.injector.get(Router)
    if (error.stack) {
      // tslint:disable-next-line: no-console
      console.error(error.stack.toString())
    }
  }

}
