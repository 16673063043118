<mat-chip-list *ngIf="!user.TvUser">
    <div class="itb-chip">
        <a routerLink= {{route}} class="pointer"><mat-chip (click)="nameHandler()" class="chip theme-bg add-append pointer" *ngIf="paramsValue > 0 && !isAddTagHidden" selected>
            <span class="chip-text"> {{paramsValue}}</span>
            <span class="add-ico">+</span>
        </mat-chip></a>
        <a routerLink= {{route}} class="pointer"><mat-chip (click)="nameHandler()" class="chip danger-bg add-append pointer" *ngIf="paramsValue == 0 && !isAddTagHidden" selected>
           <span class="chip-text"> {{paramsValue}}</span>
            <span class="add-ico">+</span>
        </mat-chip></a>

        <a class="pointer"><mat-chip class="chip theme-bg add-append pointer" *ngIf="paramsValue > 0 && isAddTagHidden" selected>
          <span class="chip-text"> {{paramsValue}}</span>
        </mat-chip></a>
        <a class="pointer"><mat-chip class="chip danger-bg add-append pointer" *ngIf="paramsValue == 0 && isAddTagHidden" selected>
          <span class="chip-text"> {{paramsValue}}</span>
        </mat-chip></a>

    </div>
</mat-chip-list>

<mat-chip-list *ngIf="user.TvUser">
  <div class="itb-chip">
    <a  class="pointer"><mat-chip (click)="nameHandler()" class="chip theme-bg add-append pointer"
                                                       *ngIf="paramsValue > 0" selected>
      <span class="chip-text"> {{paramsValue}}</span>
    </mat-chip></a>
    <a  class="pointer"><mat-chip (click)="nameHandler()" class="chip danger-bg add-append pointer"
                                                       *ngIf="paramsValue == 0" selected>
      <span class="chip-text"> {{paramsValue}}</span>
    </mat-chip></a>
  </div>
</mat-chip-list>
