import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

import { GirdActionService } from '../../services/grid-actions.service'

@Component({
  selector: 'custom-loading',
  templateUrl: './custom-loading.component.html',
})
export class CustomLoadingRendererComponent implements AgRendererComponent {
rowModelType: string
isRefresh: boolean
lastDisplayRow: number
oneLoader = true
params // used for optional params that are added dynamically for different types
isSort: boolean
isFilter: boolean
constructor(readonly gridctionService: GirdActionService) {
  this.oneLoader = true
  this.gridctionService.purgeCache$.subscribe(res => {
    const elem: any = document.getElementsByClassName('ag-row') // tslint:disable-line
    for (let i = 0 ; i < elem.length ; i++) { // tslint:disable-line
      elem[i].style.borderStyle = 'hidden'

    }
    this.isRefresh = true
    this.oneLoader = true
  })

  this.gridctionService.filterChanged$.subscribe(res => {
    this.oneLoader = res
  })
  }
agInit(params: ICellRendererParams): void {
    this.params = params
    this.isSort = this.params.api.sortController.getSortModel().length > 0
    this.isFilter = this.params.api.filterManager.getFilterModel().length > 0
    if (this.params.api.getCacheBlockState()[0]) {
      this.isRefresh = this.params.api.getCacheBlockState()[0].startRow === 0 &&
      this.params.api.getCacheBlockState()[0].pageStatus !== 'loaded'
    } else {
      this.isRefresh = false
    }
  }

refresh(): boolean {
    return false
  }
}
