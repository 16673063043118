export interface ITheme {
  name: string
  properties: object
}

export const light: ITheme = {
  name: 'light',
  properties: {
    '--appbg': '#f1f3f7',
    '--theme': '#0866c6',
    '--theme-main': '#1d81bf',
    '--primary': '#0866c6',
    '--primary2': '#e8edf3',
    '--primary3': '#248dde',
    '--bgContainer': '#ffffff',
    '--bgContainer2': '#0067b1',
    '--bgContainer3': '#f8f9fa;',
    '--sideBarIconBg': '#f2fbff',
    '--color': '#333333',
    '--colorT': '#ffffff',
    '--colorI': '#0866c6',
    '--input': '#333333',
    '--label': '#0866c6',
    '--breadcrumb': '#0866c6',
    '--titleBG': '#f8f9fa',
    '--titleColor': '#0866c6',
    '--iconBG': '#ffffff',
    '--sidebarBG': '#ffffff',
    '--sidebarBG2': '#f8f9fa',
    '--sidebarBox': '#248dde',
    '--sidebarHover': '#f2f7fc',
    '--arrow': '#ffffff',
    '--chevron': '#1d212d',
    '--border': '#e4e8eb',
    '--border2': '#0866c6',
    '--aghborder': '#d0d0d0',
    '--highlgtBGR': '#e2a09c',
    '--shadow1': 'rgba(126, 142, 177, 0.15)',
    '--shadowb': 'rgba(8, 102, 198, 0.3)',
    '--shadowg': 'rgba(52, 191, 163, 0.3)',
    '--shadowred': 'rgba(239,63,62,0.1s)',
    '--ripple': 'rgba(0,0,0,.87)',
    '--loaderB': '#c3d6ee',
    '--disabled': 'rgba(0,0,0,.38)',
    '--bar2active': '#e8edf3',
    '--nodfIco': 'url("assets/img/theme/light/img_nodatafound.svg")',
    '--timeoutIco': 'url("assets/img/theme/light/img_timeout.svg")',
    '--emptytabIco': 'url("assets/img/theme/light/img_addtab.svg")',
    '--greyfilter': 'grayscale(0)',
    '--icoFilter': 'brightness(1)',
    '--icoBFilter': 'grayscale(100%) invert(0.8)',
    '--crossfilter': 'brightness(0)',
    '--themeBtn': '#248dde',
    '--image-404error': 'url(images-library/empty-states/light/img_404error.svg)',
    '--image-accessdenied': 'url(images-library/empty-states/light/img_accessdenied.svg)',
    '--image-adddashboard': 'url(images-library/empty-states/light/img_adddashboard.svg)',
    '--image-addintegration': 'url(images-library/empty-states/light/img_addintegration.svg)',
    '--image-addnew': 'url(images-library/empty-states/light/img_addnew.svg)',
    '--image-addtab': 'url(images-library/empty-states/light/img_addtab.svg)',
    '--image-addtemplate': 'url(images-library/empty-states/light/img_addtemplate.svg)',
    '--image-nodata': 'url(images-library/empty-states/light/img_nodata.svg)',
    '--image-nodatafound': 'url(images-library/empty-states/light/img_nodatafound.svg)',
    '--image-nopermission': 'url(images-library/empty-states/light/img_nopermission.svg)',
    '--image-thanksregister': 'url(images-library/empty-states/light/img_thanksregister.svg)',
    '--image-timeout': 'url(images-library/empty-states/light/img_timeout.svg)',
    '--image-uploadfiles': 'url(images-library/empty-states/light/img_uploadfiles.svg)',
    '--image-noresults': 'url(images-library/empty-states/light/img_noresults.svg)',
    '--image-binorecords': 'url(images-library/empty-states/light/img_binorecords.svg)',
    '--ck-color-toolbar-background': '#fafafa',
    '--ck-color-toolbar-border': '#e4e8eb',
    '--ck-color-tooltip-background': '#000',
    '--ck-color-tooltip-text': '#fff',
    '--ck-color-button-default-hover-background': 'transparent',
    '--menuButtonRes': 'brightness(0.7) invert(1)',
    '--subMenuArrow': 'brightness(6) invert(1)',
  },
}

export const dark: ITheme = {
  name: 'dark',
  properties: {
    '--appbg': '#1d2126',
    '--theme': '#0a95df',
    '--theme-main': '#1b1f23',
    '--primary': '#181b21',
    '--primary2': '#181b21',
    '--primary3': '#0866c6',
    '--bgContainer': '#2e353e',
    '--bgContainer2': '#181b21',
    '--bgContainer3': '#282c35;',
    '--sideBarIconBg': '#2e353e',
    '--color': '#a4a6ae',
    '--colorT': '#ffffff',
    '--colorI': '#f1f3f7',
    '--input': '#ffffff',
    '--label': '#a4a6ae',
    '--breadcrumb': '#a4a6ae',
    '--titleBG': '#282c35',
    '--titleColor': '#ffffff',
    '--iconBG': '#282c35',
    '--sidebarBG': '#1d2126',
    '--sidebarBG2': '#1d2126',
    '--sidebarBox': '#282c35',
    '--sidebarHover': '#181b21',
    '--arrow': '#1d212d',
    '--chevron': '#a4a6ae',
    '--border': '#434a52',
    '--border2': '#000000',
    '--aghborder': '#434a52',
    '--highlgtBGR': '#583d3b',
    '--shadow1': 'rgba(0, 0, 0, .4)',
    '--shadowb': 'rgba(0, 0, 0, 0.4)',
    '--shadowg': 'rgba(0, 0, 0, .4)',
    '--shadowred': 'rgba(0, 0, 0, .4)',
    '--ripple': 'rgba(255,255,255,.67)',
    '--loaderB': '#c3d6ee',
    '--disabled': 'rgba(255,255,255,.38)',
    '--bar2active': '#181b21',
    '--nodfIco': 'url("assets/img/theme/dark/img_nodatafound.svg")',
    '--timeoutIco': 'url("assets/img/theme/dark/img_timeout.svg")',
    '--emptytabIco': 'url("assets/img/theme/dark/img_addtab.svg")',
    '--greyfilter': 'brightness(1.5)',
    '--menuButtonRes': 'brightness(1.5)',
    '--icoFilter': 'brightness(1.5)',
    '--icoBFilter': 'grayscale(100%) invert(0.2)',
    '--crossfilter': 'brightness(8)',
    '--themeBtn': '#0a95df',
    '--image-404error': 'url(images-library/empty-states/dark/img_404error.svg)',
    '--image-accessdenied': 'url(images-library/empty-states/dark/img_accessdenied.svg)',
    '--image-adddashboard': 'url(images-library/empty-states/dark/img_adddashboard.svg)',
    '--image-addintegration': 'url(images-library/empty-states/dark/img_addintegration.svg)',
    '--image-addnew': 'url(images-library/empty-states/dark/img_addnew.svg)',
    '--image-addtab': 'url(images-library/empty-states/dark/img_addtab.svg)',
    '--image-addtemplate': 'url(images-library/empty-states/dark/img_addtemplate.svg)',
    '--image-nodata': 'url(images-library/empty-states/dark/img_nodata.svg)',
    '--image-nodatafound': 'url(images-library/empty-states/dark/img_nodatafound.svg)',
    '--image-nopermission': 'url(images-library/empty-states/dark/img_nopermission.svg)',
    '--image-thanksregister': 'url(images-library/empty-states/dark/img_thanksregister.svg)',
    '--image-timeout': 'url(images-library/empty-states/dark/img_timeout.svg)',
    '--image-uploadfiles': 'url(images-library/empty-states/dark/img_uploadfiles.svg)',
    '--image-noresults': 'url(images-library/empty-states/dark/img_noresults.svg)',
    '--image-binorecords': 'url(images-library/empty-states/dark/img_binorecords.svg)',
    '--ck-color-toolbar-background': '#282c35',
    '--ck-color-toolbar-border': '#434a52',
    '--ck-color-tooltip-background': '#000',
    '--ck-color-tooltip-text': '#fff',
    '--ck-color-button-default-hover-background': 'transparent',
    '--subMenuArrow': 'brightness(0) invert(1)',
  },
}

export const green: ITheme = {
  name: 'green',
  properties: {
    '--appbg': '#f1f3f7',
    '--theme': '#006e47',
    '--primary': '#006e47',
    '--primary2': '#e8edf3',
    '--primary3': '#009c65',
    '--bgContainer': '#ffffff',
    '--color': '#333333',
    '--colorT': '#ffffff',
    '--colorI': '#006e47',
    '--input': '#333333',
    '--label': '#006e47',
    '--breadcrumb': '#006e47',
    '--titleBG': '#f8f9fa',
    '--titleColor': '#006e47',
    '--iconBG': '#ffffff',
    '--sidebarBG': '#ffffff',
    '--sidebarBG2': '#f8f9fa',
    '--sidebarBox': '#009c65',
    '--sidebarHover': '#f2f7fc',
    '--arrow': '#ffffff',
    '--chevron': '#1d212d',
    '--border': '#e4e8eb',
    '--border2': '#006e47',
    '--shadow1': 'rgba(126, 142, 177, 0.4)',
    '--shadowb': 'rgba(0, 110, 71, 0.5)',
    '--shadowg': 'rgba(52, 191, 163, 0.5)',
    '--ripple': 'rgba(0,0,0,.87)',
    '--loaderB': '#c3d6ee',
    '--disabled': 'rgba(0,0,0,.38)',
    '--bar2active': '#e8edf3',
    '--nodfIco': 'url("assets/img/theme/light/img_nodatafound.svg")',
    '--timeoutIco': 'url("assets/img/theme/light/img_timeout.svg")',
    '--greyfilter': 'grayscale(0)',
    '--icoFilter': 'grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8)',
    '--themeBtn': '#009c65',
    '--image-404error': 'url(images-library/empty-states/light/img_404error.svg)',
    '--image-accessdenied': 'url(images-library/empty-states/light/img_accessdenied.svg)',
    '--image-adddashboard': 'url(images-library/empty-states/light/img_adddashboard.svg)',
    '--image-addintegration': 'url(images-library/empty-states/light/img_addintegration.svg)',
    '--image-addnew': 'url(images-library/empty-states/light/img_addnew.svg)',
    '--image-addtab': 'url(images-library/empty-states/light/img_addtab.svg)',
    '--image-addtemplate': 'url(images-library/empty-states/light/img_addtemplate.svg)',
    '--image-nodata': 'url(images-library/empty-states/light/img_nodata.svg)',
    '--image-nodatafound': 'url(images-library/empty-states/light/img_nodatafound.svg)',
    '--image-nopermission': 'url(images-library/empty-states/light/img_nopermission.svg)',
    '--image-thanksregister': 'url(images-library/empty-states/light/img_thanksregister.svg)',
    '--image-timeout': 'url(images-library/empty-states/light/img_timeout.svg)',
    '--image-uploadfiles': 'url(images-library/empty-states/light/img_uploadfiles.svg)',
    '--image-noresults': 'url(images-library/empty-states/light/img_noresults.svg)',
    '--image-binorecords': 'url(images-library/empty-states/light/img_binorecords.svg)',
  },
}
