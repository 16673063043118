import { IDiscovererSystem } from '../../../interfaces/discoverer-system.interface'
import { IDiscoverers, IIntegrationsList } from '../../../interfaces/intgeration-list.interface'

export interface DiscovererState {
  data: IDiscoverers[]
  headers: object[]
  hasDataChanged?: boolean
  isLoading: boolean
  isLoaded: boolean
  isError: boolean
}

export const initialState: DiscovererState = {
  data: [],
  headers: [],
  isLoaded: false,
  hasDataChanged: false,
  isLoading: false,
  isError: false,
}

export interface DiscovererSystemState {
  data: IDiscovererSystem[]
  hasDataChanged?: boolean
  isLoading: boolean
  isLoaded: boolean
  isError: boolean
}

export const initialDsState: DiscovererSystemState = {
  data: [],
  isLoaded: false,
  hasDataChanged: false,
  isLoading: false,
  isError: false,
}
