import { Injectable } from '@angular/core'
import { IAvatar } from '@app/modules/core/interfaces/avatar'
import { IProfileBackendInterface, IProfileImageUpload } from '@app/modules/core/interfaces/profile-user'
import { Observable } from 'rxjs/index'

import { ApiService } from '../../../services/api.service'
import { backendRoutes } from '../constants/constant'
import { IUsers } from '../interfaces/users'
@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private readonly baseService: ApiService) {}
  /*** Returns avatar array */
  avatarArray(): IAvatar[] {
    return [{
      id: 'ProfilePic1',
      src: 'images-library/profiles/1.png',
    },
            {
        id: 'ProfilePic2',
        src: 'images-library/profiles/2.png',
      },
            {
        id: 'ProfilePic3',
        src: 'images-library/profiles/3.png',
      },
            {
        id: 'ProfilePic4',
        src: 'images-library/profiles/4.png',
      },
            {
        id: 'ProfilePic5',
        src: 'images-library/profiles/5.png',
      },
            {
        id: 'ProfilePic6',
        src: 'images-library/profiles/6.png',
      },
            {
        id: 'ProfilePic7',
        src: 'images-library/profiles/7.png',
      },
            {
        id: 'ProfilePic8',
        src: 'images-library/profiles/8.png',
      },
            {
        id: 'ProfilePic9',
        src: 'images-library/profiles/9.png',
      },
            {
        id: 'ProfilePic10',
        src: 'images-library/profiles/10.png',
      },
            {
        id: 'ProfilePic11',
        src: 'images-library/profiles/11.png',
      },
            {
        id: 'ProfilePic12',
        src: 'images-library/profiles/12.png',
      },
    ]
  }
  /*** Sends http request to get profile data  */
  getUserProfile(): Observable<IUsers> {
    const url = backendRoutes.GET_PROFILE()
    return this.baseService.get(url, 'Get User Profile')
  }
  /*** Sends http request to update profile data  */
  patchUserProfile(data: IProfileBackendInterface): Observable<IProfileBackendInterface>  {
    const url = backendRoutes.UPDATE_PROFILE()
    return this.baseService.edit(url, data)
  }
  uploadProfileImage(name: string, type: string): Observable<IProfileImageUpload>  {
    const url = backendRoutes.PROFILE_IMAGE_UPLOAD(name, type)
    return this.baseService.get(url, 'Upload Profile Image')
  }
  uploadProfileImageToS3(url: string, file)  {
    return this.baseService.s3ImageUploadSignedUrl(url, file)
  }
  /*** Sends http request to update profile data  */
  getAvatarImg(picSrc: string): string {
    if (!picSrc) {
      return
    }
    const picSrcArr = picSrc.split('/')
    return picSrcArr[picSrcArr.length - 1]
  }
  /*** Creates backend object */
  createBackendObject(data: IUsers): IProfileBackendInterface {
    data.phoneMobile = data.phoneMobile ? data.phoneMobile : ''
    data.phoneOffice = data.phoneOffice ? data.phoneOffice : ''
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      address: data.address ? data.address : '',
      userName: data.userName,
      dob : data.dob ? data.dob : '',
      gender : data.gender ? data.gender : '',
      timeFormat : data.timeFormat,
      phoneMobile: data.phoneMobile,
      phoneOffice: data.phoneOffice,
      theme: data.theme,
      timezone: data.timezone,
      dateFormat: data.dateFormat,
      picMode: data.picMode,
      userPhoto: data.userPhoto,
    }
  }

  checkString(value) {
    return typeof  value === 'number' ? value.toString() : value
  }
}
