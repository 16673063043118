<itb-heading mat-dialog-title [className]="'modal-h'" [text]="'HOW DO YOU WANT TO OPEN THE APPLICATION?'"></itb-heading>
<itb-round-button [tooltipText]="'Close'" mat-dialog-close [className]="'light round-modal-cross caret-close-modal'"
  [iconClassName]="'cross'"></itb-round-button>

<img src="../../assets/img/logo_screenconnect.png" alt="Connect wise Control">
<div mat-mat-dialog-content>


  <div class="itb-table"  *ngIf="!isLoading" >

    <itb-table [dataSource]="dataSource" [displayedColumns]="displayedColumns"
      (onActionClickHandler)="onClickEvent($event)"></itb-table>

  </div>
  <itb-spinner *ngIf="isLoading" fxLayoutAlign="center center" class="h-100"
    [spinnerSize]="75">
  </itb-spinner>

</div>
<div mat-dialog-actions>
  <!-- <itb-action-panel [className]="'example'" [btnArray]="btnArray" (onClickHandler)="dispatchEvent($event)">
</itb-action-panel> -->

  <itb-default-button [mat-dialog-close]="'Close'" #infoDialogClose [text]="'cancel'" [className]="'default'"
    [iconClassName]="'cross'"></itb-default-button>
</div>
