import { Location } from '@angular/common'
import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { meta } from '@app/modules/core/constants/constant'
import { filter, map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title,
    private readonly location: Location
  ) { }

  /*** Sets the title of the page : Appends title passed to the route in data object along with the universal title : ITBOOST */
  init() {
    this.router.events.pipe(map(() => {
      let child = this.activatedRoute.firstChild
      while (child) {
          if (child.firstChild) {
              child = child.firstChild
          } else if (child.snapshot && child.snapshot.data && child.snapshot.data.title) {
              return child.snapshot.data.title
          } else {
              return undefined
          }
      }
      return undefined
    })).subscribe(title => {
        if (!title)
          title = meta.TITLE_SUBTITLE

        this.titleService.setTitle(`${title}${meta.TITLE_SEPARATOR}${meta.TITLE_MAIN}`)
    })
  }
}
