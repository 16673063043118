import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

import { GirdActionService } from '../../services/grid-actions.service'

@Component({
  selector: 'no-rows-overlay',
  templateUrl: './custom-no-overlay.component.html',
})
export class CustomNoRowsRendererComponent implements AgRendererComponent {
  params // used for optional params that are added dynamically for different types
  hasNoColumns: boolean
  constructor(public gridActionService: GirdActionService) {
    this.gridActionService.hideNoRowsOverlay$.subscribe(value => (this.hasNoColumns = false))
  }

  agInit(params: ICellRendererParams): void {
    this.hasNoColumns = this.gridActionService.hasNoColumns
    this.params = params
  }

  refresh(params: ICellRendererParams): boolean {
    return false
  }

  noRowsOverlayClicked(event) {
    this.gridActionService.noRowsOverlayClicked()
  }
}
