import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { ActivatedRoute } from '@angular/router'
import { loginRouteConstants, loginStringConstants, PODSCONST } from '@app/modules/login/constants/login-string-constants'
import { IChecBoxInterface } from '@app/modules/login/interface/check-box-interface'
import { IInputInterface } from '@app/modules/login/interface/input-interface'
import { IOrganization } from '@app/modules/login/interface/organization'
import { SharedDataService } from '@core/services/shared-data.service'
import { UtilService } from '@core/services/utils.service'
import { Observable } from 'rxjs/index'

import { ApiService } from '../../../services/api.service'
import { loginErrorConstants } from '../constants/login-string-constants'
import { IForgetPassword } from '../interface/forget-password'

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  constructor(
    private readonly baseService: ApiService, private readonly activatedRoute: ActivatedRoute,
    public dialog: MatDialog, private readonly utilService: UtilService,
    public sharedDataService: SharedDataService) {
  }
  /*** Gets username field configuration from login constants */
  userNameConfiguration(): IInputInterface {
    return loginStringConstants.userNameConfiguration
  }
  /*** Gets password field configuration from login constants */
  passwordConfiguration(): IInputInterface {
    return loginStringConstants.passwordConfiguration
  }
  /*** Gets code field configuration from login constants */
  codeConfiguration(): IInputInterface {
    return loginStringConstants.codeConfiguration
  }
  /*** Gets checkbox field configuration from login constants */
  ldapCheckBoxConfiguration(): IChecBoxInterface {
    return loginStringConstants.ldapCheckBoxConfiguration
  }
  /*** Gets forget password field configuration from login constants */
  forgotPasswordConfiguration(): IInputInterface {
    return loginStringConstants.forgotPasswordConfiguration
  }
  /*** Getsting organization info from http request */
  getConfig(): Observable<IOrganization> {
    const url = loginRouteConstants.ORG_INFO(this.getSubDomain())
    return this.baseService.get(url, 'organization info', true)
  }
  /*** Getsting subdomain */
  getSubDomain(): string {
    let host = window.location.href
    host = host.replace(loginStringConstants.HTTP_STRONG, loginStringConstants.EMPTY_STRING)
    host = host.replace(loginStringConstants.HTTPS_STRONG, loginStringConstants.EMPTY_STRING)
    if (host.indexOf(loginStringConstants.DOT) >= 0) {
      return host.split('.')[0]
    }
  }
  /*** Close all dialog box */
  closeModal() {
    this.dialog.closeAll()
  }
  /*** Sends reset password http request  */
  reset(body: object): Observable<IForgetPassword> {
    const url = loginRouteConstants.RESET_PASSWORD()
    return this.baseService.add(url, body, true)
  }
  /*** Emails validation  */
  checkEmailValidation(value: string): boolean {
    if (!value) {
      return
    }
    const regex = '^(([^<>()[\]\\.,;:\s@\]+(\.[^<>()[\]\\.,;:\s@\]+)*)' +
      '|(\.+\))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$'
    const pattern = new RegExp(regex)
    const valid = value.length > 0 && !pattern.test(value)
    return valid
  }
  /*** Sending SSO login http request  */
  ssoLogin(body: object) {
    const url = loginRouteConstants.SSO_LOGIN()
    return this.baseService.add(url, body, true)
  }

  /*** Sending request to get SSO URL **/
  loginRedirect(subDomain) {
    const url = loginRouteConstants.LOGIN_REDIRECT(subDomain)
    return this.baseService.get(url, 'SSO login redirect', true)
  }
  /*** Sending LDAP login  http request  */
  googleAuthVerify(body: object) {
    const url = loginRouteConstants.GOOGLE_AUTH_VERIFY()
    return this.baseService.add(url, body, true)
  }
  /*** Sending ITB login  http request  */
  itbAuth(body: object) {
    const url = loginRouteConstants.AUTH()
    return this.baseService.add(url, body, true)
  }
  /*** Checks required fields   */
  checkRequiredFields(userName: string, password: string) {
    let errorMessage = ''
    if (this.utilService.isUndefined(userName)) {
      errorMessage = loginErrorConstants.VALID_EMAIL
    }
    if (this.utilService.isUndefined(password)) {
      errorMessage = loginErrorConstants.VALID_PASSWORD
    }
    if (this.utilService.isUndefined(userName) && this.utilService.isUndefined(password)) {
      errorMessage = loginErrorConstants.VALID_EMAIL_AND_PASSWORD
    }
    return errorMessage
  }

  checkPodsData() {
    const podsInterval = setInterval(() => {
      if (!this.utilService.isUndefined(this.sharedDataService.getUser())) {
        this.createUrlForPods(this.activatedRoute.snapshot.queryParams.pods)
        clearInterval(podsInterval)

      }
    }, 10000)
  }

  createUrlForLogin(pods?: string) {
    let url = document.location.href.replace(PODSCONST.REPLACE.APP, '')
    url = url.replace(pods, PODSCONST.REPLACE.LOGIN)
    if (!url.includes(pods)) {
      url = `${url}${PODSCONST.GET_PODS}${pods}`
    }
    url = url.replace(/([^:]\/)\/+/g, '$1')
    window.location.href = url
  }
  createUrlForPods(pods?: string) {
    const url = document.location.href.replace('login', `${PODSCONST.REPLACE.APP}/${pods}`)
    window.location.href = url
  }
  confirmUserUuid(url: string): Observable<{ data: string }> {

    return this.baseService.add(url, '', true)

  }
}
