import { Component, Inject , Input, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { IUsers } from '@core/interfaces/users'
import { SharedDataService } from '@core/services/shared-data.service'
import { On } from '@ngrx/store'
import { Subscription } from 'rxjs/Rx'

import { IDropdown, IItems } from '../../../interfaces/side-pull'
import { SidepullComponent } from '../sidepull.component'
@Component({
  selector: 'app-add-company-items',
  templateUrl: './add-company-items.component.html',
})
export class AddCompanyItemsComponent implements OnInit, OnDestroy {
  subscription: Subscription
  @Input() compData: { uuid: string }
  items: IItems[]
  dropDownButton: IDropdown
  user: IUsers
  constructor(private readonly sharedDataService: SharedDataService, private readonly _router: Router,
              @Inject(SidepullComponent) private readonly parent: SidepullComponent) {
    this.subscription = new Subscription()
    this.user = this.sharedDataService.getUser()
    this.dropDownButton = {
      buttonClassName: 'light',
      buttonIconClassName: 'plus-c',
    }
    this.items = []
  }
    /*** Shows options to add company items */
  ngOnInit() {
    this.items = [
      {
        iconClassName: 'setting',
        label: 'Add Configuration',
        id : '0',
        url: `/app/company/${this.compData.uuid}/configurations/add`,
      },
      {
        iconClassName: 'tab-inner-ico headphones',
        label: 'Add Contact',
        id : '1',
        url: `/app/company/${this.compData.uuid}/contacts/add`,
      },
      {
        iconClassName: 'tab-inner-ico map-marker',
        label: 'Add Location',
        id : '2',
        url: `/app/company/${this.compData.uuid}/locations/add`,
      }]
  }
  closeSidePull = obj => {
    this._router.navigateByUrl(obj.url).catch()
    this.parent.toggle()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
