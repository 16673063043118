import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, NgModule, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ActivatedRoute, Router } from '@angular/router'
import { MODAL_WIDTH_CONSTANTS } from '@app/modules/core/constants/constants'
import { SharedDataService } from '@app/modules/core/services/shared-data.service'
import { ThemeService } from '@app/modules/core/services/theme.service'
import { UtilService } from '@app/modules/core/services/utils.service'
import { loginStringConstants } from '@app/modules/login/constants/login-string-constants'
import { IChecBoxInterface } from '@app/modules/login/interface/check-box-interface'
import { IInputInterface } from '@app/modules/login/interface/input-interface'
import { IOrganization } from '@app/modules/login/interface/organization'
import { LoginService } from '@app/modules/login/services/login.service'
import { Subscription } from 'rxjs'
import { TimeInterval } from 'rxjs/Rx'

import { loginErrorConstants } from '../../constants/login-string-constants'
import { IGoogleAuth, ILoginResponse } from '../../interface/login-response'
@NgModule({
  imports: [BrowserAnimationsModule],
})
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  animations: [
    trigger('loginIn', [state('void', style({ opacity: 0 })),
                        transition('void => *', animate(500)),
    ]),
    trigger('forgetIn', [
      state('void', style({ opacity: 0 })),
      transition('void => *', animate(500)),
    ]),
  ],
})
/**
 * Login Component - Login user to ITB
 */
export class LoginComponent implements OnInit {
  token: string
  subscriptions: Subscription
  errorMessages: string
  isEmailError: boolean
  userNameConfiguration: IInputInterface
  passwordConfiguration: IInputInterface
  codeConfiguration: IInputInterface
  checkBoxConfiguration: IChecBoxInterface
  isGoogleAuthVerified: boolean
  isGoogleAuthError: boolean
  defaultLogoPath: string
  orgName: string
  companyLogo: string
  showBrandLogo: boolean
  isLoader: boolean
  subDomain: string
  enforceLoginType: string
  isSsoFlag: boolean
  isShowSSOFlag: boolean
  isIncorrectPasswordFlag: boolean
  isServerError: boolean
  inputForm: FormGroup
  isPromiseResolved: boolean
  isSuccessResponse: boolean
  isFailRespnse: boolean
  isResetScreen: boolean
  validationMessage: string
  forgotPasswordConfiguration: IInputInterface
  inputFormPassword: FormGroup
  forgetErrorMessage: string
  isPodsLoginFlag: boolean
  loginPodsPage: string
  firstTimeLogin: boolean
  sliderDots: NodeListOf<HTMLElement>
  // tslint:disable-next-line: no-any
  sliderInterval: any
  emailPattern
  isSsoLogin: boolean
  isAdminLoginDisable: boolean
  @ViewChild('content', { static: true }) private readonly content
  @ViewChild('errors', { static: true }) private readonly errors
  @ViewChild('sessionExpire', { static: true }) private readonly sessionExpire
  constructor(private readonly router: Router, private readonly sharedDataService: SharedDataService,
              private readonly activatedRoute: ActivatedRoute, private readonly loginService: LoginService,
              private readonly themeSvc: ThemeService,
              private readonly utilService: UtilService, public dialog: MatDialog) {
    this.loginPodsPage = activatedRoute.snapshot.queryParams.pods ? 'pods-login-container' : ''
    this.emailPattern = loginStringConstants.EMAIL_PATTERN
    this.isPodsLoginFlag = false
    this.subscriptions = new Subscription()
    this.isEmailError = false
    this.userNameConfiguration = this.loginService.userNameConfiguration()
    this.passwordConfiguration = this.loginService.passwordConfiguration()
    this.codeConfiguration = this.loginService.codeConfiguration()
    this.checkBoxConfiguration = this.loginService.ldapCheckBoxConfiguration()
    this.isGoogleAuthVerified = false
    this.orgName = loginStringConstants.EMPTY_STRING
    this.companyLogo = loginStringConstants.EMPTY_STRING
    this.showBrandLogo = false
    this.isLoader = false
    this.subDomain = loginStringConstants.EMPTY_STRING
    this.enforceLoginType = loginStringConstants.EMPTY_STRING
    this.isSsoFlag = false
    this.isShowSSOFlag = false
    this.isIncorrectPasswordFlag = false
    this.isServerError = false
    this.inputForm = new FormGroup({
      [this.userNameConfiguration.model]: new FormControl(),
      [this.passwordConfiguration.model]: new FormControl(),
      [this.codeConfiguration.model]: new FormControl(),
      [this.checkBoxConfiguration.model]: new FormControl(),
    })
    this.isPromiseResolved = false
    this.isSuccessResponse = false
    this.defaultLogoPath = 'images-library/logo.png'
    this.isFailRespnse = false
    this.isLoader = false
    this.isResetScreen = false
    this.validationMessage = ''
    this.forgotPasswordConfiguration = this.loginService.forgotPasswordConfiguration()
    this.inputFormPassword = new FormGroup({
      [this.forgotPasswordConfiguration.model]: new FormControl(),
    })
    if (!this.sharedDataService.getUser()) {
      const performance = 'navigation'
      if (window.performance[performance].type === 1 && !this.sharedDataService.getShowSessionExpiredAlertFlag()) {
        this.sharedDataService.clearLocalStorage()
      }
    }
    this.isGoogleAuthError = false
    this.themeSvc.setLightTheme()
    this.isSsoLogin = false
    this.isAdminLoginDisable = false
  }
  /*** Gets organization and stores the detail including the image, title and login type*/
  getAuthResponse(orgData: IOrganization) {
    this.subDomain = this.loginService.getSubDomain()
    this.orgName = orgData.orgName
    this.companyLogo = orgData.companyLogo2x ? orgData.companyLogo2x : 'assets/img/logo-dark.png'
    this.showBrandLogo = orgData.showBrandLogo
    this.enforceLoginType = orgData.enforceLoginType
    this.isSsoFlag = orgData.sso
    if (orgData.sso) {
      this.isShowSSOFlag = true
      if (this.activatedRoute.snapshot.queryParams.token) {
        this.isAdminLoginDisable = true
        this.samlLogin()
      }
      if (this.activatedRoute.snapshot.queryParams.error_code) {
          this.errorHandling(parseInt(this.activatedRoute.snapshot.queryParams.error_code, 10))
      }
    }
    this.sharedDataService.setOrgLogo(this.companyLogo)
  }
  /*** SSO Login sends http request using login service */
  samlLogin() {
    const body: object = {
      token: this.activatedRoute.snapshot.queryParams.token,
      sessionIndex: this.activatedRoute.snapshot.queryParams.sessionIndex,
    }
    this.isSsoLogin = true
    this.subscriptions.add(this.loginService.googleAuthVerify(body).subscribe(
      dataSsO => {
        this.isSsoLogin = false
        if (!dataSsO.error_code) {
          /*if (dataQR.code === '401' || dataQR.resp === '3') {
            this.err()
          }*/
          if (dataSsO.userType && dataSsO.userType.toLowerCase() === loginStringConstants.EXTERNAL_USER
            && (!dataSsO.companyList || dataSsO.companyList.length === 0)) {
            this.errorMessages = loginErrorConstants.COMPANY_ERROR
            // this.loginService.openLgModal(this.errors)
          } else {
            dataSsO.ssoEnable = true
            this.proccessAfterITBLoginSuccess(dataSsO)
          }
        }
      },
      error => {
        this.isSsoLogin = false
        if (error.code === '405' || error.resp === '2' || error.code === '403' || error.resp === '5' || error.resp === '4') {
          this.errorMessages = loginErrorConstants.ACCOUNT_ACTIVE
          // this.loginService.openLgModal(this.errors)
        }
      }
    ))
  }
  /*** Fetches organization data from the login service */
  getOrgData() {
    this.subscriptions.add(this.loginService.getConfig().subscribe(dataOrg => {
      this.isPromiseResolved = true
      dataOrg.orgName ? this.getAuthResponse(dataOrg) : this.orgError()
    }, error => {
      this.isPromiseResolved = true
    }))
  }
  /*** Displays organization not found error on modal */
  orgError() {
    this.dialog.open(this.content, { width: MODAL_WIDTH_CONSTANTS.SMALL, disableClose: true })
  }
  /*** Displays error on modal in case of failed http request */
  errorModal() {
    this.dialog.open(this.errors, { width: MODAL_WIDTH_CONSTANTS.MEDIUM, disableClose: true })
  }
  /*** Switches screen from login to forget password or vice versa */
  switchScreen() {
    this.isGoogleAuthVerified = false
    this.isEmailError = false
    this.isFailRespnse = false
    this.isSuccessResponse = false
    this.validationMessage = ''

    this.inputForm = new FormGroup({
      [this.userNameConfiguration.model]: new FormControl(),
      [this.passwordConfiguration.model]: new FormControl(),
      [this.codeConfiguration.model]: new FormControl(),
      [this.checkBoxConfiguration.model]: new FormControl(),
    })
    this.inputFormPassword = new FormGroup({
      [this.forgotPasswordConfiguration.model]: new FormControl(),
    })
    this.isResetScreen = !this.isResetScreen
  }
  /*** Calls on load incase user already login then redirect user to dashboard otherwise it shows login screen */
  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.pods) {
      this.isPodsLoginFlag = true
      this.loginService.checkPodsData()
    }
    this.getOrgData()
    if (!this.utilService.isUndefined(this.sharedDataService.getUser())) {
      this.routeToModule()
    }
    if (this.sharedDataService.getShowSessionExpiredAlertFlag() === true) {
      this.dialog.open(this.sessionExpire, { width: MODAL_WIDTH_CONSTANTS.SMALL, disableClose: true })
      this.sharedDataService.setShowSessionExpiredAlertFlag('false')
    }
  }
  /*** Flags handling in case user 2FA enable */
  googleAuthentication(data: IGoogleAuth) {
    this.isGoogleAuthVerified = true
    this.inputForm.get(this.userNameConfiguration.model).disable()
    this.token = data.token
  }
  /*** Verifies QR Code by sending http request using login service */
  verifyQrCode() {
    this.isLoader = true
    this.isGoogleAuthError = false
    const body = {
      code: this.inputForm.controls[this.codeConfiguration.model].value,
      token: this.token,
    }
    this.subscriptions.add(this.loginService.googleAuthVerify(body).subscribe(
      dataQR => {
        this.isLoader = false
        if (!dataQR.error_code) {
          /*if (dataQR.code === '401' || dataQR.resp === '3') {
            this.err()
          }*/
          if (dataQR.userType && dataQR.userType.toLowerCase() === loginStringConstants.EXTERNAL_USER
            && (!dataQR.companyList || dataQR.companyList.length === 0)) {
            this.errorMessages = loginErrorConstants.COMPANY_ERROR
            // this.loginService.openLgModal(this.errors)
          } else {
            this.proccessAfterITBLoginSuccess(dataQR)
          }
        } else {
          this.isGoogleAuthError = true
        }
      },
      error => {
        this.isLoader = false
        this.isGoogleAuthError = true
      }))
  }
  /*** Verifies from which method user wanted to login(ITB, SSO, LDAP) to ITB */
  login() {
    this.isIncorrectPasswordFlag = false
    this.isServerError = false
    const userName = this.inputForm.controls[this.userNameConfiguration.model].value
    const password = this.inputForm.controls[this.passwordConfiguration.model].value
    if (this.isSsoFlag && this.isShowSSOFlag) {
      this.getSamlUrl()
      return
    }
    if (this.loginService.checkRequiredFields(userName, password) !== '') {
      this.validationMessage = this.loginService.checkRequiredFields(userName, password)
      return
    }
    if (this.isGoogleAuthVerified) {
      this.verifyQrCode()
      return
    }
    this.authenticateFromITB()
  }
  /*** Gets Saml URL **/
  getSamlUrl() {
    this.loginService.loginRedirect(this.subDomain).subscribe(data => {
      if (data.url) {
        window.location = data.url
      }
    }, err => {

    })
  }
  /*** Authenticates user by sending http request using login service */
  authenticateFromITB(ldapErrorFlag?: boolean) {
    this.isLoader = true
    const object = {
      subDomain: this.loginService.getSubDomain(),
      password: this.inputForm.controls[this.passwordConfiguration.model].value,
      userName: this.inputForm.controls[this.userNameConfiguration.model].value,
    }
    if (!ldapErrorFlag) {
      if (this.inputForm.controls[this.checkBoxConfiguration.model].value || this.enforceLoginType === 'LDAP') {
        object[`${'ldap'}`] = true
      }
    }
    this.subscriptions.add(this.loginService.itbAuth(object).subscribe(
      data => {
        this.isLoader = false
        this.loginResponse(data)
      },
      error => {
        if (object[`${'ldap'}`]) {
          this.authenticateFromITB(true)
          return
        }
        this.errorHandling(error.error_code)
        this.isLoader = false
      }
    ))
  }

  /***When login is pressed, an error might occur. This function handles those error codes */
  errorHandling(errorCode: number) {
    switch (errorCode) {
      case 40117:
        this.errorMessages = loginErrorConstants.ACCOUNT_ACTIVE
        this.errorModal()
        break
      case 40138:
        this.errorMessages = loginErrorConstants.SSO_ERROR
        this.errorModal()
        break
      case 40120:
        this.errorMessages = loginErrorConstants.ACCOUNT_ACTIVE
        this.errorModal()
        break
      case 40329:
        this.errorMessages = loginErrorConstants.ACCOUNT_ACTIVE
        this.errorModal()
        break
      case 40159:
        this.errorMessages = loginErrorConstants.ACCOUNT_ACTIVE
        this.errorModal()
        break
      case 40107:
        this.errorMessages = !this.isSsoFlag ? loginErrorConstants.ACCOUNT_ACTIVE : loginErrorConstants.SSO_ERROR
        this.errorModal()
        break
      case 40513:
        this.serverError()
        break
      default:
        this.err()
    }
  }
  /*** Checks what the login response is and takes appropriate action (e.g. show 2FA or login to dashboard) */
  loginResponse(data: ILoginResponse) {

    this.firstTimeLogin = data.firstTimeLoginFlag
    if (data['2fa']) {
      this.googleAuthentication(data)
    } else {
      data.roleId = data.role.roleId
      if (data.enforce2fa) {
        data.googleAuthFlag = false
      }
      this.sharedDataService.setUser(data)
      this.proccessAfterITBLoginSuccess(data)
    }
  }
  /*** Login successful - Save required data to local storage for use */
  proccessAfterITBLoginSuccess(data: ILoginResponse) {

    if (data.userType === 'Internal' || data.userType === 'internal' || typeof data.userType === 'undefined') {
      data.roleId = data.role.roleId
      this.sharedDataService.setUser(data)
      this.routeToModule()
    } else {
      if (data.userType && data.userType.toLowerCase() === 'external' &&
        (!data.companyList || data.companyList.length === 0)) {
        this.errorMessages = loginErrorConstants.COMPANY_ERROR
        this.errorModal()
        return
      }
      data.roleId = data.role.roleId
      this.sharedDataService.setUser(data)
      if (this.activatedRoute.snapshot.queryParams.pods) {
        this.loginService.createUrlForPods(this.activatedRoute.snapshot.queryParams.pods)
      } else {
        this.router.navigateByUrl(`/app/company/${data.companyList[0].uuid}`).then(
          routeData => { }).catch(() => {
        })
      }
    }
  }
  /*** Route to dashboard - for dev purposes, if one does not have access to dashboard, then redirect to company dashboard and so on */
  routeToModule() {

    if (this.firstTimeLogin) {
      this.router.navigateByUrl('app/welcome').catch()
      return
    }

    if (this.activatedRoute.snapshot.queryParams.pods) {
      this.loginService.createUrlForPods(this.activatedRoute.snapshot.queryParams.pods)
    } else if (this.sharedDataService.getURL()) {
      window.open(this.sharedDataService.getURL(), '_self')
    } else {
      this.router.navigateByUrl('/app/dashboard').then(
        routeData => { }).catch(() => {
          this.router.navigateByUrl('/app/company/82d28af5-685b-4729-8f8b-453041a37dfa').then(
            routeData => { }).catch(() => {
              this.router.navigateByUrl('/app/adl/discoverers').then(
                routeData => { }).catch(() => {
                })
            })
        })
    }
  }
  /*** Enable fields in case SSO field enable */
  enablePassword() {
    this.isShowSSOFlag = false
  }
  /*** Checks email validations */
  checkEmailValidation(data?: string) {
    this.isEmailError = data ?
      this.loginService.checkEmailValidation(this.inputFormPassword.controls[this.forgotPasswordConfiguration.model].value) :
      this.loginService.checkEmailValidation(this.inputForm.controls[this.userNameConfiguration.model].value)
  }
  /*** Enable error flag in case of error */
  err() {
    this.isIncorrectPasswordFlag = true
  }
   /*** Enable server error flag in case of error */
  serverError() {
    this.isServerError = true
  }
  /*** Close dialog box */
  closeAccountModal() {
    this.loginService.closeModal()
  }
  /*** Send ajax request to reset password */
  reset() {
    this.isSuccessResponse = false
    this.isLoader = true
    this.isFailRespnse = false
    const forgetUsername = this.inputFormPassword.controls[this.forgotPasswordConfiguration.model].value
    if (this.utilService.isUndefined(forgetUsername)) {
      this.isLoader = false
      this.forgetErrorMessage = 'Please enter email address'
      this.isFailRespnse = true
      return
    }
    const body = {
      userEmail: forgetUsername,
      subDomain: this.subDomain,
    }
    this.subscriptions.add(this.loginService.reset(body).subscribe(data => {
      if (!data.error_code && data.success) {
        this.isLoader = false
        this.isSuccessResponse = true
      } else {
        this.isLoader = false
        this.isFailRespnse = true
        this.forgetErrorMessage = 'Email address not found for this account.'
      }
    }, error => {
      this.isLoader = false
      this.isFailRespnse = true
      this.forgetErrorMessage = 'Email address not found for this account.'
    }))
  }

  /*** mg AfterViewInit */
  ngAfterViewInit() {
    this.contentDotSlider(-1)
  }
  /*** Scroll to View and Slider */
  contentDotSlider(elem) {
    this.sliderDots = document.querySelectorAll('.slider-dots button')
    let counter = 0
    if (elem >= 0) {
      counter = elem
      if (this.sliderInterval) {
        this.sliderDots.forEach((item, i) => {
          item.classList.remove('active')
          document.getElementById(`slide-${(i + 1)}`).style.display = 'none'
        })
        clearInterval(this.sliderInterval)
      }
    }
    this.sliderDots[counter].classList.add('active')
    document.getElementById(`slide-${(counter + 1)}`).style.display = 'block'
    this.sliderInterval = setInterval(() => {
      if (counter > 2) { counter = 0 }
      this.sliderDots.forEach((item, i) => {
        item.classList.remove('active')
        document.getElementById(`slide-${(i + 1)}`).style.display = 'none'
      })
      this.sliderDots[counter].classList.add('active')
      document.getElementById(`slide-${(counter + 1)}`).style.display = 'block'
      counter++
    }, 15000)
  }

  changeForgetPassword() {
    this.isFailRespnse = false
  }

  /***Opens the local mail client with the subject: Account not found */
  openSupportLink() {
    const mailText = 'mailto:support@itboost.com+?subject=Account Not Found'
    window.location.href = mailText
  }

  onChangeEvent() {
    if (this.inputForm.valid) {
      this.validationMessage = ''
      this.isIncorrectPasswordFlag = false
    }
  }

  /*** Unsubscribe all subscription when component destroy*/
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
    if (this.sliderInterval) { clearInterval(this.sliderInterval) }
  }
}
