import * as uiInterfaces from '@app/interfaces/ui-components.interface'
import { Action } from '@ngrx/store'

import { IDiscovererSystem } from '../../../interfaces/discoverer-system.interface'
import { IDiscoverers, IIntegrationsList } from '../../../interfaces/intgeration-list.interface'

export const LOAD_DISCOVERERS = '[Discoverer] Load Discoverers'
export const LOAD_DISCOVERERS_FAIL = '[Discoverer] Load Discoverers Fail'
export const LOAD_DISCOVERERS_SUCCESS = '[Discoverer] Load Discoverers Success'
export const SET_CURRENT_DISCOVERER = '[Discoverer] Set Current Discoverer'
export const SET_CURRENT_DISCOVERER_SUCCESS = '[Discoverer] Set Current Discoverer Success'
export const SET_CURRENT_DISCOVERER_FAIL = '[Discoverer] Set Current Discoverer Fail'
export const LOAD_DISCOVERER_AUTHFORMAT = '[Discoverer] Load DiscovererAuthFormat'
export const LOAD_DISCOVERER_AUTHFORMAT_FAIL = '[Discoverer] Load DiscovererAuthFormat Fail'
export const LOAD_DISCOVERER_AUTHFORMAT_SUCCESS = '[Discoverer] Load DiscovererAuthFormat Success'
export const LOAD_DISCOVERERSYSTEMS = '[DiscovererSystem] Load DiscovererSystems'
export const LOAD_DISCOVERERSYSTEMS_FAIL = '[DiscovererSystem] Load DiscovererSystems Fail'
export const LOAD_DISCOVERERSYSTEMS_SUCCESS = '[DiscovererSystem] Load DiscovererSystems Success'
export const SET_CURRENT_DISCOVERERSYSTEM = '[DiscovererSystem] Set Current DiscovererSystem'
export const SET_CURRENT_DISCOVERERSYSTEM_SUCCESS = '[DiscovererSystem] Set Current DiscovererSystem Success'
export const SET_CURRENT_DISCOVERERSYSTEM_FAIL = '[DiscovererSystem] Set Current DiscovererSystem Fail'
export const UPDATE_DISCOVERERSYSTEM = '[DiscovererSystem] Update DiscovererSystem'
export const UPDATE_DISCOVERERSYSTEM_SUCCESS = '[DiscovererSystem] Update DiscovererSystem Success'
export const UPDATE_DISCOVERERSYSTEM_FAIL = '[DiscovererSystem] Update DiscovererSystem Fail'
export const POST_DISCOVERERSYSTEM = '[DiscovererSystem] Post DiscovererSystem'
export const POST_DISCOVERERSYSTEM_SUCCESS = '[DiscovererSystem] Post DiscovererSystem Success'
export const POST_DISCOVERERSYSTEM_FAIL = '[DiscovererSystem] Post DiscovererSystem Fail'
export const VALIDATE_DISCOVERERSYSTEM = '[DiscovererSystem] Validate DiscovererSystem'
export const VALIDATE_DISCOVERERSYSTEM_SUCCESS = '[DiscovererSystem] Validate DiscovererSystem Success'
export const VALIDATE_DISCOVERERSYSTEM_FAIL = '[DiscovererSystem] Validate DiscovererSystem Fail'
export const LOAD_DISCOVERERSYSTEMS_DETAIL = '[DiscovererSystemDetail] Load DiscovererSystemDetails'
export const LOAD_DISCOVERERSYSTEMS_DETAIL_FAIL = '[DiscovererSystemDetail] Load DiscovererSystemDetails Fail'
export const LOAD_DISCOVERERSYSTEMS_DETAIL_SUCCESS = '[DiscovererSystemDetail] Load DiscovererSystemDetails Success'
export const LOAD_DISCOVERERSYSTEMS_REVISIONS = '[DiscovererSystemRevision] Load DiscovererSystemRevisions'
export const LOAD_DISCOVERERSYSTEMS_REVISIONS_FAIL = '[DiscovererSystemRevision] Load DiscovererSystemRevisions Fail'
export const LOAD_DISCOVERERSYSTEMS_REVISIONS_SUCCESS = '[DiscovererSystemRevision] Load DiscovererSystemRevisions Success'
export const LOAD_DISCOVERERS_TEMPLATE = '[DiscovererTemplate] Load DiscovererTemplates'
export const LOAD_DISCOVERERS_TEMPLATE_FAIL = '[DiscovererTemplate] Load DiscovererTemplates Fail'
export const LOAD_DISCOVERERS_TEMPLATE_SUCCESS = '[DiscovererTemplate] Load DiscovererTemplates Success'
export const LOAD_COMPANIES = '[Companies] Load Companies'
export const LOAD_COMPANIES_FAIL = '[Companies] Load Companies Fail'
export const LOAD_COMPANIES_SUCCESS = '[Companies] Load Companies Success'
export const LOAD_HEADERS = '[Headers] Load Headers'
export const LOAD_HEADERS_FAIL = '[Headers] Load Headers Fail'
export const LOAD_HEADERS_SUCCESS = '[Headers] Load Headers Success'

export class LoadDiscoverers implements Action {
  readonly type = LOAD_DISCOVERERS
}

export class LoadDiscoverersFail implements Action {
  readonly type = LOAD_DISCOVERERS_FAIL
  constructor(public payload) { }
}

export class LoadDiscoverersSuccess implements Action {
  readonly type = LOAD_DISCOVERERS_SUCCESS
  constructor(public payload: IDiscoverers[]) { }
}

export class LoadDiscovererSystems implements Action {
  readonly type = LOAD_DISCOVERERSYSTEMS
  constructor(public conditions: string,
              public companyUuid: string,
              public pageNo?: number,
              public pageSize?: number,
              public sortBy?: string,
              public order?: string) { }
}

export class LoadDiscovererSystemsFail implements Action {
  readonly type = LOAD_DISCOVERERSYSTEMS_FAIL
  constructor(public payload) { }
}

export class LoadDiscovererSystemsSuccess implements Action {
  readonly type = LOAD_DISCOVERERSYSTEMS_SUCCESS
  constructor(public payload: IDiscovererSystem[]) { }
}

export class LoadHeaders implements Action {
  readonly type = LOAD_HEADERS
  constructor(public payload: string) { }
}

export class LoadHeadersFail implements Action {
  readonly type = LOAD_HEADERS_FAIL
  constructor(public payload) { }
}

export class LoadHeadersSuccess implements Action {
  readonly type = LOAD_HEADERS_SUCCESS
  constructor(public payload: any) { } // tslint:disable-line
}

export type AdlAction = LoadDiscoverers
  | LoadDiscoverersFail
  | LoadDiscoverersSuccess
  | LoadDiscovererSystems
  | LoadDiscovererSystemsFail
  | LoadDiscovererSystemsSuccess
  | LoadHeaders
  | LoadHeadersFail
  | LoadHeadersSuccess
