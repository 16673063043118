<itb-widget class="sidepull-widgets" [title]="'ASSET STATISTICS'" [footerText]="''"
               [hasContentCenter]='true' [footerWidgetScore]="footerWidgetScore"  [roundButtons]=" "
              [hasBarLoader]='false' [hasContentCenter]='true' (footerClickHandler)="showListModel($event.scoreHeading)"
            [footerSpinner]="configLoader"
  >
  <div  fxLayoutAlign="center center" *ngIf="nodata">
      <itb-empty-state-image class="mb-5 pb-5" [imageClass]="'es-image img-nodata'" [iconClassName]="'btn-innerico save'" [headingClass]="'heading'" [headingText]="'NO DATA FOUND'" ></itb-empty-state-image>
  </div>
  <itb-pie-chart *ngIf="!nodata" [chartOptions]="chartOption"></itb-pie-chart>

</itb-widget>
