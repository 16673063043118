import { AgRendererComponent } from '@ag-grid-community/angular'
import { Component } from '@angular/core'

@Component({
  selector: 'image-cell-renderer',
  templateUrl: './image-cell-renderer.component.html',
  styleUrls: ['./image-cell-renderer.component.scss'],
})
export class ImageCellRendererComponent implements AgRendererComponent {

  params

  agInit(params): void {
    this.params = params
  }

  refresh(params): boolean {
    return false
  }
}
