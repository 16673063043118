import { createFeatureSelector, createSelector } from '@ngrx/store'

import { FORM_SUBMISSION } from '../../modules/configuration/constants/communication-constants'
import * as fromStates from '../states'

export interface CommunicationActionState {
  communications: fromStates.CommunicationActionState
}

export const getCompanyDashboardState = createFeatureSelector<CommunicationActionState>(FORM_SUBMISSION.STORE_COMMUNICATION)

export const selectCommunications = createSelector(getCompanyDashboardState, (state: CommunicationActionState) => state.communications.type)
