import { Injectable } from '@angular/core'
import { IColumnState } from '@app/interfaces/ui-components.interface'
import { ApiService } from '@app/services/api.service'
import { BehaviorSubject, Observable } from 'rxjs'

import { CompanyInfo } from '../../interfaces/company-dashboard'
import { Menu } from '../../interfaces/menu'

import { END_POINTS } from './../../constants/constants'
@Injectable({
  providedIn: 'root',
})
export class CompanyDashboardService {
  companyName: BehaviorSubject<CompanyInfo> = new BehaviorSubject(undefined)
  userMenu: BehaviorSubject<Menu[]> = new BehaviorSubject([])
  company: CompanyInfo
  constructor(private readonly baseService: ApiService) { }

  getMenu(companyId) {
    return this.baseService.get(END_POINTS.GET_MENU(companyId), 'User Menu', true)
  }
  getCountry() {
    return this.baseService.get(END_POINTS.GET_COUNTRIES, '', true)
  }
  getCompanySecondView() {
    return this.baseService.get(END_POINTS.GET_SECOND_VIEW, '', true)
  }

  getCompanyInfo(companyId: string) {
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(`${END_POINTS.GET_COMPANY(companyId)}?fields={"accountManagerId":1,"name":1,"identifier":1,"city":1,"website":1,"prefferedtechId":1,"status":1,"types":1,"state":1,"phoneNumber":1,"tags":1,"zip_code":1,"addressLine2":1, "addressLine1":1,"phoneNumber":1,"country":1,"favourite":1,"syncFlag":1,"id":1,"ITBCompanyId":1,"resource":1,"classification":1,"createdAt":1,"permissions":1,"type":1,"uuid":1}`, '', true)
  }
  updateCompanyInfo(companyId: string, body) {
    return this.baseService.edit(END_POINTS.GET_COMPANY(companyId), body, true)
  }
  patchCompanyInfo(companyId: string, body) {
    return this.baseService.patch(END_POINTS.GET_COMPANY(companyId), body)
  }
  getCompanyStatus() {
    return this.baseService.get(END_POINTS.GET_COMPANY_STATUS, '', true)
  }
  getCompanyTypes() {
    return this.baseService.get(END_POINTS.GET_COMPANY_TYPE, '', true)
  }
  getMembers() {
    return this.baseService.get(END_POINTS.GET_COMPANY_MEMBERS, '', true)
  }
  getClassifications() {
    return this.baseService.get(END_POINTS.GET_COMPANY_CLASSIFFICATION, '', true)
  }
  getFavCompany() {
    return this.baseService.get(END_POINTS.GET_USER_FAVORITE, '', true)
  }
  getWatchList() {
    return this.baseService.get(END_POINTS.GET_USER_WATCHLIST, '', true)
  }

  setfavCompany(body) {
    return this.baseService.edit(END_POINTS.GET_USER_FAVORITE, body)
  }
  setWatchList(body) {
    return this.baseService.edit(END_POINTS.GET_USER_WATCHLIST, body)
  }
  getCompanyContactList(companyId: string) {
    return this.baseService.get(END_POINTS.GET_COMPANY_CONTACT(companyId), '', true)
  }
  updatePrimaryContact(contactId, body, companyId) {
    return this.baseService.edit(`${END_POINTS.UPDATE_CONTACTS(contactId)}?companyId=${companyId}`, body)
  }
  updatePrimaryLocation(locationId, body, companyId) {
    return this.baseService.edit(`${END_POINTS.UPDATE_LOCATION(locationId)}?companyId=${companyId}`, body)
  }
  getCompanyContact(companyId: string) {
    return this.baseService.get(`${END_POINTS.GET_COMPANY_CONTACT(companyId)}?conditions={"primaryContact":true}`, '', true)
  }
  getCompanyLocation(companyId: string) {
    return this.baseService.get(`${END_POINTS.GET_COMPANY_LOCATION(companyId)}?conditions={"primaryAddressFlag":true}`, '', true)
  }

  getNinjasOrManagers(id: string) {
    return this.baseService.get(`${END_POINTS.GET_USERS}?conditions={"uuid":"${id}"}`, '', true)
  }
  getCompanyManagersList() {
    return this.baseService.get(`${END_POINTS.GET_USERS}?fields={"firstName":1,"lastName":1,"uuid":1}`, '', true)
  }

  getCompanyNinjasList() {
    return this.baseService.get(`${END_POINTS.GET_USERS}?fields={"firstName":1,"lastName":1,"uuid":1}`, '', true)
  }

  saveCompanyName(name) {
    this.companyName.next(name)
  }

  getCompanyDashboardColumnState(name: string): Observable<IColumnState[]> {
    return this.baseService.get(END_POINTS.GET_HEADER_STATE(name), '', true)
  }

  getCompanyDashboardSidebarState(name: string) {
    return this.baseService.get(END_POINTS.GET_HEADER_STATE(name), '', true)
  }
  // tslint:disable-next-line: no-any
  saveCompanyDashboardSidebarState(state: boolean, name: string): Observable<any[]> {
    const testState = [{ sidebarState: state }]
    const colState = {
      columnDefs: testState,
    }
    return this.baseService.add(END_POINTS.POST_HEADER_STATE(name), colState)
  }

  saveCompanyDashboardColumnState(state: IColumnState[], name: string): Observable<IColumnState[]> {
    const colState = {
      columnDefs: state,
    }
    return this.baseService.add(END_POINTS.POST_HEADER_STATE(name), colState)
  }
  saveUserMenu(menu: Menu[]) {
    this.userMenu.next(menu)
  }

  getFilterSQPR(model) {
    const sqpr = {}
    for (const key of Object.keys(model)) {
      switch (model[key].type) {
        case 'contains':
          sqpr[key] = { $regex: model[key].filter, $options: 'i' }
          break
        case 'notContains':
          sqpr[key] = { $regex: `^((?!${model[key].filter}).)*$` }
          break
        default:
          sqpr[key] = model[key].filter ? model[key].filter : model[key].dateFrom
      }
    }
    return sqpr
  }
  getSortSQPR(model) {
    if (model.length > 0) {
      return {
        key: model[0].colId,
        order: model[0].sort,
      }
    }
  }

  hasAddPermission(assetName: string, companyId: string) {
    return this.baseService.get(END_POINTS.HAS_ADD_PERMISSION(assetName, companyId), 'Company')
  }

}
