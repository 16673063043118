import { ColumnState } from '@ag-grid-community/core/dist/cjs/columnController/columnController'
import { Injectable } from '@angular/core'
import { IStandardResponse } from '@app/interfaces/standard-response.interface'
import { UtilService } from '@app/modules/core/services/utils.service'
import { SharedDataService } from '@core/services/shared-data.service'
import { knowledgebaseHeaders } from '@projects/documentation/shared/data-grid-header-models/knowledgebase-headers'
import { IRowCount } from '@projects/documentation/shared/interfaces/count.interface'
import { saveAs } from 'file-saver'
import { omit } from 'lodash'
import { BehaviorSubject, Observable } from 'rxjs'

import { IColumnDefs } from '../../../../../src/app/interfaces/column-defs.interface'
import { ApiService } from '../../../../../src/app/services/api.service'
import { IRequestParams } from '../../../shared/interfaces/grid-request-params.interface'
import { STRING_CONSTANTS, URL } from '../constants'
import { ICompanies } from '../interfaces/companies.interface'
import { IKnowledgebaseArticle } from '../interfaces/knowledgebase-article.interface'
import { ITreeNode } from '../interfaces/tree-node.interface'

@Injectable(
  {
    providedIn : 'root',
  }
)
export class KnowledgeBaseService {
  userPermissions: BehaviorSubject<any> = new BehaviorSubject(null) // tslint:disable-line
  columnDefs
  categoryList
  permission
  userPermission
  companyID = new BehaviorSubject<string>('')
  companyName: string
  copiedArticle: IKnowledgebaseArticle
  isShowRefresh: BehaviorSubject<boolean>
  isLoading: BehaviorSubject<boolean>
  treeData: any // tslint:disable-line
  constructor(public baseService: ApiService, public utils: UtilService,
              public sharedDataService: SharedDataService) {
    this.copiedArticle = undefined
    this.isShowRefresh = new  BehaviorSubject<boolean>(false)
    this.isLoading = new  BehaviorSubject<boolean>(false)
  }
  getTableColumnDef(): IColumnDefs[] {
    return knowledgebaseHeaders
  }

  fetchKnowledgebaseList(companyID: string , params: IRequestParams, pageSize: number): Observable<IKnowledgebaseArticle[]> {
    const filterSQPR = this.utils.getFilterSQPR(params.filterModel)
    const sortSQPR = this.utils.getSortSQPR(params.sortModel)
    // tslint:disable-next-line: max-line-length
    let url = `${companyID === '1' ? URL.FETCH_ARTICLES_LIST : URL.COMPANY_FETCH_ARTICLES_LIST(companyID)}?pageNo=${
      params.offset
      }&pageSize=${pageSize}${Object.entries(filterSQPR).length ? `&conditions=${JSON.stringify(filterSQPR)}` : ''}${
      sortSQPR ? `&sortBy=${sortSQPR.key}&order=${sortSQPR.order}` : ''
      }`
    url = this.utils.equalFlag ? `${url}&equalFlag=${this.utils.equalFlag}` : url
    return this.baseService.get(url, 'KnowledgeBase List')
  }

  fetchArticleById(id: string, companyID): Observable<IKnowledgebaseArticle> {
    let url = `${URL.FETCH_ARTICLE_BY_ID}/${id}`
    if (companyID && companyID !== '1')
      url = `${URL.FETCH_ARTICLE_BY_ID}/${id}?companyId=${companyID}`
    return this.baseService.get(url, 'Knowledge Base Article')
  }

  updateArticleById(article: IKnowledgebaseArticle | any, companyId: string, nameChanged = false ): Observable<IKnowledgebaseArticle |any > | undefined {     // tslint:disable-line
    if (!article.id && !article.uuid) return
    const id = article.id ? article.id : article.uuid
    let url = ''

    url = companyId ? `${URL.UPDATE_ARTICLE}/${id}?companyId=${companyId}&nameChanged=${nameChanged}` :
    `${URL.UPDATE_ARTICLE}/${id}?nameChanged=${nameChanged}`
    return this.baseService.edit(
      url,
      omit(article, [
        'updatedAt',
        'companyUuid',
        'lastModifiedDate',
        'ITBRunBookId',
        'imported',
        'ITBLastUpdated',
        'ITBUpdatedBy',
        'id',
        'recordStatus',
        'uuid',
        'orgId',
        'userName',
        'searchTags',
        'linkedItems',
        'linkedItem',
        'createdAt',
        'companyIds',
        'myOptions',
        'operationType',
        'companyId',
        'ITBCompanyId',
        'permission',
      ])
    )
  }

  createArticle(companyID: string, companyName: string, itbCompanyId?: number): Observable<IKnowledgebaseArticle> {
    if (companyID && companyName) {
      this.companyName = companyName
    }
    return (
      this.baseService
        // tslint:disable-next-line: max-line-length
        .add(`${companyID === '1' ? URL.CREATE_ARTICLE : URL.COMPANY_CREATE_ARTICLE(companyID)}`, this.initializeBlankArticle(companyID, itbCompanyId))
    )
  }
  pasteArticle(companyID, article: IKnowledgebaseArticle): Observable<IKnowledgebaseArticle> {
    const url = `${URL.COPY_KB_TO_COMPANIES}/${article.id}?companyId=${companyID}`
    return (
      this.baseService
        // tslint:disable-next-line: max-line-length
        .edit(url)
    )
  }

  saveKnowledgebaseColumnState(state: ColumnState[], companyId: string): Observable<IStandardResponse> {
    const columnDefs = state
    const kbType = companyId ? STRING_CONSTANTS.KNOWLEDGEBASE_TYPE.COMPANY : STRING_CONSTANTS.KNOWLEDGEBASE_TYPE.GLOBAL
    return this.baseService.edit(`${URL.USER_HEADERS}/${kbType}`, { columnDefs })
  }

  resetKnowledgebaseColumnState(companyId: string): Observable<ColumnState[]> {
    const kbType = companyId ? STRING_CONSTANTS.KNOWLEDGEBASE_TYPE.COMPANY : STRING_CONSTANTS.KNOWLEDGEBASE_TYPE.GLOBAL
    return this.baseService.edit(`${URL.USER_HEADERS}/${kbType}`, {})
  }

  getKnowledgebaseColumnState(companyId: string): Observable<ColumnState[]> {
    const kbType = companyId ? STRING_CONSTANTS.KNOWLEDGEBASE_TYPE.COMPANY : STRING_CONSTANTS.KNOWLEDGEBASE_TYPE.GLOBAL
    return this.baseService.get(`${URL.USER_HEADERS}/${kbType}`, 'Knowledgebase Column State')
  }

  fetchRestoreKnowledgebaseList(params, pageSize: number, companyID): Observable<IKnowledgebaseArticle[]> {
    const filterSQPR = this.utils.getFilterSQPR(params.filterModel)
    const sortSQPR = this.utils.getSortSQPR(params.sortModel)
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${companyID === '1' ? URL.FETCH_RESTORE_ARTICLE_LIST : URL.COMPANY_FETCH_RESTORE_ARTICLE_LIST(companyID)}?pageNo=${
      params.offset
      }&pageSize=${pageSize}${Object.entries(filterSQPR).length ? `&conditions=${JSON.stringify(filterSQPR)}` : ''}${
      sortSQPR ? `&sortBy=${JSON.stringify(sortSQPR)}` : ''
      }`,
      'Restore knowledgebase list'
    )
  }

  fetchCompaniesList(pageNo: number): Observable<ICompanies[]> {
    return this.baseService.get(`${URL.FETCH_COMPANIES_LIST}?pageNo=${pageNo}&pageSize=200`, 'Company List')
  }

  fetchCompaniesCount(): Observable<IRowCount> {
    return this.baseService.get(`${URL.FETCH_COMPANIES_COUNT}`, 'Company Count')
  }

  restoreKnowledgebase(rows: IKnowledgebaseArticle[]): Observable<IStandardResponse> {
    return this.baseService.patch(URL.FETCH_RESTORE_ARTICLE_LIST, { articlesId: rows.map(row => row.id) })
  }

  deleteKnowledgebaseArticle(id: string, companyId): Observable<IStandardResponse> {
    let url = ''
    url = companyId ? `${URL.FETCH_ARTICLE_BY_ID}/${id}?companyId=${companyId}` : `${URL.FETCH_ARTICLE_BY_ID}/${id}`
    return this.baseService.delete(url)
  }

  bulkDeleteKnowledgebase(ids: string[], companyId): Observable<IStandardResponse> {
    let url = ''
    url = companyId ? `${URL.FETCH_ARTICLES_LIST}?companyId=${companyId}` : `${URL.FETCH_ARTICLES_LIST}`
    return this.baseService.delete(url, { articlesId: ids })
  }

  copyKbArticleToCompanies(articleID, companies: ICompanies[]): Observable<IStandardResponse> {
    return this.baseService.add(`${URL.COPY_KB_TO_COMPANIES}/${articleID}`, { companyIdsArray: companies.map(company => company.uuid) })
  }

  fetchHierarchy(companyID): Observable<ITreeNode[]> {
    return this.baseService.get(
      `${companyID === '1' ? URL.HIERARCHY_STRUCTURE : URL.COMPANY_HIERARCHY_STRUCTURE(companyID)}`,
      'Fetch Hierarchy'
    )
  }

  // tslint:disable-next-line: no-any
  getKnowledgebaseArticlesCount(filterModel: any, companyID): Observable<IRowCount> {
    const filterSQPR = this.utils.getFilterSQPR(filterModel)
    const equalFlag = this.utils.equalFlag
    // tslint:disable-next-line: max-line-length
    let url = `${companyID === '1' ? URL.GLOBAL_ARTICLES_COUNT : URL.COMPANY_ARTICLES_COUNT(companyID)}${
      Object.entries(filterSQPR).length ? `?conditions=${JSON.stringify(filterSQPR)}` : ''
      }`
    url = equalFlag ? `${url}&equalFlag=${equalFlag}` : url
    return this.baseService.get(url, 'Knowledgebase Articles Count')
  }

  // tslint:disable-next-line: no-any
  getDeletedKnowledgebaseArticlesCount(filterModel: any, companyID) {
    const filterSQPR = this.utils.getFilterSQPR(filterModel)
    return (
      this.baseService
        // tslint:disable-next-line: max-line-length
        .get(
          `${companyID === '1' ? URL.GLOBAL_RESTORE_ARTICLES_COUNT : URL.COMPANY_RESTORE_ARTICLES_COUNT(companyID)}${
          Object.entries(filterSQPR).length ? `?conditions=${JSON.stringify(filterSQPR)}` : ''
          }`,
          'Knowledgebase Articles Count'
        )
    )
  }

  kbImageUpload(articleID: string, file: File, companyID: string , itbCompanyId?: number) {
    if (!companyID) companyID = '1'
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${
      companyID === '1' ? URL.KB_IMAGE_UPLOAD(articleID, file.name) :
      URL.COMPANY_KB_IMAGE_UPLOAD(articleID, file.name, companyID, itbCompanyId)
      }?mimeType=${file.type}&subDomain=${window.location.host.split('.')[0]}`,
      'Image upload'
    )
  }
  // tslint:disable-next-line: no-any
  updateHierarchy(hierarchy: any, companyID): Observable<ITreeNode[]> {
    companyID = companyID ? companyID :  this.companyID.value
     // Dynamic object for tree structure
    // tslint:disable-next-line: no-any
    let body: any = { kbFolder: hierarchy }
    if (!companyID || companyID === '1') {
      companyID = '1'
      body = { kbFolderGlobal: hierarchy }
    }

    return this.baseService.edit(`${companyID === '1' ? URL.HIERARCHY_STRUCTURE : URL.COMPANY_HIERARCHY_STRUCTURE(companyID)}`, body)
  }

  // tslint:disable-next-line: no-any
  exportFileDoc(kbPref: any, id: string): Observable<any> {
    const pref = omit(kbPref, ['orgLogo',  'cName', 'name'])
    return this.baseService.add(`${URL.EXPORT_KB_DOC_FILE}/${id}`, { KbPref: pref })
  }

  // tslint:disable-next-line: no-any
  exportFilePDF(kbPref: any, id: string): Observable<any> {
    const pref = omit(kbPref, ['orgLogo', 'cName', 'name'])
    return this.baseService.add(`${URL.EXPORT_KB_PDF_FILE}/${id}`, { KbPref: pref , modal: true, width: 186, height: 52 })
  }

  uploadFile(file: File[], companyID) {
    const files = file.map(f => ({ name: f.name, mimeType: f.type }))
    return this.baseService.add(`${companyID === '1' ? URL.KB_IMPORT : URL.COMPANY_KB_IMPORT(companyID)}`, { files })
  }

  downLoadFile(blobData: Blob, name: string, type: string, extension: string): void {
    const blob = new Blob([blobData], { type })
    saveAs(blob, `${name}.${extension}`)
  }

  /*** initialize blank Article Object */
  initializeBlankArticle(companyID: string, itbCompanyId?: number) {
    const article: IKnowledgebaseArticle = {
      ITBRunBookName: `Untitled - ${new Date().getTime()}`,
      activeFlag: true,
      favourite: false,
      newEditor: false,
      userPermission: [],
      url: '',
      tags: [],
      filesName: [],
      ITBRunBookStatus: 'private',
      recordStatus: 'complete',
      fileContent: '<p></p>',
      company: { uuid: '', name: 'global' },
    }
    if (companyID !== '1') {
      article.companyName = this.companyName.toString()
      article.ITBCompanyId = itbCompanyId
      article.company.uuid = companyID
      article.company.name = this.companyName.toString()
      article.company.ITBCompanyId = itbCompanyId
    } else {
      delete article.company
    }
    return article
  }
  getCompany(uuid) {
    const n = { name: 1 , permissions : 1 , ITBCompanyId : 1}
    const url = `${URL.GET_COMPANY}${uuid}?fields=${JSON.stringify(n)}`
    return this.baseService.get(url, 'Company ')
  }

  treeViewFlag(url: string) {
    return this.baseService.get(url, 'Get kb tree flag')
  }

  updateTreeFlag(object) {
    const url = 'users'
    return this.baseService.patch(url, object)
  }

  setUserPermissions(params) {
    this.userPermission = params
  }
  getUserPermissions() {
    return this.userPermission
  }
  s3ImageUpload(url, file: File) {
    return this.baseService.s3ImageUploadSignedUrl(url, file)
  }

  getUserRolesConfigurations() {
    const categoriesList = this.sharedDataService.geCategories()
    this.categoryList = categoriesList
    const userType = JSON.parse(localStorage.getItem('user')).userType
    if (categoriesList && userType !== 'external') {
      this.categoryList.forEach(categoryItem => {
        if (categoryItem.name === 'Documentation') {
          this.permission = categoryItem.articles ? categoryItem.articles : ['get', 'post', 'put', 'delete']
        }
      })
    } else {
      this.permission = ['get']
    }
    if (this.permission && this.permission.length > 0) {
      this.userPermissions.next({
        isRead: this.permission.indexOf('get') > -1,
        isEdit: this.permission.indexOf('put') > -1,
        isDelete: this.permission.indexOf('delete') > -1,
        isCreate: this.permission.indexOf('post') > -1,
      })
      return this.userPermissions.asObservable()
    }
    return this.userPermissions.asObservable()
  }
  hasAddPermission(assetName: string, companyId: string) {
    return this.baseService.get(URL.HAS_ADD_PERMISSION(assetName, companyId), 'Company')
  }
}
