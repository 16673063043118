import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { UiComponentsModule } from '@app/ui-components.module'

import { NavbarComponent } from './navbar.component'

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    CommonModule, UiComponentsModule,
  ],
  exports: [NavbarComponent],
})
export class NavBarModule { }
