export const URL = {
  FETCH_TABLE_COLUMN_DEFS: 'articles/headers',
  FETCH_ARTICLES_LIST: 'articles/global',
  FETCH_COMPANIES_LIST: 'companies',
  FETCH_COMPANIES_COUNT: 'companies/count',
  FETCH_RESTORE_ARTICLE_LIST: 'articles/restore',
  GLOBAL_ARTICLES_COUNT: 'articles/global/count',
  GLOBAL_RESTORE_ARTICLES_COUNT: 'articles/restore/count',
  CREATE_ARTICLE: 'articles/global',
  UPDATE_ARTICLE: 'articles',
  KB_IMAGE_UPLOAD: (articleID: string, imageName: string) => `users/uploads/s3/global/knowledgebaseImages/${articleID}/${imageName}`,
  FETCH_ARTICLE_BY_ID: 'articles',
  EXPORT_KB_PDF_FILE: 'articles/export/pdf',
  EXPORT_KB_DOC_FILE: 'articles/export/doc',
  HIERARCHY_STRUCTURE: 'articles/globalOrganizationKB',
  USER_HEADERS: 'dashboard/user/headers',
  KNOWLEDGE_BASE_UPLOAD_FILE: 'uploadFile',
  GET_COMPANY: 'companies/',
  FETCH_KNOWLEDGEBASE_HIERARCHY_TABLE_HEADERS: 'hierarchy-table/headers',
  GET_COMPANIES_LIST: 'companies?pageNo=0&pageSize=200',
  COPY_KB_TO_COMPANIES: 'articles/copy',
  KB_IMPORT: 'articles/kbImport',
  HAS_ADD_PERMISSION: (assetName: string, companyId: string) =>
  `users/permissions/${assetName}?companyId=${companyId}`,
  COMPANY_FETCH_ARTICLES_LIST: id => `articles/company/${id}`,
  COMPANY_FETCH_RESTORE_ARTICLE_LIST: id => `articles/company/${id}/restore`,
  COMPANY_ARTICLES_COUNT: id => `articles/company/${id}/count`,
  COMPANY_RESTORE_ARTICLES_COUNT: id => `articles/company/${id}/restore/count`,
  COMPANY_HIERARCHY_STRUCTURE: id => `articles/companyOrganizationKB/${id}`,
  COMPANY_CREATE_ARTICLE: id => `articles/company/${id}`,
  COMPANY_FETCH_ARTICLE: id => 'articles',
  COMPANY_KB_IMAGE_UPLOAD: (articleID: string, imageName: string, id,
                            itbCompnyId: number) => `users/uploads/s3/${itbCompnyId}/knowledgebaseImages/${articleID}/${imageName}`,
  COMPANY_KB_IMPORT: id => `articles/kbImport/${id}`,
  COMPANY_KB_TREE_VIEW_FLAG: 'users/metadata/kbTreeViewFlagCompany',
  GLOBAL_KB_TREE_VIEW_FLAG: 'users/metadata/kbTreeViewFlagGlobal',
}

export const STRING_CONSTANTS = {
  KB_TREE_DEPTH_NOTIFICATION: 'Tree depth can only be increased to 3',
  KB_TREE_DEPTH_TITLE: 'INVALID TREE DEPTH',
  SERVER_SIDE: 'serverSide',
  CLIENT_SIDE: 'clientSide',
  UPDATE_CALL_TIME: 30000,
  DELETE : 'delete',

  // tooltips
  TOOLTIP_TREE_VIEW: 'Tree View',
  TOOLTIP_LIST_VIEW: 'List View',

  KNOWLEDGEBASE_TYPE: {
    GLOBAL: 'globalKb',
    COMPANY: 'companyKb',
  },

  // classnames
  CLASSNAME_LIGHT: 'light',
  CLASSNAME_DARK: 'dark',

  CLASSNAME_DEFAULT: 'default',
  CLASSNAME_PRIMARY: 'primary',

  CLASSNAME_BTN_ICON: 'btn-icon',

  // text
  TEXT_CONFIRM: 'Confirm',
  TEXT_CANCEL: 'Cancel',

  // url path names
  URL_ADD: 'add',
  URL_EDIT: 'edit',

  PAGE_SIZE: 20,
  // text
  TEXT_TITLE: 'title',
  TEXT_PAGE: 'page',
  TEXT_LOGO: 'logo',
  // icon names
  ICON_NAME_TREE_VIEW: 'treeview',
  ICON_NAME_CANCEL: 'cross',
  ICON_NAME_TICK: 'tick',
  ICON_PRIVATE: 'private',
  ICON_PUBLIC: 'public',
  ICON_HEART_FILLED: 'heart-filled',
  ICON_HEART_LINE: 'heart-line',
  ICON_FILE_ACTIVE: 'file-active',
  ICON_FILE_INACTIVE: 'file-disabled',
  ICON_HIERARCHY: 'hierarchy',
  ICON_TAGS: 'tag',

  // button names
  BUTTON_TREE_LIST_TOGGLER: 'tree_list_toggler_button',
  BUTTON_ICON_TAGS: 'tags',
  BUTTON_ICON_HEART: 'heart',
  BUTTON_ICON_PRIVATE: 'private',
  BUTTON_ICON_FILE: 'file',
  BUTTON_ICON_HIERARCHY: 'hierarchy',

  BUTTON_CANCEL: 'cancel',
  BUTTON_DISCARD: 'discard',
  BUTTON_CONFIRM: 'confirm',

  // route names
  GLOBAL_DASHBOARD: 'Global Dashboard',
  KB_ARTICLES: 'Knowledgebase',
  KB_GLOBAL_ARTICLES: 'Global Dashboard',
  KB_ARTICLE_ADD: 'Add Article',
  KB_ARTICLE_EDIT: 'Edit Article',
  KB_ARTICLE_VIEW: 'View Article',
  KB_ARTICLE_UPDATE: 'Edit Article',

  // store features
  KNOWLEDGEBASE: 'knowledgebase',

  // Button Names
  DELETE_BUTTON: 'deleteButton',
  BACK_BUTTON: 'backButton',

  // modal confirmation messages
  MODAL_WIDTH: 600,
  MODAL_SUCCESS: 'success',
  MODAL_FAIL: 'fail',
  DELETE_CONFIRMATION_HEADING: 'DELETE ARTICLE',
  DELETE_BULK_CONFIRMATION_HEADING: 'DELETE ARTICLEs',
  DELETE_CONFIRMATION: 'Are you sure you want to delete: ',

  DELETE_SUCCESS_TITLE: 'DELETED',
  DELETE_SUCCESS_MAIN_MESSAGE: 'Article Deleted',
  DELETE_SUCCESS_MAIN_MESSAGE_BULK: 'Articles Deleted',
  SUCCESS_MESSAGE: 'Success',

  DELETE_FAIL_TITLE: 'DELETE FAILED',
  DELETE_FAIL_MAIN_MESSAGE: 'An error occurred while deleting',
  FAIL_MESSAGE: 'Fail',

  COPIED_SUCCESS_TITLE: 'Article copied to clipboard',
  COPIED_SUCCESS_MAIN_MESSAGE: 'Saved!',

  PASTE_SUCCESS_TITLE: 'Article added successfully',

  KB_COPY_SUCESS_TITLE: 'Successfully copied to companies',
  KB_COPY_FAIL_TITLE: 'COPY FAILED',
  KB_COPY_SUCCESS_MAIN_MESSAGE: 'Saved!',
  KB_COPY_FAIL_MAIN_MESSAGE: 'An error occurred while copying',
  KB_SNACKBAR_TIMEOUT: 1000,
  CLOSE: 'Close',

  KB_ARTICLE_UPDATE_SUCCESS_TITLE: 'Article Updated',
  KB_ARTICLE_ADD_SUCCESS_TITLE: 'Article Added',
  KB_ARTICLE_UPDATE_FAIL_TITLE: 'Update Failed',
  KB_ARTICLE_UPDATE_SAME_TITLE: 'An article with this name already exists. Please enter a different name.',
  KB_ARTICLE_UPDATE_SUCCESSFUL: 'SAVED!',
  KB_ARTICLE_UPDATE_FAIL: 'FAILED',

  KB_ARTICLE_EMPTYCONTENT: 'Unable to add empty data',

  KB_IMPORT_FAIL_TITLE: 'IMPORT FAILED',
  KB_IMPORT_FILE_MAIN_MESSAGE: 'Import of files failed',

  KB_RESTORE_SUCCESS_TITLE: 'Article: $name successfully restored.',
  KB_RESTORE_SUCCESS_TITLE_MULTIPLE: 'Articles successfully restored.',
  KB_RESTORE_SUCCESS_MAIN_MESSAGE: 'Restored!',
  KB_RESTORE_FAIL_TITLE: 'RESTORE FAILED',
  KB_RESTORE_FAIL_MAIN_MESSAGE: 'An error occurred while restoring',

  KB_DISCARD_CHANGES_TITLE: 'UNSAVED CHANGES',
  KB_DISCARD_CHANGES_BODY: 'Are you sure you want to discard changes?',

  // kb tree
  TREE_FRAMEWORK_TYPE_ACTIVE: 'active',
  TREE_FRAMEWORK_TYPE_DATE: 'date',
  TREE_FRAMEWORK_TYPE_FAVORITE: 'favorite',
  TREE_FRAMEWORK_TYPE_STATUS: 'status',
  TREE_FRAMEWORK_TYPE_FILE: 'file',
  DEFAULT_DATAGRID_CELL_RENDERER_FRAMEWORK: 'cellRendererFramework',
  KB_TREE_FOLDER_DELETE_TITLE: 'DELETE FOLDER',
  // tslint:disable-next-line: max-line-length
  KB_TREE_FOLDER_DELETE_MESSAGE: 'Are you sure you want to delete this folder from the hierarchy? All articles inside this folder will be deleted as well.',
  KB_TREE_FILE_DELETE_TITLE: 'DELETE FILE',
  KB_TREE_FILE_DELETE_MESSAGE: 'Are you sure you want to delete the selected article?',
  KB_TREE_TYPE_FOLDER: 'folder',
  KB_TREE_TYPE_FILE: 'file',
  KB_TREE_NODE_UNCATEGORIZED: 'Uncategorized',
  KB_TREE_NEW_FOLDER: 'New Folder',

  KB_TREE_HIERARCHY_UPDATED: 'Successfuly Saved Changes!',
  KB_COMPY_TO_COMPANIES: 'You can copy to a maximum of 10 companies at one time',
  KB_TREE_HIERARCHY_UPDATE_FAILED: 'An error occurred while saving',
  KB_TREE_INVALID_MOVE: '',
  OK: 'OK',

  HEADER_FIELD_ACTIONS: 'actions',
  HEADER_FIELD_CREATED_AT: 'createdAt',
  HEADER_FIELD_LAST_UPDATED_BY: 'ITBLastUpdated',
  HEADER_FIELD_NAME: 'ITBRunBookName',
  HEADER_FIELD_DELETED_BY: 'deletedBy',
  HEADER_FIELD_DELETED_AT: 'deletedAt',

  MODAL_SIZE_SMALL: '50%',
  MODAL_MEDIUM_SIZE: '75%',
  MODAL_LARGE_SIZE: '95%',

  PRINT_VIEW_HEIGHT: '700',
  PRINT_VIEW_WIDTH: '700',

  KB_NO_ROWS_OVERLAY_HEADING: 'No articles found',
  KB_NO_ROWS_OVERLAY_SUB_HEADING: 'Add an article to get started',
  KB_NO_ROWS_OVERLAY_IMAGE: 'img-addnew',

  COPIED_TO_CLIPBOARD_SUCCESS: 'Successfuly Copied To Clipboard',
  COPIED_TO_CLIPBOARD_FAIL: 'Failed Copying To Clipboard',
  PLACEMENT_OPTIONS: [
    { id: 0, label: 'Header - Left', isSelected: false },
    { id: 1, label: 'Header - Right', isSelected: false },
    { id: 2, label: 'Header - Center', isSelected: false },
    { id: 3, label: 'Footer - Left', isSelected: false },
    { id: 4, label: 'Footer - Right', isSelected: false },
    { id: 5, label: 'Footer - Center', isSelected: false },
  ],
  FONT_FAMILY: ['Times New Roman', 'Arial', 'Calibri', 'Tahoma', 'Verdana', 'Georgia'],
  FONT_SIZE: [
    { label: 'Normal', value: 'Normal', class: '' },
    { label: 'Heading 1', value: 'Heading 1', class: 'heading1' },
    { label: 'Heading 2', value: 'Heading 2', class: 'heading2' },
    { label: 'Heading 3', value: 'Heading 3', class: 'heading3' },
  ],
  DROPDOWN_TITLES: ['logo', 'title', 'page'],
}

export const NOTIFICATION_CONSTANTS = {
  MODE : 'success',
  TEXT_FILE_UPLOAD: 'File Upload',
  TEXT_FILE_UPLOAD_MESSAGE: 'Your files are uploaded but will take up to 30 minutes to show up in the knowledgebase.',
}

export const ROUTER_LINKS_CONSTANTS = {
  KNOWLEDGEBASE_ADD: 'add',
  KNOWLEDGEBASE_EDIT: 'edit',
  KNOWLEDGEBASE_VIEW: 'view',
  KNOWLEDGE_TREE: 'tree',
  KNOWLEDGEBASE_LIST: 'list',
  KNOWLEDGE_BASE_2_BACK: './list',
  KNOWLEDGE_BASE_1_BACK: './knowledgebase/list',
  KNOWLEDGE_BASE_COMPANY_URL: '/app/company',
  KNOWLEDGE_COMPANY_LIST: 'documents/knowledgebase/list',
  KNOWLEDGE_GLOBAL_LIST: 'documentation/knowledgebase/list',
}
export const NAVIGATIONS = {
  PERMISSION_MATCH: {
    ['runbook']: 'Runbooks',
    ['knowledgebase']: 'Knowledge Base',
},
}
export const KBEXPORTIMAGE = {
    HIEGHT: 351,
    WIDTH: 624,
}
