import { DatePipe } from '@angular/common'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { IColumnState, IHeaders } from '@app/interfaces/ui-components.interface'
import { SharedService } from '@app/services/shared.service'
import { stringConstants } from '@projects/ag-grid-customized/src/app/constants/constants'
import { GirdActionService } from '@projects/ag-grid-customized/src/app/data-grid/services/grid-actions.service'
import { Subscription } from 'rxjs'

import { MODAL_WIDTH_CONSTANTS } from '../../../../../../../../src/app/modules/core/constants/constants'

import { dashboardContants } from './../../../company-dashboard/constants/constants'
import { Domain } from './../../../company-dashboard/interfaces/asset-warranty'
import { WidgetType } from './../../../company-dashboard/interfaces/menu'
import { RoundButton, Select } from './../../../company-dashboard/interfaces/ui-library'
import { CompanyDashboardService } from './../../../company-dashboard/services/companyDashboard/company-dashboard.service'
import { AssetWarrantyService } from './../../../company-dashboard/services/companyWarranty/asset-warranty.service'
import { config } from './../../constants/charts-config'
@Component({
  selector: 'app-company-pods-domain',
  templateUrl: './pods-domain.component.html',
  styleUrls: ['./pods-domain.component.scss'],
  providers: [GirdActionService],
})
export class PodsDomainComponent implements OnInit {
  @Input() companyId: string
  @ViewChild('trackerLists', { static: true }) modal
  @Input() widgetData: WidgetType
  @Output() contentReady = new EventEmitter()
  isDomain: boolean
  isLoading: boolean
  buttonClass
  subscriptions: Subscription
  loading: boolean
  roundButtonwithCross
  wrapperClass: string
  allAssets: Domain[]
  roundButton: RoundButton[]
  tabs: Array<{ label: string }>
  filter: boolean
  headername: string
  domainColumnDefs: IHeaders[]
  sslColumnDefs: IHeaders[]
  dropDownArray: string[]
  defaultColDef: {}
  options: {}
  isEmpty: boolean
  domainRowData
  sslRowData
  userDomainColumnState: IColumnState[]
  userDomainFilterColumnState: IColumnState[]
  userSslColumnState: IColumnState[]
  isDomainInitialRender: boolean
  isSslInitialRender: boolean
  isDomainEmpty: boolean
  isSslEmpty: boolean
  filterName: string
  isFilterEmpty: boolean
  isDomainColLoaded: boolean
  isSslColLoaded: boolean
  isDomainLoading: boolean
  isSslLoading: boolean
  isFilterColLoaded: boolean
  isFilterLoading: boolean
  isSslFirst: boolean
  isDomainFirst: boolean
  dialogRef
  pieChartData
  isDomainError: boolean
  isSslError: boolean
  isFilterError: boolean
  isError: boolean
  sslCompleteCount: number
  domainCompleteCount: number
  constructor(
    private readonly dialog: MatDialog,
    private readonly assetService: AssetWarrantyService,
    private readonly companyService: CompanyDashboardService,
    private readonly sharedService: SharedService,
    private readonly gridActionsService: GirdActionService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.domainRowData = []
    this.isDomainError = false
    this.isError = false
    this.isSslError = false
    this.isFilterError = false
    this.sslRowData = []
    this.isEmpty = false
    this.isDomainEmpty = false
    this.isSslEmpty = false
    this.isFilterEmpty = false
    this.isDomainInitialRender = true
    this.isSslInitialRender = true
    this.subscriptions = new Subscription()
    this.roundButton = []
    this.isLoading = true
    this.roundButton = []
    this.wrapperClass = ''
    this.buttonClass = 'expand'
    this.tabs = [{ label: 'DOMAIN' }, { label: 'SSL' }]
    this.domainCompleteCount = 0
    this.sslCompleteCount = 0
    this.dropDownArray = [
      dashboardContants.FILTER_EXPIRED,
      dashboardContants.FILTER_SOON,
      dashboardContants.FILTER_ACTIVE,
      dashboardContants.FILTER_UNKNOWN,
    ]
  }

  ngOnInit() {
    this.getDomain()
  }

  tabsShift(event) {
    event.index === 1 ? (this.isDomain = true) : (this.isDomain = false)
  }
  getDomain() {
    this.subscriptions.add(
      this.assetService.getCompanyDomainCount(this.companyId).subscribe(
        res => {
          this.contentReady.emit()
          this.isLoading = false
          let zeroValue = true
          res.forEach(ele => {
            if (ele.value !== 0) zeroValue = false
          })
          if ((Array.isArray(res) && res.length === 0) || zeroValue) {
            this.isEmpty = true
            return
          }
          this.pieChartData = res
          this.roundButton = [
            {
              tooltipText: 'Domain & SSL List',
              className: dashboardContants.EDIT_BUTTON_CLASS,
              iconClassName: dashboardContants.EDIT_ICON_CLASSNAME,
              roundButtonName: dashboardContants.EDIT_BUTTON_NAME,
            },
          ]
          this.roundButtonwithCross = [
            {
              tooltipText: 'Close List',
              className: dashboardContants.EDIT_BUTTON_CLASS,
              iconClassName: 'cross',
              roundButtonName: dashboardContants.EDIT_BUTTON_NAME,
            },
          ]
          res.forEach(el => {
            if (el.name === 'Expired') this.pieChartData[0] = el
            if (el.name === 'Soon') this.pieChartData[1] = el
            if (el.name === 'Active') this.pieChartData[2] = el
            if (el.name === 'Unknown') this.pieChartData[3] = el
          })
          this.options = config.chartConfig(this.pieChartData, 'Domain & SSL Expiry')
        },
        error => {
          this.isEmpty = false
          this.isError = true
          this.isLoading = false
          this.contentReady.emit()
        }
      )
    )
  }
  listTrackers() {
    this.wrapperClass = (this.wrapperClass === '') ? 'slideUp' : ''
    this.headername = 'DOMAIN & SSL DETAILS'
    this.filter = true
    this.isSslFirst = true
    this.isDomainFirst = true
    this.isDomainColLoaded = false
    this.isSslColLoaded = false
    this.isSslLoading = true
    this.isDomainLoading = true
    this.defaultColDef = {
      filter: dashboardContants.AG_GRID_FILTER,
    }
    this.domainColumnDefs = this.assetService.getCompanyDomainColDefs(this.companyId)
    // tslint:disable-next-line: ban-comma-operator
    this.domainColumnDefs[0] = {
      headerName: 'NAME', field: 'domainName',
      minWidth: 180, filter: 'agTextColumnFilter',
      suppressColumnsToolPanel: true, lockPosition: true,
      cellRenderer: name =>
        `<a  target="_blank" href=" /app/company/${this.companyId}/domains/view/${name.data.uuid}">${name.value}</a>`,
    },
      this.subscriptions.add(
        this.companyService.getCompanyDashboardColumnState('domain').subscribe(
          response => {
            this.isDomainLoading = false
            this.isDomainColLoaded = true
            if (response.length) {
              this.userDomainColumnState = response
            }
            this.isDomainEmpty = false
          },
          error => { },
          () => { })
      )

    this.sslColumnDefs = this.assetService.getCompanySslColDefs(this.companyId)
    // tslint:disable-next-line: ban-comma-operator
    this.sslColumnDefs[0] = {
      headerName: 'NAME', field: 'website',
      minWidth: 180, filter: 'agTextColumnFilter',
      suppressColumnsToolPanel: true, lockPosition: true,
      cellRenderer: name =>
        `<a  target="_blank" href=" /app/company/${this.companyId}/ssls/view/${name.data.uuid}">${name.value}</a>`,
    },
      this.subscriptions.add(
        this.companyService.getCompanyDashboardColumnState('ssl').subscribe(
          response => {
            this.isSslLoading = false
            this.isSslColLoaded = true
            if (response.length) {
              this.userSslColumnState = response
            }
            this.isSslEmpty = false
          },
          error => { },
          () => { }
        )
      )
    this.subscriptions.add(this.gridActionsService.action.subscribe(action => this.actionClicked(action)))
  }

  actionClicked = action => {
    switch (action.actionType) {
      case stringConstants.DEFAULT_DATAGRID_NAVIGATE_TO_VIEW:
        if (action.data.domainName) {
          window.open(`app/company/${this.companyId}/domains/view/${action.data.uuid}`, '_blank')
        } else {
          window.open(`app/company/${this.companyId}/ssls/view/${action.data.uuid}`, '_blank')
        }
        break
      default:
        return
    }
  }
  openModal(event, value) {
    if (value === 0) {
      return
    }
    this.wrapperClass = (this.wrapperClass === '') ? 'slideUp' : ''
    this.filter = false
    this.isDomainColLoaded = false
    this.isFilterColLoaded = false
    this.isFilterLoading = true
    this.isDomainFirst = true
    this.defaultColDef = {
      filter: dashboardContants.AG_GRID_FILTER,
    }
    this.domainColumnDefs = [
      {
        headerName: 'NAME', field: 'assetName', minWidth: 180,
        suppressColumnsToolPanel: true, lockPosition: true,
        cellRenderer: name =>
          name.data.type === 'SSL' ?
            `<a  target="_blank" href="/app/company/${this.companyId}/ssls/view/${name.data.uuid}">${name.value}</a>` :
            `<a  target="_blank" href=" /app/company/${this.companyId}/domains/view/${name.data.uuid}">${name.value}</a>`,
      },
      { headerName: 'TYPE', field: 'type' },
      {
        headerName: 'EXPIRY DATE', field: 'date',
      },
      {
        headerName: 'REMAINING DAYS',
        field: 'date',
        // tslint:disable-next-line: object-literal-shorthand
        valueFormatter: function(params) {
          let ticketAge
          ticketAge = params.data.expiration
          return ticketAge
        },
      },
    ]
    this.headername = event
    this.filterName = event
    this.loading = false
    this.subscriptions.add(
      this.companyService.getCompanyDashboardColumnState('filterdomain').subscribe(
        response => {
          this.isFilterLoading = false
          this.isFilterColLoaded = true
          if (response.length) {
            this.userDomainFilterColumnState = response
          }
          this.isFilterEmpty = false
        },
        error => { },
        () => { }
      )
    )
    this.subscriptions.add(
      this.assetService.getFilterDomainDetails(this.filterName, this.companyId, '').subscribe(res => {
        res.forEach((element, index) => {
          res[index].date = new DatePipe('en-US').transform(element.date, 'dd/MM/yyyy')
          if (this.filterName.toLowerCase() === 'unknown') {
            res[index].remainingDays = '-'
            res[index].expiration = '-'
          }
        })
        this.domainRowData = res
        this.isFilterEmpty = (this.domainRowData.length === 0 && this.isDomainFirst) ? true : false
        this.isFilterColLoaded = (this.domainRowData.length === 0 && this.isDomainFirst) ? false : true
        this.isDomainFirst = false
      }, error => {
        this.isFilterError = true
        this.isFilterEmpty = false
        this.isFilterColLoaded = false
        this.isDomainFirst = false
      })
    )
  }
  domainColumnStateChanged(state: IColumnState[]) {
    state.forEach((col, i) => {
      col[`${'position'}`] = i
    })
    this.companyService.saveCompanyDashboardColumnState(state, 'domain').subscribe(
      response => { },
      error => { },
      () => { }
    )
  }
  sslColumnStateChanged(state: IColumnState[]) {
    state.forEach((col, i) => {
      col[`${'position'}`] = i
    })
    this.companyService.saveCompanyDashboardColumnState(state, 'ssl').subscribe(
      response => { },
      error => { },
      () => { }
    )
  }
  filterDomainColumnStateChanged(state: IColumnState[]) {
    state.forEach((col, i) => {
      col[`${'position'}`] = i
    })
    this.companyService.saveCompanyDashboardColumnState(state, 'filterdomain').subscribe(
      response => { },
      error => { },
      () => { }
    )
  }
  getDomainRows(params: Params) {
    Object.keys(params.request.filterModel).length || Object.keys(params.request.sortModel).length
      ? this.gridActionsService.filterChanged$.next(true)
      : this.gridActionsService.filterChanged$.next(false)
    let pageNo: number
    const { startRow, endRow } = params.request
    pageNo = this.setOffset(startRow)
    this.subscriptions.add(
      this.assetService.getCompanyDomainCounts(this.companyId).subscribe(
        data => {
          this.domainCompleteCount = data.count
          this.subscriptions.add(
            this.assetService.getCompanyDomainDetails(this.companyId, params.request, pageNo).subscribe(
              res => {
                this.domainRowData = res
                this.isDomainEmpty = this.domainRowData.length === 0 && this.isDomainFirst ? true : false
                this.isDomainColLoaded = this.domainRowData.length === 0 && this.isDomainFirst ? false : true
                this.isDomainFirst = false
                const lastRow = this.domainCompleteCount <= endRow ? this.domainCompleteCount : -1
                if (res.length === 0) {
                  this.gridActionsService.showNoRowsOverlay()
                  params.successCallback([], 0)
                  return
                }
                this.gridActionsService.hideNoRowsOverlay()
                params.successCallback(res, lastRow)
              },
              error => {
                this.isDomainError = true
                this.isDomainEmpty = false
                this.isDomainColLoaded = false
                params.failCallback()
              }
            )
          )
        })
    )
  }
  getRows(params: Params) {
    let dataCount: number
    let pageNo: number
    let pageSize: number

    this.subscriptions.add(
      this.assetService.getFilterDomainDetails(this.filterName, this.companyId, params.request).subscribe(res => {
        this.domainRowData = res
        this.isFilterEmpty = (this.domainRowData.length === 0 && this.isDomainFirst) ? true : false
        this.isFilterColLoaded = (this.domainRowData.length === 0 && this.isDomainFirst) ? false : true
        this.isDomainFirst = false
        dataCount = this.domainRowData.length
        pageNo = this.setOffset(dataCount)
        pageSize = pageNo * params.request.endRow
        const lastRow = res.length <= pageSize ? res.length : -1
        params.successCallback(this.domainRowData, lastRow)
      }, error => {
        this.isFilterError = true
        this.isFilterColLoaded = false
        this.isFilterEmpty = false
        params.failCallback()
      })
    )
  }
  getSslRows(params: Params) {
    Object.keys(params.request.filterModel).length || Object.keys(params.request.sortModel).length ?
      this.gridActionsService.filterChanged$.next(true) : this.gridActionsService.filterChanged$.next(false)
    Object.keys(params.request.filterModel).length || Object.keys(params.request.sortModel).length
      ? this.gridActionsService.filterChanged$.next(true)
      : this.gridActionsService.filterChanged$.next(false)
    let pageNo: number
    const { startRow, endRow } = params.request
    pageNo = this.setOffset(startRow)
    this.subscriptions.add(
      this.assetService.getCompanySslCounts(this.companyId).subscribe(
        data => {
          this.sslCompleteCount = data.count
          this.subscriptions.add(
            this.assetService.getCompanySslDetail(this.companyId, params.request, pageNo).subscribe(
              res => {
                this.sslRowData = res
                this.isSslEmpty = this.sslRowData.length === 0 && this.isSslFirst ? true : false
                this.isSslColLoaded = this.sslRowData.length === 0 && this.isSslFirst ? false : true
                this.isSslFirst = false
                const lastRow = this.sslCompleteCount <= endRow ? this.sslCompleteCount : -1
                if (res.length === 0) {
                  this.gridActionsService.showNoRowsOverlay()
                  params.successCallback([], 0)
                  return
                }
                this.gridActionsService.hideNoRowsOverlay()
                params.successCallback(res, lastRow)
              },
              error => {
                this.isSslError = true
                this.isSslEmpty = false
                this.isSslColLoaded = false
                params.failCallback()
              }
            )
          )
        })
    )
  }
  setOffset(offset: number): number {
    return (offset = 0 ? 1 : offset / 100 + 1)
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
