import { Component, OnDestroy, OnInit } from '@angular/core'
import { DateService } from '@app/modules/core/services/date.service'
import { HeaderNotificationService } from '@app/modules/core/services/header-notification.service'
import { SharedDataService } from '@app/modules/core/services/shared-data.service'
import { backendRoutes } from '@core/constants/constant'
import { IAlert } from '@core/interfaces/alert'
import { interval } from 'rxjs'
import { Subscription } from 'rxjs/index'

@Component({
  selector: 'app-header-notification',
  templateUrl: './notification.component.html',
})
export class NotificationComponent implements OnInit, OnDestroy {
  alert: IAlert[]
  isRead: boolean
  totalUnread: number
  subscriptions: Subscription
  constructor(private readonly notificationService: HeaderNotificationService,
              private readonly sharedDataSvc: SharedDataService, private readonly  dateSvc: DateService) {
    this.isRead = false
    this.alert = []
    this.subscriptions = new Subscription()
    this.totalUnread = 0
  }
  /*** Call fetchNotifications() on load and call fetchNotifications() every 5 minutes  */
  ngOnInit() {
    this.fetchNotifications()
    const source = interval(60000)
    this.subscriptions.add(source.subscribe(data => this.fetchNotifications()))
  }
  /*** Sends http request to get notifications */
  fetchNotifications() {
    const url = backendRoutes.GET_NOTIIFICATION()
    this.subscriptions.add(this.notificationService.getNotifications(url).subscribe(response => {
      if (Array.isArray(response)) {
        this.alert = response
        this.checkRead(this.alert)
        this.totalUnread = this.alert.filter(p => !p.isRead).length
      }
    }, error => {
    }))
  }
  /*** Updates notifications when they are read */
  update() {
    const url = backendRoutes.UPDATE_NOTIFICATION()
    for (const obj of Object.keys(this.alert)) {
      if (!this.alert[obj].isRead) {
        this.isRead = false
        this.subscriptions.add(this.notificationService.updateNotification(url).subscribe(response => {
        }, error => {}))
        break
      }
    }
  }
  /*** Checks if notification is read already */
  checkRead(alert: IAlert[]) {
    this.isRead = false
    for (const obj of Object.keys(alert)) {
      if (!alert[obj].isRead) {
        this.isRead = true
        break
      }
    }
  }
  /*** Converts date to string */
  convertDateToMoment(date: number): string {
    return this.dateSvc.convertDateToMoment(date.toString())
  }
  checkBulk(recordName: string): boolean {
    return recordName ? recordName.includes('Bulk') : false
  }
  /*** Unsubscribes all subscription when component is destroyed */
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
