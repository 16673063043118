import { AgGridModule } from '@ag-grid-community/angular'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import {
  MatCardModule,
  MatChipsModule,
  MatDialogModule,
  MatFormFieldModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTooltipModule,
} from '@angular/material'
import { RouterModule } from '@angular/router'
import { MdePopoverModule } from '@material-extended/mde'
import { CheckboxModule, EmptyStateImageModule, IconModule, ImageModule, JsonViewerModule, SelectModule, TextModule } from 'ui-components'

import { ActionsCellRendererComponent } from './cell-renderers/actions-cell-renderer/actions-cell-renderer.component'
import { AgreementComponent } from './cell-renderers/agreement-cell-renderer/agreement-cell-renderer.component'
import { CustomLoadingRendererComponent } from './cell-renderers/custom-loading-renderer/custom-loading.component'
import { CustomNoRowsRendererComponent } from './cell-renderers/custom-no-rows-overlay/custom-no-rows-overlay.component'
import { DateCellRendererComponent } from './cell-renderers/date-cell-renderer/date-cell-renderer.component'
import { HoverTagsCellRendererComponent } from './cell-renderers/hover-tags-renderer/hover-tags-cell-renderer.component'
import { IconCellRendererComponent } from './cell-renderers/icon-cell-renderer/icon-cell-renderer.component'
import { ImageCellRendererComponent } from './cell-renderers/image-cell-renderer/image-cell-renderer.component'
import { ImportScreenCellRendererComponent } from './cell-renderers/import-screen-cell-renderer/import-screen-cell-renderer.component'
import { IntegrationNameIconComponent } from './cell-renderers/integration-name-icon/integration-name-icon.component'
import { JsonCellRenderComponent } from './cell-renderers/json-cell-render/json-cell-render.component'
import { MasterTableSyncComponent } from './cell-renderers/master-table-sync/master-table-sync.component'
import { NameCellRendererComponent } from './cell-renderers/name-cell-renderer/name-cell-renderer.component'
import { OSCellRendererComponent } from './cell-renderers/os-cell-renderer/os-cell-renderer.component'
import { PasswordCellRendererComponent } from './cell-renderers/password-cell-renderer/password-cell-renderer.component'
import { ProgressBarCellRendererComponent } from './cell-renderers/progressbar-cell-renderer/progressbar-cell-renderer.component'
import { ProgressStatusCellRendererComponent } from './cell-renderers/progressstatus-cell-renderer/progressstatus-cell-renderer.component'
import { SimpleSelectComponent } from './cell-renderers/simple-select/simple-select.component'
import { SmileyComponent } from './cell-renderers/smiley/smiley.component'
import { SyncStatusActionsComponent } from './cell-renderers/sync-status-actions/sync-status-actions.component'
import { TableTagsCellRendererComponent } from './cell-renderers/table-tags-cell-renderer/table-tags-cell-renderer.component'
import { DataGridTooltipComponent } from './cell-renderers/tooltip-cell-renderer/tooltip-cell-renderer.component'
import { CustomHeader } from './custom-components/custom-header.component'
import { DropdownFloatingFilterComponent } from './data-grid-custom-filter/data-grid-custom-filter.component'
import { DataGridComponent } from './data-grid.component'
import { GirdActionService } from './services/grid-actions.service'

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressBarModule,
    JsonViewerModule,
    MatTooltipModule,
    IconModule,
    MatProgressSpinnerModule,
    TextModule,
    SelectModule,
    ImageModule,
    EmptyStateImageModule,
    CheckboxModule,
    MdePopoverModule,
    MatCardModule,
    MatDialogModule,
    RouterModule,
    AgGridModule.withComponents([
      ActionsCellRendererComponent,
      CustomLoadingRendererComponent,
      DateCellRendererComponent,
      NameCellRendererComponent,
      ImportScreenCellRendererComponent,
      TableTagsCellRendererComponent,
      HoverTagsCellRendererComponent,
      DataGridTooltipComponent,
      DropdownFloatingFilterComponent,
      IconCellRendererComponent,
      ImageCellRendererComponent,
      ProgressStatusCellRendererComponent,
      ProgressBarCellRendererComponent,
      SmileyComponent,
      OSCellRendererComponent,
      CustomNoRowsRendererComponent,
      CustomHeader,
      AgreementComponent,
      JsonCellRenderComponent,
      PasswordCellRendererComponent,
      MasterTableSyncComponent,
    ]),
  ],
  declarations: [
    DataGridComponent,
    ActionsCellRendererComponent,
    DateCellRendererComponent,
    NameCellRendererComponent,
    ImportScreenCellRendererComponent,
    TableTagsCellRendererComponent,
    HoverTagsCellRendererComponent,
    DataGridTooltipComponent,
    DropdownFloatingFilterComponent,
    IconCellRendererComponent,
    ImageCellRendererComponent,
    ProgressBarCellRendererComponent,
    ProgressStatusCellRendererComponent,
    SmileyComponent,
    CustomLoadingRendererComponent,
    OSCellRendererComponent,
    CustomNoRowsRendererComponent,
    CustomHeader,
    AgreementComponent,
    JsonCellRenderComponent,
    PasswordCellRendererComponent,
    IntegrationNameIconComponent,
    SyncStatusActionsComponent,
    SimpleSelectComponent,
    MasterTableSyncComponent,
  ],
  exports: [DataGridComponent],
  providers: [GirdActionService],
})
export class DataGridModule { }
