<itb-heading mat-dialog-title [className]="'modal-h'" [text]="'Logout'"></itb-heading>
<itb-round-button [tooltipText]="'Close'" mat-dialog-close [className]="'light round-modal-cross caret-close-modal'" [iconClassName]="'cross'"></itb-round-button>
<div>
  <form>
<mat-dialog-content class="modal-align-text">
  <div>    
    Are you sure you want to end your session and log out? 
  </div>
</mat-dialog-content>
<mat-dialog-actions class="xsModalAction">
  <itb-default-button [text]="'Logout'" [className]="'primary'" mat-dialog-close
                      [iconClassName]="'icon-logout'" [isDisabled]="" (onClickHandler)="logout(false)"></itb-default-button>
  <itb-default-button [text]="'Logout All Sessions'" [className]="'success'" mat-dialog-close
                      [iconClassName]="'icon-logout'" [isDisabled]="" (onClickHandler)="logout(true)"></itb-default-button>
  <itb-default-button [text]="'Cancel'" [className]="'default'" mat-dialog-close
                      [iconClassName]="'cross'" [isDisabled]=""></itb-default-button>
</mat-dialog-actions>
  </form>
</div>
