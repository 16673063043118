<itb-heading mat-dialog-title [className]="'modal-h'" [text]="saveGrid"></itb-heading>
<itb-round-button [tooltipText]="'Close'" mat-dialog-close [className]="'light round-modal-cross caret-close-modal'"
  [iconClassName]="'cross'"></itb-round-button>
<div mat-dialog-content class="mt-2 mb-2">
  <itb-text [className]="'text-default'" [text]="saveGridMsg"></itb-text>
</div>
<div mat-dialog-actions>
  <itb-default-button [text]="'Yes'" [className]="'primary'" [iconClassName]="'save'" (onClickHandler)="onClick()">
  </itb-default-button>
  <itb-default-button mat-dialog-close [text]="'No'" [className]="'default'" [iconClassName]="'cross'">
  </itb-default-button>
</div>
