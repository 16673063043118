import { AgRendererComponent } from '@ag-grid-community/angular'
import { Component } from '@angular/core'

@Component({
  selector: 'os-cell-renderer',
  templateUrl: './os-cell-renderer.component.html',
  styleUrls: ['./os-cell-renderer.component.scss'],
})
export class OSCellRendererComponent implements AgRendererComponent {

  params

  agInit(params): void {
    this.params = params
    if (params.value === 'win')
    params.value = 'images-library/windows.png'
    else if (params.value === 'mac')
    params.value = 'images-library/mac.png'
  }

  refresh(params): boolean {
    return false
  }
}
