import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { IColumnState } from '@app/interfaces/ui-components.interface'
import { getRouterState, RouterState } from '@app/root-store/reducers/router-store'
import { Store } from '@ngrx/store'
import { ApiService } from 'app/services/api.service'
import { BehaviorSubject, Observable } from 'rxjs'

import { APIResponse, Contact, ContactNotes, CopyContactPayload } from '../interfaces/contacts.interface'
import { IQueryParams } from '../interfaces/query-params.interface'
import { EditContact } from '../store/models/contacts.interface'

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  companyId: string
  private readonly dashboardData: BehaviorSubject<Contact> = new BehaviorSubject<Contact>({})
  constructor(public dialog: MatDialog, private readonly apiService: ApiService, private readonly _store: Store<RouterState>) {
    this.fetchCompany()
  }

  getDashboardData() {
    return this.dashboardData.asObservable()
  }
  updateDashboardData(contact: Contact) {
    this.dashboardData.next(contact)
  }

  fetchCompany() {
    this._store.select(getRouterState).subscribe(res => {
      if (res) this.companyId = res.state.params.companyId
    })
  }

  fetchContactTableData(companyId, params): Observable<Contact[]> {
    return this.apiService.get(`contacts/company/${companyId}/${this.getQueryParams(params)}`, 'Contact')
  }

  getQueryParams(params: IQueryParams): string {
    let queryParams = '?'
    if (params && params.condition !== undefined) queryParams += `conditions=${JSON.stringify(params.condition)}&`
    if (params && params.fields !== undefined) queryParams += `fields=${JSON.stringify(params.fields)}&`
    if (params && params.sortBy !== undefined) queryParams += `sortBy=${params.sortBy}&`
    if (params && params.order !== undefined) queryParams += `order=${params.order}&`
    if (params && params.pageNo !== undefined) queryParams += `pageNo=${params.pageNo}&`
    if (params && params.pageSize !== undefined) queryParams += `pageSize=${params.pageSize}&`
    return queryParams
  }

  saveContact(contact, flag = false): Observable<Contact> {
    return this.apiService.add(`contacts/company/${contact.companyId}`, contact.formData, flag)
  }
  copyContact(data: CopyContactPayload): Observable<Contact> {
    return this.apiService.add(`contacts/company/${data.formData.companyUuid}/${data.formData.uuid}/copy`, data.payload)
  }
  getContactTypes() {
    return this.apiService.get('organizations/metadata/contactType', 'Contacts')
  }

  getContact(id: string): Observable<Contact> {
    return this.apiService.get(`contacts/${id}?companyId=${this.companyId}`, 'Contacts')
  }

  editContact(data: EditContact, flag = false): Observable<Contact> {
    return this.apiService.edit(`contacts/${data.id}?companyId=${this.companyId}&nameChanged=${flag}`, data.payload)
  }

  deleteContact(id: string): Observable<APIResponse> {
    return this.apiService.delete(`contacts/${id}?companyId=${this.companyId}`)
  }
  bulkDeleteContact(ids: { contactsArray: string[] }): Observable<APIResponse> {
    return this.apiService.delete(`contacts?companyId=${this.companyId}`, ids)
  }

  getLinkedItems(contactId: string) {
    return this.apiService.get(`contacts/${contactId}/linkedItem`, 'Contacts')
  }

  getLastTechCall(contactUuid, companyUuid) {
    return this.apiService.get(`contacts/lasttech/${contactUuid}/${companyUuid}`, 'Contacts')
  }
  getContactNotes(contactId: string): Observable<ContactNotes[]> {
    return this.apiService.get(`contacts/${contactId}/notes`, 'Contacts')
  }
  getContactColumnState(): Observable<IColumnState[]> {
    return this.apiService.get('dashboard/user/headers/contacts', 'Contacts')
  }

  resetColumnPreference() {
    return this.apiService.edit('dashboard/user/headers/contacts', [])
  }

  saveContactColumnState(state, flag = false): Observable<IColumnState[]> {
    let colState
    if (!flag) {
      colState = {
        columnDefs: state,
      }
    } else {
      colState = { ...state }
    }
    return this.apiService.edit('dashboard/user/headers/contacts', colState)
  }
  getContactsCount(companyId, params): Observable<{ count: string }> {
    return this.apiService.get(`contacts/company/${companyId}/count/${this.getQueryParams(params)}`, 'Contacts')
  }
  getContactsDraftsCount(companyId): Observable<{ count: number }> {
    return this.apiService.get(`contacts/company/${companyId}/count?conditions={"recordStatus":"Draft"}`, 'Contacts')
  }
  getCompanyName(companyId) {
    return this.apiService.get(`companies/${companyId}`, 'Company')
  }
  sendInvite(payload) {
    return this.apiService.add('users/sendInvite', payload)
  }
  getUserRoles() {
    return this.apiService.get('users/roles/list', 'User')
  }
  getAllDepartments() {
    return this.apiService.get('organizations/departments', 'Departments')
  }
  getCountries() {
    return this.apiService.get('organizations/metadata/countries', 'Countries')
  }
  getAllRelationships() {
    return this.apiService.get('organizations/metadata/contactRelation', 'Relationships')
  }
  getAllLocations(companyId) {
    return this.apiService.get(
      `locations/company/${companyId}?conditions={ "recordStatus": { "$ne": "Draft" } }
       &order=desc&sortBy=ITBLastUpdated&pageNo=1&pageSize=20`,
      'Locations'
    )
  }
  getContactByNumber(phoneMobile) {
    return this.apiService.get(`contacts/contactinfo/${phoneMobile}`, 'Contacts')
  }
  postLastTechCall(obj) {
    return this.apiService.add('contacts/contactdashboard/lasttech', obj)
  }
}
