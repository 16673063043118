import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { PopupAlertComponent } from 'ui-components'

import { MODAL_CONSTANTS } from '../../../../constants/ui-components.constants'
import { NOTIFICATION_CONSTANTS, STRING_CONSTANTS } from '../../../core/constants/constants'
import { IAvatarArray } from '../../../core/interfaces/avatar-array'
import { IChangePasswordRequest } from '../../../core/interfaces/change-password-backend'
import { IChangePasswordErrorCode } from '../../../core/interfaces/change-password-error-code'
import { IChangePassword } from '../../../core/interfaces/change-password-interface'
import { ChangePasswordService } from '../../../core/services/change-password.service'
import { loginErrorConstants, loginRouteConstants } from '../../constants/login-string-constants'
import { ILoginResponse } from '../../interface/login-response'

import { SharedDataService } from './../../../core/services/shared-data.service'
import { LoginService } from './../../services/login.service'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  isLoading: boolean
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
  canViewPassword: boolean
  hasErrorMsgForPwdFlag: boolean
  errorMsgForPwd: string
  isWrongPasswordText: boolean
  isChecked: boolean
  description: string
  isULetter: boolean
  isSsymbol: boolean
  hasNumber: boolean
  hasNoSpace: boolean
  hasEightChar: boolean
  hasErrorMsgForPwdFlagConfrm: boolean
  hasErrorMsgForPwdFlagNew: boolean
  isEightCharStyle: boolean
  isCapitalLetterStyle: boolean
  isNumberStyle: boolean
  isSymbolStyle: boolean
  isNospaceStyle: boolean
  hasContent: boolean
  isDescriptionStyle: boolean
  isFirstBarStyle: boolean
  isSecondBarStyle: boolean
  isThirdBarStyle: boolean
  isFourthBarStyle: boolean
  inputType: string
  changePasswordForm: FormGroup
  iconsArray: IAvatarArray[]
  iconsArrayForChange: IAvatarArray[]
  subscriptions: Subscription
  inputTypeForChange: string
  isScreenEnabled: boolean
  confirmationUserId: string
  paramUuid: string
  firstTimeLogin: boolean

  constructor(private readonly changePasswordSvc: ChangePasswordService, public dialog: MatDialog,
              private readonly router: Router,
              public sharedDataService: SharedDataService,

              public loginService: LoginService, private readonly activatedRoute: ActivatedRoute
  ) {
    this.isScreenEnabled = false
    this.isLoading = false
    this.canViewPassword = false
    this.hasErrorMsgForPwdFlag = false
    this.errorMsgForPwd = ''
    this.isWrongPasswordText = false
    this.isChecked = false
    this.description = ''
    this.isULetter = false
    this.isSsymbol = false
    this.hasNumber = false
    this.hasNoSpace = false
    this.hasEightChar = false
    this.hasErrorMsgForPwdFlagConfrm = false
    this.hasErrorMsgForPwdFlagNew = false
    this.isEightCharStyle = false
    this.isCapitalLetterStyle = false
    this.isNumberStyle = false
    this.isSymbolStyle = false
    this.isNospaceStyle = false
    this.isDescriptionStyle = false
    this.isFirstBarStyle = false
    this.isSecondBarStyle = false
    this.isThirdBarStyle = false
    this.isFourthBarStyle = false
    this.hasContent = true
    this.iconsArray = [{
      tooltipText: STRING_CONSTANTS.HIDE_PASSWORD, className: STRING_CONSTANTS.EYE_DA,
    },
    ]
    this.iconsArrayForChange = [{
      tooltipText: STRING_CONSTANTS.HIDE_PASSWORD, className: STRING_CONSTANTS.EYE_DA,
    },
    ]
    this.inputType = STRING_CONSTANTS.PASSWORD
    this.inputTypeForChange = STRING_CONSTANTS.PASSWORD
    this.subscriptions = new Subscription()
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      const param = params.id
      this.paramUuid = params.id
      const url = loginRouteConstants.CONFIRM_USER_UUID(param)

      this.loginService.confirmUserUuid(url).subscribe(response => {

        if (response) {
          this.confirmationUserId = response.data

          this.isScreenEnabled = true
        } else { this.hasContent = false }

      }, error => { this.hasContent = false })

    })

    this.changePasswordForm = new FormGroup({

      newPassword: new FormControl('', Validators.required),
      confirmNewPassword: new FormControl('', Validators.required),
    })

  }
  /*** Show / hide password   */
  showPassword() {
    this.canViewPassword = !this.canViewPassword
  }
  /*** Change event for confirm password   */
  changeNewConfirmPwd() {
    this.isWrongPasswordText = false
    this.isChecked = false
    if (this.changePasswordForm.value.newPassword !== this.changePasswordForm.value.confirmNewPassword) {
      this.isWrongPasswordText = true
      this.isChecked = true
    }
  }
  /*** Change event for new password   */
  changeNewPwd() {
    const object: IChangePasswordErrorCode = this.createObject()
    const changeNewPassword = this.changePasswordSvc.changeNewPassword(
      this.changePasswordForm.value.newPassword, this.changePasswordForm.value.confirmNewPassword, object)
    this.hasEightChar = changeNewPassword.errorObject.hasEightChar
    this.changePasswordForm.value.newPassword = changeNewPassword.newPassword
    this.hasNumber = changeNewPassword.errorObject.hasNumbers
    this.isULetter = changeNewPassword.errorObject.isULetter
    this.isSsymbol = changeNewPassword.errorObject.isSsymbol
    this.hasNoSpace = changeNewPassword.errorObject.hasNoSpace
    this.description = changeNewPassword.errorObject.description
    this.isChecked = changeNewPassword.errorObject.isChecked
    this.isWrongPasswordText = changeNewPassword.errorObject.isWrongPasswordText
    this.isEightCharStyle = changeNewPassword.errorObject.isEightCharStyle
    this.isCapitalLetterStyle = changeNewPassword.errorObject.isCapitalLetterStyle
    this.isNumberStyle = changeNewPassword.errorObject.isNumberStyle
    this.isSymbolStyle = changeNewPassword.errorObject.isSymbolStyle
    this.isNospaceStyle = changeNewPassword.errorObject.isNospaceStyle
    this.isFirstBarStyle = changeNewPassword.errorObject.isFirstBarStyle
    this.isSecondBarStyle = changeNewPassword.errorObject.isSecondBarStyle
    this.isThirdBarStyle = changeNewPassword.errorObject.isThirdBarStyle
    this.isFourthBarStyle = changeNewPassword.errorObject.isFourthBarStyle
    this.isDescriptionStyle = changeNewPassword.errorObject.isDescriptionStyle
  }
  /*** Returns password settings object   */
  createObject() {
    return {
      isULetter: this.isULetter, isSsymbol: this.isSsymbol, hasNumber: this.hasNumber,
      hasNoSpace: this.hasNoSpace, hasEightChar: this.hasEightChar, description: this.description,
      isWrongPasswordText: this.isWrongPasswordText, isChecked: this.isChecked, isEightCharStyle: this.isEightCharStyle,
      isCapitalLetterStyle: this.isCapitalLetterStyle, isNumberStyle: this.isNumberStyle,
      isSymbolStyle: this.isSymbolStyle, isNospaceStyle: this.isNospaceStyle, isFirstBarStyle: this.isFirstBarStyle,
      isSecondBarStyle: this.isSecondBarStyle, isThirdBarStyle: this.isThirdBarStyle,
      isFourthBarStyle: this.isFourthBarStyle, isDescriptionStyle: this.isDescriptionStyle,
    }
  }
  /*** Send change password http request using change password service   */
  changePasswordCall() {

    this.isLoading = true
    const passwordObj: IChangePassword = {

      newPassword: this.changePasswordForm.value.newPassword,
      confirmationUuid: this.paramUuid,
    }
    const url = loginRouteConstants.CHANGE_USER_PASSWORD(this.confirmationUserId)

    this.subscriptions.add(this.changePasswordSvc.resetPasswordCall(url, passwordObj).subscribe(data =>

      this.afterPassword(),
      error => {
        this.isLoading = false
        this.errorMsgForPwd = 'Invalid Password'
        this.hasErrorMsgForPwdFlag = true
      }))
  }
  /*** Checks response of change password request */
  afterPassword() {
    this.showNotification(NOTIFICATION_CONSTANTS.SUCCESS, '', NOTIFICATION_CONSTANTS.PASSWORD_CHANGED, 'Saved!')
    this.router.navigateByUrl('login').catch()

  }
  /*** Validates input fields and calls changePasswordCall() function in case of correct data  */
  submit() {
    this.hasErrorMsgForPwdFlagNew = false
    this.hasErrorMsgForPwdFlagConfrm = false
    this.hasErrorMsgForPwdFlag = false
    if (this.changePasswordForm.value.oldPassword === '') {
      this.errorMsgForPwd = 'Please enter existing password'
      this.hasErrorMsgForPwdFlag = true
    }
    if (this.changePasswordForm.value.newPassword === '') {
      this.errorMsgForPwd = 'Please enter New password'
      this.hasErrorMsgForPwdFlagNew = true
    }
    if (this.changePasswordForm.value.confirmNewPassword === '') {
      this.errorMsgForPwd = 'Please enter New Confirm Password'
      this.hasErrorMsgForPwdFlagConfrm = true
    } else if (this.changePasswordForm.value.oldPassword === this.changePasswordForm.value.newPassword) {
      this.errorMsgForPwd = 'New and old passwords cannot be same'
      this.hasErrorMsgForPwdFlag = true
    }
    if ((this.hasErrorMsgForPwdFlag) || (this.hasErrorMsgForPwdFlagConfrm)
      || (this.hasErrorMsgForPwdFlagNew) || (this.hasErrorMsgForPwdFlag)) {
      return
    }
    if (this.description === 'Strong') {
      this.changePasswordCall()
    }
  }
  /*** Show / hide New Password  */
  clickCatch(item: string) {
    this.inputType = item === STRING_CONSTANTS.EYE_DA ? STRING_CONSTANTS.TEXT : STRING_CONSTANTS.PASSWORD
    this.iconsArray = [{
      tooltipText: this.inputType === STRING_CONSTANTS.PASSWORD ? STRING_CONSTANTS.HIDE_PASSWORD : STRING_CONSTANTS.SHOW_PASSWORD,
      className: this.inputType === STRING_CONSTANTS.PASSWORD ? STRING_CONSTANTS.EYE_DA : STRING_CONSTANTS.EYE,
    }]
  }
  /*** Show / hide Confirm Password  */
  clickCatchConfirmPassword(item: string) {
    this.inputTypeForChange = item === STRING_CONSTANTS.EYE_DA ? STRING_CONSTANTS.TEXT : STRING_CONSTANTS.PASSWORD
    this.iconsArrayForChange = [{
      tooltipText: this.inputTypeForChange === STRING_CONSTANTS.PASSWORD ? STRING_CONSTANTS.HIDE_PASSWORD : STRING_CONSTANTS.SHOW_PASSWORD,
      className: this.inputTypeForChange === STRING_CONSTANTS.PASSWORD ? STRING_CONSTANTS.EYE_DA : STRING_CONSTANTS.EYE,
    }]
  }
  /*** show modal notification  */
  showNotification(mode: string, title: string, body: string, mainMessage: string) {
    const dialogRef = this.dialog.open(PopupAlertComponent, {
      width: MODAL_CONSTANTS.MODAL_WIDTH,
      data: { mode, title, body, mainMessage },
    })
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
  loginClickHandler() {

    this.router.navigateByUrl('login')
  }

}
