import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { stringConstants } from '../../../constants/constants'
import { GirdActionService } from '../../services/grid-actions.service'

@Component({
  selector: 'app-import-screen-cell-renderer',
  templateUrl: './import-screen-cell-renderer.component.html',
})
export class ImportScreenCellRendererComponent implements AgRendererComponent {
  params // used for optional params that are added dynamically for different types
  constructor(public gridActionsService: GirdActionService, public route: ActivatedRoute) {}

  agInit(params: ICellRendererParams): void {
    if (params.value instanceof Array && !params.value.length) params.value = ''
    if (typeof params.value === 'boolean') params.value = params.value.toString()
    this.params = params
  }

  refresh(params: ICellRendererParams): boolean {
    return false
  }

  nameHandler() {
    if (this.params.data.name === this.params.highlight) {
      this.gridActionsService.setSelectedAction(
        stringConstants.DEFAULT_DATAGRID_VIEW_TEMPLATES_DETAIL,
        this.params.data,
        this.params.rowIndex
      )
    }
  }
}
