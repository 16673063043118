import { Injectable } from '@angular/core'
import { ApiService } from '@app/services/api.service'
import { backendRoutes } from '@core/constants/constant'
import * as _ from 'lodash'

import { IDate } from '../interfaces/activities'
@Injectable({
  providedIn: 'root',
})
export class UtilService {
  equalFlag: boolean
  constructor(private readonly baseService: ApiService) {

  }
  /*** Checks if object is undefined or not  */
  isUndefined(object: any): boolean { // tslint:disable-line
    if (typeof object !== 'undefined' && object !== '' && object != undefined) {
      return false
    }
    return true
  }
  /*** Set hours for start and end date */
  filterDateForActivityExport(start: Date, end: Date): IDate {
    start.setHours(23, 59, 59, 999)
    start.setHours(start.getHours() - start.getTimezoneOffset() / 60)
    end.setHours(0, 0, 0, 0)
    end.setHours(end.getHours() - end.getTimezoneOffset() / 60)
    return { start, end }
  }
  /*** Gets the month name against the month id (number) */
  convertMonthToMonthName(month: number): string {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return monthNames[month]
  }
  /*** Sorts an array by name */
  dynamicSort(property: string) {
    let sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substr(1)
    }
    return (a, b) => {
      if (a[property] && b[property]) {
        const result = (a[property].toUpperCase() < b[property].toUpperCase())
          ? -1 : (a[property].toUpperCase() > b[property].toUpperCase()) ? 1 : 0
        return result * sortOrder
      }
    }
  }

  getFilterSQPR(model) {
    this.equalFlag = false
    const sqpr = {}
    for (const obj in model) {  // tslint:disable-line
      if ((obj && model[obj].type === 'equals')) {
        this.equalFlag = true

      }
      if (obj && model[obj].filterType === 'date') {
        sqpr[obj] = model[obj].dateFrom
      } else if (obj && model[obj].type === 'notContains') {
        sqpr[obj] = `!${model[obj].filter}`
      } else {
        sqpr[obj] = model[obj].filter
      }
    }
    return sqpr
  }

  getSortSQPR(model) {
    if (model && model.length > 0) {
      return {
        key: model[0].colId,
        order: model[0].sort,
      }
    }
  }

  getCamelCaseToWord(str): string {
    let result = str.replace(/([A-Z])/g, ' $1')
    // tslint:disable-next-line: restrict-plus-operands
    result = result.charAt(0).toUpperCase() + result.slice(1)
    return result
  }

/***Sets the string to Title Case */
  toTitleCase(str): string {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        // tslint:disable-next-line: restrict-plus-operands
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      }
    )
  }

  /*** Removes duplicate objects from the array */
  removeDuplicatesFromArray(originalArray, prop) {
    const newArray = []
    const lookupObject = {}

    // tslint:disable-next-line: forin
    for (const i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i]
    }

    // tslint:disable-next-line: forin
    for (const i in lookupObject) {
      newArray.push(lookupObject[i])
    }
    return newArray
  }

  /*** Remove duplicates from a given array */
  removeDuplicates(array) {
    return array.filter((a, b) => array.indexOf(a) === b)
  }

  /*** Gets the device type of the user browser agent */
  GetDeviceType(): string {
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.
      test(navigator.userAgent)
    const isMobile = /Mobi|Android/i.test(navigator.userAgent)

    if (isMobile)
      return 'mobile'
    if (isTablet)
      return 'tablet'

    return 'desktop'
  }
  /***AG Grid Sort function*/
  sortFunction(aVal, bVal, nodeA, nodeB, isInverted) {
    if (aVal === bVal) {
      return 0
    }
    if (isInverted === true) {
      if (_.isNil(aVal) || _.isNaN(aVal))
        return -1
      if (_.isNil(bVal) || _.isNaN(bVal))
        return 1
    } else {
      if (_.isNil(aVal) || _.isNaN(aVal))
        return 1

      if (_.isNil(bVal) || _.isNaN(bVal))
        return -1
    }
    return aVal < bVal ? -1 : 1
  }

  getAssetFilterSQPR(model) {
    const sqpr = {}
    for (const key of Object.keys(model)) {
      switch (model[key].type) {
        case 'contains':
          sqpr[key] = { $regex: model[key].filter, $options: 'i' }
          break
        case 'notContains':
          sqpr[key] = { $regex: `^((?!${model[key].filter}).)*$` }
          break
        default:
          sqpr[key] = model[key].filter ? model[key].filter : model[key].dateFrom
      }
    }
    return sqpr
  }
  getStatsDetails(companyId: string, name: string, sqpr, pageNo: number, pageSize = 20) {
    const filterSQPR = this.getAssetFilterSQPR(sqpr.filterModel)
    const sortSQPR = this.getSortSQPR(sqpr.sortModel)
    let merged
    merged = { ...this.getOriginalCondition(name), ...filterSQPR }
    if (name === 'Other') {
      merged = { ...this.getOtherRegx(), ...filterSQPR }
    }
    if (name === 'WorkStation') {
      merged = { ...this.getComputerregx(), ...filterSQPR }
    }
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${backendRoutes.GET_CATEGORY_DETAIL(companyId)}${
      Object.entries(merged).length ? `?conditions=${JSON.stringify(merged)}` : `?conditions=${JSON.stringify({})}`
      }${sortSQPR ? `&sortBy=${sortSQPR.key}&order=${sortSQPR.order}&pageNo=${pageNo}&pageSize=${pageSize}` : ''}`,
      '',
      true
    )
  }
  getTotalStatsCounts(companyId, name) {
    let merged
    merged = this.getOriginalCondition(name)
    if (name === 'Other') {
      merged = this.getOtherRegx()
    }
    if (name === 'WorkStation') {
      merged = this.getComputerregx()
    }
    return this.baseService.get(
      `${backendRoutes.GET_CATEGORY_COUNTS(companyId)}?conditions=${JSON.stringify(merged)} `,
      '',
      true
    )

  }
  getOriginalCondition(name: string) {
    return  { 'type.category': { $regex: `${name}`, $options: 'i' } }
  }
  getOtherRegx() {
    return  {'type.category': 'printer|computer|phone|network|switch'}
  }
  getComputerregx() {
return  { $or: [{ 'type.category': { $regex: 'WorkStation', $options: 'i' } }, { 'type.category': { $regex: 'computer', $options: 'i' } }] }
  }

  getCompanyAssetsWarrnatyColDefs(id) {
    return [
      {
        headerName: 'CONFIGURATION NAME', field: 'name',
        minWidth: 180, type: 'name',
        suppressColumnsToolPanel: true, lockPosition: true, filter: 'agTextColumnFilter',
        cellRendererParams: {
          baseURL: 'app/company/$companyId/configurations/view',
          propertyName: 'uuid',
          companyId: id,
        },
      },
      { headerName: 'TYPE NAME', field: 'type.name', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'STATUS NAME', field: 'status.name', minWidth: 180, filter: 'agTextColumnFilter' },
      {
        headerName: 'ASSET DATE', field: 'AssetDate', minWidth: 180, filter: 'agDateColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      { headerName: 'RECORD STATUS', field: 'recordStatus', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'IP ADDRESS', field: 'ipAddress', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'MAINTENANCE WINDOW', field: 'maintenanceWindow', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'ASSET TAG', field: 'searchTags', minWidth: 180, type: 'tags' },
      { headerName: 'NAME', field: 'Name', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'DOMAIN', field: 'Domain', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'BIOS MFG', field: 'biosMFG', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'BIOS NAME', field: 'biosName', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'UP TIME', field: 'UpTime', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'LAST LOGIN NAME', field: 'lastLoginName', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'SERIAL NUMBER', field: 'serialNumber', minWidth: 180, filter: 'agTextColumnFilter' },
      {
        headerName: 'PURCHASE DATE', field: 'purchaseDate', minWidth: 180, filter: 'agDateColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      { headerName: 'PROCESSOR NAME', field: 'processorName', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'TOTAL MEMORY', field: 'TotalMemory', minWidth: 180, filter: 'agTextColumnFilter' },
      {
        headerName: 'INSTALLATION DATE', field: 'installationDate', minWidth: 180, filter: 'agDateColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      { headerName: 'PURCHASE BY', field: 'purchaseBy', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'OS', field: 'OS', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'OS INFO', field: 'osInfo', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'OS INSTALL DATE', field: 'installationDate', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'DNS INFO', field: 'DNSInfo', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'ROUTER ADDRESS', field: 'RouterAddress', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'MANAGEMENT PORT', field: 'ManagementPort', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'SEARCH TAGS', field: 'tags', type: 'tags', minWidth: 180, filter: 'agTextColumnFilter' },
    ]
  }

  openUserTour() {
    if (document.querySelector('.itb-progress-button')) {
      const element: HTMLElement = document.getElementsByClassName('itb-progress-button')[0] as HTMLElement
      element.click()
    }
  }
}
