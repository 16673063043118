<itb-widget
  [hasFooter]="false"
  [roundButtons]="wrapperClass === '' ? roundButton : roundButtonwithCross"
  [hasBarLoader]="isLoading"
  [hasContentCenter]="true"
  (onClickHandler)="listTrackers()"
>
  <div class="slide-wrapper  {{ wrapperClass }}">
    <div class="main-content">
      <div *ngIf="!isEmpty" fxLayout="row">
        <itb-pie-chart [chartOptions]="options" fxFlex="51"> </itb-pie-chart>
        <div fxFlex="49" class="legends-container pointer">
          <div class="legends" *ngFor="let item of pieChartData" (click)="openModal(item.name, item.value)">
            <itb-color-bar *ngIf="item.name === 'Expired'" [className]="item.name === 'Expired' ? 'color-bar color-bar-1' : ''">
            </itb-color-bar>
            <itb-color-bar *ngIf="item.name === 'Soon'" [className]="item.name === 'Soon' ? 'color-bar color-bar-3' : ''"> </itb-color-bar>
            <itb-color-bar *ngIf="item.name === 'Active'" [className]="item.name === 'Active' ? 'color-bar color-bar-2' : ''">
            </itb-color-bar>
            <itb-color-bar *ngIf="item.name === 'Unknown'" [className]="item.name === 'Unknown' ? 'color-bar color-bar-4' : ''">
            </itb-color-bar>
            <itb-text [className]="'text-default pl-2'" [text]="item.value + ' - ' + item.name"></itb-text>
          </div>
        </div>
      </div>
      <div *ngIf="isEmpty" fxLayout="column" fxLayoutAlign="center center" class="no-data">
        <span [ngClass]="{ 'img-nodatafound': true }"></span>
        <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
      </div>
      <div *ngIf="isError" class="no-data" fxLayout="column" fxLayoutAlign="center center">
        <span [ngClass]="{ 'img-404error': true }"></span>
        <itb-text [className]="'text-default text-color-primary'" [text]="'SOME WENT WRONG'"></itb-text>
      </div>
    </div>
    <div class="slide-content">
      <div *ngIf="filter">
        <div class="position-relative mb-2">
          <itb-tabs [tabs]="tabs" (onClickHandler)="tabsShift($event)"></itb-tabs>
          <div class="position-absolute right-0 top-0 modal-select"></div>
        </div>
        <div *ngIf="!isDomain">
          <ng-container *ngIf="isDomainColLoaded">
            <itb-data-grid
              [columnDefs]="domainColumnDefs"
              [rowData]="domainRowData"
              [rowModelType]="'serverSide'"
              (dataGridRowsPropsEmitter)="getDomainRows($event)"
              (columnStateChangeEmitter)="domainColumnStateChanged($event)"
              [initialUserColState]="userDomainColumnState"
              [sideBar]="true"
              [cacheBlockSize]="20"
              [totalRows]="domainCompleteCount"
            >
            </itb-data-grid>
          </ng-container>
          <itb-spinner fxLayoutAlign="center center" class="h-100" [spinnerSize]="75" *ngIf="isDomainLoading"></itb-spinner>
          <div *ngIf="isDomainEmpty" [ngClass]="{ 'img-nodatafound': true }" fxLayout="column" fxLayoutAlign="end center">
            <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
          </div>
          <div *ngIf="isDomainError" class="no-data" fxLayout="column" fxLayoutAlign="center center">
            <span [ngClass]="{ 'img-404error': true }"></span>
            <itb-text [className]="'text-default text-color-primary'" [text]="'SOME WENT WRONG'"></itb-text>
          </div>
        </div>
        <div *ngIf="isDomain">
          <ng-container *ngIf="isSslColLoaded">
            <itb-data-grid
              [columnDefs]="sslColumnDefs"
              [rowData]="sslRowData"
              [rowModelType]="'serverSide'"
              (dataGridRowsPropsEmitter)="getSslRows($event)"
              (columnStateChangeEmitter)="sslColumnStateChanged($event)"
              [initialUserColState]="userSslColumnState"
              [sideBar]="true"
              [cacheBlockSize]="20"
              [totalRows]="sslCompleteCount"
            ></itb-data-grid>
          </ng-container>
          <itb-spinner fxLayoutAlign="center center" class="h-100" [spinnerSize]="75" *ngIf="isSslLoading"></itb-spinner>
          <div *ngIf="isSslEmpty" [ngClass]="{ 'img-nodatafound': true }" fxLayout="column" fxLayoutAlign="end center">
            <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
          </div>
          <div *ngIf="isSslError" class="no-data" fxLayout="column" fxLayoutAlign="center center">
            <span [ngClass]="{ 'img-404error': true }"></span>
            <itb-text [className]="'text-default text-color-primary'" [text]="'SOME WENT WRONG'"></itb-text>
          </div>
        </div>
      </div>
      <div *ngIf="!filter">
        <ng-container *ngIf="isFilterColLoaded">
          <itb-data-grid
            [sideBar]="true"
            [totalRows]="domainRowData.length"
            [defaultColDef]="defaultColDef"
            [columnDefs]="domainColumnDefs"
            [rowData]="domainRowData"
            [rowModelType]="'clientSide'"
            (columnStateChangeEmitter)="filterDomainColumnStateChanged($event)"
            [initialUserColState]="userDomainFilterColumnState"
          ></itb-data-grid>
        </ng-container>
        <itb-spinner fxLayoutAlign="center center" class="h-100" [spinnerSize]="75" *ngIf="isFilterLoading"></itb-spinner>
        <div *ngIf="isFilterEmpty" [ngClass]="{ 'img-nodatafound': true }" fxLayout="column" fxLayoutAlign="end center">
          <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
        </div>
        <div *ngIf="isFilterError" class="no-data" fxLayout="column" fxLayoutAlign="center center">
          <span [ngClass]="{ 'img-404error': true }"></span>
          <itb-text [className]="'text-default text-color-primary'" [text]="'SOME WENT WRONG'"></itb-text>
        </div>
      </div>
    </div>
  </div>
</itb-widget>
