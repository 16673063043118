import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import { LoginService } from '@app/modules/login/services/login.service'
import { BehaviorSubject } from 'rxjs'

import { NOTIFICATION_CONSTANTS } from '../core/constants/constants'
import { URL_CONSTANTS } from '../core/constants/url-constants'
import { CoreService } from '../core/services/core.service'
import { SharedDataService } from '../core/services/shared-data.service'
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  disableGlobalSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  // tslint:disable-next-line: no-any
  errorCodesArray: any
  expireAllSession: boolean

  constructor(
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly coreService: CoreService, private readonly activateRoute: ActivatedRoute,
    private readonly sharedDataService: SharedDataService,  private readonly loginService: LoginService
  ) {
    this.errorCodesArray = {}
    this.errorCodesArray['90244'] = { errorCode: 90244, message: 'Parent Discoverer System can not be deleted while it has children'}
    this.errorCodesArray['90246'] = { errorCode: 90246, message: 'Parent Discoverer System(s) can not be deleted while it has children'}
    this.errorCodesArray['42367'] = { errorCode: 42367, message: 'Domain already exists'}
    this.errorCodesArray['42319'] = { errorCode: 42319, message: 'Domain record not found'}
    this.errorCodesArray['99999'] = { errorCode: 99999, message: 'Access Denied'}
    this.errorCodesArray['43078'] = { errorCode: 43078, message: 'Password name already exist'}
    this.errorCodesArray['40182'] = { errorCode: 40182, message: 'Failed to send invite because this user already exists'}
    this.errorCodesArray['40158'] = { errorCode: 40158, message: 'User Already Exists'}
    this.errorCodesArray['450462'] = { errorCode: 450462, message: 'Something went wrong while saving grid settings'}
    this.errorCodesArray['90210'] = { errorCode: 90210, message: 'Can\'t run adhoc sync, discoverer system is disabled'}
    this.errorCodesArray['47038'] = { errorCode: '47038',
                                      message: 'Article with same name already exists. Please change the name or delete existing article'}
    this.expireAllSession = false
  }
  /***Enables global search   */
  toggleGlobalSearchTrue() {
    this.disableGlobalSearch.next(true)
  }
  /***Disables global search   */
  toggleGlobalSearchFalse() {
    this.disableGlobalSearch.next(false)
  }
  setUrlForLogout() {
    if (this.router.url !== '/app/error' && !this.router.url.includes('/app/dashboard/') && this.router.url !== '/login' &&
      (!this.activateRoute.snapshot.queryParams.pods && !this.activateRoute.snapshot.queryParams.asset)) {
      this.sharedDataService.setURL(document.URL)
    }
  }

  /*** Logouts user   */
  logout(sessionString?: string) {
    const user = this.sharedDataService.getUser()
    if (user && user.sso) {
      this.coreService.itbLogout(this.expireAllSession).subscribe(
        data => {},
        error => {},
        () => {
          this.expireAllSession = false
        }
      )
      this.sharedDataService.clearLocalStorage()
      this.setUrlForLogout()
      if (sessionString) {
        this.sharedDataService.setShowSessionExpiredAlertFlag(true)
      }
      if (this.activateRoute.snapshot.queryParams.pods || this.activateRoute.snapshot.queryParams.asset) {
        const podValue = this.activateRoute.snapshot.queryParams.pods ? this.activateRoute.snapshot.queryParams.pods : 'companyAssetsPods'
        this.loginService.createUrlForLogin(podValue)
      } else {
        this.router
          .navigate([URL_CONSTANTS.LOGIN])
          .then(routeData => {})
          .catch(() => {})
      }
    } else {
      this.coreService.itbLogout(this.expireAllSession).subscribe(
        data => {},
        error => {},
        () => {
          this.expireAllSession = false
        }
      )
      this.sharedDataService.clearLocalStorage()
      this.setUrlForLogout()
      if (sessionString) {
        this.sharedDataService.setShowSessionExpiredAlertFlag(true)
      }
      if (this.activateRoute.snapshot.queryParams.pods || this.activateRoute.snapshot.queryParams.asset) {
        const podValue = this.activateRoute.snapshot.queryParams.pods ? this.activateRoute.snapshot.queryParams.pods : 'companyAssetsPods'
        this.loginService.createUrlForLogin(podValue)
      } else {
        this.router
          .navigate([URL_CONSTANTS.LOGIN])
          .then(routeData => {})
          .catch(() => {})
      }
    }
  }

  /***Gets error codes */
  getErrorCodes() {
    return this.errorCodesArray
  }
}
