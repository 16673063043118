import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core'
import { MatDialog } from '@angular/material'
import { PERMISSIONS } from '@app/modules/core/constants/constants'
import { ICompaniesList } from '@app/modules/core/interfaces/companies'
import { IUsers } from '@core/interfaces/users'
import { SharedDataService } from '@core/services/shared-data.service'

import { TicketsDialogComponent } from '../../../../shared/component/tickets-dialog/tickets-dialog.component'
import { SidepullComponent } from '../sidepull.component'
@Component({
  selector: 'app-company-view',
  templateUrl: './company-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyViewComponent {
  @Input() companyData: ICompaniesList
  @Input() viewFlag: boolean
  @Output() getFlag = new EventEmitter<boolean>()
  users: IUsers
  isClicked: boolean
  constructor(
    private readonly dialog: MatDialog,
    private readonly sharedDataService: SharedDataService,
    @Inject(SidepullComponent) private readonly parent: SidepullComponent
  ) {
    this.isClicked = false
    this.users = this.sharedDataService.getUser()
  }
  /*** Show / hide companies list */
  showCompaniesList() {
    this.viewFlag = false
    this.getFlag.emit(this.viewFlag)
  }

  /*** Route to companyDashboard */
  toggle() {
    this.parent.toggle()
  }

    /*** Deos user have access to company creation */
  showAddCompanyControl() {
    return this.users.userType !== 'external'
      && !this.users.TvUser
      && this.companyData.permission
      && this.companyData.permission.includes(PERMISSIONS.create)
  }

  /*** Open ticket creation dialog box */
  onCreateTicket() {
    this.dialog.open(TicketsDialogComponent, {
      data: { title: 'Create New Ticket' },
      width: '600px',
    })
  }
}
