import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { LoginService } from '@app/modules/login/services/login.service'
import { SharedService } from '@app/modules/shared/shared.service'
import { FOOTER_CONST } from '@core/constants/footer.constants'
import { Subscription } from 'rxjs'

import { environment } from '../../../../../environments/environment'
import { NUMBER_CONSTANTS } from '../../constants/constants'
import { URL_CONSTANTS } from '../../constants/url-constants'
import { IUsers } from '../../interfaces/users'
import { IVersion } from '../../interfaces/version'
import { CoreService } from '../../services/core.service'
import { DateService } from '../../services/date.service'
import { SharedDataService } from '../../services/shared-data.service'

import { OrganizationExpirayModalComponent } from './organization-expiray-modal/organization-expiray-modal.component'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit, OnDestroy {
  canShowTimerFooter: boolean
  subscriptions: Subscription
  user: IUsers
  orgStatus: string
  date: string
  currentVersion: IVersion
  bgColor: string
  remTime
  color: string
  interval
  config: any // tslint:disable-line
  constructor(public sharedDataService: SharedDataService, public dialog: MatDialog, private readonly router: Router,
              private readonly dateService: DateService, public loginSvc: LoginService, public sharedService: SharedService,
              public coreService: CoreService) {
    this.subscriptions = new Subscription()
    this.canShowTimerFooter = false
    this.initConfig()
    this.subscriptions.add(this.coreService.footerEvent.subscribe(data => {
      if (data) {
        this.initConfig()
        this.getOrgStatus()
      } else {
        this.canShowTimerFooter = false
      }
    }))
  }
  /*** Sets footer object on load of component */
  ngOnInit() {
    this.getOrgStatus()
  }
  /*** Hide footer timer - displayed when user is in trial mode */
  hideTimerFooter() {
    this.canShowTimerFooter = false
  }
  /*** Gets Current Date */
  getDate(data: string): {ret: string, months: number, days: number} {
    return this.dateService.getDateTimeString(data)
  }
  /*** Gets version of the application from the server */
  getVersion() {
    this.subscriptions.add(this.coreService.getCurrentVersion().subscribe(data => {
      this.currentVersion = data
    }, err => {
    }))
  }
  /*** Opens link to manage subscriptions from admin portal */
  goToManageSubscription() {
  /*  const url = `${this.coreService.getEnvironment().v3BaseUrl}/access/login?domain=${this.loginSvc.getSubDomain()}&page=${2}`
    const win = window.open(url, '_blank')
    win.focus()*/
    this.router.navigateByUrl(FOOTER_CONST.SUBSCRIPTION_ROUTE).catch()
  }
  /*** initialize config object */
  initConfig() {
    this.user = this.sharedDataService.getUser()
    this.config = {
      trial: {
        msg: FOOTER_CONST.TRIAL_MSG,
        showBtn: this.user.canAccessAll,
        showTime: true,
        btnText: FOOTER_CONST.TRIAL_BTN_TEXT,
        btnFunc: () => {
          this.goToManageSubscription()
        },
        date: this.user.ITBTrialEndDate,
        timeOverFunc: () => {
          this.dialog.open(OrganizationExpirayModalComponent, { data: this.user, disableClose: true })
          this.canShowTimerFooter = false
        },
        styleFunc: (months, noOfdays) => {
          let stripColor = { background: FOOTER_CONST.TRIAL_VALID, color: FOOTER_CONST.TRIAL_BLACK }
          if (noOfdays > 10 || months > 0) {
            stripColor = { background: FOOTER_CONST.TRIAL_VALID, color: FOOTER_CONST.TRIAL_BLACK }
          } else if (noOfdays > 6 && noOfdays <= 10) {
            stripColor = { background: FOOTER_CONST.TRIAL_10DAYS, color: FOOTER_CONST.TRIAL_BLACK }
          } else if (noOfdays > 3 && noOfdays <= 6) {
            stripColor = { background: FOOTER_CONST.TRIAL_6VALID, color: FOOTER_CONST.TRIAL_BLACK }
          } else if (noOfdays <= 3 && months === 0) {
            stripColor = { background: FOOTER_CONST.TRIAL_INVALID, color: FOOTER_CONST.TRIAL_WHITE }
          }
          this.bgColor = stripColor.background
          this.color = stripColor.color
        },
      },
      'cancellation requested': {
        msg: FOOTER_CONST.CANCELLATION_REQ_MSG,
        showTime: true,
        showBtn: this.user.canAccessAll,
        btnText: FOOTER_CONST.CANCELLATION_REQ_BTN_TEXT,
        btnFunc: () => {
          this.goToManageSubscription()
        },
        date: this.user.cancellationDate,
        timeOverFunc: () => {
          this.orgStatus = FOOTER_CONST.CANCELLED
          this.user.orgStatus = FOOTER_CONST.CAP_CANCELLED
          this.sharedDataService.setUser(this.user)
          this.config[this.orgStatus.toLowerCase()].styleFunc(this.remTime.months, this.remTime.days)
          this.dialog.open(OrganizationExpirayModalComponent, { data: this.user, disableClose: true })
        },
        styleFunc: (months, noOfdays) => {
          this.bgColor = FOOTER_CONST.CANCEL_BG_COLOR
          this.color = FOOTER_CONST.TRIAL_WHITE
        },
      },
      cancelled: {
        msg: FOOTER_CONST.CANCELLED_MSG,
        showTime: false,
        showBtn: false,
        date: false,
        styleFunc: () => {
          this.bgColor = FOOTER_CONST.CANCELLED
          this.color = FOOTER_CONST.TRIAL_WHITE
        },
      },
    }
  }
  /*** Gets organization status */
  getOrgStatus() {
    this.subscriptions.add(this.loginSvc.getConfig().subscribe(org => {
      const orgStatus = org.orgStatus
      this.user = this.sharedDataService.getUser()
      this.user.orgStatus = orgStatus
      this.sharedDataService.setUser(this.user)
      this.orgStatus = orgStatus
      const trialDate = new Date(this.user.ITBTrialEndDate)
      const cancellationDate = this.user.cancellationDate ? new Date(this.user.cancellationDate) : ''
      if (orgStatus.toLowerCase() === 'trial' &&
        this.dateService.getDatesDifference(new Date(trialDate), new Date()) <= 0) {
        this.openExpiryModal()
      }
      if (orgStatus.toLowerCase() === 'cancellation requested' &&
        this.dateService.getDatesDifference(new Date(cancellationDate), new Date()) <= 0 && cancellationDate !== '') {
        this.orgStatus = 'cancelled'
        this.config[this.orgStatus.toLowerCase()].styleFunc()
        this.user.orgStatus = 'Cancelled'
        this.openExpiryModal()
      }
      if (orgStatus.toLowerCase() === 'cancelled') {
        this.openExpiryModal()
      }
      this.getVersion()
      if ((this.config[this.orgStatus.toLocaleLowerCase()])) {
        this.canShowTimerFooter = true
        // const fixedStyle = this.config[this.orgStatus].style === 'fixed'
        this.remTime = this.getDate(this.config[this.orgStatus.toLocaleLowerCase()].date)
        if (this.config[this.orgStatus.toLocaleLowerCase()] && this.config[this.orgStatus.toLocaleLowerCase()].showTime &&
          this.dateService.getDatesDifference(new Date(this.config[this.orgStatus.toLocaleLowerCase()].date), new Date()) > 0) {
          this.interval = setInterval(() => {
            this.remTime = this.getDate(this.config[this.orgStatus.toLocaleLowerCase()].date)
            this.config[this.orgStatus.toLocaleLowerCase()].styleFunc(this.remTime.months, this.remTime.days)
            if (this.dateService.getDatesDifference(new Date(this.config[this.orgStatus.toLocaleLowerCase()].date), new Date()) <= 0) {
              this.config[this.orgStatus.toLocaleLowerCase()].timeOverFunc()
              clearInterval(this.interval)
            }
          }, 1000)
        }
        this.config[this.orgStatus.toLowerCase()].styleFunc(this.remTime.months, this.remTime.days)
      }
      if (orgStatus.toLowerCase() === 'trial' &&
        this.dateService.getDatesDifference(new Date(trialDate), new Date()) <= 0) {
        this.canShowTimerFooter = false
      }
    }))
  }

    /*** If trial has expired, shows the modal and does not allow user to close the modal */
  openExpiryModal() {
    this.coreService.updateSidePull(true)
    if (!this.router.url.includes(FOOTER_CONST.REGISTER_ROUTE)) {
      /*this.dialog.open(OrganizationExpirayModalComponent, { data: this.user, disableClose: true })*/
      this.router.navigateByUrl(FOOTER_CONST.REGISTER_ROUTE).catch()
    }
  }
  /*** Unsubscribe all subscription when component is destroyed */
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
    clearInterval(this.interval)
  }
}
