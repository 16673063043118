<div class="progress-bar" *ngIf="progressValue != undefined">
    <mat-progress-bar class="bar-normal" mode="determinate" value="{{progressValue}}" *ngIf="progressValue >= 50"></mat-progress-bar>
    <mat-progress-bar class="bar-warning" mode="determinate" value="{{progressValue}}" *ngIf="progressValue < 50 && progressValue >= 20"></mat-progress-bar>
    <mat-progress-bar class="bar-danger" mode="determinate" value="{{progressValue}}" *ngIf="progressValue < 20"></mat-progress-bar>
    <span class="progress-text-normal" *ngIf="progressValue >= 50">{{progressStatus}} </span>
    <span class="progress-text-warning" *ngIf="progressValue < 50 && progressValue >= 20">{{progressStatus}} </span>
    <span class="progress-text-danger" *ngIf="progressValue < 20">{{progressStatus}} </span>
</div>
<div class="progress-bar" *ngIf="progressValue == undefined">
  <span>-</span>
</div>
