import { Injectable } from '@angular/core'

import { IAssetsChartObject, IWidget, IWidgetConfiguration } from '../interfaces/widget.interface'

import { ThemeService } from './theme.service'
@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  constructor(private readonly themeService: ThemeService) { }
  /*** Asset warranty column definition */
  getAssetWarantyColoumDefs() {
    return [
      { field: 'companyName', sortable: true, filter: true },
      { field: 'assetName', sortable: true, filter: true },
      { field: 'expiryDate', sortable: true, filter: true },
      { field: 'remainingDays', sortable: true, filter: true },
    ]
  }
  /*** Pie chart configuration hardcoded */
  getPieChartConfig(widgetName: string, config: IWidgetConfiguration, resp: IWidget) {
    const themeColor = this.themeService.getActiveTheme().properties['--color']
    return {
      textStyle: {
        color: [themeColor],
        fontSize: 14,
      },
      color: ['#e14c42', '#00c5dc', '#716aca', '#ffb822', '#00bff3', '#afe911'],
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      legend: config.showLegends ? {
        itemWidth: 20,
        itemHeight: 6,
        borderRadius: 20,
        textStyle: {
          color: [themeColor],
          fontSize: 14,
        },
        orient: 'vertical',
        x: 'right',
        y: 'center',
        data: resp,
        left: '70%',
    } : undefined,
      series: [
        {
          name: widgetName,
          type: 'pie',
          radius: ['45%', '55%'],
          avoidLabelOverlap: true,
          label: {
            normal: {
              show: config.showLabels,
            },
          },
          center: config.center,
          data: resp,
        },
      ],
    }
  }
  /*******************End Pie Chart******************/

  getChartConfig(counts, colorr) {
    return {
      title: {
        text: 'OS',
        subtext: 'count',
        left: 'center',
        top: '35%',
        textStyle: {
          color: '#909090',
          fontWeight: 'normal',
          fontFamily: 'Lato',
          fontSize: 18,
        },
        subtextStyle: {
          color: '#909090',
          fontWeight: 'normal',
          fontFamily: 'Lato',
          fontSize: 14,
        },
      },
      textStyle: {
        color: ['themeColor'],
        fontSize: [16],
      },
      color: ['#e14c42', '#00c5dc', '#716aca', '#ffb822', '#00bff3', '#afe911'],
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      label: {
        fontSize: 10,
        color: '#909090',
      },
      series: [
        {
          name: 'Asset Widget',
          type: 'pie',
          radius: ['60%', '50%'],
          center: ['50%', '40%'],
          avoidLabelOverlap: true,
          color: colorr,
          label: {
            normal: {
              show: true,
            },
          },
          animation: false,
          labelLine: {
            show: !0,
            length: 25,
          },
          data: counts,
          tooltip: {
            backgroundColor: 'rgba(0,0,0,.75)',
            formatter: '{b0}: {c0} ({d0}%)',
            padding: [3, 5],
            textStyle: {
              fontSize: 13,
            },
          },
        },
      ],
    }
  }

}
