export const URL_CONSTANTS = {
  RIGHT_SIDE_BAR: {
    GET: (moduleName: string, itemId: string, panelName: string) => `${moduleName}/${itemId}/${panelName}`,

    GET_SIGNED_URL: (moduleName: string, itemId: string, companyId: string, fileName: string, type: string) =>
      `users/uploads/s3/${companyId}/${moduleName}/${itemId}/${fileName}?mimeType=${type}`,

    PUT: (moduleName: string, itemId: string, panelName: string, passwordId: string) =>
      `${moduleName}/${itemId}/${panelName}/${passwordId}`,
  },
  TEMPLATES: 'templates',
  APP: '/app',
  APP_DASHBOARD: '/app/dashboard',
  APP_DOCUMENTATION_KNOWLEDGEBASE: 'app/documentation/knowledgebase',
  DOCUMENTATION_RUNBOOK: 'documentation/runbook',
  DOCUMENTATION_KNOWLEDGEBASE: 'documentation/knowledgebase',
  LOGIN: '/login',
  GET_RIGHT_SIDE_NAVBAR_STATUS: 'users/',
  GET_PASSWORD_SCHEMA: 'organizations/metadata/passwordSettings',
}
