export const FOOTER_CONST = {
  CANCELLED: 'cancelled',
  CAP_CANCELLED: 'Cancelled',
  TRIAL: 'trial',
  REGISTER_ROUTE: 'admin/billing/register',
  SUBSCRIPTION_ROUTE: 'admin/billing/subscription',
  TRIAL_MSG: 'Your trial period is ending in  ',
  TRIAL_BTN_TEXT: 'Buy Now',
  CANCELLATION_REQ_MSG: 'Your organization will be canceled in ',
  CANCELLATION_REQ_BTN_TEXT: 'Revoke',
  CANCELLED_MSG: 'Your subscription has been canceled. ',
  TRIAL_VALID: 'trial-valid',
  TRIAL_BLACK: 'trial-black',
  TRIAL_WHITE: 'trial-white',
  TRIAL_10DAYS: 'trial-10days',
  TRIAL_6VALID: 'trial-6valid',
  TRIAL_INVALID: 'trial-invalid',
  CANCEL_BG_COLOR: 'cancellation-req',
}
