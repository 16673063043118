import { Injectable } from '@angular/core'
import { ApiService } from '@app/services/api.service'
import { Observable } from 'rxjs/internal/Observable'

import { IGroupList, IUserList } from '../interfaces/granular-permissions.interface'

@Injectable({
  providedIn: 'root',
})
export class GranularPermissionService {
  constructor(private readonly _apiService: ApiService) {}

  /***Gets all users */
  getAllUsers(): Observable<IUserList[]> {
    return this._apiService.get('users/list', 'Users')
  }

  /***Gets all user groups */
  getAllGroup(): Observable<IGroupList[]> {
    return this._apiService.get('users/group/list', 'Groups')
  }
}
