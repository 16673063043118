<div id="exportConntent" class="disp-none"> </div>
<div class="header-main-navbar">
    <div class="inner-header app-logo logo"  fxLayoutAlign="center center">
        <img width="180" src="images-library/logo.png" />
    </div>
</div>

<itb-page-wrapper [className]="'wrapper-shadow knowledgebase-section public doc-page-list'">
    <div *ngIf="isLoading"  class="wrapper-content" fxLayoutAlign="center center">
        <itb-spinner [spinnerSize]="50"></itb-spinner>
    </div>
    <div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="space-between center">
        <itb-page-title [title]="article.ITBRunBookName"></itb-page-title>
    </div>
    <div *ngIf="!isLoading && isExists" class="wrapper-content">
        <ckeditor disabled="true" [(ngModel)]="article.fileContent"  (ready)="onReady($event)" [config]="config" ></ckeditor>
    </div>
   
    <div *ngIf="!isLoading && !isExists" class="empty-state-image-full-height" fxLayoutAlign="center center">
        <itb-empty-state-image
          [imageClass]="'es-image img-404error'"
          [iconClassName]="'btn-innerico back'"
          [headingClass]="'heading'"
          [headingText]="'No Article found'"
          [subHeadingClass]="'text-default'"
          [buttonClass]="'btn btn-default'"
        ></itb-empty-state-image>
      </div>
    
</itb-page-wrapper>
