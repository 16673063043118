<span *ngIf = "params && params.raw "	[mdePopoverTriggerFor]="popover"
[mdePopoverEnterDelay]="300">
    {{params.raw}}
</span>
<mde-popover
#popover="mdePopover"
[mdePopoverOverlapTrigger]="false">
<mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row wrap">
                {{params.raw}}

          </div>
        </mat-card-content>
      </mat-card>
</mde-popover>
