<div [hidden]="hasCompanyViewFlag === true">
  <div class="sidepull-tab-icon">
    <itb-round-button [className]="'light'" [iconClassName]="'icon-ticket-new'" [tooltipText]="'Create Ticket'"
                      (onClickHandler)="onCreateTicket()"
                      *ngIf="users.userType !=='external' &&
                      ( users.mainIntegration && users.mainIntegration !=='autotask') && !users.TvUser"></itb-round-button>
    <itb-round-button [className]="'light'" [iconClassName]="'plus-c'" [tooltipText]="'Add a new Company'" [isLinkButton]="true"
                      [link]="'/app/creation/company'" *ngIf="users.userType !=='external'  && !users.TvUser && isAdd"
                      ></itb-round-button>
  </div>
  <div >
    <itb-input [formFieldName]="'query'" [parentForm]="searchForm" [label]="'Search'" [placeholder]="'Enter Text'"
               [type]="'text'" (onChangeHandler)="filterCompanies()">
    </itb-input>
  </div>
  <div *ngIf="isLoading" fxLayoutAlign="center center">
    <itb-spinner [spinnerType]="'m'"></itb-spinner>
  </div>
  <div id="sidePullCompanyScroll" class="side-pull-lazyload">
    <div class="list-view-group-container" [hidden]="isLoading" *ngFor="let item of companiesList">
      <div class="list-view-group-header" *ngIf="item.printFlag==1">
        {{item.letter}}
      </div>
      <ul>
        <li (click) ="setCurrentCompany(item)" >
          <a [href]="'/app/company/'+item.uuid"   onclick="return false;">
            <span class="text-master">{{item.name}}</span>
            <span class="hint-text">{{item.identifier}}</span>
          </a>
        </li>
      </ul>
    </div>
    <div *ngIf="isSmallLodaing" fxLayoutAlign="center center">
      <itb-spinner [spinnerType]="'s'"></itb-spinner> Loading ...
    </div>
    <div *ngIf="(!isLoading || !isSmallLodaing )&& companiesList && companiesList.length == 0">
      <itb-empty-state-image [imageClass]="'es-image img-nodata'" [headingClass]="'heading'" [headingText]="'NO DATA AVAILABLE'"></itb-empty-state-image>
    </div>
  </div>
</div>

<app-company-view *ngIf="hasCompanyViewFlag === true" [companyData]="companyData" [viewFlag]="hasCompanyViewFlag" (getFlag)="getFlag($event)"></app-company-view>
