<div *ngIf="!params.showPopOver">
  <a  [routerLink] = "baseURL" class="ag-cell-value name-field-table underline">{{ params.value }}</a>
</div>

<div *ngIf="params.showPopOver">
  <div>
    <a [mdePopoverTriggerFor]="popover" [mdePopoverEnterDelay]="300" [routerLink] = "baseURL" class="ag-cell-value name-field-table underline">{{ params.value }}</a>
  </div>
  <mde-popover #popover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
    <mat-card class="itb-popover">
      <div popoverBody class="popover-content">
        <h3 class="popover-title">Password Info : &nbsp;<b>{{ params.data.passwordName }}</b></h3>
        <div class="popover-details">
          <div class="mb-2 d-flex">
            <itb-text [className]="'text-small'" [text]="'UserName'"></itb-text> : &nbsp;
            <itb-text [className]="'text-small text-color-primary'" [text]=" params.data.userNamePassword"></itb-text>
            <itb-icon
              [tooltipText]="'Copy Username'"
              [className]="'copy'"
              (click)="copyToClipboard(params.data.userNamePassword)"
            ></itb-icon>
          </div>
          <div *ngIf="params.data.server" class="text-truncate mt-2 mb-2 d-flex">
            <itb-text [className]="'text-small'" [text]="'URL'"></itb-text> : &nbsp;&nbsp;
            <a href="" (click)="urlHandler($event)">{{params.data.server}}</a>
            <itb-icon
              *ngIf="params.data.server"
              [tooltipText]="'Copy URL'"
              [className]="'copy'"
              (click)="copyToClipboard(params.data.server)"
            ></itb-icon>
          </div>
          <div class="mt-2 d-flex align-items-center">
            <itb-text [className]="'text-small'" [text]="'Password:'"></itb-text>&nbsp;
            <itb-text [className]="'text-small'" [text]=" params.data.password || ''"></itb-text>
            <itb-icon
              *ngIf="!isShow"
              [tooltipText]="'Show Password'"
              [className]="'eye'"
              (click)="togglePassword($event)"
            ></itb-icon>
            <itb-icon
              *ngIf="isShow"
              [tooltipText]="'Hide Password'"
              [className]="'eye-da'"
              (click)="togglePassword($event)"
            ></itb-icon>
            <itb-icon [tooltipText]="'Copy Password'" [className]="'copy'" (click)="copyPassword()"></itb-icon>
          </div>
        </div>
      </div>
    </mat-card>
  </mde-popover>
</div>
