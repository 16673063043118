import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { stringConstants } from '../../../constants/constants'
import { GirdActionService } from '../../services/grid-actions.service'

@Component({
  selector: 'app-name-cell-renderer',
  templateUrl: './name-cell-renderer.component.html',
  styleUrls: ['./name-cell-renderer.component.scss'],
})
export class NameCellRendererComponent implements AgRendererComponent {
  params // used for optional params that are added dynamically for different types
  baseURL: string
  qureyParams: string
  showQueryParams: boolean
  constructor(public gridActionsService: GirdActionService, public route: ActivatedRoute) {
    this.qureyParams = ''
    this.showQueryParams = false
  }

  agInit(params: ICellRendererParams): void {
    if (params.value instanceof Array && !params.value.length) params.value = ''
    if (typeof params.value === 'boolean') params.value = params.value.toString()
    this.params = params
    if (this.params.baseURL && this.params.propertyName) {
      if (!this.params.propertyName || !this.params.data) {
        return
      }

      // Parent-Child Company Handling on configuration List
      if (this.params.baseURL.includes('configurations/view') && this.params.companyId)
        this.params.companyId = params.data.company ? params.data.company.companyUuid : ''

      this.params.companyId
        ? (this.baseURL = `/${this.params.baseURL.replace('$companyId', this.params.companyId)}/${
          this.params.data[this.params.propertyName]
          }`)
        : (this.baseURL = `/${this.params.baseURL.replace('company/$companyId/', '')}/${this.params.data[this.params.propertyName]}`)

      this.route.queryParams.subscribe(queryParams => {
        if (Object.keys(queryParams).length > 0 && this.params.baseURL.includes('configurations/view')) {
          //  this.baseURL += `?type=${queryParams.type}`
          this.qureyParams = queryParams.type
          this.showQueryParams = true
        }
      })
    } else {
      if (this.params.data !== undefined && this.params.data.id !== undefined) {
        this.baseURL = `/${this.params.baseURL}${this.params.data.id}`
        this.baseURL = this.baseURL.replace('https://', '')
      } else {
        if (this.params.data !== undefined && params.data.companyUuid != undefined) {
          this.baseURL = `${this.params.baseURL}${params.data.companyUuid}`
          this.params = { ...this.params, propertyName: 'snapshot' }
        } else this.baseURL = this.params.baseURL
      }
    }
  }

  refresh(): boolean {
    return false
  }

  nameHandler(e: Event): boolean {
    this.gridActionsService.setSelectedAction(stringConstants.DEFAULT_DATAGRID_NAVIGATE_TO_VIEW, this.params.data, this.params.rowIndex)
    if (this.params.showPopOver) {
      e.preventDefault()
      return false
    }
  }
}
