import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
// tslint:disable-next-line: max-line-length
import { ContactDashboardLinkComponent } from '@projects/company-dashboard/src/app/modules/contacts/components/contact-dashboard-link/contact-dashboard-link.component'

import { AssetPodsComponent } from '../../projects/company-dashboard/src/app/modules/pods/asset-pods/asset-pods.component'
// tslint:disable-next-line: max-line-length
import { PodsParentComponent } from '../../projects/company-dashboard/src/app/modules/pods/company-dashboard/pods-parent/pods-parent.component'

import { AppComponent } from './app.component'
import { CanAddCompanyGuard } from './guards/can-add-company-guard'
import { CanDeactivateGuard } from './guards/can-deactivate-guard'
import { RegistrationGuard } from './guards/can-register-activate.guard'
import { LoginGuard } from './guards/login.guard'
import { AccessErrorComponent } from './modules/core/components/access-error/access-error.component'
import { CompanyCreationComponent } from './modules/core/components/company-creation/company-creation.component'
import { NotFoundComponent } from './modules/core/components/not-found/not-found.component'
import { PublicViewArticleComponent } from './modules/core/components/public-view-article/public-view-article.component'
import { WelcomeScreenComponent } from './modules/core/components/welcome-screen/welcome-screen.component'
import { SubscriptionGuard } from './modules/core/guard/subscription.guard'
import { LoginComponent } from './modules/login/components/login/login.component'
import { ResetPasswordComponent } from './modules/login/components/reset-password/reset-password.component'

/***Application level routes defined here - other routes are in their respective modules. */
/***Previous version (v3) routes are also handled by simple redirection */
const routes: Routes = [
  {
    path: 'access/login',
    redirectTo: 'app/login',
    pathMatch: 'prefix',
  },
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'prefix',
  },
  {
    path: 'access/users/confirm/:id',
    component: ResetPasswordComponent,
  },
  {
    path: 'app',
    component: AppComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../../projects/global-dashboard/dashboard.module').then(mod => mod.DashboardModule),
        data: { title: 'Dashboard' },
        canActivate: [RegistrationGuard],
      },
      {
        path: 'company',
        loadChildren: () => import('../../projects/company-dashboard/src/app/company.module').then(mod => mod.CompanyDashboardModule),
        data: { title: 'Company Dashboard' },
      },
      {
        path: 'companyDashboard',
        redirectTo: 'company',
      },
      {
        path: 'admin',
        loadChildren: () => import('../../projects/admin-dashboard/src/app/app.module').then(mod => mod.AdminModule),
        data: { title: 'Admin Dashboard' },
      },
      {
        path: 'adl',
        loadChildren: () => import('../../projects/asset-discovery-lite/app/app.module').then(mod => mod.AssetDiscoveryMainModule),
        data: { title: 'Asset Discovery' },
      },
      {
        path: 'documentation',
        loadChildren: () => import('../../projects/documentation/documentation.module').then(mod => mod.DocumentationModule),
        data: { title: 'Documentation' },
      },
      {
        path: 'globalKnowledgebase',
        redirectTo: 'documentation',
      },
      {
        path: 'globalKnowledgebase/simpleView/:id',
        redirectTo: 'documentation/knowledgebase/view/:id',
      },
      {
        path: 'globalKnowledgebase/simpleEdit/:id',
        redirectTo: 'documentation/knowledgebase/edit/:id',
      },
      {
        path: 'globalKnowledgebase/simple',
        redirectTo: 'documentation/knowledgebase/add',
      },
      {
        path: 'globalRunbook',
        redirectTo: 'documentation/runbook/list',
      },
      {
        path: 'globalRunbook/:id',
        redirectTo: 'documentation/runbook/view/:id',
      },
      {
        path: 'companies',
        loadChildren: () => import('../../projects/global-dashboard/companies/companies/companies.module').then(mod => mod.CompaniesModule),
        data: { title: 'Companies' },
      },
      {
        path: 'dashboardCompany',
        redirectTo: 'companies',
      },
      {
        path: 'bi',
        component: AccessErrorComponent,
      },
      {
        path: 'backups',
        loadChildren: () => import('../../projects/global-dashboard/backups/crash-plan/crash-plan.module').then(mod => mod.CrashPlanModule),
        data: { title: 'Crash Plan' },
      },
      {
        path: 'dashboardBackup',
        redirectTo: 'backups',
      },
      {
        path: 'feedback',
        loadChildren: () => import('../../projects/global-dashboard/feedback/feedback.module').then(mod => mod.FeedbackModule),
        data: { title: 'Feedback' },
        canActivate: [SubscriptionGuard],
      },
      {
        path: 'surveyDashboard',
        redirectTo: 'feedback',
      },
      {
        path: 'question/list',
        redirectTo: 'feedback/questions',
      },
      {
        path: 'question/add',
        redirectTo: 'feedback/questions/add',
      },
      {
        path: 'survey/list',
        redirectTo: 'feedback/templates',
      },
      {
        path: 'survey/add',
        redirectTo: 'feedback/templates/add',
      },
      {
        path: 'clientSurvey/list',
        redirectTo: 'feedback/results',
      },
      {
        path: 'passwordList/:id',
        redirectTo: 'company/:id/passwords/list',
      },
      {
        path: 'addPassword/:id',
        redirectTo: 'company/:id/passwords/add',
      },
      {
        path: 'welcome',
        component: WelcomeScreenComponent,
        data: { title: 'ITBoost Welcome' },
      },

      { path: 'companyPods', component: PodsParentComponent },
      { path: 'companyAssetsPods', component: AssetPodsComponent },
      {
        path: 'contactDashboard',
        component: ContactDashboardLinkComponent,
        data: { title: 'Contact Dashboard' },
      },
      {
        path: 'creation/company',
        component: CompanyCreationComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [CanAddCompanyGuard],
      },
      {
        path: 'error',
        component: AccessErrorComponent,
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
      },
    ],
  },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    path: 'access',
    loadChildren: () => import('../../projects/global-dashboard/feedback/feedback.module').then(mod => mod.FeedbackModule),
    data: { title: 'Feedback' },
  },
  {
    path: 'app/knowledgebase/ViewKnowledgebasePublic',
    component: PublicViewArticleComponent,
    data: { title: 'Knowledgebase Article' },
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
