import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { BUTTONS, IMAGE_MAX_SIZE } from '@app/constants/ui-components.constants'
import { IFileType } from '@app/interfaces/ui-components.interface'
import { ModalService } from '@app/services/modal.service'
import { NOTIFICATION_CONSTANTS, RIGHT_SIDE_BAR, STRING_CONSTANTS } from '@core/constants/constants'
import { URL_CONSTANTS } from '@core/constants/url-constants'
import { IExpansionPanel } from '@core/interfaces/panels'
import { RightSideBarService } from '@core/services/right-side-bar.service'
import { forkJoin, Subscription } from 'rxjs'

@Component({
  selector: 'app-company-pods-attachment',
  templateUrl: './pods-attachment.component.html',
  styleUrls: ['./pods-attachment.component.scss'],
})
export class PodsAttachmentComponent implements OnInit, OnDestroy {
  @Input() moduleName: string
  @Input() itemId: string
  @Input() panelName: string
  @Input() companyId: string
  @Input() panelObj: IExpansionPanel
  @Input() hasEditPermissions: boolean

  // tslint:disable-next-line: no-any /*** file could be of 'File' or 'blob' type */
  files: any
  isDropZoneDisabled: boolean
  allFilesSize: number
  isUploaded: boolean
  subscriptions: Subscription
  basePath: string
  isFileNameInvalid: boolean

  constructor(private readonly rightSideBarSvc: RightSideBarService, private readonly modalService: ModalService) {
    this.files = []
    this.isDropZoneDisabled = false
    this.isUploaded = true
    this.allFilesSize = 0
    this.subscriptions = new Subscription()
    this.basePath = 'https://nc-uploads.s3-us-west-1.amazonaws.com'
    this.isFileNameInvalid = false
  }

  ngOnInit() {
    this.isDropZoneDisabled = !this.hasEditPermissions
    this.subscriptions.add(
      this.rightSideBarSvc.getAttachments(URL_CONSTANTS.RIGHT_SIDE_BAR.GET(this.moduleName,
        this.itemId, this.panelName)).subscribe(files => {
        if (files.length > 0) {
          if (files.length > 0) this.panelObj.isExpanded = true
          let imagesArray = []
          imagesArray = files.map(file => {
            file.path = file.path.substring(1)
            return this.rightSideBarSvc.getBlob(file.path + file.filename)
          })
          this.subscriptions.add(
            forkJoin(imagesArray).subscribe(allImages => {
              files.forEach((item, index) => {
                allImages[index].uuid = item.uuid
                allImages[index].name = item.filename
                allImages[index].imagePath = item.path + item.filename
              })
              this.files = [...allImages]
              this.files.forEach(file => {
                this.allFilesSize += file.size
              })
            })
          )
        }
      })
    )
  }

  async fileSelectHandler(event: IFileType) {
    this.isFileNameInvalid = false
    event.addedFiles.forEach(file => {
      if (!file.name.includes('#')) this.allFilesSize += file.size
    })
    if (this.files.length <= 20 && this.allFilesSize < IMAGE_MAX_SIZE) {
      let moduleName = ''
      switch (this.moduleName) {
        case 'templates/templatesData':
          moduleName = 'templates'
          break
        case 'assetdiscovery/discoverersystems':
          moduleName = 'assetdiscovery'
          break
        case 'trackers/whois':
          moduleName = 'trackers'
          break
        default:
          moduleName = this.moduleName
      }
      const companyId = this.companyId === '1' ? 'global' : this.companyId
      for (const item of event.addedFiles) {
        if (!item.name.includes('#')) {
          this.files.push(item)
          this.isUploaded = false
          const signedUrl = await this.rightSideBarSvc.getSignedUrl(URL_CONSTANTS.RIGHT_SIDE_BAR.GET_SIGNED_URL(moduleName,
            this.itemId, companyId, item.name, item.type)).toPromise()
          if (!this.isUploaded) {
            const imageUpload = await this.rightSideBarSvc.s3ImageUpload(item, signedUrl.destination).toPromise()
            if (!this.isUploaded) {
              signedUrl.size = item.size
              const imageUploadResponse = await this.rightSideBarSvc.uploadFile(signedUrl, URL_CONSTANTS.RIGHT_SIDE_BAR.GET(this.moduleName,
                this.itemId, this.panelName)).toPromise()
              imageUploadResponse.path = imageUploadResponse.path.substring(1)
              this.isUploaded = true
              this.panelObj.isExpanded = true
              Object.defineProperty(this.files[this.files.length - 1], 'uuid', {
                value: imageUploadResponse.uuid,
              })
              Object.defineProperty(this.files[this.files.length - 1], 'name', {
                value: imageUploadResponse.filename,
              })
              Object.defineProperty(this.files[this.files.length - 1], 'imagePath', {
                value: imageUploadResponse.path + imageUploadResponse.filename,
              })
            }
          }
        } else {
          this.isFileNameInvalid = true
        }
      }
    } else {
      event.addedFiles.forEach(file => {
        this.allFilesSize -= file.size
      })
    }
  }

  fileRemovHandler(id: string) {
    if (this.hasEditPermissions) {
      this.isUploaded = true
      this.isFileNameInvalid = false
      if (id) {
        this.modalService.showDeleteConfirmationModal(`${STRING_CONSTANTS.DELETE} ${RIGHT_SIDE_BAR.ATTACHMENT}`,
          NOTIFICATION_CONSTANTS.DELETE(RIGHT_SIDE_BAR.ATTACHMENT)).subscribe(res => {
          if (res === BUTTONS.EVENTS.DELETE) {
            this.subscriptions.add(
              this.rightSideBarSvc.deleteItem(URL_CONSTANTS.RIGHT_SIDE_BAR.PUT(this.moduleName,
                this.itemId, this.panelName, id)).subscribe(() => {
                this.files.splice(this.files.findIndex(item => item.uuid === id), 1)
                if (this.files.length === 0) this.panelObj.isExpanded = false
              })
            )
          }
        })
      } else {
        this.files.pop()
      }
    }
  }

  fileDownloadHandler(event: Event, imgUrl: string, name: string) {
    this.isFileNameInvalid = false
    event.preventDefault()
    this.isDropZoneDisabled = true
    imgUrl = imgUrl.substring(1)
    this.rightSideBarSvc.getBlob(imgUrl).subscribe(data => {
      const link = window.URL.createObjectURL(data)
      const a = document.createElement('a')
      a.href = link
      a.download = name
      a.click()
      this.isDropZoneDisabled = false
    })
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

}
