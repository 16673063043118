<div fxLayout="row" class="mt-3">
  <itb-select fxFlex="45" [formFieldName]="'activities'" [parentForm]="inputForm" [items]="activityOptions"
    (onChangeHandler)="activityChange($event)" [label]="'Select Activity'" ></itb-select>
  <itb-select fxFlex="45" [formFieldName]="'filters'" [parentForm]="inputForm" [items]="filterOptions"
    [label]="'Select Filter'" (onChangeHandler)="filterChange($event)"></itb-select>
  <app-activity-export [filterBy]="inputForm.value.filters" [filterActivity]="inputForm.value.activities" ></app-activity-export>
</div>
<ng-container>
  <div >
    <itb-input [formFieldName]="'searchName'" [parentForm]="inputForm" [label]="'Search Activities'"
               [placeholder]="'Enter Text'" (onChangeHandler)="changeActivities()"
               [type]="'text'" >
    </itb-input>
  </div>
<div id="sidePullActivityScroll"  class="side-pull-lazyload">
  <div *ngIf="isLoading" fxLayoutAlign="center center">
    <itb-spinner> </itb-spinner>
  </div>
  <div *ngIf="!isLoading">
  <div class="list-view-group-container" *ngFor="let activity of filteredActivitiesList | searchFilter : searchActivity ">
    <div class="list-view-group-header" *ngIf="activity.printFlag == 1">
      {{activity.letter}}
    </div>
    <ul>
      <li class="fs-12">
        <div fxLayout='row' >
          <span *ngIf="activity.action === 'POST'" class="sidepull-activity-img"> <itb-image [imageWidth]=20 [src]="'images-library/icon-library/plus.svg'"></itb-image></span>
          <span *ngIf="activity.action === 'DELETE'" class="sidepull-activity-img"><itb-image [imageWidth]=20  [src]="'images-library/icon-library/delete.svg'"></itb-image></span>
          <span *ngIf="activity.action === 'PUT' || activity.action === 'PATCH'" class="sidepull-activity-img"><itb-image [imageWidth]=20  [src]="'images-library/icon-library/edit.svg'"></itb-image></span>

          <div class="pl-2">
                <span>
                  <b class="fs-11" *ngIf="activity.action == 'POST' && activity.assetName !== 'Some data fields purged' && activity.assetName !== 'All data purged' && activity.assetName !== 'Global Knowledgebase folder'"><b>New </b></b>
                                                  <b class="fs-11" *ngIf="activity.action === 'POST' && activity.assetName === 'Some data fields purged'">{{activity.assetName}} </b>
                                                  <b class="fs-11" *ngIf="activity.action === 'POST' && activity.assetName === 'All data purged'">{{activity.assetName}} </b>
                                                  <b class="fs-11" *ngIf="activity.action === 'PUT' && activity.assetName === 'Global Knowledgebase folder'">{{activity.assetName}} </b>
                                                  <b class="fs-11" *ngIf="activity.assetName === 'Configuration'"><b>{{activity.assetName}} </b> </b>
                                                  <b class="fs-11" *ngIf="activity.assetName === 'Login Attempts'"><b>{{activity.assetName}} </b> </b>
                                                  <b class="fs-11" *ngIf="activity.assetName === 'Password Generator'">{{activity.assetName}} </b>
                                                  <b class="fs-11" *ngIf="activity.assetName === 'Login Method'">{{activity.assetName}} </b>
                                                  <b class="fs-12" *ngIf="activity.assetName !== 'Configuration'  && activity.assetName !== 'Some data fields purged' && activity.assetName !== 'All data purged' && activity.assetName !== 'Login Method'
                                                          && activity.assetName !== 'Password Generator' && activity.assetName !== 'Login Attempts' && activity.assetName !== 'Global Knowledgebase folder'"><b>{{activity.assetName}} </b></b><b class="fs-12"> {{activity.recordName}} </b>
                   <small class="fs-11" *ngIf="activity.action === 'DELETE'"><i>deleted </i> </small>
                   <small class="fs-11" *ngIf="activity.action === 'GET'"><i>viewed </i> </small>
                   <small class="fs-11" *ngIf="activity.action === 'POST' && activity.assetName !== 'Some data fields purged'"><i>added </i> </small>
                   <small class="fs-11" *ngIf="activity.action === 'PUT' || activity.action === 'PATCH'"><i>updated </i> </small>
                   <small> By </small>
                   <small>{{activity.user.userName}} </small>
                </span>
            <br>
            <span class="fs-10">{{activity.moment | date:'medium'}}</span>
            <span>-</span>
            <b>{{activity.companyName}}</b>
          </div>
        </div>
      </li>
    </ul>
  </div>
    <div *ngIf="isScrollLoader" fxLayoutAlign="center center">
      <itb-spinner [spinnerType]="'s'"> </itb-spinner>&nbsp;&nbsp;Loading ...
    </div>

    <div *ngIf="(!isLoading || !isScrollLoader )&& filteredActivitiesList.length == 0">
      <itb-empty-state-image [imageClass]="'es-image img-nodata'" [headingClass]="'heading'"
                             [headingText]="'NO DATA AVAILABLE'"></itb-empty-state-image>
    </div>
</div>
  </div>
</ng-container>
