export interface IAllCompanies {
  id?: string
  tagName?: string
}

export interface ICompaniesList {
  CompanyId?: number
  companyName?: string
  type?: string
  name?: string
  uuid?: string
}
export const resolutions = {
  MOBILE_RESOLUTION: 478,
  TABLET_RESOLUTION: 1200,
  STACKED_RESOLUTION: 480,
  LARGE_RESOLUTION: 1600,
}
export interface IAllBoards {
  id?: string
  name?: string
  _id?: string
  userCheckFlag?: boolean
}
export interface IAllCompanies {
  companyId?: string
  companyName?: string
  type?: string
  uuid?: string
  name?: string
  id?: string
  tagName?: string
}
export interface IFavCompanies {
  identifier?: string
  name?: string
  orgId?: string
  uuid?: string
  data?: IFavCompanies[]
  ITBCompanyId: number
  className: string
  type?: IFavCompaniesType
}
export interface PostBodySaveFavCompanies {
  companiesIdsArray: string[]
  data: { favourite: boolean }
}
export interface INumberWidgetBody {
  status?: boolean
  code?: number
  count?: number
  color: string
}
export interface IPieChartWidgetBody {
  name?: string
  value?: number
  data?: IPieChartWidgetBody[]
}

export interface INumberWidgetDetails {
  ticketRef?: number
  ticketSummary?: string
  board?: string
  resource?: string
  company?: string
  site?: string
  contact?: string
  type?: string
  team?: string
  priority?: string
  serviceLocation?: string
  source?: string
  agreement?: string
  date?: string
}

export interface IAllCompaniesList {
  name: string
  permission: string[]
  uuid: string
  id?: string
}

export interface IFavCompaniesType {
id?: number
name?: string
}
