import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material'
import { BUTTONS } from '@app/constants/ui-components.constants'
import { ModalService } from '@app/services/modal.service'
import { MODAL_WIDTH_CONSTANTS, NOTIFICATION_CONSTANTS, RIGHT_SIDE_BAR, STRING_CONSTANTS } from '@core/constants/constants'
import { URL_CONSTANTS } from '@core/constants/url-constants'
import { ILocalNotes } from '@core/interfaces/local-notes'
import { IExpansionPanel, ISwitcherItem } from '@core/interfaces/panels'
import { RightSideBarService } from '@core/services/right-side-bar.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-company-pods-notes',
  templateUrl: './pods-notes.component.html',
  styleUrls: ['./pods-notes.component.scss'],
})
export class PodsNotesComponent {

  @Input() switcherItem: ISwitcherItem
  @Input() moduleName: string
  @Input() itemId: string
  @Input() panelName: string
  @Input() panelObj: IExpansionPanel
  @Input() hasEditPermissions: boolean
  @ViewChild('activeNotesLists', { static: true }) modal
  localNotes: ILocalNotes[]
  filteredNotes: ILocalNotes[]
  isDisabled: boolean
  subscriptions: Subscription

  constructor(private readonly rightSideBarSvc: RightSideBarService, private readonly modalService: ModalService,
              private readonly dialog: MatDialog) {
    this.isDisabled = false
    this.subscriptions = new Subscription()
  }

  editLocalNote(id: string) {
    this.switcherItem.selectedObj = this.localNotes.find(note => note.uuid === id)
    this.switcherItem.isList = false
    this.switcherItem.currentView = RIGHT_SIDE_BAR.EDIT.toLowerCase()
  }

  viewLocalNote(id: string) {
    this.switcherItem.selectedObj = this.localNotes.find(note => note.uuid === id)
    this.switcherItem.isList = false
    this.switcherItem.currentView = RIGHT_SIDE_BAR.VIEW.toLowerCase()
    this.isDisabled = true
  }

  deleteLocalNote(id: string) {
    this.modalService.showDeleteConfirmationModal(`${STRING_CONSTANTS.DELETE} ${RIGHT_SIDE_BAR.NOTE}`,
      NOTIFICATION_CONSTANTS.DELETE(RIGHT_SIDE_BAR.NOTE)).subscribe(res => {
      if (res === BUTTONS.EVENTS.DELETE) {
        this.isDisabled = false
        this.subscriptions.add(
          this.rightSideBarSvc.deleteItem(URL_CONSTANTS.RIGHT_SIDE_BAR.PUT(this.moduleName,
            this.itemId, this.panelName, id)).subscribe(() => {
            this.localNotes.splice(this.localNotes.findIndex(note => note.uuid === id), 1)
            if (this.localNotes.length === 0) this.panelObj.isExpanded = false
            this.switcherItem.itemsLength--
            this.switcherItem.isList = true
          })
        )
      }
    })
  }

  saveLocalNote(note: ILocalNotes) {
    if (this.switcherItem.currentView === RIGHT_SIDE_BAR.ADD.toLowerCase()) {
      this.subscriptions.add(
        this.rightSideBarSvc.addNote(note, URL_CONSTANTS.RIGHT_SIDE_BAR.GET(this.moduleName,
          this.itemId, this.panelName)).subscribe(item => {
          this.localNotes.push(item)
          this.panelObj.isExpanded = true
          this.switcherItem.itemsLength++
          this.switcherItem.isList = true
          this.switcherItem.currentView = ''
        })
      )
    } else {
      this.subscriptions.add(
        this.rightSideBarSvc.editNote(note, URL_CONSTANTS.RIGHT_SIDE_BAR.PUT(this.moduleName,
          this.itemId, this.panelName, this.switcherItem.selectedObj.uuid)).subscribe(noteItem => {
          this.localNotes[this.localNotes.findIndex(item => item.uuid === noteItem.uuid)] = noteItem
          this.switcherItem.isList = true
          this.switcherItem.currentView = ''
        })
      )
    }
  }

  cancel() {
    this.switcherItem.currentView = ''
    this.switcherItem.isList = !this.switcherItem.isList
    this.isDisabled = false
  }

  ngOnInit() {
    if (this.switcherItem.isList) {
      this.subscriptions.add(
        this.rightSideBarSvc.getNotes(URL_CONSTANTS.RIGHT_SIDE_BAR.GET(this.moduleName,
          this.itemId, this.panelName)).subscribe(data => {
          this.localNotes = data
          this.switcherItem.itemsLength = this.localNotes.length
          if (this.localNotes.length > 0) this.panelObj.isExpanded = true
          this.filteredNotes = this.localNotes.filter(element => element.modalFlag)
        })
      )
    }
  }
  onAdd(item: IExpansionPanel) {
    item.isExpanded = true
    this.switcherItem.isList = false
    this.switcherItem.currentView = RIGHT_SIDE_BAR.ADD.toLowerCase()
    this.switcherItem.selectedObj = {
      description: '',
      priority: '',
      ispopup: false,
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
