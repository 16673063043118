import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ModalService } from '@app/services/modal.service'
import { TicketService } from '@app/services/ticket.service'
import { Subscription } from 'rxjs'

import { ApiService } from '../../../../services/api.service'
import { NOTIFICATIONS } from '../../constants/ticket-constants'
import { IDropDown, ITicket } from '../../interfaces/ticket.interface'

@Component({
  selector: 'app-tickets-dialog',
  templateUrl: './tickets-dialog.component.html',
})
export class TicketsDialogComponent implements OnInit {

  companyNames: string[]
  contactNames: string[]
  ticketForm: FormGroup
  ticketData: ITicket
  isDisabled: boolean
  isBoardSelected: boolean
  isStatusClicked: boolean
  isTypeClicked: boolean
  isSaving: boolean
  isDataLoaded: boolean
  hasNoStatuses: boolean
  hasNoTypes: boolean
  contactName: string
  companies
  contacts
  boards
  statuses: IDropDown[]
  types: IDropDown[]
  owners: IDropDown[]
  subscription: Subscription
  constructor(
    public dialogRef: MatDialogRef<TicketsDialogComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly modalService: ModalService,
    private readonly ticketService: TicketService,
    private readonly _api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.companies = data.company ? [data.company] : []
    this.contactName = data.contactName ? data.contactName : undefined
    this.contacts = []
    this.boards = []
    this.statuses = []
    this.types = []
    this.owners = []
    this.isDisabled = false
    this.isBoardSelected = false
    this.isStatusClicked = false
    this.isTypeClicked = false
    this.isSaving = false
    this.isDataLoaded = false
    this.hasNoStatuses = false
    this.hasNoTypes = false
    this.subscription = new Subscription()
  }
  ngOnInit() {
    this.statuses[0] = {
      id: 0,
      name: NOTIFICATIONS.SELECT_BOARD_FIRST,
      isSelected: true,
    }
    this.types[0] = {
      id: 0,
      name: NOTIFICATIONS.SELECT_BOARD_FIRST,
      isSelected: true,
    }
    this.subscription.add(this.ticketService.getBoards().subscribe(boards => {
      this.boards = boards
    }))
    this.subscription.add(this.ticketService.getTicketOwners().subscribe(owner => {
      this.owners = owner.filter(data => (data.licenseClass !== 'A'))
    }))
    if (this.companies.length <= 0) {
      this.subscription.add(
        this._api.get('companies', 'Company').subscribe(res => {
          this.companies = res
          this.companies[0].isSelected = true
        })
      )
    } else {
      this.isDisabled = true
      this.companies[0].isSelected = true
    }
    this.buildForm()
  }
  buildForm() {
    this.ticketForm = this.formBuilder.group({
      summary: ['', [Validators.required]],
      company: [{value: '', disabled: this.isDisabled}, [Validators.required]],
      contact: [''],
      contactName: [''],
      contactPhoneNumber: [''],
      board: ['', [Validators.required]],
      status: [{value: '', disabled: !this.isBoardSelected}, [Validators.required]],
      type: [{value: '', disabled: !this.isBoardSelected}],
      owner: [''],
      initialDescription: [''],
    })
  }
  setCompany(event) {
    this.ticketForm.controls.company.setValue({ identifier: event.source.value.identifier })
    this.subscription.add(
      this.ticketService.getContacts(event.source.value.uuid).subscribe(contactsList => {
        this.isDataLoaded = true
        if (contactsList.length) {
          this.contacts = contactsList.map(contact =>
            ({
              id: contact.id,
              name: `${contact.firstName} ${contact.lastName}`,
              phoneDirect: contact.phoneDirect ? contact.phoneDirect : '',
              isSelected: false,
            }))
          let index = 0
          if (this.contactName) {
            for (const contact of this.contacts) {
              if (contact.name === this.contactName) {
                break
              }
              index++
            }
            this.contacts[index].isSelected = true
          } else {
            this.contacts[index].isSelected = true
          }
          this.ticketForm.controls.contact.setValue({
            id: this.contacts[index].id,
            name: this.contacts[index].name,
          })
        }
      })
    )
  }
  setContact(event) {
    this.ticketForm.controls.contactName.setValue(event.source.value.name)
    this.ticketForm.controls.contactPhoneNumber.setValue(event.source.value.phoneDirect)
  }
  setBoard(event) {
    if (event.source.value) {
      this.subscription.add(this.ticketService.getStatuses().subscribe(statuses => {
        this.statuses = statuses.filter(element => (element.board && element.board.id === event.source.value.id))
        this.hasNoStatuses = this.statuses.length <= 0
      }))
      this.subscription.add(this.ticketService.getTypes().subscribe(type => {
        this.types = type.filter(element => (element.boardId && element.boardId === event.source.value.id))
        this.hasNoTypes = this.types.length <= 0
      }))
      this.isBoardSelected = true
      this.ticketForm.controls.status.enable()
      this.ticketForm.controls.type.enable()
    }
  }
  onClose() {
    this.dialogRef.close()
  }
  onSave() {
    if (this.ticketForm.valid) {
      this.ticketForm.controls.company.enable()
      const ticket = {
        summary: this.ticketForm.value.summary,
        initialDescription: this.ticketForm.value.initialDescription,
        board: {
          id: this.ticketForm.value.board.id,
          name: this.ticketForm.value.board.name,
        },
        type: {
          id: this.ticketForm.value.type.id,
          name: this.ticketForm.value.type.name,
        },
        status: {
          id: this.ticketForm.value.status.id,
          name: this.ticketForm.value.status.name,
        },
        company: {
          identifier: this.ticketForm.value.company.identifier,
        },
        contact: {
          id: this.ticketForm.value.contact.id,
          name: this.ticketForm.value.contact.name,
        },
        contactName: this.ticketForm.value.contactName,
        contactPhoneNumber: this.ticketForm.value.contactPhoneNumber,
        owner: {
          id: this.ticketForm.value.owner.id,
          identifier: this.ticketForm.value.owner.identifier,
        },
      }
      this.isSaving = true
      this.subscription.add(this.ticketService.postTicket(ticket).subscribe(response => {
        if (response.status) {
          this.modalService.showAlertNotification(
            NOTIFICATIONS.SUCCESS_MODE,
            NOTIFICATIONS.SAVE_MESSAGE, '',
            NOTIFICATIONS.SAVED
          )
          this.dialogRef.close('success')
        } else {
          this.modalService.showAlertNotification(
            NOTIFICATIONS.ERROR_MODE,
            NOTIFICATIONS.ERROR_MESSAGE,
            'TICKET',
            NOTIFICATIONS.UNSAVED
          )
        }
        this.isSaving = false
      }))
    }
  }
}
