import { IHeader } from '@ag-grid-community/all-modules'
import { DatePipe } from '@angular/common'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { IColumnState, IHeaders } from '@app/interfaces/ui-components.interface'
import { SharedService } from '@app/services/shared.service'
import { IAssets } from '@core/interfaces/side-pull'
import { CoreService } from '@core/services/core.service'
import { UtilService } from '@core/services/utils.service'
import { stringConstants } from '@projects/ag-grid-customized/src/app/constants/constants'
import { GirdActionService } from '@projects/ag-grid-customized/src/app/data-grid/services/grid-actions.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-asset-statistics-dialog',
  templateUrl: './asset-statistics-dialog.component.html',
  providers: [GirdActionService],
})
export class AssetStatisticsDialogComponent implements OnInit, OnDestroy {
  name: string
  defaultColDef: {}
  columnDefs: IHeaders[]
  subscriptions: Subscription
  companyId: string
  rowData: IAssets[]
  isLoading: boolean
  userColumnState: IColumnState[]
  isInitialRender: boolean
  isEmpty: boolean
  isColLoaded: boolean
  isFirst: boolean
  isError: boolean
  completeCount: number
  constructor(
    public dialogRef: MatDialogRef<AssetStatisticsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data,
    private readonly coreService: CoreService,
    private readonly sharedService: SharedService,
    private readonly router: Router,
    private readonly gridActionsService: GirdActionService,
    private readonly utilService: UtilService
  ) {
    this.name = data.type
    this.companyId = data.companyId
    this.isInitialRender = true
    this.isError = false
    this.completeCount = 0
    this.isFirst = true
    this.isLoading = true
    this.isColLoaded = false
    this.columnDefs = []
    this.rowData = []
    this.subscriptions = new Subscription()
    this.defaultColDef = {
      filter: 'agTextColumnFilter',
    }
  }
    /*** Gets data for the grid */
  ngOnInit() {
    this.columnDefs = this.utilService.getCompanyAssetsWarrnatyColDefs(this.companyId)
    this.subscriptions.add(
      this.coreService.getCompanyDashboardColumnState('assetStats').subscribe(
        response => {
          this.isColLoaded = true
          this.isLoading = false
          if (response.length) {
            this.userColumnState = response
          }
          this.isEmpty = false
        },
        error => { },
        () => { }
      )
    )
    this.subscriptions.add(this.gridActionsService.action.subscribe(action => this.actionClicked(action)))
  }

    /*** Saves grid columns state when a user changes column positions etc. */
  columnStateChanged(state: IColumnState[]) {
    state.forEach((col, i) => {
      col[`${'position'}`] = i
    })
    this.coreService.saveCompanyDashboardColumnState(state, 'assetStats').subscribe(
      response => { },
      error => { },
      () => { }
    )
  }

    /*** Gets data with pagination for ag grid server side implementation */
  getRows(params: Params) {
    Object.keys(params.request.filterModel).length || Object.keys(params.request.sortModel).length ?
      this.gridActionsService.filterChanged$.next(true) : this.gridActionsService.filterChanged$.next(false)

    let pageNo: number
    const { startRow, endRow } = params.request
    pageNo = this.setOffset(startRow)
    this.subscriptions.add(
      this.utilService.getTotalStatsCounts(this.companyId, this.name).subscribe(res => {
        this.completeCount = res.count
        this.subscriptions.add(
          this.utilService.getStatsDetails(this.companyId, this.name, params.request, pageNo).subscribe(
            resp => {
              this.rowData = []
              resp.forEach(element => {
                this.rowData.push({
                  uuid: element.uuid,
                  configName: element.configName,
                  type: element.type,
                  status: element.status,
                  AssetDate: new DatePipe('en-US').transform(element.AssetDate, 'dd/MM/yyyy'),
                  recordStatus: element.recordStatus,
                  ipAddress: element.ipAddress,
                  maintenanceWindow: element.maintenanceWindow,
                  tags: element.tags,
                  name: element.name,
                  domain: element.domain,
                  biosMFG: element.biosMFG,
                  biosName: element.biosName,
                  upTime: element.upTime,
                  lastLoginName: element.lastLoginName,
                  serialNumber: element.serialNumber,
                  purchaseDate: new DatePipe('en-US').transform(element.purchaseDate, 'dd/MM/yyyy'),
                  processorName: element.processorName,
                  totalMemory: element.totalMemory,
                  installDate: new DatePipe('en-US').transform(element.installDate, 'dd/MM/yyyy'),
                  purchaseBy: element.purchaseBy,
                  os: element.os,
                  osInfo: element.osInfo,
                  osInstallDate: new DatePipe('en-US').transform(element.osInstallDate, 'dd/MM/yyyy'),
                  dnsInfo: element.dnsInfo,
                  routerAddress: element.routerAddress,
                  managmentPort: element.managmentPort,
                })
              })
              this.isEmpty = (this.rowData.length === 0 || this.rowData === undefined) && this.isFirst ? true : false
              this.isColLoaded = (this.rowData.length === 0 || this.rowData === undefined) && this.isFirst ? false : true
              this.isFirst = false

              const lastRow = this.completeCount <= endRow ? this.completeCount : -1
              if (resp.length === 0) {
                this.gridActionsService.showNoRowsOverlay()
                params.successCallback([], 0)
                return
              }
              this.gridActionsService.hideNoRowsOverlay()
              params.successCallback(resp, lastRow)
            },
            error => {
              params.failCallback()
              this.isEmpty = false
              this.isError = true
              this.isColLoaded = false
              this.isFirst = false
            }
          )
        )
      })
    )
  }
  setOffset(offset: number): number {
    return (offset = 0 ? 1 : offset / 20 + 1)
  }

    /*** Handles ag grid action event */
  actionClicked = action => {
    switch (action.actionType) {
      case stringConstants.DEFAULT_DATAGRID_NAVIGATE_TO_VIEW:
        this.router.navigateByUrl(`app/company/${this.companyId}/configurations/view/${action.data.uuid}`).catch()
        this.dialogRef.close()
        break
      default:
        return
    }
  }

    /*** Unsubscribes from all subscriptions */
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
