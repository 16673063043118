import * as discovererSystems from '../actions/adl.actions'
import {
  DiscovererSystemState,
  initialDsState,
} from '../states/adl.states'

export function reducer(state = initialDsState, action: discovererSystems.AdlAction): DiscovererSystemState {
  switch (action.type) {
    case discovererSystems.LOAD_DISCOVERERSYSTEMS: {
      const data = action
      return {
        ...state,
        isLoading: true,
      }
    }
    case discovererSystems.LOAD_DISCOVERERSYSTEMS_FAIL: {
      const isError = action.payload
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError,
      }
    }
    case discovererSystems.LOAD_DISCOVERERSYSTEMS_SUCCESS: {
      const data = action.payload
      return {
        ...state,
        data,
        isLoading: false,
        isLoaded: true,
        isError: false,
      }
    }
    default:
      return { ...state }
  }
}
