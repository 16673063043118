import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { DateService } from '@app/modules/core/services/date.service'
import { SharedDataService } from '@app/modules/core/services/shared-data.service'
import { IUsers } from '@core/interfaces/users'

@Injectable({
    providedIn: 'root',
})

export class RegistrationGuard implements CanActivate {

    user: IUsers
    constructor(private readonly sharedService: SharedDataService, private readonly dateService: DateService,
                private readonly router: Router) {
                    this.user = this.sharedService.getUser()
    }

    canActivate() {
        if (this.user.orgStatus.toLowerCase() === 'cancelled' ||
            this.user.orgStatus.toLowerCase() === 'trial' &&
            this.dateService.getDatesDifference(new Date(this.user.ITBTrialEndDate), new Date()) <= 0) {
                this.router.navigateByUrl('/app/admin/billing/register')
                return false
            }
        return true
    }
}
