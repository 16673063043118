
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store'

import * as fromCommunicationChannel from '../reducers/communication.reducers'
import * as fromUserMenuChannel from '../reducers/user-menu.reducers'
import * as fromStates from '../states'

export interface CompanyDashboardState {

  UserMenu: fromUserMenuChannel.UserMenuState,
  communications: fromStates.CommunicationActionState
}

export const reducers: ActionReducerMap<CompanyDashboardState> = {
  UserMenu: fromUserMenuChannel.menuReducer,
  communications: fromCommunicationChannel.reducer,
}

export const getCommunicationChannel = createFeatureSelector<CompanyDashboardState>('communicationChannel')

export const getUserMenu = createSelector(
  getCommunicationChannel,
  state => state.UserMenu
)
export const getCommunications = createSelector(
  getCommunicationChannel,
  state => state.communications
)

export const selectUserMenu = createSelector(
  getUserMenu,
  userMenu => userMenu.data
)

export const currentUserMenu = createSelector(
  getUserMenu,
  userMenu => userMenu.selectedMenu
)

export const currentCompany = createSelector(
  getUserMenu,
  userMenu => userMenu.selectedCompany
)
