<itb-page-wrapper [className]="'wrapper-flat'">
  <itb-page-title [title]="'ADD NEW COMPANY'"></itb-page-title>
  <itb-breadcrumbs [links]="links" [icons]="icons" (iconHandler)="iconClickHandler($event)"></itb-breadcrumbs>
  <div class="wrapper-content">
    <itb-inpage-heading [text]="'Company Details'" [hasDivider]="true" [hasButton]="false"></itb-inpage-heading>
    <form [formGroup]="companyForm">
      <div fxLayout="row wrap">
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
          <itb-input [formFieldName]="'name'" [parentForm]="companyForm" [label]="'Name'" [type]="'text'" [isRequired]="true"></itb-input>
          <itb-info-message
            *ngIf="isSubmittedFlag && !companyForm.valid && companyForm.get('name').errors?.required"
            [text]="'Required'"
            [className]="'info-danger'"
          ></itb-info-message>
          <itb-info-message
            *ngIf="!companyForm.valid && companyForm.get('name').errors?.maxlength"
            [text]="''"
            [className]="'info-danger'"
          ></itb-info-message>
          <itb-info-message
            *ngIf="!companyForm.valid && companyForm.get('name').errors?.minlength"
            [text]="'Please enter at least 3 characters'"
            [className]="'info-danger'"
          ></itb-info-message>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
          <itb-input
            [formFieldName]="'identifier'"
            [parentForm]="companyForm"
            [label]="'company identifier'"
            [type]="'text'"
            [isRequired]="true"
          ></itb-input>
          <itb-info-message
            *ngIf="isSubmittedFlag && !companyForm.valid && companyForm.get('identifier').errors?.required"
            [text]="'Required'"
            [className]="'info-danger'"
          ></itb-info-message>
          <itb-info-message
            *ngIf="!companyForm.valid && companyForm.get('identifier').errors?.maxlength"
            [text]="''"
            [className]="'info-danger'"
          ></itb-info-message>
          <itb-info-message
            *ngIf="this.identifierExist"
            [text]="'A company with this identifier already exists'"
            [className]="'info-danger'"
          ></itb-info-message>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100" *ngIf="autoTaskFlag">
          <itb-input
            [formFieldName]="'phoneNumber'"
            [parentForm]="companyForm"
            [label]="'PHONE NUMBER'"
            [type]="'text'"
            [isRequired]="true"
          ></itb-input>
          <itb-info-message
            *ngIf="isSubmittedFlag && !companyForm.valid && companyForm.get('phoneNumber').errors?.required"
            [text]="'Required'"
            [className]="'info-danger'"
          ></itb-info-message>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100" *ngIf="autoTaskFlag">
          <itb-select
            [items]="members"
            [formFieldName]="'companyMembers'"
            [parentForm]="companyForm"
            [label]="'OWNER RESOURSE'"
            [isMultiple]="false"
            [isDataInStrings]="false"
            [isRequired]="true"
          >
          </itb-select>
          <itb-info-message
            *ngIf="isSubmittedFlag && !companyForm.valid && companyForm.get('companyMembers').errors?.required"
            [text]="'Required'"
            [className]="'info-danger'"
          ></itb-info-message>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
          <itb-select-with-button
            *ngIf="(!autoTaskFlag && !syncFlag) || (!autoTaskFlag && syncFlag)"
            [formField]="'types'"
            [parentForm]="companyForm"
            [items]="companyTypeOptions"
            [iconClassName]="'plus-bold'"
            [className]="'btn-square-lg'"
            [tooltipText]="'Add'"
            [label]="'Type'"
            [isReactive]="true"
            [isRequired]="true"
            [isMultiple]="!autoTaskFlag && syncFlag"
            (onClickHandler)="addNewCompanyTypeHandler()"
            (onChangeHandler)="changeTypeHandler($event)"
          >
          </itb-select-with-button>
          <itb-select
            *ngIf="(autoTaskFlag && !syncFlag) || (autoTaskFlag && syncFlag)"
            [items]="companyTypeOptions"
            [formFieldName]="'types'"
            [parentForm]="companyForm"
            [label]="'Type'"
            [isMultiple]="false"
            [isDataInStrings]="false"
            [isRequired]="true"
          >
          </itb-select>
          <itb-info-message
            *ngIf="isSubmittedFlag && !companyForm.valid && companyForm.get('types').errors?.required"
            [text]="'Required'"
            [className]="'info-danger'"
          ></itb-info-message>
          <itb-info-message
            *ngIf="syncFlag && !typesValidator && !autoTaskFlag && typesValueLengthValidatior"
            [text]="'Selected type is not synced'"
            [className]="'info-warning'"
          ></itb-info-message>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
          <itb-select-with-button
            *ngIf="(!autoTaskFlag && !syncFlag) || (!autoTaskFlag && syncFlag)"
            [formField]="'status'"
            [parentForm]="companyForm"
            [items]="companyStatusOptions"
            [iconClassName]="'plus-bold'"
            [className]="'btn-square-lg'"
            [tooltipText]="'Add'"
            [label]="'Status'"
            [isReactive]="true"
            [isRequired]="true"
            (onClickHandler)="addNewCompanyStatusHandler()"
          >
          </itb-select-with-button>
          <itb-select
            *ngIf="(autoTaskFlag && !syncFlag) || (autoTaskFlag && syncFlag)"
            [items]="companyStatusOptions"
            [formFieldName]="'status'"
            [parentForm]="companyForm"
            [label]="'Status'"
            [isMultiple]="false"
            [isRequired]="true"
            [isDataInStrings]="false"
          >
          </itb-select>
          <itb-info-message
            *ngIf="isSubmittedFlag && !companyForm.valid && companyForm.get('status').errors?.required"
            [text]="'Field is required'"
            [className]="'info-danger'"
          ></itb-info-message>
          <itb-info-message
            *ngIf="syncFlag && companyForm.get('types').value && companyForm.get('status').value && !companyForm.get('status').value.id"
            [text]="'Selected status is not synced'"
            [className]="'info-warning'"
          ></itb-info-message>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
          <itb-input
            [formFieldName]="'addressLine1'"
            [parentForm]="companyForm"
            [label]="'address 1'"
            [type]="'text'"
            [isRequired]="true"
          ></itb-input>
          <itb-info-message
            *ngIf="isSubmittedFlag && !companyForm.valid && companyForm.get('addressLine1').errors?.required"
            [text]="'Required'"
            [className]="'info-danger'"
          ></itb-info-message>
          <itb-info-message
            *ngIf="!companyForm.valid && companyForm.get('addressLine1').errors?.maxlength"
            [text]="''"
            [className]="'info-danger'"
          ></itb-info-message>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
          <itb-input [formFieldName]="'addressLine2'" [parentForm]="companyForm" [label]="'address 2'" [type]="'text'"></itb-input>
          <itb-info-message
            *ngIf="!companyForm.valid && companyForm.get('addressLine2').errors?.maxlength"
            [text]="''"
            [className]="'info-danger'"
          ></itb-info-message>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
          <itb-select
            [parentForm]="companyForm"
            [items]="countries"
            (onChangeHandler)="changeCountryHandler($event)"
            [formFieldName]="'country'"
            [label]="'country'"
          ></itb-select>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100" *ngIf="itsUSA">
          <itb-select [isReactive]="false" [isSelectWithObjects]="true" [items]="states" [label]="'state'" (onChangeHandler)="onStateChangeEvent($event.source.value.label)"></itb-select>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100" *ngIf="!itsUSA">
          <itb-input [parentForm]="companyForm" [formFieldName]="'state'" [label]="'state'" [type]="'text'"></itb-input>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
          <itb-input [formFieldName]="'city'" [parentForm]="companyForm" [label]="'city'" [type]="'text'"></itb-input>
          <itb-info-message
            *ngIf="!companyForm.valid && companyForm.get('city').errors?.maxlength"
            [text]="'Maximum length Exceeded'"
            [className]="'info-danger'"
          ></itb-info-message>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
          <itb-input [formFieldName]="'zip'" [parentForm]="companyForm" [label]="'zip code'" [type]="'text'"></itb-input>
          <itb-info-message
            *ngIf="!companyForm.valid && companyForm.get('zip').errors?.maxlength"
            [text]="'Maximum length Exceeded'"
            [className]="'info-danger'"
          ></itb-info-message>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100" *ngIf="autoTaskFlag">
          <itb-select
            [items]="classifications"
            [formFieldName]="'companyClassification'"
            [parentForm]="companyForm"
            [label]="'COMPANY CLASSIFICATION'"
            [isMultiple]="false"
            [isDataInStrings]="false"
          >
          </itb-select>
          <itb-info-message
            *ngIf="isSubmittedFlag && !companyForm.valid && companyForm.get('companyClassification').errors?.required"
            [text]="'Required'"
            [className]="'info-danger'"
          ></itb-info-message>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
          <itb-date-time-picker
            [hourFormate]="'false'"
            [placeholder]="'Date acquired'"
            (onChangeHandler)="dateHandler($event)"
            [pickerType]="'calendar'"
            [parentForm]="companyForm"
            [formFieldName]="'date'"
          ></itb-date-time-picker>
          <itb-info-message
            *ngIf="isSubmittedFlag && !companyForm.valid && companyForm.get('date').errors?.required"
            [text]="'Required'"
            [className]="'info-danger'"
          ></itb-info-message>
        </div>
        <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100">
          <itb-input [parentForm]="companyForm" [formFieldName]="'website'" [label]="'website'" [type]="'text'"></itb-input>
        </div>
      </div>
    </form>
  </div>
  <itb-dynamic-action-panel>
    <itb-default-button
      [text]="'Save'"
      [className]="'primary'"
      [iconClassName]="'save'"
      (onClickHandler)="saveHandler('save')"
      [isLoading]="isButtonLoading"
    ></itb-default-button>
    <itb-default-button
      [text]="'Back'"
      [className]="'default'"
      [iconClassName]="'back'"
      (onClickHandler)="backHandler('back')"
    ></itb-default-button>
  </itb-dynamic-action-panel>
</itb-page-wrapper>
