import { DatePipe } from '@angular/common'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Params, Router } from '@angular/router'
import { ConnectwiseControlComponent } from '@app/connectwise-control/connectwise-control.component'
import { IColumnState, IHeaders } from '@app/interfaces/ui-components.interface'
import { stringConstants } from '@projects/ag-grid-customized/src/app/constants/constants'
import { GirdActionService } from '@projects/ag-grid-customized/src/app/data-grid/services/grid-actions.service'
import { Subscription } from 'rxjs'

import { MODAL_WIDTH_CONSTANTS } from '../../../../../../../../src/app/modules/core/constants/constants'

import { dashboardContants } from './../../../company-dashboard/constants/constants'
import { Assets, FavoriteAssets, Source2 } from './../../../company-dashboard/interfaces/company-dashboard'
import { WidgetType } from './../../../company-dashboard/interfaces/menu'
import { RoundButton } from './../../../company-dashboard/interfaces/ui-library'
import { AssetService } from './../../../company-dashboard/services/assets/asset.service'
import { CompanyDashboardService } from './../../../company-dashboard/services/companyDashboard/company-dashboard.service'
import { AssetWarrantyService } from './../../../company-dashboard/services/companyWarranty/asset-warranty.service'

@Component({
  selector: 'app-company-pods-fav-assets',
  templateUrl: './pods-fav-assets.component.html',
  styleUrls: ['./pods-fav-assets.component.scss'],
  providers: [GirdActionService],
})
export class PodsFavAssetsComponent implements OnInit, OnDestroy {
  wrapperClass: string
  @Input() companyId: string
  @Input() widgetData: WidgetType
  @Output() contentReady = new EventEmitter()
  isDetail: boolean
  isLoading: boolean
  isEmpty: boolean
  subscriptions: Subscription
  roundButton: RoundButton[]
  displayedColumns: string[]
  dataSource2: Source2[]
  allAssets: FavoriteAssets[]
  isError: boolean

  defaultColDef: object
  columnDefs: IHeaders[]
  rowData: Assets[]
  userColumnState: IColumnState[]
  isInitialRender: boolean
  isColLoaded: boolean
  isFirst: boolean
  constructor(
    public assetService: AssetService,
    public dialog: MatDialog,
    public route: Router, private readonly companyService: CompanyDashboardService,
    private readonly gridActionsService: GirdActionService, private readonly router: Router,
    private readonly assetWarrantyService: AssetWarrantyService) {
    this.subscriptions = new Subscription()
    this.isEmpty = false
    this.isError = false
    this.isDetail = true
    this.allAssets = []
    this.isLoading = true
    this.dataSource2 = []
    this.wrapperClass = ''
  }

  ngOnInit() {
    this.getAssets()
  }
  getAssets() {
    this.subscriptions.add(
      this.assetService.getCompanyAssetNames(this.companyId).subscribe(res => {
        this.isLoading = false
        this.contentReady.emit()
        this.allAssets = res
        if (this.allAssets.length === 0) {
          this.isEmpty = true
          return
        }
        this.roundButton = [
          {
            tooltipText: 'Favorite Assets List',
            className: dashboardContants.EDIT_BUTTON_CLASS, iconClassName: dashboardContants.EDIT_ICON_CLASSNAME,
            roundButtonName: 'open',
          },
        ]
        this.displayedColumns = ['machine name', 'actions']
        // icon-disabled
        this.allAssets.forEach(ele => {
          if (JSON.parse(localStorage.getItem('user')).screenConnectIntegration) {
            this.dataSource2.push(
              {
                'machine name': ele.name,
                actions: [
                  { tooltipText: 'View Document', className: 'icon-redirect', name: 'view' },
                  { tooltipText: 'CW Control', className: 'btn-icon refresh-c icon-btn-loader', name: 'refresh' },
                ],
              }
            )
            this.checkScreenConnectMachine(ele.name)
          } else {
            this.dataSource2.push(
              { 'machine name': ele.name, actions: [{ tooltipText: 'View Document', className: 'icon-redirect', name: 'view' }] }
            )
          }
        })
      }, error => {
        this.isEmpty = false
        this.isError = true
        this.isLoading = false
        this.contentReady.emit()
      })
    )

  }

  checkScreenConnectMachine(configName) {
    this.subscriptions.add(
      this.assetService.checkScreenConnect(configName).subscribe(response => {
        this.dataSource2.forEach(ele => {
          if (ele['machine name'] === configName) {
            ele.actions = [
              { tooltipText: 'View Document', className: 'icon-redirect', name: 'view' },
              { tooltipText: 'CW Control', className: 'btn-icon icon-screenconnect', name: 'cw' },
            ]
          }
        })
      },
        error => {
          this.dataSource2.forEach(ele => {
            if (ele['machine name'] === configName) {
              ele.actions = [
                { tooltipText: 'View Document', className: 'icon-redirect', name: 'view' },
                { tooltipText: 'CW Control', className: 'icon-disabled icon-screenconnect', name: 'disabled' },
              ]
            }
          })
        }))
  }
  viewListings(event) {
    // console.log(event)
    if (event === 'open') {
      this.wrapperClass = 'slideUp'
      this.isDetail = false
    } else {
      this.wrapperClass = ''
      this.isDetail = true
      this.roundButton = [
        {
          tooltipText: 'Favorite Assets List',
          className: dashboardContants.EDIT_BUTTON_CLASS, iconClassName: dashboardContants.EDIT_ICON_CLASSNAME,
          roundButtonName: 'open',
        },
      ]
      return
    }
    // console.log(event)
    this.roundButton = [
      {
        tooltipText: 'Close List',
        className: dashboardContants.EDIT_BUTTON_CLASS,
        iconClassName: 'cross',
        roundButtonName: 'close',
      },
    ]
    this.isDetail = false
    this.isColLoaded = false
    this.rowData = []
    this.isError = false
    this.isFirst = true
    this.defaultColDef = {
      filter: dashboardContants.AG_GRID_FILTER,
    }
    this.columnDefs = this.assetWarrantyService.getCompanyAssetsWarrnatyColDefs(this.companyId)
    // tslint:disable-next-line: ban-comma-operator
    this.columnDefs[0] = {
      headerName: 'CONFIGURATION NAME', field: 'name',
      minWidth: 180,
      suppressColumnsToolPanel: true, lockPosition: true,
      cellRenderer: name =>
        `<a  target="_blank" href="/app/company/${this.companyId}/configurations/view/${name.data.uuid}">${name.value}</a>`,
    },
      this.isLoading = true
    this.subscriptions.add(
      this.companyService.getCompanyDashboardColumnState('favAssets').subscribe(
        response => {
          this.isLoading = false
          if (response.length) {
            this.isEmpty = false
            this.userColumnState = response
          }
          this.isColLoaded = true
          this.isEmpty = false
        },
        error => { },
        () => { }
      )
    )
    this.subscriptions.add(
      this.gridActionsService.action.subscribe(action => this.actionClicked(action))
    )
  }
  columnStateChanged(state: IColumnState[]) {
    state.forEach((col, i) => {
      col[`${'position'}`] = i
    })
    this.companyService.saveCompanyDashboardColumnState(state, 'favAssets').subscribe(
      response => { },
      error => { },
      () => { }
    )
  }
  actionClicked = action => {
    switch (action.actionType) {
      case stringConstants.DEFAULT_DATAGRID_NAVIGATE_TO_VIEW:
        window.open(`app/company/${this.companyId}/configurations/view/${action.data.uuid}`, '_blank')
        break
      default:
        return
    }
  }
  navigate(event) {
    if (event.action === 'view') {
      this.allAssets.forEach(ele => {
        if (ele.name === event.user['machine name']) {
          window.open(`app/company/${this.companyId}/configurations/view/${ele.uuid}`, '_blank')
        }
      })
    }
    if (event.action === 'cw') {
      this.allAssets.forEach(ele => {
        if (ele.name === event.user['machine name']) {
          this.dialog.open(ConnectwiseControlComponent,
            {
              data:
              {
                machineName: event.user['machine name'],
                companyID: this.companyId,
                recordUuid: ele.uuid,
                type: 'pods',
              },
              width: MODAL_WIDTH_CONSTANTS.SMALL,
            })
        }
      })
    }
  }

  getRows(params: Params) {
    let dataCount: number
    let pageNo: number
    let pageSize: number
    const { startRow } = params.request
    pageNo = this.setOffset(startRow)
    this.subscriptions.add(
      this.assetService.getCompanyAsset(this.companyId, params.request, pageNo).subscribe(res => {
        this.rowData = []
        // this.rowData = res
        res.forEach(element => {
          this.rowData.push({
            uuid: element.uuid,
            configName: element.configName,
            type: element.type,
            status: element.status,
            AssetDate: new DatePipe('en-US').transform(element.AssetDate, 'dd/MM/yyyy'),
            recordStatus: element.recordStatus,
            ipAddress: element.ipAddress,
            maintenanceWindow: element.maintenanceWindow,
            tags: element.tags,
            name: element.name,
            domain: element.domain,
            biosMFG: element.biosMFG,
            biosName: element.biosName,
            upTime: element.upTime,
            lastLoginName: element.lastLoginName,
            serialNumber: element.serialNumber,
            purchaseDate: element.purchaseDate,
            processorName: element.processorName,
            totalMemory: element.totalMemory,
            installDate: element.installDate,
            purchaseBy: element.purchaseBy,
            os: element.os,
            osInfo: element.osInfo,
            osInstallDate: element.osInstallDate,
            dnsInfo: element.dnsInfo,
            routerAddress: element.routerAddress,
            managmentPort: element.managmentPort,
          })
        })
        this.isEmpty = (this.rowData.length === 0 || this.rowData === undefined) && this.isFirst ? true : false
        this.isColLoaded = (this.rowData.length === 0 || this.rowData === undefined) && this.isFirst ? false : true
        this.isFirst = false
        dataCount = this.rowData.length
        pageSize = pageNo * params.request.endRow
        const lastRow = res.length <= pageSize ? res.length : -1
        params.successCallback(this.rowData, lastRow)
      },
        error => {
          this.isError = true
          this.isEmpty = false
          this.isColLoaded = false
          this.isFirst = false
          params.failCallback()
        })
    )
  }
  setOffset(offset: number): number {
    return (offset = 0 ? 1 : offset / 100 + 1)
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

}
