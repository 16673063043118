import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material'
import { ACTION_ICON, SCREEN_CONNECT_HEADER } from '@app/constants/connectwise-control.constants'
import { IScreenConnectResponce } from '@app/interfaces/screen-connect.interface'
import { SharedService } from '@app/services/shared.service'
import { Subscription } from 'rxjs'
@Component({
  selector: 'app-connectwise-control',
  templateUrl: './connectwise-control.component.html',
  styleUrls: ['./connectwise-control.component.css'],
})
export class ConnectwiseControlComponent implements OnInit {

  displayedColumns: string[]
  dataSource
  machineName: string
  companyID: string
  scNewAppURL: string
  scLaunchAppURL: string
  scDownloadAppURL: string
  isLoading: boolean
  companyName: string
  recordUuid: string
  type: string
  private readonly subscriptions: Subscription
  constructor(
    private readonly modalService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.machineName = ''
    this.displayedColumns = SCREEN_CONNECT_HEADER  // ['name', 'actions'] //
    this.subscriptions = new Subscription()
    this.isLoading = true
    this.scNewAppURL = ''
    this.scLaunchAppURL = ''
    this.scDownloadAppURL = ''
    this.companyID = ''
    this.recordUuid = ''

    if (data) {
      this.machineName = data.machineName
      this.recordUuid = data.recordUuid
      this.companyID = data.companyID
      this.type = data.type
    }
    this.dataSource = []
  }

  ngOnInit() {
    this.subscriptions.add(
      this.modalService.getCompanyName(this.companyID).subscribe(res => {
        this.companyName = res.name
      }))

    this.subscriptions.add(
      this.modalService.geLoggedUsersList(this.machineName).subscribe((res: IScreenConnectResponce) => {
        this.isLoading = false
        if (!!res.error_code) return
        this.dataSource = [
          {
            'LOGGED IN USER': res[0].GuestLoggedOnUserName,
            'OPERATING SYSTEM': res[0].GuestOperatingSystemName,
            ORGANIZATION: res[0].CustomPropertyValues,
            actions: [
              { tooltipText: 'Download Installer', className: 'btn-icon icon-download-launcher', name: 'Download_Installer' },
              { tooltipText: 'Download App', className: 'btn-icon icon-download-app', name: 'Download_app' },
              { tooltipText: 'Launch App', className: 'btn-icon icon-launch-app', name: 'Launch_app' },
            ],
          },
        ]

        this.scNewAppURL = res[0].scNewAppURL
        this.scLaunchAppURL = res[0].scLaunchAppURL
        this.scDownloadAppURL = res[0].scDownloadAppURL
      },
        error => this.isLoading = false
      )
    )
  }

    /*** Called when an option is selected */
  onClickEvent(e) {
    switch (e.action) {
      case ACTION_ICON.Download_Installer: {
        // window.open(this.scDownloadAppURL)
        window.location.href = this.scDownloadAppURL
        this.addActivity()
        break
      }
      case ACTION_ICON.Download_app: {
        //  window.open(this.scNewAppURL)
        window.location.href = this.scNewAppURL
        this.addActivity()
        break
      }
      case ACTION_ICON.Launch_app: {
        //   window.open(this.scLaunchAppURL)
        if (this.type) {
          window.open(this.scLaunchAppURL)
        } else {
          window.location.href = this.scLaunchAppURL
        }
        this.addActivity()
        break
      }
      default:
    }

  }
    /*** Callsthe modal service to show the selected activity */
  addActivity() {
    const payload = { serverMachine: this.machineName, group: this.recordUuid, companyName: this.companyName }
    this.subscriptions.add(
      this.modalService.addActivity(this.companyID, payload).subscribe(res => {
        //  window.location.href = this.scLaunchAppURL
      }))

  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
