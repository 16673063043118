import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'
import { CoreService } from '@app/modules/core/services/core.service'
import { SharedDataService } from '@app/modules/core/services/shared-data.service'
import { Store } from '@ngrx/store'
import { Menu } from '@projects/company-dashboard/src/app/modules/company-dashboard/interfaces/menu'
import { CompanyDashboardState, selectUserMenu } from '@projects/company-dashboard/src/app/store'
import { KnowledgeBaseService } from '@projects/documentation/modules/knowledgebase/services/knowledgebase.service'
import { Observable, PartialObserver, Subscription } from 'rxjs'
@Injectable()
export class AuthGuardService implements CanActivate {
  categoryList = []
  subscriptions: Subscription
  kbPermission
  rbPermission
  constructor(
    public router: Router,
    public sharedDataService: SharedDataService,
    readonly coreService: CoreService,
    private readonly menuStore: Store<CompanyDashboardState>,
    readonly knowledgeBaseService: KnowledgeBaseService
  ) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const observable: Observable<boolean> = new Observable((observer: PartialObserver<boolean>) => {
      const routeName = route.data.name.toString()
      this.subscriptions = new Subscription()
      if (route.params.companyId) {
        this.subscriptions.add(
          this.menuStore.select(selectUserMenu).subscribe(userMenu => {
            if (Object.keys(userMenu).length !== 0) {
              this.resolveUserMenuPermission(userMenu, observer, route, route.data.assetName)
            } else {
              this.resolveCompanyPermission(observer, route, route.data.assetName)
            }
          })
        )
      } else {
        this.subscriptions.add(this.coreService.getCategories().subscribe(response => {
          this.categoryList = response
          this.resolvePermission(observer, routeName, route)
        }))
      }
    })
    return observable
  }
  resolvePermission(observer: PartialObserver<boolean>, routeName: string, route) {
    const userType = JSON.parse(localStorage.getItem('user')).userType
    let permissions = []
    if (this.categoryList && userType !== 'external') {
      if (this.categoryList && this.categoryList.length > 0) {
        this.categoryList.forEach(category => {
          if (category.name.toString().toLowerCase() === routeName.toLowerCase()) {
            permissions = category.permissions ? category.permissions : ['get', 'post', 'put', 'delete']
          }
        })
      } else {
        observer.next(false)
        observer.complete()
        this.router.navigateByUrl('app/error').catch(err => {
          throw err
        })
        return
      }

    } else {
      if (this.categoryList && this.categoryList.length === 0) {
        observer.next(false)
        observer.complete()
        this.router.navigateByUrl('app/error').catch(err => {
          throw err
        })
        return
      }
      if (this.categoryList) {
          this.categoryList.forEach(category => {
            if (category.name.toString().toLowerCase() === routeName.toLowerCase()) {
              permissions = category.permissions ? category.permissions : ['get', 'post', 'put', 'delete']
            }
          })
        }

    }
    if (permissions.length === 0 && routeName.toLowerCase() === 'Documentation'.toLowerCase()) {
      this.categoryList.forEach(categoryList => {
        if (routeName.toLowerCase() === 'Documentation'.toLowerCase()) {
          this.kbPermission = categoryList.articles ? categoryList.articles : ['get', 'post', 'put', 'delete']
          this.rbPermission = categoryList.runbooks ? categoryList.runbooks : ['get', 'post', 'put', 'delete']
        }
      })
      route.data.title.indexOf('Runbook') > -1 ?   permissions = this.rbPermission : permissions = this.kbPermission}
    this.checkAndNavigate(route, observer, permissions)
    this.subscriptions.unsubscribe()
  }

  resolveUserMenuPermission(userMenu: Menu[], observer: PartialObserver<boolean>, route: ActivatedRouteSnapshot, routeName: string) {
    const moduleName = routeName
    let permissions = []
    const permissionsArray = userMenu.map(menuItem =>
      menuItem.menuItems.filter(item => item.name.replace(/ /g, '').toLowerCase() === moduleName)
    )
    const modulePermission = permissionsArray.filter(entry => entry.length > 0)
    permissions = modulePermission[0] && modulePermission[0][0].permissions ?
     modulePermission[0] && modulePermission[0][0].permissions : ['get', 'post', 'put', 'delete']
    this.checkAndNavigate(route, observer, permissions)

    this.subscriptions.unsubscribe()
  }

  resolveCompanyPermission(observer: PartialObserver<boolean>, route: ActivatedRouteSnapshot, assetName: string) {
    this.subscriptions.add(
      this.knowledgeBaseService.getCompany(route.params.companyId).subscribe(response => {
        this.checkAndNavigate(route, observer, response.permission)
        this.subscriptions.unsubscribe()
        if (!response.permission) {
          if (route.queryParams.company === 'true') {
            this.router
              .navigate([`/company/${route.params.companyId}`])
              .then()
              .catch()
          } else {
            this.router
              .navigate(['../error'])
              .then()
              .catch()
          }
        }
        this.subscriptions.unsubscribe()
      })
    )
  }
  checkAndNavigate(route: ActivatedRouteSnapshot, observer: PartialObserver<boolean>, permissions: string[]) {
    let value = false
    if (route.url.length > 0) {
      switch (route.url[0].path) {
        case 'add':
          value = permissions.indexOf('post') > -1
          break
        case 'edit':
          value = permissions.indexOf('put') > -1
          break
        default:
          value = permissions.indexOf('get') > -1
      }
    } else value = permissions.indexOf('get') > -1
    if (value) {
      observer.next(true)
      observer.complete()
    } else {
      observer.next(false)
      observer.complete()
      this.router.navigateByUrl('app/error').catch(err => {
        throw err
      })
    }
  }
}
