import { HostListener, Injectable, OnDestroy } from '@angular/core'
import { MatDialog } from '@angular/material'
import { CanDeactivate } from '@angular/router'
import { Observable, PartialObserver, Subscription } from 'rxjs'
import { ConfirmationMessagesComponent } from 'ui-components'

import { BUTTONS, MODAL_CONSTANTS, PREPARED_BUTTONS } from '../constants/ui-components.constants'
import { IActionPannelButton } from '../interfaces/ui-components.interface'
import { MODAL_WIDTH_CONSTANTS } from '../modules/core/constants/constants'

export abstract class CanComponentDeactivate implements OnDestroy {
  confirmationModalButtons: IActionPannelButton[]
  modalSubscription: Subscription

  constructor(readonly dialog: MatDialog) {
    this.confirmationModalButtons = [PREPARED_BUTTONS.CONFIRM_BUTTON, PREPARED_BUTTONS.CANCEL_BUTTON]
  }

  abstract canDeactivate(): boolean

  @HostListener('window:beforeunload', ['$event'])
  canDeactivateWindowEventsGuard($event) {
    $event.returnValue = false
  }

  checkForm(): Observable<boolean> | Promise<boolean> | boolean {
    const isFormSubmitted = this.canDeactivate()
    if (isFormSubmitted) {
      return true
    }
    const observable: Observable<boolean> = new Observable((observer: PartialObserver<boolean>) => {
      const dialogRef = this.dialog.open(ConfirmationMessagesComponent, {
        data: {
          title: MODAL_CONSTANTS.UNSAVED_CHANGES_MODAL_HEADING,
          body: MODAL_CONSTANTS.UNSAVED_CHANGES_MODAL_TEXT,
          buttonsArray: this.confirmationModalButtons,
        },
        width: MODAL_WIDTH_CONSTANTS.SMALL,
      })
      const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
        if (result === BUTTONS.EVENTS.CONFIRM) {
          observer.next(true)
        } else {
          observer.next(false)
        }
        observer.complete()
      })
      this.modalSubscription = dialogSubscription
    })
    return observable
  }

  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe()
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate ? component.checkForm() : true
  }
}
