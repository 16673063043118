import { AgFrameworkComponent } from '@ag-grid-community/angular'
import { Component } from '@angular/core'

import { stringConstants } from '../../constants/constants'
// import { DropdownFloatingFilterParam } from '../../interfaces/dropdown-floating-filter-params.interface'
@Component({
  templateUrl: './data-grid-custom-filter.component.html',
})

export class DropdownFloatingFilterComponent implements AgFrameworkComponent<any> { //tslint:disable-line
  params // used for optional params that are added dynamically for different types
  currentValue: number
  filterInstance: string
  IMAGE_TRUE: string
  IMAGE_FALSE: string
  IMAGE_TEXT_TRUE: string
  IMAGE_TEXT_FALSE: string

  agInit(params): void {
    this.params = params
    this.filterInstance = this.params.filterInstance
    this.IMAGE_TRUE = this.params.imageTrue
    this.IMAGE_FALSE = this.params.imageFalse
    this.IMAGE_TEXT_TRUE = this.params.imageTextTrue
    this.IMAGE_TEXT_FALSE = this.params.imageTextFalse
  }

  valueChanged(value: number) {
    const { api } = this.params
    this.currentValue = value
    let change // dynamic object
    const model = {} // dynamic object
    const filterModel = api.getFilterModel()
    if (Number(value) === 1) {
      model[this.filterInstance] = {
        type: stringConstants.DEFAULT_DATAGRID_EQUALS,
        filter:
          this.filterInstance === stringConstants.DEFAULT_DATAGRID_ITB_RUNBOOK_STATUS
            ? stringConstants.DEFAULT_DATAGRID_PRIVATE
            : stringConstants.DEAULT_DATAGRID_TRUE,
        filterType: stringConstants.DEFAULT_DATAGRID_TEXT,
      }
      change = { ...filterModel, ...model }
    } else if (Number(value) === 0) {
      model[this.filterInstance] = {
        type: stringConstants.DEFAULT_DATAGRID_EQUALS,
        filter:
          this.filterInstance === stringConstants.DEFAULT_DATAGRID_ITB_RUNBOOK_STATUS
            ? stringConstants.DEFAULT_DATAGRID_PUBLIC
            : stringConstants.DEFAULT_DATAGRID_FALSE,
        filterType: stringConstants.DEFAULT_DATAGRID_TEXT,
      }
      change = { ...filterModel, ...model }
    } else {
      change = api.getFilterModel()
      // tslint:disable-next-line: no-dynamic-delete
      delete change[this.filterInstance]
    }
    api.setFilterModel(change)
    //   api.onFilterChanged();
  }

  onParentModelChanged(parentModel): void {}
}
