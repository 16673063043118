<!-- <mat-chip-list>
    <div class="itb-chip">
        <mat-chip class="chip theme-bg" *ngFor="let tag of customTags" selected>{{tag}}</mat-chip>
    </div>
    <div class="itb-chip" *ngIf="params.length > 3" title="Show more" >
            <mat-chip class="chip theme-bg"   (click)="showTags()" selected>...</mat-chip>
        </div>
</mat-chip-list> -->

<mat-chip-list>
  <div class="itb-chip">
  <mat-chip class="chip theme-bg" *ngFor="let tag of customTags; let i=index" selected>{{tag}}</mat-chip>
  <mat-chip *ngIf="params.length > numberOfTags" class="chip theme-bg" (click)="showTags()" selected>...</mat-chip>
  </div>
  </mat-chip-list>
