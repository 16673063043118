import { Component, ViewChild } from '@angular/core'
import { ITabSidePullInteface } from '@core/interfaces/side-pull'
@Component({
  selector: 'app-sidepull',
  templateUrl: './sidepull.component.html',
})
export class SidepullComponent  {
  tabs: ITabSidePullInteface[]
  hasSidePullFlag: boolean
  currentTab: string
  @ViewChild('drawer', { static: true }) drawer
  constructor() {
    this.hasSidePullFlag = false
    this.tabs = [
      { label: 'Companies'},
      { label: 'Activity List'},
    ]
    this.currentTab = this.tabs[0].label
  }
  /***Click event of tab */
  onClick(tabId: ITabSidePullInteface) {
    this.currentTab = this.tabs[tabId.index].label
  }
  /***Show/Hide Right Side pull */
  toggle() {
    this.drawer.toggle()
    this.hasSidePullFlag = this.hasSidePullFlag ? false : true
    this.currentTab = !this.hasSidePullFlag ? this.tabs[0].label : this.currentTab
  }

}
