export const environment = {
  production: true,
  cwChat: 'https://nachat.myconnectwise.net/customer/partnerId/535fc181aa8af35c985fff49?n=1',
  swaggerUrl: 'http://virtserver.swaggerhub.com/ITBoost3/v4Configurations/1.0.0/',
  agGridLicenceKey: 'ITBOOST_ITBOOST_1Devs_1Deployment_16_October_2020__MTYwMjgwMjgwMDAwMA==6b4493259a1e07726bd993b6eb25785a',
  baseUrl: 'https://api.itboost.com/',
  mk2Server: '',
  xApiKey: 'KV8ULIH3JuIVTNaILg3i2z5kYxhPuKb8NaUZMyK8',
  v3BaseUrl: 'https://admin-dev.itboost.com:4453',
  hmr: false,
}
