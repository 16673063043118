<div class="headig-container">
  <div class="heading-sidepull" fxLayout="row" fxLayoutAlign="center center">
    <itb-icon class="z-index-1" fxFlex="0.01" [tooltipText]="''" [className]="'left-arrow-c'" (onClickHandler)="showCompaniesList()"></itb-icon>
    <div fxFlex="100" class="text-center">
      <div class="view-heading">
        {{companyData.name}}
      </div>
      <div class="fs-11 hint-text">
        {{companyData.identifier}}
      </div>
    </div>
  </div>
  <div class="pl-2 pr-2" fxLayoutAlign="space-between center">
    <a class="dashboard-title-s" data-toggle="quickview" data-toggle-element="#quickview" (click)="toggle()" [routerLink]="['/app/company/' + companyData.uuid]">
      <itb-icon [className]="'icon-dashboard-sb'"></itb-icon> Dashboard
    </a>
    <div fxLayout="row">
      <itb-round-button [className]="'light'" [iconClassName]="'icon-ticket-new'" [tooltipText]="'Create Ticket'"
                        (onClickHandler)="onCreateTicket()"
      *ngIf="users.userType !=='external' && users.mainIntegration !=='autotask' && !users.TvUser"></itb-round-button>
      <app-add-company-items [compData]="companyData"
                             *ngIf="showAddCompanyControl()"></app-add-company-items>
    </div>
  </div>
</div>
<div class="side-pull-lazyload">
  <app-favorite-configuration [compData]="companyData"></app-favorite-configuration>
  <app-asset-widget [companyData]="companyData"></app-asset-widget>
</div>
