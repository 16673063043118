import * as fromActions from '../../store/actions'
import { CommunicationActionState } from '../states/communication.state'

export const initialState: CommunicationActionState = {
  type: undefined,
}

export function reducer(state = initialState, action: fromActions.CommunicationActions): CommunicationActionState {
  switch (action.type) {
    case fromActions.ECommunicationActions.TRIGGER_ACTION: {
      const type = action.payload
      return { type }
    }
    default:
      return { ...state }
  }
}
