import { Injectable } from '@angular/core'
import { IColumnState } from '@app/interfaces/ui-components.interface'
import { ApiService } from '@app/services/api.service'
import { SharedDataService } from '@core/services/shared-data.service'
import { BehaviorSubject, Observable } from 'rxjs/index'
import { Subject } from 'rxjs/Subject'

import { backendRoutes } from '../constants/constant'
import { ICompaniesList } from '../interfaces/companies'
import { ICompanyConfiguration } from '../interfaces/company-configuration'
import { IConfigurationType } from '../interfaces/configuration-type'
import { IExportActivities } from '../interfaces/export-activities'
import { IFavoriteConfigurations } from '../interfaces/favorite-configuration'
import { IQrCode } from '../interfaces/google-auth-object'
import { IActivity, ICategoryList, ITab } from '../interfaces/header-items.interface'
import { IProgress } from '../interfaces/progress'
import { IServerStats } from '../interfaces/server-stats.interface'
import { IItbLogout, ISsoLogout } from '../interfaces/sso-logout'
import { ITerms } from '../interfaces/users'
import { IUsersList } from '../interfaces/users-list'
import { IVersion } from '../interfaces/version'
import { IWidget } from '../interfaces/widget.interface'

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  toggleHeaderButton: BehaviorSubject<boolean>
  hideUserMenuButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined)
  // tslint:disable-next-line: no-any
  toggleValue: any
  firstLogin: BehaviorSubject<boolean>
  // tslint:disable-next-line: no-any
  firstLoginValue: any
  footerSubject: Subject<boolean>
  // tslint:disable-next-line: no-any
  footerEvent: any
  headerLogoUpdateSubject: Subject<boolean>
  // tslint:disable-next-line: no-any
  headerLogoUpdateEvent: any
  sidePullUpdateSubject: Subject<boolean>
  // tslint:disable-next-line: no-any
  sidePullUpdateEvent: any
  constructor(private readonly baseService: ApiService, private  readonly sharedDataService: SharedDataService) {
    this.toggleHeaderButton = new BehaviorSubject(false)
    this.hideUserMenuButton = new BehaviorSubject(false)
    this.toggleValue = this.toggleHeaderButton.asObservable()
    this.firstLogin = new BehaviorSubject(false)
    this.firstLoginValue = this.firstLogin.asObservable()
    this.footerSubject = new Subject<boolean>()
    this.footerEvent = this.footerSubject.asObservable()
    this.headerLogoUpdateSubject = new Subject<boolean>()
    this.headerLogoUpdateEvent = this.headerLogoUpdateSubject.asObservable()
    this.sidePullUpdateSubject = new Subject<boolean>()
    this.sidePullUpdateEvent = this.sidePullUpdateSubject.asObservable()
  }
  updateLogo(data: boolean) {
    this.headerLogoUpdateSubject.next(data)
  }

  updateSidePull(data: boolean) {
    this.sidePullUpdateSubject.next(data)
  }

  updateFooter(data: boolean) {
    this.footerSubject.next(data)
  }
  updateFirstLogin(data: boolean) {
    this.firstLogin.next(data)
  }

  toggleLSB(change: boolean) {
    this.toggleHeaderButton.next(change)
  }
  switchUserMenu(change) {
    this.hideUserMenuButton.next(change)
  }
  /*** Gets configuration type  */
  getConfigurationType(): Observable<IConfigurationType[]> {
    return this.baseService.get(backendRoutes.GET_CONFIGURATION_TYPE, 'Configuration Type')
  }
  /*** Gets widgets  */
  getWidgtes(companyUuid): Observable<IWidget[]> {
    return this.baseService.get(backendRoutes.GET_WIDGETS(companyUuid), 'Asset Widget')
  }
  /*** Gets company server stats  */
  addCompnayServerStats(data: string): Observable<IServerStats> {
    return this.baseService.get(backendRoutes.ADD_COMPANY_SERVER_STAT(data), 'Server Stats', true)
  }
  /*** Gets company configuration data  */
  getCompanyConfigurations(companyUuid: string): Observable<ICompanyConfiguration> {
    return this.baseService.get(backendRoutes.GET_COMPANY_CONFIGURATION(companyUuid), 'Configurations')
  }
  /*** Gets categories  */
  getCategories(): Observable<ICategoryList[]> {
    const url = backendRoutes.GET_CATEGORIES()
    return this.baseService.get(url, 'Categories')
  }
  /*** Gets tabs  */
  getTabs(categoryId: string): Observable<ITab[]> {
    const url = backendRoutes.GET_TABS(categoryId)
    return this.baseService.get(url, 'Tabs')
  }

  /*** Updates tabs  */
  updateTab(id: string, formData: ITab): Observable<ITab> {
    const url = backendRoutes.UPDATE_TABS(id)
    return this.baseService.edit(url, formData)
  }
  /*** Copies tabs  */
  copyTab(id: ITab): Observable<ITab> {
    const url = backendRoutes.COPY_TABS(id)
    return this.baseService.get(url, 'Copy Tabs')
  }
  /*** Gets favorite configurations  */
  favoriteConfiguration(uuid: string): Observable<IFavoriteConfigurations[]> {
    const url = backendRoutes.GET_FAVORITE_CONFIGURATION(uuid)
    return this.baseService.get(url, 'Favorite Configurations')
  }
  /*** Gets companies list  */
  getCompaniesList(pageNumber: number): Observable<ICompaniesList[]> {
    const url = backendRoutes.COMPANIES_LIST(pageNumber)
    return this.baseService.get(url, 'Companies List')
  }
  /*** Gets companies list  */
  searchCompaniesList(name: string): Observable<ICompaniesList[]> {
    const url = backendRoutes.COMPANIES_LIST_FILTER(name)
    return this.baseService.get(url, 'Companies List Filter')
  }
  /*** Exports activities  */
  exportActivities(query): Observable<IExportActivities> {
    const url = backendRoutes.EXPORT_ACTIVITIES()
    return this.baseService.add(url, query)
  }
  /*** Generates 2FA code  */
  generateQRCode(): Observable<IQrCode> {
    const url = backendRoutes.GENERATE_QR_CODE()
    return this.baseService.get(url, 'Generate Two Factor Code')
  }
  /*** Submits QR Code  */
  submitQRCode(body: IQrCode): Observable<IQrCode> {
    const url = backendRoutes.SUBMIT_QR_CODE()
    return this.baseService.add(url, body, true)
  }
  /*** Gets users list  */
  getUsersList(): Observable<IUsersList[]> {
    const url = backendRoutes.GET_USERS_LIST()
    return this.baseService.get(url, 'User List')
  }
  /*** Gets current version  */
  getCurrentVersion(): Observable<IVersion> {
    const url = backendRoutes.GET_ITB_VERSION()
    return this.baseService.get(url, 'Current Version')
  }
  /*** Gets user tour data  */
  getUserTour(): Observable<IProgress> {
    const url = backendRoutes.USER_TOUR()
    return this.baseService.get(url, 'User Tour')
  }
  /*** Puts user tour data*/
  putUserTourdata(data) {
    const url = backendRoutes.USER_TOUR()
    return this.baseService.edit(url, data)
  }
  /*** Logouts user from SSO  */
  ssoLogout(): Observable<ISsoLogout> {
    const url = backendRoutes.SSO_LOGOUT()
    return this.baseService.get(url, 'SSO logout')
  }
  /*** Logouts user from ITB  */
  itbLogout(expireAllSession: boolean): Observable<IItbLogout> {
    const url = expireAllSession ? backendRoutes.ITB_LOGOUT_ALL_SESSIONS() : backendRoutes.ITB_LOGOUT()
    return this.baseService.add(url, {})
  }
  /*** Gets top ten activities  */
  activitiesFilter(url: string): Observable<IActivity[]> {
    return this.baseService.get(url, 'Activities List Filter')
  }
  /*** Gets terms object  */
  getTerms(url: string): Observable<ITerms> {
    return this.baseService.get(url, 'Terms')
  }
  /*** Update terms object  */
  updateTerms(): Observable<ITerms> {
    const url = backendRoutes.UPDATE_TERMS()
    return this.baseService.edit(url, {})
  }
  updateFirsttimeLoginStatus(body, url) {
    return this.baseService.edit(url, body)
  }

  checkScreenStatus(configName: string) {
    return this.baseService.get(`users/check/configuration/${configName}`, 'Screen Connect', true)
  }
  /*** Gets user roles */
  getUserRolesConfigurations() {
    const categoriesList = this.sharedDataService.geCategories()
    const userType = JSON.parse(localStorage.getItem('user')).userType
    let permission: string[] = []
    if (categoriesList && userType !== 'external') {
      for (const obj of Object.keys(categoriesList)) {
        if (categoriesList[obj].name === 'Companies') {
          permission = categoriesList[obj].permissions ? categoriesList[obj].permissions :
            ['get', 'post', 'put', 'delete']
          break
        }
      }
    } else {
      permission = ['get']
    }
    let userPermissions: { isRead: boolean, isEdit: boolean, isDelete: boolean, isCreate: boolean }
    if (permission) {
      userPermissions = {
        isRead: permission.indexOf('get') > -1,
        isEdit: permission.indexOf('put') > -1,
        isDelete: permission.indexOf('delete') > -1,
        isCreate: permission.indexOf('post') > -1,
      }
      return userPermissions
    }
    //   return userPermissions
  }

    /*** Gets company counts */
  getCounts(companyId: string, category: string) {
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(`${backendRoutes.GET_CATEGORY_COUNTS(companyId)}?conditions={"type.category": {"$regex": "${category}", "$options": "i"}}`, '', true)
  }
  getComputersCounts(companyId: string) {
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(`${backendRoutes.GET_CATEGORY_COUNTS(companyId)}?conditions={"$or": [{"type.category":{"$regex":"WorkStation","$options":"i"}},{"type.category":{"$regex":"computer","$options":"i"}} ]}`, '', true)
  }

  getOtherCount(companyId) {
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(`${backendRoutes.GET_CATEGORY_COUNTS(companyId)}?conditions={"type.category": "printer|computer|phone|network|switch"}`, '', true)
  }

    /*** Gets company dashboard grid state */
  getCompanyDashboardColumnState(name: string): Observable<IColumnState[]> {
    return this.baseService.get(`dashboard/user/headers/${name}`, '', true)
  }

    /*** Saves company dashboard grid state */
  saveCompanyDashboardColumnState(state: IColumnState[], name: string): Observable<IColumnState[]> {
    const colState = {
      columnDefs: state,
    }
    return this.baseService.add(`dashboard/user/headers/${name}`, colState)
  }
  getEnvironment() {
    return this.baseService.getEnvironment()
  }
}
