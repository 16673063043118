import { Component } from '@angular/core'
import { SharedService } from '@app/modules/shared/shared.service'
@Component({
  selector: 'app-access-error',
  templateUrl: './access-error.component.html',
  styleUrls: ['./access-error.component.css'],
})
export class AccessErrorComponent {
  errorMessage: string
  constructor(private readonly sharedService: SharedService) {
    this.errorMessage = "Either this record doesn't exist or you don't have access to it"
  }
  logout() {
   this.sharedService.logout()
  }

}
