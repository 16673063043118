<itb-heading mat-dialog-title [className]="'modal-h'" [text]="'SELECT COMPANIES'"></itb-heading>
<itb-round-button mat-dialog-close [className]="'light round-modal-cross caret-close-modal'" [iconClassName]="'cross'"> </itb-round-button>
<div mat-dialog-content>
  <div *ngIf="!isNoDataFound">
    <itb-data-grid
      [rowModelType]="'serverSide'"
      [columnDefs]="columnDefs"
      [cacheBlockSize]="pageSize"
      [totalRows]="totalRowsCount"
      (dataGridRowsPropsEmitter)="getRows($event)"
    >
    </itb-data-grid>
  </div>
</div>
<div *ngIf="isNoDataFound" class="ag-grid-size">
  <itb-empty-state-image
    [imageClass]="'es-image img-nodatafound'"
    [iconClassName]="'btn-innerico save'"
    [headingClass]="'heading'"
    [headingText]="'No Data Found'"
  ></itb-empty-state-image>
</div>
<mat-dialog-actions>
  <itb-default-button
    [isHidden]="isNoDataFound"
    [text]="'save'"
    [className]="'primary float-right'"
    [iconClassName]="'save'"
    (onClickHandler)="saveAddedCompanies()"
  ></itb-default-button>
  <itb-default-button mat-dialog-close [text]="'Cancel'" [className]="'default'" [iconClassName]="'cross'"> </itb-default-button>
</mat-dialog-actions>
