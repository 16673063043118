
    <div class="ag-header-cell-label">
      <label class="container-checkbox">
        <input type="checkbox" [(ngModel)]="isChecked" (click)="selectRows($event)" />
        <span class="checkmark"></span>
      </label>

      <span ref="eText" class="ag-header-cell-text pl-1" role="columnheader" unselectable="on" aria-colindex="2">{{
        params.displayName
      }}</span>
      <span ref="eSortAsc" *ngIf="ascSort == 'active'" class="ag-header-icon ag-sort-ascending-icon" aria-hidden="true">
        <span class="ag-icon ag-icon-asc" unselectable="on" (click)="onSortRequested('desc', $event)"></span
      ></span>

      <span ref="eSortDesc" *ngIf="descSort == 'active'" class="ag-header-icon ag-sort-descending-icon" aria-hidden="true">
        <span class="ag-icon ag-icon-desc" unselectable="on" (click)="onSortRequested('', $event)"></span
      ></span>

      <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" aria-hidden="true" *ngIf="noSort == 'active'">
        <span class="ag-icon ag-icon-none" unselectable="on" (click)="onSortRequested('asc', $event)"> </span
      ></span>
    </div>
  