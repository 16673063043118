import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ILinkedItems } from '@core/interfaces/linked-items'

@Component({
  selector: 'app-company-pods-linked-items-list',
  templateUrl: './pods-linked-items-list.component.html',
  styleUrls: ['./pods-linked-items-list.component.scss'],
})
export class PodsLinkedItemsListComponent {

  @Input() linedItems: ILinkedItems[]
  @Input() hasEditPermissions: boolean
  @Output() onView = new EventEmitter<string>()
  @Output() onDelete = new EventEmitter<string>()

  onItemViewInNewTab(item: ILinkedItems) {
    item.newTab = true
    this.onView.emit(item.uuid)
  }

  onItemDelete(item: ILinkedItems) {
    this.onDelete.emit(item.uuid)
  }

  onItemView(item: ILinkedItems) {
    item.newTab = false
    this.onView.emit(item.uuid)
  }

}
