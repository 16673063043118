<mat-drawer-container class="side-pull sidepull-container" autosize >
  <mat-drawer #drawer class="sidepull-nav" mode="side" position="end">
    <itb-tabs *ngIf="hasSidePullFlag" [tabs]="tabs" (onClickHandler)="onClick($event)"></itb-tabs>
    <app-activity-list *ngIf="currentTab === 'Activity List' && hasSidePullFlag"></app-activity-list>
    <app-companies-list *ngIf="currentTab === 'Companies' && hasSidePullFlag"></app-companies-list>
  </mat-drawer>
  <div class="builder-toggle" fxHide.xs="true" fxHide.sm="true" (click)="toggle()">
    <itb-icon  *ngIf="!hasSidePullFlag" [tooltipText]="''" [className]="'icon_menu'"></itb-icon>
    <itb-icon *ngIf="hasSidePullFlag" [tooltipText]="''" [className]="'cross '" ></itb-icon>
  </div>
</mat-drawer-container>

