import { AgRendererComponent } from '@ag-grid-community/angular'
import { Component } from '@angular/core'
import { IUsers } from '@core/interfaces/users'
import { SharedDataService } from '@core/services/shared-data.service'

import { stringConstants } from '../../../constants/constants'
import { GirdActionService } from '../../services/grid-actions.service'
export const COMPANIES_STATES = {
  DASHBOARD: '/app/company/',
  COMPANY_ON_BOARD: '/app/creation/company',
}
@Component({
  selector: 'app-hover-tags-cell-renderer',
  templateUrl: './hover-tags-cell-renderer.component.html',
  styleUrls: ['./hover-tags-cell-renderer.component.scss'],
})
export class HoverTagsCellRendererComponent implements AgRendererComponent {

  params // used for optional params that are added dynamically for different types
  paramsData
  paramsValue
  route: string
  user: IUsers
  isAddTagHidden: boolean
  constructor(public gridActionsService: GirdActionService, public sharedDataSvc: SharedDataService) {
    this.user = this.sharedDataSvc.getUser()
    this.isAddTagHidden = false
  }

  agInit(params): void { // used for optional params that are added dynamically for different types
    if (params && params.column && params.column.colId && params.data) {
      // tslint:disable-next-line: prefer-conditional-expression
      if (params.colDef.Id)
      this.route = this.populateRouterLink(params.column.colId, params.data.companyUuid, true, params.colDef.Id)
      else
      this.route = this.populateRouterLink(params.column.colId, params.data.companyUuid)
    }
    if (params.data) {
      this.paramsData = params.data
      this.paramsValue = params.value
      this.params = params
    }
  }

  refresh(params): boolean { // used for optional params that are added dynamically for different types
    return false
  }

  populateRouterLink(data, companyUuid, isCustomTemplate = false, customTemplateId = '') {
      let url = ''
      if (isCustomTemplate) {
        url = `${COMPANIES_STATES.DASHBOARD}${companyUuid}/templates/${customTemplateId.toLowerCase()}/add`
      } else {
      switch (data) {
        case 'ssl':
          url = `${COMPANIES_STATES.DASHBOARD}${companyUuid}/ssls/list/add`
          break
        case 'whois':
          url = `${COMPANIES_STATES.DASHBOARD}${companyUuid}/domains/list/add`
          break
        case 'configurations':
          url = `${COMPANIES_STATES.DASHBOARD}${companyUuid}/configurations/add`
          break
        case 'knowledgebase':
          url = `${COMPANIES_STATES.DASHBOARD}${companyUuid}/documents/knowledgebase/add`
          break
        case 'runbooks':
          url = `${COMPANIES_STATES.DASHBOARD}${companyUuid}/documents/runbook/add`
          break
        case 'agreements':
          this.isAddTagHidden = true
          break
        case 'agreements_1':
          this.isAddTagHidden = true
          break
        default:
          url = `${COMPANIES_STATES.DASHBOARD}${companyUuid}/${data}/add`.toLowerCase()
      }
    }
      return url
  }

  nameHandler() {
    this.gridActionsService.setSelectedAction
    (stringConstants.DEFAULT_DATAGRID_NAVIGATE_TO_COMPANY_DASHBOARD, this.params, this.paramsData.rowIndex)
  }

}
