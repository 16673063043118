import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DomSanitizer, SafeStyle } from '@angular/platform-browser'
import { NgxDropzonePreviewComponent } from 'ngx-dropzone'

@Component({
  selector: 'app-company-pods-custom-drop-zone-preview',
  templateUrl: './pods-custom-drop-zone-preview.component.html',
  styleUrls: ['./pods-custom-drop-zone-preview.component.scss'],
  providers: [{ provide: NgxDropzonePreviewComponent, useExisting: PodsCustomDropZonePreviewComponent }],
})
export class PodsCustomDropZonePreviewComponent  extends NgxDropzonePreviewComponent implements OnInit {
  @Output() readonly download = new EventEmitter()
  @Input() showDownload: boolean
  @Input() fileObj?: any // tslint:disable-line: no-any /*** file could be of 'File' or 'blob' type */
  imageSrc: string | ArrayBuffer
  canShowPreview: boolean

  constructor(santizer: DomSanitizer) {
    super(santizer)
    this.imageSrc = ''
    this.canShowPreview = true
  }

  ngOnInit() {
    if (!this.file) {
      return
    }
    const terms = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png', 'image/vnd.adobe.photoshop']
    this.canShowPreview = terms.includes(this.fileObj.type)
    if (this.fileObj) {
      if (!this.canShowPreview) {
        switch (this.fileObj.type) {
          case 'application/zip':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-zip.svg'
            break
          case 'application/pdf':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-pdf.svg'
            break
          case 'text/plain':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-txt.svg'
            break
          case 'text/csv':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-csv.svg'
            break
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-doc.svg'
            break
          case 'application/msword':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-doc.svg'
            break
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-xls.svg'
            break
          case 'application/vnd.ms-excel':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-xls.svg'
            break
          case 'application/vnd.ms-powerpoint':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-ppt.svg'
            break
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-ppt.svg'
            break
          case 'video/mp4':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-vid.svg'
            break
          case 'audio/mpeg':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-vid.svg'
            break
          case 'application/x-shockwave-flash':
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-vid.svg'
            break
          default:
            this.fileObj.imageSrc = 'assets/img/file-icons/ico-generic.svg'
        }
      }
    }
    this.readFile()
      .then(img => setTimeout(() => (this.imageSrc = img)))
      .catch(err => {})
  }

  _download($event: Event) {
    $event.preventDefault()
    this.download.emit($event)
  }
}
