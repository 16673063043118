import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ILocalNotes } from '@core/interfaces/local-notes'

@Component({
  selector: 'app-company-pods-notes-list',
  templateUrl: './pods-notes-list.component.html',
  styleUrls: ['./pods-notes-list.component.scss'],
})
export class PodsNotesListComponent {
  @Input() notes: ILocalNotes[]
  @Input() hasEditPermissions: boolean
  @Output() onEdit = new EventEmitter<string>()
  @Output() onView = new EventEmitter<string>()
  @Output() onDelete = new EventEmitter<string>()

  onItemEdit(item: ILocalNotes) {
    this.onEdit.emit(item.uuid)
  }

  onItemDelete(item: ILocalNotes) {
    this.onDelete.emit(item.uuid)
  }

  onItemView(item: ILocalNotes) {
    this.onView.emit(item.uuid)
  }

}
