<div class="footer_container" ng-class="{'pull-bottom': is('app.maps.vector') }">
  <div class="footerWrapper">
    <div class="inner-div">
      <span class="fs-11"> Copyright © 2020 </span>
      <span class="fs-12">ITBOOST </span>
      <span class="fs-11" *ngIf="currentVersion"> Version <a href={{currentVersion.currentVersionLink}}
          target="_blank">{{currentVersion.currentVersion}}</a></span>.
      <span class="fs-11"> All rights reserved.</span>
      <span class="fs-11"> Please refer to <a href="http://help.itboost.com/general-info/terms-and-conditions"
          target="_blank">Terms of Use</a> and <a href="http://help.itboost.com/general-info/privacy-policy"
          target="_blank">Privacy Policy.</a></span>

      <div id="trailFooter" class="footer_container" ng-class="{'pull-bottom': is('app.maps.vector') }"
        *ngIf="canShowTimerFooter">
        <div class="footer_trials" [ngClass]="[bgColor,color]">
          <span class="fs-12">{{config[orgStatus.toLocaleLowerCase()].msg }} &nbsp;</span>
          <strong class="fs-12" *ngIf="config[orgStatus.toLocaleLowerCase()].showTime">{{remTime.ret}}</strong>
          <button (click)="config[orgStatus.toLocaleLowerCase()].btnFunc()"
            *ngIf="config[orgStatus.toLocaleLowerCase()].showBtn" ng-style="buynow"
            class="btnBuy">{{config[orgStatus.toLocaleLowerCase()].btnText}}</button>
          <itb-icon (click)="hideTimerFooter()" [className]="'icon_cross  icon-white'" ></itb-icon>
        </div>
      </div>

    </div>
  </div>
</div>
