export const LISTOFCATEGORIES = [
    { id: 0, label: 'Network', isDisabled: false },
    { id: 1, label: 'Other', isDisabled: false },
    { id: 2, label: 'Phone', isDisabled: false },
    { id: 3, label: 'Printer', isDisabled: false },
    { id: 4, label: 'Server', isDisabled: false },
    { id: 5, label: 'Switch', isDisabled: false },
    { id: 6, label: 'WorkStation', isDisabled: false },
  ]

export const WALKMAN_SCRIPT = {
  // tslint:disable-next-line: max-line-length
  TEST: "(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/914aad86c1474eaca8d5486094d1263c/test/walkme_914aad86c1474eaca8d5486094d1263c_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();" ,
  // tslint:disable-next-line: max-line-length
  PROD:  "(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/914aad86c1474eaca8d5486094d1263c/walkme_914aad86c1474eaca8d5486094d1263c_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();",
}
