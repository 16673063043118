import { CompanySidebarComponent } from '../components/company-sidebar/company-sidebar.component'

// import { environment } from '../../../../environments/environment'
// export const companyUrlConstants = {
//   COMPANY_DASHBOARD_DATA: (id: number): string => {
//     return environment.baseURL + `companies/companydashboard/` + id
//   },
// }

export const notesValidationMessages = {
  SUMMARY_REQUIRED: '*Summary field is required',
  PRIORITY_REQUIRED: '*Priority field is required',
  NOTE_TYPE_REQUIRED: '*Note field type required',
  NOTES_REQUIRED: '*Notes field is required',
}

export const dashboardContants = {
  // ASSET WARRAY CONSTANTS
  EDIT_BUTTON_TOOTLTIP: 'edit',
  EDIT_BUTTON_CLASS: 'light',
  EDIT_BUTTON_NAME: 'roundButton1',
  EDIT_ICON_CLASSNAME: 'expand',
  FILTER_EXPIRED: 'Expired',
  FILTER_SOON: 'Soon',
  FILTER_ACTIVE: 'Active',
  FILTER_UNKNOWN: 'Unknown',
  FIELD_REQUIRED: 'Field value required.',
  AG_GRID_FILTER: 'agTextColumnFilter',
}
export const END_POINTS = {
  GET_MENU: id => `users/menu/${id}`,
  PUT_MENU: id => `users/metadata/${id}`,
  GET_MENU_ITEMS: 'users/menuItems',
  GET_FEEDBACK: 'surveys/feedback/ces',
  GET_FEEDBACK_CHARTS: 'surveys/feedback/metrics',
  GET_FEEDBACK_CES: 'surveys/feedback/ces',
  GET_FEEDBACK_NAS: 'surveys/feedback/nas',
  GET_ORG_MENU: '/organization/menu',
  GET_USERS: 'users/list',
  GET_COMPANY_TYPE: 'organizations/metadata/companyType',
  GET_COMPANY_MEMBERS: 'organizations/metadata/member',
  GET_COMPANY_CLASSIFFICATION: 'organizations/metadata/companyClassification',
  GET_COMPANY_STATUS: 'organizations/metadata/companyStatus',
  UPDATE_CONTACTS: id => `contacts/${id}`,
  UPDATE_LOCATION: id => `locations/${id}`,
  GET_COMPANY: id => `companies/${id}`,
  GET_COMPANY_CONTACT: id => `contacts/company/${id}`,
  GET_COMPANY_LOCATION: id => `locations/company/${id}`,
  POST_FAVORITE: id => `companies/${id}/favorite`,
  POST_WATCH: id => `companies/${id}/watch`,
  GET_NOTES: id => `companies/${id}/notes`,
  GET_DELETED_NOTES: id => `companies/${id}/deleted/notes`,
  REMOVE_NOTES: (companyId, notesId) => `companies/${companyId}/notes/${notesId}`,
  ADD_NOTES: id => `companies/${id}/notes`,
  GET_FEEDBACK_ROW_DATA: 'surveys/feedback/customerResponse',
  GET_ROWS_COUNT: (id, feedback) => `surveys/feedback/customer/response/count?companies=["${id}"]&responses=[${feedback}]`,
  GET_AGREEMENTS: id => `companies/${id}/agreements`,
  GET_DOCUMENTS: id => `articles/company/${id}`,
  GET_DOCUMENTS_COUNT: id => `articles/company/${id}/count`,
  GET_SERVERS: id => `dashboard/company/widgets/${id}/`,
  GET_OS_STATE: id => `configurations/company/${id}`,
  GET_OS_STATE_COUNT: id =>
    // tslint:disable-next-line: max-line-length
    `configurations/company/${id}/count?conditions: { $or: [{ recordStatus: null }, { recordStatus: { $regex: 'Complete', $options: 'i' } }] }`,
  // tslint:disable-next-line: max-line-length
  GET_ASSETS_COUNT: (id: string) => `configurations/company/${id}/count`,
  GET_FAV_COUNT: id => `configurations/company/${id}/count?conditions={ "favourite": true }`,
  SCREEN_CONNECT: name => `users/check/configuration/${name}`,
  GET_ASSETS_DETAILS: id => `company/assetsDetails/${id}`,
  GET_DOMAIN_DETAILS: id => `trackers/company/${id}/whois`,
  GET_DOMAIN_COUNTS: id => `trackers/company/${id}/whois/count`,
  GET_SSL_DETAILS: id => `trackers/company/${id}/ssl`,
  GET_SSL_COUNTS: id => `trackers/company/${id}/ssl/count`,
  // GET_TICKET_STATS: id => `dashboard/company/widgets/${id}/91831c1c-2f1d-44b0-acb5-7350066f290f`,
  GET_TICKET_DETAILS: id => `dashboard/widgets/company/${id}/d4515557-fc3e-4207-ac73-99ed2187e634?detailFlag=true`,
  GET_TICKET_DETAILS_COUNTS: id => `dashboard/widgets/company/${id}/`,
  TICKET_DETAIL_ROW_DATA: id => `company/${id}/alldetails`,
  GET_RMM_COUNT: id => `organizations/companyserverstats/${id}`,
  GET_HEADER_STATE: name => `dashboard/user/headers/${name}`,
  POST_HEADER_STATE: name => `dashboard/user/headers/${name}`,
  GET_USER_WATCHLIST: 'users/metadata/watchList',
  GET_USER_FAVORITE: 'users/metadata/favCompanies',
  GET_ASSETS_FILTERED_DETAILS: status =>
    `dashboard/widgets/detail/data/${status}/542d4b65-a615-4e3c-b381-93447caa9b0b/68bed8b7-2b5e-47ef-8ef6-1da6050b4c25`,
  GET_DOMAIN_FILTERED_DETAILS: status =>
    `dashboard/widgets/detail/data/${status}/24197946-6016-4a69-85c9-24eb994cd5f0/68bed8b7-2b5e-47ef-8ef6-1da6050b4c25`,
  GET_CW_AUTH: 'users/cwauth',
  GET_OPEN_TICKETS: id => `dashboard/widgets/company/${id}/bc6dd4cc-3a3b-43f2-ab06-256cb7edd45c?detailFlag=true`,
  GET_CLOSE_TICKETS: id => `dashboard/widgets/company/${id}/3eead8c5-a933-4316-b775-65f1729696d0?detailFlag=true`,
  GET_FILTERED_TICKETS: id => `dashboard/LiveTicketsGetByStatusForLastDays/${id}`,
  GET_TICKETS_ROWS: (companyId, widgetId) => `dashboard/company/widgets/${companyId}/${widgetId}?detailFlag=true`,
  GET_ONLINE_STATE: id => `dashboard/widgets/company/${id}/f9719345-3523-40d0-96cc-b23cf745daee?detailFlag=true`,
  GET_OFFLINE_STATE: id => `dashboard/widgets/company/${id}/fada70b6-8dfa-49ec-b0ce-4dcc25298f58?detailFlag=true`,
  GET_TOTAL_STATE: id => `dashboard/widgets/company/${id}/855eabdf-0113-4cae-b87c-e8026deb56da?detailFlag=true`,
  GET_COUNTRIES: 'organizations/metadata/countries',
  GET_SECOND_VIEW: 'organizations/metadata/companyDashboardSecondView',
  HAS_ADD_PERMISSION: (assetName, companyId) => `users/permissions/${assetName}?companyId=${companyId}`,
  GET_SIGNED_URL: (companyId: string, fileName: string) => `quickNotes/company/getsignedurl/${companyId}/${fileName}?mimeType=text/csv`,
  GET_FILE_SIGNED_URL: (companyId: string, fileName: string, type: string) =>
    `users/uploads/s3/${companyId}/quickNotes/${companyId}/${fileName}?mimeType=${type}`,
}

export const ICON_CONSTANTS = {
  FILE_ACTIVE_NAME: 'file-active',
  FILE_INACTIVE_NAME: 'file-disabled',
  SYNC_NAME: 'sync',
  ASYNC_NAME: 'sync-disable',
  TAGS_NAME: 'tags',
  TAGS_TOOLTIP: 'Tags',
  FILE_ACTIVE_TOOLTIP: 'Active',
  FILE_INACTIVE_TOOLTIP: 'Inactive',
  SYNC_TOOLTIP: 'Enable Sync',
  ASYNC_TOOLTIP: 'Disable Sync',
  TAG_CLASSNAME: 'btn-icon tag',
  FILE_ACTIVE_CLASSNAME: 'btn-icon file-active',
  FILE_INACTIVE_CLASSNAME: 'btn-icon file-disabled',
  SYNC_CLASSNAME: 'btn-icon sync',
  ASYNC_CLASSNAME: 'btn-icon sync-disable',
  SAVE: 'save',
  CROSS: 'cross',
  EDIT: 'edit',
  REFRESH: 'refresh-c',
  ADD: 'add',
  PLUS: 'plus-c',
  ADD_NOTES: 'add-note',
}
export const TOOLTIPS = {
  SAVE: 'Save',
  CANCEL: 'Cancel',
  EDIT: 'Edit',
  REFRESH: 'Refresh',
  ADD: 'Add',
  CREATE_TICKETS: 'Create Ticket',
  TICKET_DETAIL_LIST: 'Ticket Details List',
  ADD_NOTES: 'Add Notes',
  NOTES_LIST: 'Notes List',
}
export const CLASS_NAME = {
  LIGHT: 'light',
  DANGER: 'danger',
  DEFAULT: 'default',
  ADD: 'plus-c',
}
export const BUTTON_NAME = {
  SAVE: 'Save',
  CANCEL: 'Cancel',
  EDIT: 'edit',
  REFRESH: 'refresh',
  LIST: 'list',
  TICKET: 'ticket',
  ADD: 'Add',
  DELETE: 'delete',
  CLOSE: 'close',
}

export const CONFIRMATION = {
  DELETE_SURE: 'Are you sure you want to delete this!',
}
export const TEXT = {
  DELETE: 'Delete',
  CLOSE: 'Close',
}

export const CUSTOMIZED_SIDEBAR = {
  USER_MENU: 'userMenu',
  USER_MENU_NAME: 'Core Assets',
  CONFIG_TYPES: 'orgConfigTypes',
  CONFIG_TYPES_NAME: 'Configuration Types',
  FLEXIBLE_ASSETS: 'flexibleAssets',
  FLEXIBLE_ASSETS_NAME: 'Flexible Assets',
  DISCOVERERS: 'discoverers',
  DISCOVERERS_NAME: 'Discoverers',
  DONE: 'd',
  TODO: 't',
  CORE_ASSETS: ['Locations', 'Contacts', 'Configuration', 'Knowledge Base', 'Runbooks', 'Domain Tracker', 'SSL Tracker', 'Passwords'],
}

export const NAVIGATIONS = {
  ROUTES: {
    ['url']: (companyId, name) => `app/company/${companyId}/${name}`,
    ['configuration']: companyId => `app/company/${companyId}/configurations`,
    ['app.configurationlist']: (companyId, text) => `app/company/${companyId}/configurations/list?type=${text}`,
    ['Knowledge Base']: companyId => `app/company/${companyId}/documents/knowledgebase`,
    ['Domain Tracker']: companyId => `app/company/${companyId}/domains/list`,
    ['Runbooks']: companyId => `app/company/${companyId}/documents/runbook`,
    ['SSL Tracker']: companyId => `app/company/${companyId}/ssls`,
    ['app.customTemplatesData.list']: (companyId, menuId) => `app/company/${companyId}/templates/${menuId}`,
    ['adl/discovererSystems']: (companyId, route, menuId) => `app/company/${companyId}/${route}/${menuId}`,
  },
  EXTENSION: {
    ['configurations']: 'configurations',
    ['app.configurationlist']: (text: string) => 'configurations/list?type=Configurations',
    ['Knowledge Base']: 'documents/knowledgebase',
    ['Domain Tracker']: 'domains',
    ['Runbooks']: 'documents/runbook',
    ['SSL Tracker']: 'ssls',
  },
  ROUTE_NAMES: [
    'locations',
    'configurations',
    'ssls',
    'domains',
    'runbooks',
    'runbook',
    'knowledgebase',
    'passwords',
    'contacts',
    'templates',
    'adl',
    'documents',
  ],
  ROUTE_MATCH: {
    ['locations']: 'locations',
    ['configurations']: 'configurations',
    ['ssls']: 'ssl tracker',
    ['domains']: 'domain tracker',
    ['runbooks']: 'runbook',
    ['runbook']: 'runbooks',
    ['knowledgebase']: 'knowledge base',
    ['knowledge base']: 'knowledgebase',
    ['passwords']: 'passwords',
    ['contacts']: 'contacts',
    ['templates']: 'templates',
    ['adl']: 'adl',
    ['documents']: ['domain tracker', 'knowledge base'],
  },
  PERMISSION_MATCH: {
    ['locations']: 'Locations',
    ['configuration']: 'Configurations',
    ['contact']: 'Contacts',
    ['companyPasswords']: 'Passwords',
    ['ssl']: 'SSL Tracker',
    ['whois']: 'Domain Tracker',
  },
}
