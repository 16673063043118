import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { dark, light } from '@app/modules/core/interfaces/theme'
import { DateService } from '@core/services/date.service'
import { TitleService } from '@core/services/title.service'
import _ from 'lodash'
import { Subscription } from 'rxjs'

import { categoriesConstant } from './modules/core/constants/constant'
import { NOTIFICATION_CONSTANTS, RESTRICTED_URLS } from './modules/core/constants/constants'
import { ICategoryList } from './modules/core/interfaces/header-items.interface'
import { CoreService } from './modules/core/services/core.service'
import { GlobalService } from './modules/core/services/global.service'
import { SharedDataService } from './modules/core/services/shared-data.service'
import { ThemeService } from './modules/core/services/theme.service'
import { UtilService } from './modules/core/services/utils.service'
import { LoginService } from './modules/login/services/login.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  // tslint:disable-next-line: no-any
  snakBar: any
  categoryList: ICategoryList[]
  sortedCategoryList
  subscriptions: Subscription
  categoryId: string
  isPageLoader: boolean
  isRestrictedRoute: boolean
  restrictedRoutes: string[]
  subscriptionPlan: string
  constructor(
    private readonly titleService: TitleService,
    private readonly _snackBar: MatSnackBar,
    private readonly globalService: GlobalService,
    private readonly route: ActivatedRoute,
    private readonly coreService: CoreService,
    private readonly sharedDataService: SharedDataService,
    private readonly themeService: ThemeService,
    private readonly utilService: UtilService,
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly dateService: DateService
  ) {
    this.subscriptions = new Subscription()
    this.restrictedRoutes = RESTRICTED_URLS.RESTRICTED_URLS_CORE
    this.isRestrictedRoute = true
    this.subscriptions.add(this.coreService.sidePullUpdateEvent.subscribe(data => {
      this.verifySubscription()
    }))
  }

  /***Continously checks for the internet connection - displays error in a snack bar if connection lost  */
  ngOnInit() {
    addEventListener('online', () => {
      if (this.snakBar) this.snakBar.dismiss()
    })

    addEventListener('offline', () => {
      this.snakBar = this._snackBar.open('It appears that your internet connection is offline.', '', {
        panelClass: '',
      })
    })
    if (this.sharedDataService.getUser()) {
      this.subscriptionPlan = this.sharedDataService.getUser().planName
      this.sharedDataService.getUser().theme === 'theme-dark'
        ? this.themeService.setActiveTheme(dark)
        : this.themeService.setActiveTheme(light)
      this.subscriptions.add(
        this.route.data.subscribe(data => {
          this.categoryId = data.categoryId
          this.isPageLoader = !this.sharedDataService.geCategories() ? true : false
        })
      )
      this.createCategories()
    }
  }
  ngAfterViewInit(): void {
    const interval = setInterval(() => {
      // tslint:disable-next-line: no-any
      if ((window as any).ga && (window as any).ga.getAll) {
        this.router.events.subscribe(event => {
          // tslint:disable-next-line: no-any
          const ga = (window as any).ga
          if (event instanceof NavigationEnd) {
            const tracker = ga.getAll()[0]
            tracker.set('page', event.urlAfterRedirects)
            tracker.send('pageview')
          }
        })
        clearInterval(interval)
      }
    }, 0)
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  /***Sanitizes/Locks categories to display in the header based on subscription plan*/
  createCategories() {
    if (!this.sharedDataService.geCategories()) {
      this.subscriptions.add(
        this.coreService.getCategories().subscribe(
          data => {
            this.isPageLoader = false
            data.sort((a, b) => (a.id > b.id ? 1 : -1))
            const sortedCategoryList = this.utilService.removeDuplicatesFromArray(data, 'name')
            this.categoryList = sortedCategoryList
            for (const obj of Object.keys(this.categoryList)) {
              this.categoryList[obj].textClassName = 'smallText'
              this.categoryList[obj].link = this.getLink(this.categoryList[obj].name)
              if (this.categoryList[obj].name.toLowerCase() === 'bi') {
                if (this.subscriptionPlan === 'Tier 1' || this.subscriptionPlan === 'Tier 2') this.categoryList[obj].isDisabled = true
              }
              if (this.categoryList[obj].name.toLowerCase() === 'feedback') {
                if (this.subscriptionPlan === 'Tier 1') this.categoryList[obj].isDisabled = true
              }
            }
            this.sharedDataService.setCategories(this.categoryList)
            this.hideHeader()
          },
          error => {
            this.isPageLoader = false
            throw new Error(error.message || error || NOTIFICATION_CONSTANTS.SOMETHING_WENT_WRONG)
          }
        )
      )
    } else {
      this.isPageLoader = false
      this.categoryList = this.sharedDataService.geCategories()
      this.hideHeader()
      if (this.router.url === '/app') {
        this.router.navigateByUrl('app/dashboard').catch()
      }
    }
  }

  hideHeader() {
    this.isRestrictedRoute = _.includes(this.restrictedRoutes, window.location.pathname) ? true : false
  }

  verifySubscription(): boolean {
    const orgStatus = this.sharedDataService.getUser().orgStatus
    if (orgStatus.toLowerCase() === 'cancelled') {
      return false
    }
    if (orgStatus.toLowerCase() === 'trial' &&
      this.dateService.getDatesDifference(new Date(this.sharedDataService.getUser().ITBTrialEndDate), new Date()) <= 0) {
      return false
    }
    return !this.isRestrictedRoute ? true : false
  }

  getLink(category: string): string {
    if (category === categoriesConstant.DASHBOARD) return categoriesConstant.DASHBOARD.toLowerCase()
    if (category === categoriesConstant.DOCUMENTATION) return categoriesConstant.DOCUMENTATION.toLowerCase()
    if (category === categoriesConstant.COMPANIES) return categoriesConstant.COMPANIES.toLowerCase()
    if (category === categoriesConstant.BI) return categoriesConstant.BI.toLowerCase()
    if (category === categoriesConstant.BACKUPS) return categoriesConstant.BACKUPS.toLowerCase()
    if (category === categoriesConstant.FEEDBACK) return categoriesConstant.FEEDBACK.toLowerCase()
    if (category === categoriesConstant.ASSET_DISCOVERY) return categoriesConstant.ADL.toLowerCase()
  }
}
