export class ResponsiveMenu {
  mainMenuContainer: HTMLElement
  mainMenuPrimary: HTMLElement
  mainMenuPrimaryItems: NodeListOf<HTMLElement>
  mainMenuSecondary: HTMLElement
  mainMenuSecondaryItems: NodeListOf<HTMLElement>
  mainMenuAllItems: NodeListOf<HTMLElement>
  mainMenuMoreLi: HTMLElement
  mainMenuMoreBtn: HTMLElement
  constructor() {
    this.mainMenuContainer = document.querySelector('.adaptive-main-menu')
    this.mainMenuPrimary = this.mainMenuContainer.querySelector('.main-menu')
    this.mainMenuPrimaryItems = this.mainMenuPrimary.querySelectorAll('itb-menu-button.main-i')
    this.mainMenuContainer.classList.add('--jsfiedMenu')

    this.mainMenuSecondary = this.mainMenuContainer.querySelector('.-menu-secondary')
    this.mainMenuSecondaryItems = this.mainMenuSecondary.querySelectorAll('itb-menu-button')
    this.mainMenuAllItems = this.mainMenuContainer.querySelectorAll('itb-menu-button')
    this.mainMenuMoreLi = this.mainMenuPrimary.querySelector('.-menu-more')
    this.mainMenuMoreBtn = this.mainMenuMoreLi.querySelector('div.edit-dots')
    this.mainMenuMoreBtn.addEventListener('click', e => {
        e.preventDefault()
        this.mainMenuContainer.classList.toggle('--show-secondary-menu')
        this.mainMenuMoreBtn.setAttribute('aria-expanded', this.mainMenuContainer.classList.contains('--show-secondary-menu').toString())
      })
    this.doMenuAdaptive()
  }
    /*** Check offset and set hidden class items to more button*/
  doMenuAdaptive() {
    if (this.mainMenuPrimary.offsetWidth >= 240) {
      this.mainMenuPrimaryItems.forEach((item, i) => {
        item.classList.remove('--hidden')
        this.mainMenuSecondaryItems[i].classList.add('--hidden')
      })
      this.mainMenuMoreLi.classList.remove('--hidden')
      let stopWidth = this.mainMenuMoreBtn.offsetWidth
      const hiddenItems = []
      const primaryWidth = this.mainMenuPrimary.offsetWidth
      this.mainMenuPrimaryItems.forEach((item, i) => {
        if (primaryWidth >= stopWidth + item.offsetWidth) {
          stopWidth += item.offsetWidth
        } else {
          item.classList.add('--hidden')
          this.mainMenuSecondaryItems[i].classList.remove('--hidden')
          hiddenItems.push(i)
        }
      })
      if (!hiddenItems.length) {
        this.mainMenuMoreLi.classList.add('--hidden')
        this.mainMenuContainer.classList.remove('--show-secondary-menu')
        this.mainMenuMoreBtn.setAttribute('aria-expanded', 'false')
      }
    }
  }
}
