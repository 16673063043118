export const notificationItems = {

  SETTING_ITEMS: () =>
    [{
      iconClassName: 'li-os',
      label: 'Organization Settings',
      id: '1',

    },
     {
      iconClassName: 'billing',
      label: 'Manage Subscription',
      id: '2',

    },
     {
      iconClassName: 'merge',
      label: 'Mapping and Merging',
      id: '4',

    },
     {
      iconClassName: 'li-rl',
      label: 'Security Control',
      id: '5',

    },
     {
      iconClassName: 'li-ul',
      label: 'Users',
      id: '6',

    },
     {
      iconClassName: 'sync-w',
      label: 'Sync Status',
      id: '7',

    },
     {
      iconClassName: 'li-in',
      label: 'Integrations',
      id: '8',

    },
     {
      iconClassName: 'edit',
      label: 'Import Data',
      id: '9',

    },
     {
      iconClassName: 'li-mdt',
      label: 'Master Data Tables',
      id: '10',

    },
     {
      iconClassName: 'li-ct',
      label: 'Custom Templates',
      id: '11',

    },
     {
      iconClassName: 'setting-a',
      label: 'Advanced Settings',
      id: '12',

    },
     {
      iconClassName: 'li-nt',
      label: 'Notifications',
      id: '13',

    }],
  SETTING_ITEMS_V4: () =>
    [{
      iconClassName: 'li-os',
      label: 'Organization Settings',
      id: '1',
      link: '/app/admin/update-global-preferences',
    },
     {
      iconClassName: 'billing',
      label: 'Manage Subscription',
      id: '2',
      link: '/app/admin/billing/subscription',
    },
     {
      iconClassName: 'merge',
      label: 'Mapping and Merging',
      id: '4',
      link: '/app/admin/mapping',
    },
     {
      iconClassName: 'li-rl',
      label: 'Security Control',
      id: '5',
      link: '/app/admin/roles',
    },
     {
      iconClassName: 'li-ul',
      label: 'Users',
      id: '6',
      link: '/app/admin/users/list',
    },
     {
      iconClassName: 'sync-w',
      label: 'Sync Status',
      id: '7',
      link: '/app/admin/sync-status',
    },
     {
      iconClassName: 'li-in',
      label: 'Integrations',
      id: '8',
      link: '/app/admin/integrations',
    },
     {
      iconClassName: 'edit',
      label: 'Import Data',
      id: '9',
      link: '/app/admin/importData/list',
    },
     {
      iconClassName: 'li-mdt',
      label: 'Master Data Tables',
      id: '10',
      link: '/app/admin/masterdata',
    },
     {
      iconClassName: 'li-ct',
      label: 'Custom Templates',
      id: '11',
      link: '/app/admin/customTemplates/list',
    },
     {
      iconClassName: 'setting-a',
      label: 'Advanced Settings',
      id: '12',
      link: '/app/admin/advanceSettings',
    },
     {
      iconClassName: 'li-nt',
      label: 'Notifications',
      id: '13',
      link: '/app/admin/notification-settings',
    }],
  ONBOARDING_PERCENTAGES: {
    skipIntegrations: 30,
    setupNotifications: 10,
    reviewAdvanceSettings: 10,
    createCustomTemplates: 10,
    passwordTypeFlag: 5,
    configurationTypeCategoryMapping: 10,
    ticketMapping: 5,
    companyMapping: 5,
    createAndInvite: 5,
    rolesAndPermissions: 10,
    checkSyncStatus: 10,
    personalAvatar: 10,
    companyLogo: 10,
    Integrations: 15,
  },
  ONBOARDING_MASTER_DATA_TABLE_ITEMS: () =>
    [{
      iconClassName: 'li-mdt',
      textClassName: 'text-default',
      text: 'Add Configuration Type Categories',
      actionButtonClassName: 'primary',
      roundButtonClassName: 'light',
      roundButtonIconClassName: 'info-c',
      id: '1',
    },
     {
      iconClassName: 'li-mdt',
      textClassName: 'text-default',
      text: 'Create Password Types',
      actionButtonClassName: 'primary',
      roundButtonClassName: 'light',
      roundButtonIconClassName: 'info-c',
      id: '2',
    }],

  ONBOARDING_SETTINGS_ITEMS: () =>
    [{
      iconClassName: 'li-ct',
      textClassName: 'text-default',
      text: 'Review Advanced Settings',
      actionButtonClassName: 'primary',
      roundButtonClassName: 'light',
      roundButtonIconClassName: 'info-c',
      id: '3',
    },
     {
      iconClassName: 'li-nt',
      textClassName: 'text-default',
      text: 'Setup Notifications',
      actionButtonClassName: 'primary',
      roundButtonClassName: 'light',
      roundButtonIconClassName: 'info-c',
      id: '4',
    }],

  ONBOARDING_PERSONALIZATION_ITEMS: () =>
    [{
      iconClassName: 'li-os',
      textClassName: 'text-default',
      text: 'Add Company Logo',
      actionButtonClassName: 'primary',
      roundButtonClassName: 'light',
      roundButtonIconClassName: 'info-c',
      id: '5',
    }],

  ONBOARDING_USER_ITEMS: () =>
    [{
      iconClassName: 'li-ul',
      textClassName: 'text-default',
      text: 'Create and Invite Users',
      actionButtonClassName: 'primary',
      roundButtonClassName: 'light',
      roundButtonIconClassName: 'info-c',
      id: '7',
    },
     {
      iconClassName: 'li-rl',
      textClassName: 'text-default',
      text: 'Create Roles and Permissions',
      roundButtonClassName: 'light',
      actionButtonClassName: 'primary',
      roundButtonIconClassName: 'info-c',
      id: '8',
    }],

  ONBOARDING_ASSET_ITEMS: () =>
    ({
      iconClassName: 'li-os',
      textClassName: 'text-default',
      text: 'Create/Import Custom Assets',
      actionButtonClassName: 'primary',
      roundButtonClassName: 'light',
      roundButtonIconClassName: 'info-c',
      id: '9',
    }),

  ONBOARDING_INTEGRATION_ITEMS: () =>
    ({
      iconClassName: 'li-in',
      textClassName: 'text-default',
      text: 'Setup Integrations',
      actionButtonClassName: 'primary',
      roundButtonClassName: 'light',
      roundButtonIconClassName: 'info-c',
      id: '10',
    }),

  TICKET_ITEMS: () =>
    [{
      iconClassName: 'tab-inner-ico ticket',
      label: 'Ticket Portal',
      id: '11',
    },
     {
      iconClassName: 'tab-inner-ico notebook',
      label: 'ITBOOST Knowledgebase',
      id: '12',
    }],
  ONBOARDING_Mapping_Merging: () =>
    [{
      iconClassName: 'merge',
      textClassName: 'text-default',
      text: 'Map Your Companies',
      actionButtonClassName: 'primary',
      roundButtonClassName: 'light',
      roundButtonIconClassName: 'info-c',
      id: '13',
    },
     {
        iconClassName: 'merge',
        textClassName: 'text-default',
        text: 'Map Your Tickets',
        actionButtonClassName: 'primary',
        roundButtonClassName: 'light',
        roundButtonIconClassName: 'info-c',
        id: '14',
      }],
}

export const routes = {
  Documentation: '/app/documentation/knowledgebase',
}

export const meta = {
  TITLE_MAIN: 'ITBoost',
  TITLE_SUBTITLE: 'Document, Track & Analyze.',
  TITLE_SEPARATOR: ' | ',
  TITLE_DASHBOARD: 'Dashboard',
  TITLE_DOCUMENTATION: 'Documentation',
  TITLE_COMPANIES: 'Companies',
  TITLE_BI: 'Business Intelligence',
  TITLE_BACKUPS: 'Backups',
  TITLE_FEEDBACK: 'Feedback',

}

export const titles = {

  TITLE_KNOWLEDGEBASE: `${meta.TITLE_DOCUMENTATION}${meta.TITLE_SEPARATOR} Knowledgebase`,
  TITLE_KNOWLEDGEBASE_LIST: `${meta.TITLE_DOCUMENTATION}${meta.TITLE_SEPARATOR}Knowledgebase${meta.TITLE_SEPARATOR}List`,
  TITLE_KNOWLEDGEBASE_ADD: `${meta.TITLE_DOCUMENTATION}${meta.TITLE_SEPARATOR}Knowledgebase${meta.TITLE_SEPARATOR}Add`,
  TITLE_KNOWLEDGEBASE_VIEW: `${meta.TITLE_DOCUMENTATION}${meta.TITLE_SEPARATOR}Knowledgebase${meta.TITLE_SEPARATOR}View`,
  TITLE_KNOWLEDGEBASE_EDIT: `${meta.TITLE_DOCUMENTATION}${meta.TITLE_SEPARATOR}Knowledgebase${meta.TITLE_SEPARATOR}Edit`,
  TITLE_KNOWLEDGEBASE_TREE: `${meta.TITLE_DOCUMENTATION}${meta.TITLE_SEPARATOR}Knowledgebase${meta.TITLE_SEPARATOR}Tree View`,

  TITLE_RUNBOOK: `${meta.TITLE_DOCUMENTATION}${meta.TITLE_SEPARATOR}Runbook`,
  TITLE_RUNBOOK_LIST: `${meta.TITLE_DOCUMENTATION}${meta.TITLE_SEPARATOR}Runbook${meta.TITLE_SEPARATOR}List`,
  TITLE_RUNBOOK_ADD: `${meta.TITLE_DOCUMENTATION}${meta.TITLE_SEPARATOR}Runbook${meta.TITLE_SEPARATOR}Add`,
  TITLE_RUNBOOK_VIEW: `${meta.TITLE_DOCUMENTATION}${meta.TITLE_SEPARATOR}Runbook${meta.TITLE_SEPARATOR}View`,
  TITLE_RUNBOOK_EDIT: `${meta.TITLE_DOCUMENTATION}${meta.TITLE_SEPARATOR}Runbook${meta.TITLE_SEPARATOR}Edit`,

  TITLE_COMPANIES_LIST: `${meta.TITLE_COMPANIES}${meta.TITLE_SEPARATOR}List`,
  TITLE_COMPANIES_SNAPSHOT: `${meta.TITLE_COMPANIES}${meta.TITLE_SEPARATOR}Snapshot`,
  TITLE_COMPANIES_ALL: `${meta.TITLE_COMPANIES}${meta.TITLE_SEPARATOR}All`,
  TITLE_BACKUPS_CRASHPLAN: `${meta.TITLE_BACKUPS}${meta.TITLE_SEPARATOR}Crash Plan`,
  TITLE_FEEDBACK_DASHBOARD: `${meta.TITLE_FEEDBACK}${meta.TITLE_SEPARATOR}Dashboard`,
  TITLE_FEEDBACK_TEMPLATES: `${meta.TITLE_FEEDBACK}${meta.TITLE_SEPARATOR}Templates`,
  TITLE_FEEDBACK_TEMPLATES_ADD: `${meta.TITLE_FEEDBACK} ${meta.TITLE_SEPARATOR}Templates${meta.TITLE_SEPARATOR}Add`,
  TITLE_FEEDBACK_TEMPLATES_EDIT: `${meta.TITLE_FEEDBACK}${meta.TITLE_SEPARATOR}Templates${meta.TITLE_SEPARATOR}Edit`,
  TITLE_FEEDBACK_TEMPLATES_VIEW: `${meta.TITLE_FEEDBACK}${meta.TITLE_SEPARATOR}Templates${meta.TITLE_SEPARATOR}View`,
  TITLE_FEEDBACK_QUESTIONS: `${meta.TITLE_FEEDBACK}${meta.TITLE_SEPARATOR}Questions`,
  TITLE_FEEDBACK_QUESTIONS_ADD: `${meta.TITLE_FEEDBACK} ${meta.TITLE_SEPARATOR}Questions${meta.TITLE_SEPARATOR}Add`,
  TITLE_FEEDBACK_QUESTIONS_EDIT: `${meta.TITLE_FEEDBACK}${meta.TITLE_SEPARATOR}Questions${meta.TITLE_SEPARATOR}Edit`,
  TITLE_FEEDBACK_QUESTIONS_VIEW: `${meta.TITLE_FEEDBACK} ${meta.TITLE_SEPARATOR}Questions${meta.TITLE_SEPARATOR}View`,
  TITLE_FEEDBACK_RESULTS: `${meta.TITLE_FEEDBACK}  ${meta.TITLE_SEPARATOR}Results`,
}

// Routes Constants

export const routesConstants = {
  CATEGORY_ID: '0',
  WIDGET_ID: 'a0a7ae2b-9a13-4a5f-b130-efd64ff90795',
}

export const categoriesConstant = {
  DASHBOARD: 'Dashboard',
  DOCUMENTATION: 'Documentation',
  COMPANIES: 'Companies',
  BI: 'BI',
  BACKUPS: 'Backups',
  FEEDBACK: 'Feedback',
  ADL: 'adl',
  ASSET_DISCOVERY: 'Asset Discovery',
}
export const backendRoutes = {
  GET_NOTIIFICATION: () => ('organizations/notifications?sortBy=createdAt&order=desc'),
  UPDATE_NOTIFICATION: () => ('organizations/notifications/'),
  GET_CONFIGURATION_TYPE: 'organizations/metadata/configurationType',
  GET_WIDGETS: companyUuid => (`dashboard/company/widgets/${companyUuid}/${routesConstants.WIDGET_ID}`),
  GET_COMPANY_CONFIGURATION: companyUuid => (`configurations/company/${companyUuid}`),
  ADD_COMPANY_SERVER_STAT: id => (`organizations/companyserverstats/${id}`),
  GET_CATEGORIES: () => ('dashboard/categories'),
  GET_TABS: id => (`dashboard/tabs/${id}`),
  ADD_TABS: () => ('dashboard/tabs/'),
  UPDATE_TABS: id => (`dashboard/tabs/${id}`),
  COPY_TABS: id => (`dashboard/tabs/${id}`),
  GET_PROFILE: () => ('users/profile'),
  UPDATE_PROFILE: () => ('users/profile'),
  GET_FAVORITE_CONFIGURATION: uuid => (`configurations/company/${uuid}?conditions={"favourite": true}`),
  COMPANIES_LIST: pageNumber => (`companies?pageNo=${pageNumber}&&pageSize=20`),
  COMPANIES_LIST_FILTER: name => (`companies?conditions={"name":{"$regex":"${name}","$options":"i"}}`),
  ITB_ES_SEARCH: search => (`users/globalSearch/${search}`),
  ITB_ES_ADVANCE_SEARCH: (value, company) => (`users/globalSearch/advanceSearch/${value}${company}`),
  EXPORT_ACTIVITIES: () => ('users/activity/exportlist'),
  GENERATE_QR_CODE: () => ('users/generate-code'),
  SUBMIT_QR_CODE: () => ('users/save-code'),
  GET_USERS_LIST: () => ('users/list'),
  GET_ITB_VERSION: () => ('users/versionForFooter'),
  USER_TOUR: () => ('users/tour'),
  ITB_LOGOUT: () => ('users/logout'),
  ITB_LOGOUT_ALL_SESSIONS: () => ('users/logout?allSessions=true'),
  SSO_LOGOUT: () => ('users/login/logouturl/'),
  CHANGE_PASSWORD: () => ('users/profile'),
  GRAVATAR_URL: name => (`https://secure.gravatar.com/avatar/${name}?rating=PG&default=mm`),
  PROFILE_IMAGE_UPLOAD: (name, type) => `users/uploads/s3/profile/profile/users/${name}?mimeType=${type}`,
  GET_TERMS: () => ('users/metadata/terms'),
  UPDATE_TERMS: () => ('users/terms'),
  GET_ALL_COMPANIES_LIST: () => ('companies?pageNo=0&pageSize=1000'),
  GET_LOGIN_HISTORY: name => `organizations/activities?conditions={"assetName": "Screen Connect", "recordName": "${name}"}`,
  SCREEN_CONNECT: name => `users/check/configuration/${name}`,
  FIRST_FLAG_LOGIN_URL: 'organizations/metadata/flag',
  GET_CATEGORY_COUNTS: id => `configurations/company/${id}/count`,
  GET_CATEGORY_DETAIL: id => `configurations/company/${id}`,
}

export const HEADER_CONST = {
    TICKET_PORTAL_LINK: 'https://home.connectwise.com',
    ITBOOST_KNOWLEDGE_LINK : 'http://help.itboost.com',
}
