import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material'
import { MODAL_CONSTANTS } from '@app/constants/ui-components.constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { Subscription } from 'rxjs/index'
import { PopupAlertComponent } from 'ui-components'

import { NOTIFICATION_CONSTANTS } from '../../constants/constants'
import { IQrCode } from '../../interfaces/google-auth-object'
import { IUsers } from '../../interfaces/users'
import { CoreService } from '../../services/core.service'
import { UtilService } from '../../services/utils.service'
@Component({
  selector: 'app-google-authentication-popup',
  templateUrl: './google-authentication-popup.component.html',
})
export class GoogleAuthenticationPopupComponent implements OnInit, OnDestroy {
  subscriptions: Subscription
  hasErrorMessage: boolean
  qrCode: IQrCode
  user: IUsers
  hasGoogleAuthErrorMessage: boolean
  googleAuthForm: FormGroup
  isLoading: boolean
  isSubmitLoader: boolean
  constructor(private readonly  utilService: UtilService,  public dialog: MatDialog,
              public coreService: CoreService, private readonly sharedService: SharedDataService,
              @Inject(MAT_DIALOG_DATA) public modalData) {
    this.subscriptions = new Subscription()
    this.user = this.sharedService.getUser()
    this.hasErrorMessage = false
    this.hasGoogleAuthErrorMessage = false
    this.isLoading = false
    this.isSubmitLoader = false
    this.googleAuthForm = new FormGroup({
      code: new FormControl('', Validators.required),
    })
  }
  /*** Sends http request to get 2FA QR code */
  ngOnInit() {
    this.isLoading = true
    this.subscriptions.add(this.coreService.generateQRCode().subscribe(response => {
      if (!response.googleAuthFlag) {
        this.isLoading = false
        this.qrCode = response
      } else {
          this.user.googleAuthFlag = true
          this.user.googleAuthAlgorithmFlag = true
      }

    }))
  }
  /*** Sends http request to verify 2FA code*/
  submitCode(value: boolean) {
      if (this.user.googleAuthFlag && !value) {
      this.hasGoogleAuthErrorMessage = true
      return
    }
      let dataAuth: IQrCode
      if (!value) {
       dataAuth = {
        ascii: this.qrCode.ascii,
        hex: this.qrCode.hex,
        base32: this.qrCode.base32,
        otpauth_url: this.qrCode.otpauth_url,
        code : this.googleAuthForm.value.code,
      }
    } else {
        dataAuth = {
          googleAuth: false,
      }
    }
      this.isSubmitLoader = true
      this.subscriptions.add(this.coreService.submitQRCode(dataAuth).subscribe(response => {
        this.isSubmitLoader = false
        if (!response.error_code) {
          this.user.googleAuthAlgorithmFlag = value ? false : true
          this.user.googleAuthAlgorithmFlag = this.user.googleAuthAlgorithmFlag ? true : false
          const users = this.sharedService.getUser()
          users.googleAuthFlag = value ? false : true
          users.enforce2fa = false
          this.sharedService.setUser(users)
          this.modalData.cacheUser(users)
          this.dialog.closeAll()
          if (!dataAuth.code) {
            this.showNotification(NOTIFICATION_CONSTANTS.SUCCESS, NOTIFICATION_CONSTANTS.TWO_FA,
              NOTIFICATION_CONSTANTS.DISABLE_SUCCESS, '')
          } else {
            this.showNotification(NOTIFICATION_CONSTANTS.SUCCESS, NOTIFICATION_CONSTANTS.TWO_FA,
              NOTIFICATION_CONSTANTS.ENABLE_SUCCSESS, '')
          }
        } else {
          this.hasGoogleAuthErrorMessage = true
        }
    }, error => {
        this.isSubmitLoader = false
        this.hasGoogleAuthErrorMessage = true
      }))
  }
  /*** Enable / disable status*/
  changeStatus(flag: boolean) {
    if (!flag) {
        this.submitCode(true)
    } else {
      return
    }
  }

  /*** Shows notification modal based on data passed */
  showNotification(mode: string, title: string, body: string, mainMessage: string) {
    const dialogRef = this.dialog.open(PopupAlertComponent, {
      width: MODAL_CONSTANTS.MODAL_WIDTH,
      data: {mode, title, body, mainMessage},
    })
  }
  /*** Unsubscribe all subscriptions when component is destroyed*/
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
