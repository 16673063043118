import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { SearchCompaniesDialogComponent } from '@core/components/search-companies-dialog/search-companies-dialog.component'
import { SharedDataService } from '@core/services/shared-data.service'
import { Subscription } from 'rxjs/index'

import { IKbContentSearch } from '../../interfaces/kb-content-search'
import { ISearchAssets, ISearchBKResponse, ISslSearch } from '../../interfaces/search-bk-response'
import { SearchService } from '../../services/search.service'
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements AfterViewInit, OnDestroy {
  @Output() closeSearch = new EventEmitter<boolean>()
  subscriptions: Subscription
  companies: ISearchAssets[]
  contacts: ISearchAssets[]
  locations: ISearchAssets[]
  passwords: ISearchAssets[]
  assetts: ISearchAssets[]
  documents: ISearchAssets[]
  ssls: ISslSearch[]
  canShowLoading: boolean
  canShowLoadingKbContent: boolean
  globalSearchForm: FormGroup
  selectedCompanies: string[]
  isSelectedCompaniesDialog: boolean
  contentSearchFlag: boolean
  constructor(private readonly searchService: SearchService,
              public dialog: MatDialog,
              private readonly sharedDataService: SharedDataService,
              private readonly changeDetector: ChangeDetectorRef) {
    this.subscriptions = new Subscription()
    this.canShowLoading = false
    this.canShowLoadingKbContent = false
    this.companies = []
    this.contacts = []
    this.locations = []
    this.passwords = []
    this.assetts = []
    this.documents = []
    this.ssls = []
    this.contentSearchFlag = this.sharedDataService.getGlobalSearchContentSearch() ? true : false
    this.globalSearchForm = new FormGroup({
      searchField: new FormControl(),
      kbSearchContent: new FormControl(false),
      highlightText: new FormControl(this.contentSearchFlag),
    })
    this.selectedCompanies = this.sharedDataService.getGlobalSearchCompanies()
    this.isSelectedCompaniesDialog = false
  }
  /*** Change screen from global search to advance kb search */
  toggleFlag(content: string) {
    this.clearArray()
    this.globalSearchForm.value.searchField = ''
  }
  /*** Clear search assets array */
  clearArray() {
    this.companies = []
    this.contacts = []
    this.locations = []
    this.passwords = []
    this.assetts = []
    this.documents = []
    this.ssls = []
  }
  /*** On load autofocus on search box */
  ngAfterViewInit() {
    this.textBoxFocus()
  }
  /*** Change event of search textbox  */
  search() {
    this.globalSearchForm.controls.searchField.markAsTouched()
    if (!this.globalSearchForm.valid) {
      return
    }
    !this.globalSearchForm.value.kbSearchContent ? this.searchCall() : this.kbContentSearch()
  }
  /*** Close search page  */
  close() {
    this.closeSearch.emit(true)
  }
  /*** Sends http request to get global search data by using search service  */
  searchCall() {
    this.clearArray()
    if (this.globalSearchForm.value.searchField.length <= 2) {
        return
    }
    if (this.canShowLoading) {
      this.subscriptions.unsubscribe()
      this.subscriptions = new Subscription()
    }
    this.canShowLoading = true
    this.subscriptions.add(this.searchService.searchCall(this.globalSearchForm.value.searchField).subscribe((data: ISearchBKResponse) => {
        this.processAfterSearchCall(data)
        this.changeDetector.detectChanges()

    }, err => {
      this.changeDetector.detectChanges()
    }))
  }
  /*** Process search response data  */
  processAfterSearchCall(data: ISearchBKResponse) {
    this.clearArray()
    this.canShowLoading = false
    const filterData = this.searchService.filterDataByType(data, this.globalSearchForm.value.searchField,
      this.companies, this.contacts, this.locations, this.passwords, this.assetts, this.documents, this.ssls)
    this.companies = filterData.companies
    this.contacts = filterData.contacts
    this.locations = filterData.companySite
    this.passwords = filterData.passwords
    this.assetts = filterData.assetts
    this.documents = filterData.documents
    this.ssls = filterData.ssls
  }
  /*** Autofocus on search textbox  */
  textBoxFocus() {
    document.getElementById('itbSearch').focus()
  }
  /*** Removes duplicate data from array  */
  removeDublicateObjects(array: ISearchAssets[], prop: string) {
    return this.searchService.removeDublicateObjects(array, prop)
  }
  /*** Sends http request to get advance kb search by using search service  */
  kbContentSearch() {
    this.clearArray()
    if (this.globalSearchForm.value.searchField.length < 3) {
      return
    }
    if (this.canShowLoadingKbContent) {
      this.subscriptions.unsubscribe()
      this.subscriptions = new Subscription()
    }
    this.canShowLoadingKbContent = true
    this.subscriptions.add(this.searchService.kbContentCall(
      this.globalSearchForm.value.searchField).subscribe((dataContent: IKbContentSearch[]) => {
        this.canShowLoadingKbContent = false
        this.documents = this.searchService.processAfterSearchContentCall(dataContent, this.documents)
        this.changeDetector.detectChanges()
    }, err => {
      this.changeDetector.detectChanges()
    }))
  }
  /*** View of clicked assets  */
  getData(item: ISearchAssets, flag: string) {
    this.clearArray()
    this.searchService.getData(item, flag)
    this.close()
  }

  filterCompanies() {
    this.isSelectedCompaniesDialog = true
    const dialogRef = this.dialog.open(SearchCompaniesDialogComponent, {
      data: {
        selected: this.selectCompny,
      },
    })
    dialogRef.afterClosed().subscribe(result => {
      this.isSelectedCompaniesDialog = false
    })
  }
  selectCompny = () => {
    this.selectedCompanies = this.sharedDataService.getGlobalSearchCompanies()
  }
  changeEvent(value) {
    if (value) {
      this.sharedDataService.setGlobalSearchContentSearch(value.checked ? true : false)
    }
  }

  /*** Unsubscribe all subscription when component destroy*/
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
