<itb-heading mat-dialog-title [className]="'modal-h'" [text]="'TAGS'"></itb-heading>
<itb-round-button
  [tooltipText]="'Close'"
  [className]="'light round-modal-cross caret-close-modal'"
  [iconClassName]="'cross'"
  (onClickHandler)="onCancel()"
></itb-round-button>
<div mat-dialog-content>
  <itb-multi-select-chips
    [tags]="tags"
    [enableBoth]="true"
    [isSelectFromOtionOnly]="true"
    [options]="options"
    [isDisabled]="isDisabled"
    [placeholder]="placeHolder"
    [tagsMaxLength]="10"
    (onRemoveHandler)="updateTags($event)"
    (onAddHandler)="updateTags($event)"
  ></itb-multi-select-chips>
</div>
<div mat-dialog-actions>
  <itb-default-button
    *ngIf="!isDisabled"
    [text]="buttons.TEXTS.SAVE"
    [className]="buttons.CLASSES.PRIMARY"
    [iconClassName]="buttons.TEXTS.EDIT"
    (onClickHandler)="onSave()"
  ></itb-default-button>

  <itb-default-button
    *ngIf="!isDisabled"
    [text]="buttons.TEXTS.CANCEL"
    [className]="buttons.CLASSES.DEFAULT"
    [iconClassName]="buttons.CLASSES.CROSS"
    (onClickHandler)="onCancel()"
  ></itb-default-button>

  <itb-default-button
    *ngIf="isDisabled"
    [text]="buttons.TEXTS.CLOSE"
    [className]="buttons.CLASSES.DEFAULT"
    [iconClassName]="buttons.CLASSES.CROSS"
    (onClickHandler)="onCancel()"
  ></itb-default-button>
</div>
