import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { LoginComponent } from '@app/modules/login/components/login/login.component'
import { SharedModule } from '@app/modules/shared/shared.module'
import { UiComponentsModule } from '@app/ui-components.module'

import { RegisterComponent } from './components/register/register.component'
import { ResetPasswordComponent } from './components/reset-password/reset-password.component'

@NgModule({
  declarations: [LoginComponent, RegisterComponent, ResetPasswordComponent],
  imports: [
    CommonModule,
    UiComponentsModule,
    SharedModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,

  ],
  exports: [],
})
export class LoginModule { }
