<form>
      <div>
        <itb-textarea  [formFieldName]="'description'" [isRequired]="true" [rows]="'3'" [placeholder]="'Description'" [parentForm]="inputForm">
        </itb-textarea>
        <itb-info-message *ngIf="form.description.errors?.required && (form.description.touched || isShowErrorsOnSubmit)"
                          [text]="'Description is required'" [className]="'info-danger'"></itb-info-message>
      </div>
      <div>
        <itb-select
          [formFieldName]="'priority'"
          [isDisabled]="isDisabled"
          [parentForm]="inputForm"
          [items]="priorityOptions"
          [label]="'Select Priority'"
          (onChangeHandler)="onPriorityChange($event)"
        ></itb-select>
      </div>
      <div>
        <itb-switch
          [label]="'show as popup'"
          [labelPosition]="'after'"
          [parentForm]="inputForm"
          [formFieldName]="'modalFlag'">
        </itb-switch>
      </div>
  <div class="rsb-btngroup">
    <itb-button-group [buttons]="buttons" [className]="'button-group'" (onClickHandler)="btnClickHandler($event)"></itb-button-group>
  </div>
</form>
