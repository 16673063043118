import { NgModule } from '@angular/core'
import { BREAKPOINT, FlexLayoutModule } from '@angular/flex-layout'

const SMALL_SCREEN_BREAKPOINT = [{
  alias: 'sm',
  mediaQuery: 'screen and (min-width: 500px) and (max-width: 768px)',
  overlapping: true,
  priority: 1001,
}]

export const customBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: SMALL_SCREEN_BREAKPOINT,
  multi: true,
}

@NgModule({
  imports : [ FlexLayoutModule ],
  providers: [ customBreakPointsProvider],
})

export class CustomBreakpointsModule { }
