import { Injectable } from '@angular/core'
import { ApiService } from '@app/services/api.service'

import { END_POINTS } from './../../constants/constants'
import { CompanyDashboardService } from './../companyDashboard/company-dashboard.service'

@Injectable({
  providedIn: 'root',
})
export class AssetWarrantyService {
  constructor(private readonly baseService: ApiService, private readonly utils: CompanyDashboardService) { }

  getCompanyAssetsWarrnatyCounts(companyId: string) {
    return this.baseService.get(`${END_POINTS.GET_SERVERS(companyId)}542d4b65-a615-4e3c-b381-93447caa9b0b`, '', true)
  }

  getCompanyAssetsWarrnatyDetails(companyId: string) {
    return this.baseService.get(END_POINTS.GET_ASSETS_DETAILS(companyId), '', true)
  }

  getCompanyDomainCount(companyId: string) {
    return this.baseService.get(`${END_POINTS.GET_SERVERS(companyId)}24197946-6016-4a69-85c9-24eb994cd5f0`, '', true)
  }
  getCompanyDomainDetails(companyId: string, sqpr, pageNo: number, pageSize = 20) {
    const filterSQPR = this.utils.getFilterSQPR(sqpr.filterModel)
    const sortSQPR = this.utils.getSortSQPR(sqpr.sortModel)
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${END_POINTS.GET_DOMAIN_DETAILS(companyId)}${
      Object.entries(filterSQPR).length ? `?conditions=${JSON.stringify(filterSQPR)}` : `?conditions=${JSON.stringify({})}`
      }${sortSQPR ? `&sortBy=${sortSQPR.key}&order=${sortSQPR.order}&pageNo=${pageNo}&pageSize=${pageSize}` : ''}`,
      '',
      true
    )
  }
  getCompanyDomainCounts(companyId: string) {
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(END_POINTS.GET_DOMAIN_COUNTS(companyId), '', true)
  }
  getFilterDomainDetails(status: string, companyId, sqpr) {
    return this.baseService.get(`${END_POINTS.GET_DOMAIN_FILTERED_DETAILS(status)}?detailFlag=true&companyId=${companyId}`, '', true)
  }
  getCompanySslDetail(companyId: string, sqpr, pageNo: number, pageSize = 20) {
    const filterSQPR = this.utils.getFilterSQPR(sqpr.filterModel)
    const sortSQPR = this.utils.getSortSQPR(sqpr.sortModel)
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${END_POINTS.GET_SSL_DETAILS(companyId)}${
      Object.entries(filterSQPR).length ? `?conditions=${JSON.stringify(filterSQPR)}` : `?conditions=${JSON.stringify({})}`
      }${sortSQPR ? `&sortBy=${sortSQPR.key}&order=${sortSQPR.order}&pageNo=${pageNo}&pageSize=${pageSize}` : ''}`,
      '',
      true
    )
  }
  getCompanySslCounts(companyId: string) {
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${END_POINTS.GET_SSL_COUNTS(companyId)}`, '', true)
  }

  getCompanyDomainColDefs(id) {
    return [
      {
        headerName: 'NAME', field: 'domainName', type: 'name', minWidth: 180, filter: 'agTextColumnFilter',
        cellRendererParams: {
          baseURL: 'app/company/$companyId/domains/view',
          propertyName: 'uuid',
          companyId: id,
        },
      },
      { headerName: 'STATUS', field: 'status', minWidth: 180, filter: 'agTextColumnFilter' },
      {
        headerName: 'EXPIRATION DATE', field: 'expiration_date', minWidth: 180,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      { headerName: 'CONTACT', field: 'contact.registrant.name', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'REGISTRAR', field: 'registrar', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'SERVER', field: 'whois_server', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'SEARCH TAGS', field: 'searchTags', type: 'tags', minWidth: 180, filter: 'agTextColumnFilter' },
    ]
  }
  getCompanySslColDefs(id) {
    return [
      {
        headerName: 'NAME', field: 'website', type: 'name', minWidth: 180, filter: 'agTextColumnFilter',
        cellRendererParams: {
          baseURL: 'app/company/$companyId/ssls/view',
          propertyName: 'uuid',
          companyId: id,
        },
      },
      { headerName: 'SERVER NAME', field: 'serverName', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'IP ADDRESS', field: 'ipAddress', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'ISSURER LABLE', field: 'issurerLable', minWidth: 180, filter: 'agTextColumnFilter' },
      {
        headerName: 'VALID FROM', field: 'validFrom', minWidth: 180, filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        headerName: 'VALID UNTIL', field: 'validUntil', minWidth: 180, filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      { headerName: 'ALTERNATIVE NAME', field: 'alternativeName', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'STATUS', field: 'status', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'SEARCH TAGS', field: 'searchTags', type: 'tags', minWidth: 180, filter: 'agTextColumnFilter' },
    ]
  }
  getCompanyAssetsWarrnatyColDefs(id) {
    return [
      {
        headerName: 'CONFIGURATION NAME', field: 'name',
        minWidth: 180, type: 'name', filter: 'agTextColumnFilter',
        suppressColumnsToolPanel: true, lockPosition: true,
        cellRendererParams: {
          baseURL: 'app/company/$companyId/configurations/view',
          propertyName: 'uuid',
          companyId: id,
        },
      },
      { headerName: 'TYPE NAME', field: 'type.name', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'STATUS NAME', field: 'status.name', minWidth: 180, filter: 'agTextColumnFilter' },
      {
        headerName: 'ASSET DATE', field: 'AssetDate', minWidth: 180, filter: 'agDateColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      { headerName: 'RECORD STATUS', field: 'recordStatus', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'IP ADDRESS', field: 'ipAddress', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'MAINTENANCE WINDOW', field: 'maintenanceWindow', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'ASSET TAG', field: 'searchTags', minWidth: 180, type: 'tags', filter: 'agTextColumnFilter' },
      { headerName: 'NAME', field: 'Name', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'DOMAIN', field: 'Domain', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'BIOS MFG', field: 'biosMFG', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'BIOS NAME', field: 'biosName', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'UP TIME', field: 'UpTime', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'LAST LOGIN NAME', field: 'lastLoginName', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'SERIAL NUMBER', field: 'serialNumber', minWidth: 180, filter: 'agTextColumnFilter' },
      {
        headerName: 'PURCHASE DATE', field: 'purchaseDate', minWidth: 180, filter: 'agDateColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      { headerName: 'PROCESSOR NAME', field: 'processorName', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'TOTAL MEMORY', field: 'TotalMemory', minWidth: 180, filter: 'agTextColumnFilter' },
      {
        headerName: 'INSTALLATION DATE', field: 'installationDate', minWidth: 180, filter: 'agDateColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      { headerName: 'PURCHASE BY', field: 'purchaseBy', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'OS', field: 'OS', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'OS INFO', field: 'osInfo', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'OS INSTALL DATE', field: 'installationDate', minWidth: 180, filter: 'agDateColumnFilter' },
      { headerName: 'DNS INFO', field: 'DNSInfo', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'ROUTER ADDRESS', field: 'RouterAddress', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'MANAGEMENT PORT', field: 'ManagementPort', minWidth: 180, filter: 'agTextColumnFilter' },
      { headerName: 'SEARCH TAGS', field: 'tags', type: 'tags', minWidth: 180, filter: 'agTextColumnFilter' },
    ]
  }
  getPieChartConfig(counts, namee) {
    return {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      series: [
        {
          name: namee,
          type: 'pie',
          radius: ['56%', '44%'],
          center: ['50%', '20%'],
          avoidLabelOverlap: false,
          color: counts === undefined ? ['#ddd'] : ['#e14c42', '#716aca', ' #00c5dc', '#ffb822'],
          label: {
            normal: {
              show: false,
              position: 'center',
            },
            emphasis: {
              show: false,
              textStyle: {
                fontSize: '30',
                fontWeight: 'bold',
              },
            },
          },
          animation: false,
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: counts,
          tooltip: {
            backgroundColor: 'rgba(0,0,0,.75)',
            formatter: '{b0}: {c0} ({d0}%)',
            padding: [3, 5],
            textStyle: {
              fontSize: 13,
            },
          },
        },
      ],
    }
  }
}
