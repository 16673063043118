<div class="mt-3" *ngIf="isFileNameInvalid"><itb-info-message [text]="'Please upload file with valid name.'" [className]="'info-danger'"></itb-info-message></div>
<ng-container>
  <ngx-dropzone (change)="fileSelectHandler($event)" [disableClick]="isDropZoneDisabled">
    <ngx-dropzone-label>
      Drop files to upload <br />
      or click here
    </ngx-dropzone-label>
    <div class="dropzone-img-container" [ngClass]="last && !isUploaded ? 'cancel-file' : 'remove-file'"
         *ngFor="let f of files; let last = last; let i = index">
      <app-company-pods-custom-drop-zone-preview ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="true" (removed)="fileRemovHandler(f.uuid)"
                                   (download)="fileDownloadHandler($event, f.imagePath, f.name)" [showDownload] = "!last || (last && isUploaded)" [fileObj]="f">
        <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
      </app-company-pods-custom-drop-zone-preview>
      <itb-bar-loader *ngIf="last && !isUploaded" [mode]="'indeterminate'"> </itb-bar-loader>
      <itb-round-button *ngIf="isUploaded" class="dropzone-img-tick" [className]="'light'" [iconClassName]="'tick'"></itb-round-button>
    </div>
  </ngx-dropzone>
</ng-container>
