import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog, MatSelectChange } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import {
  BREAD_CRUMBS,
  BUTTONS,
  MODAL_CONSTANTS,
  NOTIFICATION,
  PREPARED_BUTTONS,
  PREPARED_ICONS,
} from '@app/constants/ui-components.constants'
import { CanComponentDeactivate } from '@app/guards/can-deactivate-guard'
import { IActionPannelButton, IBreadcrumbIcon, IBreadcrumbLink } from '@app/interfaces/ui-components.interface'
import { ModalService } from '@app/services/modal.service'
import { ISelect } from '@projects/company-dashboard/src/app/modules/configuration/interfaces/configuration.interface'
import * as moment from 'moment'
import { Subscription } from 'rxjs'
import { Select } from 'ui-components/lib/interfaces/select-options.interface'

import { COUNTRIES, STATES } from '../../constants/company-creation.constants'
import { ICompanyObject, IIconClickEvent, IStatusOptions, ITypeOptions } from '../../interfaces/company-creation'
import { CompanyCreationService } from '../../services/company-creation.service'
import { AddCompanyTypeModalComponent } from '../add-company-type-modal/add-company-type-modal.component'

@Component({
  selector: 'app-company-company-creation',
  templateUrl: './company-creation.component.html',
})
export class CompanyCreationComponent extends CanComponentDeactivate implements OnInit, OnDestroy {
  itsUSA: boolean
  syncFlag: boolean
  isSubmittedFlag: boolean
  identifierExist: boolean
  companyForm: FormGroup
  subscriptions: Subscription
  event: string
  isButtonLoading: boolean
  countries: Select[]
  members: ISelect[]
  classifications: ISelect[]
  states: Select[]
  links: IBreadcrumbLink[]
  icons: IBreadcrumbIcon[]
  companyTypeOptions: Select[]
  companyStatusOptions: Select[]
  companyStatus: Select[]
  searchTags: string[]
  integrationType: string
  autoTaskFlag: boolean
  private readonly backBtnArray: IActionPannelButton[]

  constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _fb: FormBuilder,
    private readonly _dialog: MatDialog,
    private readonly _companyCreationService: CompanyCreationService,
    private readonly _modalService: ModalService
  ) {
    super(_dialog)
    this.links = [
      {
        name: 'DASHBOARD',
        route: '/app/dashboard',
      },
      {
        name: 'ADD COMPANY',
        route: '',
      },
    ]
    this.icons = [PREPARED_ICONS.TAG_ICON, PREPARED_ICONS.ASYNC_ICON]
    this.states = STATES
    this.countries = COUNTRIES
    this.itsUSA = false
    this.companyStatusOptions = []
    this.companyTypeOptions = []
    this.searchTags = []
    this.subscriptions = new Subscription()
    this.backBtnArray = [PREPARED_BUTTONS.CONFIRM_BUTTON, PREPARED_BUTTONS.CANCEL_BUTTON]
    this.syncFlag = JSON.parse(localStorage.getItem('user')).cwSyncFlag
    this.isSubmittedFlag = false
    this.event = ''
    this.members = []
    this.classifications = []
    this.companyStatus = []
    this.isButtonLoading = false
    this.identifierExist = false
    this.integrationType = JSON.parse(localStorage.getItem('user')).mainIntegration
    if (!this.integrationType) {
      this.autoTaskFlag = false
      this.syncFlag = false
    } else {
      this.integrationType === 'autotask' ? (this.autoTaskFlag = true) : (this.autoTaskFlag = false)
    }
  }

  ngOnInit() {
    this.createForms()
    this.initializeOptions()
    this.initializeSync()
    if (this.autoTaskFlag) {
      this.initilizeAutoTask()
    }
  }

    /*** Creates the form with validations */
  createForms() {
    this.companyForm = this._fb.group({
      name: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(3)]],
      identifier: ['', [Validators.required, Validators.maxLength(20)]],
      types: ['', Validators.required],
      status: ['', Validators.required],
      addressLine1: ['', [Validators.required, Validators.maxLength(60)]],
      addressLine2: ['', [Validators.maxLength(60)]],
      country: [''],
      state: [''],
      city: ['', Validators.maxLength(20)],
      zip: ['', Validators.maxLength(30)],
      date: [''],
      website: ['', Validators.maxLength(25)],
      companyClassification: [''],
      companyMembers: ['', this.autoTaskFlag && Validators.required],
      phoneNumber: ['', this.autoTaskFlag && Validators.required],
    })
  }

    /*** Sets company sync status */
  initializeSync() {
    if (this.syncFlag) {
      if (this.autoTaskFlag) {
        this.companyStatusOptions = [
          { id: 1, label: 'Active', isDisabled: false, isSelected: false },
          { id: 2, label: 'Inactive', isDisabled: false, isSelected: false },
        ]
        this.countries = COUNTRIES
      } else {
        this.companyStatusOptions = this.companyStatus
        this.getCountries()
      }
      this.companyForm.get('state').reset()
      this.icons[1] = PREPARED_ICONS.SYNC_ICON
    } else {
      this.countries = COUNTRIES
      this.companyForm.get('state').reset()
      this.icons[1] = PREPARED_ICONS.ASYNC_ICON
      this.companyStatusOptions = this.companyStatus
    }
  }

    /*** Gets all countries for the select dropdown from the server */
  getCountries() {
    this._companyCreationService.getCountries().subscribe(countries => {
      this.countries = countries
    })
  }

  initilizeAutoTask() {
    this._companyCreationService.getMembers().subscribe(members => {
      if (members.length) {
        members.forEach(element => {
          this.members.push({
            id: element.id,
            label: element.name,
            isSelected: false,
            isDisabled: false,
          })
          this.members = [...this.members]
        })
      }
    })

    this._companyCreationService.getClassifications().subscribe(classifications => {
      if (classifications.length) {
        classifications.forEach(element => {
          this.classifications.push({
            id: element.id,
            label: element.name,
            isSelected: false,
            isDisabled: false,
          })
          this.classifications = [...this.classifications]
        })
      }
    })
  }
  initializeOptions() {
    this.subscriptions.add(
      this._companyCreationService.getCompanyTypes().subscribe((companyTypes: ITypeOptions[]) => {
        if (companyTypes.length) {
          companyTypes.reverse().forEach(element => {
            if (element.name) {
              this.companyTypeOptions.push({
                id: element.id,
                label: element.name,
                isSelected: !this.autoTaskFlag && element.defaultFlag ? true : false,
                isDisabled: false,
              })
            }
          })
          if (!this.autoTaskFlag) {
            this.companyForm.patchValue({
              types: this.companyTypeOptions.filter(e => e.isSelected),
            })
          }
        }
      })
    )
    this.subscriptions.add(
      this._companyCreationService.getCompanyStatus().subscribe((companyStatus: IStatusOptions[]) => {
        if (companyStatus.length) {
          companyStatus.reverse().forEach(element => {
            if (element.name) {
              this.companyStatus.push({
                id: element.id,
                label: element.name,
                isSelected: element.defaultFlag ? true : false,
                isDisabled: false,
              })
            }
          })
          this.companyForm.patchValue({
            status: this.companyStatusOptions.find(e => e.isSelected),
          })
        }
      })
    )
  }

    /*** Shows the add company type modal  */
  addNewCompanyTypeHandler() {
    const dialogRef = this._dialog.open(AddCompanyTypeModalComponent, {
      width: MODAL_CONSTANTS.MODAL_WIDTH,
      data: { title: 'ADD COMPANY TYPE', type: 'Type', integrationType: this.integrationType },
      disableClose: true,
    })
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.companyTypeOptions.unshift({
            id: result.data.result.id,
            label: result.data.result.name,
            isSelected: result.data.result.defaultFlag,
            isDisabled: false,
          })
          this.companyTypeOptions = [...this.companyTypeOptions]
          this.companyForm.get('types').reset()
          this.companyForm.patchValue({
            types: this.companyTypeOptions.filter(e => e.isSelected),
          })
        }
      })
    )
  }

    /*** Shows the add company status modal */
  addNewCompanyStatusHandler() {
    const dialogRef = this._dialog.open(AddCompanyTypeModalComponent, {
      width: MODAL_CONSTANTS.MODAL_WIDTH,
      data: { title: 'ADD COMPANY STATUS', type: 'Status', integrationType: this.integrationType },
      disableClose: true,
    })
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.companyStatusOptions.unshift({
            id: result.data.result.id,
            label: result.data.result.name,
            isSelected: result.data.result.defaultFlag,
            isDisabled: false,
          })
          this.companyStatusOptions = [...this.companyStatusOptions]
          this.companyForm.patchValue({
            status: this.companyStatusOptions[0],
          })
        }
      })
    )
  }

    /*** When USA is selected, shows the list of states in the select dropdown */
  changeCountryHandler(event: MatSelectChange) {
    this.itsUSA = false
    this.companyForm.get('state').reset()
    if (event.source.value.name === 'United States') {
      this.itsUSA = true
    }
  }

    /*** Updates type when a different option is selected from the dropdown */
  changeTypeHandler(event: MatSelectChange) {
    const index = this.companyTypeOptions.findIndex(el => el.id === event.source.value.id || el.label === event.source.value.label)
    event.source.selected ? (this.companyTypeOptions[index].isSelected = true) : (this.companyTypeOptions[index].isSelected = false)
  }

  /*** On Back button pressed */
  backHandler(event: string) {
    this.event = event
    this._router
      .navigateByUrl('app/companies/list')
      .then()
      .catch()
  }

  /*** Updates the value of state field on selection */
  onStateChangeEvent(event: string) {
    this.companyForm.patchValue({
      state: event,
    })
  }

  /*** If validations are passed, sends a post request to create the company */
  saveHandler(event: string) {
    this.event = event
    this.isSubmittedFlag = true
    if (this.companyForm.valid) {
      this.saveCompanyForm()
    }
  }

    /*** Sanitizes data and sends the request to create company - displays the appropriate modal based on the response */
  saveCompanyForm() {
    this.isButtonLoading = true
    const companyObject = this.companyCreationObject()
    if (!Object.keys(this.companyForm.value.country).length) {
      delete companyObject.country
    } else {
      companyObject.country = {
        id: this.companyForm.value.country.id,
        name: this.companyForm.value.country.label || this.companyForm.value.country.name,
      }
    }
    if (!this.autoTaskFlag || !this.syncFlag) {
      delete companyObject.resource
      delete companyObject.companyClassification
      delete companyObject.phoneNumber
    }
    if (!this.autoTaskFlag) {
      delete companyObject.type
      if (!this.syncFlag) {
        const array = []
        array.push(companyObject.types)
        companyObject.types = array
      }
    } else {
      delete companyObject.types
    }
    if (!companyObject.status.id) delete companyObject.status.id
    if (this.autoTaskFlag && this.syncFlag) {
      delete companyObject.status.id
    }
    if (!this.integrationType) delete companyObject.mainIntegration
    this.identifierExist = false
    this.subscriptions.add(
      this._companyCreationService.postCompany(companyObject).subscribe(
        (companyData: ICompanyObject) => {
          this._modalService.showAlertNotification('success', 'Saved!', `${companyData.name}`, 'Company added.')
          this.isButtonLoading = false
          this._router
            .navigate([`app/company/${companyData.uuid}/locations/add`], { queryParams: { company: true } })
            .then()
            .catch()
        },
        error => {
          if (error.error_code !== 40403) {
            this._modalService
              .showDeleteConfirmationModal(
                'SYNC FAILED',
                `Sync with PSA Failed. Error: ${error.error_code} .Do you want to save a local copy of the record?`,
                [PREPARED_BUTTONS.CONFIRM_BUTTON, PREPARED_BUTTONS.CANCEL_BUTTON]
              )
              .subscribe(result => {
                if (result === BUTTONS.EVENTS.CONFIRM) {
                  this.syncFlag = false
                  this.saveCompanyForm()
                }
              })
          } else {
            this.identifierExist = true
          }
          this.isButtonLoading = false
        }
      )
    )
  }

    /*** Creates the company object based on the format required for the back end */
  companyCreationObject(): ICompanyObject {
    const company = {
      mainIntegration: this.integrationType,
      name: this.companyForm.value.name,
      identifier: this.companyForm.value.identifier,
      types:
        this.autoTaskFlag || !this.syncFlag
          ? { id: this.companyForm.value.types.id || '', name: this.companyForm.value.types.label || '' }
          : this.getTypes(),
      type:
        this.autoTaskFlag || !this.syncFlag
          ? { id: this.companyForm.value.types.id || '', name: this.companyForm.value.types.label || '' }
          : this.getTypes(),
      status: { id: this.companyForm.value.status.id || '', name: this.companyForm.value.status.label || '' },
      addressLine1: this.companyForm.value.addressLine1,
      addressLine2: this.companyForm.value.addressLine2,
      country: { id: '', name: '' },
      state: this.getcountryState(),
      city: this.companyForm.value.city,
      zip: this.companyForm.value.zip,
      date: this.companyForm.value.date ? moment(this.companyForm.value.date).format('MM/DD/YYYY') : '',
      website: this.companyForm.value.website,
      searchTags: this.searchTags,
      syncFlag: this.syncFlag,
      site: { id: 4545, name: 'dummy' },
      resource:
        this.companyForm.value.companyMembers && Object.keys(this.companyForm.value.companyMembers).length
          ? { id: this.companyForm.value.companyMembers.id, name: this.companyForm.value.companyMembers.label }
          : { id: '', name: '' },
      companyClassification:
        this.companyForm.value.companyClassification && Object.keys(this.companyForm.value.companyClassification).length
          ? { id: this.companyForm.value.companyClassification.id, name: this.companyForm.value.companyClassification.label }
          : { id: '', name: '' },
      phoneNumber: this.companyForm.value.phoneNumber,
    }

    return company
  }

    /*** Gets all types of companies of the organization */
  getTypes() {
    const types = []
    this.companyForm.value.types.forEach(el => {
      types.push({
        id: el.id || '',
        name: el.label,
      })
    })
    types.forEach((el, index) => {
      if (!el.id) delete el.id
    })
    return types
  }

  get typesValidator() {
    if (!this.syncFlag) return true
    if (this.autoTaskFlag && this.companyForm && this.companyForm.value) return this.companyForm.value.types.id
    if (!this.autoTaskFlag && this.companyForm && this.companyForm.value && this.companyForm.value.types) {
      return this.companyForm.value.types.some(el => el.id)
    }
  }

  get typesValueLengthValidatior() {
    return this.companyTypeOptions.length && this.companyForm.get('types').value && this.companyForm.get('types').value.length
  }

   /*** Gets value of state dropdown */
  getcountryState(): string {
    if (!this.companyForm.value.state) return
    if (typeof this.companyForm.value.state === 'string') {
      return this.companyForm.value.state
    }
    return this.companyForm.value.state.label
  }

    /*** Handles click on icons - based on the event.name */
  iconClickHandler(event: IIconClickEvent) {
    if (event.name === BREAD_CRUMBS.NAMES.TAGS) {
      this._modalService.showSearchTagsModal(this.searchTags, false).subscribe(result => {
        if (result instanceof Array) {
          this.searchTags = result
        }
      })
    }
    if (this.integrationType) {
      if (event.name === BREAD_CRUMBS.NAMES.ASYNC) {
        if (this.autoTaskFlag) {
          this.companyStatusOptions = [
            { id: 1, label: 'Active', isDisabled: false, isSelected: false },
            { id: 2, label: 'Inactive', isDisabled: false, isSelected: false },
          ]
          this.countries = COUNTRIES
        } else {
          this.companyStatusOptions = this.companyStatus
          this.getCountries()
        }
        this.syncFlag = true
        this.itsUSA = false
        this.companyForm.get('state').reset()
        this.icons[1] = PREPARED_ICONS.SYNC_ICON
      } else {
        if (this.autoTaskFlag) {
          this.companyStatusOptions = [
            { id: 1, label: 'Active', isDisabled: false, isSelected: false },
            { id: 2, label: 'Inactive', isDisabled: false, isSelected: false },
          ]
        } else {
          this.companyStatusOptions = this.companyStatus
        }
        this.syncFlag = false
        this.countries = COUNTRIES
        this.itsUSA = false
        this.companyForm.get('state').reset()
        this.icons[1] = PREPARED_ICONS.ASYNC_ICON
      }
    }
  }

    /*** Gets date value */
  dateHandler(event) {
    this.companyForm.patchValue({
      date: event.target.value,
    })
  }

  canDeactivate(): boolean {
    if (this.event === BUTTONS.TEXTS.SAVE) return true
    return false
  }

    /*** Unsubscribes from all subscriptions */
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
