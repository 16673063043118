<div *ngIf="isScreenEnabled" class="set-passwod-main-section">
    <div class="set-password-change" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div class="logo-section" fxFlex="35" fxFlex.sm="100" fxFlex.xs="100">
            <img class="company-logo" src="images-library/logo.png" width="180px" />
            <h3>Set Password</h3>
        </div>
        <div class="change-pass-section" fxFlex="65" fxFlex.sm="100" fxFlex.xs="100">
            <form [formGroup]="changePasswordForm" class="form-section">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="50px" fxLayoutGap.xs="0">
                    <div fxFlex="50" fxLayout="column">

                        <div fxLayout="column" class="mb-3">
                            <div>
                                <itb-input [formFieldName]="'newPassword'" [parentForm]="changePasswordForm"
                                    [label]="'NEW PASSWORD'" (onIconClickHandler)="clickCatch($event)" [placeholder]=""
                                    [maxlength]="50" [isRequired]="true" [type]="inputType"
                                    (onChangeHandler)="changeNewPwd()" [icons]="iconsArray"></itb-input>
                            </div>
                            <div class="p-r-12 p-l-12 passStrength">
                                <div class="pass-progress" fxFlex="25" id="1stbar"
                                    [ngClass]="{'valid': this.isFirstBarStyle}">
                                </div>
                                <div class="pass-progress" fxFlex="25" id="2ndbar"
                                    [ngClass]="{'valid': this.isSecondBarStyle}">
                                </div>
                                <div class="pass-progress" fxFlex="25" id="3rdbar"
                                    [ngClass]="{'valid': this.isThirdBarStyle}">
                                </div>
                                <div class="pass-progress" fxFlex="25" id="4thbar"
                                    [ngClass]="{'valid': this.isFourthBarStyle}">
                                </div>
                                <itb-info-message [text]="errorMsgForPwd" [className]="'info-danger'"
                                    *ngIf="!hasErrorMsgForPwdFlagConfrm && !hasErrorMsgForPwdFlag && hasErrorMsgForPwdFlagNew">
                                </itb-info-message>
                            </div>
                        </div>
                        <div fxFlex="100">
                            <itb-input [formFieldName]="'confirmNewPassword'" [parentForm]="changePasswordForm"
                                [label]="'Confirm password'" (onIconClickHandler)="clickCatchConfirmPassword($event)"
                                [placeholder]="" [maxlength]="50" [isRequired]="true" [type]="inputTypeForChange"
                                (onChangeHandler)="changeNewConfirmPwd()" [icons]="iconsArrayForChange"></itb-input>
                            <itb-info-message [text]="errorMsgForPwd" [className]="'info-danger'"
                                *ngIf="hasErrorMsgForPwdFlagConfrm && !hasErrorMsgForPwdFlag && !hasErrorMsgForPwdFlagNew">
                            </itb-info-message>
                            <itb-info-message [text]="'Password does not match'" [className]="'info-danger'"
                                *ngIf="isWrongPasswordText && changePasswordForm.value.newPassword.length!=0">
                            </itb-info-message>
                        </div>
                    </div>
                    <div class="passwordtooltip" fxFlex="50">
                        <h2 class="text-center mt-2 pl-2">
                            Password strength<br>
                            <span class="tooweek" id="description" name="description"
                                [ngClass]="{'text-valid': this.isDescriptionStyle}">{{description}}</span>
                        </h2>
                        <hr class="m-b-20">
                        <div class="pl-3">
                            <p>Password must include</p>
                            <p [ngClass]="{'text-valid': this.isEightCharStyle}" id="eightCharacter">
                                <!--<mat-icon [ngClass]="'pull-left'" *ngIf="!eightChar">clear</mat-icon>-->
                                <itb-icon [tooltipText]="''" [className]="'icon-circle-error'" *ngIf="!hasEightChar">
                                </itb-icon>
                                <!-- <mat-icon [ngClass]="'pull-left'" *ngIf="eightChar">done</mat-icon>-->
                                <itb-icon [tooltipText]="''" [className]="'icon-circle-success'" *ngIf="hasEightChar">
                                </itb-icon>
                                At least 8 characters
                            </p>
                            <p [ngClass]="{'text-valid': this.isCapitalLetterStyle}" id="capitalLetter">
                                <!-- <mat-icon [ngClass]="'pull-left'" *ngIf="!uLetter">clear</mat-icon>-->
                                <!-- <mat-icon [ngClass]="'pull-left'" *ngIf="uLetter">done</mat-icon>-->
                                <itb-icon [tooltipText]="''" [className]="'icon-circle-error'" *ngIf="!isULetter">
                                </itb-icon>
                                <itb-icon [tooltipText]="''" [className]="'icon-circle-success'" *ngIf="isULetter">
                                </itb-icon>
                                At least one capital letter
                            </p>
                            <p [ngClass]="{'text-valid': this.isNumberStyle}" id="number">
                                <!-- <mat-icon [ngClass]="'pull-left'" *ngIf="!number">clear</mat-icon>
                  <mat-icon [ngClass]="'pull-left'" *ngIf="number">done</mat-icon>-->
                                <itb-icon [tooltipText]="''" [className]="'icon-circle-error'" *ngIf="!hasNumber">
                                </itb-icon>
                                <itb-icon [tooltipText]="''" [className]="'icon-circle-success'" *ngIf="hasNumber">
                                </itb-icon>
                                At least one number
                            </p>
                            <p [ngClass]="{'text-valid': this.isSymbolStyle}" id="symbol">
                                <!-- <mat-icon [ngClass]="'pull-left'" *ngIf="!sSymbol">clear</mat-icon>
                  <mat-icon [ngClass]="'pull-left'" *ngIf="sSymbol">done</mat-icon>-->
                                <itb-icon [tooltipText]="''" [className]="'icon-circle-error'" *ngIf="!isSsymbol">
                                </itb-icon>
                                <itb-icon [tooltipText]="''" [className]="'icon-circle-success'" *ngIf="isSsymbol">
                                </itb-icon>
                                At least one special symbol
                            </p>
                            <p [ngClass]="{'text-valid': this.isNospaceStyle}" id="nospace">
                                <!-- <mat-icon [ngClass]="'pull-left'" *ngIf="!nospace">clear</mat-icon>
                  <mat-icon [ngClass]="'pull-left'" *ngIf="nospace">done</mat-icon>-->
                                <itb-icon [tooltipText]="''" [className]="'icon-circle-error'" *ngIf="!hasNoSpace">
                                </itb-icon>
                                <itb-icon [tooltipText]="''" [className]="'icon-circle-success'" *ngIf="hasNoSpace">
                                </itb-icon>
                                No spaces
                            </p>
                        </div>
                    </div>
                </div>
            </form>
            <div class="action-section">
                <itb-default-button [text]="'Save'" [className]="'primary'" [iconClassName]="'save'" [isDisabled]="!changePasswordForm.value.newPassword ||
                             !changePasswordForm.value.confirmNewPassword  || description !== 'Strong' ||
                             (isWrongPasswordText && changePasswordForm.value.newPassword.length!=0)"
                    (onClickHandler)="submit()" [isLoading]="isLoading"></itb-default-button>
            </div>
        </div>
    </div>


    <!-- <mat-dialog-actions align="end">
        <itb-default-button [text]="'Save'" [className]="'primary'" [iconClassName]="'save'" [isDisabled]="!changePasswordForm.value.newPassword ||
                             !changePasswordForm.value.confirmNewPassword  || description !== 'Strong' ||
                             (isWrongPasswordText && changePasswordForm.value.newPassword.length!=0)"
            (onClickHandler)="submit()" [isLoading]="isLoading"></itb-default-button>

    </mat-dialog-actions> -->


</div>

<div class="access-error" *ngIf="hasContent===false">
    <itb-empty-state-image [imageClass]="'img-404error'" [iconClassName]="'btn-innerico back'" [buttonText]="'LOGIN'"
        [buttonClass]="'btn btn-primary'" [headingClass]="'heading'" (onClickHandler)="loginClickHandler()"
        [headingText]="'OOPS! SOMETHING WENT WRONG'" [subHeadingClass]="'text-default'"
        [subHeadingText]="'Either this record doesn\'t exist or you don\'t have access to it'">
    </itb-empty-state-image>
</div>