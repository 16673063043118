import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { SharedService } from '@app/services/shared.service'
import * as DocumentEditor from '@ckeditor/ckeditor5-build-classic'

@Component({
  selector: 'app-public-view-article',
  templateUrl: './public-view-article.component.html',
})
export class PublicViewArticleComponent implements OnInit {
  // tslint:disable-next-line: no-any
  config: any // ck editor config object. No type provided by ckEditor
  article
  isLoading: boolean
  isExists: boolean
  documentEditor: DocumentEditor
    /*** Gets the public article by id from the server */
  constructor(
    public route: ActivatedRoute,
    public sharedService: SharedService
  ) {
    this.documentEditor = DocumentEditor
    this.isLoading = true
    this.isExists = false
    this.config = {
      image: {
        styles: ['full', 'side', 'alignLeft', 'alignRight'],
        toolbar: ['imageStyle:full', 'imageStyle:side', 'imageStyle:alignLeft', '|', 'imageTextAlternative'],
      },
    }
    this.route.queryParams.subscribe(param => {
      this.sharedService.fetchArticleById(param.url).subscribe(
        response => {
          this.article = response
          if (this.article.fileContent) {
            this.reSizeImages()
          }
          if (!(Object.entries(response).length === 0)) {
            this.isExists = true
          }
        },
        error => { },
        () => {
          this.isLoading = false
        }
      )
    })

  }
  ngOnInit() {

  }

  /**
   * Fired when ever CK-Editor gets mounted on the DOM, adding css classes to the div for custom styling
   */
  onReady(
    // tslint:disable-next-line: no-any
    editor: any
  ) { // no type provided by CKEditor
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    )
    const el = document
      .getElementsByClassName('ck-blurred ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline')
    const wrapper = document.createElement('div')
    wrapper.classList.add('document-editor__editable-container')
    el[0].parentNode.appendChild(wrapper)
    wrapper.appendChild(el[0])
  }

    /*** Images are resized to fit in the view */
  reSizeImages() {
    const docElem = document.getElementById('exportConntent')
    docElem.innerHTML = this.article.fileContent
    const images : any  = docElem.getElementsByTagName('img') // tslint:disable-line
    for (const obj of images) {
    const  maxWidth = 654
    const  maxHeight = 321
    let  ratio = 0
    let  width = 0
    let  height = 0
    if (obj.style.width === '' && obj.style.minWidth === '') {
      width = obj.width
      height = obj.height
      } else {
      if (obj.style.minWidth === '') {
      width = parseInt(obj.style.width.replace(/[A-Za-z$-]/g, ''))
      height = parseInt(obj.style.height.replace(/[A-Za-z$-]/g, ''))
      obj.style.width = ''
      obj.style.height = ''
      } else {
      width = parseInt(obj.style.minWidth.replace(/[A-Za-z$-]/g, ''))
      height = parseInt(obj.style.minHeight.replace(/[A-Za-z$-]/g, ''))
      obj.style.minWidth = ''
      obj.style.minHeight = ''
      }
      }
    if (width > maxWidth) {
      ratio = maxWidth / width
      obj.width = maxWidth
      obj.height = height * ratio
      height = height * ratio
      width = width * ratio
      }
    if (height > maxHeight) {
      ratio = maxHeight / height
      obj.width = width * ratio
      obj.height = maxHeight

      width = width * ratio
      height = height * ratio
      }
    obj.width = width  !== undefined && width  !== 0 ? width : maxWidth
    obj.height = height !== undefined && width  !==  0 ?  height : maxHeight
  }

    this.article.fileContent = docElem.innerHTML
  }
}
