import { Injectable } from '@angular/core'
import * as moment from 'moment-timezone'

import { DATE_CONSTANTS, STRING_CONSTANTS } from '../constants/constants'
@Injectable({
  providedIn: 'root',
})
export class DateService {
  /***Converts date to timezone */
  convertDateToTimezoneDate(date: Date, timezone: string): Date {
    return new Date(moment(date).tz(timezone))
  }
  /***Gets timezone of user */
  getTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }
  /***Gets date object*/
  getDate(): Date {
    return new Date()
  }
  /*** Returns days ago base on number of days pass in arguments */
  getXDaysAgoDate(days: number) {
    const currentDate = new Date()
    const daysAgo = new Date()
    daysAgo.setDate(currentDate.getDate() - days)
    return daysAgo
  }
  /*** Converts moment to date */
  convertMomentToDate(date: Date) {
    return new Date(date)
  }
  /*** Converts simple date to moment */
  convertSimpleDateToMoment(date: Date) {
    return moment(date)._d
  }
  /*** Converts string to date object */
  convertStringToDate(date: string) {
    return new Date(date)
  }
  /*** Converts date to string */
  convertDateToMoment(endDate: string): string {
    const newEndDate = new Date(endDate).toISOString()
    const now = moment(new Date().toISOString())
    const end = moment(newEndDate)
    const duration = moment.duration(now.diff(end))
    let hours = duration.asHours()
    let days = duration.asDays()
    let minutes = duration.asMinutes()
    minutes = parseInt(minutes.toString(), 10)
    hours = parseInt(hours.toString(), 10)
    days = parseInt(days.toString(), 10)
    let stringDate
    if (minutes < 1) {
      stringDate = DATE_CONSTANTS.FEW_SECONDS
    } else if (minutes === 1) {
      stringDate = DATE_CONSTANTS.ONE_MINUTE
    } else if (minutes < 61) {
      stringDate = ` ${minutes}  ${DATE_CONSTANTS.MINUTES_A_GO}`
    } else if (hours === 1) {
      stringDate = ` ${hours}   ${DATE_CONSTANTS.HOURS_A_GO}`
    } else if (hours < 24) {
      stringDate = ` ${hours}   ${DATE_CONSTANTS.HOURS_A_GO}`
    } else if (hours >= 24 && hours < 48) {
      stringDate = DATE_CONSTANTS.ONE_DAY_A_GO
    } else if (days > 1 && days < 30) {
      stringDate = ` ${days} ${DATE_CONSTANTS.DAYS_A_GO}`
    } else if (days >= 30 && days <= 59) {
      stringDate = DATE_CONSTANTS.ONE_MONTH
    } else if (days > 59 && days < 365) {
      stringDate = ` ${parseFloat(Math.round(days / 30).toFixed(2))}   ${DATE_CONSTANTS.MONTHS_A_GO}`
    } else if (days >= 365 && days < 730) {
      stringDate = DATE_CONSTANTS.ONE_YEAR
    } else if (days >= 730) {
      stringDate = ` ${parseFloat(Math.round(days / 365).toFixed(2))}   ${DATE_CONSTANTS.YEARS_A_GO}`
    }
    if (stringDate === undefined)
      stringDate = DATE_CONSTANTS.WHILE

    return stringDate
  }

  /* getRefreshTime() {
     return moment().format('MMM DD Y, h:mm a')
   }*/

  /***Gets date string */
  getDataHR(newMinutes: number): string {
    const MINS_PER_YEAR = 24 * 365 * 60
    const MINS_PER_MONTH = 24 * 30 * 60
    const MINS_PER_WEEK = 24 * 7 * 60
    const MINS_PER_DAY = 24 * 60
    const MINS_PER_HOUR = 1 * 60
    let minutes = newMinutes
    const years = Math.floor(minutes / MINS_PER_YEAR)
    minutes = minutes - years * MINS_PER_YEAR
    const days = Math.floor(minutes / MINS_PER_DAY)
    minutes = minutes - days * MINS_PER_DAY
    const hours = Math.floor(minutes / MINS_PER_HOUR)
    minutes = minutes - hours * MINS_PER_HOUR
    let dateString
    if (years !== 0) {
      dateString = `${years}  ${DATE_CONSTANTS.YEARS} ${days} ${DATE_CONSTANTS.DAYS} ${hours}
      ${DATE_CONSTANTS.HOUR} ${minutes}`
    } else if (days !== 0) {
      dateString = `${days}  ${DATE_CONSTANTS.DAYS} ${hours} ${DATE_CONSTANTS.HOUR} ${minutes} ${DATE_CONSTANTS.MINUTES}`
    } else if (hours !== 0) {
      dateString = `${hours} ${DATE_CONSTANTS.DAYS} ${minutes} ${DATE_CONSTANTS.MINUTES}`
    } else {
      dateString = `${minutes} ${DATE_CONSTANTS.MINUTES}`
    }
    return dateString
  }
  /***Gets date time string */
  getDateTimeString(date: string) {
    const now = new Date()
    const countDownDate = new Date(date)
    const distance = this.getDatesDifference(countDownDate, now)
    return this.createDateString(distance)
  }
  /***Gets date string for footer */
  // tslint:disable-next-line: cyclomatic-complexity
  createDateString(distance: number) {
    const months = Math.floor(distance / (1000 * 60 * 60 * 24 * 30))
    let days = Math.floor((distance % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24))
    days = days >= 1 ? days + 1 : days
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)
    let ret =
      months > 1
        ? ` ${months}  ${DATE_CONSTANTS.MONTHS} `
        : months === 1
          ? ` ${months} ${DATE_CONSTANTS.MONTH} `
          : ' '
    ret += days !== 0 ? days > 1 ? ` ${days}  ${DATE_CONSTANTS.DAYS} ` : ` ${days}  ${DATE_CONSTANTS.DAY} `  : ''
    ret += months === 0 && days === 0 && hours !== 0 ? hours > 1 ? ` ${hours}  ${DATE_CONSTANTS.HOURS} ` :
      ` ${hours}  ${DATE_CONSTANTS.HOUR} ` : ''
    ret += months === 0 && days === 0 && hours <= 24 && minutes !== 0 ? minutes > 1 ? ` ${minutes}   ${DATE_CONSTANTS.MINUTES} ` :
      ` ${minutes}   ${DATE_CONSTANTS.MINUTE} ` : ''
    ret += months === 0 && days === 0 && hours === 0 && minutes === 0 && seconds !== 0 ? seconds > 0 ?
      ` ${seconds}  ${DATE_CONSTANTS.SECONDS} ` : ` ${seconds}  ${DATE_CONSTANTS.SECOND} ` : ''
    return { ret, months, days }
  }
  /***Gets remaining days string */
  getRemainingDates(months: number, days: number) {
    let remainingDays = months >= 1 ? days % 30 : ''
    remainingDays = remainingDays === 0 ? '' : remainingDays === 1 ? `${remainingDays} day ` : `${remainingDays}  days `
    return remainingDays
  }
  /***Get dates difference */
  getDatesDifference(countDownDate, now): number {
    return countDownDate - now
  }
    /*** Converts date to locale string */
  convertDateStringToLocaleString(date: string): string {
    return new Date(date).toLocaleString()
  }
  /*** Return difference duration from date object */
  getDifferenceDuration(obj1: Date, obj2: Date) {
    const date1 = obj1
    const date2 = obj2
    const diff = new Date(date2.getTime() - date1.getTime())
    const years = diff.getUTCFullYear() - 1970 // Gives difference as year
    const months = (diff.getUTCMonth()) // Gives month count of difference
    const days = diff.getUTCDate() - 1 // Gives day count of difference
    const hour = diff.getUTCHours()
    const minutes = diff.getUTCMinutes()
    const second = diff.getUTCSeconds()
    let week = 0
    let count = ''
    if (years > 0) {
      count = `${STRING_CONSTANTS.NEXT_RUN_IN} ${years} ${DATE_CONSTANTS.YEAR}(s)`
    } else if (months > 0) {
      count = `${STRING_CONSTANTS.NEXT_RUN_IN} ${months} ${DATE_CONSTANTS.MONTH}(s)`
    } else if (days > 0) {
      if (days > 6) {
        week = Math.floor(days / 7)
        count = `${STRING_CONSTANTS.NEXT_RUN_IN} ${week} ${DATE_CONSTANTS.WEEK}(s)`
      } else {
        count = `${STRING_CONSTANTS.NEXT_RUN_IN} ${days} ${DATE_CONSTANTS.DAY}(s)`
      }
    } else if (hour > 0) {
      count = `${STRING_CONSTANTS.NEXT_RUN_IN} ${hour} ${DATE_CONSTANTS.HOUR}(s)`
    } else if (minutes >= 0) {
      count = `${STRING_CONSTANTS.NEXT_RUN_IN} ${minutes} ${DATE_CONSTANTS.MINUTE}(s)`
    }
    return count
  }
  /*** Return formatted date */
  convertDateToFormat(date: Date, format: string): Date {
    return moment(date).format(format)
  }

  /**
   * This function takes a date and tell difference from current date in no of days
   * Params (date)
   * Return integer
   */
  tellDiffInNoOfDays(date: Date): number {
    const date1 = new Date()
    const date2 = new Date(date)
    const timeDiff = Math.abs(date2.getTime() - date1.getTime())
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
    return diffDays
  }
}
