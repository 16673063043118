import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router,  RouterStateSnapshot } from '@angular/router'

import { SharedDataService } from '../services/shared-data.service'

@Injectable({
    providedIn: 'root',
})

export class SubscriptionGuard implements CanActivate {

    constructor(private readonly sharedDataService: SharedDataService, private readonly router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const planName = this.sharedDataService.getUser().planName

        if (planName === 'Tier 1' && (state.url.indexOf('/app/feedback') !== -1)) {
            this.router.navigateByUrl('app/error').catch()
            return false
        }
        return true
    }
}
