import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'

import { stringConstants } from '../../../constants/constants'
import { GirdActionService } from '../../services/grid-actions.service'

@Component({
  selector: 'app-actions-cell-renderer',
  templateUrl: './actions-cell-renderer.component.html',
  styleUrls: ['./actions-cell-renderer.component.scss'],
})
export class ActionsCellRendererComponent implements AgRendererComponent, OnDestroy {
  params // used for optional params that are added dynamically for different types
  constants
  docLoader: boolean
  pdfLoader: boolean
  pdfIndex: number
  docIndex: number
  lock: boolean
  actionsDisabled: boolean
  subscriptions: Subscription[] = []
  navigateToEditConstant: string
  navigateToViewConstant: string
  exportPdfConstant: string
  exportDocConstant: string
  copyCompaniesConstant: string
  docLoaderSubscription: Subscription
  pdfLoaderSubscription: Subscription
  pdfIndexSubscription: Subscription
  docIndexSubscription: Subscription
  permission: string[]
  newTicket: boolean
  constructor(public gridActionService: GirdActionService) {
    this.newTicket = false
    this.navigateToEditConstant = stringConstants.DEFAULT_DATAGRID_NAVIGATE_TO_EDIT
    this.navigateToViewConstant = stringConstants.DEFAULT_DATAGRID_NAVIGATE_TO_VIEW
    this.exportDocConstant = stringConstants.DEFAULT_DATAGRID_EXPORT_DOC
    this.exportPdfConstant = stringConstants.DEFAULT_DATAGRID_EXPORT_PDF
    this.copyCompaniesConstant = stringConstants.DEFAULT_DATAGRID_COPY_COMPANIES
    this.subscriptions.push(this.gridActionService.actionsDisabled$.subscribe(v => (this.actionsDisabled = v)))
  }

  // tslint:disable-next-line: cyclomatic-complexity
  agInit(params: ICellRendererParams): void {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.mainIntegration === 'connectwise') {
      this.newTicket = true
    }
    this.params = params

    if (this.params.data && this.params.data.exportStatus === 'Available') {
      this.params.download_report = true
    }
    if (this.params.data && this.params.data.exportStatus === 'In Queue') {
      this.params.delete = true

    }

    this.params.data ? (this.params.integrationIcons = this.params.data.integrationIcons) : (this.params.integrationIcons = [])
    if (this.params.lock !== false && this.params.unlock !== false) {
      if (this.params.data && this.params.data.userPermission && this.params.data.userPermission.length > 0) {
        this.params.lock = true
        this.params.unlock = false
      } else {
        this.params.lock = false
        this.params.unlock = true
      }
    }
    if (this.params.data) {

      if (this.params.data.permission && this.params.data.permission.length > 0) {
        this.params.contactDashBoard = false
        this.permission = this.params.data.permission
        this.params.edit = this.permission.indexOf('put') > -1 ? this.params.edit : false
        this.params.delete = this.permission.indexOf('delete') > -1 ? this.params.delete : false
        this.params.copy_companies = this.permission.indexOf('post') > -1 ? this.params.copy_companies : false
        this.params.copyCompanies = this.permission.indexOf('post') > -1 ? this.params.copyCompanies : false
        this.params.expiryDate = this.permission.indexOf('put') > -1 ? this.params.expiryDate : false
        this.params.tagsUpdate = this.permission.indexOf('put') > -1 ? this.params.tagsUpdate : false
        this.params.update = this.permission.indexOf('put') > -1 ? this.params.update : false
      }
      if (this.params.data.permission && this.params.data.permission.length === 0) {
        this.params.edit = false
        this.params.delete = false
        this.params.copy_companies = false
        this.params.copyCompanies = false
        this.params.expiryDate = false
        this.params.tagsUpdate = false
        this.params.update = false
      }
      if (this.params.data.userEmail && this.params.data.userEmail.isConfirmed) {
        this.params.sendInvite = false
      }
    }
    this.constants = stringConstants
    this.subscriptions.push(this.gridActionService.docLoader.subscribe(l => (this.docLoader = l)))
    this.subscriptions.push(
      this.gridActionService.pdfLoader.subscribe(l => {
        this.pdfLoader = l
      })
    )
    this.subscriptions.push(
      this.gridActionService.pdfIndex.subscribe(i => {
        this.pdfIndex = i
      })
    )
    this.subscriptions.push(this.gridActionService.docIndex.subscribe(i => (this.docIndex = i)))
  }
  refresh(params: ICellRendererParams): boolean {
    return false
  }

  actionClicked(actionType) {
    const { data, rowIndex } = this.params
    actionType === this.constants.DEFAULT_DATAGRID_UPDATE_ACTION ? (this.docLoader = true) : (this.docLoader = false)

    this.gridActionService.setSelectedAction(actionType, data, rowIndex) // set the action type that was clicked
  }

  redCrossCheck() {
    if (this.params.data && this.params.data.status && this.params.redCross) {
      const isRedCross = (this.params.data.status !== stringConstants.COMPLETED
        && this.params.data.status !== stringConstants.PARTIAL_COMPLETED)
      this.params.download_report = !isRedCross
      return isRedCross
    }
    return false
  }

  imagePdfCheck() {
    if (this.pdfIndex === this.params.rowIndex && this.pdfLoader) {
      return false
    }
    return true
  }
  loaderCheck() {
    if (this.pdfIndex === this.params.rowIndex && this.docLoader) {
      return false
    }
    return true
  }

  imageDocCheck() {
    if (this.docIndex === this.params.rowIndex && this.docLoader) {
      return false
    }
    return true
  }

  loaderPdfCheck() {
    if (this.pdfIndex === this.params.rowIndex && this.pdfLoader) {
      return true
    }
    return false
  }

  loaderDocCheck() {
    if (this.docIndex === this.params.rowIndex && this.docLoader) {
      return true
    }
    return false
  }

  isCWSynced() {
    if (this.params.data && this.params.data.syncFlag) {
      return true
    }
    return false
  }
  isATSynced() {
    if (this.params.data && this.params.data.syncFlag) {
      return true
    }
    return false
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
  }
}
