import { ICellRendererAngularComp } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement-cell-renderer.component.html',
})
export class AgreementComponent implements ICellRendererAngularComp {
  params: ICellRendererParams // used for optional params that are added dynamically for different types
  refresh(params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.')
  }
  agInit(params: ICellRendererParams): void {
    if (params.data) {
      this.params = params
    }
  }
}
