export const PERMISSIONS_STRING = {
  ALL: 'ALL',
  POST: 'CREATE',
  GET: 'READ',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  ALL_PERMISSIONS: 'All users and groups can access this document ',
  SPECIFIC_PERMISSIONS: 'Specific users and/or groups can access this document ',
  SPECIFIC: 'Specific',
  GROUP: 'Group',
  TYPE: 'type',
  EXTERNAL: 'External',
  INTERNAL: 'Internal',
  RADIO: 'radio',
  DELETE_HEADER: 'remove rule',
  DELETE_BODY: 'Do you want to remove this Rule?',
}

export const CHECKBOX_OPTIONS = [
  { value: PERMISSIONS_STRING.ALL, isDisabled: false, isChecked: false, label: PERMISSIONS_STRING.ALL_PERMISSIONS },
  { value: PERMISSIONS_STRING.SPECIFIC, isDisabled: false, isChecked: false, label: PERMISSIONS_STRING.SPECIFIC_PERMISSIONS },
]

export const TABLE_COLUMN = ['name', 'permissions', 'actions']

export const ADMIN_PERMISSIONS = [
  PERMISSIONS_STRING.ALL,
  PERMISSIONS_STRING.GET,
  PERMISSIONS_STRING.POST,
  PERMISSIONS_STRING.EDIT,
  PERMISSIONS_STRING.DELETE,
]

export const CHECKBOX_ARRAY = [
  { label: PERMISSIONS_STRING.ALL, isSelected: false, isDisabled: false },
  { label: PERMISSIONS_STRING.GET, isSelected: false, isDisabled: false },
  { label: PERMISSIONS_STRING.POST, isSelected: false, isDisabled: false },
  { label: PERMISSIONS_STRING.EDIT, isSelected: false, isDisabled: false },
  { label: PERMISSIONS_STRING.DELETE, isSelected: false, isDisabled: false },
]

export const TABLE_ACTIONS = [
  { tooltipText: PERMISSIONS_STRING.EDIT, className: 'btn-icon edit-s', name: PERMISSIONS_STRING.EDIT },
  { tooltipText: PERMISSIONS_STRING.DELETE, className: 'btn-icon delete-r', name: PERMISSIONS_STRING.DELETE },
]
export const INITIAL_GROUP_SELECT = [
  {
    label: 'Internal',
    options: [],
  },
  {
    label: 'External',
    options: [],
  },
  {
    label: 'Group',
    options: [],
  },
]

export const INITIAL_NO_EXTERNAL_GROUP_SELECT = [
  {
    label: 'Internal',
    options: [],
  },
  {
    label: 'Group',
    options: [],
  },
]
