import { IServerSideDatasource, IServerSideGetRowsParams } from '@ag-grid-community/all-modules'
import { AgGridAngular } from '@ag-grid-community/angular'
import { ColumnState } from '@ag-grid-community/core/dist/cjs/columnController/columnController'
import { AllModules, Module } from '@ag-grid-enterprise/all-modules'
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { SharedService } from '@app/services/shared.service'
import { Subscription } from 'rxjs'

import { stringConstants } from '../constants/constants'

import {
  CustomLoadingRendererComponent,
 } from './cell-renderers/custom-loading-renderer/custom-loading.component'
import {
  CustomNoRowsRendererComponent,
} from './cell-renderers/custom-no-rows-overlay/custom-no-rows-overlay.component'
import {
  DataGridTooltipComponent,
 } from './cell-renderers/tooltip-cell-renderer/tooltip-cell-renderer.component'
import {
  DropdownFloatingFilterComponent,
 } from './data-grid-custom-filter/data-grid-custom-filter.component'
import { ICustomNoRowsOverlayParams } from './interfaces/no-rows-overlay.interface'
import { ISidebar } from './interfaces/sidebar'
import { CellRendererFrameworksMapper } from './mappers/grid-cell-renderer-mapper'
import { CustomHeaderFrameworksMapper } from './mappers/grid-custom-header-mapper'
import { GirdActionService } from './services/grid-actions.service'
@Component({
  selector: 'itb-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
})
export class DataGridComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular
  @Output() dataGridRowsPropsEmitter = new EventEmitter<{}>()
  @Output() selectedRowsEmitter = new EventEmitter<ColumnState[]>()
  @Output() cellValueChangedEmitter = new EventEmitter<{}>()
  @Output() columnStateChangeEmitter = new EventEmitter<ColumnState[]>()
  @Output() gridReadyEmitter = new EventEmitter<boolean>()
  @Output() noRowsOverlayClickEmitter
  @Input() defaultColDef
  @Input() columnDefs
  @Input() rowSelection
  @Input() rowData
  @Input() sectionName: string
  @Input() gridOptions
  @Input() frameworkComponents
  @Input() rowModelType
  @Input() sideBar: ISidebar | []
  @Input() rowHeight
  @Input() maxBlocksInCache
  @Input() cacheBlockSize
  @Input() initialUserColState
  @Input() totalRows
  @Input() noRowsOverlayParams: ICustomNoRowsOverlayParams
  @Input() pinndedRowData = []
  pinnedRow

  initialRender: boolean
  currentRows: number
  totalFetchedRows: number
  modules: Module[] = AllModules
  context: object
  loadingCellRenderer
  noRowsOverlay
  columnTypes
  mainMenuContainer: HTMLElement
  subscriptions: Subscription
  dataSource: IServerSideDatasource = {
    getRows: (params: IServerSideGetRowsParams) => {
      this.dataGridRowsPropsEmitter.emit(params)
    },
  }
  columnsKeys: any = [] // tslint:disable-line
  containerWidth: number
  constructor(public gridActionService: GirdActionService, readonly sharedService: SharedService) {
    this.loadingCellRenderer = 'customLoadingRenderer'
    this.noRowsOverlay = 'noRowsOverlay'
    this.initialRender = true
    this.noRowsOverlayClickEmitter = new EventEmitter<boolean>()
    // this.gridActionService.resetExport()
    this.gridActionService.resetPurgeCache()
    this.gridActionService.resetRefreshseColumnState()
    this.gridActionService.resetHideNoRowsOverlay()
    this.gridActionService.resetNoRowsOverlay()
    this.gridActionService.resetRowSelection()
    this.gridActionService.resetDeselectRowSelection()
    this.gridActionService.resetunCheck()
    this.subscriptions = new Subscription()
    this.subscriptions.add(this.gridActionService.exportCSV$.subscribe(value => this.exportCSV(value)))
    this.subscriptions.add(this.gridActionService.purgeCache$.subscribe(value => this.purgeCache(value)))
    this.subscriptions.add(this.gridActionService.rowsToUpdate.subscribe(ids => this.updateRows(ids)))
    this.subscriptions.add(this.gridActionService.resetColumnState$.subscribe(value => this.refreshseColumnState(value)))
    this.subscriptions.add(this.gridActionService.showNoRowsOverlay$.subscribe(value => this.showNoRowsOverlay(value)))
    this.subscriptions.add(this.gridActionService.hideNoRowsOverlay$.subscribe(value => this.hideNoRowsOverlay(value)))
    this.subscriptions.add(this.gridActionService.noRowsOverlayClicked$.subscribe(value => this.noRowsOverlayClicked(value)))
    this.subscriptions.add(this.gridActionService.rowsSelection$.subscribe(value => this.rowsSelection()))
    this.subscriptions.add(this.gridActionService.deselectRows$.subscribe(value => this.deselectRows()))
    // this.gridActionService.destroy$.subscribe(value => this.destroy(value))
    this.rowSelection = 'multiple'

    this.context = { componentParent: this }
    this.columnTypes = {
      name: {},
      smiley: {},
      date: {},
      tags: {},
      actions: {},
      icon: {},
      image: {},
      'clickable-tags': {},
      progressbar: {},
      'status-progress': {},
      os: {},
      agreement: {},
      json: {},
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.gridActionService.rowModelType = this.rowModelType
    if (changes.rowHeight) {
      this.rowHeight = changes.rowHeight.currentValue
    }
    if (changes.sideBar) this.setSidebarProps()
    if (changes.columnDefs) {
      this.setDefaultColDefs()

      this.columnComponentFrameworksMapper() // mapping of framework components to the column definitions
      this.setFrameworkComponents()
    } else if (changes.initialUserColState && changes.initialUserColState.currentValue.length === 0) {
      this.refreshseColumnState(true)
    }
  }

  applyDefaultColumnProperties() {
    this.columnDefs.forEach(col => {
      // tslint:disable-next-line: prefer-conditional-expression
      if (col.field === 'actions') {
        col.resizable = false
        col.pinned = 'right'
        col.lockPinned = true
        col.width = 100
      } else {
        col.resizable = true
      }
    })
  }

  ngOnInit() {
    this.pinnedRow = this.pinndedRowData
    this.setSidebarProps()
  }
  ngAfterViewChecked() {}

  setOffset(offset: number) {
    return (offset = 0 ? 1 : offset / 100 + 1)
  }

  noRowsOverlayClicked = value => {
    if (value) this.noRowsOverlayClickEmitter.emit(true)
  }

  setSidebarProps() {
    if (this.sideBar) {
      this.sideBar = {
        toolPanels: [
          {
            id: stringConstants.DEFAULT_DATAGRID_SIDEBAR_ID,
            labelDefault: stringConstants.DEFAULT_DATAGRID_SIDEBAR_LABEL,
            labelKey: stringConstants.DEFAULT_DATAGRID_SIDEBAR_LABEL_KEY,
            iconKey: stringConstants.DEFAULT_DATAGRID_SIDEBAR_LABEL_ICON_KEY,
            toolPanel: stringConstants.DEFAULT_DATAGRID_SIDEBAR_TOOL_PANEL,
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivotMode: true,
            },
          },
        ],
      }
    } else {
      this.sideBar = []
    }
  }

  onGridReady(params: AgGridAngular) {
    this.mainMenuContainer = document.querySelector('.ag-root-wrapper')
    this.containerWidth = this.mainMenuContainer.offsetWidth
    if (this.rowModelType === 'serverSide') {
      params.api.setServerSideDatasource(this.dataSource)
    }
    const columnState = this.getColumnState()
    if (columnState && Array.isArray(columnState) && columnState.length > 0) {
      params.columnApi.setColumnState(columnState)
    }
    this.setColumnsTofit() // then size to fit. Trying size to fit for all giving max, min width in col defs.

    this.setSearchPlaceHolder()
    const elm  = document.getElementsByClassName('ag-icon ag-icon-grip ag-column-drag')
    for (let i = 0 ; i < elm.length ; i++) { // tslint:disable-line
      const htmlElm: any = elm[i] // tslint:disable-line
      htmlElm.style.display = 'none'
    }

  }

  virtualColumnsChanged(params) {
    this.setSearchPlaceHolder()
  }
  setDefaultColDefs() {
    this.defaultColDef = {
      enablePivot: false,
      suppressMenu: true,
      resizable: true,
      lockPinned: true,
      sortable: true,
      unSortIcon: true,
      filterParams: {
        filterOptions: [
          stringConstants.DEFAULT_DATAGRID_CONTAINS,
          stringConstants.DEFAULT_DATAGRID_NOT_CONTAINS,
          stringConstants.DEFAULT_DATAGRID_EQUALS,
        ],
        suppressAndOrCondition: true,
      },
      tooltipComponent: stringConstants.DEFAULT_DATAGRID_TOOLTIP_COMPONENT,
      ...this.defaultColDef,
    }
  }

  setFrameworkComponents() {
    this.frameworkComponents = {
      dataGridTooltip: DataGridTooltipComponent,
      dropdownFloatingFilter: DropdownFloatingFilterComponent,
      customLoadingRenderer: CustomLoadingRendererComponent,
      noRowsOverlay: CustomNoRowsRendererComponent,
      ...this.frameworkComponents,
    }
  }
  onColumnResized(event) {
   if (event && event.finished && event.source !== 'sizeColumnsToFit') {
    this.saveColumnState(event.columnApi.getColumnState())
   }
  }
  onFilterChanged() {
    this.agGrid.api.ensureIndexVisible(0)
    this.gridActionService.filterChanged$.next(true)
    if (this.rowModelType === 'clientSide') {
      const displayCount = (this.totalRows = this.agGrid.api.getDisplayedRowCount())
      displayCount === 0 ? this.agGrid.api.showNoRowsOverlay() : this.agGrid.api.hideOverlay()
      if (this.pinndedRowData !== []) {

        this.pinnedRow = (displayCount !== this.rowData.length) ? this.pinnedRow = [] : this.pinnedRow = this.pinndedRowData

      }
    }
  }
  onsortChanged() {
    if (this.rowModelType === 'clientSide') return
    this.agGrid.api.ensureIndexVisible(0)
    this.gridActionService.filterChanged$.next(true)
    const elem: any = document.getElementsByClassName('ag-row') // tslint:disable-line
      for (let i = 0 ; i < elem.length ; i++) { // tslint:disable-line
        elem[i].style.borderStyle = 'hidden'
      }
  }
  columnComponentFrameworksMapper() {
    this.columnDefs.forEach(col => {
      CellRendererFrameworksMapper(col.type, col)
      CustomHeaderFrameworksMapper(col.headerType, col)
    })
  }

  dataRendered(event) {
    this.gridReadyEmitter.emit(event)
  }

  onSelectionChanged(event: AgGridAngular) {
    const selectedRows = event.api.getSelectedRows()
    this.selectedRowsEmitter.emit(selectedRows)
    if (this.getTotalFetchedRows() > 0) {
      if (this.agGrid.api.getSelectedNodes().length !== this.getTotalFetchedRows()) {
        this.gridActionService.unCheck$.next(true)
      } else {
        this.gridActionService.unCheck$.next(false)
      }
    }

  }
  oncellValueChanged(event: object) {
    this.cellValueChangedEmitter.emit(event)
  }
  onColumnMoved(params: AgGridAngular) {
    const elm  = document.getElementsByClassName('ag-icon ag-icon-grip ag-column-drag')
    for (let i = 0 ; i < elm.length ; i++) { // tslint:disable-line
      const htmlElm: any = elm[i] // tslint:disable-line
      htmlElm.style.display = 'none'
    }
    this.saveColumnState(params.columnApi.getColumnState())
    this.setSearchPlaceHolder()
  }

  columnVisible(params: AgGridAngular) {
    this.setSearchPlaceHolder()
    const displayedColumnCount = this.agGrid.columnApi.getAllDisplayedColumns().length
    if (displayedColumnCount === this.columnDefs.length || displayedColumnCount + 1 === this.columnDefs.length)
        this.agGrid.columnApi.autoSizeAllColumns()
    if (this.rowModelType === 'clientSide') {
      if (displayedColumnCount <= 0) {
        const column: any = this.agGrid.columnApi.getAllColumns()[0] // tslint:disable-line
        if (column.userProvidedColDef.suppressColumnsToolPanel) {
          this.agGrid.columnApi.setColumnVisible(column.colId, true)
          this.gridActionService.hideNoRowsOverlay(false)
        } else
        this.gridActionService.showNoRowsOverlay(true)
      } else {
        this.gridActionService.hideNoRowsOverlay(true)
      }
      this.saveColumnState(params.columnApi.getColumnState())
      return
    }
    if (displayedColumnCount <= 0) {
      const column: any = this.agGrid.columnApi.getAllColumns()[0] // tslint:disable-line
      if (column.userProvidedColDef.suppressColumnsToolPanel) {
        this.agGrid.columnApi.setColumnVisible(column.colId, true)
        this.gridActionService.hideNoRowsOverlay(false)
      } else
      this.gridActionService.showNoRowsOverlay(true)
    } else {
      if (displayedColumnCount === 1) {
        const column: any = this.agGrid.columnApi.getAllDisplayedColumns()[0] // tslint:disable-line
        if (column.colId === 'actions') {
          this.agGrid.columnApi.setColumnVisible(column.colId, false)
          this.gridActionService.hideNoRowsOverlay(false)
        } else {
          this.agGrid.columnApi.setColumnVisible('actions', true)
        }
      } else {
        this.agGrid.columnApi.setColumnVisible('actions', true)
      }
      this.gridActionService.hideNoRowsOverlay(false)
    }
    this.saveColumnState(params.columnApi.getColumnState())
  }

  getColumnState() {
    return this.initialUserColState
  }

  saveColumnState(state: ColumnState[]) {
    if (state.filter(x => x.hide).length < this.columnDefs.length) {
      state.forEach(element => {
        if (element.colId === 'actions') {
          element.hide = false
        }
      })
    }
    this.columnStateChangeEmitter.emit(state)
    this.setColumnsTofit()
  }

  toolPanelVisibleChanged(event) {
    this.setColumnsTofit()

  }
  setColumnsTofit() {
    let totalColumnsWidth = 0
    this.agGrid.columnApi.getColumnState().forEach(col => {
      if (!col.hide)
      totalColumnsWidth += col.width
    })

    if (totalColumnsWidth <= this.containerWidth) {
      this.agGrid.api.sizeColumnsToFit()
    }
  }

  refreshseColumnState = (value: boolean) => {
    if (value) {
      this.agGrid.columnApi.resetColumnState()
      this.agGrid.columnApi.autoSizeAllColumns()
      this.gridActionService.resetColumnState$.next(false)
    }
  }
  updateRows(ids: string[]) {
    const updatedData = []
    this.agGrid.api.forEachNode(node => {
      if (!ids.includes(node.data.id)) {
        updatedData.push(node)
      }
    })
    this.agGrid.api.setRowData(updatedData)
  }
  rowsSelection() {
    this.agGrid.api.forEachNode(node => {
      if (!node.data) {
        this.gridActionService.unCheck$.next(true)
        return
      }

      if (node.data.permission && node.data.permission.length > 0) {
        if (node.data.permission.includes('delete') || node.data.permission.includes('put')) {
          node.setSelected(true)
        }
      } else
           node.setSelected(true)
    })
  }
  deselectRows() {
    this.agGrid.api.forEachNode(node => node.setSelected(false))
    if (this.agGrid.api.getSelectedNodes().length !== this.agGrid.api.getDisplayedRowCount()) {
      this.gridActionService.unCheck$.next(true)
    } else {
      this.gridActionService.unCheck$.next(false)
    }
  }
  exportCSV = value => {
    if (value) {
      let isExport = true
      if (this.sharedService.section && this.sectionName.toLowerCase() !== this.sharedService.section.toLowerCase()) {
          isExport = false
     }

      if (isExport) {
        this.agGrid.api.exportDataAsCsv({
          allColumns: false,
          columnKeys: this.columnsKeys,
          processCellCallback(params) {
            if (params.column.getColId() === 'isEnabled') {
              params.value ? (params.value = 'Yes') : (params.value = 'No')
            }
            if (params.column.getColId() === 'scheduling.frequencyInterval') {
              return `${params.value}`
            }
            return params.value
          },
        })
      }

      this.gridActionService.exportCSV$.next(false)
    }
  }
  purgeCache = (value: boolean) => {
    if (value) {
      this.gridActionService.filterChanged$.next(true)
      this.agGrid.api.ensureIndexVisible(0)
      this.agGrid.api.deselectAll()
      this.agGrid.api.purgeServerSideCache()
      this.gridActionService.purgeCache$.next(false)
    }
  }
  showNoRowsOverlay = (value: boolean) => {
    if (value) {
      this.agGrid.api.showNoRowsOverlay()
      this.gridActionService.showNoRowsOverlay$.next(false)
    }
  }
  hideNoRowsOverlay = (value: boolean) => {
    if (value) {
      this.agGrid.api.hideOverlay()
      this.gridActionService.hideNoRowsOverlay$.next(false)
    }
  }

  ngOnDestroy() {
    this.gridActionService.resetSelectedAction()
    this.subscriptions.unsubscribe()
    //  this.gridActionService.resetExport()
  }

  destroy(value) {
    if (value) {
      this.agGrid.api.destroy()
    }
  }

  getTotalFetchedRows() {
    if (this.agGrid.api && this.agGrid.api.getCacheBlockState()) {
      const index = Object.entries(this.agGrid.api.getCacheBlockState()).length - 1
      if (this.agGrid.api.getCacheBlockState()[index]) {
        return this.agGrid.api.getCacheBlockState()[index].endRow > this.totalRows
        ? this.totalRows
        : this.agGrid.api.getCacheBlockState()[index].endRow
      }

    }
    return 0
  }
  setSearchPlaceHolder() {
    Array.from(document.getElementsByClassName('ag-floating-filter-input')).forEach(obj => {
      if ((obj.attributes as any).disabled) {         // tslint:disable-line
        return
      }
      obj.setAttribute('placeholder', 'Search')
    })
  }
}
