import { Injectable } from '@angular/core'

import { ISelect } from './../interfaces/ui-components.interface'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  section: string
  constructor(public apiService: ApiService) { }
  getPriorityOptions(): ISelect[] {
    return [
      {
        id: 1,
        label: 'High',
        isSelected: false,
        isDisabled: false,
      },
      {
        id: 2,
        label: 'Medium',
        isSelected: false,
        isDisabled: false,
      },
      {
        id: 3,
        label: 'Low',
        isSelected: false,
        isDisabled: false,
      },
    ]
  }
  getTypesOptions(): ISelect[] {
    return [
      {
        id: 1,
        label: 'Internal',
        isSelected: false,
        isDisabled: false,
      },
      {
        id: 2,
        label: 'External',
        isSelected: false,
        isDisabled: false,
      },
    ]
  }
  buildFilters(filters) {
    const gridfilters = {}
    for (const key in filters) {
      if (key) {
        if (filters[key].filterType === 'number') {
          switch (filters[key].type) {
            case 'equal':
            case 'contains':
              gridfilters[key] = filters[key].filter
              break
            case 'notContains':
              gridfilters[key] = { $ne: filters[key].filter }
              break
            default:
              gridfilters[key] = filters[key].filter ? filters[key].filter : new Date(filters[key].dateFrom).toISOString()
          }
        } else {
          if (filters[key].filterType === 'date') {
            const date = new Date(filters[key].dateFrom)
            const tz = date.toISOString().split('.')

            gridfilters[key] = {
              $lte: new Date(`${filters[key].dateFrom}T23:59:59.${tz[1]}`).toISOString(),
              $gte: new Date(`${filters[key].dateFrom}T00:00:00.${tz[1]}`).toISOString(),
            }
            break
          }
          switch (filters[key].type) {
            case 'contains':
              gridfilters[key] = filters[key].filter
                ? { $regex: filters[key].filter, $options: 'i' }
                : { $regex: new Date(filters[key].dateFrom).toISOString(), $options: 'i' }
              break
            case 'notContains':
              gridfilters[key] = filters[key].filter
                ? { $regex: `^((?!${filters[key].filter}).)*$`, $options: 'gm' }
                : { $regex: `^((?!${new Date(filters[key].dateFrom).toISOString()}).)*$`, $options: 'gm' }
              break
            default:
              gridfilters[key] = filters[key].filter ? filters[key].filter : new Date(filters[key].dateFrom).toISOString()
          }
        }
      }
    }

    return gridfilters
  }

  geLoggedUsersList(machine: string) {
    return this.apiService.get(`users/screenconnect/connection/${machine}`, 'Screen Connect')
  }

  addActivity(companyId: string, payload) {
    return this.apiService.add(`users/screenconnect/activity/${companyId}/${payload.group}`, payload)
  }

  getCompanyName(companyId?: string) {
    return this.apiService.get(`companies/${companyId}?fields={"uuid":1, "identifier":1, "name":1, "id":1,"syncFlag":1}`, 'company')
  }

  fetchArticleById(id: string) {
    return this.apiService.get(`articles/${id}/public`, 'Article kb public view')
  }
}
