import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { ConnectwiseControlComponent } from '@app/connectwise-control/connectwise-control.component'
import { MODAL_WIDTH_CONSTANTS } from '@core/constants/constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { Subscription } from 'rxjs/index'

import { IFavoriteConfigurations, IFavoriteConfigurationsActionsObject } from '../../../interfaces/favorite-configuration'
import { CoreService } from '../../../services/core.service'
@Component({
  selector: 'app-favorite-configuration',
  templateUrl: './favorite-configuration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteConfigurationComponent implements OnInit, OnDestroy {
  @Input() compData
  displayedColumns2: string[]
  favoriteList: IFavoriteConfigurations[]
  isLoading: boolean
  subscriptions: Subscription
  constructor(
    private readonly coreService: CoreService,
    public dialog: MatDialog,
    public router: Router,
    public sharedDataService: SharedDataService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.subscriptions = new Subscription()
    this.displayedColumns2 = ['name', 'type', 'actions']
    this.isLoading = false
    this.favoriteList = []
  }
  /***On load : sends http request to get favorite configuration data */
  ngOnInit() {
    this.isLoading = true
    this.subscriptions.add(this.coreService.favoriteConfiguration(this.compData.uuid).subscribe(dataConfig => {
        this.isLoading  = false
        if (dataConfig) {
          const scFlag = this.sharedDataService.getUser().screenConnectIntegration
          this.favoriteList = dataConfig.slice(0, 5)
          for (const obj of Object.keys(this.favoriteList)) {
            this.favoriteList[obj].configName = this.favoriteList[obj].name
            this.favoriteList[obj].name =
            // tslint:disable-next-line: max-line-length
            `<a href="/app/company/${this.compData.uuid}/configurations/view/${this.favoriteList[obj].uuid}">${this.favoriteList[obj].name}</a>`
            this.favoriteList[obj].type = this.favoriteList[obj].type.name
            this.favoriteList[obj].actions =  [
              { tooltipText: 'Edit', className: 'btn-icon edit-s', name: 'edit' },
            ]
            if (scFlag) {
              const scObj = { tooltipText: 'CW Control', className: 'btn-icon refresh-c icon-btn-loader', name: 'cwControl' }
              this.favoriteList[obj].actions.push(scObj)
              this.checkScreenConnectMachine(this.favoriteList[obj], obj)
            }
          }
        }
        this.changeDetector.detectChanges()
    }, error => {
      this.isLoading  = false
      this.changeDetector.detectChanges()
    }))
  }
  checkScreenConnectMachine(configurationName: IFavoriteConfigurations, obj: string) {
    this.subscriptions.add(this.coreService.checkScreenStatus(configurationName.configName).subscribe(response => {
        if (response.error_code || !response.status) {
          this.favoriteList[obj].actions[1] = {
            tooltipText: 'CW Control', className: 'icon-disabled icon-screenconnect', name: 'cw',
          }
        } else {
          this.favoriteList[obj].actions[1] = {
            tooltipText: 'CW Control', className: 'btn-icon icon-screenconnect', name: 'cwControl',
          }
        }
      },
      error => {
        this.favoriteList[obj].actions[1] = {
          tooltipText: 'CW Control', className: 'icon-disabled icon-screenconnect', name: 'cw',
        }
      }))
  }
  /*** Action click event **/
  clickEvent(obj: IFavoriteConfigurationsActionsObject) {
    obj.action === 'edit' ? this.editConfiguration(obj) : this.screenConnect(obj)
  }
  /*** Routes to Configuration edit page**/
  editConfiguration(obj: IFavoriteConfigurationsActionsObject) {
    this.router.navigate([`/app/company/${this.compData.uuid}/configurations/edit/${obj.user.uuid}`]).then().catch()
  }
  /*** Opens screen connect diaog box **/
  screenConnect(obj: IFavoriteConfigurationsActionsObject) {
    if (obj.action !== 'cwControl') {
      return
    }
    this.dialog.open(ConnectwiseControlComponent,
      { data:  {
        machineName: obj.user.configName,
        recordUuid: obj.user.uuid,
        companyID: obj.user.company.companyUuid },
        width: MODAL_WIDTH_CONSTANTS.SMALL })
  }
  /*** Unsubscribe all subscription when component destroy*/
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
