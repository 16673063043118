import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BUTTONS } from '@app/constants/ui-components.constants'
import { ModalService } from '@app/services/modal.service'
import { Subscription } from 'rxjs'

import {
  ADMIN_PERMISSIONS,
  CHECKBOX_ARRAY,
  CHECKBOX_OPTIONS,
  INITIAL_GROUP_SELECT,
  INITIAL_NO_EXTERNAL_GROUP_SELECT,
  PERMISSIONS_STRING,
  TABLE_ACTIONS,
  TABLE_COLUMN,
} from '../../constants/granular-permission.constants'
import { IDataSource, IGroupSelect, IPermissions, ISelect } from '../../interfaces/granular-permissions.interface'
import { GranularPermissionService } from '../../services/granular-permission.service'

@Component({
  selector: 'app-company-granular-permission',
  templateUrl: './granular-permission.component.html',
})
export class GranularPermissionComponent implements OnInit {
  isEdit: boolean
  isSaveClicked: boolean
  currentUser: ISelect
  adminUser: ISelect
  radioForm: FormGroup
  options: ISelect[]
  displayedColumns: string[]
  dataSource: IDataSource[]
  selectOptions: IGroupSelect[]
  checkboxArray: ISelect[]
  currentPermissions: string[]
  dataSourceUuids: string[]
  subscription: Subscription
  constructor(
    public _dialogRef: MatDialogRef<GranularPermissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { permissions: IPermissions[]; createdBy?: string; noExternal?: boolean },
    private readonly _permissions: GranularPermissionService,
    private readonly _modalService: ModalService
  ) {
    this.subscription = new Subscription()
    this.options = CHECKBOX_OPTIONS
    this.options.forEach(option => (option.isChecked = false))
    !this.data.permissions.length ? (this.options[0].isSelected = true) : (this.options[1].isSelected = true)
    this.selectOptions = []
    this.data.noExternal ? (this.selectOptions = INITIAL_NO_EXTERNAL_GROUP_SELECT) : (this.selectOptions = INITIAL_GROUP_SELECT)

    this.selectOptions.forEach(el => (el.options = []))
    this.currentPermissions = []
    this.dataSource = []
    this.dataSourceUuids = []
    this.displayedColumns = TABLE_COLUMN
    this.checkboxArray = CHECKBOX_ARRAY
    for (const i of this.checkboxArray) {
      i.isSelected = false
      i.isDisabled = false
    }
    this.currentUser = {}
    this.isEdit = false
    this.isSaveClicked = false
  }
  ngOnInit() {
    if (this.data.permissions.length) {
      this.dataSource = this.data.permissions.map(element => ({
        name: element.fullName,
        permissions: element.permission.map(key => ({
          tooltipText: this.reverseMapCheckBox(key),
          className: 'chip theme-bg',
          text: this.reverseMapCheckBox(key),
        })),
        actions: TABLE_ACTIONS,
        id: element.id ? element.id.toString() : undefined,
      }))
      this.dataSourceUuids = this.dataSource.map(element => element.id && element.id.toString())
    }
    this.createCheckBoxForm()
    this.initializeSelect()
  }
  createCheckBoxForm() {
    this.radioForm = new FormGroup({
      radio: new FormControl(!this.data.permissions.length ? PERMISSIONS_STRING.ALL : PERMISSIONS_STRING.SPECIFIC),
    })
  }
  initializeSelect() {
    this.subscription.add(
      this._permissions.getAllUsers().subscribe(users => {
        if (users.length) {
          users.forEach(user => {
            if (!user.userId) return
            if (!this.dataSourceUuids.includes(user.userId.toString()) && user.firstName) {
              if (!this.data.permissions.length && this.data.createdBy && user.userName === this.data.createdBy) {
                this.adminUser = user
                this.mapAdminPermissions(this.adminUser)
              } else if (!this.data.noExternal || user.userType !== PERMISSIONS_STRING.EXTERNAL.toLocaleLowerCase()) {
                this.addOption(user, false)
              }
            } else {
              if (!this.dataSource.length) return
              this.dataSource.forEach(el => {
                if (el.id === user.userId.toString()) {
                  Object.defineProperty(el, PERMISSIONS_STRING.TYPE, {
                    value: user.userType,
                  })
                }
              })
            }
          })
        }
        this.subscription.add(
          this._permissions.getAllGroup().subscribe(groups => {
            if (groups.length) {
              groups.forEach(group => {
                if (!group.uuid) return
                if (!this.dataSourceUuids.includes(group.uuid) && group.name) {
                  group.type = PERMISSIONS_STRING.GROUP.toLocaleLowerCase()
                  this.addOption(group, false)
                } else {
                  if (!this.dataSource.length) return
                  this.dataSource.forEach(el => {
                    if (el.id === group.uuid) {
                      Object.defineProperty(el, PERMISSIONS_STRING.TYPE, {
                        value: PERMISSIONS_STRING.GROUP.toLocaleLowerCase(),
                      })
                    }
                  })
                }
              })
            }
          })
        )
      })
    )
  }
  mapAdminPermissions(admin: ISelect) {
    if (!Object.keys(admin).length) return
    this.dataSource.push({
      name: `${admin.firstName} ${admin.lastName}`,
      permissions: ADMIN_PERMISSIONS.map(key => ({ tooltipText: key, className: 'chip theme-bg', text: key })),
      actions: TABLE_ACTIONS,
      id: admin.userId,
    })
    this.dataSource = [...this.dataSource]
  }
  tableEventHandler(event: IDataSource) {
    if (event.action === PERMISSIONS_STRING.EDIT) this.tableEditEventHandler(event)
    if (event.action === PERMISSIONS_STRING.DELETE) this.tableDeleteEventHandler(event)
  }
  selectEventHandler(event) {
    for (const i of this.checkboxArray) {
      i.isSelected = false
    }
    this.currentUser = event.option
    if (this.currentUser.type === PERMISSIONS_STRING.EXTERNAL.toLocaleLowerCase()) {
      this.checkboxArray.forEach(checkBox =>
        checkBox.label !== PERMISSIONS_STRING.GET ? (checkBox.isDisabled = true) : (checkBox.isDisabled = false)
      )
    } else {
      this.checkboxArray.forEach(checkBox => (checkBox.isDisabled = false))
    }
    this.currentPermissions = []
  }
  checkBoxEventHandler(event) {
    if (event.index === 0) {
      this.currentPermissions = []
      for (const i of this.checkboxArray) {
        if (i.isDisabled) {
          continue
        }
        if (event.value.checked) {
          i.isSelected = true
          if (!this.currentPermissions.includes(i.label)) this.currentPermissions.push(i.label)
        }
        if (!event.value.checked) i.isSelected = false
      }
    } else {
      this.checkboxArray[event.index].isSelected = !this.checkboxArray[event.index].isSelected
    }
    if (event.value.checked) {
      if (!this.currentPermissions.includes(this.checkboxArray[event.index].label))
        this.currentPermissions.push(this.checkboxArray[event.index].label)
    }
    if (!event.value.checked) {
      if (this.currentPermissions.includes(this.checkboxArray[event.index].label))
        this.currentPermissions.splice(this.currentPermissions.indexOf(this.checkboxArray[event.index].label), 1)
    }
    if (this.currentPermissions.length >= 1 && !this.currentPermissions.includes(this.checkboxArray[1].label)) {
      this.currentPermissions.push(this.checkboxArray[1].label)
      this.checkboxArray[1].isSelected = true
    }
    if (this.currentPermissions.includes(this.checkboxArray[1].label) && this.currentPermissions.length === 1) {
      if (!this.checkboxArray[1].isSelected) this.currentPermissions = []
    }
  }
  saveHandler() {
    if (Object.keys(this.currentUser).length && this.currentPermissions.length) {
      this.dataSource.push({
        name: this.currentUser.name,
        permissions: this.currentPermissions.map(key => ({ tooltipText: key, className: 'chip theme-bg', text: key })),
        actions: TABLE_ACTIONS,
        id: this.currentUser.id,
        type: this.currentUser.type,
      })
      this.dataSource = [...this.dataSource]
      if (!this.isEdit) this.removeOption(this.currentUser)
      for (const i of this.checkboxArray) {
        i.isSelected = false
        i.isDisabled = false
      }
      this.checkboxArray = [...this.checkboxArray]
      this.currentUser = {}
      this.currentPermissions = []
      this.isSaveClicked = false
    } else {
      this.isSaveClicked = true
    }
    Object.keys(this.currentUser).length && !this.currentPermissions.length ? (this.isEdit = true) : (this.isEdit = false)
  }
  cancelHandler(currentUser: ISelect, currentPermissions: string[]) {
    if (Object.keys(currentUser).length && currentPermissions.length && this.isEdit) {
      this.dataSource.push({
        name: this.currentUser.name,
        permissions: this.currentPermissions.map(key => ({ tooltipText: key, className: 'chip theme-bg', text: key })),
        actions: TABLE_ACTIONS,
        id: this.currentUser.id,
        type: this.currentUser.type,
      })
      this.dataSource = [...this.dataSource]
    }
    this.selectOptions.forEach(el => {
      el.options.forEach(e => (e.isSelected = false))
    })
    this.selectOptions = [...this.selectOptions]
    for (const i of this.checkboxArray) {
      i.isSelected = false
      i.isDisabled = false
    }
    this.currentUser = {}
    this.currentPermissions = []
    this.isEdit = false
    this.isSaveClicked = false
  }
  tableEditEventHandler(event: IDataSource) {
    if (Object.keys(this.currentUser).length && this.currentPermissions.length) {
      this.dataSource.push({
        name: this.currentUser.name,
        permissions: this.currentPermissions.map(key => ({ tooltipText: key, className: 'chip theme-bg', text: key })),
        actions: TABLE_ACTIONS,
        id: this.currentUser.id,
        type: this.currentUser.type,
      })
      this.dataSource = [...this.dataSource]
    }
    this.isEdit = true
    this.currentUser = event.user
    this.currentPermissions = event.user.permissions.map(el => el.text)
    if (this.currentUser.type === PERMISSIONS_STRING.EXTERNAL.toLocaleLowerCase()) {
      this.checkboxArray.forEach(checkBox =>
        checkBox.label !== PERMISSIONS_STRING.GET ? (checkBox.isDisabled = true) : (checkBox.isDisabled = false)
      )
    }
    this.dataSource.splice(
      this.dataSource.findIndex(el => el.id === this.currentUser.id),
      1
    )
    this.dataSource = [...this.dataSource]
    this.checkboxArray.forEach(el => (this.currentPermissions.includes(el.label) ? (el.isSelected = true) : (el.isSelected = false)))
  }
  tableDeleteEventHandler(event: IDataSource) {
    this._modalService.showDeleteConfirmationModal(PERMISSIONS_STRING.DELETE_HEADER, PERMISSIONS_STRING.DELETE_BODY).subscribe(result => {
      if (result === BUTTONS.EVENTS.DELETE) {
        this.currentUser = event.user
        this.addOption(event.user, false)
        this.dataSource.splice(
          this.dataSource.findIndex(el => el.id === this.currentUser.id),
          1
        )
        this.dataSource = [...this.dataSource]
        this.selectOptions = [...this.selectOptions]
        for (const i of this.checkboxArray) {
          i.isSelected = false
        }
        this.currentUser = {}
        this.currentPermissions = []
      }
      this.isEdit = false
      return false
    })
  }
  get getRadioValue() {
    return this.radioForm.get(PERMISSIONS_STRING.RADIO) ? this.radioForm.get(PERMISSIONS_STRING.RADIO).value : undefined
  }
  savePermissionsHandler() {
    this.mapPermissions()
    if (this.getRadioValue === PERMISSIONS_STRING.SPECIFIC) {
      this._dialogRef.close(this.data)
    } else {
      this.data.permissions = []
      this._dialogRef.close(this.data)
    }
  }
  cancelPermissionsHandler() {
    this._dialogRef.close(this.data)
  }
  mapPermissions() {
    this.data.permissions = []
    this.data.permissions = this.dataSource.map(element => ({
      permission: element.permissions.map(el => (el ? this.mapCheckBox(el.text) : undefined)),
      excludedRoles: [],
      id: element.id,
      fullName: element.name,
    }))
    if (Object.keys(this.currentUser) && this.currentPermissions.length) {
      this.data.permissions.push({
        permission: this.currentPermissions.map(el => this.mapCheckBox(el)),
        excludedRoles: [],
        id: this.currentUser.id,
        fullName: this.currentUser.name,
      })
    }
  }
  removeOption(user: ISelect) {
    this.selectOptions.forEach(el => {
      if (el.label.toLocaleLowerCase() === user.type) {
        const index = el.options.findIndex(e => e.id === user.id)
        el.options.splice(index, 1)
      }
    })
    this.selectOptions = [...this.selectOptions]
  }
  addOption(user: ISelect, isSelect: boolean) {
    this.selectOptions.forEach(el => {
      if (el.label.toLocaleLowerCase() === (user.type || user.userType)) {
        el.options.push({
          id: user.userId ? user.userId.toString() || user.id.toString() : user.uuid || user.id.toString(),
          uuid: user.userId ? user.userId.toString() || user.id.toString() : user.uuid || user.id.toString(),
          name: user.name || `${user.firstName} ${user.lastName}`,
          value: user.name || `${user.firstName} ${user.lastName}`,
          isSelected: isSelect,
          isDisabled: false,
          type: user.type || user.userType,
        })
      }
    })
    this.selectOptions = [...this.selectOptions]
  }
  mapCheckBox(permission: string) {
    switch (permission) {
      case PERMISSIONS_STRING.ALL:
        return 'All'
      case PERMISSIONS_STRING.GET:
        return 'get'
      case PERMISSIONS_STRING.POST:
        return 'post'
      case PERMISSIONS_STRING.EDIT:
        return 'put'
      case PERMISSIONS_STRING.DELETE:
        return 'delete'
      default:
    }
  }
  reverseMapCheckBox(permission: string) {
    switch (permission) {
      case 'All':
        return PERMISSIONS_STRING.ALL
      case 'get':
        return PERMISSIONS_STRING.GET
      case 'put':
        return PERMISSIONS_STRING.EDIT
      case 'post':
        return PERMISSIONS_STRING.POST
      case 'delete':
        return PERMISSIONS_STRING.DELETE
      default:
    }
  }
  onDestroy() {
    this.subscription.unsubscribe()
  }
}
