<div class="action-cell-btns" >
<img
    class="{{class}}"
    height="20"
    width="20"
    src="{{image}}"
    *ngIf="params.data.syncFlag && params.value === 'Completed' && params.data.id !== null && params.data.id"
/>
<img
    class="{{failedClass}}"
    height="20"
    width="20"
    src="{{image}}"
    *ngIf="params.data.syncFlag && params.value === 'Failed'"
/>
<img
    class="{{disabledClass}}"
    height="20"
    width="20"
    src="{{image}}"
    *ngIf="params.data.syncFlag && !params.data.id && !!params.value && params.colDef.cellEditorParams === 'connectwise'"
/>
<img
    class="{{disabledClass}}"
    height="20"
    width="20"
    src="{{image}}"
    *ngIf="params.data.syncFlag && !params.data.id && !!params.value && params.colDef.cellEditorParams === 'autotask'"
/>
<p *ngIf="!params.value || !params.data.syncFlag">
N/A
</p>
</div>
