import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { ErrorHandler, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDialogModule, MatProgressSpinnerModule, MatSnackBarModule } from '@angular/material'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from '@app/app-routing.module'
import { AppComponent } from '@app/app.component'
import { CoreModule } from '@app/modules/core/core.module'
import { LoginModule } from '@app/modules/login/login.module'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { ToastrModule } from 'ngx-toastr'

import { BootstrapComponent } from './bootstrap.component'
import { SearchTagsModalComponent } from './components/search-tags-modal/search-tags-modal.component'
import { ConnectwiseControlComponent } from './connectwise-control/connectwise-control.component'
import { CustomBreakpointsModule } from './custom-breakpoints'
import { AuthGuardService } from './guards/auth-guard'
import { CanAddCompanyGuard } from './guards/can-add-company-guard'
import { CanDeactivateGuard } from './guards/can-deactivate-guard'
import { LoginGuard } from './guards/login.guard'
import { ErrorInterceptor } from './intercepters/error.interceptor'
import { GlobalErrorHandler } from './intercepters/global-handler.interceptor'
import { NavBarModule } from './modules/core/components/navbar/navbar.module'
import { PublicViewArticleComponent } from './modules/core/components/public-view-article/public-view-article.component'
// tslint:disable-next-line: max-line-length
import { GridSettingConfirmationDialogComponent } from './modules/shared/component/grid-setting-confirmation-dialog/grid-setting-confirmation-dialog.component'
import { RootStoreModule } from './root-store/root-store.module'
import { ModalService } from './services/modal.service'
import { SharedService } from './services/shared.service'
import { UiComponentsModule } from './ui-components.module'
@NgModule({
  declarations: [
    AppComponent,
    SearchTagsModalComponent,
    GridSettingConfirmationDialogComponent,
    PublicViewArticleComponent,
    ConnectwiseControlComponent,
    BootstrapComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    LoginModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RootStoreModule,
    CustomBreakpointsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 30,
    }),
    HttpClientModule,
    UiComponentsModule,
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule,
    CoreModule,
    NavBarModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    ModalService,
    CanDeactivateGuard,
    CanAddCompanyGuard,
    LoginGuard,
    SharedService,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [BootstrapComponent],
  entryComponents: [SearchTagsModalComponent, GridSettingConfirmationDialogComponent, ConnectwiseControlComponent],
})
export class AppModule {}
