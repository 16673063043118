import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { getRouterState } from '@app/root-store/reducers/router-store'
import { ModalService } from '@app/services/modal.service'
import { Store } from '@ngrx/store'

import { ContactService } from '../../services/contact.service'
import * as fromStore from '../../store'
@Component({
  selector: 'app-company-contact-dashboard-link',
  templateUrl: './contact-dashboard-link.component.html',
  styleUrls: ['./contact-dashboard-link.component.scss'],
})
export class ContactDashboardLinkComponent implements OnInit {
  constructor(
    private readonly contactService: ContactService,
    private readonly router: Router,
    private readonly store: Store<fromStore.ContactState>,
    private readonly modalService: ModalService
  ) {}
  ngOnInit() {
    this.store
      .select(getRouterState)
      .subscribe(res => {
        if (res.state.queryParams.number) {
          let phoneNumber = res.state.queryParams.number
          const temp = phoneNumber.substring(0, 1)
          if (temp === ' ') {
            phoneNumber = phoneNumber.replace(/\s/g, '')
            phoneNumber = `+${phoneNumber}`
          }

          this.contactService.getContactByNumber(phoneNumber).subscribe(resp => {
            if (Object.keys(resp).length > 0) {
              this.contactService.updateDashboardData(resp)
              this.router.navigateByUrl(`app/company/${resp.company.uuid}/contacts/contact-dashboard?number=${phoneNumber}`).catch()
            } else {
              this.modalService.showAlertNotification('fail', 'Contact not found', '', 'Something went wrong.')
            }
          })
        }
      })
      .unsubscribe()
  }
}
