import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { SharedService } from '@app/modules/shared/shared.service'
import { ModalService } from '@app/services/modal.service'
import { omit } from 'lodash'
import { ToastrService } from 'ngx-toastr'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  requestTypes = ['PUT', 'POST', 'DELETE', 'PATCH']
  _modalService: ModalService
  _toastr: ToastrService
  _sharedService: SharedService
  constructor(private readonly _injector: Injector) {}
    /*** Intercepts a http request and adds module name to it (to handle and show errors) */
  // tslint:disable-next-line: no-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let canShowToastr = false
    let moduleName = ''
    if (!navigator.onLine) return
    if (request.method === 'GET') {
      canShowToastr =
        request.body && Object.keys(request.body).length && request.body.showToastr ? JSON.parse(request.body.showToastr) : false
      moduleName = request.body && Object.keys(request.body).length && request.body.moduleName !== '' ? request.body.moduleName : ''
      request = request.clone({ body: {} })
    }
    if (this.requestTypes.includes(request.method) && request.body && Object.keys(request.body).length) {
      canShowToastr = request.body.showToastr ? JSON.parse(request.body.showToastr) : false
      request = request.clone({ body: omit(request.body, ['showToastr']) })
    }

    return next.handle(request).pipe(
      // tslint:disable-next-line: cyclomatic-complexity
      map((event: HttpEvent<any>) => { // tslint:disable-line: no-any
        this._sharedService = this._injector.get(SharedService)
        if (event instanceof HttpResponse && this.isIncludeError(event) && parseInt(event.body.error_code) === 111000) {
          this._sharedService.logout('sessionExpiry')
          return
        }
        if (event instanceof HttpResponse && this.isIncludeError(event) && this.requestTypes.includes(request.method) && !canShowToastr) {
          this._modalService = this._injector.get(ModalService)
          let errorMessage = 'something went wrong'
          if (this._sharedService.getErrorCodes()[event.body.error_code]) {
            errorMessage = this._sharedService.getErrorCodes()[event.body.error_code].message
          }
          this._modalService.showAlertNotification('fail', 'error', errorMessage, `error_code : ${event.body.error_code}`)
          throw event
        }
        if (event instanceof HttpResponse && this.isIncludeError(event) && this.requestTypes.includes(request.method) && canShowToastr) {
          throw event
        }
        if (event instanceof HttpResponse && this.isIncludeError(event) && request.method === 'GET' && !canShowToastr) {
          this._toastr = this._injector.get(ToastrService)
          let errorMessage = `Error occurred while fetching ${moduleName} data`
          if (this._sharedService.getErrorCodes()[event.body.error_code]) {
            errorMessage = this._sharedService.getErrorCodes()[event.body.error_code].message
          }
          this._toastr.error(errorMessage, `error_code : ${event.body.error_code}`, {
            timeOut: 3000,
            easeTime: 300,
            toastClass: 'ngx-toastr itbErrorToast',
          })
          throw event
        }
        if (event instanceof HttpResponse && this.isIncludeError(event) && request.method === 'GET' && canShowToastr) {
          throw event
        }
        return event
      }),
        /*** If an error occurs, shows a response based on the error code */
      // tslint:disable-next-line: no-any
      catchError((error: HttpEvent<any>) => {
        if (error instanceof HttpResponse && error.status === 200) {
          return throwError(error.body)
        }
        if (error instanceof HttpErrorResponse && error.status === 500) {
          this._modalService = this._injector.get(ModalService)
          this._modalService.showAlertNotification('fail', 'error', 'Internal server error', '')
          return
        }
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this._sharedService = this._injector.get(SharedService)
          this._sharedService.logout('sessionExpiry')
          return
        }
        return throwError(error)
      })
    )
  }
  isIncludeError(event) {
    if (typeof event.body === 'object' && event.body && Object.keys(event.body).length) {
      const keys = Object.keys(event.body)
      return keys.includes('error_code')
    }
    return false
  }
}
