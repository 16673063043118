import { AgRendererComponent } from '@ag-grid-community/angular'
import { Component } from '@angular/core'

@Component({
  selector: 'itb-tooltip-cell-renderer',
  templateUrl: './tooltip-cell-renderer.component.html',
  styleUrls: ['./tooltip-cell-renderer.component.scss'],
})
export class DataGridTooltipComponent implements AgRendererComponent {

  params // used for optional params that are added dynamically for different types

  agInit(params): void { // used for optional params that are added dynamically for different types
    this.params = params
  }

  refresh(params): boolean { // used for optional params that are added dynamically for different types
    return false
  }
}
