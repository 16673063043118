import { ColumnState } from '@ag-grid-community/core/dist/cjs/columnController/columnController'
import { Injectable } from '@angular/core'
import { ICompanyDataInterface, ICompanyTypesInterface, ICwAuth, IResponse } from '@app/interfaces/company-data.interface'
import { UtilService } from '@app/modules/core/services/utils.service'
import { ApiService } from '@app/services/api.service'
import { Observable } from 'rxjs'

import { SharedDataService } from '../../../../../src/app/modules/core/services/shared-data.service'
import { COMPANIES_ROUTES, COMPANY_TYPE, RESTORE_COMPANY_TYPE, STRING_CONSTANTS, URL } from '../constants/constants'
import { IBulkDelete } from '../interfaces/company-bulk-delete.interface'
import { IRequestParams } from '../interfaces/reqest-params'
import { IRowCount } from '../interfaces/rows-count'
import { ISnapShotCounts, ISnapShotData } from '../interfaces/snapshot-data.interface'

@Injectable()
export class CompaniesService {
  isTableRefreshDisabled: boolean
  lastRefreshDate: string
  constructor(public baseService: ApiService, private readonly utils: UtilService, public sharedDataService: SharedDataService) {
  }

  /*** get companies from /companies */
  fetchTableData(params: IRequestParams, pageSize: number): Observable<ICompanyDataInterface[]> {
    const filterSQPR = this.getFilterSQPR(params.filterModel)
    const sortSQPR = this.utils.getSortSQPR(params.sortModel)
    return this.baseService.get(`${URL.FETCH_COMPANIES_TABLE_DATA}?pageNo=${params.offset}&pageSize=${pageSize}${Object.entries(filterSQPR)
      .length ? `&conditions=${JSON.stringify(this.createFilter(filterSQPR))}` : ''}${sortSQPR ?
      `&sortBy=${sortSQPR.key}&order=${sortSQPR.order}` :
      ''}`, STRING_CONSTANTS.COMPANY_MODULE, true)
  }
  createFilter(model) {
    const sqpr = {}
    for (const obj in model) {
      if (obj) {
        let obj2 = obj
        if (obj === 'types') {
          obj2 = 'types.name'
        }
        if (obj && model[obj].filterType === 'date') {
          sqpr[obj2] = model[obj].dateFrom
        } else if (model[obj].type === 'contains') {
          sqpr[obj2] = {$regex: model[obj].filter, $options: 'i'}
        } else if (model[obj].type === 'equals') {
          sqpr[obj2] = model[obj].filter
        } else if (model[obj].type === 'notContains') {
          sqpr[obj2] = {$regex: `^((?!${model[obj].filter}).)*$`}
        }
      }
    }
    return sqpr
  }

  getCompaniesCount(filterModel): Observable<IRowCount> {
    const filterSQPR = this.getFilterSQPR(filterModel)
    return this.baseService
      .get(`${URL.GLOBAL_COMPANIES_COUNT}${Object.entries(filterSQPR).length ?
        `?conditions=${JSON.stringify(this.createFilter(filterSQPR))}` : ''}`, STRING_CONSTANTS.COMPANY_MODULE, true)
  }
  getDeletedCompaniesCount(filterModel): Observable<IRowCount> {
    const filterSQPR = this.getFilterSQPR(filterModel)
    return this.baseService
      .get(`${URL.RESTORE_COMPANIES_COUNT}${Object.entries(filterSQPR).length ?
        `?conditions=${JSON.stringify(this.createFilter(filterSQPR))}` : ''}`, STRING_CONSTANTS.COMPANY_MODULE, true)
  }

  /*** get companies to restore from companies/deletedCompanies */
  fetchRestoreCompanyTableData(params: IRequestParams, pageSize: number): Observable<ICompanyDataInterface[]> {
    const filterSQPR = this.getFilterSQPR(params.filterModel)
    const sortSQPR = this.utils.getSortSQPR(params.sortModel)
    return this.baseService.get(
      `${URL.FETCH_RESTORE_COMPANIES_TABLE_DATA}?pageNo=${params.offset}&pageSize=${pageSize}${Object.entries(filterSQPR)
      .length ? `&conditions=${JSON.stringify(this.createFilter(filterSQPR))}` : ''}${sortSQPR ?
        `&sortBy=${JSON.stringify(sortSQPR)}` : ''}`, STRING_CONSTANTS.COMPANY_MODULE, true)
  }

  /*** get doc snap shot table headers  from snapshot/headers */
  fetchTableHeadersSnapshot(): Observable<ISnapShotData> {
    return this.baseService.get(`${URL.FETCH_SNAPSHOT_TABLE_DATA}`, STRING_CONSTANTS.COMPANY_MODULE, true)
  }

  /*** company delete call /companies */
  deleteCompany(companyId: string): Observable<IResponse> {
    return this.baseService.delete(`${URL.COMPANY}/${companyId}`)
  }

  /*** companies bulk delete call /companies */
  bulkDeleteCompanies(companies: IBulkDelete): Observable<IResponse> {
    return this.baseService.delete(URL.BULK_DELETE_COMPANIES, companies)
  }

  /*** retore companies call /restoreCompanies */
  restoreCompanies(companies: string []): Observable<IResponse> {
    return this.baseService.add(URL.RESTORE_COMPANIES, {companiesIdsArray: companies})
  }

  /*** get companies call /companies */
  getCompanies(url: string): Observable<ICompanyDataInterface []> {
    return this.baseService.get(url, STRING_CONSTANTS.COMPANY_MODULE, true)
  }
  /*** get PSA credentials */
  getPSACreds(): Observable<ICwAuth> {
    return this.baseService.get(`${URL.CW_CREDS}`, STRING_CONSTANTS.COMPANY_MODULE, true)
  }

  /*** get AutoTask credentials */
  getAutotaskCreds(): Observable<ICwAuth> {
    return this.baseService.get(`${URL.AT_CREDS}`, STRING_CONSTANTS.COMPANY_MODULE, true)
  }
  saveCompaniesColumnState(state: ColumnState[]): Observable<ColumnState[]> {
    const columnDefs = state
    return this.baseService.edit(`${URL.USER_HEADERS}/${COMPANY_TYPE}`, { columnDefs })
  }

  resetCompaniesColumnState(): Observable<ColumnState[]> {
    return this.baseService.edit(`${URL.USER_HEADERS}/${COMPANY_TYPE}`, {})
  }

  getCompaniesColumnState(): Observable<ColumnState[]> {
    return this.baseService.get(`${URL.USER_HEADERS}/${COMPANY_TYPE}`, STRING_CONSTANTS.COMPANY_MODULE, true)
  }
  saveRestoreCompaniesColumnState(state: ColumnState[]): Observable<ColumnState[]> {
    const columnDefs = state
    return this.baseService.add(`${URL.USER_HEADERS}/${RESTORE_COMPANY_TYPE}`, { columnDefs })
  }

  getRestoreCompaniesColumnState(): Observable<ColumnState[]> {
    return this.baseService.get(`${URL.USER_HEADERS}/${RESTORE_COMPANY_TYPE}`, STRING_CONSTANTS.COMPANY_MODULE, true)
  }
  getFilterSQPR(model) {
    const sqpr = {}
    for (const obj in model) {
      if (obj && model[obj].filterType === 'date') {
        sqpr[obj] = model[obj].dateFrom
      } else if (obj) {
        sqpr[obj] = model[obj]
      }
    }
    return sqpr
  }
  /*** get companies type call */
  getCompaniesTypes(): Observable<ICompanyTypesInterface []> {
    return this.baseService.get(COMPANIES_ROUTES.COMPANIES_TYPE(), STRING_CONSTANTS.COMPANY_MODULE, true)
  }
  refreshTableDataSnapshot(): Observable<{message: string}> {
    this.isTableRefreshDisabled = true
    setTimeout(() => {
      this.isTableRefreshDisabled = false
    }, 50000)
    return this.baseService.get(URL.REFRESH_SNAPSHOT_TABLE_DATA, STRING_CONSTANTS.COMPANY_MODULE, true)
  }
  getUserRolesConfigurations() {
    const categoriesList = this.sharedDataService.geCategories()
    const userType = JSON.parse(localStorage.getItem('user')).userType
    let permission: string[] = []
    if (categoriesList && userType !== 'external') {
      for (const obj of Object.keys(categoriesList)) {
        if (categoriesList[obj].name === 'Companies') {
          permission = categoriesList[obj].permissions ? categoriesList[obj].permissions :
            ['get', 'post', 'put', 'delete']
          break
        }
      }
    } else {
      permission = ['get']
    }
    let userPermissions: { isRead: boolean, isEdit: boolean, isDelete: boolean, isCreate: boolean }
    if (permission) {
      userPermissions = {
        isRead: permission.indexOf('get') > -1,
        isEdit: permission.indexOf('put') > -1,
        isDelete: permission.indexOf('delete') > -1,
        isCreate: permission.indexOf('post') > -1,
      }
      return userPermissions
    }
    //   return userPermissions
  }
  configureGrid(columnDefs, userPermissions) {
    columnDefs[columnDefs.length - 1].cellRendererParams.delete = userPermissions.isDelete
  }

  saveRefreshTime(time) {
    localStorage.setItem('lastUpdatedSnapshotDate', time)
  }
  getRefreshTime() {
    return localStorage.getItem('lastUpdatedSnapshotDate')
  }
  saveSnapshotColumnState(state: ColumnState[]): Observable<ColumnState[]> {
    const columnDefs = state
    return this.baseService.edit(`${URL.USER_HEADERS}/snapshot`, { columnDefs })
  }

  resetSnapshotColumnState(): Observable<ColumnState[]> {
    return this.baseService.edit(`${URL.USER_HEADERS}/snapshot`, {})
  }

  getSnapshotColumnState(): Observable<ColumnState[]> {
    return this.baseService.get(`${URL.USER_HEADERS}/snapshot`, STRING_CONSTANTS.COMPANY_MODULE, true)
  }

  getSnapshotCounts(params: IRequestParams, pageSize: number): Observable<ISnapShotCounts> {
    let url = ''
    if (params === undefined || params.filterModel === undefined || params.sortModel === undefined) {
       url = `${URL.USER_HEADER_COUNTS}`
    } else {
      const filterSQPR = this.getFilterSQPR(params.filterModel)
      const sortSQPR = this.utils.getSortSQPR(params.sortModel)
      url = `${URL.USER_COUNTS}?pageSize=${20000}${Object.entries(filterSQPR)
        .length ? `&conditions=${JSON.stringify(this.createFilter(filterSQPR))}` : ''}${sortSQPR ?
        `&sortBy=${sortSQPR.key}&order=${sortSQPR.order}` :
        ''}`
    }
    url = url.replace('"name":', '"companyName":')
    return this.baseService
    .get(url, STRING_CONSTANTS.COMPANY_MODULE, true)
    // return this.baseService.get(`${URL.USER_COUNTS}`, STRING_CONSTANTS.COMPANY_MODULE, true)
  }

  getSnapshotHeaderCounts(): Observable<ISnapShotCounts> {
    return this.baseService.get(`${URL.USER_HEADER_COUNTS}`, STRING_CONSTANTS.COMPANY_MODULE, true)
  }

  fetchSnapshotData(params: IRequestParams, pageSize: number): Observable<ISnapShotData> {
    // this.baseService.get(`${URL.FETCH_SNAPSHOT_TABLE_DATA}`, STRING_CONSTANTS.COMPANY_MODULE, true)
    const filterSQPR = this.getFilterSQPR(params.filterModel)
    const sortSQPR = this.utils.getSortSQPR(params.sortModel)
    let url = `${URL.FETCH_SNAPSHOT_TABLE_DATA}&pageNo=${(params.offset * 4) + 1}&pageSize=${pageSize}${Object.entries(filterSQPR)
      .length ? `&conditions=${JSON.stringify(this.createFilter(filterSQPR))}` : ''}${sortSQPR ?
      `&sortBy=${sortSQPR.key}&order=${sortSQPR.order}` :
      ''}`
    url = url.replace('"name":', '"companyName":')
    url += '&detailFlag=true'
    return this.baseService
    .get(url, STRING_CONSTANTS.COMPANY_MODULE, true)
  }

}
