<itb-rsb-expansion-panel [isExpanded]="true" [hasAddNewButton]="hasEditPermissions" class="expansion-panel-pods" (onAddClickHandler)="onAdd(panelObj)" >
<app-company-pods-notes-list *ngIf="switcherItem.isList" [notes]="localNotes" (onEdit)="editLocalNote($event)" (onView)="viewLocalNote($event)"
                             (onDelete)="deleteLocalNote($event)" [hasEditPermissions]="hasEditPermissions"> </app-company-pods-notes-list>

<app-company-pods-notes-form *ngIf="!switcherItem.isList" [isDisabled]="isDisabled" [selectedObj]="switcherItem.selectedObj"
                             (onSave)="saveLocalNote($event)" (onCancel)="cancel()" (onDelete)="deleteLocalNote($event)" [hasEditPermissions]="hasEditPermissions"> </app-company-pods-notes-form>
</itb-rsb-expansion-panel>
<ng-template #activeNotesLists>
  <itb-heading mat-dialog-title [className]="'modal-h'" [text]="title"></itb-heading>
  <itb-round-button
    [tooltipText]="'Close'"
    mat-dialog-close
    [className]="'light round-modal-cross caret-close-modal'"
    [iconClassName]="'cross'"
  ></itb-round-button>
  <div mat-dialog-content class="notes-active-dialoge">
    <itb-heading [className]="'heading widget-h header-bgColor'" [text]="'Notes'" ></itb-heading>
    <itb-table [dataSource]="filteredNotes" [displayedColumns]="['priority', 'description']"></itb-table>
  </div>
  <div mat-dialog-actions>
    <itb-default-button [mat-dialog-close]="data" cdkFocusInitial #cancle [text]="'cancel'" [className]="'default'" [iconClassName]="'cross'">
    </itb-default-button>
  </div>
</ng-template>
