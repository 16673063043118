import { ICellRendererAngularComp } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'
import { stringConstants } from '@projects/ag-grid-customized/src/app/constants/constants'

@Component({
  selector: 'app-master-table-sync',
  templateUrl: './master-table-sync.component.html',
  styleUrls: ['./master-table-sync.component.scss'],
})
export class MasterTableSyncComponent implements ICellRendererAngularComp {
  image: string
  failedClass: string
  disabledClass: string
  class: string
  params: ICellRendererParams
  refresh(params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.')
  }
  agInit(params: ICellRendererParams): void {
    if (params.colDef.cellEditorParams === stringConstants.MDT.AUTOTASK) {
      this.failedClass = stringConstants.MDT.AT_CLASS_FAILED
      this.disabledClass = stringConstants.MDT.AT_CLASS_DISABLED
      this.class = stringConstants.MDT.AT_CLASS
      this.image = stringConstants.MDT.AT_IMAGE
    } else {
      this.failedClass = stringConstants.MDT.CW_CLASS_FAILED
      this.disabledClass = stringConstants.MDT.CW_CLASS_DISABLED
      this.class = stringConstants.MDT.CW_CLASS
      this.image = stringConstants.MDT.CW_IMAGE
    }
    if (params.data) {
      this.params = params
    }
  }
}
