import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { MODAL_CONSTANTS } from '@app/constants/ui-components.constants'
import { Subscription } from 'rxjs'
import { PopupAlertComponent } from 'ui-components'

import { NOTIFICATION_CONSTANTS, STRING_CONSTANTS } from '../../constants/constants'
import { IAvatarArray } from '../../interfaces/avatar-array'
import { IChangePasswordRequest } from '../../interfaces/change-password-backend'
import { IChangePasswordErrorCode } from '../../interfaces/change-password-error-code'
import { IChangePassword } from '../../interfaces/change-password-interface'
import { ChangePasswordService } from '../../services/change-password.service'
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  isLoading: boolean
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
  canViewPassword: boolean
  hasErrorMsgForPwdFlag: boolean
  errorMsgForPwd: string
  isWrongPasswordText: boolean
  isChecked: boolean
  description: string
  isULetter: boolean
  isSsymbol: boolean
  hasNumber: boolean
  hasNoSpace: boolean
  hasEightChar: boolean
  hasErrorMsgForPwdFlagConfrm: boolean
  hasErrorMsgForPwdFlagNew: boolean
  isEightCharStyle: boolean
  isCapitalLetterStyle: boolean
  isNumberStyle: boolean
  isSymbolStyle: boolean
  isNospaceStyle: boolean
  isDescriptionStyle: boolean
  isFirstBarStyle: boolean
  isSecondBarStyle: boolean
  isThirdBarStyle: boolean
  isFourthBarStyle: boolean
  inputType: string
  changePasswordForm: FormGroup
  iconsArray: IAvatarArray[]
  iconsArrayForChange: IAvatarArray[]
  subscriptions: Subscription
  inputTypeForChange: string
  constructor(private readonly changePasswordSvc: ChangePasswordService, public dialog: MatDialog) {
    this.isLoading = false
    this.canViewPassword = false
    this.hasErrorMsgForPwdFlag = false
    this.errorMsgForPwd = ''
    this.isWrongPasswordText = false
    this.isChecked = false
    this.description = ''
    this.isULetter = false
    this.isSsymbol = false
    this.hasNumber = false
    this.hasNoSpace = false
    this.hasEightChar = false
    this.hasErrorMsgForPwdFlagConfrm = false
    this.hasErrorMsgForPwdFlagNew = false
    this.isEightCharStyle = false
    this.isCapitalLetterStyle = false
    this.isNumberStyle = false
    this.isSymbolStyle = false
    this.isNospaceStyle = false
    this.isDescriptionStyle = false
    this.isFirstBarStyle = false
    this.isSecondBarStyle = false
    this.isThirdBarStyle = false
    this.isFourthBarStyle = false
    this.iconsArray = [{ tooltipText: STRING_CONSTANTS.HIDE_PASSWORD , className: STRING_CONSTANTS.EYE_DA,
      },
    ]
    this.iconsArrayForChange = [{ tooltipText: STRING_CONSTANTS.HIDE_PASSWORD , className: STRING_CONSTANTS.EYE_DA,
    },
    ]
    this.inputType = STRING_CONSTANTS.PASSWORD
    this.inputTypeForChange = STRING_CONSTANTS.PASSWORD
    this.subscriptions = new Subscription()
  }
  ngOnInit() {

    this.changePasswordForm = new FormGroup({
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmNewPassword: new FormControl('', Validators.required),
    })

  }
  /*** Show / hide password   */
  showPassword() {
    this.canViewPassword = !this.canViewPassword
  }
  /*** Change event for confirm password   */
  changeNewConfirmPwd() {
    this.isWrongPasswordText = false
    this.isChecked = false
    if (this.changePasswordForm.value.newPassword !== this.changePasswordForm.value.confirmNewPassword) {
      this.isWrongPasswordText = true
      this.isChecked = true
    }
  }
  /*** Change event for new password   */
  changeNewPwd() {
    const object: IChangePasswordErrorCode  = this.createObject()
    const changeNewPassword = this.changePasswordSvc.changeNewPassword(
      this.changePasswordForm.value.newPassword, this.changePasswordForm.value.confirmNewPassword, object)
    this.hasEightChar = changeNewPassword.errorObject.hasEightChar
    this.changePasswordForm.value.newPassword = changeNewPassword.newPassword
    this.hasNumber = changeNewPassword.errorObject.hasNumbers
    this.isULetter = changeNewPassword.errorObject.isULetter
    this.isSsymbol = changeNewPassword.errorObject.isSsymbol
    this.hasNoSpace = changeNewPassword.errorObject.hasNoSpace
    this.description = changeNewPassword.errorObject.description
    this.isChecked = changeNewPassword.errorObject.isChecked
    this.isWrongPasswordText = changeNewPassword.errorObject.isWrongPasswordText
    this.isEightCharStyle = changeNewPassword.errorObject.isEightCharStyle
    this.isCapitalLetterStyle = changeNewPassword.errorObject.isCapitalLetterStyle
    this.isNumberStyle = changeNewPassword.errorObject.isNumberStyle
    this.isSymbolStyle = changeNewPassword.errorObject.isSymbolStyle
    this.isNospaceStyle = changeNewPassword.errorObject.isNospaceStyle
    this.isFirstBarStyle = changeNewPassword.errorObject.isFirstBarStyle
    this.isSecondBarStyle = changeNewPassword.errorObject.isSecondBarStyle
    this.isThirdBarStyle = changeNewPassword.errorObject.isThirdBarStyle
    this.isFourthBarStyle = changeNewPassword.errorObject.isFourthBarStyle
    this.isDescriptionStyle  = changeNewPassword.errorObject.isDescriptionStyle
  }
  /*** Returns password settings object   */
  createObject() {
    return{isULetter: this.isULetter, isSsymbol: this.isSsymbol, hasNumber: this.hasNumber,
           hasNoSpace: this.hasNoSpace, hasEightChar: this.hasEightChar, description: this.description,
           isWrongPasswordText: this.isWrongPasswordText, isChecked: this.isChecked , isEightCharStyle: this.isEightCharStyle,
           isCapitalLetterStyle: this.isCapitalLetterStyle, isNumberStyle: this.isNumberStyle,
           isSymbolStyle: this.isSymbolStyle, isNospaceStyle: this.isNospaceStyle, isFirstBarStyle: this.isFirstBarStyle,
           isSecondBarStyle: this.isSecondBarStyle, isThirdBarStyle: this.isThirdBarStyle,
           isFourthBarStyle: this.isFourthBarStyle, isDescriptionStyle: this.isDescriptionStyle}
  }
  /*** Send change password http request using change password service   */
  changePasswordCall() {
    this.isLoading = true
    const passwordObj: IChangePassword = {
        oldPassword : this.changePasswordForm.value.oldPassword,
        newPassword : this.changePasswordForm.value.newPassword,
    }

    this.subscriptions.add(this.changePasswordSvc.changePasswordCall(passwordObj).subscribe(data =>
        this.afterPassword(data),
        error => {
          this.isLoading = false
          this.errorMsgForPwd = 'Invalid Password'
          this.hasErrorMsgForPwdFlag = true
    }))
  }
  /*** Checks response of change password request */
  afterPassword(data: IChangePasswordRequest) {
    this.isLoading = false
    if (data.error_code) {
      this.errorMsgForPwd = 'Invalid Password'
      this.hasErrorMsgForPwdFlag = true
    } else {
      this.dialog.closeAll()
      this.showNotification(NOTIFICATION_CONSTANTS.SUCCESS, '', NOTIFICATION_CONSTANTS.PASSWORD_CHANGED, 'Saved!')
    }
  }
  /*** Validates input fields and calls changePasswordCall() function in case of correct data  */
  submit() {
    this.hasErrorMsgForPwdFlagNew = false
    this.hasErrorMsgForPwdFlagConfrm = false
    this.hasErrorMsgForPwdFlag = false
    if (this.changePasswordForm.value.oldPassword === '') {
      this.errorMsgForPwd = 'Please enter existing password'
      this.hasErrorMsgForPwdFlag = true
    }
    if (this.changePasswordForm.value.newPassword === '') {
      this.errorMsgForPwd = 'Please enter New password'
      this.hasErrorMsgForPwdFlagNew = true
    }
    if (this.changePasswordForm.value.confirmNewPassword === '') {
      this.errorMsgForPwd = 'Please enter New Confirm Password'
      this.hasErrorMsgForPwdFlagConfrm = true
    } else if (this.changePasswordForm.value.oldPassword === this.changePasswordForm.value.newPassword) {
      this.errorMsgForPwd = 'New and old passwords cannot be same'
      this.hasErrorMsgForPwdFlag = true
    }
    if ((this.hasErrorMsgForPwdFlag) || (this.hasErrorMsgForPwdFlagConfrm)
      || (this.hasErrorMsgForPwdFlagNew) || (this.hasErrorMsgForPwdFlag)) {
      return
    }
    if (this.description === 'Strong') {
        this.changePasswordCall()
    }
  }
  /*** Show / hide New Password  */
  clickCatch(item: string) {
    this.inputType = item === STRING_CONSTANTS.EYE_DA ? STRING_CONSTANTS.TEXT : STRING_CONSTANTS.PASSWORD
    this.iconsArray = [{
      tooltipText: this.inputType === STRING_CONSTANTS.PASSWORD ? STRING_CONSTANTS.HIDE_PASSWORD : STRING_CONSTANTS.SHOW_PASSWORD,
      className: this.inputType === STRING_CONSTANTS.PASSWORD ? STRING_CONSTANTS.EYE_DA : STRING_CONSTANTS.EYE  ,
    } ]
  }
  /*** Show / hide Confirm Password  */
  clickCatchConfirmPassword(item: string) {
    this.inputTypeForChange = item === STRING_CONSTANTS.EYE_DA ? STRING_CONSTANTS.TEXT : STRING_CONSTANTS.PASSWORD
    this.iconsArrayForChange = [{
      tooltipText: this.inputTypeForChange === STRING_CONSTANTS.PASSWORD ? STRING_CONSTANTS.HIDE_PASSWORD : STRING_CONSTANTS.SHOW_PASSWORD,
      className: this.inputTypeForChange === STRING_CONSTANTS.PASSWORD ? STRING_CONSTANTS.EYE_DA : STRING_CONSTANTS.EYE  ,
    } ]
  }
  /*** show modal notification  */
  showNotification(mode: string, title: string, body: string, mainMessage: string) {
    const dialogRef = this.dialog.open(PopupAlertComponent, {
      width: MODAL_CONSTANTS.MODAL_WIDTH,
      data: {mode, title, body, mainMessage},
    })
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
