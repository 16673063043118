import { DragDropModule } from '@angular/cdk/drag-drop'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import {
  MAT_DIALOG_DEFAULT_OPTIONS, MatBadgeModule, MatButtonModule,
  MatCardModule,
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
  MatExpansionModule, MatMenuModule,
  MatNativeDateModule, MatSidenavModule,
  MatSnackBarModule,
  MatToolbarModule,
} from '@angular/material'

import { MODAL_WIDTH_CONSTANTS } from '../core/constants/constants'

@NgModule({
  imports: [
    MatDialogModule,
    MatSnackBarModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatExpansionModule,
    MatCardModule,
    MatMenuModule,
    DragDropModule,
    MatButtonModule,
    MatSidenavModule,
    MatBadgeModule,
  ],
  exports: [
    MatDialogModule,
    MatSnackBarModule,
    FlexLayoutModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatExpansionModule,
    MatCardModule,
    MatMenuModule,
    DragDropModule,
    MatButtonModule,
    MatSidenavModule,
    MatBadgeModule,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
         // tslint:disable-next-line: no-object-literal-type-assertion
        ...new MatDialogConfig(),
        panelClass: 'itb-dialog',
        width: MODAL_WIDTH_CONSTANTS.MEDIUM,
        hasBackdrop: true,
        maxWidth: '95vw',
      } as MatDialogConfig,
    },
    { provide: MatDialogRef },
  ],
})

export class AngularMaterialModule { }
