import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms'
import { RIGHT_SIDE_BAR } from '@core/constants/constants'
import { IButtonIcon } from '@core/interfaces/button-icons'
import { ILocalNotes } from '@core/interfaces/local-notes'
import { RightSideBarService } from '@core/services/right-side-bar.service'
import { ISelect } from 'app/interfaces/ui-components.interface'

@Component({
  selector: 'app-company-pods-notes-form',
  templateUrl: './pods-notes-form.component.html',
  styleUrls: ['./pods-notes-form.component.scss'],
})
export class PodsNotesFormComponent implements OnInit {

  @Input() selectedObj: ILocalNotes
  @Input() isDisabled: boolean
  @Input() hasEditPermissions: boolean
  @Output() onSave = new EventEmitter<ILocalNotes>()
  @Output() onCancel = new EventEmitter<string>()
  @Output() onDelete = new EventEmitter<string>()

  formData: FormGroup
  buttons: IButtonIcon[]
  isTouched: boolean
  inputForm: FormGroup
  isShowErrorsOnSubmit: boolean
  priorityOptions: ISelect[]

  constructor(private readonly formBuilder: FormBuilder, private readonly rightSideBarSvc: RightSideBarService) {
    this.isShowErrorsOnSubmit = false
    this.priorityOptions = rightSideBarSvc.getPriorityOptions()
  }

  get form() {
    return this.inputForm.controls
  }

  btnClickHandler(item: string) {
    switch (item) {
      case RIGHT_SIDE_BAR.CLOSE.toLowerCase():
        this.onCancel.emit()
        break
      case RIGHT_SIDE_BAR.SAVE.toLowerCase():
        if (this.inputForm.valid) {
          this.buttons = this.rightSideBarSvc.createButtons(RIGHT_SIDE_BAR.SETTING)
          this.inputForm.value.priority ?
            this.inputForm.value.priority = this.inputForm.value.priority.label :
            this.inputForm.value.priority = this.selectedObj.priority || 'High'
          this.onSave.emit(this.inputForm.value)
        } else {
          this.isShowErrorsOnSubmit = true
        }
        break
      case RIGHT_SIDE_BAR.DELETE.toLowerCase():
        this.onDelete.emit(this.selectedObj.uuid)
        break
      case RIGHT_SIDE_BAR.EDIT.toLowerCase():
        this.inputForm.enable()
        this.buttons = this.rightSideBarSvc.createButtons(RIGHT_SIDE_BAR.CLOSE)
        break
      default:
        this.buttons = this.rightSideBarSvc.createButtons(RIGHT_SIDE_BAR.SETTING)
    }
  }

  onPriorityChange(item: ISelect) {
    this.inputForm.controls.priority.setValue(item.label)
  }

  ngOnInit() {
    this.inputForm = this.formBuilder.group({
      description: [ this.selectedObj.description || '', [Validators.required] ],
      priority: this.createPriorityObj(),
      modalFlag: this.selectedObj.modalFlag || false,
    })
    if (this.isDisabled) {
      this.inputForm.disable()
      this.buttons = this.rightSideBarSvc.createButtons(RIGHT_SIDE_BAR.DELETE)
      this.buttons = this.hasEditPermissions ? this.rightSideBarSvc.createButtons(RIGHT_SIDE_BAR.DELETE) :
        this.rightSideBarSvc.createCloseButton()
    } else {
      this.buttons = this.hasEditPermissions ? this.rightSideBarSvc.createButtons(RIGHT_SIDE_BAR.CLOSE) :
        this.rightSideBarSvc.createCloseButton()
    }
  }

  private createPriorityObj(): ISelect[] {
    if (this.selectedObj.priority) {
      return this.priorityOptions.map(item => {
        item.label === this.selectedObj.priority ? item.isSelected = true : item.isSelected = false
        return item
      })
    }
    return this.rightSideBarSvc.getPriorityOptions()
  }
}
