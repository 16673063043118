export const loginStringConstants = {
  userNameConfiguration: {
    model: 'userName',
    name: '',
    id: 'inputEmail',
    label: 'Email Address',
    required: true,
    placeholder: '',
    readOnly: false,
    type: 'email',
  },
  passwordConfiguration: {
    model: 'Password',
    name: '',
    id: 'inputPassword',
    label: 'Password',
    required: true,
    placeholder: '',
    readOnly: false,
    type: 'password',
  },
  codeConfiguration: {
    model: 'code',
    name: '',
    id: 'code',
    label: 'Code',
    required: true,
    placeholder: '',
    readOnly: false,
    type: 'text',
  },
  ldapCheckBoxConfiguration: {
    model: 'ldapFlag',
    id: 'ldapFlag',
    label: 'Authenticate with LDAP',
  },
  forgotPasswordConfiguration: {
    model: 'forgetPassword',
    name: '',
    id: 'forgetPassword',
    label: 'Email Address',
    required: true,
    placeholder: '',
    readOnly: false,
    type: 'email',
  },

  LG_MODAL_WIDTH: '900px',
  MD_MODAL_WIDTH: '600px',
  SM_MODAL_WIDTH: '300px',

  MODAL_WIDTH: '500px',
  EMPTY_STRING: '',
  HTTP_STRONG: 'http://',
  HTTPS_STRONG: 'https://',
  DOT: '.',
  EXTERNAL_USER: 'external',
  // tslint:disable-next-line: max-line-length
  EMAIL_PATTERN: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,

}

export const loginRouteConstants = {
  LOGIN_REDIRECT: subDomain => (`users/login/redirect/${subDomain}`),
  GOOGLE_AUTH_VERIFY: () => ('users/2FA/auth'),
  AUTH: () => ('users/auth'),
  USER_ROLE: roleId => (`/users/roles/${roleId}/`),
  SSO_LOGIN: () => ('users/login/user/'),
  RESET_PASSWORD: () => ('users/forgotPassword/'),
  ORG_INFO: subDomain => (`organizations/info/${subDomain}`),
  CONFIRM_USER_UUID: uuid => (`users/confirm/${uuid}`),
  CHANGE_USER_PASSWORD: uuid => (`users/newpassword/${uuid}`),
}

export const loginErrorConstants = {
  ACCOUNT_ACTIVE: 'Your account is not active. Please contact the administrator.',
  PLAN_ERROR: 'Your subscription is invalid or has expired. Please contact the administrator.',
  RESTRICTED_ACCESS: 'Access is restricted for this account. Please contact the administrator.',
  COMPANY_ERROR: 'No companies exist for this external user.',
  VALID_EMAIL: 'Please enter a valid <strong>email</strong>',
  VALID_PASSWORD: 'Please enter a <strong>password</strong>',
  VALID_EMAIL_AND_PASSWORD: 'Please enter your <strong>Email</strong> and <strong>Password</strong>',
  SSO_ERROR: 'This account has been locked or is inactive. Please contact the administrator or log in using SSO.',
}

export const PODSCONST = {
  REPLACE: {
    LOGIN: 'login',
    ACCESS: 'access',
    APP: 'app',
  },
  GET_PODS: '&pods=',
  DOUBLE_SLASH: '//',
}
