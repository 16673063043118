import { Injectable } from '@angular/core'
import { ApiService } from '@app/services/api.service'

import { END_POINTS } from './../../constants/constants'
import { CompanyDashboardService } from './../companyDashboard/company-dashboard.service'
@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(private readonly baseService: ApiService, private readonly utils: CompanyDashboardService) { }
  getAllCompanyAsset(companyId: string, sqpr, pageNo: number, pageSize = 20) {
    const filterSQPR = this.utils.getFilterSQPR(sqpr.filterModel)
    const sortSQPR = this.utils.getSortSQPR(sqpr.sortModel)
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${END_POINTS.GET_OS_STATE(companyId)}${
      Object.entries(filterSQPR).length ? `?conditions=${JSON.stringify(filterSQPR)}` : `?conditions=${JSON.stringify({})}`
      }${sortSQPR ? `&sortBy=${sortSQPR.key}&order=${sortSQPR.order}&pageNo=${pageNo}&pageSize=${pageSize}` : ''}`,
      '',
      true
    )
  }

  getAllCompanyAssetCount(companyId: string, sqpr) {
    const filterSQPR = this.utils.getFilterSQPR(sqpr.filterModel)
    // tslint:disable-next-line: no-null-keyword
    const merged = { ...filterSQPR, $or: [{ recordStatus: null }, { recordStatus: { $regex: 'Complete', $options: 'i' } }] }
    return this.baseService.get(`${END_POINTS.GET_ASSETS_COUNT(companyId)}?conditions:${JSON.stringify(merged)}`, 'Configuration', true)
  }
  getAllCompanyFavAssetCount(companyId: string) {
    return this.baseService.get(`${END_POINTS.GET_FAV_COUNT(companyId)}`, 'Configuration', true)
  }
  getFilteredCompanyAsset(status, id) {
    return this.baseService.get(`${END_POINTS.GET_ASSETS_FILTERED_DETAILS(status)}?detailFlag=true&companyId=${id}`, '', true)
  }
  getServerCounts(companyId: string) {
    return this.baseService.get(`${END_POINTS.GET_SERVERS(companyId)}a0a7ae2b-9a13-4a5f-b130-efd64ff90795`, '', true)
  }
  getRMMCounts(companyId: string) {
    return this.baseService.get(`${END_POINTS.GET_RMM_COUNT(companyId)}`, '', true)
  }
  getCompanyAssetNames(companyId: string) {
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${END_POINTS.GET_OS_STATE(companyId)}?conditions={"favourite":true}&fields={"name":1,"syncFlag":1}`,
      '',
      true
    )
  }
  checkScreenConnect(name) {
    return this.baseService.get(END_POINTS.SCREEN_CONNECT(name), '', true)
  }
  getCompanyAsset(companyId: string, sqpr, pageNo: number, pageSize = 20) {
    const filterSQPR = this.utils.getFilterSQPR(sqpr.filterModel)
    const sortSQPR = this.utils.getSortSQPR(sqpr.sortModel)
    // tslint:disable-next-line: object-literal-key-quotes
    const merged = { ...{ favourite: true }, ...filterSQPR }
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${END_POINTS.GET_OS_STATE(companyId)}${
      Object.entries(merged).length ? `?conditions=${JSON.stringify(merged)}` : `?conditions=${JSON.stringify({})}`
      }${sortSQPR ? `&sortBy=${sortSQPR.key}&order=${sortSQPR.order}&pageNo=${pageNo}&pageSize=${pageSize}` : ''}`,
      '',
      true
    )
  }
  getStats(companyId: string) {
    return this.baseService.get(`${END_POINTS.GET_OS_STATE(companyId)}?fields={"type": 1}`, '', true)
  }

  getOnlineStats(companyId: string) {
    return this.baseService.get(`${END_POINTS.GET_ONLINE_STATE(companyId)}`, '', true)
  }
  getOfflineStats(companyId: string) {
    return this.baseService.get(`${END_POINTS.GET_OFFLINE_STATE(companyId)}`, '', true)
  }
  getTotalStats(companyId: string) {
    return this.baseService.get(`${END_POINTS.GET_TOTAL_STATE(companyId)}`, '', true)
  }
  getServerStatsColDefs() {
    return [
      { headerName: 'CONFIGURATION NAME', field: 'Name', minWidth: 180, suppressColumnsToolPanel: true, lockPosition: true },
      { headerName: 'status', field: 'status', minWidth: 180 },
      // { headerName: 'sync status', field: 'status', minWidth: 180 },
      { headerName: 'os', field: 'osType', minWidth: 180 },
      {
        headerName: 'asset date',
        field: 'AssetDate',
        minWidth: 180,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      // { headerName: 'bios name', field: 'BiosName', minWidth: 180 }, Data is currently unavailable from  automate
      { headerName: 'bios ver', field: 'BiosVer', minWidth: 180 },
      { headerName: 'cpu usage', field: 'CPUUsage', minWidth: 180 },
      // { headerName: 'dns info', field: 'DNSInfo', minWidth: 180 },
      // { headerName: 'data in', field: 'DataIn', minWidth: 180 },
      // { headerName: 'data out', field: 'DataOut', minWidth: 180 },
      {
        headerName: 'date added',
        field: 'DateAdded',
        minWidth: 180,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      { headerName: 'domain', field: 'Domain', minWidth: 180 },
      { headerName: 'idle time', field: 'IdleTime', minWidth: 180 },
      { headerName: 'local address', field: 'LocalAddress', minWidth: 180 },
      { headerName: 'mac', field: 'MAC', minWidth: 180 },
      // { headerName: 'management ip', field: 'processorName', minWidth: 180 },
      // { headerName: 'management port', field: 'ManagementPort', minWidth: 180 },
      { headerName: 'memory available', field: 'MemoryAvailable', minWidth: 180 },
      // { headerName: 'processors', field: 'purchaseBy', minWidth: 180 },
      // { headerName: 'runlevel', field: 'RunLevel', minWidth: 180 },
      // { headerName: 'version', field: 'Version', minWidth: 180, suppressColumnsToolPanel: true },
    ]
  }

  getChartConfig(counts, colorr) {
    return {
      title: {
        text: 'OS',
        subtext: 'COUNT',
        left: 'center',
        top: '35%',
        textStyle: {
          color: '#909090',
          fontWeight: 'normal',
          fontFamily: 'Lato',
          fontSize: 18,
        },
        subtextStyle: {
          color: '#909090',
          fontWeight: 'normal',
          fontFamily: 'Lato',
          fontSize: 14,
        },
      },
      textStyle: {
        color: ['themeColor'],
        fontSize: [16],
      },
      color: ['#e14c42', '#00c5dc', '#716aca', '#ffb822', '#00bff3', '#afe911'],
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      label: {
        fontSize: 10,
        color: '#909090',
      },
      series: [
        {
          name: 'Asset Widget',
          type: 'pie',
          radius: ['60%', '50%'],
          center: ['50%', '40%'],
          avoidLabelOverlap: true,
          color: colorr,
          label: {
            normal: {
              show: true,
            },
          },
          animation: false,
          labelLine: {
            show: !0,
            length: 25,
          },
          data: counts,
          tooltip: {
            backgroundColor: 'rgba(0,0,0,.75)',
            formatter: '{b0}: {c0} ({d0}%)',
            padding: [3, 5],
            textStyle: {
              fontSize: 13,
            },
          },
        },
      ],
    }
  }
}
