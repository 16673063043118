import { Component, Input, OnDestroy } from '@angular/core'
import { UtilService } from '@app/modules/core/services/utils.service'
import { Subscription } from 'rxjs'

import { STRING_CONSTANTS } from '../../../constants/constants'
import { IDate } from '../../../interfaces/activities'
import { CoreService } from '../../../services/core.service'
@Component({
  selector: 'app-activity-export',
  templateUrl: './activity-export.component.html',
})
export class ActivityExportComponent implements OnDestroy {
  @Input() filterBy: {label?: string, isSelected?: boolean, id?: number}
  @Input() filterActivity: {label?: string, isSelected?: boolean, id?: number}
  subscriptions: Subscription
  isActivityExportLoading: boolean
  constructor(private readonly coreService: CoreService, private readonly utilService: UtilService) {
    this.isActivityExportLoading = false
    this.subscriptions = new Subscription()
  }
  /***Creates query for backend and sends http request */
  activityExport() {
    this.isActivityExportLoading = true
    let query: {filter: { action?: string, createdAt?: {$lt?: string, $gte?: string}}, limit?: number, order?: string}
    let filterDate
    let start: Date
    let end: Date
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    if (!this.filterBy) {
      this.filterBy = {}
      this.filterBy.label = ''
    }
    const filterBy = this.filterActivityMethodForBackend(this.filterBy.label)
    const isFilterTypeNotAll = filterBy !== STRING_CONSTANTS.ALL
    const filter = isFilterTypeNotAll ? {action: filterBy} : {}
    query = { filter }
    switch (this.filterActivity.label) {
      case 'Last 10 Activities':
        query.limit = 10
        break
      case 'Today':
        start = new Date(year, month, day)
        end = new Date(year, month, day)
        filterDate = this.utilService.filterDateForActivityExport(start, end)
        query.filter.createdAt = {$lt: filterDate.start.toISOString(), $gte: filterDate.end.toISOString() }
        break
      case 'Last Week':
        const lastWeek = new Date(year, month, day - 7)
        start = new Date(year, month, day)
        const lastWeekYear = lastWeek.getFullYear()
        const lastWeekMonth = lastWeek.getMonth()
        const lastWeekDay = lastWeek.getDate()
        end = new Date(lastWeekYear, lastWeekMonth, lastWeekDay)
        filterDate = this.utilService.filterDateForActivityExport(start, end)
        query.filter.createdAt = {$lt: filterDate.start.toISOString(), $gte: filterDate.end.toISOString() }
        break
      case  'Last Month':
        let monthDate = new Date(date)
        monthDate = new Date(monthDate.setMonth(monthDate.getMonth() - 1))
        const startDate = new Date(year, month, day)
        const lastMonthYear = monthDate.getFullYear()
        const lastMonthMonth = monthDate.getMonth()
        const lastMonthDay = monthDate.getDate()
        const endDate: Date = new Date(lastMonthYear, lastMonthMonth, lastMonthDay)
        filterDate = this.utilService.filterDateForActivityExport(startDate, endDate)
        query.filter.createdAt = {$lt: filterDate.start.toISOString(), $gte: filterDate.end.toISOString() }
        break
      default:
    }
    this.subscriptions.add(this.coreService.exportActivities(query).subscribe(data => {
      this.isActivityExportLoading = false
      if (! data.error_code) {
        const hours = date.getHours()
        const minutes = date.getMinutes()
        const seconds = date.getSeconds()
        const fileDate = `${year}  ${(month + 1)}${day}-${hours}${minutes}${seconds}`
        const blob = new Blob([data.data], {type: 'text/csv'})
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileDate)
        } else {
          const elem = window.document.createElement('a')
          elem.href = window.URL.createObjectURL(blob)
          elem.download = `activities-Export-${fileDate} .csv`
          document.body.appendChild(elem)
          elem.click()
          document.body.removeChild(elem)
        }
      }

    }, error => {

    }))
  }
  /***Changes activity request for backend */
  filterActivityMethodForBackend(filterBy: string): string {
    let filter
    switch (filterBy) {
      case 'Filter by: All':
        filter = STRING_CONSTANTS.ALL
        break
      case 'Filter by: Add':
        filter = STRING_CONSTANTS.POST
        break
      case 'Filter by: Edit':
        filter = STRING_CONSTANTS.PUT
        break
      case 'Filter by: Delete':
        filter = STRING_CONSTANTS.DELETE
        break
      default:
        filter = STRING_CONSTANTS.ALL
    }
    return filter
  }
  /*** Unsubscribe all subscription when component destroy*/
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
