<div class="itb-login-page" id="{{loginPodsPage}}">
  <div class="container login-container" >
    <div class="login-wrapper shadow" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" [ngClass.sm]="'login-res'" [ngClass.xs]="'login-res'">
      <div class="login-intro-section" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
        <div class="intro-image" fxLayoutAlign="center">
          <itb-image [imageWidth]=180 [src]="companyLogo" [canShowErrorImage]=true [srcNotFound]="defaultLogoPath"></itb-image></div>
        <h2 class="intro-heading">{{orgName}}</h2>
        <div class="intro-p slider">
          <div class="slider-content">
            <p id="slide-1">ITBoost, IT documentation system by ConnectWise, has a versatile dashboard that hosts the most advanced documentation solution in the industry, and displays the numbers you actually need to see from your external platforms.</p>
            <p id="slide-2">The one hub that removes barriers ITBoost gives you easy access to all of your MSP business applications, PSAs and RMMs. Do what you need to do, all from one place.</p>
            <p id="slide-3">ITBoost leverages advanced technology to show you real-time data like BI, client feedback and password manager features, as well as ticket, asset and monitoring data from your external IT platforms.</p>
          </div>
          <div class="slider-dots">
            <button class="slide-1" (click)="contentDotSlider(0)"></button>
            <button class="slide-2" (click)="contentDotSlider(1)"></button>
            <button class="slide-3" (click)="contentDotSlider(2)"></button>
          </div>
        </div>
        <div class="login-more-btn"> <a target="_blank" href="https://www.itboost.com/"><itb-default-button [text]="'Learn More'" [className]="'primary success'" [iconClassName]="'login'"></itb-default-button></a></div>
      </div>
      <div class="login-form-section" [ngClass]="{'sso-login-section':isShowSSOFlag}" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
        <div *ngIf="!isPromiseResolved" class="h-100" fxLayoutAlign="center center">
          <itb-spinner [spinnerType]="'l'"></itb-spinner>
        </div>
        <form class="form-section only-buttons " [formGroup]="inputForm" *ngIf="isShowSSOFlag==true" [@loginIn]>
          <h1 class="form-heading mb-5">Login</h1>
          <div class="login-submit-btns large-btn-wrapper mt-5" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
            <div class="bt-heading-icons ml-2">
              <img src="/assets/img/lock-icon-main.png" >
              <span class="ml-2 main-hd-text" fxLayout="column" fxLayoutAlign="end center">
                <h1>SSO</h1>
                <span class="sm-text-tag">Single Sign On</span>
              </span>
            </div>
              <itb-default-button fxFlexAlign='center' [text]="'SSO LOGIN'" [className]="'primary btn-login mt-2 mb-2 '"
                                  [iconClassName]="'login'" (onClickHandler)="login()" [isLoading]="isSsoLogin" ></itb-default-button>
          </div>
          <div class="login-submit-btns large-btn-wrapper" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
            <div class="bt-heading-icons ml-2">
              <img src="/assets/img/lock-icon-main-green.png" >
              <span class="ml-2 main-hd-text" fxLayout="column" fxLayoutAlign="end center">
                <h1 style="color: #34bfa3;">ADMIN</h1>
                <span class="sm-text-tag">Administrative User</span>
              </span>
            </div>
              <itb-default-button fxFlexAlign='center' [text]="'ADMIN LOGIN'" [className]="'success btn-login mt-2 mb-2 '"
                                  [isDisabled]="isAdminLoginDisable"
                                  [iconClassName]="'login'" (onClickHandler)="enablePassword()" ></itb-default-button>
          </div>
        </form>
        <form class="form-section" [formGroup]="inputForm" *ngIf="!isResetScreen && isShowSSOFlag==false" [@loginIn]  >
          <h1 class="form-heading">Login</h1>
          <div class="login-feilds">
              <itb-input [formFieldName]="userNameConfiguration.model" [parentForm]="inputForm"
                       [label]="userNameConfiguration.label" [pattern]="emailPattern"
                       [placeholder]="userNameConfiguration.placeholder"[type]="userNameConfiguration.type" (onChangeHandler)="onChangeEvent()"></itb-input>
            <mat-error class="for-input"  *ngIf="!inputForm.valid && inputForm.controls[userNameConfiguration.model].errors.pattern" >
              Enter a valid <strong>Email</strong>
            </mat-error>
          </div>
          <div class="login-feilds">
            <div *ngIf="isPodsLoginFlag">
              <mat-form-field *ngIf=" !isGoogleAuthVerified">
                <input matInput placeholder="{{passwordConfiguration.label}}" type="{{passwordConfiguration.type}}"
                       formControlName="{{passwordConfiguration.model}}" readonly="{{isGoogleAuthVerified}}" (keyup)="onChangeEvent()">
              </mat-form-field>
            </div>
            <div *ngIf="!isPodsLoginFlag">
              <mat-form-field>
                <input matInput placeholder="{{passwordConfiguration.label}}" type="{{passwordConfiguration.type}}"
                       formControlName="{{passwordConfiguration.model}}" readonly="{{isGoogleAuthVerified}}" (keyup)="onChangeEvent()">
              </mat-form-field>
            </div>

            <div class="check-box" *ngIf="isSsoFlag !== true">
              <itb-checkbox *ngIf="enforceLoginType!=='LDAP'" [formFieldName]="checkBoxConfiguration.model"
                          [parentForm]="inputForm" [label]="checkBoxConfiguration.label"></itb-checkbox>
            </div>
          </div>

          <div class="error-box">
            <mat-error class="for-input" *ngIf="!isServerError && validationMessage !== '' && !isIncorrectPasswordFlag" [innerHTML]="validationMessage" ></mat-error>
            <mat-error class="for-input" *ngIf="isServerError"  [innerHTML]="'There was an error logging in. Please try again in a few minutes.'"></mat-error>
            <mat-error class="for-input" *ngIf="!isServerError && isIncorrectPasswordFlag"  [innerHTML]="'Invalid <strong>Email/Password</strong> or inactive user'"></mat-error>
            <itb-input *ngIf="isGoogleAuthVerified" [formFieldName]="codeConfiguration.model" [parentForm]="inputForm"
                       [label]="codeConfiguration.label"
                      [placeholder]="codeConfiguration.placeholder"[type]="codeConfiguration.type"></itb-input>
            <mat-error class="for-input" *ngIf="isGoogleAuthError" [innerText]="'The code you entered is incorrect'" ></mat-error>
      </div>
         
          <div class="login-submit-container">
          <div class="login-submit-btns">
              <itb-default-button [isDisabled]="!inputForm.valid && inputForm.controls[userNameConfiguration.model].errors.pattern" [text]="'Login'" [className]="'primary '" [iconClassName]="'login'" (onClickHandler)="login()" *ngIf="!isLoader"></itb-default-button>
              <itb-default-button [className]="'primary '" [isLoading]="'true'" *ngIf="isLoader"></itb-default-button>
          </div>
          <div class="switch-next">
              <span><a class="f-switch" (click)="switchScreen()">Forgot Password?</a></span>
          </div>
          </div>
        </form>
        <form class="form-section" [formGroup]="inputFormPassword" [@forgetIn] *ngIf="isResetScreen">
            <h1 class="form-heading">Forgot Password</h1>
            <div class="login-feilds">
                <itb-input [formFieldName]="forgotPasswordConfiguration.model" [parentForm]="inputFormPassword"
                [label]="forgotPasswordConfiguration.label" [pattern]="emailPattern" [isRequired]=""
                           (onChangeHandler)="changeForgetPassword()"
                [placeholder]="forgotPasswordConfiguration.placeholder"[type]="forgotPasswordConfiguration.type"></itb-input>
     <itb-info-message [text]="'Enter a valid Email'" [className]="'info-danger'"
                       *ngIf="!inputFormPassword.valid && inputFormPassword.get(forgotPasswordConfiguration.model).errors.pattern"></itb-info-message>
     <itb-info-message *ngIf="isSuccessResponse" [text]="'Email has been sent on the registered address.'" [className]="'info-success for-input'"></itb-info-message>
     <itb-info-message  *ngIf="isFailRespnse" [text]="forgetErrorMessage"
                        [className]="'info-danger for-input'"></itb-info-message>
            </div>
          <div class="login-submit-container">
            <div class="login-submit-btns">
                <itb-default-button [text]="'Reset'" [className]="'primary'"
                [iconClassName]="'login'" [isDisabled]="!inputFormPassword.valid && inputFormPassword.get(forgotPasswordConfiguration.model).errors.pattern || !inputFormPassword.get(this.forgotPasswordConfiguration.model).value"
                (onClickHandler)="reset()" *ngIf="!isLoader" ></itb-default-button>
<itb-default-button [className]="'primary'" [isLoading]="'true'" *ngIf="isLoader"></itb-default-button>
            </div>
            <div class="switch-next">
                <span><a class="f-switch" (click)="switchScreen()">Back to Login</a></span>
            </div>
          </div>
          </form>
      </div>
    </div>
  </div>
</div>

<ng-template #content>
  <itb-heading mat-dialog-title [className]="'modal-h'" [text]="'Account Not Found'" ></itb-heading>
  <div mat-dialog-content pt-5 pl-5 pr-5 pb-5>
    <div class="padded-modal">
    <b class="fs-18">
      Oops! Something went wrong.</b> <br>
    <br>
    <div class="error-text">
      <span>1.</span> You may have entered your subdomain incorrectly. Please verify your subdomain and try again.<br><br>
      <span>2.</span> If you have just registered, your account may not be activated. Please wait at least 2 hours for the DNS to propagate and try again.<br><br>
      <span>3.</span> If you have a firewall, security, VPN, or any other third-party networking software, make sure that it is not blocking port 4442.<br><br>
      <span>4.</span> Temporary data stored in your browser may be causing the problem. Please clear your browser cache and try again.<br>
    </div><br>
    <itb-text [text]="'If none of the steps above worked, contact ITBOOST support at '"></itb-text>
    <a href="mailto:support@itboost.com">support@itboost.com</a>
    <itb-text  [text]="' or use the live chat feature on the bottom right corner of this page for further assistance.'"></itb-text>
  </div>
  </div>
  <div mat-dialog-actions>
    <itb-default-button [text]="'Contact'" [className]="'primary'" [iconClassName]="'send'" (onClickHandler)="openSupportLink()">
    </itb-default-button>
  </div>
</ng-template>

<ng-template #errors >
  <itb-heading mat-dialog-title [className]="'modal-h'" [text]="'LOGIN FAILED'" ></itb-heading>
  <itb-round-button mat-dialog-close [className]="'light round-modal-cross caret-close-modal'" [iconClassName]="'cross'"
                    [tooltipText]="'Close'" > </itb-round-button>
  <div mat-dialog-content>
    <itb-text [className]="'text-small'" [text]="errorMessages"></itb-text>
  </div>
  <div mat-dialog-actions>
    <itb-default-button [text]="'Close'" [className]="'default'" [iconClassName]="'cross'"
                        (onClickHandler)="closeAccountModal()"></itb-default-button>
  </div>
</ng-template>

<ng-template #sessionExpire >
  <itb-heading mat-dialog-title [className]="'modal-h'" [text]="'SESSION EXPIRED'" ></itb-heading>
  <itb-round-button mat-dialog-close [className]="'light round-modal-cross caret-close-modal'" [iconClassName]="'cross'"
                    [tooltipText]="'Close'" > </itb-round-button>
  <div mat-dialog-content>
    <itb-text [className]="'text-default'" [text]="'Your session has expired. Please log in again.'"></itb-text>
  </div>
  <div mat-dialog-actions>
    <itb-default-button [text]="'Ok'" [className]="'primary'" [iconClassName]="'tick'" mat-dialog-close></itb-default-button>
  </div>
</ng-template>
