import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { SharedService } from '@app/modules/shared/shared.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-logout-confirmatin-modal',
  templateUrl: './logout-confirmation.component.html',
})
export class LogOutConfirmationComponent implements OnDestroy {

  constructor(public sharedData: SharedService) {}

  /*** Logs the user out from all sessions or the current session based on the option selected */
  logout(allSessions) {
    this.sharedData.expireAllSession = allSessions
    this.sharedData.logout()
  }

  /*** Unsubscribe all subscription when component destroy*/
  ngOnDestroy() {

  }
}
