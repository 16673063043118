<itb-rsb-expansion-panel [isExpanded]="true" [hasAddNewButton]="hasEditPermissions" class="expansion-panel-pods" (onAddClickHandler)="onAdd(panelObj)" >
<app-company-pods-linked-items-list  *ngIf="switcherItem.isList" [linedItems]="linkedItems" (onView)="viewLinkedItem($event)"
                                     (onDelete)="deleteLinkedItem($event)" [hasEditPermissions]="hasEditPermissions"> </app-company-pods-linked-items-list>

<app-company-pods-linked-items-form  *ngIf="!switcherItem.isList && !isSpinner" [selectedObj]="switcherItem.selectedObj" [templates]="templates" [adlIntegrations]="adlIntegrations" [companyId]="companyId"
                                     [linkedItemsIdsArr]="linkedItemsIdsArr" (onSave)="saveLinkedItems($event)" (onCancel)="cancel()">
</app-company-pods-linked-items-form>

<itb-spinner *ngIf="isSpinner" [spinnerSize]="30" fxLayoutAlign="center center" class="h-100 mt-3 mb-3"></itb-spinner>
</itb-rsb-expansion-panel>
