import { ICellRendererAngularComp } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

@Component({
  selector: 'app-json-cell-render',
  templateUrl: './json-cell-render.component.html',
  styleUrls: ['./json-cell-render.component.css'],
})
export class JsonCellRenderComponent implements ICellRendererAngularComp {
  params // used for optional params that are added dynamically for different types
  jsonData = {}

  refresh(params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.')
  }
  agInit(params: ICellRendererParams): void {
    if (params.data) {
      this.params = params.data
      // this.imagePath = this.params.images.integrationScreenImage
    }
  }
  afterGuiAttached?(params?: import('@ag-grid-community/core').IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.')
  }
}
