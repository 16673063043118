<ag-grid-angular
  #agGrid
  [gridOptions]="gridOptions"
  [defaultColDef]="defaultColDef"
  suppressContextMenu="true"
  suppressPropertyNamesCheck="true"
  floatingFilter="true"
  class="ag-theme-material ag-grid-size"
  [rowModelType]="rowModelType"
  [columnTypes]="columnTypes"
  [columnDefs]="columnDefs"
  [deltaColumnMode]="true"
  [rowSelection]="rowSelection"
  suppressDragLeaveHidesColumns = "true"
  [rowHeight]="rowHeight"
  [headerHeight]="30"
  [floatingFiltersHeight]="32"
  suppressCellSelection="true"
  suppressRowClickSelection="true"
  suppressDragLeaveHidesColumns="true"
  [loadingCellRenderer]="loadingCellRenderer"
  [noRowsOverlayComponent]="noRowsOverlay"
  [noRowsOverlayComponentParams]="noRowsOverlayParams"
  (selectionChanged)="onSelectionChanged($event)"
  (cellValueChanged)="oncellValueChanged($event)"
  [frameworkComponents]="frameworkComponents"
  (columnMoved)="onColumnMoved($event)"
  (gridReady)="onGridReady($event)"
  [sideBar]="sideBar"
  (virtualColumnsChanged) ="virtualColumnsChanged($event)"
  (columnVisible)="columnVisible($event)"
  [animateRows]="true"
  [rowData]="rowData"
  (columnResized) = "onColumnResized($event)"
  (filterChanged)="onFilterChanged()"
  (sortChanged) = "onsortChanged()"
  (firstDataRendered)="dataRendered($event)"
  maxConcurrentDatasourceRequests="1"
  [modules]="modules"
  [maxBlocksInCache]="maxBlocksInCache"
  [cacheBlockSize]="cacheBlockSize"
  [context]="context"
  [pinnedTopRowData]="pinnedRow"
  (toolPanelVisibleChanged)="toolPanelVisibleChanged($event)"
> 
</ag-grid-angular>
<div *ngIf="rowModelType === 'serverSide' && totalRows>= 0"> Showing {{totalRows> 0? 1 : 0}} to {{getTotalFetchedRows()}} of {{totalRows}} entries</div>
<div *ngIf="rowModelType === 'clientSide'  && totalRows>= 0"> Showing {{totalRows> 0? 1 : 0}} to {{totalRows}} of {{totalRows}} entries</div>

