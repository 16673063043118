<div>
  <itb-heading mat-dialog-title [className]="'modal-h'" [text]="'USER AND GROUP ACCESS'"></itb-heading>
  <itb-round-button
    [tooltipText]="'Close'"
    (onClickHandler)="cancelPermissionsHandler()"
    [className]="'light round-modal-cross caret-close-modal'"
    [iconClassName]="'cross'"
  ></itb-round-button>
  <div mat-dialog-content>
    <div class="wrap-normal">
      <itb-radio [label]="''" [labelPosition]="'after'" [parentForm]="radioForm" [formFieldName]="'radio'" [options]="options"></itb-radio>
    </div>
    <div *ngIf="getRadioValue && getRadioValue === 'Specific'">
      <div class="rule-table mt-4 mb-4">
        <itb-table
          [dataSource]="dataSource"
          [displayedColumns]="displayedColumns"
          (onActionClickHandler)="tableEventHandler($event)"
        ></itb-table>
      </div>
      <div fxLayout="row wrap" class="rule-container">
        <div fxFlex="40" fxFlex.sm="100" fxFlex.xs="100">
          <itb-select
            *ngIf="!isEdit"
            [isGroupSelect]="true"
            [isDisabled]="'false'"
            [groupOptions]="selectOptions"
            [label]="'SELECT USER/USER GROUPS'"
            (groupChangeHandler)="selectEventHandler($event)"
          ></itb-select>
          <itb-textarea
            *ngIf="isEdit"
            [isRequired]="false"
            [isReadOnly]="true"
            [isResizeable]="false"
            [rows]="'1'"
            [isReactive]="false"
            [placeholder]="'USER/USER GROUPS'"
            [value]="currentUser.name"
          ></itb-textarea>
          <itb-info-message
            *ngIf="isSaveClicked && (currentUser | json) === '{}'"
            [text]="'Select User/Group'"
            [className]="'info-danger'"
          ></itb-info-message>
        </div>
        <div fxFlex="60" fxFlex.sm="100" fxFlex.xs="100">
          <div class="pl-3 pb-3">
            <itb-checkbox-list
              class="rule-checkbox-group border-bottom"
              [label]="'PERMISSIONS'"
              [checkboxArray]="checkboxArray"
              (onChangeHandler)="checkBoxEventHandler($event)"
            ></itb-checkbox-list>
            <itb-divider [className]="'mat-divider'"></itb-divider>
          </div>
          <itb-info-message
            *ngIf="isSaveClicked && !currentPermissions.length"
            [text]="'Select Permissions'"
            [className]="'info-danger'"
          ></itb-info-message>
        </div>
        <div class="rule-btn-group">
          <itb-round-button
            [className]="'dark'"
            [iconClassName]="'plus-c icon-white'"
            [tooltipText]="'Add Rule'"
            (onClickHandler)="saveHandler()"
          ></itb-round-button>
          <itb-round-button
            [className]="'light'"
            [iconClassName]="'cross'"
            [tooltipText]="'Cancel Rule'"
            (onClickHandler)="cancelHandler(currentUser, currentPermissions)"
          ></itb-round-button>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <itb-default-button
      [text]="'save'"
      [className]="'primary'"
      [iconClassName]="'save'"
      (onClickHandler)="savePermissionsHandler()"
    ></itb-default-button>
    <itb-default-button
      [text]="'cancel'"
      [className]="'default'"
      [iconClassName]="'cross'"
      (onClickHandler)="cancelPermissionsHandler()"
    ></itb-default-button>
  </div>
</div>
