import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

import { stringConstants } from '../../../constants/constants'
import { GirdActionService } from '../../services/grid-actions.service'

@Component({
  selector: 'app-url-cell-renderer',
  templateUrl: './url-cell-renderer.component.html',
  styleUrls: ['./url-cell-renderer.component.scss'],
})
export class URLCellRendererComponent implements AgRendererComponent {
  params // used for optional params that are added dynamically for different types

  constructor(public gridActionsService: GirdActionService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params
  }

  refresh(params: ICellRendererParams): boolean {
    return false
  }

  urlHandler() {
    this.gridActionsService.setSelectedAction(stringConstants.DEFAULT_DATAGRID_URL, this.params.data, this.params.rowIndex)
  }
}
