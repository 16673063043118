<itb-widget
  [hasFooter]="false"
  [roundButtons]="wrapperClass === '' ? roundButton : roundButtonwithCross"
  [hasBarLoader]="''"
  [hasContentCenter]="true"
  (onClickHandler)="listTrackers($event)"
>
  <div class="slide-wrapper  {{ wrapperClass }}">
    <div class="main-content">
      <div *ngIf="!isEmpty" fxLayout="row">
        <itb-pie-chart [chartOptions]="options" fxFlex="51"> </itb-pie-chart>
        <div fxFlex="49" class="legends-container pointer">
          <div class="legends" *ngFor="let item of pieChartData" (click)="openModal(item.name, item.value)">
            <itb-color-bar *ngIf="item.name === 'Expired'" [className]="item.name === 'Expired' ? 'color-bar color-bar-1' : ''">
            </itb-color-bar>
            <itb-color-bar *ngIf="item.name === 'Soon'" [className]="item.name === 'Soon' ? 'color-bar color-bar-3' : ''"> </itb-color-bar>
            <itb-color-bar *ngIf="item.name === 'Active'" [className]="item.name === 'Active' ? 'color-bar color-bar-2' : ''">
            </itb-color-bar>
            <itb-color-bar *ngIf="item.name === 'Unknown'" [className]="item.name === 'Unknown' ? 'color-bar color-bar-4' : ''">
            </itb-color-bar>
            <itb-text [className]="'text-default pl-2'" [text]="item.value + ' - ' + item.name"></itb-text>
          </div>
        </div>
      </div>
      <div *ngIf="isEmpty" fxLayout="column" fxLayoutAlign="center center" class="no-data">
        <span [ngClass]="{ 'img-nodatafound': true }"></span>
        <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
      </div>
      <div *ngIf="isError" class="no-data" fxLayout="column" fxLayoutAlign="center center">
        <span [ngClass]="{ 'img-404error': true }"></span>
        <itb-text [className]="'text-default text-color-primary'" [text]="'SOME WENT WRONG'"></itb-text>
      </div>
    </div>
    <div class="slide-content">
      <div fxLayout="row wrap" fxLayoutAlign="end">
        <div fxFlex="25" class="modal-select mb-2"></div>
        <div fxFlex="100" *ngIf="!filter">
          <ng-container *ngIf="isColLoaded">
            <itb-data-grid
              [columnDefs]="columnDefs"
              [rowModelType]="'serverSide'"
              (dataGridRowsPropsEmitter)="getRows($event)"
              (columnStateChangeEmitter)="columnStateChanged($event)"
              [initialUserColState]="userColumnState"
              [sideBar]="true"
              [cacheBlockSize]="20"
              [totalRows]="completeCount"
            >
            </itb-data-grid>
          </ng-container>
          <itb-spinner fxLayoutAlign="center center" class="h-100" [spinnerSize]="75" *ngIf="isSpinnerLoading"></itb-spinner>
          <div *ngIf="isGridEmpty" class="no-data" fxLayout="column" fxLayoutAlign="center center">
            <span [ngClass]="{ 'img-nodatafound': true }"></span>
            <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
          </div>
        </div>
        <div fxFlex="100" *ngIf="filter">
          <ng-container *ngIf="isFilterColLoaded">
            <itb-data-grid
              [sideBar]="true"
              [totalRows]="filterRowData.length"
              [defaultColDef]="defaultColDef"
              [columnDefs]="filterColumnDefs"
              [rowData]="filterRowData"
              [rowModelType]="'clientSide'"
              (columnStateChangeEmitter)="filterColumnStateChanged($event)"
              [initialUserColState]="filteruserColumnState"
            ></itb-data-grid>
          </ng-container>
          <itb-spinner fxLayoutAlign="center center" class="h-100" [spinnerSize]="75" *ngIf="isFilterLoading"></itb-spinner>
          <div *ngIf="isFilterEmpty" [ngClass]="{ 'img-nodatafound': true }" fxLayout="column" fxLayoutAlign="end center">
            <itb-text [className]="'text-default text-color-primary'" [text]="'NO DATA FOUND'"></itb-text>
          </div>
          <div *ngIf="isError" class="no-data" fxLayout="column" fxLayoutAlign="center center">
            <span [ngClass]="{ 'img-404error': true }"></span>
            <itb-text [className]="'text-default text-color-primary'" [text]="'SOME WENT WRONG'"></itb-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</itb-widget>
