<itb-heading *ngIf="userData.orgStatus.toLowerCase() === 'trial'" mat-dialog-title [className]="'modal-h'" [text]="'Trial Expired'"></itb-heading>
<itb-heading *ngIf="userData.orgStatus.toLowerCase() === 'cancelled'" mat-dialog-title [className]="'modal-h'" [text]="'Subscription Cancelled'"></itb-heading>


<mat-dialog-content>
    <p *ngIf="userData.orgStatus.toLowerCase() === 'trial'">Your trial period has expired. Please subscribe to one of the paid plans or contact ITBoost Support
        at <span><a href="support@itboost.com">support@itboost.com</a></span> for further assistance.</p>

  <p *ngIf="userData.orgStatus.toLowerCase() === 'cancelled'">Your subscription has been cancelled. Please resubscribe to one of the paid plans or contact ITBoost Support
    at <span><a href="support@itboost.com">support@itboost.com</a></span> for further assistance.</p>

</mat-dialog-content>

<mat-dialog-actions>

    <itb-default-button [text]="'Subsribe'" [className]="'primary'" [iconClassName]="'save'"
        *ngIf="userData.canAccessAll" (onClickHandler)="subscribe()">
    </itb-default-button>
  <itb-default-button [text]="'Logout'" [className]="'default'" [iconClassName]="'back'"
                       (onClickHandler)="logout()">
  </itb-default-button>
</mat-dialog-actions>`
