import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms'
import { MatSelectChange } from '@angular/material'
import * as AdlActions from '@app/store/adl-store/actions/adl.actions'
import { ADLState } from '@app/store/adl-store/reducers'
import * as selector from '@app/store/adl-store/selectors/index'
import { RIGHT_SIDE_BAR } from '@core/constants/constants'
import { IButtonIcon } from '@core/interfaces/button-icons'
import { ILinkedItems } from '@core/interfaces/linked-items'
import { RightSideBarService } from '@core/services/right-side-bar.service'
import { Store } from '@ngrx/store'
import { ISelect } from 'app/interfaces/ui-components.interface'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-company-pods-linked-items-form',
  templateUrl: './pods-linked-items-form.component.html',
  styleUrls: ['./pods-linked-items-form.component.scss'],
})
export class PodsLinkedItemsFormComponent implements OnInit {

  @Input() selectedObj: ILinkedItems
  @Input() templates: any[] // tslint:disable-line: no-any
  @Input() adlIntegrations: any[] // tslint:disable-line: no-any
  @Input() companyId: string
  @Input() linkedItemsIdsArr: string[]
  @Output() onSave = new EventEmitter<ILinkedItems[]>()
  @Output() onCancel = new EventEmitter<string>()

  formData: FormGroup
  buttons: IButtonIcon[]
  isTouched: boolean
  hasNoData: boolean
  linkedItemForm: FormGroup
  isShowErrorsOnSubmit: boolean
  linkedItemsTypeOptions: ISelect[]
  linkedItemsSelectedType: string
  linkedItemsTypeSelectedOptions: ISelect[]
  templateItem: any // tslint:disable-line: no-any
  subscriptions: Subscription

  constructor(private readonly formBuilder: FormBuilder, private readonly rightSideBarSvc: RightSideBarService,
              private readonly store: Store<ADLState>) {
    this.isShowErrorsOnSubmit = false
    this.hasNoData = false
    this.buttons = this.rightSideBarSvc.createButtons(RIGHT_SIDE_BAR.CLOSE)
    this.linkedItemsTypeSelectedOptions = []
    this.linkedItemsTypeOptions = []
    this.subscriptions = new Subscription()
  }

  get form() {
    return this.linkedItemForm.controls
  }

  btnClickHandler(btnItem: string) {
    switch (btnItem) {
      case RIGHT_SIDE_BAR.CLOSE.toLowerCase():
        this.onCancel.emit()
        break
      case RIGHT_SIDE_BAR.SAVE.toLowerCase():
        const linkedItemsArray = []
        if (this.linkedItemForm.controls.itemsList.value) {
          this.linkedItemForm.controls.itemsList.value.forEach(item => {
            const linkedItemObj: ILinkedItems = this.rightSideBarSvc.createEmptyLinkedItem(
              this.linkedItemForm.controls.itemType.value.label
            )
            linkedItemObj.relatedAssetId = item.id
            linkedItemObj.relatedAssetName = item.label
            linkedItemObj.relatedAssetPath = ''
            linkedItemsArray.push(linkedItemObj)
          })
          this.selectedObj.returnedItemType = this.linkedItemForm.controls.itemType.value
          if (this.templateItem) {
            this.selectedObj.templateMetaId = this.templateItem.uuid
          }
          if (linkedItemsArray.length > 0) {
            this.buttons = this.rightSideBarSvc.createButtons(RIGHT_SIDE_BAR.SETTING)
            this.onSave.emit(linkedItemsArray)
          }
        }
        break
      default:
        this.buttons = this.rightSideBarSvc.createButtons(RIGHT_SIDE_BAR.SETTING)
    }
  }

  onTypeChange(item: MatSelectChange) {
    this.linkedItemForm.controls.itemType.setValue(item.source.value.label)
    this.linkedItemsSelectedType = `Select ${item.source.value.label}`
    this.linkedItemsTypeSelectedOptions = []
    switch (item.source.value.label) {
      case RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.CONTACT.id:
        this.rightSideBarSvc.fetchContactTableData(this.companyId).subscribe(contacts => {
          this.linkedItemsTypeSelectedOptions = this.rightSideBarSvc.createLinkedItemsOnCategory(contacts)
          this.filterTypeOptions()
        })
        break
      case RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.LOCATION.id:
        this.rightSideBarSvc.getAllLocations(this.companyId).subscribe(locations => {
          this.linkedItemsTypeSelectedOptions = this.rightSideBarSvc.createLinkedItemsOnCategory(locations)
          this.filterTypeOptions()
        })
        break
      case RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.PASSWORD.id:
        this.rightSideBarSvc.getPasswordList(this.companyId).subscribe(passwords => {
          this.linkedItemsTypeSelectedOptions = this.rightSideBarSvc.createLinkedItemsOnCategory(passwords)
          this.filterTypeOptions()
        })
        break
      case RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.CONFIGURATION.id:
        this.rightSideBarSvc.getConfigurationsList(this.companyId).subscribe(configurations => {
          this.linkedItemsTypeSelectedOptions = this.rightSideBarSvc.createLinkedItemsOnCategory(configurations)
          this.filterTypeOptions()
        })
        break
      case RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.KNOWLEDGEBASE.id:
        this.rightSideBarSvc.getArticlesList(this.companyId).subscribe(articles => {
          this.linkedItemsTypeSelectedOptions = this.rightSideBarSvc.createLinkedItemsOnCategory(articles)
          this.filterTypeOptions()
        })
        break
      case RIGHT_SIDE_BAR.LINKED_ITEMS_OPTIONS.DOMAIN_TRACKER.id:
        this.rightSideBarSvc.getDomainsList(this.companyId).subscribe(dt => {
          this.linkedItemsTypeSelectedOptions = this.rightSideBarSvc.createLinkedItemsOnCategory(dt)
          this.filterTypeOptions()
        })
        break
      default:
        if (item.source.value.type === 'assetdiscovery') {
          const conditions =  {integrationUuid: item.source.value.id, companyUuid: this.companyId}
          this.store.dispatch(new AdlActions.LoadDiscovererSystems(JSON.stringify(conditions), this.companyId))
          this.subscriptions.add(
            this.store.select(selector.getDiscovererSystems).subscribe(discovererSystems => {
              this.linkedItemsTypeSelectedOptions = this.rightSideBarSvc.createLinkedItemsOnAdlIntegration(discovererSystems)
              this.filterTypeOptions()
            })
          )
        } else {
          this.rightSideBarSvc.getCustomTemplateList(this.companyId,
            item.source.value.id).subscribe(templateData => {
            this.templateItem = this.templates.find(templateItem => templateItem.uuid === item.source.value.id)
            const primaryField = this.templateItem.fields.find(templateFormDataItem => templateFormDataItem.list).fieldName
            this.linkedItemsTypeSelectedOptions = this.rightSideBarSvc.createLinkedItemsOnCategory(templateData, primaryField)
            this.filterTypeOptions()
          })
        }
    }
  }
  filterTypeOptions() {
    this.linkedItemsTypeSelectedOptions = this.linkedItemsTypeSelectedOptions.filter(item => !this.linkedItemsIdsArr.includes(item.id))
    this.hasNoData = this.linkedItemsTypeSelectedOptions.length ? false : true
  }
  ngOnInit() {
    this.linkedItemForm = this.formBuilder.group({
      itemType: undefined,
      itemsList: [],
    })

    this.linkedItemsTypeOptions = this.rightSideBarSvc.getLinkedItemsOptions(this.companyId)
    if (this.companyId !== '1') {
      this.linkedItemsTypeOptions = [...this.linkedItemsTypeOptions,
                                     ...this.rightSideBarSvc.createLinkedItemsOnAdlIntegration(this.adlIntegrations)]
      this.linkedItemsTypeOptions = [...this.linkedItemsTypeOptions, ...this.rightSideBarSvc.createLinkedItemsOnCategory(this.templates)]
    }
  }

}
