import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'

import { CustomSerializer, reducers } from './reducers/router-store'
// import { CoreStoreModule } from './core-store/core-store.module'
// import { DashboardStoreModule } from './dashboard-store/dashboard-store.module'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // CoreStoreModule,
    // DashboardStoreModule,
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateImmutability: false,
      },
    }),
    EffectsModule.forRoot([]),
  ],
  providers: [{
    provide: RouterStateSerializer,
    useClass: CustomSerializer,
  }],
})
export class RootStoreModule { }
