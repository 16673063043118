import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { environment } from '../../environments/environment'
import { SharedDataService } from '@core/services/shared-data.service'

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private readonly sharedService: SharedDataService) {}

   /***Sends a get request with parameters */
  get(url: string, moduleName: string, flag: boolean = false): Observable<any> {
    url = encodeURI(url)
    url = environment.baseUrl + url
    const body = {
      showToastr: flag,
      moduleName,
    }
    return this.http.get(url, this.createHttpHeaders(body))
  }


  /***Sends a post request with payload */
  add(url: string, body: any, flag: boolean = false): Observable<any> {
    url = encodeURI(url)
    body = { ...body, showToastr: flag }
    url = environment.baseUrl + url
    return this.http.post(url, body, this.createHttpHeaders())
  }

  /***Sends a post request with payload */
  addData(url: string, body: any, flag: boolean = false): Observable<any> {
    url = encodeURI(url)
    body = { ...body, showToastr: flag }
    return this.http.post(url, body, this.createHttpHeaders())
  }

  /***Sends a put request with payload */
  edit(
    url: string,
    body?: any,
    flag: boolean = false,
    contentType: string = 'application/json',
    embedUrl: boolean = true
  ): Observable<any> {
    url = encodeURI(url)
    if (body) body = { ...body, showToastr: flag }
    embedUrl ? (url = environment.baseUrl + url) : (url = url)
    return this.http.put(url, body, this.createHttpHeaders(body, contentType))
  }

  /***Sends a patch request with parameters */
  patch(url: string, body: any, flag: boolean = false): Observable<any> {
    url = encodeURI(url)
    body = { ...body, showToastr: flag }
    url = environment.baseUrl + url
    return this.http.patch(url, body, this.createHttpHeaders())
  }
  /***Sends a delete request with parameters */
  delete(url: string, body?: any, flag: boolean = false): Observable<any> {
    url = encodeURI(url)
    url = environment.baseUrl + url
    body && Object.keys(body).length ? (body = { ...body, showToastr: flag }) : (body = { showToastr: flag })
    return this.http.delete(url, this.createHttpHeaders(body))
  }

  /***Creates http headers to send with every request */
  private createHttpHeaders(body?: any, contentType: string = 'application/json') {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': contentType,
      'x-api-key': environment.xApiKey,
      token: this.sharedService.getUser() ? `Bearer ${this.sharedService.getUser().token}` : 'asdasdasdasd',
    })
    if (!body) {
      return { headers: httpHeaders }
    } else {
      return {
        headers: httpHeaders,
        body,
      }
    }
  }

  /**
   * uploads the image to s3 bucket using pre-signed url
   * @param url pre-signed url that is recieved in response to initial call in destination key
   * @param file file object
   */
  s3ImageUploadSignedUrl(url: string, file: File) {
    const fileObj = new File([file], file.name)
    if (fileObj.hasOwnProperty('imageSrc')) delete fileObj['imageSrc']
    const headers = new HttpHeaders({ 'Content-type': fileObj.type })
    const req = new HttpRequest('PUT', url, fileObj, {
      headers: headers,
    })
    return this.http.request(req)
  }

  getBlobResponse(url: string, body?: any): Observable<Blob> {
    url = encodeURI(url)
    return this.http.get(url, { responseType: 'blob' })
  }

  uploadPasswordsFile(url, file) {
    return this.http.put(url, file)
  }
  getEnvironment() {
    return environment
  }
}
