import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { LoginService } from '@app/modules/login/services/login.service'
import { URL_CONSTANTS } from '@core/constants/url-constants'
import { SharedDataService } from '@core/services/shared-data.service'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private readonly _router: Router, private readonly loginService: LoginService,
              private  readonly sharedDataService: SharedDataService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (JSON.parse(localStorage.getItem('user')) && Object.keys(JSON.parse(localStorage.getItem('user'))).length) {
      return true
    }
    const urlParams = new URLSearchParams(window.location.search)
    if (window.location.pathname === '/app/companyPods' ||
      window.location.pathname === '/app/companyAssetsPods') {
      if (urlParams.get('pods') || urlParams.get('asset')) {
        const podValue = urlParams.get('pods') ? urlParams.get('pods') : 'companyAssetsPods'
        this.loginService.createUrlForLogin(podValue)
      } else if (urlParams.get('screenName') && urlParams.get('screenName') === 'favoriteAssets') {
        this.loginService.createUrlForLogin('companyPods')
      } else {
        this.sharedDataService.setURL(document.URL)
        this._router
          .navigate([URL_CONSTANTS.LOGIN])
          .then(routeData => {
          })
          .catch(() => {
          })
      }
      return false
    }
    this._router
      .navigate([URL_CONSTANTS.LOGIN])
      .then(routeData => {
      })
      .catch(() => {
      })
    return false
  }
}
