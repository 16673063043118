import { Injectable } from '@angular/core'
import { CompactType, DisplayGrid, GridsterConfig, GridType } from 'angular-gridster2'
import * as _ from 'lodash'
import { Subject } from 'rxjs'

import { BREAK_POINT } from '../constants/constants'
import { resolutions } from '../interfaces/all-companies.interface'
import { IGridsterInterface } from '../interfaces/gridster.interface'
import { ITab } from '../interfaces/header-items.interface'
import { IWidget } from '../interfaces/widget'
import { WidgetGridsterProps } from '../interfaces/widget-gridster-props'

import { ApiService } from './../../../../src/app/services/api.service'
import { IWidgetsData } from './../interfaces/widgets-data.interface'

@Injectable({
  providedIn: 'root',
})
export class GlobalDashboardService {
  activeTabId: string
  currentTab: ITab
  allTabs: ITab[]
  categoryId: string
  tabs
  mainDashboardTabs = new Subject()

  selectedView: string
  updatedWidgets: IWidgetsData[]
  isSettingsEnabled: boolean
  private readonly url: string
  constructor(public baseService: ApiService) {
    this.updatedWidgets = undefined
    this.selectedView = ''
  }

  /*** this function will return with current resolution  */
  getWidgetsOnCurrentResolution(windowsInnerWidth: number, widgets): IWidget[] {
    if (windowsInnerWidth <= resolutions.MOBILE_RESOLUTION) {
      return widgets.map(widget => this.createCurrentWidgets(widget, { ...widget }))
    }
    if (windowsInnerWidth <= resolutions.TABLET_RESOLUTION) {
      return widgets.map(widget => this.createCurrentWidgets(widget, { ...widget }))
    }
    return widgets.map(widget => this.createCurrentWidgets(widget, { ...widget }))
  }

  /*** this function will return  current width ie mobile/desktop/tablet current resolution  */
  getCurrentWidth(windowsInnerWidth: number): number {
    if (windowsInnerWidth <= resolutions.MOBILE_RESOLUTION) {
      return resolutions.MOBILE_RESOLUTION
    }
    if (windowsInnerWidth <= resolutions.TABLET_RESOLUTION) {
      return resolutions.TABLET_RESOLUTION
    }
    return resolutions.LARGE_RESOLUTION
  }
  /*** this function will set gridster properties for all dashboard widgets  */

  getGridsterOptions(dragable: boolean, resizable: boolean, breakPoint?: number): GridsterConfig {
    const options: GridsterConfig = {
      gridType: GridType.VerticalFixed,
      compactType: CompactType.None,
      margin: 20,
      outerMargin: true,
      outerMarginTop: undefined,
      outerMarginRight: undefined,
      outerMarginBottom: undefined,
      outerMarginLeft: undefined,
      useTransformPositioning: true,
      mobileBreakpoint: breakPoint || BREAK_POINT,
      minCols: 12,
      maxCols: 12,
      minRows: 1,
      maxRows: 20,
      maxItemCols: 12,
      minItemCols: 1,
      maxItemRows: 12,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedRowHeight: 120,
      fixedColWidth: 100,
      keepFixedHeight: true,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 0,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      swap: true,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: false,
      displayGrid: DisplayGrid.OnDragAndResize,
      disableWindowResize: false,
      disableWarnings: true,
      scrollToNewItems: false,
      setGridSize: true,
      resizable: {
        enabled: resizable,
      },
      draggable: {
        enabled: dragable,
      },
    }

    return options
  }
  setSavedWidgets(param: IWidgetsData[]) {
    this.updatedWidgets = param
  }
  getSavedWidgets() {
    return this.updatedWidgets
  }
  /*** this function will give maximum and minimum widget resizing properties . this function will be called when widgets will be
   * resized in setting component
   */

  getCustomGridsterConfig(windowsInnerWidth: number): IGridsterInterface {

    if (windowsInnerWidth <= resolutions.MOBILE_RESOLUTION || this.selectedView === 'mobile') {
      return {
        service: { minRows: 2, maxRows: 4, minCols: 12, maxCols: 13 },
        asset: { minRows: 2, maxRows: 4, minCols: 12, maxCols: 13 },
        assetPieChart: { minRows: 2, maxRows: 4, minCols: 12, maxCols: 13 },
        servicePieChart: { minRows: 2, maxRows: 4, minCols: 12, maxCols: 13 },
        companyList: { minRows: 2, maxRows: 4, minCols: 12, maxCols: 13 },
        docSnapshot: { minRows: 2, maxRows: 4, minCols: 12, maxCols: 13 },
        myFavouriteCompany: { minRows: 2, maxRows: 4, minCols: 12, maxCols: 13 },
        tickets: { minRows: 2, maxRows: 4, minCols: 12, maxCols: 13 },
        globalFavouriteCompany: {
          minRows: 2,
          maxRows: 4,
          minCols: 12,
          maxCols: 13,
        },
        companiesByType: { minRows: 2, maxRows: 3, minCols: 6, maxCols: 6 },
      }

    }
    if (windowsInnerWidth <= resolutions.TABLET_RESOLUTION || this.selectedView === 'tab') {
      return {
        service: { minRows: 2, maxRows: 5, minCols: 4, maxCols: 12 },
        asset: { minRows: 2, maxRows: 5, minCols: 4, maxCols: 12 },
        assetPieChart: { minRows: 2, maxRows: 5, minCols: 6, maxCols: 12 },
        tickets: { minRows: 2, maxRows: 5, minCols: 4, maxCols: 12 },
        servicePieChart: { minRows: 2, maxRows: 5, minCols: 6, maxCols: 12 },
        companyList: { minRows: 2, maxRows: 5, minCols: 8, maxCols: 12 },
        docSnapshot: { minRows: 2, maxRows: 5, minCols: 8, maxCols: 12 },
        myFavouriteCompany: { minRows: 2, maxRows: 5, minCols: 8, maxCols: 12 },
        globalFavouriteCompany: { minRows: 2, maxRows: 5, minCols: 8, maxCols: 12 },
        companiesByType: { minRows: 2, maxRows: 5, minCols: 6, maxCols: 12 },
      }
    }
    return {
      service: { minRows: 2, maxRows: 5, minCols: 2, maxCols: 6 },
      asset: { minRows: 2, maxRows: 5, minCols: 3, maxCols: 6 },
      assetPieChart: { minRows: 3, maxRows: 5, minCols: 3, maxCols: 6 },
      servicePieChart: { minRows: 3, maxRows: 5, minCols: 3, maxCols: 6 },
      tickets: { minRows: 2, maxRows: 5, minCols: 2, maxCols: 6 },
      companyList: { minRows: 2, maxRows: 5, minCols: 3, maxCols: 6 },
      docSnapshot: { minRows: 2, maxRows: 5, minCols: 3, maxCols: 6 },
      myFavouriteCompany: { minRows: 2, maxRows: 5, minCols: 6, maxCols: 6 },
      globalFavouriteCompany: { minRows: 2, maxRows: 5, minCols: 6, maxCols: 6 },
      companiesByType: { minRows: 2, maxRows: 5, minCols: 3, maxCols: 6 },
    }
  }

  setActiveTabId(param: string) {
    this.activeTabId = param
  }

  getActiveTabId() {
    return this.activeTabId
  }

  setAllTabs(tabs: ITab[]) {

    this.allTabs = tabs

  }

  getAllTabs(): ITab[] {

    return this.allTabs
  }

  setActiveTab(tab: ITab) {
    this.currentTab = { ...tab }
  }

  getActiveTab() {
    return this.currentTab
  }

  setSelectedView(value) {
    this.selectedView = value
  }

  getTabs(url) {
    return this.baseService.get(url, 'Tabs')
  }
  getCategoryId() {
    return this.categoryId
  }
  setCategoryId(params) {
    this.categoryId = params
  }

  /*** this function will return widgets for current selected view.   */

  private createCurrentWidgets(widgetData: IWidget, widgetDataCurrentResolutionWidgets: WidgetGridsterProps): IWidget {
    return {
      widgetUuid: widgetData.widgetUuid,
      name: widgetData.name,
      type: widgetData.type,
      x: widgetDataCurrentResolutionWidgets.x,
      y: widgetDataCurrentResolutionWidgets.y,
      rows: widgetDataCurrentResolutionWidgets.rows,
      cols: widgetDataCurrentResolutionWidgets.cols,
      boardFilter: widgetData.boardFilter,
      companyFilter: widgetData.companyFilter,
    }
  }

}
