import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { SharedDataService } from '@core/services/shared-data.service'
import { UtilService } from '@core/services/utils.service'
import * as cloneDeep from 'lodash/cloneDeep'
import { Observable } from 'rxjs/index'

import { ApiService } from '../../../services/api.service'
import { backendRoutes } from '../constants/constant'
import { IKbContentSearch } from '../interfaces/kb-content-search'
import { ISearchAssets, ISearchBKResponse, ISslSearch } from '../interfaces/search-bk-response'
@Injectable({
  providedIn: 'root',
})
export class SearchService {

  constructor(private readonly baseService: ApiService, public router: Router, private readonly utilService: UtilService,
              private readonly sharedDataService: SharedDataService) { }
  /*** Sends http call to backend for advance search */
  searchCall(body: string): Observable<ISearchBKResponse> {
    const company = this.sharedDataService.getGlobalSearchCompanies()
    body =  company && company.length !== 0 ? `${body}?reqUuidsArray=${JSON.stringify(company)}` : body
    return this.baseService.get(backendRoutes.ITB_ES_SEARCH(body),  'Global Search')
  }
  /*** Sends http call to backend for advance kb search */
  kbContentCall(value: string): Observable<IKbContentSearch[]> {
    const company = this.sharedDataService.getGlobalSearchCompanies()
    const companies = company && company.length !== 0 ? `?reqUuidsArray=${JSON.stringify(company)}` : ''
    return this.baseService.get(backendRoutes.ITB_ES_ADVANCE_SEARCH(value.trim(), companies), 'Advance Knowledge base Search')
  }
  /*** Arranged result by name */
  arrangeResult(arr: ISearchAssets[], value: string) {
    if (arr && arr.length > 0) {
      const substring = value
      arr.sort((a, b) => {
        if (!a.name) {
          return 0
        }
        if (!b.name) {
          return 1
        }
        const aName = a.name.toLowerCase()
        const bName = b.name.toLowerCase()
        return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0))
      })
      const compCopy = cloneDeep(arr)
      for (const a of Object.keys(compCopy)) {
        if (arr[a].name && (arr[a].name.indexOf(substring) < arr[a].name.indexOf(' ') || arr[a].name.indexOf(' ') === -1)) {
          arr.splice(parseInt(a, 10), 1)
          arr.unshift(compCopy[a])
        }
      }
      for (const b of Object.keys(arr)) {
        if (arr[b].name && arr[b].name.indexOf(substring) === 0)  {
          arr = this.move(arr, b, 0)
        }
        if (arr[b].name && arr[b].name.indexOf(substring) < 0)  {
          arr = this.move(arr, b, arr.length - 1)
        }
      }
      return arr
    }
    return arr
  }
  /*** Removes dublicate objects */
  move(arr: ISearchAssets[], oldIndex, newIndex): ISearchAssets[] {
    while (oldIndex < 0) {
      oldIndex += arr.length
    }
    while (newIndex < 0) {
      newIndex += arr.length
    }
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length
      while ((k--) + 1) {
        arr.push(undefined)
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
    return arr
  }
  /*** Removes dublicate objects */
  removeDublicateObjects(array: ISearchAssets[], prop: string): ISearchAssets[] {
    if (array || array.length === 0) {
      return array.reduce((previous, current) => {
        const object = previous.filter(item => item[prop] === current[prop])
        if (object.length === 0) {
          previous.push(current)
        }
        return previous
      }, [])
    }
    return []
  }
  /*** Filters contacts */
  filterContacts(data: ISearchAssets[], contacts: ISearchAssets[]): ISearchAssets[] {
    data.map(items => {
      items.entryType = 'contacts'
      items.companyName = items.company.name
      contacts.push(items)
    })
    return contacts
  }
  /*** Filter locations */
  filterLocations(data: ISearchAssets[], locations: ISearchAssets[]): ISearchAssets[] {
    data.map(item => {
      item.entryType = 'locations'
      item.companyName = item.company.name
      locations.push(item)
    })
    return locations
  }
  /*** Filters Password */
  filterPassword(data: ISearchAssets[], passwords: ISearchAssets[]): ISearchAssets[] {
    data.map(item => {
      item.entryType = 'passwords'
      item.companyName = item.company.name
      passwords.push(item)
    })
    return passwords
  }
  /*** Filters Configurations */
  filterConfigurations(data: ISearchAssets[], assets: ISearchAssets[]) {
    if (!data) {
      return assets
    }
    data.map(item => {
      if (item.ITBCompanyId && item.ITBCompanyId !== null) {
        item.entryType = 'configurations'
        if (!item.company) {
          item.name = item.company.name
          assets.push(item)
        } else {
          item.companyName = item.company.name
          assets.push(item)
        }
      } else if (item.company && item.company !== null) {
        if ((item.company.id && item.company.id !== null) || (item.company.ITBCompanyId &&
            item.company.ITBCompanyId !== null)) {
          item.entryType = 'configurations'
          item.companyName = item.company.name

          assets.push(item)
        }
      }
    })
    return assets
  }
  /*** Filters asset templates */
  filterAssetTemplates(data: ISearchAssets[], assets: ISearchAssets[]) {
    if (!data) {
      return assets
    }
    data.map(item => {
      item.entryType = item.itbTemplateName
      if (item.company) {
        item.companyName = item.company.name
      }
      assets.push(item)
    })
    return assets
  }
  filterDiscovers(data: ISearchAssets[], assets: ISearchAssets[]) {
    if (!data) {
      return assets
    }
    data.map(item => {
      item.searchName  = item.alias
      item.entryType = item.displayName
      item.companyName = item.company.name
      assets.push(item)
    })
    return assets
  }
  /*** Filter domain */
  filterDomain(data: ISearchAssets[], ssls: ISslSearch[]): ISslSearch[] {
    if (!data) {
      return ssls
    }
    data.map(item => {
      item.entryType = 'domain'
      item.companyName = item.company.name
      ssls.push(item)
    })
    return ssls
  }
  /*** Filters ssl */
  filterSsls(data: ISearchAssets[], ssls: ISslSearch[]): ISslSearch[] {
    if (!data) {
      return ssls
    }
    data.map(item => {
      item.entryType = 'SSL'
      item.companyName = item.company ? item.company.name : ''
      ssls.push(item)
      const sortedArr = ssls.slice()
      for (let i = 0; i < ssls.length - 1; i++) {
        if (sortedArr[i + 1].companyUuid === sortedArr[i].companyUuid) {
          if (sortedArr[i + 1].searchName === sortedArr[i].searchName) {
            ssls.splice(i, 1)
          }
        }
      }
    })
    return ssls
  }
  /*** Filters documents */
  filterDocuments(data: ISearchAssets[], documents: ISearchAssets[]) {
    if (!data) {
      return documents
    }
    data.map(item => {
      item.entryType = 'knowledgebase'
      item.companyName =  !item.companyUuid || (item.companyUuid === '1' || item.companyUuid === '0')
        ? 'Global' : item.company && item.company.name
      documents.push(item)
    })
    return documents
  }
  /*** Filters runbook templates */
  filterRunbooksTemplate(data: ISearchAssets[], documents: ISearchAssets[]) {
    if (!data) {
      return documents
    }
    data.map(item => {
      item.entryType = 'runbooks'
      if (item.company) {
        item.companyName = item.company.name
      }
      if (typeof item.companyName !== 'undefined') {
        documents.push(item)
      }
    })
    return documents
  }
  /*** Processing on search response */
  filterDataByType(data: ISearchBKResponse, value: string, companies: ISearchAssets[], contacts: ISearchAssets[],
                   companySite: ISearchAssets[], passwords: ISearchAssets[],
                   assetts: ISearchAssets[], documents: ISearchAssets[],
                   ssls: ISslSearch[]): ISearchBKResponse {
    companies = this.arrangeResult(data.companies ? data.companies : [], value)
    companySite = data.companySite ? this.filterLocations(data.companySite, companySite) : []
    contacts = data.contacts ? this.filterContacts(data.contacts, contacts) : []
    passwords = data.companypassword ? this.filterPassword(data.companypassword, passwords) : []
    assetts = this.filterConfigurations(data.configurations, assetts)
    assetts = this.filterAssetTemplates(data.assettemplatedatas, assetts)
    ssls = this.filterDomain(data.domain, ssls)
    documents = this.filterRunbooksTemplate(data.runbooktemplates, documents)
    ssls = this.filterSsls(data.ssllabs, ssls)
    documents = this.filterDocuments(data.runbooks, documents)
    assetts = this.filterDiscovers(data.discovererSystems, assetts)
    for (const obj1  of Object.keys(assetts)) {
      if (!this.utilService.isUndefined(assetts[obj1].formDataName)) {
        for (const obj in assetts[obj1].formDataName) {
          if (!this.utilService.isUndefined(assetts[obj1].formDataName[obj].fieldValue)) {
            assetts[obj1].searchName = assetts[obj1].formDataName[obj].searchName
          }
        }
      }
    }
    return {companies, contacts, companySite, passwords, assetts, ssls, documents,
    }
  }
  /*** Processing on advance kb search response */
  processAfterSearchContentCall(data: IKbContentSearch[], documents: ISearchAssets[]): ISearchAssets[] {
    const totalLength = data.length
    const kbContentData = data
    documents = []
    if (totalLength > 0) {
      for (const obj of Object.keys(kbContentData)) {
        kbContentData[obj].entryType = 'knowledgebase'
        if (!(kbContentData[obj].company) || (kbContentData[obj].company.uuid === '1')) {
          kbContentData[obj].companyName = 'Global'
          kbContentData[obj].searchName = kbContentData[obj].ITBRunBookName
          documents.push(kbContentData[obj])
        } else {
          kbContentData[obj].companyName = kbContentData[obj].company.name
          kbContentData[obj].searchName = kbContentData[obj].ITBRunBookName
          documents.push(kbContentData[obj])
        }
      }
    }
    return documents
  }
  /*** Processing on route to aseet  view  */
  getData(item: ISearchAssets, flag: string) {
    let path
    switch (flag) {
      case 'contacts':
        path = `/app/company/${item.companyUuid}/contacts/view/${item.uuid}`
        break
      case 'locations':
        path = `/app/company/${item.companyUuid}/locations/view/${item.uuid}`
        break
      case 'documents':
        path = item.entryType === 'runbooks' ?   this.getRunbookUrl(item)  :
          this.getKnowledgebaseUrl(item)
        break
      case 'assetts':
        path = this.getAssetsUrl(item)
        if (!path) {
          return
        }
        break
      case 'ssl':
        path = item.entryType === 'SSL' ? `/app/company/${item.companyUuid}/ssls/view/${item.uuid}` :
          `/app/company/${item.companyUuid}/domains/view/${item.uuid}`
        break
      case 'passwords':
        path = `/app/company/${item.companyUuid}/passwords/view/${item.uuid}`
        break
      case 'companies':
        path = `/app/company/${item.uuid}`
        break
      default:
        return
    }
    this.router.navigate([path]).then(data => {
    }).catch(error => {
    })
  }
  /*** Creates url for Runbook **/
  getRunbookUrl(item: ISearchAssets) {
    if (!item.companyUuid || (item.companyUuid === '0'  || item.companyUuid === '1')) {
      return `/app/documentation/runbook/view/${item.uuid}`
    }
    return`/app/company/${item.companyUuid}/documents/runbook/view/${item.uuid}`
  }
  /*** Creates url for Knowledgebase **/
  getKnowledgebaseUrl(item: ISearchAssets) {
    if (!item.companyUuid || (item.companyUuid === '1' || item.companyUuid === '0')) {
      return `/app/documentation/knowledgebase/view/${item.id}`
    }
    return`/app/company/${item.companyUuid}/documents/knowledgebase/view/${item.id}`
  }
  /*** Creates url for Assets **/
  getAssetsUrl(item: ISearchAssets) {
    if (item.integrationName) {
      if (item.company.companyUuid) {
        return`app/company/${item.company.companyUuid}/adl/discoverers/${item.integrationUuid}/view/${item.uuid}`
      }
      return`/app/adl/discoverers/${item.integrationUuid}/view/${item.uuid}`
    }

    return   item.entryType === 'configurations' ? `/app/company/${item.companyUuid}/configurations/view/${item.uuid}` :
      `/app/company/${item.itbCompanyUuid}/templates/${item.itbBTemplateUuid}/view/${item.uuid}`

  }
}
