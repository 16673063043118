import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material'
import { MODAL_WIDTH_CONSTANTS } from '@app/modules/core/constants/constants'
import { IStats } from '@app/modules/core/interfaces/server-stats.interface'
import { CoreService } from '@app/modules/core/services/core.service'
import _ from 'lodash'
import { forkJoin } from 'rxjs'
import { Subscription } from 'rxjs/index'

import { ICompanyConfiguration } from '../../../interfaces/company-configuration'
import { ICompanyConfigurationType } from '../../../interfaces/company-configuration-type'
import { IConfigurationType } from '../../../interfaces/configuration-type'
import { IAssetsFooterObject, IWidget } from '../../../interfaces/widget.interface'
import { WidgetService } from '../../../services/widget.service'
import { AssetStatisticsDialogComponent } from '../../asset-statistics-dialog/asset-statistics-dialog.component'
@Component({
  selector: 'app-asset-widget',
  templateUrl: './asset-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetWidgetComponent implements OnInit, OnDestroy {
  subscriptions: Subscription
  chartOption: {}
  OS: IWidget[]
  unknown: number
  loader: boolean
  nodata: boolean
  offline: number
  total: number
  online: number
  servers: number
  printers: number
  phones: number
  switches: number
  workstations: number
  networks: number
  others: number
  computers: number
  serverstats: IStats
  serverstatsObject: boolean
  assetCountObject: boolean
  assets: ICompanyConfigurationType[]
  dataResolved: boolean
  showLoading: boolean
  totalAsset: number
  configurationTypes: IConfigurationType[]
  currentList: ICompanyConfiguration[]
  currentType: string
  serverList: ICompanyConfiguration[]
  printerList: ICompanyConfiguration[]
  otherList: ICompanyConfiguration[]
  networkList: ICompanyConfiguration[]
  unknownList: ICompanyConfiguration[]
  computerList: ICompanyConfiguration[]
  phoneList: ICompanyConfiguration[]
  switchList: ICompanyConfiguration[]
  listDataAvailable: boolean
  count: number
  configurationList: ICompanyConfiguration[]
  footerWidgetScore: IAssetsFooterObject[]
  @Input() companyData
  configLoader: boolean
  constructor(private readonly widgetService: WidgetService,
              public dialog: MatDialog,
              private readonly coreService: CoreService,
              private readonly changeDetector: ChangeDetectorRef
  ) {
    this.subscriptions = new Subscription()
    this.configLoader = false
    this.unknown = 0
    this.loader = false
    this.nodata = false
    this.offline = 0
    this.total = 0
    this.online = 0
    this.servers = 0
    this.printers = 0
    this.phones = 0
    this.switches = 0
    this.workstations = 0
    this.networks = 0
    this.others = 0
    this.computers = 0
    this.serverstatsObject = false
    this.assetCountObject = false
    this.dataResolved = false
    this.showLoading = false
    this.totalAsset = 0
    this.serverList = []
    this.printerList = []
    this.otherList = []
    this.networkList = []
    this.unknownList = []
    this.computerList = []
    this.phoneList = []
    this.switchList = []
    this.listDataAvailable = false
    this.count = 0
  }
  /*** Sets footer object of the widget with hardcoded assets types  */
  footerObject(): IAssetsFooterObject[] {
    return [
      {
        scoreHeading: 'COMPUTERS',
        scoreCount: this.computers,
        className: 'score-col color-transparent',
      },
      {
        scoreHeading: 'PHONES',
        scoreCount: this.phones,
        className: 'score-col color-transparent',
      },
      {
        scoreHeading: 'NETWORKS',
        scoreCount: this.networks,
        className: 'score-col color-transparent',
      },
      {
        scoreHeading: 'OTHERS',
        scoreCount: this.others,
        className: 'score-col color-transparent',
      },
      {
        scoreHeading: 'SWITCHES',
        scoreCount: this.switches,
        className: 'score-col color-transparent',
      },
      {
        scoreHeading: 'PRINTERS',
        scoreCount: this.printers,
        className: 'score-col color-transparent',
      },
    ]

  }
  /*** Calls OS info, configuration type and  footerObject functions on load of component   */
  ngOnInit() {
    this.osInfo()
    this.footerWidgetScore = this.footerObject()
    this.filterByConfigType()
    this.configLoader = true
  }
  /*** Sends http request to get OS type  */
  osInfo() {
    this.loader = true
    this.nodata = false
    this.subscriptions.add(this.coreService.getWidgtes(this.companyData.uuid).subscribe((dataOS: IWidget[]) => {
      this.OS = dataOS
      this.loader = false
      const nullFlag = dataOS.every(val => !val.count)
      this.nodata = !nullFlag ? false : true
      const options = this.OS
      const color = []
      options.forEach((element, index) => {
        if (element.count) {
          options[index].value = element.count
          options[index].name = element.title
          color[index] = element.color

        }
        options[index] = _.omit(options[index], ['title', 'count', 'color'])
      })
      this.chartOption = this.widgetService.getChartConfig(options, color)
      this.changeDetector.detectChanges()
    }, errorOS => {
      this.loader = false
      this.nodata = true
      this.changeDetector.detectChanges()
    }))
  }
  /*** Sends http request to get company configuration types */
  filterByConfigType() {
    this.assetCountObject = false
    forkJoin(
      this.coreService.getCounts(this.companyData.uuid, 'Phone'),
      this.coreService.getOtherCount(this.companyData.uuid),
      this.coreService.getCounts(this.companyData.uuid, 'Network'),
      this.coreService.getCounts(this.companyData.uuid, 'Printer'),
      this.coreService.getCounts(this.companyData.uuid, 'Switch'),
      this.coreService.getCounts(this.companyData.uuid, 'WorkStation')
    ).subscribe(data => {
        this.configLoader = false
        this.serverstatsObject = true
        this.assetCountObject = true
        this.phones = data[0].count
        this.others =  data[1].count
        this.networks =  data[2].count
        this.printers =  data[3].count
        this.switches =  data[4].count
        this.workstations =  data[5].count
        this.computers = this.servers + this.workstations
        this.showLoading = false
        this.dataResolved = true
        this.footerWidgetScore = this.footerObject()
        // tslint:disable-next-line: no-string-literal
        if (!this.changeDetector['destroyed']) {
          this.changeDetector.detectChanges()
        }
    },
    error => {
      this.changeDetector.detectChanges()
    })
  }
  /*** Sends http request to get company configuration list */
  showListModel(type: string) {
    if (this[type.toLowerCase()] === 0) {
      return
    }
    if (type === 'COMPUTERS') {
      type = 'Server'
    }
    if (type === 'PHONES') {
      type = 'Phone'
    }
    if (type === 'PRINTERS') {
      type = 'Printer'
    }
    if (type === 'NETWORKS') {
      type = 'Network'
    }
    if (type === 'OTHERS') {
      type = 'Other'
    }
    if (type === 'SWITCHES') {
      type = 'Switch'
    }
    const dialogRef = this.dialog.open(AssetStatisticsDialogComponent, {
      data: { type, companyId: this.companyData.uuid },
      width: MODAL_WIDTH_CONSTANTS.LARGE,
    })
  }
  /*** Unsubscribe all subscription when component destroy*/
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
