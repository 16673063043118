import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SortPipe } from '@app/modules/core/pipes/sort.pipe'
import { SearchPipeForRole } from '@app/modules/shared/pipes/role-search.pipe'

import { AngularMaterialModule } from './angular-material.module'
import { NotificationComponent } from './component/notification/notification.component'
@NgModule({
  declarations: [NotificationComponent, SortPipe, SearchPipeForRole],
  imports: [
    CommonModule,
    AngularMaterialModule,
  ],
  exports: [
    AngularMaterialModule,
    SortPipe,
    SearchPipeForRole,
  ],
})
export class SharedModule { }
