import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'

import { GridRowAction } from '../interfaces/grid-row-action'

@Injectable()
export class GirdActionService {
  docLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  pdfLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  pdfIndex: BehaviorSubject<number> = new BehaviorSubject<number>(undefined)
  docIndex: BehaviorSubject<number> = new BehaviorSubject<number>(undefined)
  cwIndex: BehaviorSubject<number> = new BehaviorSubject<number>(undefined)
  atIndex: BehaviorSubject<number> = new BehaviorSubject<number>(undefined)
  action: Subject<GridRowAction> = new Subject<GridRowAction>()
  exportCSV$: Subject<boolean> = new Subject<boolean>()
  purgeCache$: Subject<boolean> = new Subject<boolean>()
  destroy$: Subject<boolean> = new Subject<boolean>()
  resetColumnState$: Subject<boolean> = new Subject<boolean>()
  noRowsOverlayClicked$: Subject<boolean> = new Subject<boolean>()
  actionsDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  columnState: string
  showNoRowsOverlay$: Subject<boolean> = new Subject<boolean>()
  hideNoRowsOverlay$: Subject<boolean> = new Subject<boolean>()
  rowsToUpdate: Subject<string[]> = new Subject<string[]>()
  rowsSelection$: Subject<boolean> = new Subject<boolean>()
  deselectRows$: Subject<boolean> = new Subject<boolean>()
  unCheck$: Subject<boolean> = new Subject<boolean>()
  hasNoColumns: boolean
  rowModelType: string
  filterChanged$: Subject<boolean> = new Subject<boolean>()
  setSelectedAction(actionType: string, data, rowIndex: number) {
    const action = {
      actionType,
      data,
      rowIndex,
    }
    this.action.next(action)
  }

  resetSelectedAction() {
    this.action.next({
      actionType: undefined,
      data: undefined,
      rowIndex: undefined,
    })
  }

  resetPdfIndex() {
    this.pdfIndex.next(undefined)
  }

  resetDocIndex() {
    this.docIndex.next(undefined)
  }

  resetIndexes() {
    if (!this.docLoader.value && !this.pdfLoader.value) {
      this.resetPdfIndex()
      this.resetDocIndex()
    }
  }
  rowsSelection() {
    this.rowsSelection$.next(true)
  }
  deselectRows() {
    this.deselectRows$.next(true)
  }
  unCheck() {
    this.unCheck$.next(true)
  }
  resetunCheck() {
    this.unCheck$ = new Subject<boolean>()
  }
  resetRowSelection() {
    this.rowsSelection$ = new Subject<boolean>()
  }
  resetDeselectRowSelection() {
    this.deselectRows$ = new Subject<boolean>()
  }
  exportCSV() {
    this.exportCSV$.next(true)
  }
  resetExport() {
    this.exportCSV$ = new Subject<boolean>()
  }
  purgeCache() {
    this.purgeCache$.next(true)
  }
  refreshseColumnState() {
    this.resetColumnState$.next(true)
  }
  showNoRowsOverlay(hasNoColumns = false) {
    this.hasNoColumns = hasNoColumns
    this.showNoRowsOverlay$.next(true)
  }
  hideNoRowsOverlay(hasNoColumns = false) {
    this.hasNoColumns = hasNoColumns
    this.hideNoRowsOverlay$.next(true)
  }
  refreshTable() {
    this.purgeCache$.next(true)
  }
  resetNoRowsOverlay() {
    this.showNoRowsOverlay$ = new Subject<boolean>()
  }
  resetHideNoRowsOverlay() {
    this.hideNoRowsOverlay$ = new Subject<boolean>()
  }
  resetPurgeCache() {
    this.purgeCache$ = new Subject<boolean>()
  }
  resetRefreshseColumnState() {
    this.resetColumnState$ = new Subject<boolean>()
  }

  noRowsOverlayClicked() {
    this.noRowsOverlayClicked$.next(true)
  }
  destroy() {
    this.destroy$.observers[1].next(true)
  }
}
