import { IHeaderGroupAngularComp } from '@ag-grid-community/angular'
import { Component } from '@angular/core'

import { GirdActionService } from '../services/grid-actions.service'

@Component({
  selector: 'app-loading-overlay',
  template: `
    <div class="ag-header-cell-label">
      <label class="container-checkbox">
        <input type="checkbox" [(ngModel)]="isChecked" (click)="selectRows($event)" />
        <span class="checkmark"></span>
      </label>

      <span ref="eText" class="ag-header-cell-text pl-1" role="columnheader" unselectable="on" aria-colindex="2">{{
        params.displayName
      }}</span>
      <span ref="eSortAsc" *ngIf="ascSort == 'active'" class="ag-header-icon ag-sort-ascending-icon" aria-hidden="true">
        <span class="ag-icon ag-icon-asc" unselectable="on" (click)="onSortRequested('desc', $event)"></span
      ></span>

      <span ref="eSortDesc" *ngIf="descSort == 'active'" class="ag-header-icon ag-sort-descending-icon" aria-hidden="true">
        <span class="ag-icon ag-icon-desc" unselectable="on" (click)="onSortRequested('', $event)"></span
      ></span>

      <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" aria-hidden="true" *ngIf="noSort == 'active'">
        <span class="ag-icon ag-icon-none" unselectable="on" (click)="onSortRequested('asc', $event)"> </span
      ></span>
    </div>
  `,
})
export class CustomHeader implements IHeaderGroupAngularComp {
  params
  ascSort: string
  descSort: string
  noSort: string
  isChecked: boolean
  constructor(readonly gridActionsService: GirdActionService) {
    this.noSort = 'active'
    this.isChecked = false
  }
  agInit(params): void {
    this.params = params
    params.column.addEventListener('sortChanged', this.onSortChanged.bind(this))
    this.gridActionsService.purgeCache$.subscribe(res => {
      const event = { checked: true }
      this.selectRows(event, true)
    })
    this.gridActionsService.unCheck$.subscribe(value => {
      value ? (this.isChecked = false) : (this.isChecked = true)
    })
    this.onSortChanged()
  }

  selectRows(event, refresh = false) {
    if (refresh) {
      this.isChecked = false
      return
    }

    if (event.target.checked) this.gridActionsService.rowsSelection()
    else {
      this.gridActionsService.deselectRows()
    }
  }
  onSortRequested(order, event) {
    this.params.setSort(order, event.shiftKey)
  }
  onSortChanged() {
    this.gridActionsService.filterChanged$.next(true)
    this.ascSort = this.descSort = this.noSort = 'inactive'
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active'
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active'
    } else {
      this.noSort = 'active'
    }
  }
}
