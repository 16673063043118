import * as discoverers from '../actions/adl.actions'
import {
  DiscovererState,
  initialState,
} from '../states/adl.states'

export function reducer(state = initialState, action: discoverers.AdlAction): DiscovererState {
  switch (action.type) {
    case discoverers.LOAD_DISCOVERERS: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case discoverers.LOAD_DISCOVERERS_FAIL: {
      const isError = action.payload
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError,
      }
    }
    case discoverers.LOAD_DISCOVERERS_SUCCESS: {
      const data = action.payload
      return {
        ...state,
        data,
        isLoading: false,
        isLoaded: true,
        isError: false,
      }
    }
    case discoverers.LOAD_HEADERS: {
      const data = action.payload
      return {
        ...state,
        isLoading: true,
      }
    }
    case discoverers.LOAD_HEADERS_FAIL: {
      const isError = action.payload
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError,
      }
    }
    case discoverers.LOAD_HEADERS_SUCCESS: {
      const data = action.payload
      return {
        ...state,
        data,
        isLoading: false,
        isLoaded: true,
        isError: false,
      }
    }
    default:
      return { ...state }
  }
}
