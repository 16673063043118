import { HttpEvent } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UtilService } from '@app/modules/core/services/utils.service'
import { ApiService } from '@app/services/api.service'
import { Observable } from 'rxjs'

import { IImageFileCredentials } from '../../interfaces/notes'

import { END_POINTS } from './../../constants/constants'
import { CompanyDashboardService } from './../companyDashboard/company-dashboard.service'
@Injectable({
  providedIn: 'root',
})
export class QuickNotesService {
  constructor(
    private readonly baseService: ApiService,
    private readonly utils: CompanyDashboardService,
    private readonly globalUtils: UtilService) { }

  getCompanyNotes(companyId: string, sqpr) {
    // const filterSQPR = this.utils.getFilterSQPR(sqpr.filterModel)
    // const sortSQPR = this.utils.getSortSQPR(sqpr.sortModel)
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(`${END_POINTS.GET_NOTES(companyId)}`, '', true)
  }
  getComapnyDeletedNotes(companyId) {
    return this.baseService.get(`${END_POINTS.GET_DELETED_NOTES(companyId)}`, '', true)
  }
  removeNotes(companyId: string, notesId: string) {
    return this.baseService.delete(END_POINTS.REMOVE_NOTES(companyId, notesId))
  }

  addNotes(companyId: string, body) {
    return this.baseService.add(END_POINTS.ADD_NOTES(companyId), body)
  }
  editNotes(companyId: string, body, notesId) {
    return this.baseService.edit(END_POINTS.REMOVE_NOTES(companyId, notesId), body)
  }
  getFeedbackChart(identifier) {
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${END_POINTS.GET_FEEDBACK_CHARTS}?conditions={"overallRating":{"$in":[1,3,5]},"companyid":"${identifier}"}`,
      '',
      true
    )
  }
  getFeedbackChartCounts(identifier) {
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${END_POINTS.GET_FEEDBACK_CHARTS}?conditions={"overallRating":{"$in":[2,4]},"companyid":"${identifier}"}`,
      '',
      true
    )
  }
  getFeedbackces(identifier) {
    return this.baseService.get(`${END_POINTS.GET_FEEDBACK_CES}?conditions={"companyid":"${identifier}"}`, '', true)
  }
  getFeedbacknas(identifier) {
    return this.baseService.get(`${END_POINTS.GET_FEEDBACK_NAS}?conditions={"companyid":"${identifier}"}`, '', true)
  }
  getFeedbackRowData(identifier, feedback: number, sqpr, pageNo: number, pageSize = 20) {
    // console.log(sqpr.filterModel.ticketRef.filter)
    const filterSQPR = this.utils.getFilterSQPR(sqpr.filterModel)
    const sortSQPR = this.utils.getSortSQPR(sqpr.sortModel)
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${END_POINTS.GET_FEEDBACK_ROW_DATA}?responses=[${feedback}]&companies=["${identifier}"]${
      Object.entries(filterSQPR).length ? `&conditions=${JSON.stringify(filterSQPR)}` : `&conditions=${JSON.stringify({})}`
      }${sortSQPR ? `&sortBy=${sortSQPR.key}&order=${sortSQPR.order}&pageNo=${pageNo}&pageSize=${pageSize}` : ''} `,
      '',
      true
    )
  }
  getFeedbackRowDataCount(identifier, feedback) {
    return this.baseService.get(END_POINTS.GET_ROWS_COUNT(identifier, feedback), '', true)
  }
  getCompanyDocuments(companyId: string) {
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${END_POINTS.GET_DOCUMENTS(companyId)}?conditions={"favourite":true}&fields={"ITBRunBookName":1} `,
      '',
      true
    )
  }
  getAllCompanyDocuments(companyId: string, sqpr, pageNo: number, pageSize = 20) {
    const filterSQPR = this.globalUtils.getFilterSQPR(sqpr.filterModel)
    const sortSQPR = this.globalUtils.getSortSQPR(sqpr.sortModel)
    // tslint:disable-next-line: object-literal-key-quotes
    const merged = { ...{ favourite: true }, ...filterSQPR }
    // tslint:disable-next-line: max-line-length
    return this.baseService.get(
      `${END_POINTS.GET_DOCUMENTS(companyId)}${
      Object.entries(merged).length ? `?conditions=${JSON.stringify(merged)}` : `?conditions=${JSON.stringify({})}`
      }${sortSQPR ? `&sortBy=${sortSQPR.key}&order=${sortSQPR.order}&pageNo=${pageNo}&pageSize=${pageSize}&equalFlag=true` : ''} `,
      '',
      true
    )
  }

  getAllCompanyDocumentCount(companyId: string) {
    return this.baseService.get(
      `${END_POINTS.GET_DOCUMENTS_COUNT(companyId)}?conditions={"favourite":true}`,
      '',
      true
    )
  }

  getFeedbackColDefs() {
    return [
      { headerName: '', field: 'overallRating', type: 'smiley' },
      { headerName: 'TICKET', field: 'ticketRef', type: 'name', filter: 'agNumberColumnFilter' },
      { headerName: 'CLIENT NAME', field: 'clientName', filter: 'agTextColumnFilter' },
      { headerName: 'TECHNICIAN', field: 'technician', filter: 'agTextColumnFilter' },
      { headerName: 'EMAIL', field: 'email', filter: 'agTextColumnFilter' },
      { headerName: 'FEEDBACK', field: 'comment', filter: 'agTextColumnFilter' },
      {
        headerName: 'LAST RESPONDED', field: 'updatedAt', filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
    ]
  }
  getNotesColDefs() {
    return [
      { headerName: 'SUMMARY', field: 'summary', minWidth: 140, suppressColumnsToolPanel: true, lockPosition: true },
      {
        headerName: 'START DATE', field: 'startDate', minWidth: 140,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        headerName: 'CREATED AT', field: 'createdAt', minWidth: 140,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      { headerName: 'CREATED BY', field: 'createdBy', minWidth: 140 },
      { headerName: 'EDITED BY', field: 'editedBy', minWidth: 140 },
      {
        headerName: 'UPDATED AT', field: 'updatedAt', minWidth: 140,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
    ]
  }
  getDeletedNotesColDefs() {
    return [
      { headerName: 'SUMMARY', field: 'summary', minWidth: 140, suppressColumnsToolPanel: true, lockPosition: true },
      {
        headerName: 'START DATE', field: 'startDate', minWidth: 140,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        headerName: 'CREATED AT', field: 'createdAt', minWidth: 140,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      { headerName: 'CREATED BY', field: 'createdBy', minWidth: 140 },
      { headerName: 'EDITED BY', field: 'editedBy', minWidth: 140 },
      {
        headerName: 'UPDATED AT', field: 'updatedAt', minWidth: 140,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      { headerName: 'DELETED BY', field: 'deletedBy', minWidth: 140 },
      {
        headerName: 'DELETED AT', field: 'deletedAt', minWidth: 140,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
    ]
  }
  getChartConfigs(percentage, count, colorr, outerRadius, innerRadius, outerCenter, innerCenter) {
    if (count === 0) {
      colorr = ['#ddd']
    }
    return {
      title: {
        text: `${Math.round((percentage * 100) / 100)}%`,
        left: 'center',
        textStyle: {
          fontSize: 16,
          fontWeight: 400,
          fontFamily: 'Lato',
          color: 'rgb(136, 136, 136)',
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: [outerRadius, innerRadius],
          center: [outerCenter, innerCenter],
          avoidLabelOverlap: false,
          color: colorr,
          label: {
            normal: {
              show: false,
              position: 'center',
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: '30',
                fontWeight: 'bold',
              },
            },
          },
          animation: false,
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [{ value: count, name: '' }],
        },
      ],
    }
  }
  getFileSignedURL(fileName: string, type: string, companyId: string): Observable<IImageFileCredentials> {
    return this.baseService.get(END_POINTS.GET_FILE_SIGNED_URL(companyId, fileName, type), 'File upload')
  }

  s3ImageUpload(destination: string, file: File): Observable<HttpEvent<unknown>> {
    return this.baseService.s3ImageUploadSignedUrl(destination, file)
  }
}
